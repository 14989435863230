import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getLocationProtocol } from "../../../../core/utilities/commonUtilities";
import { getUrlData } from "../../../../core/utilities/redux/componentActions";
import { SocialShare } from "../../../../website/layouts/SocialShare";
import { Avatar } from "../../../common";
import useShopDetails from "../../../hooks/useShopDetails";
import { FACEBOOK_PATH, INSTAGRAM_PATH, TWITTER_PATH } from "../../constants";

const SHOP_DETAILS_DATA_KEY = "SHOP_DETAILS_DATA_KEY";

function ShareProfile(props) {
  const { match } = props;
  const { params } = match;
  const site_id = params.id;
  const site = site_id.toString();
  const { shopDetails, isLoading } = useShopDetails(site);

  if (isLoading) {
    return (
      <div className="rounded-3 h-44r min-vh-30 skeleton-loading shadow" />
    );
  }
  const isLoading_component = (
    <div className="spinner-border text-warning" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
  const site_domain = shopDetails.url;
  const site_title = shopDetails.name;

  const shareProfileView = (
    <div className="">
      <SocialShare site_domain={site_domain} site_title={site_title} />
    </div>
  );

  return (
    <div className="w-100 h-44r bg-white justify-content-start p-4 align-items-center shadow rounded-3">
      <div className="d-flex flex-column gap-4">
        <div className="d-flex align-items-center justify-content-center align-content-center h-50">
          <h3 className=" font-weight-bold border-bottom border-2 border-warning">
            Share Profile
          </h3>
        </div>
        <div className="d-flex justify-content-center align-items-center m-0">
          {isLoading ? isLoading_component : shareProfileView}
        </div>
      </div>
    </div>
  );
}

/**
 * @name SocialIconPath
 * @description A View that displays Social Icons with links to social accounts.
 * @param iconPath socialLink
 * @returns {JSX.Element}
 * @constructor
 */
function SocialIconPath({ iconPath, socialLink }) {
  return (
    <>
      <a href={socialLink} target="_blank" rel="noreferrer">
        <Avatar src={iconPath} pointer bordered stacked size="lg" />
      </a>
    </>
  );
}

SocialIconPath.propTypes = {
  iconPath: PropTypes.string.isRequired,
  socialLink: PropTypes.string.isRequired,
};

ShareProfile.defaultProps = {};

ShareProfile.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const shop_details_data = getUrlData(
    dataByUrl,
    sessionVariables,
    SHOP_DETAILS_DATA_KEY,
  );
  return {
    isLoadingShop: shop_details_data.isFetching,
    shop: shop_details_data.items,
  };
}

export default connect(mapStateToProps)(withRouter(ShareProfile));
