import Cookies from "js-cookie";
import _ from "lodash";
import { PublicApi } from "./PublicApi";
import { AuthenticationActions } from "modules/account/AccountActions";

export class PrivateApi extends PublicApi {
  constructor() {
    super();
    const accountActions: any = new AuthenticationActions();
    const tokens: any = accountActions.retrieveAuthenticationTokens();
    this.authenticatedHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokens.access_token}`,
      "X-CSRFToken": Cookies.get("csrftoken"),
    };
    this.authenticatedMultipartHeaders = {
      "Content-Type": "multipart/form-data; boundary=--",
      Authorization: `Bearer ${tokens.access_token}`,
      "X-CSRFToken": Cookies.get("csrftoken"),
    };
    const multipart_headers: any = _.merge({}, this.getAuthenticatedHeaders());
    multipart_headers["Content-Type"] = "multipart/form-data; boundary=--";
    this.multipartHeaders = this.authenticatedMultipartHeaders;
    this.headers = this.authenticatedHeaders;
  }

  getAuthenticatedHeaders() {
    return this.authenticatedHeaders;
  }

  getMultipartHeaders() {
    return this.multipartHeaders;
  }
}
