import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Sidebar from "modules/core/components/Sidebar";
import { Menu } from "modules/core/models/Menu";
import { PATH_WEBSITE_TEMPLATES } from "modules/website/WebsiteConstants";

function SidebarTemplate(props) {
  const { categories } = props;

  const url_parameters: any = new URLSearchParams(window.location.search);
  const url_category: any = url_parameters.get("category");
  const is_new_shop: string = url_parameters.get("is_shop");

  let sidebar_menus: any = categories.map((category) => {
    const path: string = `${PATH_WEBSITE_TEMPLATES}?category=${category}&is_shop=${is_new_shop}`;
    const is_active: any = url_category === category;
    return new Menu(category, "boxes", path, is_active);
  });

  const all_categories_menu: any = new Menu(
    "Top Templates",
    "list-nested",
    `${PATH_WEBSITE_TEMPLATES}?is_shop=${is_new_shop}`,
    url_category === null,
  );

  sidebar_menus = [all_categories_menu, ...sidebar_menus];
  return <Sidebar actions={sidebar_menus} {...props} />;
}

SidebarTemplate.propTypes = {
  categories: PropTypes.instanceOf(Array).isRequired,
};

export default withRouter(SidebarTemplate);
