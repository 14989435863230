import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import ManageCampaign from "modules/marketing/components/contacts/ManageCampaign";

function ManageCampaignsModal({ selectedContactsIds }) {
  const modalId = "manage-campaign-modal";
  return (
    <Modal modal_title="Manage Campaigns" modal_id={modalId} size="md">
      <ManageCampaign
        selectedContactsIds={selectedContactsIds}
        currentModalId={modalId}
      />
    </Modal>
  );
}

ManageCampaignsModal.propTypes = {
  selectedContactsIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
};

export default ManageCampaignsModal;
