import { PrivateApi } from "../core/utilities/api/PrivateApi";
import { modelObjectsFromArray } from "../core/utilities/commonUtilities";
import { FacebookCatalog } from "./models/FacebookCatalog";
import { FacebookLog } from "./models/FacebookLog";
import { FacebookPage } from "./models/FacebookPage";
import { FacebookProduct } from "./models/FacebookProduct";
import { InstagramAccount } from "./models/InstagramAccount";
import { Order } from "./models/Order";
import { OrderDetail } from "./models/OrderDetail";
import ShopPage from "./models/Page";
import { PaymentMethod } from "./models/PaymentMethod";
import { Payment } from "./models/Payments";
import { Product } from "./models/Product";
import { Shop } from "./models/Shop";
import { Taxonomy } from "./models/Taxonomy";
import {
  URL_ORDER,
  URL_ORDER_DETAILS,
  URL_PAGES,
  URL_PAYMENTS,
  URL_PAYMENT_METHODS,
  URL_PRODUCTS,
  URL_SHOP_DETAILS,
  URL_SHOP_FACEBOOK_CATALOGS,
  URL_SHOP_FACEBOOK_PAGES,
  URL_SHOP_FACEBOOK_PRODUCT,
  URL_SHOP_FACEBOOK_PRODUCT_LOGS,
  URL_SHOP_INSTAGRAM_ACCOUNTS,
  URL_SHOP_PUBLISH_PRODUCT_TO_FACEBOOK,
  URL_SHOP_PUBLISH_PRODUCT_TO_INSTAGRAM,
  URL_TAXONOMIES,
} from "./ShopConstants";

/**
 * @name ShopAPIs
 */
export default class ShopApi extends PrivateApi {
  /**
   * @param site_id - The site id to use for the API calls.
   * @param onSuccess - The success callback.
   * @param onError - The error callback.
   */
  getShopDetails(
    site_id: number,
    onSuccess: (shop: Shop) => void,
    onError: (error: any) => void,
  ) {
    this.getAPIRequest(
      URL_SHOP_DETAILS.replace("{site_id}", site_id.toString()),
      (response: any) => {
        const shop: any = new Shop(response);
        onSuccess(shop);
      },
      (error: any) => {
        onError(error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @param site_id - The site id
   * @param parameters - The parameters to pass to the API
   * @param onSuccess - The success callback
   * @param onError - The error callback
   */
  updateShopDetails(
    site_id: number,
    parameters: Object,
    onSuccess: (shop: Shop) => void,
    onError: (error: any) => void,
  ) {
    const headers: any = {
      ...this.getAuthenticatedHeaders(),
      ...{ "Content-Type": "multipart/form-data; boundary=----" },
    };
    this.postFormAPIRequest(
      URL_SHOP_DETAILS.replace("{site_id}", site_id.toString()),
      (response: any) => {
        const shop: any = new Shop(response);
        onSuccess(shop);
      },
      (error: any) => {
        onError(error.detail);
      },
      parameters,
      headers,
      "PATCH",
    );
  }

  /**
   * @name getPaymentMethods
   * @param site_id - The site id
   * @param onSuccess - The success callback
   * @param onError  - The error callback
   */
  getPaymentMethods(
    site_id: number,
    onSuccess: (methods: any) => void,
    onError: (error: any) => void,
  ) {
    this.getAPIRequest(
      URL_PAYMENT_METHODS.replace("{site_id}", site_id.toString()),
      (response: any) => {
        const payment_methods: any = modelObjectsFromArray(
          PaymentMethod,
          response,
        );
        onSuccess(payment_methods);
      },
      (error: any) => {
        onError(error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name getPaymentMethod
   * @param site_id
   * @param payment_method_id
   * @param onSuccess
   * @param onError
   */
  getPaymentMethod(
    site_id: number,
    payment_method_id: number,
    onSuccess: (methods: any) => void,
    onError: (error: any) => void,
  ) {
    const url: any = URL_PAYMENT_METHODS.replace(
      "{site_id}",
      site_id.toString(),
    );
    this.getAPIRequest(
      `${url}${payment_method_id}/`,
      (response: any) => {
        onSuccess(response);
      },
      (error: any) => {
        onError(error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name createPaymentMethod
   * @param site_id - The site id
   * @param parameters - The parameters to pass to the API
   * @param onSuccess - The success callback
   * @param onError - The error callback
   */
  createPaymentMethod(
    site_id: number,
    parameters: Object,
    onSuccess: (methods: any) => void,
    onError: (error: any) => void,
  ) {
    this.postAPIRequest(
      URL_PAYMENT_METHODS.replace("{site_id}", site_id.toString()),
      (response: any) => {
        onSuccess(response);
      },
      (error: any) => {
        onError(error);
      },
      parameters,
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name updatePaymentMethod
   * @param site_id - The site id
   * @param payment_method_id - The payment method id
   * @param parameters - The parameters to pass to the API
   * @param onSuccess - The success callback
   * @param onError - The error callback
   */
  updatePaymentMethod(
    site_id: number,
    payment_method_id: number,
    parameters: Object,
    onSuccess: (methods: any) => void,
    onError: (error: any) => void,
  ) {
    let url: any = URL_PAYMENT_METHODS.replace("{site_id}", site_id.toString());
    url = `${url}${payment_method_id}/`;
    this.postAPIRequest(
      url,
      (response: any) => {
        onSuccess(response);
      },
      (error: any) => {
        onError(error);
      },
      parameters,
      this.getAuthenticatedHeaders(),
      "PATCH",
    );
  }

  /**
   * @name deletePaymentMethod
   * @param site_id - The site id
   * @param payment_method_id - The payment method id
   * @param onSuccess - The success callback
   * @param onError - The error callback
   */
  deletePaymentMethod(
    site_id: number,
    payment_method_id: number,
    onSuccess: (methods: any) => void,
    onError: (error: any) => void,
  ) {
    let url: any = URL_PAYMENT_METHODS.replace("{site_id}", site_id.toString());
    url = `${url}${payment_method_id}/`;
    this.deleteAPIRequest(
      url,
      (response: any) => {
        onSuccess(response);
      },
      (error: any) => {
        onError(error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name getProducts
   * @param site_id - The site id
   * @param onSuccess - The success callback
   * @param onError - The error callback
   */
  getProducts(
    site_id: number,
    onSuccess: (products: any) => void,
    onError: (error: any) => void,
  ) {
    this.getAPIRequest(
      URL_PRODUCTS.replace("{site_id}", site_id.toString()),
      (response: any) => {
        const products: any = modelObjectsFromArray(Product, response);
        onSuccess(products);
      },
      (error: any) => {
        onError(error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name getTaxonomies
   * @description Get all taxonomies for a site
   * @param site_id
   * @param onSuccess
   * @param onError
   */
  getTaxonomies(
    site_id: number,
    onSuccess: (taxonomies: any) => void,
    onError: (error: any) => void,
  ) {
    this.getAPIRequest(
      URL_TAXONOMIES.replace("{site_id}", site_id.toString()),
      (response: any) => {
        const taxonomies: any = modelObjectsFromArray(Taxonomy, response);
        onSuccess(taxonomies);
      },
      (error: any) => {
        onError(error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name getShopPages
   * @description Get all pages for a shop
   * @param site_id - The site id
   * @param onSuccess - The success callback
   * @param onError - The error callback
   */
  getShopPages(
    site_id: number,
    onSuccess: (pages: any) => void,
    onError: (error: any) => void,
  ) {
    this.getAPIRequest(
      URL_PAGES.replace("{site_id}", site_id.toString()),
      (response: any) => {
        const pages: any = modelObjectsFromArray(ShopPage, response);
        onSuccess(pages);
      },
      (error: any) => {
        onError(error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name updateShopPage
   * @param site_id
   * @param page_id
   * @param parameters
   * @param onSuccess
   * @param onError
   */
  updateShopPage(
    site_id: number,
    page_id: number,
    parameters: Object,
    onSuccess: (pages: any) => void,
    onError: (error: any) => void,
  ) {
    let url: any = URL_PAGES.replace("{site_id}", site_id.toString());
    url = `${url}${page_id}/`;
    this.postAPIRequest(
      url,
      (response: any) => {
        onSuccess(response);
      },
      (error: any) => {
        onError(error);
      },
      parameters,
      this.getAuthenticatedHeaders(),
      "PATCH",
    );
  }

  /**
   * @name retrieveFacebookPages
   * @param site_id - the site id
   * @param callback - the callback to be called on success or failure
   * */
  retrieveFacebookPages(
    site_id: number,
    callback: (is_successful: boolean, pages_or_error: string | []) => void,
  ) {
    const url: any = URL_SHOP_FACEBOOK_PAGES.replace(
      "{site_id}",
      site_id.toString(),
    );

    this.getAPIRequest(
      url,
      (success_response: any) => {
        const facebook_pages: any = modelObjectsFromArray(
          FacebookPage,
          success_response,
        );

        callback(true, facebook_pages);
      },
      (error_response: any) => {
        return callback(false, error_response);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name retrieveFacebookCatalogs
   * @param site_id - the site id
   * @param callback - the callback to be called on success or failure
   * */
  retrieveFacebookCatalogs(
    site_id: number,
    callback: (is_successful: boolean, catalogs_or_error: string | []) => void,
  ) {
    const url: any = URL_SHOP_FACEBOOK_CATALOGS.replace(
      "{site_id}",
      site_id.toString(),
    );

    this.getAPIRequest(
      url,
      (success_response: any) => {
        const facebook_catalogs: any = modelObjectsFromArray(
          FacebookCatalog,
          success_response,
        );

        callback(true, facebook_catalogs);
      },
      (error_response: any) => {
        return callback(false, error_response);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name retrieveFacebookLogs
   * @param site_id - the site id
   * @param callback - the callback to be called on success or failure
   * */
  retrieveFacebookLogs(
    site_id,
    callback: (is_successful: boolean, logs_or_error: string | []) => void,
  ) {
    const url = URL_SHOP_FACEBOOK_PRODUCT_LOGS.replace(
      "{site_id}",
      site_id.toString(),
    );

    this.getAPIRequest(
      url,
      (success_response) => {
        const logs = modelObjectsFromArray(FacebookLog, success_response);

        callback(true, logs);
      },
      (error_response) => {
        return callback(false, error_response);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name retrieveFacebookProducts
   * @param site_id - the site id
   * @param callback - the callback to be called on success or failure
   * */
  retrieveFacebookProducts(
    site_id: number,
    callback: (is_successful: boolean, products_or_error: string | []) => void,
  ) {
    const url: any = URL_SHOP_FACEBOOK_PRODUCT.replace(
      "{site_id}",
      site_id.toString(),
    );

    this.getAPIRequest(
      url,
      (success_response: any) => {
        const facebook_products: any = modelObjectsFromArray(
          FacebookProduct,
          success_response,
        );

        callback(true, facebook_products);
      },
      (error_response: any) => {
        return callback(false, error_response);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name retrieveFacebookCatalogs
   * @param site_id - the site id
   * @param callback - the callback to be called on success or failure
   * */
  retrieveInstagramAccounts(
    site_id: number,
    callback: (
      is_successful: boolean,
      ig_accounts_or_error: string | [],
    ) => void,
  ) {
    const url: any = URL_SHOP_INSTAGRAM_ACCOUNTS.replace(
      "{site_id}",
      site_id.toString(),
    );

    this.getAPIRequest(
      url,
      (success_response: any) => {
        const instagram_accounts: any = modelObjectsFromArray(
          InstagramAccount,
          success_response,
        );

        callback(true, instagram_accounts);
      },
      (error_response: any) => {
        return callback(false, error_response);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name postProductsToFacebookCatalog
   * @param site_id
   * @param parameters
   * @param onSuccess
   * @param onError
   */
  postProductsToFacebookCatalog(
    site_id: number,
    parameters: Object,
    onSuccess: (pages: any) => void,
    onError: (error: any) => void,
  ) {
    const url: any = URL_SHOP_FACEBOOK_PRODUCT.replace(
      "{site_id}",
      site_id.toString(),
    );

    this.postAPIRequest(
      url,
      (success_response: any) => {
        onSuccess(success_response);
      },
      (error_response: any) => {
        onError(error_response);
      },
      parameters,
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name postProductToFacebookPage
   * @param site_id
   * @param parameters
   * @param onSuccess
   * @param onError
   */
  postProductToFacebookPage(
    site_id: number,
    parameters: Object,
    onSuccess: (pages: any) => void,
    onError: (error: any) => void,
  ) {
    const url: any = URL_SHOP_PUBLISH_PRODUCT_TO_FACEBOOK.replace(
      "{site_id}",
      site_id.toString(),
    );

    this.postAPIRequest(
      url,
      (success_response: any) => {
        onSuccess(success_response);
      },
      (error_response: any) => {
        onError(error_response);
      },
      parameters,
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name postProductToInstagram
   * @param site_id
   * @param parameters
   * @param onSuccess
   * @param onError
   */
  postProductToInstagram(
    site_id: number,
    parameters: Object,
    onSuccess: (pages: any) => void,
    onError: (error: any) => void,
  ) {
    const url: any = URL_SHOP_PUBLISH_PRODUCT_TO_INSTAGRAM.replace(
      "{site_id}",
      site_id.toString(),
    );

    this.postAPIRequest(
      url,
      (success_response: any) => {
        onSuccess(success_response);
      },
      (error_response: any) => {
        onError(error_response);
      },
      parameters,
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name Orders
   * @param site_id - The site id
   * @param state_eq - The order state
   * @param onSuccess - The success callback
   * @param onError - The error callback
   */
  getOrders(
    site_id: number,
    state_eq: string,
    onSuccess: (orders: any) => void,
    onError: (error: any) => void,
  ) {
    let url = URL_ORDER.replace("{site_id}", site_id.toString());
    if (state_eq) {
      url += `?filter[state_eq]=${state_eq}`;
    }
    this.getAPIRequest(
      url,
      (response: any) => {
        const orders: any = modelObjectsFromArray(Order, response);
        onSuccess(orders);
      },
      (error: any) => {
        onError(error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name Orders
   * @param site_id - The site id
   * @param state_eq - The order Detail state
   * @param onSuccess - The success callback
   * @param onError - The error callback
   */
  getOrdersDetails(
    site_id: number,
    order_id: number,
    onSuccess: (orders: any) => void,
    onError: (error: any) => void,
  ) {
    let url = URL_ORDER_DETAILS.replace("{site_id}", site_id.toString());
    url = url.replace("{order_id}", order_id.toString());
    this.getAPIRequest(
      url,
      (response: any) => {
        const order: any = modelObjectsFromArray(OrderDetail, response);
        onSuccess(order);
      },
      (error: any) => {
        onError(error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name Payment
   * @param site_id - The site id
   * @param state_eq - The payment state
   * @param onSuccess - The success callback
   * @param onError - The error callback
   */
  getPayments(
    site_id: number,
    state_eq: string,
    onSuccess: (payments: any) => void,
    onError: (error: any) => void,
  ) {
    let url = URL_PAYMENTS.replace("{site_id}", site_id.toString());
    if (state_eq) {
      url += `?filter[state_eq]=${state_eq}`;
    }
    this.getAPIRequest(
      url,
      (response: any) => {
        const payments: any = modelObjectsFromArray(Payment, response);
        onSuccess(payments);
      },
      (error: any) => {
        onError(error);
      },
      this.getAuthenticatedHeaders(),
    );
  }
}
