import PropTypes from "prop-types";
import { useCallback } from "react";
import { BillingAddon } from "modules/base/models/Billing";
import { openModal } from "modules/base/utilities/Actions";
import { Site } from "modules/website/WebsiteModels";

function AddonPurchaseAction(props) {
  const { addon, setAddon, website, setSelectedWebsite } = props;
  const handlePurchase = useCallback(() => {
    setAddon(addon);
    setSelectedWebsite(website);
    openModal("purchase-addon-modal");
  });
  return (
    <button
      type="button"
      className="btn btn-sm shadow-none  btn-outline-primary ms-1 dropdown-item"
      onClick={handlePurchase}
    >
      <i className="bi bi-puzzle" /> {addon.name}
    </button>
  );
}

AddonPurchaseAction.defaultProps = {
  website: null,
};

AddonPurchaseAction.propTypes = {
  addon: PropTypes.instanceOf(BillingAddon).isRequired,
  setAddon: PropTypes.func.isRequired,
  setSelectedWebsite: PropTypes.func.isRequired,
  website: PropTypes.instanceOf(Site),
};

export default AddonPurchaseAction;
