import PropTypes from "prop-types";
import TextArea from "modules/base/components/inputs/TextArea";
import TextInput from "modules/base/components/inputs/TextInput";

function StageFormFields({ state, onInputChange }) {
  return (
    <div className="mb-3">
      <div className="row row-cols-1 gy-md-3 gy-1">
        <TextInput
          name="name"
          title="Name"
          content={state.name ?? ""}
          controlFunc={onInputChange}
          required
        />
        <TextArea
          name="description"
          title="Description"
          content={state.description ?? ""}
          controlFunc={onInputChange}
        />
        <TextInput
          name="weight"
          title="Weight"
          content={state.weight ?? 0}
          controlFunc={onInputChange}
          inputType="number"
        />
      </div>
    </div>
  );
}

StageFormFields.defaultProps = {
  state: { isPublished: true },
  onInputChange: () => {},
};

StageFormFields.propTypes = {
  state: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    weight: PropTypes.number,
  }),
  onInputChange: PropTypes.func,
};

export default StageFormFields;
