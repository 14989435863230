import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Form from "modules/base/components/Form";
import SelectOptionCard from "modules/base/components/inputs/SelectOptionCard";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import { openModal } from "modules/base/utilities/Actions";
import { BillingAPI } from "modules/billing/BillingAPIs";
import ClaimCoupon from "modules/billing/components/ClaimCoupon";
import DebitCreditCardForm from "modules/billing/components/DebitCreditCardForm";

const PlanFeatures = memo(function PlanFeatures(props) {
  const { features } = props;
  const [showAll, setShowAll] = React.useState(false);

  const toggleShowAll = useCallback((e) => {
    e.stopPropagation();
    setShowAll((prevShowAll) => !prevShowAll);
  }, []);

  const displayedFeatures = showAll ? features : features.slice(0, 3);
  const planFeatures = displayedFeatures.map((feature) => {
    const id = uuidv4();
    return <span key={id}>{feature.name}</span>;
  });

  return (
    <div className="row">
      {planFeatures}
      {features.length > 3 && (
        <div className="d-block">
          <button
            type="button"
            onClick={toggleShowAll}
            className="btn btn-link"
          >
            {showAll ? "Show less" : "Show more"}
          </button>
        </div>
      )}
    </div>
  );
});

PlanFeatures.propTypes = {
  features: PropTypes.instanceOf(Array).isRequired,
};

const PlanSelect = memo(function PlanSelect(props) {
  const {
    selectedPlanId: initialSelectedPlanId,
    selectedCycle: initialSelectedCycle,
    handleSubmit,
    success,
    error: initialError,
    isSubmitting,
    formId,
    submitFormButtonLabel,
    parentModalId,
  } = props;
  const billingApi = new BillingAPI();
  const {
    plans,
    error: plansFetchError,
    isLoading: isFetchingPlans,
  } = billingApi.retrievePlans();
  const { customer, error: customerFetchError } =
    billingApi.getBillingCustomer("i");
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [selectedCycle, setSelectedCycle] = React.useState(null);
  const [billingCycles, setBillingCycles] = React.useState([]);
  const [error, setError] = React.useState(initialError);

  let has_minimum_cards_allowed = false;
  if (customer) {
    has_minimum_cards_allowed = customer.has_minimum_cards_allowed;
  }

  useEffect(() => {
    if (plansFetchError) {
      setError({
        message: plansFetchError.message,
      });
    }
  }, [plansFetchError]);

  useEffect(() => {
    if (customerFetchError) {
      setError({
        message: customerFetchError.message,
      });
    }
  }, [customerFetchError]);

  function findPlanById(id) {
    return plans.find((p) => p.id === id);
  }

  useEffect(() => {
    if (initialSelectedPlanId) {
      const plan = findPlanById(initialSelectedPlanId);
      setSelectedPlan(plan);
    }
  }, [isFetchingPlans]);

  useEffect(() => {
    if (initialSelectedCycle) {
      setSelectedCycle(initialSelectedCycle);
    }
  }, [isFetchingPlans]);

  function getBillingCycles() {
    const cycle_names = ["weekly", "monthly", "yearly"];
    const billing_cycles = [];
    cycle_names.forEach((cycle_name) => {
      if (selectedPlan[`allow_${cycle_name}_subscription`]) {
        billing_cycles.push({
          name: cycle_name,
          price: selectedPlan[`${cycle_name}_price`],
          currency: selectedPlan.currency,
        });
      }
    });
    return billing_cycles;
  }

  function addCard() {
    openModal("add-card", parentModalId);
  }

  const handleAddCard = useCallback(() => {
    if (!has_minimum_cards_allowed) {
      addCard();
    }
  });

  const handleSelectCycle = useCallback((e) => {
    handleAddCard();
    setSelectedCycle(e.value);
  });

  const handleSelectPlan = useCallback((e) => {
    const plan = findPlanById(Number(e.value));
    setSelectedPlan(plan);
  });

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    handleSubmit(selectedPlan, selectedCycle);
  });

  useEffect(() => {
    if (selectedPlan) {
      const billing_cycles = getBillingCycles();
      if (billing_cycles.length === 1 && !selectedCycle) {
        setSelectedCycle(billing_cycles[0].name);
      }
      setBillingCycles(billing_cycles);
    }
  }, [selectedPlan]);

  const cycleOptions = billingCycles.map((cycle) => {
    const locale = navigator.language;
    const amount_display = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: cycle.currency,
    }).format(cycle.price);
    return (
      <SelectOptionCard
        key={cycle.name}
        value={cycle.name}
        label={cycle.name}
        subtitle={amount_display}
        section_name={`${formId}-billing-cycle`}
        onSelect={handleSelectCycle}
        isSelected={selectedCycle === cycle.name}
        icon_position="top"
        selected_bg_color="gray-900"
        selected_pre_label_text_color_class="text-white"
        selected_label_text_color_class="text-white"
        selected_subtitle_text_color_class="text-white"
        selected_description_text_color_class="text-white"
        unselected_description_text_color_class="text-muted"
      />
    );
  });

  const planOptions = plans.map((plan) => {
    const { features } = plan;
    return (
      <SelectOptionCard
        key={plan.id}
        value={plan.id}
        label={plan.name}
        subtitle={plan.description}
        onSelect={handleSelectPlan}
        isSelected={selectedPlan?.id === plan.id}
        icon_position="top"
        description={<PlanFeatures features={features} />}
        section_name={`${formId}-billing-plan`}
        selected_bg_color="gray-900"
        selected_pre_label_text_color_class="text-white"
        selected_label_text_color_class="text-white"
        selected_subtitle_text_color_class="text-white"
        selected_description_text_color_class="text-white"
        unselected_description_text_color_class="text-muted"
      />
    );
  });
  if (isFetchingPlans) {
    return <NetworkActivityIndicator />;
  }
  return (
    <>
      <DebitCreditCardForm isDismissible={false} moveModalToOuterMost />
      <Form
        handleSubmit={onSubmit}
        id={formId}
        button_label={submitFormButtonLabel}
        button_color="gray-900"
        fullLengthButton
        padding="p-0"
        submit_button_margin={1}
        submitButtonEnabled={Boolean(selectedCycle)}
        isSubmitting={isSubmitting}
      >
        <NetworkMessageDisplay error={error} />
        <SuccessActionIndicator success={success} />
        <div className="row row-cols-md-2 row-cols-1 gy-md-3 gy-1 p-0">
          {planOptions}
        </div>
        <div className="row row-cols-md-2 row-cols-1 gy-md-3 gy-1 p-0 mt-3">
          {cycleOptions}
        </div>
        <ClaimCoupon selected_billing_plan_id={selectedPlan?.id} />
      </Form>
    </>
  );
});

PlanSelect.propTypes = {
  selectedPlanId: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  success: PropTypes.instanceOf(Object),
  error: PropTypes.instanceOf(Object),
  isSubmitting: PropTypes.bool,
  formId: PropTypes.string.isRequired,
  selectedCycle: PropTypes.string,
  submitFormButtonLabel: PropTypes.string.isRequired,
  parentModalId: PropTypes.string,
};

PlanSelect.defaultProps = {
  selectedPlanId: null,
  success: null,
  error: null,
  isSubmitting: false,
  selectedCycle: null,
  parentModalId: null,
};

export default PlanSelect;
