import PropTypes from "prop-types";
import { useCallback } from "react";
import { IResolveParams, LoginSocialGoogle } from "reactjs-social-login";
import {
  ENABLE_GOOGLE_LOGIN,
  GOOGLE_CLIENT_ID,
} from "modules/account/AccountConstants";
import googleIcon from "modules/account/assets/google_logo.png";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";

/**
 * Google Login Component
 * @returns {JSX.Element}
 * @constructor
 */
function GoogleLoginButton({
  callback,
  setError,
  is_authenticating,
  setAuthenticating,
}) {
  const onLoginStart = () => {
    setAuthenticating(true);
  };
  const handleGoogleLogin = useCallback(
    (response: IResolveParams) => {
      callback(true, response.data.access_token);
    },
    [callback],
  );
  const handleGoogleLoginError = useCallback(
    (err) => {
      const errorMessage = err?.data?.message ?? "Something went wrong";
      setError({ short_desc: errorMessage });
      setAuthenticating(false);
    },
    [setError, setAuthenticating],
  );
  const google_login_display = ENABLE_GOOGLE_LOGIN ? "block" : "none";
  let googleLoginButton = (
    <LoginSocialGoogle
      client_id={GOOGLE_CLIENT_ID}
      onLoginStart={onLoginStart}
      scope="profile email"
      discoveryDocs="claims_supported"
      access_type="online"
      typeResponse="accessToken"
      onResolve={handleGoogleLogin}
      onReject={handleGoogleLoginError}
      className="w-100 d-grid"
    >
      <button
        type="button"
        className={`btn btn-outline-olitt-grey d-flex align-items-center shadow-none d-${google_login_display}`}
      >
        <div className="d-flex w-100 align-items-center">
          <img height="30px" src={googleIcon} alt="" />
          <strong className="flex-grow-1"> Continue With Google </strong>
        </div>
      </button>
    </LoginSocialGoogle>
  );
  if (is_authenticating) {
    googleLoginButton = <NetworkActivityIndicator />;
  }
  return (
    <div className="col d-flex justify-content-center">{googleLoginButton}</div>
  );
}

GoogleLoginButton.propTypes = {
  callback: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setAuthenticating: PropTypes.func.isRequired,
  is_authenticating: PropTypes.bool.isRequired,
};

export default GoogleLoginButton;
