import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

const { SearchBar, ClearSearchButton } = Search;

export class Datatable extends React.Component {
  render() {
    const {
      columns,
      data,
      onClickRow,
      is_searchable,
      enable_clear_search,
      no_header,
    } = this.props;

    const headerClasses: any = no_header ? "d-none" : "table-borderless";

    return (
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={columns}
        bootstrap4
        search
      >
        {(props) => (
          <div>
            {is_searchable && (
              <SearchBar {...props.searchProps} className="mb-2" />
            )}
            {enable_clear_search && (
              <ClearSearchButton {...props.searchProps} />
            )}

            <BootstrapTable
              wrapperClasses="table-responsive"
              {...props.baseProps}
              classes="table table-hover table-bordered"
              headerClasses={headerClasses}
              rowClasses="border-bottom border-top"
              rowEvents={{
                onClick: (e, row, rowIndex) =>
                  onClickRow && onClickRow(data[rowIndex]),
              }}
              pagination={paginationFactory()}
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}
