import SidebarWrapper from "modules/core/components/SidebarWrapper";
import ContentList from "modules/marketing/components/content/ContentList";
import SidebarMarketing from "modules/marketing/components/SidebarMarketing";

function Content() {
  return (
    <SidebarWrapper sidebar={<SidebarMarketing />}>
      <ContentList />
    </SidebarWrapper>
  );
}

export default Content;
