import { City, State } from "country-state-city";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import CountryRegionSelector from "modules/base/components/inputs/CountryRegionSelect";

function CountryRegionCityFilterField({
  currentFilterValue,
  onFilterValueChange,
  selectedField,
  isMultiselect,
}) {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedRegions, setSelectedRegions] = useState([]);
  useEffect(() => {
    if (!isMultiselect) {
      if (selectedField?.value === "city") {
        const currentCity = City.getAllCities().find(
          (city) => city.name === currentFilterValue,
        );
        if (currentCity) {
          const currentState = State.getStateByCode(currentCity.stateCode);
          setSelectedCountry(currentCity.countryCode);
          setSelectedRegion(currentState.name);
        }
      } else if (selectedField?.value === "state") {
        const currentState = State.getAllStates().find(
          (state) => state.name === currentFilterValue,
        );
        if (currentState) {
          setSelectedCountry(currentState.countryCode);
        }
      }
    }
  }, [isMultiselect, selectedField]);
  useEffect(() => {
    if (isMultiselect) {
      if (selectedField?.value === "city") {
        const currentCities = City.getAllCities().filter((city) =>
          currentFilterValue.includes(city.name),
        );
        const currentStates = currentCities.map((city) =>
          State.getStateByCode(city.stateCode),
        );
        setSelectedCountries(currentCities.map((city) => city.countryCode));
        setSelectedRegions(currentStates.map((state) => state.name));
      } else if (selectedField?.value === "state") {
        const currentStates = State.getAllStates().filter((state) =>
          currentFilterValue.includes(state.name),
        );
        setSelectedCountries(currentStates.map((state) => state.countryCode));
      }
    }
  }, [isMultiselect, selectedField]);
  const onCountryRegionCityChange = useCallback((name, selectOption) => {
    if (name === selectedField?.type || name === selectedField?.value) {
      onFilterValueChange(selectOption);
    } else if (name === "country") {
      setSelectedCountry(selectOption.isoCode);
    } else if (name === "region") {
      setSelectedRegion(selectOption.label);
    }
  });
  const onCountryRegionCityMultiChange = useCallback((name, selectOptions) => {
    if (name === selectedField?.type || name === selectedField?.value) {
      onFilterValueChange(selectOptions.map((option) => option.label));
    } else if (name === "country") {
      setSelectedCountries(selectOptions.map((option) => option.isoCode));
    } else if (name === "region") {
      setSelectedRegions(selectOptions.map((option) => option.label));
    }
  });
  let handleFilterChange = onCountryRegionCityChange;
  let currentCountry = currentFilterValue;
  let currentRegion = currentFilterValue;
  if (isMultiselect) {
    handleFilterChange = onCountryRegionCityMultiChange;
  }
  let useCountryNameAsValue = true;
  if (["state", "city"].includes(selectedField?.value)) {
    useCountryNameAsValue = false;
    if (isMultiselect) {
      currentCountry = selectedCountries;
      if (selectedField?.value === "city") {
        currentRegion = selectedRegions;
      }
    } else {
      currentCountry = selectedCountry;
      if (selectedField?.value === "city") {
        currentRegion = selectedRegion;
      }
    }
  }
  let displayCityField = false;
  let displayRegionField = false;
  if (selectedField?.value === "state") {
    displayRegionField = true;
  } else if (selectedField?.value === "city") {
    displayRegionField = true;
    displayCityField = true;
  }
  return (
    <CountryRegionSelector
      controlFunc={handleFilterChange}
      isMulti={isMultiselect}
      displayCityField={displayCityField}
      displayRegionField={displayRegionField}
      isCountryMultiSelect={isMultiselect}
      isRegionMultiSelect={isMultiselect}
      isCityMultiSelect={isMultiselect}
      current_country={currentCountry}
      current_region={currentRegion}
      current_city={currentFilterValue}
      useCountryNameAsValue={useCountryNameAsValue}
      appendCountySuffixOnKenya
    />
  );
}

CountryRegionCityFilterField.defaultProps = {
  currentFilterValue: null,
};

CountryRegionCityFilterField.propTypes = {
  currentFilterValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onFilterValueChange: PropTypes.func.isRequired,
  selectedField: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  isMultiselect: PropTypes.bool.isRequired,
};

export default CountryRegionCityFilterField;
