import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { IconLoading } from "../../base/components/Icons";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import { ErrorModal } from "../../core/components/ErrorModal";
import { FormCountryInput } from "../../core/components/form-controls/FormCountryInput";
import { FormCountryRegionInput } from "../../core/components/form-controls/FormCountryRegionInput";
import { FormNumberInput } from "../../core/components/form-controls/FormNumberInput";
import FormTextInput from "../../core/components/form-controls/FormTextInput";
import MainNavigationSidebar from "../../core/components/MainNavigationSidebar";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import { ToastUtility, toast_styles } from "../../core/utilities/ToastUtility";
import PageLoadingIndicator from "../../website/components/PageLoadingIndicator";
import { AuthenticationActions } from "../AccountActions";
import { AuthenticationApi, UserApi } from "../AccountAPIs";
import { User } from "../AccountModels";

function Profile(props) {
  const authenticationActions: any = new AuthenticationActions();
  const authenticationApi: any = new AuthenticationApi();
  const user: any = authenticationActions.retrieveAuthenticatedUser();
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    phone: "",
    city: "",
    country: "",
    state: "",
    postcode: "",
  });
  const [is_updating_profile, setIsUpdatingProfile] = useState(false);
  const update_profile_btn: any = is_updating_profile ? (
    <IconLoading text="Updating" />
  ) : (
    "Update"
  );
  const [update_error, setUpdateError] = useState(false);
  const [fetch_error, setFetchError] = useState(null);
  const closeErrorModal: any = () => setUpdateError(false);
  const [is_fetching_user_profile, setIsFetchingUserProfile] = useState(false);

  const userApi: any = new UserApi();

  function fetchUserProfile() {
    setIsFetchingUserProfile(true);
    new UserApi().retrieveUserProfile((is_successful, profile_or_error) => {
      setIsFetchingUserProfile(false);
      if (is_successful) {
        setState({
          id: profile_or_error.id,
          first_name: profile_or_error.first_name,
          last_name: profile_or_error.last_name,
          email: profile_or_error.email,
          address: profile_or_error.address,
          phone: profile_or_error.phone,
          city: profile_or_error.city,
          country: profile_or_error.country,
          state: profile_or_error.state,
          postcode: profile_or_error.postcode,
        });
      } else {
        setFetchError(profile_or_error);
      }
    });
  }

  useEffect(fetchUserProfile, []);

  function updateProfileDetails() {
    setIsUpdatingProfile(true);
    const profile_user: any = new User(state);
    userApi.updateProfile(profile_user, (is_successful, profile_or_error) => {
      setIsUpdatingProfile(false);
      if (is_successful) {
        ToastUtility.toast(toast_styles.success, "Profile updated.");
        fetchUserProfile();
      } else {
        setUpdateError(true);
      }
    });
  }

  const handleChange: any = (event) => {
    const { name, value } = event.target;
    const form_input_values: any = state;
    form_input_values[name] = value;
    setState((prevState) => {
      return { ...prevState, ...form_input_values };
    });
  };

  const onSubmitForm: any = (event) => {
    event.preventDefault();
    setIsUpdatingProfile(true);
    updateProfileDetails();
  };

  let profile_component: object = null;

  if (is_fetching_user_profile) {
    profile_component = (
      <ComponentLoadingIndicator text="Fetching Your details" />
    );
  } else {
    profile_component = (
      <div>
        <form className="w-100" onSubmit={onSubmitForm}>
          <div className="row">
            <div className="col-md-6">
              <div className="card-body rounded border">
                <div className="mb-2">
                  <span className="text-secondary">First Name: </span>
                  <FormTextInput
                    name="first_name"
                    placeholder="Enter your first name"
                    value={state.first_name || ""}
                    is_required
                    is_editable
                    onChangeText={handleChange}
                  />
                </div>
                <div className="mb-2">
                  <span className="text-secondary">Last Name: </span>
                  <FormTextInput
                    name="last_name"
                    placeholder="Enter your last name"
                    value={state.last_name || ""}
                    is_required
                    is_editable
                    onChangeText={handleChange}
                  />
                </div>
                <div className="mb-2">
                  <span className="text-secondary">Email: </span>
                  <FormTextInput
                    type="email"
                    name="email"
                    value={state.email || ""}
                    is_required={false}
                    is_editable={false}
                  />
                </div>
                <div className="mb-2">
                  <span className="text-secondary">Phone number: </span>
                  <FormTextInput
                    type="tel"
                    name="phone"
                    placeholder="Enter your phone number"
                    value={state.phone || ""}
                    is_required={false}
                    is_editable
                    onChangeText={handleChange}
                  />
                </div>
                <div className="mb-2">
                  <span className="text-secondary">Address: </span>
                  <FormTextInput
                    name="address"
                    placeholder="Enter your address"
                    value={state.address || ""}
                    is_required
                    is_editable
                    onChangeText={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="d-block d-md-none m-2" />
            <div className="col-md-6">
              <div className="card-body rounded border">
                <div className="mb-2">
                  <span className="text-secondary">City: </span>
                  <FormTextInput
                    name="city"
                    placeholder="Enter your city"
                    value={state.city || ""}
                    is_required
                    is_editable
                    onChangeText={handleChange}
                  />
                </div>
                <div className="mb-2">
                  <span className="text-secondary">Country: </span>
                  <FormCountryInput
                    name="country"
                    placeholder="Choose your country"
                    country={state.country || ""}
                    is_required={false}
                    is_editable
                    onChangeText={handleChange}
                  />
                </div>
                <div className="mb-2">
                  <span className="text-secondary">Region or State: </span>
                  <FormCountryRegionInput
                    name="state"
                    placeholder="Enter your region or state"
                    country={state.country || ""}
                    region={state.state || ""}
                    is_required={false}
                    is_editable
                    onChangeText={handleChange}
                  />
                </div>
                <div className="mb-2">
                  <span className="text-secondary">Postal Code: </span>
                  <FormNumberInput
                    name="postcode"
                    placeholder="Enter your postal code"
                    value={state.postcode || ""}
                    is_required={false}
                    is_editable
                    onChangeText={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <br />
              <button
                type="submit"
                className="btn btn-olitt-grey w-100 d-flex align-items-center shadow-none"
                disabled={is_updating_profile}
              >
                <div className="d-flex w-100 align-items-center justify-content-center">
                  {update_profile_btn}
                </div>
              </button>
            </div>
          </div>
        </form>
        <ErrorModal
          show={update_error !== false}
          onClose={closeErrorModal}
          positive_button={closeErrorModal}
          short_desc={"Something didn't go quite right."}
          suggestion={update_error || ""}
        />
      </div>
    );
  }

  if (!user) {
    userApi.logout(props);
    return <PageLoadingIndicator />;
  }

  return (
    <SidebarWrapper sidebar={<MainNavigationSidebar />}>
      <div className="card card-body shadow-sm border-0 mb-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="font-weight-bold">Personal Information </h5>
        </div>
        {profile_component}
      </div>
    </SidebarWrapper>
  );
}

export default withRouter(Profile);
