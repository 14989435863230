import PropTypes from "prop-types";
import { useCallback } from "react";
import { openModal } from "modules/base/utilities/Actions";
import { Site } from "modules/website/WebsiteModels";

function ViewAddonPurchasesAction(props) {
  const { website, setSelectedWebsite } = props;
  const handlePurchase = useCallback(() => {
    setSelectedWebsite(website);
    openModal("addon-purchases-modal");
  });
  return (
    <button
      type="button"
      className="btn btn-sm shadow-none  btn-outline-primary ms-1 dropdown-item"
      onClick={handlePurchase}
    >
      <i className="bi bi-view-list" /> View Purchased Addons
    </button>
  );
}

ViewAddonPurchasesAction.defaultProps = {
  website: null,
};

ViewAddonPurchasesAction.propTypes = {
  setSelectedWebsite: PropTypes.func.isRequired,
  website: PropTypes.instanceOf(Site),
};

export default ViewAddonPurchasesAction;
