/**
 * Menu model
 */
export class Menu {
  title;

  icon;

  path;

  is_active;

  onClick;

  actions;

  showOnMobile;

  slug;

  constructor(
    title: string,
    icon,
    path: string,
    is_active: boolean,
    onClick: Function,
    actions: Array<any>,
    showOnMobile: boolean,
    slug: string,
  ) {
    this.title = title;
    this.icon = icon;
    this.path = path;
    this.is_active = is_active;
    this.onClick = onClick;
    this.actions = actions;
    this.showOnMobile = showOnMobile ?? true;
    this.slug = slug;
  }
}
