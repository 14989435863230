import { useEffect, useState } from "react";
import "sweetalert2/src/sweetalert2.scss";
import $ from "jquery";
import debounce from "lodash.debounce";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import withReactContent from "sweetalert2-react-content";
import Cookies from "universal-cookie";
import { AuthenticationActions } from "../../account/AccountActions";
import {
  CHECK_PAID_SITE_DATA,
  CHECK_PAID_SITE_URL,
} from "../../billing/BillingConstants";
import { invalidateData } from "../../core/utilities/redux/actions/actions";
import {
  fetchUrlData,
  getUrlData,
} from "../../core/utilities/redux/componentActions";
import { SiteBanner } from "../WebsiteModels";
import SiteApi from "modules/website/api/SiteApi";

const accountActions: any = new AuthenticationActions();

function OlittBanners(props) {
  const { is_paid_user } = props;

  const [banners, setBanners] = useState([]);
  const [pathname, setPathname] = useState("");
  const cookies: any = new Cookies();
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const isDesktopOrLaptop: any = dimensions.width >= 1025;
  const isTablet: any = dimensions.width >= 768 && dimensions.width <= 1024;
  const isMobile: any = dimensions.width >= 320 && dimensions.width <= 767;
  const current_device: any = isDesktopOrLaptop
    ? "desktop"
    : isTablet
    ? "tablet"
    : isMobile
    ? "mobile"
    : "all";

  const route_regex: {
    websites: string,
    templates: string,
    editor: string,
    dns: string,
    affiliate: string,
    domains: string,
    website_domains: string,
    website_billing: string,
    website_seo: string,
    website_plugins: string,
    profile: string,
    authentication: string,
  } = {
    websites: "\\/app\\/websites",
    templates: "\\/app\\/websites\\/new",
    editor: "\\/app\\/websites\\/\\d+\\/pages\\/\\d+",
    dns: "\\/app\\/dns\\/domains",
    affiliate: "\\/app\\/referral",
    domains: "\\/app\\/domains\\/search",
    website_domains: "\\/app\\/websites\\/\\d+\\/domains",
    website_billing: "\\/app\\/websites\\/\\d+\\/billing",
    website_seo: "\\/app\\/websites\\/\\d+\\/seo",
    website_plugins: "\\/app\\/websites\\/\\d+\\/plugins",
    profile: "\\/app\\/account\\/profile",
    authentication: "\\/app\\/account\\/authentication",
  };

  const siteApi: any = new SiteApi();

  function loadBanners() {
    siteApi.retrieveSiteBanners((is_successful, banners_or_error) => {
      if (is_successful) {
        setBanners(banners_or_error);
      }
    });
  }

  useEffect(() => {
    if (accountActions.isAuthenticated()) {
      props.dispatch(invalidateData(`${CHECK_PAID_SITE_URL}`));
      fetchUrlData(CHECK_PAID_SITE_DATA, `${CHECK_PAID_SITE_URL}`, props);
    }
    loadBanners();

    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const show_popup: any = (
    dom_position,
    html_content,
    is_backdrop,
    background_color,
    show_anim,
    close_anim,
    onClose,
  ) => {
    const MySwal: any = withReactContent(Swal);
    MySwal.fire({
      position: dom_position,
      html: html_content,
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      background: "transparent",
      backdrop: is_backdrop,
      showClass: {
        popup: show_anim,
      },
      hideClass: {
        popup: close_anim,
      },
    }).then((results) => {
      onClose(results);
    });
  };

  const show_default_popup: any = (
    dom_position,
    is_backdrop,
    background_color,
    show_anim,
    close_anim,
    header,
    body,
    btn_link,
    btn_label,
    button_color,
    footer_content,
    content_color,
    onClose,
  ) => {
    const MySwal: any = withReactContent(Swal);
    MySwal.fire({
      position: dom_position,
      title: `<span style="color: ${content_color};">${header}</span>`,
      html: `<div style="color: ${content_color};">${body}</div>`,
      footer: `<span style="color: ${content_color};">${footer_content}</span>`,
      showCancelButton: false,
      confirmButtonColor: button_color,
      confirmButtonText: btn_label,
      showCloseButton: true,
      allowOutsideClick: false,
      background: background_color,
      focusConfirm: true,
      backdrop: is_backdrop,
      showClass: {
        popup: show_anim,
      },
      hideClass: {
        popup: close_anim,
      },
    }).then((results) => {
      onClose(results);
    });
  };

  let banner_popups;
  if (banners) {
    banner_popups = banners.map((banner: SiteBanner) => {
      let init_banners: any = function () {};
      if (
        (banner.subscription_plan.pricing.amount > 0.0 && is_paid_user) ||
        banner.subscription_plan.pricing.amount <= 0.0
      ) {
        if (
          banner.device_target === "all" ||
          banner.device_target === current_device
        ) {
          const regex: any =
            typeof route_regex[banner.view_name] === "undefined"
              ? ""
              : route_regex[banner.view_name];
          const cookie_name: string = `banner-${banner.id}`;
          let frequency: any =
            typeof cookies.get(cookie_name) === "undefined"
              ? 0
              : parseInt(cookies.get(cookie_name), 10);
          let expiry_date: any = new Date(
            new Date().getTime() + banner.interval_days * 24 * 60 * 60 * 1000,
          );
          if (Number.isNaN(expiry_date.getTime())) {
            expiry_date = new Date(new Date().getTime());
          }
          const matchExactRegex: any = (regx, str) => {
            const match: any = str.match(regx);
            return match && str === match[0];
          };
          const onClose: any = (results) => {
            if (results.isConfirmed) {
              window.location.href = banner.button_link;
            }
            frequency += 1;
            cookies.set(cookie_name, frequency, {
              path: "/",
              expires: expiry_date,
            });
          };

          const position: any = banner.banner_position;
          const html: any = banner.html_content;
          const is_backdrop: any = banner.backdrop;
          const { background_color } = banner;
          const show_anim: any = banner.show_animation;
          const close_anim: any = banner.close_animation;
          const header: any = banner.header_text;
          const body: any = banner.banner_body;
          const btn_link: any = banner.button_link;
          const btn_label: any = banner.button_label;
          const { button_color } = banner;
          const footer: any = banner.footer_text;
          const { content_color } = banner;
          const banner_delay: any = banner.delay_milliseconds;

          const popup: any = () => {
            if (regex && pathname) {
              if (frequency < banner.limit) {
                if (matchExactRegex(regex, window.location.pathname)) {
                  if (Swal.isVisible()) {
                    return;
                  }
                  if (html) {
                    return show_popup(
                      position,
                      html,
                      is_backdrop,
                      background_color,
                      show_anim,
                      close_anim,
                      onClose,
                    );
                  }
                  return show_default_popup(
                    position,
                    is_backdrop,
                    background_color,
                    show_anim,
                    close_anim,
                    header,
                    body,
                    btn_link,
                    btn_label,
                    button_color,
                    footer,
                    content_color,
                    onClose,
                  );
                }
              }
            }
          };

          if (banner.pop_up_trigger === "exit_page") {
            init_banners = debounce(() => {
              $(document).on("mouseout", (evt) => {
                if (evt.toElement === null && evt.relatedTarget === null) {
                  $(evt.currentTarget).off("mouseout");
                  popup();
                }
              });
            }, banner_delay);
          } else {
            init_banners = debounce(() => {
              popup();
            }, banner_delay);
          }
        }
      }
      return init_banners();
    });
  }

  return banner_popups.map((banner) => banner);
}

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  let is_paid_user: boolean = false;
  if (accountActions.isAuthenticated()) {
    const pro_site_data: any = getUrlData(
      dataByUrl,
      sessionVariables,
      CHECK_PAID_SITE_DATA,
    );
    const plan: any = pro_site_data.items.subscription_plan;
    if (typeof plan !== "undefined" && plan !== null) {
      is_paid_user = true;
    }
  }
  return { is_paid_user };
}

export default connect(mapStateToProps)(withRouter(OlittBanners));
