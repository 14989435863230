import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import EmailSchedulerFields from "./EmailSchedulerFields";
import SelectInput from "modules/base/components/inputs/SelectInput";
import SwitchInput from "modules/base/components/inputs/SwitchInput";
import TextInput from "modules/base/components/inputs/TextInput";
import WYSIWYGEditor from "modules/base/components/inputs/WYSIWYGEditor";
import Tooltip from "modules/base/components/Tooltip";
import { openModal } from "modules/base/utilities/Actions";
import { serverGeneratePath } from "modules/base/utilities/Navigation";
import DesignEmailTemplateModal from "modules/marketing/components/emailstemplates/DesignEmailTemplateModal";
import { PATH_WEBSITE_DOMAINS } from "modules/website/WebsiteConstants";

function EmailTemplateEditor({
  handleInputChange,
  checked,
  customHtml,
  onClickDesign,
  onClickChooseTemplate,
  controlFunc,
}) {
  return (
    <>
      <SwitchInput
        label="Design Email Template"
        name="toggleDesign"
        handleInputChange={handleInputChange}
        isChecked={checked}
        wrapperClasses="ms-3"
      />
      {checked ? (
        <div>
          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            onClick={customHtml ? onClickDesign : onClickChooseTemplate}
            aria-label={
              customHtml ? "Design Email Template" : "Choose Email Template"
            }
          >
            <i className="bi bi-brush-fill" />
            {customHtml ? " Design Template" : " Choose Template"}
          </button>
        </div>
      ) : (
        <WYSIWYGEditor
          name="customHtml"
          title="Enter Email Content"
          placeholder="Type email text here..."
          controlFunc={controlFunc}
          content={customHtml ?? ""}
        />
      )}
    </>
  );
}

EmailTemplateEditor.defaultProps = {
  customHtml: "",
};

EmailTemplateEditor.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  customHtml: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  onClickDesign: PropTypes.func.isRequired,
  onClickChooseTemplate: PropTypes.func.isRequired,
  controlFunc: PropTypes.func.isRequired,
};

function EmailTemplateFormFields({
  state,
  setState,
  onInputChange,
  match,
  site,
  currentModalId,
  onWYSIWYGEditorChange,
}) {
  const { id: siteId } = match.params;
  const [isWYSIWYGEditorEnabled, setIsWYSIWYGEditorEnabled] = useState(true);
  const emailTypeOptions = [
    {
      value: "template",
      label: "Automated Email",
    },
    {
      value: "list",
      label: "Scheduled Email",
    },
  ];

  const onEmailTypeChange = useCallback(
    (e) => {
      onInputChange({ target: { name: "emailType", value: e.value } });
    },
    [onInputChange],
  );

  const domainConnectPath = serverGeneratePath(PATH_WEBSITE_DOMAINS, {
    id: siteId,
  });
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      site: siteId,
    }));
  }, [siteId]);

  const onClickDesign = useCallback(() => {
    openModal(
      `design-email-template-${state.id ?? "new"}-modal`,
      currentModalId,
    );
  });

  const onClickChooseTemplate = useCallback(() => {
    openModal(`prebuilt-templates-${state.id}-modal`, currentModalId, false);
  }, [currentModalId, state.id]);

  const onClickToggleDesign = useCallback(() => {
    setIsWYSIWYGEditorEnabled((prevChecked) => !prevChecked);
  }, []);

  return (
    <>
      <DesignEmailTemplateModal state={state} setState={setState} site={site} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <TextInput
          name="name"
          title="Name"
          content={state.name ?? ""}
          controlFunc={onInputChange}
          required
        />
        <TextInput
          name="subject"
          title="Subject"
          content={state.subject ?? ""}
          controlFunc={onInputChange}
          required
        />
        <TextInput
          name="fromName"
          title="Sender's Name"
          content={state.fromName ?? ""}
          controlFunc={onInputChange}
          required
        />
        <div className="flex">
          {site.custom_domain && (
            <TextInput
              name="fromAddress"
              title="Sender's Email Prefix"
              placeholder="mailbox name"
              content={state.fromAddress?.split("@")[0] ?? ""}
              controlFunc={onInputChange}
              groupClass="input-group"
              PostAddon={
                <span className="input-group-text bg-primary text-white">{`@${site.custom_domain}`}</span>
              }
            />
          )}

          {!site.custom_domain && (
            <NavLink
              className="btn btn-link mt-0"
              to={domainConnectPath}
              target="_blank"
              rel="noopener noreferrer"
            >
              Want to access a custom email?
            </NavLink>
          )}
        </div>
        <div className="mb-3">
          <Tooltip
            id="emailTypeTooltip"
            content="Select 'Automated' for emails triggered by campaigns or 'Scheduled' for manually sent emails, scheduled or instant."
          >
            <SelectInput
              name="emailType"
              title="Email Type"
              required
              options={emailTypeOptions}
              onChange={onEmailTypeChange}
              value={state.emailType}
              predicate={(option) => option.value === state.emailType}
            />
          </Tooltip>
        </div>
        {state.emailType === "list" && (
          <>
            <EmailSchedulerFields
              match={match}
              state={state}
              setState={setState}
              onInputChange={onInputChange}
            />
          </>
        )}
        <EmailTemplateEditor
          handleInputChange={onClickToggleDesign}
          checked={isWYSIWYGEditorEnabled}
          customHtml={state.customHtml}
          onClickDesign={onClickDesign}
          onClickChooseTemplate={onClickChooseTemplate}
          controlFunc={onWYSIWYGEditorChange}
        />
      </div>
    </>
  );
}

EmailTemplateFormFields.defaultProps = {
  state: {},
  onInputChange: () => {
    // do nothing
  },
  onWYSIWYGEditorChange: () => {
    // do nothing
  },
};

EmailTemplateFormFields.propTypes = {
  state: PropTypes.instanceOf(Object),
  setState: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  site: PropTypes.instanceOf(Object).isRequired,
  onWYSIWYGEditorChange: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string.isRequired,
};

export default withRouter(EmailTemplateFormFields);
