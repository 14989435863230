import $ from "jquery";
import { cmdCustomOpenStyleManager } from "../consts";

export const OpenStyleManager = {
  run(editor, sender, opts = {}) {
    this.sender = sender;
    this.editor = editor;

    if (!this.$cn) {
      this.$cn = $("<div></div>");
      this.$cn2 = $("<div></div>");
      this.$cn.append(this.$cn2);

      // Selector Manager container
      const slm = editor.SelectorManager;
      this.slm = slm;
      if (slm) this.$cn2.append(slm.render([]));

      // Style Manager
      const sm = editor.StyleManager;
      this.$cn2.append(sm.render());
      this.sm = sm;
      const smConfig = sm.getConfig();
      const pfx = smConfig.stylePrefix;
      this.$header = $(
        `<div class="${pfx}header">${editor.t("styleManager.empty")}</div>`,
      );
      this.$cn.append(this.$header);

      // Toggle Style Manager on target selection
      this.target = editor.editor;
      this.popUp = editor.PopUp;

      editor.on("pop-up:close", () => {
        editor.Commands.stop(cmdCustomOpenStyleManager);
      });
    }
    this.toggleSm(opts);
  },

  /**
   * Toggle Style Manager visibility
   * @private
   */
  toggleSm(opts) {
    const { target, sender, popUp } = this;
    if (sender && sender.get && !sender.get("active")) return;
    const { componentFirst } = target.get("SelectorManager").getConfig();
    const selectedAll = target.getSelectedAll().length;
    if (selectedAll === 1 || (selectedAll > 1 && componentFirst)) {
      this.$cn2.show();
      this.$header.hide();
    } else {
      this.$cn2.hide();
      this.$header.show();
    }

    popUp.setContent(this.$cn);
    popUp.open({
      selector: opts.selector,
    });
  },

  stop() {
    // Hide secondary container if exists
    if (this.$cn2) this.$cn2.hide();

    // Hide header container if exists
    if (this.$header) this.$header.hide();

    this.editor.Commands.stop(cmdCustomOpenStyleManager);
  },
};

export default OpenStyleManager;
