import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "modules/base/components/Icons";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import { openModal } from "modules/base/utilities/Actions";
import CreateTemplateModal from "modules/marketing/components/emailstemplates/CreateTemplateModal";
import EditTemplateModal from "modules/marketing/components/emailstemplates/EditTemplateModal";
import GenerateEmailTemplateModal from "modules/marketing/components/emailstemplates/GenerateEmailTemplateModal";
import MailSettingsModal from "modules/marketing/components/emailstemplates/MailSettingsModal";
import PrebuiltEmailTemplatesModal from "modules/marketing/components/emailstemplates/PrebuiltEmailTemplatesModal";
import publishUnpublishActionColumn from "modules/marketing/components/PublishUnpublishActionColumn";
import { emailTemplatesColumns } from "modules/marketing/components/tableColumns";
import MarketingAPI from "modules/marketing/MarketingAPI";

function EmailTemplateList({ match }) {
  const { id: siteId } = match.params;
  const [error, setError] = useState(null);
  const [uuid] = useState(uuidv4());
  const API = new MarketingAPI(siteId);
  const [prebuiltHtml, setPrebuiltHtml] = useState(null);
  const [paginationParams, setPaginationParams] = useState({
    start: 0,
    limit: 10,
  });
  const params = useMemo(
    () => ({
      ...paginationParams,
      orderBy: "dateAdded",
      orderByDir: "desc",
    }),
    [paginationParams],
  );
  const {
    emailTemplates,
    total,
    error: fetchTemplatesError,
    isLoading,
  } = API.getEmailTemplates(params);
  const onClickEdit = useCallback((template) => {
    openModal(`edit-template-${template.id}-modal`);
  });
  const onClickCreate = useCallback(() => {
    openModal(`create-template-${uuid}-modal`);
  });

  const onClickAICreate = useCallback(() => {
    openModal("generate-templates-modal");
  });
  const onClickMailSettings = useCallback(() => {
    openModal("mail-settings-modal");
  });
  const onClickDelete = useCallback((id, onSuccess, onError) => {
    API.deleteEmailTemplate(
      id,
      () => {
        onSuccess();
      },
      (error_response) => {
        onError("Unable to delete email template", error_response.message);
      },
    );
  });
  const handlePaginationChange = useCallback((newPaginationParams) => {
    setPaginationParams(newPaginationParams);
  }, []);
  const columns = [
    ...emailTemplatesColumns,
    publishUnpublishActionColumn(siteId, setError, "updateEmailTemplate"),
  ];
  const additionalTopRightButtons = [
    <button
      key="transport-settings"
      type="button"
      className="btn btn-outline-gray-900 mb-2 mb-md-0 me-0 me-md-2"
      onClick={onClickMailSettings}
    >
      <Icon icon="mailbox-flag" additionalClasses="me-2" />
      Mail Settings
    </button>,
    <button
      key="generate-ai"
      type="button"
      className="btn btn-ai text-white mb-2 mb-md-0 me-0 me-md-2"
      onClick={onClickAICreate}
    >
      <i className="bi bi-stars me-2" />
      Create using AI
    </button>,
  ];
  return (
    <>
      <NetworkMessageDisplay error={error ?? fetchTemplatesError} />
      <CreateTemplateModal uuid={uuid} prebuiltHtml={prebuiltHtml} />
      <GenerateEmailTemplateModal />
      <PrebuiltEmailTemplatesModal
        setPrebuiltHtml={setPrebuiltHtml}
        uuid={uuid}
      />
      <MailSettingsModal />
      <DataTable
        data={emailTemplates}
        total={total}
        columns={columns}
        isLoading={isLoading}
        createButtonColor="gray-900"
        editButtonColor="gray-900"
        objectType="email template"
        allowEdit
        allowDelete
        allowCreate
        onClickEdit={onClickEdit}
        onClickCreate={onClickCreate}
        EditModal={EditTemplateModal}
        onClickDelete={onClickDelete}
        additionalTopRightButtons={additionalTopRightButtons}
        onPaginationChange={handlePaginationChange}
        isServerSidePagination
      />
    </>
  );
}

EmailTemplateList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(EmailTemplateList);
