import { PrivateApi } from "../core/utilities/api/PrivateApi";
import { PublicApi } from "../core/utilities/api/PublicApi";
import { modelObjectsFromArray } from "../core/utilities/commonUtilities";
import { navigateToPage } from "../core/utilities/navigation/Navigator";
import { URL_AFFILIATE } from "../referral/ReferralConstants";
import { Affiliate } from "../referral/ReferralModels";
import {
  PATH_LOGIN,
  URL_APPLE_AUTHENTICATION,
  URL_EMAIL_FORGOT_PASSWORD,
  URL_EMAIL_LOGIN,
  URL_EMAIL_REGISTRATION,
  URL_EMAIL_RESEND_OTP,
  URL_EMAIL_RESET_PASSWORD,
  URL_EMAIL_VERIFY_OTP,
  URL_FACEBOOK_AUTHENTICATION,
  URL_GOOGLE_AUTHENTICATION,
  URL_SPREE_LOGIN_PATH,
  URL_USER_FACEBOOK_BUSINESSES,
  URL_USER_LOGOUT,
  URL_USER_PLASMIC_SSO,
  URL_USER_PROFILE,
  URL_USER_PROFILES,
} from "./AccountConstants";
import {
  AuthenticationTokens,
  FacebookBusiness,
  StudioSSO,
  User,
} from "./AccountModels";

/**
 * Api actions for the authentication service:
 *  - Refresh auth tokens
 *  - Revoke auth tokens
 */
export class AuthenticationApi extends PublicApi {
  verifyOtpCode(
    hash: string,
    email: string,
    otp: number,
    callback: (is_successful: boolean, message_or_error: string) => void,
  ) {
    this.postAPIRequest(
      URL_EMAIL_VERIFY_OTP,
      (results) => {
        const { message } = results;
        callback(true, message);
      },
      (e) => {
        const default_error: string =
          "An error occurred while verifying your account.";
        const error: any = e.detail || default_error;
        return callback(false, error);
      },
      {
        hash,
        email,
        otp,
      },
      this.getUnauthenticatedHeaders(),
    );
  }

  resendOtpCode(
    email: string,
    callback: (is_successful: boolean, message_or_error: string) => void,
  ) {
    this.postAPIRequest(
      URL_EMAIL_RESEND_OTP,
      (results) => callback(true, results),
      (e) => {
        const default_error: string =
          "An error occurred while resending your otp.";
        const error: any = e.detail || default_error;
        return callback(false, error);
      },
      { email },
      this.getUnauthenticatedHeaders(),
    );
  }

  forgotPassword(
    email: string,
    callback: (is_successful: boolean, message_or_error: string) => void,
  ) {
    this.postAPIRequest(
      URL_EMAIL_FORGOT_PASSWORD,
      (results) => {
        const { message } = results;
        callback(true, message);
      },
      (e) => {
        const default_error: string =
          "An error occurred while requesting for a password reset.";
        const error: any = e.detail || default_error;
        return callback(false, error);
      },
      { email },
      this.getUnauthenticatedHeaders(),
    );
  }

  resetPassword(
    reset_hash: string,
    password1: string,
    password2: string,
    callback: (is_successful: boolean, message_or_error: string) => void,
  ) {
    this.postAPIRequest(
      URL_EMAIL_RESET_PASSWORD,
      (results) => {
        const { message } = results;
        callback(true, message);
      },
      (e) => {
        const default_error: string =
          "An error occurred while resetting your password.";
        const error: any = e.detail || default_error;
        return callback(false, error);
      },
      {
        reset_hash,
        password1,
        password2,
      },
      this.getUnauthenticatedHeaders(),
    );
  }

  registerWithEmail(
    first_name: String,
    last_name: String,
    email: string,
    password: string,
    callback: (
      is_successful: boolean,
      tokens_or_error: AuthenticationTokens | string,
    ) => void,
  ) {
    this.postAPIRequest(
      URL_EMAIL_REGISTRATION,
      (results) => {
        const tokens: any = new AuthenticationTokens(results);
        callback(true, tokens);
      },
      (e) => {
        return callback(false, this.extractResponseError(e));
      },
      {
        first_name,
        last_name,
        email,
        password,
      },
      this.getUnauthenticatedHeaders(),
    );
  }

  loginWithEmail(
    email: string,
    password: string,
    callback: (
      is_successful: boolean,
      tokens_or_error: AuthenticationTokens | string,
    ) => void,
  ) {
    this.postAPIRequest(
      URL_EMAIL_LOGIN,
      (results) => {
        if (results.hash) {
          callback(false, results);
        } else {
          const tokens: any = new AuthenticationTokens(results);
          callback(true, tokens);
        }
      },
      (e) => {
        return callback(false, this.extractResponseError(e));
      },
      { email, password },
      this.getUnauthenticatedHeaders(),
    );
  }

  loginWithGoogle(
    social_access_token: string,
    callback: (
      is_successful: boolean,
      tokens_or_error: AuthenticationTokens | string,
    ) => void,
  ) {
    this.postAPIRequest(
      URL_GOOGLE_AUTHENTICATION,
      (results) => {
        const tokens: any = new AuthenticationTokens(results);
        callback(true, tokens);
      },
      (e) => {
        const default_error: string =
          "An error occurred while logging in with google.";
        const error: any = e.error_description || default_error;
        return callback(false, error);
      },
      { social_access_token },
      this.getUnauthenticatedHeaders(),
    );
  }

  loginWithApple(
    id_token: string,
    callback: (
      is_successful: boolean,
      tokens_or_error: AuthenticationTokens | string,
    ) => void,
  ) {
    this.postAPIRequest(
      URL_APPLE_AUTHENTICATION,
      (results) => {
        const tokens: any = new AuthenticationTokens(results);
        callback(true, tokens);
      },
      (e) => {
        const default_error: string =
          "An error occurred while logging in with apple.";
        const error: any = e.error_description || default_error;
        return callback(false, error);
      },
      { id_token },
      this.getUnauthenticatedHeaders(),
    );
  }

  /**
   * @name loginWithFacebook
   * @description Logs in a user with facebook.
   * @param payload
   * @param callback
   */
  loginWithFacebook(
    payload: Object,
    callback: (
      is_successful: boolean,
      tokens_or_error: AuthenticationTokens | string,
    ) => void,
  ) {
    this.postAPIRequest(
      URL_FACEBOOK_AUTHENTICATION,
      (results) => {
        const tokens: any = new AuthenticationTokens(results);
        callback(true, tokens);
      },
      (e) => {
        const default_error: string =
          "An error occurred while logging in with facebook.";
        const error: any = e.error_description || default_error;
        return callback(false, error);
      },
      payload,
      this.getUnauthenticatedHeaders(),
    );
  }
}

/**
 * Api actions for user management:
 *  - Fetch user details
 */
export class UserApi extends PrivateApi {
  retrieveAffiliateDetails(
    callback: (
      is_successful: boolean,
      affiliate_or_error: Affiliate | string,
    ) => void,
  ) {
    this.getAPIRequest(
      URL_AFFILIATE,
      (success_response) => {
        if (success_response) {
          const affiliate: any = new Affiliate(success_response);
          return callback(true, affiliate);
        }
      },
      (error_response) => {
        const default_error: string = "Something went wrong";
        const error: any = error_response.error_description || default_error;
        return callback(false, error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  // retrieve user profile
  retrieveUserProfile(
    callback: (is_successful: boolean, profile_or_error: User | string) => void,
  ) {
    this.getAPIRequest(
      URL_USER_PROFILE,
      (success_response) => {
        if (success_response) {
          const user: any = new User(success_response);
          return callback(true, user);
        }
      },
      (error_response) => {
        const default_error: string = "Something went wrong";
        const error: any = error_response.error_description || default_error;
        return callback(false, error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name getUser
   * @description Retrieves a user
   * @returns {Object} An object containing the user data, error, and loading status.
   */
  getUser() {
    const { data, error, isLoading } = this.getRequest(URL_USER_PROFILE);
    const user = new User(data);
    return { user, error, isLoading };
  }

  /**
   * @name retrieveFacebookBusinesses
   * @description Retrieves a list of facebook businesses
   * @param callback
   */
  retrieveFacebookBusinesses(
    callback: (
      is_successful: boolean,
      businesses_or_error: [] | string,
    ) => void,
  ) {
    this.getAPIRequest(
      URL_USER_FACEBOOK_BUSINESSES,
      (success_response) => {
        const facebook_business: any = modelObjectsFromArray(
          FacebookBusiness,
          success_response,
        );
        return callback(true, facebook_business);
      },
      (error_response) => {
        return callback(false, error_response);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  // update profile
  updateProfile(
    user: User,
    callback: (is_successful: boolean, profile_or_error: User | string) => void,
  ) {
    this.postAPIRequest(
      `${URL_USER_PROFILES}${user.id}/`,
      (results) => {
        const updated_user: any = new User(results);
        callback(true, updated_user);
      },
      (response) => {
        const default_error: string = "Error updating profile";
        const error: any = response.detail || default_error;
        return callback(false, error);
      },
      {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        address: user.address,
        phone: user.phone,
        city: user.city,
        country: user.country,
        state: user.state,
        postcode: user.postcode,
      },
      this.getAuthenticatedHeaders(),
      "PATCH",
    );
  }

  // login to spree
  spreeLogin(
    user: User,
    shop_admin_url: string,
    callback: (is_successful: boolean, profile_or_error: User | string) => void,
  ) {
    this.postAPIRequest(
      `${shop_admin_url}${URL_SPREE_LOGIN_PATH}`,
      (results) => {
        const updated_user: any = new User(results);
        callback(true, updated_user);
      },
      (response) => {
        const default_error: string = "Error creating spree session";
        const error: any = response.detail || default_error;
        return callback(false, error);
      },
      {
        email: user.email,
        password: user.spree_password,
      },
      this.getUnauthenticatedHeaders(),
      "POST",
    );
  }

  // clear session data and redirect to login page
  clearSessionData = (props: object) => {
    window.localStorage.clear();
    navigateToPage(PATH_LOGIN, props);
  };

  // logout from current session
  logout(props: object) {
    const privateApi: any = new PrivateApi();
    privateApi.postAPIRequest(
      URL_USER_LOGOUT,
      () => {
        this.clearSessionData(props);
      },
      () => {
        this.clearSessionData(props);
      },
      {},
      privateApi.getAuthenticatedHeaders(),
    );
  }

  /**
   * @name loginWithFacebook
   * @description Logs in a user with facebook.
   * @param payload
   * @param callback
   */
  loginWithFacebook(
    payload: Object,
    callback: (
      is_successful: boolean,
      tokens_or_error: AuthenticationTokens | string,
    ) => void,
  ) {
    this.postAPIRequest(
      URL_FACEBOOK_AUTHENTICATION,
      (results) => {
        const tokens: any = new AuthenticationTokens(results);
        callback(true, tokens);
      },
      (e) => {
        const default_error: string =
          "An error occurred while logging in with facebook.";
        const error: any = e.error_description || default_error;
        return callback(false, error);
      },
      payload,
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * Retrieves the Studio SSO URL.
   * @returns {Object} An object containing the Studio SSO data, error, and loading status.
   */
  getStudioSSO() {
    const { data, error, isLoading } = this.getRequest(URL_USER_PLASMIC_SSO);
    const plasmicSSO = new StudioSSO(data);
    return { studioSSO: plasmicSSO, error, isLoading };
  }
}
