import { init as initApm } from "@elastic/apm-rum";
import {
  APP_NAME,
  APP_VERSION,
  RUM_URL,
} from "modules/core/constants/Constants";

const apm: any = initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: APP_NAME,
  // Set the version of your application
  serviceVersion: APP_VERSION,
  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: RUM_URL,
});

export default apm;
