import PropTypes from "prop-types";
import { useState } from "react";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import BaseChart from "modules/marketing/components/dashboard/BaseChart";
import WidgetMenu from "modules/marketing/components/dashboard/WidgetMenu";
import { WidgetData } from "modules/marketing/MarketingModels";

const Widget = ({
  widget,
  widgets,
  isLoading,
  error,
  data,
  onSelectWidget,
  assets,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleWidgetSelect = (widgetName) => {
    onSelectWidget(widgetName);
    setShowMenu(false);
  };

  const assetsData = assets || [];
  const sortedAssets = [...assetsData].sort(
    (a, b) => b.downloadCount - a.downloadCount,
  );
  const top5Assets = sortedAssets.slice(0, 4);

  const columns = [
    {
      accessorKey: "fileName",
      header: "File Name",
      cell: (info) => {
        const value = info.getValue();
        return value !== null ? value : "";
      },
    },
    {
      accessorKey: "numberOfDownloads",
      header: "Number of Downloads",
      cell: (info) => {
        const value = info.getValue();
        return value !== null ? value : "";
      },
    },
  ];

  const tableData = top5Assets.map((asset) => ({
    fileName: asset.title,
    numberOfDownloads: asset.downloadCount,
  }));

  return (
    <div className="card px-0 w-100 position-relative h-100">
      <div className="card-header d-flex justify-content-between align-items-center w-100 bg-dark text-white">
        <h5
          className="card-title mb-0"
          title={widgets.find(({ value }) => value === widget)?.tooltip || ""}
        >
          {widgets.find(({ value }) => value === widget)?.label}
        </h5>

        <WidgetMenu
          widgets={widgets}
          handleWidgetSelect={handleWidgetSelect}
          showMenu={showMenu}
          toggleMenu={toggleMenu}
        />
      </div>
      <div className="card-body">
        {isLoading && (
          <div className="rounded-3 h-stat-card shadow-sm skeleton-loading" />
        )}
        {!isLoading && !error && (
          <>
            {assets ? (
              <DataTable
                columns={columns}
                data={tableData}
                isLoading={isLoading}
              />
            ) : (
              <BaseChart chartData={data} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

Widget.defaultProps = {
  widget: "",
  widgets: [],
  isLoading: false,
  error: "",
  data: {},
  onSelectWidget: () => {},
};

Widget.propTypes = {
  widget: PropTypes.string,
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  data: PropTypes.shape(WidgetData),
  onSelectWidget: PropTypes.func,
};

export default Widget;
