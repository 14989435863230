import BillingCards from "modules/billing/components/BillingCards";
import BillingDetails from "modules/billing/components/BillingDetails";
import Payments from "modules/billing/components/Payments";
import BillingWalletBalance from "modules/billing/components/WalletBalance";
import MainNavigationSidebar from "modules/core/components/MainNavigationSidebar";
import SidebarWrapper from "modules/core/components/SidebarWrapper";

function BillingOverview() {
  return (
    <SidebarWrapper sidebar={<MainNavigationSidebar />}>
      <BillingDetails />
      <div className="card shadow mt-3">
        <div className="card-body">
          <BillingCards />
          <BillingWalletBalance />
          <Payments />
        </div>
      </div>
    </SidebarWrapper>
  );
}

export default BillingOverview;
