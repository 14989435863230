import moment from "moment";

export const getCurrentDate = () =>
  moment().startOf("day").format("YYYY-MM-DD");

export const getDateXDaysAgo = (days) => {
  // Check if 'days' is a valid number; if not, default to 1
  if (typeof days !== "number" || Number.isNaN(days)) {
    days = 1;
  }

  return moment().subtract(days, "days").startOf("day").format("YYYY-MM-DD");
};

export const getDateXMonthsAgo = (months) =>
  moment().subtract(months, "months").startOf("day").format("YYYY-MM-DD");

export const getDateXYearsAgo = (years) =>
  moment().subtract(years, "years").startOf("day").format("YYYY-MM-DD");

export const getDefaultDateRange = () => {
  try {
    const today = getCurrentDate();
    const lastMonth = getDateXMonthsAgo(1);
    return { dateFrom: lastMonth, dateTo: today, timeUnit: "d" };
  } catch (err) {
    return { dateFrom: null, dateTo: null, timeUnit: "d" };
  }
};
