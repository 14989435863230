import React from "react";

const rowSelectionContext = React.createContext({
  products: [],
  setProducts(_) {},
});

const RowSelectionProvider = ({ children }) => {
  const [products, setProducts] = React.useState([]);
  return (
    <rowSelectionContext.Provider
      value={{
        products,
        setProducts,
      }}
    >
      {children}
    </rowSelectionContext.Provider>
  );
};

const useRowSelection = () => {
  return React.useContext(rowSelectionContext);
};

export { useRowSelection, RowSelectionProvider };
