import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import EditSegment from "modules/marketing/components/segments/EditSegment";
import { Segment } from "modules/marketing/MarketingModels";

function EditSegmentModal(props) {
  const { row: segment } = props;
  const modalId = `edit-segment-${segment.id}-modal`;
  return (
    <Modal modal_title="Edit Engagement Item" modal_id={modalId} size="md">
      <EditSegment segment={segment} currentModalId={modalId} />
    </Modal>
  );
}

EditSegmentModal.propTypes = {
  row: PropTypes.instanceOf(Segment).isRequired,
};

export default EditSegmentModal;
