import PropTypes from "prop-types";
import { useCallback } from "react";
import SelectInput from "modules/base/components/inputs/SelectInput";
import TextArea from "modules/base/components/inputs/TextArea";
import TextInput from "modules/base/components/inputs/TextInput";
import CountryRegionCityFilterField from "modules/marketing/components/segments/CountryRegionCityFilterField";
import deviceBrands from "modules/marketing/components/segments/data/deviceBrands";
import deviceOses from "modules/marketing/components/segments/data/deviceOses";
import deviceTypes from "modules/marketing/components/segments/data/deviceTypes";
import {
  deviceOperators,
  operators,
} from "modules/marketing/components/segments/data/operator";
import { Field } from "modules/marketing/MarketingModels";

function ContactFilterField({
  contactFields,
  setSelectedField,
  setFilterState,
  onFilterValueChange,
  selectedField,
  filterState,
  currentFilterValue,
}) {
  const fieldsTypesMap = {
    email: TextInput,
    text: TextInput,
    textarea: TextArea,
    tel: TextInput,
    url: TextInput,
    select: SelectInput,
    country: CountryRegionCityFilterField,
    region: CountryRegionCityFilterField,
    city: CountryRegionCityFilterField,
    device_os: SelectInput,
    device_type: SelectInput,
    device_brand: SelectInput,
    file: TextInput,
    date: TextInput,
    time: TextInput,
    datetime: TextInput,
  };
  const fieldOptions = contactFields.map((field) => ({
    value: field.alias,
    label: field.label,
    type: field.type,
  }));
  const onFieldChange = useCallback(
    (selectedOption) => {
      setSelectedField(selectedOption);
      setFilterState((prev) => {
        const newFilter = { ...prev };
        newFilter.field = selectedOption.value;
        newFilter.type = selectedOption.type;
        return newFilter;
      });
    },
    [setFilterState],
  );
  const onOperatorChange = useCallback(
    (selectedOption) => {
      setFilterState((prev) => {
        const newFilter = { ...prev };
        newFilter.operator = selectedOption.value;
        return newFilter;
      });
    },
    [setFilterState],
  );
  const onMultiselectChange = useCallback(
    (selectedOptions) => {
      const filterValue = selectedOptions.map((option) => option.value);
      onFilterValueChange(filterValue);
    },
    [onFilterValueChange],
  );
  const onSingleSelectChange = useCallback(
    (selectedOption) => {
      onFilterValueChange(selectedOption.value);
    },
    [onFilterValueChange],
  );
  const onFilterInputChange = useCallback(
    (e) => {
      onFilterValueChange(e.target.value);
    },
    [onFilterValueChange],
  );
  const FilterField =
    fieldsTypesMap[selectedField?.value] ??
    fieldsTypesMap[selectedField?.type] ??
    TextInput;
  const operatorOptions = ["device_os", "device_type", "device_brand"].includes(
    selectedField?.value,
  )
    ? deviceOperators
    : operators;
  let selectOptions = [];
  const isMultiselect = ["in", "notin"].includes(filterState.operator);
  const isSelect = [
    "device_os",
    "device_type",
    "device_brand",
    "country",
  ].includes(selectedField?.value);
  if (selectedField?.type === "device_os") {
    selectOptions = deviceOses;
  } else if (selectedField?.type === "device_type") {
    selectOptions = deviceTypes;
  } else if (selectedField?.type === "device_brand") {
    selectOptions = deviceBrands;
  }
  let handleFilterChange = onFilterInputChange;
  let predicate = () => null;
  if (isMultiselect) {
    handleFilterChange = onMultiselectChange;
    predicate = (option) => currentFilterValue.includes(option.value);
  } else if (isSelect) {
    handleFilterChange = onSingleSelectChange;
    predicate = (option) => option.value === currentFilterValue;
  }
  let filterField;
  if (["country", "state", "city"].includes(selectedField?.value)) {
    filterField = (
      <FilterField
        currentFilterValue={currentFilterValue}
        onFilterValueChange={onFilterValueChange}
        selectedField={selectedField}
        isMultiselect={isMultiselect}
      />
    );
  } else {
    filterField = (
      <FilterField
        name="value"
        title="What should be filtered?"
        value={currentFilterValue}
        content={currentFilterValue}
        onChange={handleFilterChange}
        controlFunc={handleFilterChange}
        options={selectOptions}
        isMulti={isMultiselect}
        predicate={predicate}
      />
    );
  }
  return (
    <>
      <SelectInput
        name="field"
        title="Which contact field?"
        required
        options={fieldOptions}
        onChange={onFieldChange}
        predicate={(option) => option.value === filterState.field}
      />
      <SelectInput
        name="operator"
        title="How do you want to filter?"
        required
        options={operatorOptions}
        predicate={(option) => option.value === filterState.operator}
        onChange={onOperatorChange}
      />
      {filterField}
    </>
  );
}

ContactFilterField.propTypes = {
  contactFields: PropTypes.arrayOf(PropTypes.instanceOf(Field)).isRequired,
  setSelectedField: PropTypes.func.isRequired,
  setFilterState: PropTypes.func.isRequired,
  onFilterValueChange: PropTypes.func.isRequired,
  selectedField: PropTypes.instanceOf(Object).isRequired,
  filterState: PropTypes.instanceOf(Object).isRequired,
  currentFilterValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default ContactFilterField;
