import PropTypes from "prop-types";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import { closeModalById } from "modules/base/utilities/Actions";
import PropertyFormFields from "modules/marketing/components/dashboard/googleanalytics/PropertyFormFields";
import MarketingAPI from "modules/marketing/MarketingAPI";

function PropertySelectionForm({
  currentModalId,
  match,
  properties,
  showCharts,
}) {
  const { id: siteId } = match.params;

  const API = new MarketingAPI(siteId);

  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedProperty.property_id || !selectedProperty.stream_id) {
      setError("Please select a property before submitting.");
      return;
    }
    setError(null);
    setSuccess(null);
    setIsSubmitting(true);
    API.createSiteAnalyticsProperty(
      selectedProperty,
      () => {
        setIsSubmitting(false);
        closeModalById(currentModalId);
        setSuccess({ message: "Connected Successfully" });
        showCharts();
      },
      (error_response) => {
        setError(error_response?.message);
        setIsSubmitting(false);
      },
    );
  };

  if (properties.length === 0) {
    return (
      <p>
        <strong>
          No properties found in the Google Analytics. Please visit Google
          Analytics and set up the account
        </strong>
      </p>
    );
  }

  return (
    <>
      <p>
        <strong>Select the website you want to import the data</strong>
      </p>
      <Form
        handleSubmit={handleSubmit}
        id="property-selection-form"
        button_color="primary"
        button_label="Connect"
        isSubmitting={isSubmitting}
        fullLengthButton
        padding="p-0"
        submitButtonEnabled={
          selectedProperty.property_id && selectedProperty.stream_id
        }
      >
        <NetworkMessageDisplay error={error} />
        <SuccessActionIndicator success={success} />
        <PropertyFormFields
          properties={properties}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
        />
      </Form>
    </>
  );
}

PropertySelectionForm.defaultProps = {
  properties: [],
};

PropertySelectionForm.propTypes = {
  currentModalId: PropTypes.string.isRequired,
  properties: PropTypes.arrayOf(Object),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  showCharts: PropTypes.func.isRequired,
};

export default withRouter(PropertySelectionForm);
