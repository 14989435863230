import { ApmRoute as Route } from "@elastic/apm-rum-react";
import { Switch } from "react-router-dom";
import Page404 from "../dns/layouts/Page404";
import WebsiteBilling from "./layouts/WebsiteBilling";
import WebsiteCreate from "./layouts/WebsiteCreator";
import WebsiteDomains from "./layouts/WebsiteDomains";
import Editor from "./layouts/WebsiteEditor";
import WebsiteEditorCanvas from "./layouts/WebsiteEditorCanvas";
import WebsiteForms from "./layouts/WebsiteForms";
import WebsiteList from "./layouts/WebsiteList";
import WebsiteSeo from "./layouts/WebsiteSeo";
import WebsiteSettings from "./layouts/WebsiteSettings";
import WebsiteTemplates from "./layouts/WebsiteTemplates";
import {
  PATH_WEBSITES,
  PATH_WEBSITE_BILLING,
  PATH_WEBSITE_DOMAINS,
  PATH_WEBSITE_FORM_SUBMISSIONS,
  PATH_WEBSITE_NEW,
  PATH_WEBSITE_PAGE,
  PATH_WEBSITE_PAGE_CANVAS,
  PATH_WEBSITE_SEO,
  PATH_WEBSITE_SETTINGS,
  PATH_WEBSITE_TEMPLATES,
  PATH_WEBSITE_STUDIO,
} from "./WebsiteConstants";
import WebsiteStudio from "modules/website/layouts/WebsiteStudio";

function WebsiteRouter() {
  return (
    <Switch>
      <Route key={1.1} exact path={PATH_WEBSITES} component={WebsiteList} />
      <Route
        key={1.2}
        exact
        path={PATH_WEBSITE_DOMAINS}
        component={WebsiteDomains}
      />
      <Route
        key={1.3}
        exact
        path={PATH_WEBSITE_BILLING}
        component={WebsiteBilling}
      />
      <Route key={1.4} exact path={PATH_WEBSITE_SEO} component={WebsiteSeo} />
      <Route key={1.5} exact path={PATH_WEBSITE_PAGE} component={Editor} />
      <Route exact path={PATH_WEBSITE_STUDIO} component={WebsiteStudio} />
      <Route
        key={1.6}
        exact
        path={PATH_WEBSITE_PAGE_CANVAS}
        component={WebsiteEditorCanvas}
      />
      <Route
        key={1.7}
        exact
        path={PATH_WEBSITE_TEMPLATES}
        component={WebsiteTemplates}
      />
      <Route
        key={1.8}
        exact
        path={PATH_WEBSITE_NEW}
        component={WebsiteCreate}
      />
      <Route
        key={1.9}
        exact
        path={PATH_WEBSITE_FORM_SUBMISSIONS}
        component={WebsiteForms}
      />
      <Route
        key={2.2}
        exact
        path={PATH_WEBSITE_SETTINGS}
        component={WebsiteSettings}
      />
      <Route key={1.1} path="*" component={Page404} />
    </Switch>
  );
}

export default WebsiteRouter;
