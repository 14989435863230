import countryRegionData from "country-region-data";
import React from "react";
import FormSelectInput from "./FormSelectInput";

export class FormCountryInput extends React.Component {
  render() {
    const { label } = this.props;
    const { placeholder } = this.props;
    const { name } = this.props;
    const { country } = this.props;
    const onChangeCountry: any = this.props.onChangeText;
    const { is_required } = this.props;
    const { is_editable } = this.props;

    const countries: any = countryRegionData.map(
      (country) => country.countryName,
    );

    return (
      <FormSelectInput
        label={label}
        placeholder={placeholder}
        name={name}
        is_required={is_required}
        is_editable={is_editable}
        selected_option={country}
        options={countries}
        onSelectOption={onChangeCountry}
      />
    );
  }
}
