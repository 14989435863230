import PropTypes from "prop-types";

/**
 * A generic text input component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function EmailInput(props) {
  const { title, name, controlFunc, content, placeholder, required } = props;
  const requiredAsterisk = required ? "*" : "";
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {title} {requiredAsterisk}
      </label>
      <input
        className="form-control"
        id={name}
        name={name}
        type="email"
        value={content}
        onChange={controlFunc}
        placeholder={placeholder}
        required={required}
      />
    </div>
  );
}

EmailInput.defaultProps = {
  content: "",
  placeholder: "",
  required: false,
};

EmailInput.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default EmailInput;
