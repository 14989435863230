import Modal from "modules/base/components/Modal";
import CreateSegment from "modules/marketing/components/segments/CreateSegment";

function CreateSegmentModal() {
  return (
    <Modal
      modal_title="Create Segment"
      modal_id="create-segment-modal"
      size="md"
    >
      <CreateSegment currentModalId="create-segment-modal" />
    </Modal>
  );
}

export default CreateSegmentModal;
