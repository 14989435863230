export const FACEBOOK_PATH = "/social-icon/facebook_icon.svg";
export const WHATSAPP_PATH = "/social-icon/whatsApp_icon.png";
export const TWITTER_PATH = "/social-icon/twitter_icon.svg";
export const INSTAGRAM_PATH = "/social-icon/instagram_icon.svg";

export const PAYMENT_COMPLETE = "completed";
export const PAYMENT_VOID = "void";
export const PAYMENT_CHECKOUT = "checkout";
export const PAYMENT_PENDING = "pending";

export const ORDER_CART = "cart";
export const ORDER_CANCELED = "canceled";
export const ORDER_ADDRESS = "address";
export const ORDER_DELIVERY = "delivery";
export const ORDER_CONFIRM = "confirm";
export const ORDER_PAYMENT = "payment";
export const ORDER_COMPLETE = "complete";

export const SHIPMENT_READY = "ready";
export const SHIPMENT_PENDING = "pending";
export const SHIPMENT_SHIPPED = "shipped";
export const SHIPMENT_CANCELED = "canceled";

// Colors
export const PRIMARY_BLACK = "#000000";
export const PRIMARY_YELLOW = "#EBC544";
export const BACKGROUND = "#FFFEFC";

export const WEEKS = ["Week 1", "Week 2", "Week 3", "Week 4"];
export const DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

// Order Table
export const ORDER_NUMBER = "Order Number";
export const ORDER_PRICE = "Price";
export const ORDER_STATE = "State";
export const ORDER_DATE = "Date";

// Chart

export const DAILY = "daily";
export const WEEKLY = "weekly";
export const MONTHLY = "monthly";
export const CURRENT_DAY = "currentDay";

export const DAILY_ORDERS = "Daily Orders";
export const WEEKLY_ORDERS = "Weekly Orders";
export const MONTHLY_ORDERS = "Monthly Orders";
export const CURRENT_DAY_ORDERS = "Current Day Orders";

export const MONTHLY_PAYMENTS = "Monthly Payments";
export const WEEKLY_PAYMENTS = "Weekly Payments";
export const DAILY_PAYMENTS = "Daily Payments";
export const CURRENT_DAY_PAYMENTS = "Current Day Payments";
