import PropTypes from "prop-types";
import React from "react";

export default function ActionProcessing(props) {
  const { processing_label } = props;

  return (
    <div>
      {processing_label}
      <i className="ms-2 spinner-border spinner-border-sm" />
    </div>
  );
}

ActionProcessing.defaultProps = {
  processing_label: "Please wait",
};

ActionProcessing.propTypes = {
  processing_label: PropTypes.string,
};
