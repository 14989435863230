import juice from "juice";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AuthenticationActions } from "../../account/AccountActions";
import { BillingAPI } from "../../billing/BillingAPIs";
import {
  BILLING_PLANS_DATA,
  PAYMENT_TITLE,
  URL_PLANS,
} from "../../billing/BillingConstants";
import { BillingPlan } from "../../billing/BillingModels";
import Editor from "../../core/components/Editor";
import { ErrorModal } from "../../core/components/ErrorModal";
import { PaymentModal } from "../../core/components/PaymentModal";
import { APP_PROTOCOL } from "../../core/constants/Constants";
import {
  appendEditorGoogleFonts,
  deepCompare,
} from "../../core/utilities/commonUtilities";
import { navigateToPage } from "../../core/utilities/navigation/Navigator";
import { NetworkRequests } from "../../core/utilities/network/NetworkRequests";
import {
  fetchUrlData,
  getUrlData,
} from "../../core/utilities/redux/componentActions";
import ComponentLoadingIndicator from "../components/ComponentLoadingIndicator";
import { NewWebsiteModal } from "../components/NewWebsiteModal";
import { GalleryActions } from "../WebsiteActions";
import {
  INTEGRATIONS_DATA,
  PATH_WEBSITE_BILLING,
  PATH_WEBSITE_DOMAINS,
  PATH_WEBSITE_PAGE,
  PATH_WEBSITES,
  UNSPLASH_IMAGES,
  URL_INTEGRATIONS,
  URL_STORAGE,
  URL_UNSPLASH_IMAGES,
} from "../WebsiteConstants";
import PageApi from "modules/website/api/PageApi";
import SiteApi from "modules/website/api/SiteApi";

require("grapesjs-uppy/dist/grapesjs-uppy.min.css");

const UPLOADS_DATA: string = "UPLOADS_DATA";

const authenticationActions: any = new AuthenticationActions();

const siteApi = new SiteApi();

class WebsiteEditor extends React.Component {
  constructor(props) {
    super(props);
    this.user = authenticationActions.retrieveAuthenticatedUser();
    this.resizable = true;
    this.save_icon = "fa fa-floppy-o";
    this.saving_icon = "fa fa-spinner fa-spin";
    this.save_changes_label = "Save changes";
    this.saving_changes_label = "Saving changes";
    this.state = {
      website: null,
      page: null,
      pages: [],
      is_new_website_modal_visible: false,
      editor: null,
      newsletter_mode: false,
      google_fonts: [],
      is_checking_out: false,
      payment_url: null,
      pay_error: null,
      is_loading_website: false,
      loadCanvas: false,
    };
  }

  componentDidMount() {
    this.retrieveWebsite();
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, _) {
    const { params: prevParams } = prevProps.match;
    const {
      match: { params: currentParams },
    } = this.props;

    if (!deepCompare(prevParams, currentParams)) {
      const { is_shop } = this.website ?? {};
      const { id, page_id } = currentParams;
      if (id && page_id) {
        this.handleLoadPageInEditor(id, page_id, is_shop);
      }
    }

    const previous_uploads_data: any = prevProps.uploads_data;
    const { uploads_data } = this.props;
    if (
      !deepCompare(uploads_data, previous_uploads_data) ||
      prevState.editor === null
    ) {
      const { editor } = this.state;
      if (editor) {
        editor.AssetManager.add(uploads_data);
      }
    }
  }

  getUnpaidSubscription = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const subscription_api: any = new BillingAPI();
    subscription_api.get_unpaid_subscription(
      id,
      (is_successful, pay_url_or_error) => {
        this.setState({ is_checking_out: false });
        if (is_successful) {
          if (pay_url_or_error !== null) {
            this.setState({ payment_url: pay_url_or_error });
          } else {
            this.retrieveWebsite();
          }
        } else {
          this.setState({ pay_error: pay_url_or_error });
        }
      },
    );
  };

  retrieveWebsite = () => {
    const {
      match: {
        params: { id, page_id },
      },
    } = this.props;
    this.setState({ payment_url: null });
    fetchUrlData(UPLOADS_DATA, URL_STORAGE, this.props);
    fetchUrlData(INTEGRATIONS_DATA, URL_INTEGRATIONS, this.props);
    fetchUrlData(BILLING_PLANS_DATA, URL_PLANS, this.props);
    this.setState({ is_loading_website: true });
    new SiteApi().retrieveWebsite(
      id,
      (website) => {
        this.setState({ is_loading_website: false });
        this.setWebsite(website);
        fetchUrlData(
          UNSPLASH_IMAGES,
          `${URL_UNSPLASH_IMAGES}?search_string=${website.category}`,
          this.props,
        );
        this.handleLoadPageInEditor(website.id, page_id, website.is_shop);
      },
      (error) => {
        this.handleErrorLoadingWebsiteOrWebpage(error);
      },
    );
  };

  handleErrorLoadingWebsiteOrWebpage = () => {
    // ToDo: Modal, 404 or retry
    // TODO: Restore before live
    // this.openDashboard();
  };

  setWebsite = (value) => this.setState({ website: value });

  setPage = (value) => this.setState({ page: value });

  setPages = (value) => this.setState({ pages: value });

  setLoadCanvas = (value) => this.setState({ loadCanvas: value });

  togglePublishWebsite = (sender, callback) => {
    const { website } = this.state;
    website.is_published = !website.is_published;
    siteApi.updateWebsite(
      website,
      (updated_website) => {
        this.setWebsite(updated_website);
        callback(updated_website, sender);
      },
      (error_response) => {
        const default_error: string =
          "We are unable to publish your site at the moment.";
        const error: any = error_response.detail || default_error;
        callback(error, sender);
      },
    );
  };

  visitSite = () => {
    const { website } = this.state;
    const site_url: string = website.custom_domain || website.olitt_domain;
    window.open(`${APP_PROTOCOL}://${site_url}`, "_blank");
    if (website.time_updated === null) {
      this.siteMakeOld();
    }
  };

  navigateToDomainManagement = () => {
    const { website } = this.state;
    const path: any = PATH_WEBSITE_DOMAINS.replace(":id", website.id);
    navigateToPage(path, this.props);
    if (website.time_updated === null) {
      this.siteMakeOld();
    }
  };

  navigateToWebsiteBilling = () => {
    const { website } = this.state;
    const path: any = PATH_WEBSITE_BILLING.replace(":id", website.id);
    navigateToPage(path, this.props);
    if (website.time_updated === null) {
      this.siteMakeOld();
    }
  };

  openDashboard = () => {
    navigateToPage(PATH_WEBSITES, this.props);
  };

  siteMakeOld = () => {
    const { website } = this.state;
    const site_api: any = new SiteApi();
    site_api.updateWebsiteFields(
      website.id,
      {},
      (is_successful, website_or_error) => {
        if (is_successful) {
          this.setState({ is_new_website_modal_visible: false });
          this.setWebsite(website_or_error);
          if (website.is_shop) {
            this.openDashboard();
          }
        }
      },
    );
  };

  modifyWebPage = (page_content, page_json, callback) => {
    const { website, page } = this.state;
    const pageApi: any = new PageApi(website);
    page.page_content = page_content;
    page.page_json = page_json;
    pageApi.updatePage(
      page,
      (updated_page) => callback(true, updated_page),
      (error_response) => {
        const default_error: string = `We are unable to save your changes at the moment. Kindly try again after some time.`;
        const error: any = error_response.detail || default_error;
        callback(false, error);
      },
    );
  };

  handleLoadPageInEditor = (
    website_id: number,
    page_id: number,
    is_shop: boolean,
  ) => {
    const { website } = this.state;
    const site_api: any = new SiteApi();
    site_api.retrieveWebsitePages(
      website_id,
      (is_successful, pages_or_error) => {
        if (website.time_updated === null) {
          this.setState({ is_new_website_modal_visible: true });
        }
        if (is_successful && pages_or_error.length > 0) {
          this.setPages(pages_or_error);
          let website_page = pages_or_error.find((page) => {
            return page.id.toString() === page_id.toString();
          });
          if (!website_page) {
            website_page = pages_or_error.find((page) => {
              return page.is_start_page;
            });
          }

          if (website_page) {
            if (website_page.page_json === null) {
              website_page.page_json = {};
            }
            this.setPage(website_page);
            if (!is_shop) {
              this.setLoadCanvas(true);
            } else {
              // refresh authentication token
              const accountActions: any = new AuthenticationActions();
              const tokens: any = accountActions.retrieveAuthenticationTokens();
              const headers: { Authorization: string } = {
                Authorization: `Bearer ${tokens.access_token}`,
              };
              const networkRequests: any = new NetworkRequests();
              networkRequests.refreshTokenRequest(() => {}, headers);
            }
          } else {
            const error: string = `Page ${page_id} not found.`;
            this.handleErrorLoadingWebsiteOrWebpage(error);
          }
        } else {
          this.handleErrorLoadingWebsiteOrWebpage(pages_or_error);
        }
      },
    );
  };

  onEditorInit = (editor) => {
    const { website, page, pages } = this.state;

    editor.on("custom:page:select", (_page) => {
      this.changePage(_page);
    });

    editor.on("custom:page:rename", (_page) => {
      siteApi.updatePage(
        _page.id,
        {
          ..._page,
          site: website.id,
        },
        (is_successful, updated_page_or_error) => {
          if (is_successful) {
            this.handleLoadPageInEditor(
              website.id,
              updated_page_or_error.id,
              website.is_shop,
            );
          }
        },
      );
    });

    editor.on("custom:page:clone", (_page) => {
      siteApi.createPage(
        {
          ..._page,
          id: undefined,
          site: website.id,
        },
        (is_successful, updated_page_or_error) => {
          if (is_successful) {
            this.changePage(updated_page_or_error);
          }
        },
      );
    });

    editor.on("custom:page:delete", (_page) => {
      siteApi.deletePage(_page.id, (is_successful) => {
        if (is_successful) {
          if (_page.id === page.id) {
            const startPage = pages.find((p) => p.is_start_page);
            this.changePage(startPage);
          } else {
            this.handleLoadPageInEditor(website.id, page.id, website.is_shop);
          }
        }
      });
    });

    editor.on("custom:page:add", () => {
      siteApi.createPage(
        {
          page_title: "New Page",
          page_json: {},
          is_start_page: false,
          site: website.id,
        },
        (is_successful, updated_page_or_error) => {
          if (is_successful) {
            this.changePage(updated_page_or_error);
          }
        },
      );
    });
  };

  changePage = (_page) => {
    const { website } = this.state;
    let editorPath = PATH_WEBSITE_PAGE.replace(":id", website.id);
    editorPath = editorPath.replace(":page_id", _page.id);
    navigateToPage(editorPath, this.props);
  };

  savePageContent = (
    editor,
    auto_save_enabled,
    callback,
    from_auto_save = false,
  ) => {
    if (auto_save_enabled || !from_auto_save) {
      const { google_fonts } = this.state;
      const htmlData = appendEditorGoogleFonts(editor, google_fonts);
      const cssData = editor.getCss();
      const pageJSON = editor.getProjectData();
      const pageContent = juice(htmlData, { extraCss: cssData });
      this.modifyWebPage(pageContent, pageJSON, (status, message) => {
        callback(status, message);
      });
    }
  };

  render() {
    const {
      website,
      is_new_website_modal_visible,
      is_checking_out,
      is_loading_website,
      pay_error,
      payment_url,
      loadCanvas,
    } = this.state;
    let olitt_domain: string = null;
    let site_domain: string = null;
    let site_title: string = null;
    let is_shop: boolean = false;
    if (website) {
      olitt_domain = website.olitt_domain;
      site_title = website.title;
      site_domain = website.site_domain;
      is_shop = website.is_shop;
    }
    if (is_checking_out) {
      return <ComponentLoadingIndicator text="Loading" />;
    }
    if (is_loading_website) {
      return <ComponentLoadingIndicator text="Loading" />;
    }
    let should_upgrade: boolean = true;
    if (website) {
      const website_plan: any = website.subscription_plan;
      if (website_plan) {
        if (website_plan.pricing.amount > 0) {
          should_upgrade = false;
        }
      }
    }
    const close_modal_button: any = (
      <button
        type="button"
        className="btn btn-olitt-grey px-4"
        data-dismiss="modal"
        onClick={this.handleErrorLoadingWebsiteOrWebpage}
      >
        Close
      </button>
    );
    const onClosePaymentModal: any = () => {
      if (payment_url) {
        this.retrieveWebsite();
      }
    };

    const new_website_modal: any = (
      <NewWebsiteModal
        modal_id="new-site-modal"
        is_visible={is_new_website_modal_visible}
        visitWebsite={this.visitSite}
        olitt_sub_domain={olitt_domain}
        site_domain={site_domain}
        is_shop={is_shop}
        site_title={site_title}
        onClose={this.siteMakeOld}
        connectDomain={this.navigateToDomainManagement}
        should_upgrade={should_upgrade}
        upgradePlan={this.navigateToWebsiteBilling}
      />
    );
    const payment_modal: any = (
      <PaymentModal
        modal_id="payment-modal"
        show={payment_url !== null}
        onClose={onClosePaymentModal}
        payment_url={payment_url}
        title={PAYMENT_TITLE}
      />
    );

    const error_modal: any = (
      <ErrorModal
        modal_id="error-modal"
        show={pay_error !== null}
        onClose={this.handleErrorLoadingWebsiteOrWebpage}
        positive_button={close_modal_button}
        short_desc="Something went wrong while creating your website."
        suggestion={pay_error || ""}
      />
    );

    const { newsletter_mode, page, pages } = this.state;

    const { paid_plugins, paid_plan, unsplash_images } = this.props;

    return (
      <>
        {new_website_modal}
        {payment_modal}
        {error_modal}
        <Editor
          newsletter_mode={newsletter_mode}
          user={this.user}
          handleGoHome={this.openDashboard}
          handleVisitSite={this.visitSite}
          website={website}
          page={page}
          pages={pages}
          handleSavePageContent={this.savePageContent}
          paid_plan={paid_plan}
          paid_plugins={paid_plugins}
          unsplash_images={unsplash_images}
          handleTogglePublishWebsite={this.togglePublishWebsite}
          loadCanvas={loadCanvas}
          onEditorInit={this.onEditorInit}
        />
      </>
    );
  }
}

WebsiteEditor.propTypes = {
  uploads_data: PropTypes.instanceOf(Object).isRequired,
  unsplash_images: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object),
  paid_plugins: PropTypes.instanceOf(Object).isRequired,
  paid_plan: PropTypes.instanceOf(Object).isRequired,
};

WebsiteEditor.defaultProps = {
  match: {},
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  const uploads_results: any = getUrlData(
    dataByUrl,
    sessionVariables,
    UPLOADS_DATA,
  );
  const uploads_data: any = uploads_results.items.map((upload) => {
    const file_url: string = upload.url || upload.external_url;
    const file_name_array: any = file_url.split("/");
    const file_name: string = file_name_array[file_name_array.length - 1];
    return {
      id: upload.id,
      name: file_name,
      caption: "",
      src: file_url,
      category: "library",
    };
  });
  const unsplash_results: any = getUrlData(
    dataByUrl,
    sessionVariables,
    UNSPLASH_IMAGES,
  );
  const unsplash_images: any = GalleryActions.getUnsplashImagesObjects(
    unsplash_results.items,
  );

  const integrations_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    INTEGRATIONS_DATA,
  );
  const paid_plugins: null[] = [];
  if (integrations_data.items) {
    integrations_data.items.forEach((integration_data) => {
      paid_plugins.push(integration_data.component_id);
    });
  }
  const billing_plan_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    BILLING_PLANS_DATA,
  );

  let billing_plans: null[] = [];
  let paid_plan: null[] = [];
  if (billing_plan_data.items.length > 0) {
    billing_plans = billing_plan_data.items.map(
      (parameters) => new BillingPlan(parameters),
    );
    paid_plan =
      billing_plans.find((billing_plan) => {
        return billing_plan.pricing.amount > 0.0;
      }) || [];
  }

  return {
    sessionVariables,
    uploads_data,
    paid_plugins,
    paid_plan,
    unsplash_images,
  };
}

export default connect(mapStateToProps)(withRouter(WebsiteEditor));
