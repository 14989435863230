import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import EditTemplate from "modules/marketing/components/emailstemplates/EditTemplate";
import { EmailTemplate } from "modules/marketing/MarketingModels";

function EditTemplateModal({ row: template }) {
  const modalId = `edit-template-${template.id}-modal`;
  return (
    <Modal modal_title="Edit Template" modal_id={modalId} size="md">
      <EditTemplate template={template} currentModalId={modalId} />
    </Modal>
  );
}

EditTemplateModal.propTypes = {
  row: PropTypes.instanceOf(EmailTemplate).isRequired,
};

export default EditTemplateModal;
