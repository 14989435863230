import { IS_DEVELOPMENT } from "./Constants";

export const SERVER_BASE_URL: any = IS_DEVELOPMENT
  ? "http://localhost:8000"
  : "";
export const API_BASE_URL: any = `${SERVER_BASE_URL}/api`;

export const WS_BASE_URL: any = IS_DEVELOPMENT
  ? "ws://localhost:8000"
  : `wss://${window.location.host}`;

export const FORM_COLLECT_URL: any = `${API_BASE_URL}/contact-form-collect`;

export const SPREE_BASE_URL: any = IS_DEVELOPMENT
  ? "https://www.olitt.shop"
  : "https://www.olitt.shop";

export const IMAGE_SERVER_ENDPOINT: string = "https://images.olitt.net/unsafe";
