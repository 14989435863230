import Cookies from "js-cookie";
import { NetworkRequests } from "../network/NetworkRequests";

export class PublicApi extends NetworkRequests {
  constructor() {
    super();
    this.unauthenticatedHeaders = {
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    };
  }

  getUnauthenticatedHeaders() {
    return this.unauthenticatedHeaders;
  }
}
