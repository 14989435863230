import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import ConfigureSubmitButton from "modules/marketing/components/forms/ConfigureSubmitButton";

function ConfigureSubmitButtonModal(props) {
  const { field, setState } = props;
  return (
    <Modal
      modal_title="Configure Submit Button"
      modal_id={`configure-submit-button-${field.id}-modal`}
      size="md"
    >
      <ConfigureSubmitButton field={field} setState={setState} />
    </Modal>
  );
}

ConfigureSubmitButtonModal.propTypes = {
  field: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
};

export default ConfigureSubmitButtonModal;
