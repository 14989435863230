import { API_BASE_URL } from "../core/constants/Endpoints";
import { PATH_MAIN_ROUTE } from "../core/constants/Routes";

/**
 * API Endpoints
 */
export const URL_AFFILIATE: string = `${API_BASE_URL}/affiliate/`;

/**
 * Routes
 */
export const PATH_REFERRAL: string = `${PATH_MAIN_ROUTE}/referral`;

/**
 * Constants
 */
export const AFFILIATE_DATA: string = "AFFILIATE_DATA";
export const AFFILIATE_LINK_SCRUB: string =
  "https://www.olitt.com/billing/aff.php?aff=";
export const AFFILIATE_SUBJECT: string = "Free website builder";
export const AFFILIATE_MESSAGE: string =
  "Try out this amazing Free website builder. Over 500 pre-built websites to choose from, you can connect your domain absolutely free!";
