/**
 * Order Detail model
 */

export class OrderDetail {
  id: number;

  user: string | null;

  created_by: string | null;

  approver: string | null;

  canceler: string | null;

  ship_address: string | null;

  bill_address: string | null;

  line_items: any[];

  shipments: any[];

  adjustments: any[];

  payments: any[];

  number: string;

  item_total: string;

  total: string;

  state: string;

  adjustment_total: string;

  completed_at: string;

  payment_total: string;

  shipment_state: string;

  payment_state: string;

  email: string;

  special_instructions: string | null;

  currency: string;

  last_ip_address: string;

  shipment_total: string;

  additional_tax_total: string;

  promo_total: string;

  channel: string;

  included_tax_total: string;

  item_count: number;

  approved_at: string;

  confirmation_delivered: boolean;

  canceled_at: string | null;

  state_lock_version: number;

  taxable_adjustment_total: string;

  non_taxable_adjustment_total: string;

  store_owner_notification_delivered: string | null;

  internal_note: string | null;

  display_ship_total: string;

  display_shipment_total: string;

  display_outstanding_balance: string;

  display_item_total: string;

  display_adjustment_total: string;

  display_included_tax_total: string;

  display_additional_tax_total: string;

  display_tax_total: string;

  display_promo_total: string;

  display_total: string;

  display_cart_promo_total: string;

  display_pre_tax_item_amount: string;

  display_pre_tax_total: string;

  display_total_applicable_store_credit: string;

  display_total_applied_store_credit: string;

  display_order_total_after_store_credit: string;

  display_total_available_store_credit: string;

  display_store_credit_remaining_after_capture: string;

  display_order_commission: string;

  display_vendor_total: string;

  display_vendor_ship_total: string;

  display_vendor_subtotal: string;

  display_vendor_included_tax_total: string;

  display_vendor_additional_tax_total: string;

  display_vendor_promo_total: string;

  display_vendor_pre_tax_item_amount: string;

  display_vendor_pre_tax_ship_amount: string;

  display_vendor_pre_tax_total: string;

  display_vendor_commission: string;

  created_at: string;

  updated_at: string;

  public_metadata: any;

  private_metadata: any;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.user = parameters.user;
    this.created_by = parameters.created_by;
    this.approver = parameters.approver;
    this.canceler = parameters.canceler;
    this.ship_address = parameters.ship_address;
    this.bill_address = parameters.bill_address;
    this.line_items = parameters.line_items;
    this.shipments = parameters.shipments;
    this.adjustments = parameters.adjustments;
    this.payments = parameters.payments;
    this.number = parameters.number;
    this.item_total = parameters.item_total;
    this.total = parameters.total;
    this.state = parameters.state;
    this.adjustment_total = parameters.adjustment_total;
    this.completed_at = parameters.completed_at;
    this.payment_total = parameters.payment_total;
    this.shipment_state = parameters.shipment_state;
    this.payment_state = parameters.payment_state;
    this.email = parameters.email;
    this.special_instructions = parameters.special_instructions;
    this.currency = parameters.currency;
    this.last_ip_address = parameters.last_ip_address;
    this.shipment_total = parameters.shipment_total;
    this.additional_tax_total = parameters.additional_tax_total;
    this.promo_total = parameters.promo_total;
    this.channel = parameters.channel;
    this.included_tax_total = parameters.included_tax_total;
    this.item_count = parameters.item_count;
    this.approved_at = parameters.approved_at;
    this.confirmation_delivered = parameters.confirmation_delivered;
    this.canceled_at = parameters.canceled_at;
    this.state_lock_version = parameters.state_lock_version;
    this.taxable_adjustment_total = parameters.taxable_adjustment_total;
    this.non_taxable_adjustment_total = parameters.non_taxable_adjustment_total;
    this.store_owner_notification_delivered =
      parameters.store_owner_notification_delivered;
    this.internal_note = parameters.internal_note;
    this.display_ship_total = parameters.display_ship_total;
    this.display_shipment_total = parameters.display_shipment_total;
    this.display_outstanding_balance = parameters.display_outstanding_balance;
    this.display_item_total = parameters.display_item_total;
    this.display_adjustment_total = parameters.display_adjustment_total;
    this.display_included_tax_total = parameters.display_included_tax_total;
    this.display_additional_tax_total = parameters.display_additional_tax_total;
    this.display_tax_total = parameters.display_tax_total;
    this.display_promo_total = parameters.display_promo_total;
    this.display_total = parameters.display_total;
    this.display_cart_promo_total = parameters.display_cart_promo_total;
    this.display_pre_tax_item_amount = parameters.display_pre_tax_item_amount;
    this.display_pre_tax_total = parameters.display_pre_tax_total;
    this.display_total_applicable_store_credit =
      parameters.display_total_applicable_store_credit;
    this.display_total_applied_store_credit =
      parameters.display_total_applied_store_credit;
    this.display_order_total_after_store_credit =
      parameters.display_order_total_after_store_credit;
    this.display_total_available_store_credit =
      parameters.display_total_available_store_credit;
    this.display_store_credit_remaining_after_capture =
      parameters.display_store_credit_remaining_after_capture;
    this.display_order_commission = parameters.display_order_commission;
    this.display_vendor_total = parameters.display_vendor_total;
    this.display_vendor_ship_total = parameters.display_vendor_ship_total;
    this.display_vendor_subtotal = parameters.display_vendor_subtotal;
    this.display_vendor_included_tax_total =
      parameters.display_vendor_included_tax_total;
    this.display_vendor_additional_tax_total =
      parameters.display_vendor_additional_tax_total;
    this.display_vendor_promo_total = parameters.display_vendor_promo_total;
    this.display_vendor_pre_tax_item_amount =
      parameters.display_vendor_pre_tax_item_amount;
    this.display_vendor_pre_tax_ship_amount =
      parameters.display_vendor_pre_tax_ship_amount;
    this.display_vendor_pre_tax_total = parameters.display_vendor_pre_tax_total;
    this.display_vendor_commission = parameters.display_vendor_commission;
    this.created_at = parameters.created_at;
    this.updated_at = parameters.updated_at;
    this.public_metadata = parameters.public_metadata;
    this.private_metadata = parameters.private_metadata;
  }
}
