import { PrivateApi } from "modules/core/utilities/api/PrivateApi";
import { URL_WEBPAGES } from "modules/website/WebsiteConstants";
import { Page, Site } from "modules/website/WebsiteModels";

export default class PageApi extends PrivateApi {
  site: Site;

  constructor(site: Site) {
    super();
    this.site = site;
  }

  createPage(
    start_page: boolean,
    title: string,
    content: string,
    onSuccess: (page: Page) => void,
    onFailure: (error: any) => void,
  ) {
    this.postAPIRequest(
      URL_WEBPAGES,
      (response) => {
        const page: any = new Page(response);
        onSuccess(page);
      },
      (error) => {
        onFailure(error);
      },
      {
        site: this.site.id,
        is_start_page: start_page,
        page_title: title,
        page_content: content,
      },
      this.getAuthenticatedHeaders(),
    );
  }

  updatePage(
    webPage: Page,
    onSuccess: (page: Page) => void,
    onFailure: (error: any) => void,
  ) {
    this.postAPIRequest(
      `${URL_WEBPAGES}${webPage.id}/`,
      (response: any) => {
        const page: any = new Page(response);
        onSuccess(page);
      },
      (error) => onFailure(error),
      {
        site: this.site.id,
        is_start_page: webPage.is_start_page,
        page_title: webPage.page_title,
        page_content: webPage.page_content,
        page_json: webPage.page_json,
      },
      this.getAuthenticatedHeaders(),
      "PUT",
    );
  }
}
