import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import { closeModalById } from "modules/base/utilities/Actions";
import ContentFormFields from "modules/marketing/components/content/ContentFormFields";
import MarketingAPI from "modules/marketing/MarketingAPI";

function CreateContentForm({ currentModalId, match }) {
  const { id: siteId } = match.params;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [state, setState] = useState({
    title: "",
    content: "",
    channel: "",
    auto_generate_content: true,
  });
  const API = new MarketingAPI(siteId);
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      API.createContent(
        state,
        (response) => {
          setIsSubmitting(false);
          setError(null);
          setState(response);
          closeModalById(currentModalId);
          setSuccess({
            message: "Content created successfully",
          });
        },
        (error_response) => {
          setIsSubmitting(false);
          setError({
            message: error_response.message,
          });
          setSuccess(null);
        },
      );
    },
    [state],
  );
  const onInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }, []);
  return (
    <Form
      handleSubmit={handleSubmit}
      id="create-content-form"
      button_color="gray-900"
      button_label="Create"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      <ContentFormFields
        state={state}
        setState={setState}
        onInputChange={onInputChange}
      />
    </Form>
  );
}

CreateContentForm.defaultProps = {
  currentModalId: "",
};

CreateContentForm.propTypes = {
  currentModalId: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(CreateContentForm);
