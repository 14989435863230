import PropTypes from "prop-types";
import { memo } from "react";
import Modal from "modules/base/components/Modal";
import PlanUpgrade from "modules/billing/components/PlanUpgrade";

const BillingPlansModal = memo(function BillingPlansModal(props) {
  const { modal_id, title, handleSelectedPlan } = props;
  return (
    <Modal modal_title={title} modal_id={modal_id} size="xl">
      <PlanUpgrade
        handleSelectedPlan={handleSelectedPlan}
        submitFormButtonLabel="Create"
        parentModalId={modal_id}
      />
    </Modal>
  );
});

BillingPlansModal.defaultProps = {
  modal_id: "plans-select-modal",
  title: "Select a plan",
  handleSelectedPlan: () => {},
};

BillingPlansModal.propTypes = {
  modal_id: PropTypes.string,
  title: PropTypes.string,
  handleSelectedPlan: PropTypes.func,
};

export default BillingPlansModal;
