import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import DesignEmailTemplate from "modules/marketing/components/emailstemplates/DesignEmailTemplate";

function DesignEmailTemplateModal({ state, setState, site }) {
  const modalId = `design-email-template-${state.id ?? "new"}-modal`;
  return (
    <Modal modal_title="Design Template" modal_id={modalId} size="xl">
      <DesignEmailTemplate
        state={state}
        setState={setState}
        currentModalId={modalId}
        site={site}
      />
    </Modal>
  );
}

DesignEmailTemplateModal.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  site: PropTypes.instanceOf(Object).isRequired,
};

export default DesignEmailTemplateModal;
