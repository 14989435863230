import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useTemplateType } from "modules/marketing/components/Focusitems/TemplatesContext";
import { typeOptions } from "modules/marketing/components/Focusitems/utils";
import WebsiteCard from "modules/website/components/WebsiteCard";

function TemplatesList({ templates, onSelectTemplate }) {
  const { templateType } = useTemplateType();

  const filterTemplates = useCallback((prebuiltTemplates, type) => {
    if (type === undefined || type === null) return prebuiltTemplates;
    return prebuiltTemplates.filter((template) => {
      const lowerCaseTemplateCategory = template.category.toLowerCase();
      return typeOptions.find(
        (t) =>
          t.value === type &&
          (lowerCaseTemplateCategory === t.label.toLowerCase() ||
            lowerCaseTemplateCategory === "blank"),
      );
    });
  }, []);

  const filteredTemplates = useMemo(
    () => filterTemplates(templates, templateType),
    [templates, templateType, filterTemplates],
  );

  const prebuiltTemplatesList = useMemo(() => {
    return filteredTemplates.map((template) => {
      let isPremium = false;
      const templateSubscriptionPlan = template.subscription_plan;
      if (templateSubscriptionPlan.pricing.amount > 0) {
        isPremium = true;
      }
      const overImageAction = {
        className: "btn btn-outline-primary mx-md-auto",
        onClick: () => onSelectTemplate(template),
        text: `Create from ${template.name}`,
        icon: "plus-lg",
      };

      return (
        <WebsiteCard
          key={template.id}
          website={template}
          over_image_action={overImageAction}
          showPreview
          view_mode="templates"
          is_premium={isPremium}
          image_width={800}
          is_share_site_visible={false}
        />
      );
    });
  }, [filteredTemplates, onSelectTemplate]);

  return (
    <div className="row row-cols-md-2 row-cols-1 ms-2" key={templateType}>
      {prebuiltTemplatesList.length === 0 ? (
        <p>No templates available</p>
      ) : (
        prebuiltTemplatesList
      )}
    </div>
  );
}

TemplatesList.defaultProps = {
  templates: [],
  onSelectTemplate: () => {},
};

TemplatesList.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onSelectTemplate: PropTypes.func,
};

export default TemplatesList;
