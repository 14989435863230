import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DataTable from "../../../../core/components/datagrid/DataTable";
import { ErrorModal } from "../../../../core/components/ErrorModal";
import { getUrlData } from "../../../../core/utilities/redux/componentActions";
import useProducts from "../../../hooks/useProducts";
import { ON_DEMAND_RRODUCTS_TABLE } from "../../../ShopConstants";

function OnDemandProducts(props) {
  const { match } = props;
  const { params } = match;
  const site_id = params.id;
  const { products, isLoading, error } = useProducts(site_id);
  const [setError] = React.useState(null);

  const numberOfProducts = process.env.ON_DEMAND_RRODUCTS_TABLE || 4;

  if (isLoading) {
    return (
      <div className="rounded-3 vh-30 flex-grow-1 skeleton-loading shadow" />
    );
  }

  return (
    <>
      <ErrorModal
        show={error !== null}
        onClose={() => setError(null)}
        short_desc={error ? error.short_desc : ""}
        suggestion={error ? error.suggestion : ""}
      />
      <div className="rounded-3 p-4 h-100 w-100 bg-white shadow">
        <div>
          <h3>
            <b>Products</b>
          </h3>
          <div className="overflow-scroll">
            <DataTable
              data={products.slice(0, numberOfProducts)}
              columns={ON_DEMAND_RRODUCTS_TABLE}
              rowSize={numberOfProducts}
            />
          </div>
        </div>
      </div>
    </>
  );
}

OnDemandProducts.defaultProps = {};

OnDemandProducts.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const shop_details_data = getUrlData(dataByUrl, sessionVariables);
  return {
    isLoadingShop: shop_details_data.isFetching,
    shop: shop_details_data.items,
  };
}

export default connect(mapStateToProps)(withRouter(OnDemandProducts));
