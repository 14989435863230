import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUrlData } from "../../../../../core/utilities/redux/componentActions";
import useOrders from "../../../../hooks/useOrders";
import {
  ORDER_ADDRESS,
  ORDER_CART,
  ORDER_COMPLETE,
  ORDER_CONFIRM,
  ORDER_DELIVERY,
  ORDER_PAYMENT,
} from "../../../constants";
import stateCount from "./stateCounter";
import TopCard from "./TopCard";

const SHOP_DETAILS_DATA_KEY = "SHOP_DETAILS_DATA_KEY";

/**
 * @name getAbandonded
 * @description Get States that are abandoded from payments that have not been completed for more than 7 days.
 * @param arr
 * @returns {day, total}
 * @constructor
 */

function getAbandonded(orderResponse) {
  const abandonedStates = [
    ORDER_CART,
    ORDER_ADDRESS,
    ORDER_DELIVERY,
    ORDER_PAYMENT,
    ORDER_CONFIRM,
  ];

  let abandonedCount = 0;
  for (let i = 0; i < orderResponse.length; i += 1) {
    const order = orderResponse[i];
    if (
      abandonedStates.includes(order.state) &&
      moment(order.created_at) < moment().subtract(7, "days")
    ) {
      abandonedCount += 1;
    }
  }
  return abandonedCount;
}

function OrderCard(props) {
  const { match } = props;
  const { params } = match;
  const site_id = params.id;
  const site = site_id.toString();
  const { isLoading, orders } = useOrders(site);

  const adandonded = getAbandonded(orders);
  const completed = stateCount(orders, ORDER_COMPLETE);

  if (isLoading) {
    return <div className="rounded-3 p-4 h-44r shadow-sm skeleton-loading" />;
  }

  return (
    <TopCard
      title="Total Completed Orders"
      count={completed}
      itemsLabel="Orders abandoned"
      itemCount={adandonded}
      backgroundColor="white"
    />
  );
}

OrderCard.defaultProps = {};

OrderCard.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

/**
 * @name mapStateToProps
 * Maps the state to the props.
 * @param state
 * @returns {{shop, isLoadingShop: (boolean|*)}}
 */
function mapStateToProps(state: any) {
  const { sessionVariables, dataByUrl } = state;
  const shop_details_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    SHOP_DETAILS_DATA_KEY,
  );
  return {
    isLoadingShop: shop_details_data.isFetching,
    shop: shop_details_data.items,
  };
}

export default connect(mapStateToProps)(withRouter(OrderCard));
