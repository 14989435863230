import { ApmRoute as Route } from "@elastic/apm-rum-react";
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import {
  PATH_DOMAINS,
  PATH_NAMESERVERS,
  PATH_SEARCH,
} from "./DomainsConstants";
import Domains404Controller from "./layouts/Domains404Controller";
import DomainSearchController from "./layouts/DomainSearchController";
import NameserverList from "./layouts/NameserverList";

function DomainsRouter() {
  return (
    <Switch>
      <Route
        key={2.1}
        exact
        path={PATH_SEARCH}
        component={DomainSearchController}
      />
      <Redirect key={2.2} exact from={PATH_DOMAINS} to={PATH_SEARCH} />
      <Route
        key={2.3}
        exact
        path={PATH_NAMESERVERS}
        component={NameserverList}
      />
      <Route key={2.4} path="*" component={Domains404Controller} />
    </Switch>
  );
}

export default DomainsRouter;
