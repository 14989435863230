import PropTypes from "prop-types";
import React, { useState } from "react";
import { Icon } from "../../base/components/Icons";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import { FormEmailInput } from "../../core/components/form-controls/FormEmailInput";
import { AuthenticationApi } from "../AccountAPIs";

export function ForgotPassword({ onSuccess }) {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [banner, setBanner] = useState(null);
  const [is_requesting_password_reset, setIsRequestingPasswordReset] =
    useState(false);

  const authenticationApi: any = new AuthenticationApi();

  function requestPasswordReset(event) {
    event.preventDefault();
    setIsRequestingPasswordReset(true);
    setError(null);
    authenticationApi.forgotPassword(
      email,
      (is_successful, message_or_error) => {
        setIsRequestingPasswordReset(false);
        if (is_successful) {
          setBanner(message_or_error);
        } else {
          setError(message_or_error);
        }
      },
    );
  }

  let reset_btn_text;
  if (is_requesting_password_reset) {
    reset_btn_text = <ComponentLoadingIndicator />;
  } else {
    reset_btn_text = (
      <span className="text-uppercase"> Request Password Reset </span>
    );
  }

  return (
    <div className="w-100">
      <div
        role="alert"
        className="alert alert-danger alert-dismissible fade show"
        hidden={!error}
      >
        <small> {error} </small>
        <button type="button" className="close" onClick={() => setError(null)}>
          <Icon icon="x" />
        </button>
      </div>

      <div
        role="alert"
        className="alert alert-success alert-dismissible fade show"
        hidden={error || !banner}
      >
        <small> {banner} </small>
        <button type="button" className="close" onClick={onSuccess}>
          <Icon icon="x" />
        </button>
      </div>

      <form onSubmit={requestPasswordReset} className="row g-3">
        <FormEmailInput
          name="email"
          placeholder="Enter your email address"
          text={email}
          is_required
          is_editable={!is_requesting_password_reset}
          onChangeText={(e) => setEmail(e.target.value)}
        />

        <div className="col">
          <button
            type="submit"
            className="btn btn-olitt-grey w-100 d-flex align-items-center shadow-none"
            disabled={is_requesting_password_reset}
          >
            <div className="d-flex w-100 align-items-center justify-content-center">
              {reset_btn_text}
            </div>
          </button>
        </div>
      </form>
    </div>
  );
}

ForgotPassword.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
