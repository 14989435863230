import React from "react";
import TopMenu from "../../core/components/TopMenu";
import GenerateTemplate from "../components/GenerateTemplate";

export default function TemplateGenerator() {
  return (
    <>
      <TopMenu />
      <div className="d-flex align-items-center justify-content-center mt-4">
        <GenerateTemplate />
      </div>
    </>
  );
}
