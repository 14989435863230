import StageList from "modules/marketing/components/stages/StagesList";
import SubscriptionCheck from "modules/marketing/layouts/SubscriptionCheck";
import { MARKETING_STAGES_SLUG } from "modules/marketing/MarketingConstants";

const Stages = () => {
  return (
    <SubscriptionCheck slug={MARKETING_STAGES_SLUG} component={<StageList />} />
  );
};

export default Stages;
