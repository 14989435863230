import { PrivateApi } from "../core/utilities/api/PrivateApi";
import { PublicApi } from "../core/utilities/api/PublicApi";
import { URL_CMS_CONTENT, URL_PRIVACY_POLICY_TEMPLATE } from "./CmsConstants";
import { CMSContent, TemplateContent } from "./CmsModels";

export class GenerateContentAPI extends PrivateApi {
  generateContent(
    parameters: Object,
    callback: (
      is_successful: boolean,
      content_or_error: CMSContent | string,
    ) => void,
  ) {
    this.postAPIRequest(
      URL_CMS_CONTENT,
      (success_response) => {
        callback(true, success_response);
      },
      (error_response) => {
        callback(false, this.extractResponseError(error_response));
      },
      parameters,
      this.getAuthenticatedHeaders(),
    );
  }
}

export class GenerateTemplateAPI extends PublicApi {
  generateTemplate(
    parameters: Object,
    callback: (
      is_successful: boolean,
      template_content_or_error: TemplateContent | string,
    ) => void,
  ) {
    this.postAPIRequest(
      URL_PRIVACY_POLICY_TEMPLATE,
      (success_response) => {
        callback(true, success_response);
      },
      (error_response) => {
        callback(false, this.extractResponseError(error_response));
      },
      parameters,
      this.getUnauthenticatedHeaders(),
    );
  }
}
