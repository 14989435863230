import PropTypes from "prop-types";
import { useCallback } from "react";
import { withRouter } from "react-router-dom";
import ActionInput from "modules/base/components/ActionInput";
import SelectInput from "modules/base/components/inputs/SelectInput";
import TextArea from "modules/base/components/inputs/TextArea";
import TextInput from "modules/base/components/inputs/TextInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import { openModal } from "modules/base/utilities/Actions";
import ConfigureFormActions from "modules/marketing/components/forms/ConfigureFormActions";
import ConfigureFormFieldModal from "modules/marketing/components/forms/ConfigureFormFieldModal";
import ConfigureSubmitButtonModal from "modules/marketing/components/forms/ConfigureSubmitButtonModal";
import MarketingAPI from "modules/marketing/MarketingAPI";
import { NEW_FORM_FIELD_PREFIX } from "modules/marketing/MarketingConstants";
import { Field } from "modules/marketing/MarketingModels";

function FormField(props) {
  const {
    field,
    state,
    setState,
    contactFields,
    currentModalId,
    onClickMoveUp,
    onClickMoveDown,
    siteId,
  } = props;
  const { id, alias, type, label, isRequired, defaultValue } = field;
  const API = new MarketingAPI(siteId);
  const fieldsTypesMap = {
    email: TextInput,
    text: TextInput,
    textarea: TextArea,
    tel: TextInput,
    url: TextInput,
    select: SelectInput,
    country: SelectInput,
    file: TextInput,
    date: TextInput,
    time: TextInput,
    datetime: TextInput,
    button: (
      <div className="col-12 d-grid flex-fill">
        <button type="button" className="btn btn-secondary" disabled>
          {label}
        </button>
      </div>
    ),
    captcha: TextInput,
  };
  const textInputTypesMap = {
    email: "email",
    text: "text",
    phone: "text",
    url: "url",
    file: "file",
  };
  const onClickRemove = useCallback((formId, onSuccess, onError) => {
    setState((prevState) => {
      const { fields } = prevState;
      const newFields = fields.filter((f) => f.id !== id);
      return { ...prevState, fields: newFields };
    });
    if (typeof field.id === "string" && id.includes(NEW_FORM_FIELD_PREFIX)) {
      onSuccess();
      return;
    }
    API.deleteFormFields(
      state.id,
      [id],
      () => {
        onSuccess();
      },
      () => {
        onError();
      },
    );
  });
  const onClickEdit = useCallback(() => {
    openModal(`configure-form-field-${id}-modal`, currentModalId);
  });
  const onClickEditSubmitButton = useCallback(() => {
    openModal(`configure-submit-button-${id}-modal`, currentModalId);
  });
  const removeButton = (
    <ActionInput
      label=""
      icon="trash-fill"
      subject_id={id}
      showConfirm
      confirm={{
        title: "Delete Field",
        message: "Are you sure you want to delete this field?",
        confirm_button_label: "Delete",
        confirm_button_color: "danger",
        action_done_message: "Field deleted",
        action_done_title: "Success",
      }}
      button_classes="btn btn-outline-danger align-self-end mx-2"
      action_type="button"
      onClick={onClickRemove}
      isButtonDisabled={type === "captcha"}
    />
  );
  const editButton = (
    <button
      type="button"
      className="btn btn-outline-primary align-self-end mx-2"
      onClick={onClickEdit}
      disabled={type === "captcha"}
    >
      <i className="bi bi-pencil-fill" />
    </button>
  );
  const editSubmitButton = (
    <button
      type="button"
      className="btn btn-outline-primary align-self-end ms-2"
      onClick={onClickEditSubmitButton}
    >
      <i className="bi bi-pencil-fill" />
    </button>
  );
  const InputField = fieldsTypesMap[type];
  const inputType = textInputTypesMap[type] ?? "text";
  const handleMoveUp = useCallback(() => {
    onClickMoveUp(id);
  });
  const handleMoveDown = useCallback(() => {
    onClickMoveDown(id);
  });
  let isMoveUpDisabled = type === "captcha";
  let isMoveDownDisabled = type === "captcha";
  if (!isMoveUpDisabled) {
    const fieldIndex = state.fields.findIndex((f) => f.id === id);
    isMoveUpDisabled = fieldIndex === 0;
  }
  if (!isMoveDownDisabled) {
    const fieldIndex = state.fields.findIndex((f) => f.id === id);
    const nextField = state.fields[fieldIndex + 1];
    if (nextField) {
      isMoveDownDisabled = ["button", "captcha"].includes(nextField.type);
    }
  }
  const moveUpButton = (
    <button
      type="button"
      className="btn btn-link align-self-end px-1"
      disabled={isMoveUpDisabled}
      onClick={handleMoveUp}
    >
      <i className="bi bi-arrow-up" />
    </button>
  );
  const moveDownButton = (
    <button
      type="button"
      className="btn btn-link align-self-end px-1"
      disabled={isMoveDownDisabled}
      onClick={handleMoveDown}
    >
      <i className="bi bi-arrow-down" />
    </button>
  );
  if (type === "button") {
    return (
      <div className="d-flex">
        <ConfigureSubmitButtonModal field={field} setState={setState} />
        {InputField}
        {editSubmitButton}
      </div>
    );
  }
  return (
    <div className="d-flex">
      {moveUpButton}
      {moveDownButton}
      <ConfigureFormFieldModal
        field={field}
        setState={setState}
        contactFields={contactFields}
      />
      <InputField
        name={alias}
        title={label}
        content={defaultValue ?? ""}
        controlFunc={setState}
        required={isRequired}
        isDisabled
        wrapperClasses="flex-fill"
        inputType={inputType}
      />
      {editButton}
      {removeButton}
    </div>
  );
}

FormField.propTypes = {
  field: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  contactFields: PropTypes.arrayOf(PropTypes.instanceOf(Field)).isRequired,
  currentModalId: PropTypes.string.isRequired,
  onClickMoveUp: PropTypes.func.isRequired,
  onClickMoveDown: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
};

function FormFields(props) {
  const { state, onInputChange, setState, currentModalId, match } = props;
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const params = {
    start: 0,
    limit: 1000,
  };
  const { fields: contactFields, isLoading, error } = API.getFields(params);
  state.site = siteId;
  const { fields } = state;
  const onAddField = useCallback(() => {
    // when counting the number of fields, we need to exclude the button field
    const newFieldNo = fields.length + 1;
    const newField = {
      id: `${NEW_FORM_FIELD_PREFIX}-${newFieldNo}`,
      type: "text",
      label: `Field ${newFieldNo}`,
      isRequired: false,
      leadField: null,
    };
    setState((prevState) => {
      const { fields: prevFields } = prevState;
      const specialFieldIndex = prevFields.findIndex((field) =>
        ["captcha", "button"].includes(field.type),
      );
      if (specialFieldIndex === -1) {
        return { ...prevState, fields: [...prevFields, newField] };
      }
      const newFields = [...prevFields];
      newFields.splice(specialFieldIndex, 0, newField);
      return { ...prevState, fields: newFields };
    });
  });
  const onSuccessfulSubmitActionChange = useCallback((selectedOption) => {
    const { value } = selectedOption;
    setState((prevState) => {
      const newState = { ...prevState, postAction: value };
      if (value === "message") {
        newState.postActionProperty = "Thank you for submitting the form";
      } else if (value === "redirect") {
        newState.postActionProperty = "";
      }
      return newState;
    });
  });
  const onClickMove = useCallback((fieldId, direction) => {
    setState((prevState) => {
      const { fields: prevFields } = prevState;
      const fieldIndex = prevFields.findIndex((f) => f.id === fieldId);
      if (
        (direction === "up" && fieldIndex === 0) ||
        (direction === "down" && fieldIndex === prevFields.length - 1)
      ) {
        return prevState;
      }
      const newFields = [...prevFields];
      const temp = newFields[fieldIndex];
      let newFieldIndex = fieldIndex - 1;
      if (direction === "down") {
        newFieldIndex = fieldIndex + 1;
      }
      // Swap id and order attributes
      newFields[fieldIndex] = {
        ...newFields[newFieldIndex],
        id: temp.id,
        order: temp.order,
      };
      newFields[newFieldIndex] = {
        ...temp,
        id: newFields[newFieldIndex].id,
        order: newFields[newFieldIndex].order,
      };
      return { ...prevState, fields: newFields };
    });
  });
  const onClickMoveUp = useCallback((fieldId) => {
    onClickMove(fieldId, "up");
  });
  const onClickMoveDown = useCallback((fieldId) => {
    onClickMove(fieldId, "down");
  });
  const successfulSubmitActionOptions = [
    {
      label: "Show message",
      value: "message",
    },
    {
      label: "Redirect to URL",
      value: "redirect",
    },
  ];
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  const fieldList = fields.map((field) => (
    <FormField
      key={field.id}
      field={field}
      setState={setState}
      state={state}
      contactFields={contactFields}
      currentModalId={currentModalId}
      onClickMoveUp={onClickMoveUp}
      onClickMoveDown={onClickMoveDown}
      siteId={siteId}
    />
  ));
  const addFieldButton = (
    <div className="col">
      <button
        type="button"
        className="btn btn-outline-gray-900 btn-sm"
        onClick={onAddField}
      >
        <i className="bi bi-plus-square" /> Add field
      </button>
    </div>
  );
  let postActionPropertyField = null;
  if (state.postAction === "message") {
    postActionPropertyField = (
      <TextInput
        name="postActionProperty"
        title="Message"
        content={state.postActionProperty ?? ""}
        controlFunc={onInputChange}
        required
      />
    );
  } else if (state.postAction === "redirect") {
    postActionPropertyField = (
      <TextInput
        name="postActionProperty"
        title="URL"
        inputType="url"
        content={state.postActionProperty ?? ""}
        controlFunc={onInputChange}
        required
      />
    );
  }
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <div className="mb-3">
        <div className="row row-cols-1 gy-md-3 gy-1">
          <TextInput
            name="name"
            title="Name"
            content={state.name}
            controlFunc={onInputChange}
            required
          />
          <TextArea
            name="description"
            title="Description"
            content={state.description ?? ""}
            controlFunc={onInputChange}
          />
          <SelectInput
            name="postAction"
            title="Action after successful submit"
            required
            onChange={onSuccessfulSubmitActionChange}
            options={successfulSubmitActionOptions}
            predicate={(option) => option.value === state.postAction}
          />
          {postActionPropertyField}
          <div className="col">
            <div className="card">
              <div className="h5 card-header">Form</div>
              <div className="card-body row row-cols-1 gy-md-3 gy-1">
                {fieldList}
                {addFieldButton}
              </div>
            </div>
          </div>
          <ConfigureFormActions
            formState={state}
            setFormState={setState}
            currentModalId={currentModalId}
          />
        </div>
      </div>
    </>
  );
}

FormFields.defaultProps = {
  state: {},
  onInputChange: () => {
    // Do nothing
  },
  currentModalId: null,
};

FormFields.propTypes = {
  state: PropTypes.instanceOf(Object),
  onInputChange: PropTypes.func,
  setState: PropTypes.func.isRequired,
  currentModalId: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
export default withRouter(FormFields);
