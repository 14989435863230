export const tags_to_edit: string[] = [
  "div",
  "nav",
  "section",
  "form",
  "ul",
  "ol",
  "li",
];

export const default_opened_categories: string[] = [
  "Add Section",
  "Social",
  "Integrations",
  "Google",
  "Sell Online",
];
