import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import { BillingWallet } from "modules/base/models/Billing";
import WalletTransactions from "modules/billing/components/WalletTransactions";

function WalletTransactionsModal(props) {
  const { wallet } = props;
  const modalId = "wallet-transactions-modal";
  return (
    <Modal modal_title="Wallet Transactions" modal_id={modalId} size="lg">
      <WalletTransactions wallet={wallet} currentModalId={modalId} />
    </Modal>
  );
}

WalletTransactionsModal.propTypes = {
  wallet: PropTypes.instanceOf(BillingWallet).isRequired,
};

export default WalletTransactionsModal;
