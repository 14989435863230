import { mailchimp_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const tm = editor.Panels.getButton("views", "open-tm");
  const {
    embedded_form_code,
    pop_up_code,
    MailChimpEmbeddedComponent,
    MailChimpPopUpComponent,
  } = opts;

  const mailchimp_placeholder = `<div style="text-align:center;color:#f6a908;height: 50px;">${mailchimp_logo}</div><div style="text-align:center;color:#f6a908;">This is a placeholder and will not be rendered. Toggle visibility in layers</div>`;

  domc.addType("MAILCHIMP-EMBEDDED-COMPONENT", {
    model: {
      defaults: {
        tagName: "div",
        name: "MailChimp Embedded Form",
        draggable: "[data-gjs-type=wrapper]",
        droppable: false,
        stylable: true,
        copyable: false,
        embedded_form_code,
        traits: [
          {
            name: "embedded_form_code",
            label: "Embedded Code",
            placeholder: "</> Paste code here.",
            changeProp: 1,
          },
        ],
        content: `${mailchimp_placeholder}`,
      },
      init() {
        this.on("change:embedded_form_code", () => valuesOnchange());
        const valuesOnchange = () => {
          this.trigger("change:script");
          editor.store();
        };
        this.setStyle({
          height: "fit-content",
          padding: "12px",
        });
        tm.set("active", 1);
      },
      toHTML() {
        const tagName = this.get("tagName");
        const embedded_form_code = this.get("embedded_form_code");
        let code = "";
        if (embedded_form_code === undefined || embedded_form_code === "") {
          this.set("content", mailchimp_placeholder);
        } else {
          code = stripScripts(embedded_form_code);
          this.set("content", code);
          this.set("embedded_form_code", code);
        }
        return `<${tagName} id="${this.getId()}">${code}</${tagName}>`;
      },
      ...MailChimpEmbeddedComponent,
    },
    view: {},
  });

  domc.addType("MAILCHIMP-POPUP-COMPONENT", {
    model: {
      defaults: {
        tagName: "div",
        name: "MailChimp PopUp Form",
        draggable: "[data-gjs-type=wrapper]",
        droppable: false,
        stylable: false,
        copyable: false,
        pop_up_code,
        traits: [
          {
            name: "pop_up_code",
            label: "Pop up code",
            placeholder: "</> Paste code here.",
            changeProp: 1,
          },
        ],
        content: `<div id="mailchimp-popup-container" style="padding: 25px;">${mailchimp_placeholder}</div>`,
      },
      init() {
        this.on("change:pop_up_code", () => valuesOnchange());
        const valuesOnchange = () => {
          this.trigger("change:script");
          editor.store();
        };
        tm.set("active", 1);
      },
      toHTML() {
        const tagName = this.get("tagName");
        const append_script = setComponent(this);
        return `<${tagName} id="${this.getId()}"><script type="text/javascript" id="mcjs">${append_script}</script></${tagName}>`;
      },
      ...MailChimpPopUpComponent,
    },
    view: {},
  });
  const setComponent = (context) => {
    const pop_up_code = context.get("pop_up_code");
    let url = null;
    if (pop_up_code !== undefined) {
      url = getURL(pop_up_code);
    }
    return `!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","${url}");`;
  };

  const getURL = (script) => {
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s][^\");]+)/g;
    return script.match(urlRegex);
  };

  const stripScripts = (code) => {
    const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script\s*>/g;
    while (scriptRegex.test(code)) {
      code = code.replace(scriptRegex, "");
    }
    return code;
  };
};
