import PropTypes from "prop-types";
import React from "react";
import { InputType } from "../constants/Enumerators";
import { FormInput } from "../models/FormInput";
import { objGetAttribute } from "../utilities/commonUtilities";
import ComponentLoadingIndicator from "./ComponentLoadingIndicator";
import FormCheckbox from "./form-controls/FormCheckbox";
import { FormCountryInput } from "./form-controls/FormCountryInput";
import { FormCountryRegionInput } from "./form-controls/FormCountryRegionInput";
import { FormEmailInput } from "./form-controls/FormEmailInput";
import FormFileInput from "./form-controls/FormFileInput";
import { FormNumberInput } from "./form-controls/FormNumberInput";
import { FormPhoneInput } from "./form-controls/FormPhoneInput";
import FormSelectInput from "./form-controls/FormSelectInput";
import { FormTextAreaInput } from "./form-controls/FormTextAreaInput";
import FormTextInput from "./form-controls/FormTextInput";

/**
 * Form component
 * @param {Object} props
 * @returns {React.Component}
 */
export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form_input_values: {},
    };
  }

  /**
   * @function componentDidMount
   * @description React lifecycle method
   */
  componentDidMount() {
    const { inputs } = this.props;
    const form_input_values: {} = {};
    inputs.forEach((form_input) => {
      form_input_values[form_input.name] = form_input.value;
    });
    this.setState({ form_input_values });
  }

  /**
   * @function onChangeFormInputValue
   * @description Event handler for form input value change
   * @param event {Object}
   * @param oninputChange {Function}
   */
  onChangeFormInputValue = (event, oninputChange) => {
    let { value } = event.target;
    const { name } = event.target;
    if (event.target.type === InputType.checkbox) {
      value = event.target.checked;
    } else if (event.target.type === InputType.file) {
      const { files } = event.target;
      if (files.length > 0) {
        [value] = files;
      }
    }
    const { form_input_values } = this.state;
    form_input_values[name] = value;
    oninputChange(value);
    this.setState({ form_input_values });
  };

  /**
   * @function formColumnClasses
   * @description Returns form column classes
   * @param no_of_columns
   * @returns {string}
   */
  formColumnClasses = (no_of_columns): string => {
    let column_classes: string = "";
    switch (no_of_columns) {
      case 2:
        column_classes = `${column_classes} col-md-6`;
        break;
      default:
        column_classes = `${column_classes} col-md-12`;
        break;
    }
    return column_classes;
  };

  /**
   * @function renderInput
   * @description Renders form input
   * @param form_input
   * @param form_input_values
   * @returns {*}
   */
  renderInput = (form_input: FormInput, form_input_values) => {
    let input: any = <div />;
    if (form_input.type === InputType.text) {
      input = (
        <FormTextInput
          name={form_input.name}
          label={form_input.label}
          placeholder={form_input.placeholder}
          text={form_input_values[form_input.name] || form_input.value}
          is_required={form_input.is_required}
          is_editable={form_input.is_editable}
          value={form_input_values[form_input.name] || form_input.value}
          onChangeText={(event) =>
            this.onChangeFormInputValue(event, form_input.oninputChange)
          }
        />
      );
    } else if (form_input.type === InputType.file) {
      input = (
        <FormFileInput
          name={form_input.name}
          label={form_input.label}
          placeholder={form_input.placeholder}
          text={form_input_values[form_input.name] || form_input.value}
          is_required={form_input.is_required}
          is_editable={form_input.is_editable}
          onChangeText={(event) =>
            this.onChangeFormInputValue(event, form_input.oninputChange)
          }
        />
      );
    } else if (form_input.type === InputType.checkbox) {
      input = (
        <FormCheckbox
          name={form_input.name}
          label={form_input.label}
          text={form_input_values[form_input.name] || form_input.value}
          is_required={form_input.is_required}
          is_editable={form_input.is_editable}
          value={objGetAttribute(
            form_input_values,
            form_input.name,
            form_input.value,
          )}
          onChangeText={(event) =>
            this.onChangeFormInputValue(event, form_input.oninputChange)
          }
        />
      );
    } else if (form_input.type === InputType.textarea) {
      input = (
        <FormTextAreaInput
          name={form_input.name}
          label={form_input.label}
          placeholder={form_input.placeholder}
          text={form_input_values[form_input.name] || form_input.value}
          is_required={form_input.is_required || false}
          is_editable={form_input.is_editable || true}
          value={form_input_values[form_input.name] || form_input.value}
          onChangeText={(event) =>
            this.onChangeFormInputValue(event, form_input.oninputChange)
          }
        />
      );
    } else if (form_input.type === InputType.select) {
      input = (
        <FormSelectInput
          name={form_input.name}
          label={form_input.label}
          placeholder={form_input.placeholder}
          text={form_input_values[form_input.name] || form_input.value}
          is_required={form_input.is_required || false}
          is_editable={form_input.is_editable || true}
          isMulti={form_input.selectIsMulti}
          selected_option={
            form_input_values[form_input.name] || form_input.value
          }
          options={form_input.options || []}
          onSelectOption={(event) =>
            this.onChangeFormInputValue(event, form_input.oninputChange)
          }
        />
      );
    } else if (form_input.type === InputType.number) {
      input = (
        <FormNumberInput
          name={form_input.name}
          label={form_input.label}
          placeholder={form_input.placeholder}
          text={form_input_values[form_input.name] || form_input.value}
          is_required={form_input.is_required || false}
          is_editable={form_input.is_editable || true}
          min={form_input.min || null}
          max={form_input.max || null}
          value={form_input_values[form_input.name] || form_input.value}
          onChangeText={(event) =>
            this.onChangeFormInputValue(event, form_input.oninputChange)
          }
        />
      );
    } else if (form_input.type === InputType.email) {
      input = (
        <FormEmailInput
          name={form_input.name}
          label={form_input.label}
          placeholder={form_input.placeholder}
          text={form_input_values[form_input.name] || form_input.value}
          is_required={form_input.is_required || false}
          is_editable={form_input.is_editable || true}
          value={form_input_values[form_input.name] || form_input.value}
          onChangeText={(event) =>
            this.onChangeFormInputValue(event, form_input.oninputChange)
          }
        />
      );
    } else if (form_input.type === InputType.phone) {
      input = (
        <FormPhoneInput
          name={form_input.name}
          label={form_input.label}
          placeholder={form_input.placeholder}
          phone_number={form_input_values[form_input.name] || form_input.value}
          is_required={form_input.is_required || false}
          is_editable={form_input.is_editable || true}
          value={form_input_values[form_input.name] || form_input.value}
          onChangePhonenumber={(event) =>
            this.onChangeFormInputValue(event, form_input.oninputChange)
          }
        />
      );
    } else if (form_input.type === InputType.country) {
      input = (
        <FormCountryInput
          name={form_input.name}
          label={form_input.label}
          placeholder={form_input.placeholder}
          country={form_input_values[form_input.name] || form_input.value}
          is_required={form_input.is_required || false}
          is_editable={form_input.is_editable || true}
          value={form_input_values[form_input.name] || form_input.value}
          onChangeText={(event) =>
            this.onChangeFormInputValue(event, form_input.oninputChange)
          }
        />
      );
    } else if (form_input.type === InputType.country_region) {
      input = (
        <FormCountryRegionInput
          name={form_input.name}
          label={form_input.label}
          placeholder={form_input.placeholder}
          country={form_input_values.country}
          region={form_input_values[form_input.name] || form_input.value}
          is_required={form_input.is_required || false}
          is_editable={form_input.is_editable || true}
          value={form_input_values[form_input.name] || form_input.value}
          onChangeText={(event) =>
            this.onChangeFormInputValue(event, form_input.oninputChange)
          }
        />
      );
    }

    return input;
  };

  render() {
    const { onSubmit } = this.props;
    const {
      error,
      success_message,
      is_submitting,
      isLoading,
      no_of_columns,
      processing_label,
      submit_text,
      inputs,
    } = this.props;
    const { form_classes } = this.props;

    const { form_input_values } = this.state;
    const onSubmitFormInputValues: any = (event) => {
      event.preventDefault();
      onSubmit(form_input_values);
    };

    const renderInputs: any = inputs.map((form_input: FormInput) => {
      return (
        <div
          className={this.formColumnClasses(no_of_columns)}
          key={form_input.name}
        >
          {this.renderInput(form_input, form_input_values)}
        </div>
      );
    });
    if (isLoading) {
      return <ComponentLoadingIndicator />;
    }

    return (
      <form
        onSubmit={onSubmitFormInputValues}
        className={`${form_classes} row g-3`}
      >
        <div
          className="mb-3 alert alert-danger text-center"
          role="alert"
          hidden={!error}
        >
          {error}
        </div>
        <div
          className="mb-3 alert alert-success text-center"
          role="alert"
          hidden={!success_message}
        >
          {success_message}
        </div>

        {renderInputs}
        <div className="col-md-12">
          <button
            type="submit"
            className="btn btn-primary shadow-none mt-2 w-100"
          >
            {is_submitting ? processing_label : submit_text}
            {is_submitting && (
              <i className="ms-2 spinner-border spinner-border-sm" />
            )}
          </button>
        </div>
      </form>
    );
  }
}

Form.defaultProps = {
  onSubmit: () => {},
  is_submitting: false,
  isLoading: false,
  error: "",
  success_message: "",
  submit_text: "Submit",
  no_of_columns: 1,
  inputs: [],
  processing_label: "Please wait",
  form_classes: "",
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  is_submitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  success_message: PropTypes.string,
  submit_text: PropTypes.string,
  no_of_columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputs: PropTypes.instanceOf(Array),
  processing_label: PropTypes.string,
  form_classes: PropTypes.string,
};
