import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Form from "modules/base/components/Form";
import TextInput from "modules/base/components/inputs/TextInput";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import {
  closeModalById,
  handleInputChange,
  openModal,
} from "modules/base/utilities/Actions";
import CreateTemplateModal from "modules/marketing/components/emailstemplates/CreateTemplateModal";
import MarketingAPI from "modules/marketing/MarketingAPI";
import { AIEmailTemplate } from "modules/marketing/MarketingModels";

function GenerateEmailTemplate({ match, currentModalId }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTemplateContent, setSelectedTemplateContent] = useState(null);
  const [uuid] = useState(uuidv4());
  const [state, setState] = useState({
    subject: "",
  });

  const onInputChange = useCallback((e) =>
    handleInputChange(e.target.name, e.target.value, state, setState),
  );

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const payload = {
      site: siteId,
      subject: state.subject,
    };
    API.generateEmailTemplate(
      payload,
      (templateContent: AIEmailTemplate) => {
        setSelectedTemplateContent(templateContent.content);
        setIsSubmitting(false);
        closeModalById(currentModalId);
        openModal(
          `design-email-template-${uuid}-modal`,
          `create-template-${uuid}-modal`,
        );
      },
      (error_response) => {
        setError({
          message: error_response.message,
        });
        setIsSubmitting(false);
      },
    );
  });
  return (
    <>
      <CreateTemplateModal prebuiltHtml={selectedTemplateContent} uuid={uuid} />
      <Form
        handleSubmit={handleSubmit}
        id="create-template-form"
        button_color="gray-900"
        button_label="Create"
        fullLengthButton
        isSubmitting={isSubmitting}
        use_as="div"
        padding="p-0"
      >
        <NetworkMessageDisplay error={error} />
        <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
          <TextInput
            name="subject"
            title="What is the email about?"
            content={state.subject ?? ""}
            controlFunc={onInputChange}
            required
          />
        </div>
      </Form>
    </>
  );
}

GenerateEmailTemplate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string.isRequired,
};

export default withRouter(GenerateEmailTemplate);
