import PropTypes from "prop-types";
import React, { useState } from "react";
import { User } from "../../account/AccountModels";
import InvoiceCard from "./InvoiceCard";

export default function InvoiceList({ user, invoices, handleInvoicePayUrl }) {
  const [expandedInvoice, setExpandedInvoice] = useState(null);

  const invoice_cards: any = invoices.map((invoice, index) => {
    const onExpandCard: any = () => {
      const expandedInvoice_id: any =
        expandedInvoice === invoice.id ? null : invoice.id;
      setExpandedInvoice(expandedInvoice_id);
    };
    return (
      <InvoiceCard
        key={invoice.id}
        user={user}
        invoice={invoice}
        is_expanded={invoice.id === expandedInvoice}
        onExpand={onExpandCard}
        handleInvoicePayUrl={handleInvoicePayUrl}
      />
    );
  });

  return <div id="accordion">{invoice_cards}</div>;
}

InvoiceList.propTypes = {
  user: PropTypes.instanceOf(User).isRequired,
  invoices: PropTypes.instanceOf(Object).isRequired,
  handleInvoicePayUrl: PropTypes.func.isRequired,
};
