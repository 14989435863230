import PropTypes from "prop-types";
import { useStore } from "reactflow";

function ConnectionLine({ fromX, fromY, toX, toY }) {
  const { connectionHandleId } = useStore();

  return (
    <g>
      <path
        fill="none"
        stroke={connectionHandleId}
        strokeWidth={1.5}
        className="animated"
        d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
      />
      <circle
        cx={toX}
        cy={toY}
        fill="#fff"
        r={3}
        stroke={connectionHandleId}
        strokeWidth={1.5}
      />
    </g>
  );
}

ConnectionLine.propTypes = {
  fromX: PropTypes.number.isRequired,
  fromY: PropTypes.number.isRequired,
  toX: PropTypes.number.isRequired,
  toY: PropTypes.number.isRequired,
};

export default ConnectionLine;
