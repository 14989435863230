import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import {
  closeModalById,
  handleInputChange,
} from "modules/base/utilities/Actions";
import StageFormFields from "modules/marketing/components/stages/StageFormFields";
import MarketingAPI from "modules/marketing/MarketingAPI";

function CreateStageForm({ currentModalId, siteId }) {
  const API = new MarketingAPI(siteId);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, setState] = useState({ isPublished: true, site: siteId });

  const onInputChange = useCallback((e) =>
    handleInputChange(e.target.name, e.target.value, state, setState),
  );
  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    API.createStage(
      state,
      () => {
        setState({ isPublished: true, site: siteId });
        setIsSubmitting(false);
        closeModalById(currentModalId);
        setSuccess({ message: "Stage created successfully" });
      },
      (error_response) => {
        setError(error_response.message);
        setIsSubmitting(false);
      },
    );
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      id="create-stage-form"
      button_color="gray-900"
      button_label="Create"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      <StageFormFields state={state} onInputChange={onInputChange} />
    </Form>
  );
}

CreateStageForm.defaultProps = {
  currentModalId: null,
};

CreateStageForm.propTypes = {
  currentModalId: PropTypes.string,
  siteId: PropTypes.string.isRequired,
};

export default CreateStageForm;
