import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Sidebar from "modules/core/components/Sidebar";
import { Menu } from "modules/core/models/Menu";
import {
  PATH_WEBSITES,
  PATH_WEBSITE_DOMAINS,
  PATH_WEBSITE_FORM_SUBMISSIONS,
  PATH_WEBSITE_SEO,
  PATH_WEBSITE_SETTINGS,
} from "modules/website/WebsiteConstants";
import { Site } from "modules/website/WebsiteModels";

function SidebarWebsite(props) {
  const { website } = props;

  let sidebar_menus: any = [
    new Menu("Domains", "link-45deg", PATH_WEBSITE_DOMAINS, false),
  ];
  if (!website.is_shop) {
    sidebar_menus.push(
      new Menu("Search Engine", "search", PATH_WEBSITE_SEO, false),
      new Menu(
        "Form Submissions",
        "chat-text",
        PATH_WEBSITE_FORM_SUBMISSIONS,
        false,
      ),
      new Menu("Settings", "gear", PATH_WEBSITE_SETTINGS, false),
    );
  }

  const current_path: any = props.location.pathname;
  sidebar_menus = sidebar_menus.map((menu: Menu) => {
    menu.path = menu.path.replace(":id", website.id);
    menu.is_active = menu.path.includes(current_path);
    return menu;
  });

  const bottom_action: any = new Menu(
    "All Websites",
    "chevron-double-left",
    PATH_WEBSITES,
    false,
  );

  return (
    <Sidebar
      sidebar_banner={website.desktop_thumbnail}
      sidebar_banner_width={100}
      actions={sidebar_menus}
      bottom_action={bottom_action}
      site={website}
      {...props}
    />
  );
}

SidebarWebsite.propTypes = {
  website: PropTypes.instanceOf(Site).isRequired,
};

export default withRouter(SidebarWebsite);
