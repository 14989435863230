import countryRegionData from "country-region-data";
import React from "react";
import FormSelectInput from "./FormSelectInput";

export class FormCountryRegionInput extends React.Component {
  render() {
    const { label } = this.props;
    let { placeholder } = this.props;
    const { name } = this.props;
    const { country } = this.props;
    const { region } = this.props;
    const onChangeRegion: any = this.props.onChangeText;
    const { is_required } = this.props;
    let { is_editable } = this.props;

    const country_object: any =
      countryRegionData.find((c_obj) => c_obj.countryName === country) || {};
    const country_regions: any = country_object.regions || [];
    const regions: any = country_regions.map((region) => region.name);

    if (!country) {
      is_editable = false;
      placeholder = "Choose a country";
    }

    return (
      <FormSelectInput
        label={label}
        placeholder={placeholder}
        name={name}
        is_required={is_required}
        is_editable={is_editable}
        selected_option={region}
        options={regions}
        onSelectOption={onChangeRegion}
      />
    );
  }
}
