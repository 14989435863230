import React from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationActions } from "../../account/AccountActions";
import { navigateToPage } from "../../core/utilities/navigation/Navigator";
import {
  PATH_WEBSITES,
  PATH_WEBSITE_TEMPLATES,
} from "../../website/WebsiteConstants";

class Page404 extends React.Component {
  render() {
    const accountActions: any = new AuthenticationActions();
    const is_authenticated: any = accountActions.isAuthenticated();
    const btn_go_home_text: any = is_authenticated
      ? "Take me to my dashboard"
      : "Take me  home";

    return (
      <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
        <h1 className="text-primary">404. You are lost</h1>

        <h4 className="mt-5">May be we can help. What did you intend to do?</h4>
        <button
          type="button"
          className="btn btn-link bg-transparent"
          onClick={() => navigateToPage(PATH_WEBSITE_TEMPLATES, this.props)}
        >
          Create a website
        </button>
        <button
          type="button"
          className="btn btn-link bg-transparent"
          onClick={() => navigateToPage(PATH_WEBSITES, this.props)}
        >
          View my websites
        </button>
        <button
          type="button"
          className="btn btn-link bg-transparent"
          onClick={() => navigateToPage(PATH_WEBSITES, this.props)}
        >
          {btn_go_home_text}
        </button>
      </div>
    );
  }
}

export default withRouter(Page404);
