export const ChartBlock = (editor, opts = {}) => {
    const bm = editor.BlockManager;
    bm.add("chart", {
        label: "Chart",
        activate: true,
        select: true,
        category: "Extra",
        content: {type: "chart-component"},
        media: '<i class="fa fa-bar-chart" aria-hidden="true"style="font-size:40px"></i>'
    });
};


export default (editor, opts = {}) => {
    const domc = editor.DomComponents;
    const tm = editor.Panels.getButton("views", "open-tm");
    const {
        labels_string_x_axis = "January, February, March, April, May, June",
        data_string_y_axis = "4500, 5300, 6250, 7800, 9800, 15000",
        primary_color = "#4e73df",
        chart_label = "Revenue",
        chartComponent
    } = opts;

    domc.addType("chart-component", {
        model: {
            defaults: {
                tagName: "div",
                name: "Chart",
                draggable: "#wrapper",
                droppable: false,
                stylable: false,
                copyable: false,
                resizable: true,
                labels_string_x_axis: labels_string_x_axis,
                data_string_y_axis: data_string_y_axis,
                primary_color: primary_color,
                chart_label: chart_label,
                traits: [
                    {
                        name: "labels_string_x_axis",
                        label: "X-Axis Labels (comma separated)",
                        placeholder: "Enter x-axis labels",
                        changeProp: 1,
                    },
                    {
                        name: "data_string_y_axis",
                        label: "Y-Axis data (comma separated)",
                        placeholder: "Enter y-axis data",
                        changeProp: 1,
                    },
                    {
                        type: "color",
                        name: "primary_color",
                        label: "choose chart color",
                        changeProp: 1,
                    },
                    {
                        name: "chart_label",
                        label: "Chart label",
                        changeProp: 1,
                    },
                ],
                components: `<canvas data-chart=\"{&quot;type&quot;:&quot;bar&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;January&quot;,&quot;February&quot;,&quot;March&quot;,&quot;April&quot;,&quot;May&quot;,&quot;June&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Revenue&quot;,&quot;backgroundColor&quot;:&quot;#4e73df&quot;,&quot;borderColor&quot;:&quot;#4e73df&quot;,&quot;data&quot;:[&quot;4500&quot;,&quot;5300&quot;,&quot;6250&quot;,&quot;7800&quot;,&quot;9800&quot;,&quot;15000&quot;]}]},&quot;options&quot;:{&quot;maintainAspectRatio&quot;:false,&quot;responsive&quot;:true,&quot;legend&quot;:{&quot;display&quot;:false},&quot;title&quot;:{}}}\"></canvas>`,
                script: function () {
                    $(document).ready((function () {
                        if (!document.getElementById("chartjs_script")) {
                            const script = document.createElement("script");
                            script.setAttribute("id", "chartjs_script");
                            script.setAttribute("src", "https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.8.0/Chart.bundle.min.js");
                            document.head.appendChild(script);
                            script.onload = () => {
                               loadChart();
                            };
                        } else {
                           loadChart();
                        }
                        function loadChart(){
                             $("[data-chart]").each((function (a, t) {
                                this.chart = new Chart($(t), $(t).data("chart"))
                            }))
                        }
                    }));


                }
            },
            init() {
                this.on("change:primary_color change:data_string_y_axis change:labels_string_x_axis change:chart_label", () => valuesOnchange());
                const valuesOnchange = () => {
                    this.setComponent();
                    this.trigger("change:script");
                    editor.store();
                };
                this.setStyle({
                    width: "80vw",
                    height: "40vh",
                    position: "relative",
                    padding: "12px"
                });
                const attr = {
                    'dropppable': false, 'copyable': false, 'removable': false, 'draggable': false,
                    toolbar: []
                };
                this.get('components').each(child => child.set(attr))
                tm.set("active", 1);
            },
            setComponent() {
                const tagName = this.get("tagName");
                const labels_string = this.get("labels_string_x_axis");
                const data_string = this.get("data_string_y_axis");
                const primary_color = this.get("primary_color");
                const chart_label = this.get("chart_label");
                let labels = '';
                let data = '';
                if (labels_string !== undefined) {
                    labels = labels_string.replace(/\s/g, '').split(',').map(s => '&quot;' + s + '&quot;').join(',');
                }
                if (data_string !== undefined) {
                    data = data_string.replace(/\s/g, '').split(',').map(s => '&quot;' + s + '&quot;').join(',');
                }
                const updated_chart = `<canvas  data-chart="{&quot;type&quot;:&quot;bar&quot;,&quot;data&quot;:{&quot;labels&quot;:
                [${labels}],
                &quot;datasets&quot;:[{&quot;label&quot;:&quot;${chart_label}&quot;,&quot;backgroundColor&quot;:&quot;${primary_color}&quot;,
                &quot;borderColor&quot;:&quot;${primary_color}&quot;,&quot;data&quot;:[${data}]}]},&quot;options&quot;:{&quot;maintainAspectRatio&quot;:false,&quot;responsive&quot;:true,
                &quot;legend&quot;:{&quot;display&quot;:false},&quot;title&quot;:{}}}"></canvas>`;
                this.components(updated_chart);
            },
            ...chartComponent,
        },
        view: {},
    });
};
