import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { Icon } from "modules/base/components/Icons";
import { openModal } from "modules/base/utilities/Actions";
import PlanUpgradeModal from "modules/billing/components/PlanUpgradeModal";
import { Site } from "modules/website/WebsiteModels";

/**
 * Payment Modal Button
 */
const PaymentModalButton = ({ website, wideView, trigger }) => {
  const upgradePlanModalId = `select-plan-${website.id}`;
  useEffect(() => {
    if (trigger) {
      openModal(upgradePlanModalId);
    }
  }, [trigger]);

  let upgrade_button;

  const onClickUpgrade = useCallback(() => {
    openModal(upgradePlanModalId);
  });

  if (!website) {
    return null;
  }

  if (wideView) {
    const billingPlanDisplay = (
      <strong className="text-olitt-grey d-none d-lg-inline me-3">
        <span className="badge rounded-pill bg-secondary">
          {website.subscription_plan?.name}
        </span>{" "}
      </strong>
    );
    if (website.subscription_plan) {
      if (website.can_subscription_upgrade) {
        upgrade_button = (
          <>
            {billingPlanDisplay}
            <button
              type="button"
              className="btn btn-olitt-grey shadow-none me-0 me-md-4 mb-2 mb-md-0 flex-grow-1"
              onClick={onClickUpgrade}
            >
              <Icon icon="cart4" additionalClasses="me-1" />
              Upgrade
            </button>
          </>
        );
      } else {
        upgrade_button = billingPlanDisplay;
      }
    }
  }

  return (
    <div className="d-flex align-items-center">
      {upgrade_button}
      <PlanUpgradeModal
        modal_id={`select-plan-${website.id}`}
        website={website}
      />
    </div>
  );
};

PaymentModalButton.propTypes = {
  website: PropTypes.instanceOf(Site).isRequired,
  wideView: PropTypes.bool,
  trigger: PropTypes.bool,
};

PaymentModalButton.defaultProps = {
  wideView: false,
  trigger: false,
};

export default PaymentModalButton;
