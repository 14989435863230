import DashboardContainer from "modules/marketing/components/dashboard/Dashboard";
import SubscriptionCheck from "modules/marketing/layouts/SubscriptionCheck";
import { MARKETING_SLUG } from "modules/marketing/MarketingConstants";

const Dashboard = () => {
  return (
    <SubscriptionCheck
      slug={MARKETING_SLUG}
      component={<DashboardContainer />}
    />
  );
};

export default Dashboard;
