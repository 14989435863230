import { useState } from "react";
import { deleteNullProperties } from "modules/base/utilities/Actions";
import PublishUnpublishButton from "modules/marketing/components/PublishUnpublishButton";
import MarketingAPI from "modules/marketing/MarketingAPI";

function publishUnpublishActionColumn(siteId, setError, publishFunctionName) {
  const API = new MarketingAPI(siteId);
  const [isPublishingOrUnpublishing, setIsPublishingOrUnpublishing] = useState(
    {},
  );

  const startUpdating = (id) => {
    setIsPublishingOrUnpublishing((prev) => ({ ...prev, [id]: true }));
  };

  const stopUpdating = (id) => {
    setIsPublishingOrUnpublishing((prev) => ({ ...prev, [id]: false }));
  };

  return {
    header: "Publish/Unpublish",
    accessorKey: "publishUnpublish",
    cell: ({ row }) => {
      const { original } = row;
      const { id, isPublished } = original;
      const onClick = () => {
        startUpdating(id);
        const payload = deleteNullProperties({
          ...original,
          isPublished: !isPublished,
          site: siteId,
        });

        const updateCallback = () => {
          stopUpdating(id);
          setError(null);
        };

        const errorCallback = (error_response) => {
          stopUpdating(id);
          setError({ message: error_response.message });
        };
        API[publishFunctionName](
          original.id,
          payload,
          updateCallback,
          errorCallback,
        );
      };
      return (
        <PublishUnpublishButton
          isPublished={isPublished}
          onClick={onClick}
          isPublishingOrUnpublishing={isPublishingOrUnpublishing[id] || false}
        />
      );
    },
  };
}

export default publishUnpublishActionColumn;
