import {
  getCurrentDate,
  getDateXDaysAgo,
  getDateXMonthsAgo,
  getDateXYearsAgo,
} from "modules/marketing/components/dashboard/dateUtils";

export const filterConfig = {
  "24HR": { func: getCurrentDate, timeUnit: "H" },
  "7D": { func: () => getDateXDaysAgo(7), timeUnit: "d" },
  "1M": { func: () => getDateXMonthsAgo(1), timeUnit: "d" },
  "3M": { func: () => getDateXMonthsAgo(3), timeUnit: "m" },
  "1Y": { func: () => getDateXYearsAgo(1), timeUnit: "m" },
};
