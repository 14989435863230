import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import {
  closeModalById,
  handleInputChange,
} from "modules/base/utilities/Actions";
import NoteFormFields from "modules/marketing/components/notes/NoteFormFields";
import MarketingAPI from "modules/marketing/MarketingAPI";

function CreateNoteForm({ currentModalId, lead, siteId }) {
  const API = new MarketingAPI(siteId);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, setState] = useState({});

  const onInputChange = useCallback(
    (e) => {
      handleInputChange(e.target.name, e.target.value, state, setState);
    },
    [state],
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const payload = { ...state, lead };
    API.createNote(
      payload,
      () => {
        setState({});
        setIsSubmitting(false);
        closeModalById(currentModalId);
        setSuccess({ message: "Contact note created successfully" });
      },
      (error_response) => {
        setError(error_response.message);
        setIsSubmitting(false);
      },
    );
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      id="create-note"
      button_color="gray-900"
      button_label="Create Note"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      <NoteFormFields state={state} onInputChange={onInputChange} />
    </Form>
  );
}

CreateNoteForm.defaultProps = {
  currentModalId: null,
  lead: null,
};

CreateNoteForm.propTypes = {
  currentModalId: PropTypes.string,
  lead: PropTypes.number,
  siteId: PropTypes.string.isRequired,
};

export default CreateNoteForm;
