import PropTypes from "prop-types";
import React from "react";
import emptyBox from "../assets/empty-box.svg";

export default function EmptyList({ title, description }) {
  return (
    <div className="container-fluid h-100 py-5">
      <div className="h-100 d-flex flex-column justify-content-center align-items-center border">
        <img className="mb-5" width="150px" src={emptyBox} alt="" />
        <p className="text-center h4 text-secondary py-2">{title}</p>
        <p className="text-center h5 text-secondary">{description}</p>
      </div>
    </div>
  );
}

EmptyList.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
