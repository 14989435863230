import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import TextInput from "modules/base/components/inputs/TextInput";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import { closeModalById, openModal } from "modules/base/utilities/Actions";
import MarketingAPI from "modules/marketing/MarketingAPI";
import { useImportContext } from "modules/marketing/components/contacts/import/ImportContext";

function ImportFile(props) {
  const { currentModalId, assignRawContacts, match } = props;
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const { contactsFile, setContactsFile } = useImportContext();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFileChange = (e) => {
    setContactsFile(e.target.files[0]);
  };

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);
    API.formatFileContacts(
      { file: contactsFile },
      (processedData) => {
        setIsSubmitting(false);
        setError(null);
        assignRawContacts(processedData);
        openModal("match-columns-modal", currentModalId, false);
      },
      (error_response) => {
        setIsSubmitting(false);
        setError({
          message: error_response.message,
        });
        setSuccess(null);
      },
    );
  });

  return (
    <>
      <Form
        handleSubmit={handleSubmit}
        id="import-file-for-processing"
        button_color="gray-900"
        button_label="Import"
        fullLengthButton
        isSubmitting={isSubmitting}
        use_as="form"
        padding="p-0"
      >
        <NetworkMessageDisplay error={error} />
        <SuccessActionIndicator success={success} />
        <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
          <TextInput
            name="file"
            title="Choose contacts file to upload"
            inputType="file"
            required
            controlFunc={onFileChange}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </div>
      </Form>
    </>
  );
}

ImportFile.defaultProps = {
  currentModalId: null,
  assignRawContacts: () => {
    // Do nothing
  },
};

ImportFile.propTypes = {
  currentModalId: PropTypes.string,
  assignRawContacts: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(ImportFile);
