import PropTypes from "prop-types";
import SelectOptionCard from "modules/base/components/inputs/SelectOptionCard";

function StyleOptionCards({
  styleOptions,
  state,
  onInputChange,
  styleIconsMap,
}) {
  return styleOptions.map((option) => {
    const iconName = styleIconsMap[option.value] ?? "bi-window";
    const iconFontColor = option.value === state.style ? "white" : "gray";
    const icon = <i className={`bi bi-${iconName} text-${iconFontColor}`} />;
    return (
      <SelectOptionCard
        key={option.value}
        value={option.value}
        label={option.label}
        subtitle={option.description}
        icon={icon}
        section_name={`${state.id}-style`}
        onSelect={() => {
          onInputChange({
            target: {
              name: "style",
              value: option.value,
            },
          });
        }}
        isSelected={option.value === state.style}
        icon_position="right"
        selected_bg_color="gray-900"
        selected_pre_label_text_color_class="text-white"
        selected_label_text_color_class="text-white"
        selected_subtitle_text_color_class="text-white"
        selected_description_text_color_class="text-white"
        unselected_description_text_color_class="text-muted"
      />
    );
  });
}

StyleOptionCards.propTypes = {
  styleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ).isRequired,
  state: PropTypes.shape({
    id: PropTypes.string.isRequired,
    style: PropTypes.string.isRequired,
  }).isRequired,
  onInputChange: PropTypes.func.isRequired,
  styleIconsMap: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default StyleOptionCards;
