import $ from "jquery";
import { isString } from "underscore";
import { tags_to_edit } from "./constants";

export default (editor, options = {}) => {
  const opts = {
    ...{
      changeBackGroundCommand: "change-background",
      changeBackgroundLabel: "Change background",
      BackgroundModalTitle: "Choose background",
    },
    ...options,
  };
  const {
    changeBackgroundLabel,
    changeBackGroundCommand,
    BackgroundModalTitle,
  } = opts;
  const domc = editor.DomComponents;

  editor.on("component:selected", (component) => {
    const tag_name = component.get("tagName");
    const component_type = component.get("type");
    const tb = component.get("toolbar");
    if (tags_to_edit.includes(tag_name) || component_type === "text") {
      const tbChangeBgExists = tb.some(
        (item) => item.command === changeBackGroundCommand,
      );
      if (!tbChangeBgExists) {
        tb.push({
          command: changeBackGroundCommand,
          label: changeBackgroundLabel,
          attributes: { title: "Change background" },
        });
      }
    }
  });

  editor.Commands.add(changeBackGroundCommand, {
    run(editor, sender, opts = {}) {
      this.editor = editor;
      this.options = opts;
      this.target = opts.target || editor.getSelected();
      const { target } = this;
      this.$ = editor.$;
      this.content = "";
      this.bg_color_parent = {};
      this.bg_color_dom = {};

      this.showBackgroundPickModal();
    },
    showBackgroundPickModal() {
      const { editor, target, options } = this;
      const title = options.title || BackgroundModalTitle;
      const content = this.getContent();
      editor.PopUp.open({ title, content })
        .getModel()
        .once("change:open", () => {
          this.bgColorCallback();
          editor.stopCommand(this.id);
        });
    },
    getContent() {
      const { target } = this;
      const bg_color_dom = $(
        "#gjs-sm-decorations .gjs-sm-properties #gjs-sm-background-color",
      );
      this.bg_color_dom = bg_color_dom;
      const current_styles = target.getStyle();
      const current_bg = current_styles["background-image"];
      const src_url = current_bg
        ? current_bg.slice(4, -1).replace(/["']/g, "")
        : "";
      const content = $(`
                     <div class="row p-4">
                    <div class="col bg-color-input">
                    </div>
                    <div class="col bg-image-input">
                    <div class="gjs-sm-label" data-sm-label="">
                      <span class="gjs-sm-icon " title="">
                        Background Image
                      </span>
                      <img  id="this-bg-img" width='20' src="${src_url}" style="display: none;"> 
                       <b class="gjs-sm-clear" id="this-bg-clear" style="display: none;">⨯</b>
                    </div>
                    <button type="button" class="gjs-btn-prim gjs-btn--full" id="select-img-bg">
                        Select Image
                     </button>
                    </div>
                  </div>
            `);
      const clear_btn = content.find("#this-bg-clear");
      const bg_preview = content.find("#this-bg-img");
      if (src_url) {
        bg_preview.show();
        clear_btn.show();
        clear_btn.parent().addClass("gjs-four-color");
      }
      content.find(".bg-color-input").append(bg_color_dom);
      content.find("#select-img-bg").on("click", () => this.showImageModal());
      clear_btn.on("click", () => this.removeBgImage(clear_btn, bg_preview));

      return content;
    },
    showImageModal() {
      const { editor, target } = this;
      editor.runCommand("open-assets", {
        target: this,
        types: ["image"],
        accept: "image/*",
        onSelect: (asset) => {
          editor.PopUp.close();
          const url = isString(asset) ? asset : asset.get("src");
          target.setStyle(`background-image: url("${url}")`);
        },
      });
    },

    removeBgImage(clear_btn, bg_preview) {
      const { target } = this;
      bg_preview.hide();
      clear_btn.hide();
      clear_btn.parent().removeClass("gjs-four-color");
      target.setStyle(`background-image: none)`);
    },
    bgColorCallback() {
      // return the bg color selector to style manger
      const { bg_color_dom } = this;
      $(bg_color_dom).insertAfter(
        "#gjs-sm-decorations .gjs-sm-properties #gjs-sm-border-radius.gjs-sm-integer",
      );
    },
  });
};
