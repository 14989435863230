import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import { openModal } from "modules/base/utilities/Actions";
import publishUnpublishActionColumn from "modules/marketing/components/PublishUnpublishActionColumn";
import CreateSegmentModal from "modules/marketing/components/segments/CreateSegmentModal";
import EditSegmentModal from "modules/marketing/components/segments/EditSegmentModal";
import SendEmailTemplateModal from "modules/marketing/components/segments/SendEmailTemplateModal";
import { segmentsColumns } from "modules/marketing/components/tableColumns";
import MarketingAPI from "modules/marketing/MarketingAPI";

function SegmentsList(props) {
  const { match } = props;
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const [paginationParams, setPaginationParams] = useState({
    start: 0,
    limit: 10,
  });
  const params = useMemo(
    () => ({
      ...paginationParams,
      orderBy: "dateAdded",
      orderByDir: "desc",
    }),
    [paginationParams],
  );
  const {
    segments,
    total,
    error: segmentLoadingError,
    isLoading,
  } = API.getSegments(params);
  const [error, setError] = useState(null);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const onClickCreate = useCallback(() => {
    openModal("create-segment-modal");
  });
  const onClickEdit = useCallback((segment) => {
    openModal(`edit-segment-${segment.id}-modal`);
  });

  const onClickSendEmail = useCallback(() => {
    openModal("send-email-template-modal");
  });

  const onRowSelectionChange = useCallback((rowSelection) => {
    setSelectedSegments(Object.keys(rowSelection));
  });

  const onClickDelete = useCallback((id, onSuccess, onError) => {
    API.deleteSegment(
      id,
      () => {
        onSuccess();
      },
      (error_response) => {
        onError("Unable to delete segment", error_response.message);
      },
    );
  });
  const handlePaginationChange = useCallback((newPaginationParams) => {
    setPaginationParams(newPaginationParams);
  }, []);
  const columns = [
    ...segmentsColumns,
    publishUnpublishActionColumn(siteId, setError, "updateSegment"),
  ];
  return (
    <>
      <NetworkMessageDisplay error={error ?? segmentLoadingError} />
      <CreateSegmentModal />
      <SendEmailTemplateModal selectedSegmentsIds={selectedSegments} />
      <button
        type="button"
        className="btn btn-sm btn-outline-gray-900 mb-3"
        disabled={selectedSegments.length === 0}
        onClick={onClickSendEmail}
      >
        <i className="bi bi-envelope-arrow-up-fill" /> Send Email
      </button>
      <DataTable
        data={segments}
        columns={columns}
        total={total}
        isLoading={isLoading}
        createButtonColor="gray-900"
        editButtonColor="gray-900"
        objectType="segment"
        allowEdit
        allowDelete
        allowCreate
        EditModal={EditSegmentModal}
        onClickCreate={onClickCreate}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        enableRowSelection
        enableMultiRowSelection
        onRowSelectionChange={onRowSelectionChange}
        onPaginationChange={handlePaginationChange}
        isServerSidePagination
      />
    </>
  );
}

SegmentsList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(SegmentsList);
