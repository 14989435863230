import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import EditFocusItem from "modules/marketing/components/Focusitems/EditFocusItem";
import { FocusItem } from "modules/marketing/MarketingModels";

function EditFocusItemModal(props) {
  const { row: focusItem } = props;
  const modalId = `edit-focus-item-${focusItem.id}-modal`;
  return (
    <Modal modal_title="Edit Engagement Item" modal_id={modalId} size="md">
      <EditFocusItem focusItem={focusItem} currentModalId={modalId} />
    </Modal>
  );
}

EditFocusItemModal.propTypes = {
  row: PropTypes.instanceOf(FocusItem).isRequired,
};

export default EditFocusItemModal;
