import { AuthenticationActions } from "../account/AccountActions";
import { URL_SPREE_LOGIN_PATH } from "../account/AccountConstants";
import { InputType } from "../core/constants/Enumerators";
import { LabeledFormInput } from "../core/models/FormInput";
import BasicInfoInputs from "./components/details/BasicInfoInputs";
import EmailSettingsInputs from "./components/details/EmailSettingsInputs";
import SEODetailsInputs from "./components/details/SEODetailsInputs";
import SocialDetailsInputs from "./components/details/SocialDetailsInputs";
import { PAYMENT_METHODS_PROVIDERS } from "./components/paymentmethods/PaymentMethodProviders";
import { Shop } from "./models/Shop";
import {
  PATH_SHOP_DETAILS,
  PATH_SHOP_EMAIL_SETTINGS,
  PATH_SHOP_SEO,
  PATH_SHOP_SOCIAL,
} from "./ShopConstants";

/**
 * @returns {boolean}
 * @param route
 * @param site_id
 */
export function generateRoute(route: string, site_id: string) {
  return route.replace(":id", site_id);
}

/**
 * @param path
 * @param current_path
 * @param site_id
 * @returns {boolean}
 */
export function isActiveRoute(path, current_path, site_id = null) {
  if (path.includes(":id")) {
    path = generateRoute(path, site_id);
  }
  return path.includes(current_path) || current_path.includes(path);
}

/**
 * @param current_path
 * @param shop
 * @returns {{submit_button_text, inputs, title}}
 */
export function getFormInputs(current_path: string, shop: Shop) {
  let inputs;
  let title;
  let submit_button_text;
  if (isActiveRoute(PATH_SHOP_DETAILS, current_path, shop.site)) {
    inputs = BasicInfoInputs(shop);
    title = "Basic Info";
    submit_button_text = "Update basic info";
  } else if (isActiveRoute(PATH_SHOP_SOCIAL, current_path, shop.site)) {
    inputs = SocialDetailsInputs(shop);
    title = "Social Details";
    submit_button_text = "Update social details";
  } else if (isActiveRoute(PATH_SHOP_SEO, current_path, shop.site)) {
    inputs = SEODetailsInputs(shop);
    title = "SEO Details";
    submit_button_text = "Update SEO";
  } else if (isActiveRoute(PATH_SHOP_EMAIL_SETTINGS, current_path, shop.site)) {
    inputs = EmailSettingsInputs(shop);
    title = "Email Settings";
    submit_button_text = "Update email settings";
  }
  return { inputs, title, submit_button_text };
}

/**
 * @name togglePaymentProvider
 * @param selected_provider
 * @param callback
 * @param configs
 * @returns {Array}
 */
export function togglePaymentProvider(selected_provider, callback, configs) {
  const provider_inputs: Array = [];
  const payment_provider: any = PAYMENT_METHODS_PROVIDERS.find(
    (provider) => provider.value === selected_provider,
  );
  if (payment_provider) {
    payment_provider.configs.forEach((input) => {
      provider_inputs.push(
        new LabeledFormInput({
          type: InputType[input.type],
          name: input.name,
          label: input.label,
          placeholder: input.placeholder,
          value: configs[input.name],
          is_required: input.required,
          is_editable: true,
          options: input.options,
        }),
      );
    });
  }
  return callback(provider_inputs);
}

/**
 * getAutoLoginUrl
 * description: returns the auto login url
 * @param shop
 * @returns {string}
 */
export function getAutoLoginUrl(shop: Shop) {
  const authenticationActions: any = new AuthenticationActions();
  const user: any = authenticationActions.retrieveAuthenticatedUser();
  const base64_auth_string: string = btoa(
    `${user.email}:${user.spree_password}`,
  );
  const login_query_params: string = `?basic_auth=${base64_auth_string}`;
  const auto_login_url: string = `${shop.admin_url}${URL_SPREE_LOGIN_PATH}${login_query_params}`;
  return auto_login_url;
}
