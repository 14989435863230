import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import {
  closeModalById,
  handleInputChange,
} from "modules/base/utilities/Actions";
import StageFormFields from "modules/marketing/components/stages/StageFormFields";
import MarketingAPI from "modules/marketing/MarketingAPI";
import { Stage } from "modules/marketing/MarketingModels";

function EditStageForm({ stage, match, currentModalId }) {
  const { id: siteId } = match.params;
  const updatedStage = { ...stage, site: siteId };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [state, setState] = useState(updatedStage);

  const API = new MarketingAPI(siteId);

  const onInputChange = useCallback((e) => {
    handleInputChange(e.target.name, e.target.value, state, setState);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    API.updateStage(
      state.id,
      state,
      () => {
        setIsSubmitting(false);
        closeModalById(currentModalId);
        setSuccess({ message: "Stage updated successfully" });
      },
      (error_response) => {
        setError({
          message: error_response.message,
        });
        setIsSubmitting(false);
      },
    );
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      id="edit-stage-form"
      button_color="gray-900"
      button_label="Update"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      <StageFormFields state={state} onInputChange={onInputChange} />
    </Form>
  );
}

EditStageForm.defaultProps = {
  currentModalId: null,
};

EditStageForm.propTypes = {
  stage: PropTypes.instanceOf(Stage).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string,
};

export default withRouter(EditStageForm);
