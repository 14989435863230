import React, { useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { PATH_LOGIN } from "modules/account/AccountConstants";

const AuthContextType = {
  access_token: localStorage.getItem("access_token"),
  refresh_token: localStorage.getItem("refresh_token"),
  logout: () => {},
  login: () => {},
  isAuthenticated: () => {},
};

const AuthContext = React.createContext(AuthContextType);

/**
 * AuthProvider
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [auth, setAuth] = React.useState(AuthContextType);
  const authRef = React.useRef(AuthContextType);
  const [loginCallback, setLoginCallback] = React.useState(() => {});

  React.useEffect(() => {
    authRef.current = auth;
    if (auth.access_token !== null && loginCallback) {
      loginCallback();
    }
  }, [auth]);

  function isAuthenticated() {
    return authRef.current.access_token !== null;
  }

  const login = (tokens, callback = () => {}) => {
    setAuth(tokens);
    setLoginCallback(callback);
  };
  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setAuth({
      access_token: null,
      refresh_token: null,
    });
  };

  useEffect(() => {
    const handleLocalStorageChange = () => {
      if (!localStorage.getItem("access_token")) {
        logout();
      }
    };
    window.addEventListener("localStorageChange", handleLocalStorageChange);
    return () =>
      window.removeEventListener(
        "localStorageChange",
        handleLocalStorageChange,
      );
  }, []);

  const value = React.useMemo(() => ({
    access_token: auth.access_token,
    refresh_token: auth.refresh_token,
    logout: () => logout(),
    login: (tokens, callback) => login(tokens, callback),
    isAuthenticated: () => isAuthenticated(),
  }));
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

/**
 * useAuth
 * @returns {{
 *   access_token: string,
 *   refresh_token: string,
 *   logout: function(): void,
 *   login: function(tokens: object, callback: function(): void): void,
 *   isAuthenticated: function(): boolean
 * }}
 */
export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

/**
 * RequireAuth
 * @returns {JSX.Element}
 * @constructor
 */
export function PrivateRoute({
  path,
  component: Component,
}: {
  path: string,
  component: React.ReactNode,
}) {
  const auth = useAuth();
  const location = useLocation();
  if (!auth.isAuthenticated()) {
    return (
      <Redirect
        to={{
          pathname: PATH_LOGIN,
          search: `?next=${location.pathname}`,
          state: { from: location },
        }}
      />
    );
  }
  return <Route path={path} component={Component} />;
}
