import PropTypes from "prop-types";
import React from "react";
import { OlittModal } from "modules/core/components/OlittModal";

export function PaymentModal(props) {
  const { modal_id, payment_url, title, show, onClose } = props;
  const iframe_style: { border: string, width: string, height: string } = {
    border: "none",
    width: "100%",
    height: "100%",
  };
  return (
    <OlittModal
      modal_id={modal_id}
      show={show}
      onClose={onClose}
      title={title}
      size="xl"
      hide_padding
    >
      <iframe src={payment_url} title={title} style={iframe_style} />
    </OlittModal>
  );
}

PaymentModal.defaultProps = {
  payment_url: "",
};

PaymentModal.propTypes = {
  modal_id: PropTypes.string,
  payment_url: PropTypes.string,
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
PaymentModal.defaultProps = {
  modal_id: "payment-modal",
};
