import extractDomain from "extract-domain";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ImageView } from "./ImageView";
import { ShareSiteModal } from "./ShareSiteModal";
import ActionsDropdown from "modules/base/components/ActionsDropdown";
import { Icon } from "modules/base/components/Icons";
import PlanUpgradeModal from "modules/billing/components/PlanUpgradeModal";
import ActionProcessing from "modules/core/components/ActionProcessing";
import { OlittModal } from "modules/core/components/OlittModal";
import PaymentModalButton from "modules/core/components/PaymentModalButton";
import WebsiteDomain from "modules/website/components/WebsiteDomain";
import { Site } from "modules/website/WebsiteModels";

const ImageOverlayActions = ({
  over_image_action,
  showPreview,
  handlePreview,
}) => {
  if (!over_image_action) return null;

  if (over_image_action.is_action_processing === true) {
    return (
      <button
        className={over_image_action.className}
        type="button"
        disabled
        aria-label="Processing action"
      >
        <ActionProcessing />
      </button>
    );
  }

  return (
    <div className="d-flex gap-2">
      <button
        className={over_image_action.className}
        onClick={over_image_action.onClick}
        type="button"
      >
        {over_image_action.icon && (
          <i className={`bi bi-${over_image_action.icon} me-1`} />
        )}
        {over_image_action.text}
      </button>
      {showPreview && (
        <button
          className="btn btn-outline-primary"
          onClick={handlePreview}
          type="button"
        >
          <i className="bi bi-eye-fill" /> Preview
        </button>
      )}
    </div>
  );
};

ImageOverlayActions.propTypes = {
  over_image_action: PropTypes.shape({
    className: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
    icon: PropTypes.string,
    is_action_processing: PropTypes.bool,
  }),
  showPreview: PropTypes.bool,
  handlePreview: PropTypes.func,
};

ImageOverlayActions.defaultProps = {
  over_image_action: null,
  showPreview: false,
  handlePreview: () => {},
};

const ImageSection = ({
  website,
  over_image_action,
  showPreview,
  handlePreview,
  image_width,
  image_height,
}) => {
  const [is_hovering, setIsHovering] = useState(false);

  const overlay = is_hovering && over_image_action && (
    <div className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
      <ImageOverlayActions
        over_image_action={over_image_action}
        showPreview={showPreview}
        handlePreview={handlePreview}
      />
    </div>
  );

  return (
    <div className="card rounded-0 border-0 border-end h-100">
      <div
        className="card-img-top"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <ImageView
          classes="img-fluid"
          opacity={is_hovering ? 0.3 : undefined}
          image_url={website.desktop_thumbnail}
          image_width={image_width}
          image_height={image_height}
          thumbnail_url={website.desktop_thumbnail}
        />
        {overlay}
      </div>
    </div>
  );
};

ImageSection.propTypes = {
  website: PropTypes.instanceOf(Site).isRequired,
  over_image_action: PropTypes.shape({
    className: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
    icon: PropTypes.string,
    is_action_processing: PropTypes.bool,
  }),
  showPreview: PropTypes.bool,
  handlePreview: PropTypes.func,
  image_width: PropTypes.number,
  image_height: PropTypes.number,
};

ImageSection.defaultProps = {
  over_image_action: null,
  showPreview: false,
  handlePreview: () => {},
  image_width: 500,
  image_height: 0,
};

const StatusIndicator = ({ is_published, status_reason }) => {
  let icon = "dash-circle";
  if (is_published) {
    icon = "check-circle";
  } else if (status_reason) {
    icon = "exclamation-circle";
  }
  const status = is_published ? "Published" : "Unpublished";
  const reason =
    status_reason && !is_published
      ? ` - ${status_reason.replace(/_/g, " ")}`
      : "";
  const colorClass = is_published ? "text-success" : "text-muted";

  return (
    <div className={`d-flex align-items-center ${colorClass} small`}>
      <Icon icon={icon} additionalClasses="me-1" />
      <span className="text-truncate">
        {status}
        {reason}
      </span>
    </div>
  );
};

StatusIndicator.propTypes = {
  is_published: PropTypes.bool,
  status_reason: PropTypes.string,
};

StatusIndicator.defaultProps = {
  is_published: false,
  status_reason: "",
};

const ContentSection = ({
  website,
  view_mode,
  wide_view,
  is_premium,
  actionsList,
  image_action,
}) => {
  const site_label = website.title || website.name;
  const image_action_display = view_mode === "templates" ? "d-grid" : "d-none";

  const renderImageAction = () => {
    if (!image_action) return null;

    return (
      <button
        className={image_action.className}
        onClick={image_action.onClick}
        type="button"
      >
        {image_action.icon && (
          <i className={`bi bi-${image_action.icon} me-1`} />
        )}
        {image_action.text}
      </button>
    );
  };

  return (
    <div className="h-100 card rounded-0 border-0">
      <div className="h-100 card-footer bg-transparent border-top-0">
        <div className="h-100 d-md-flex flex-column flex-md-row justify-content-md-between">
          <div className="d-flex flex-column align-self-center">
            <div>
              <p className="fs-5 d-inline">{site_label}</p>
              <img
                className="img-circle premium_lock-sm"
                src={website.subscription_plan.icon}
                height="40px"
                alt=""
                hidden={!is_premium}
              />
            </div>
            <div className="d-flex flex-column mb-2 mb-md-0">
              <WebsiteDomain website={website} view_mode={view_mode} />
              {view_mode === "sites" && (
                <div className="mt-1">
                  <StatusIndicator
                    is_published={website.is_published}
                    status_reason={website.status_reason}
                  />
                </div>
              )}
              <div className={`${image_action_display} d-md-none`}>
                {renderImageAction()}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row align-self-center">
            <PaymentModalButton website={website} wideView={wide_view} />
            {actionsList.length > 0 && (
              <ActionsDropdown
                label="Site actions"
                actionsList={actionsList}
                subject_id={website.id}
                removeTextWhiteClass
                dropDownButtonSize="md"
                dropDownButtonFontWeight="bold"
                dropdownMenuButtonSize="sm"
                additionalDropdownClasses="d-grid d-md-block"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ContentSection.propTypes = {
  website: PropTypes.instanceOf(Site).isRequired,
  view_mode: PropTypes.oneOf(["templates", "sites"]),
  wide_view: PropTypes.bool,
  is_premium: PropTypes.bool,
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      showConfirm: PropTypes.bool,
      confirm: PropTypes.shape({
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        confirm_button_label: PropTypes.string.isRequired,
        action_done_title: PropTypes.string.isRequired,
        action_done_message: PropTypes.string.isRequired,
        confirm_button_color: PropTypes.string,
      }),
    }),
  ),
  image_action: PropTypes.shape({
    className: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
    icon: PropTypes.string,
  }),
};

ContentSection.defaultProps = {
  view_mode: "",
  wide_view: false,
  is_premium: false,
  actionsList: [],
  image_action: null,
};

const ModalWrapper = ({
  website,
  onCloseShareSite,
  is_share_site_visible,
  isPreviewModalOpen,
  onClosePreviewModal,
  showPreview,
  formatSiteUrl,
}) => (
  <>
    <PlanUpgradeModal
      modal_id={`select-plan-${website.id}`}
      website={website}
    />
    <ShareSiteModal
      onClose={onCloseShareSite}
      modal_id="social-share-site"
      is_visible={is_share_site_visible}
      site_domain={website.site_domain}
      site_title={website.title}
    />
    {showPreview && (
      <OlittModal
        onClose={onClosePreviewModal}
        modal_id={`website-preview-${website.id}`}
        show={isPreviewModalOpen}
        title={website.title}
        size="xl"
        hide_padding
      >
        <iframe
          src={formatSiteUrl()}
          sandbox=""
          title={website.title}
          className="w-100 h-100"
        />
      </OlittModal>
    )}
  </>
);

ModalWrapper.propTypes = {
  website: PropTypes.instanceOf(Site).isRequired,
  onCloseShareSite: PropTypes.func,
  is_share_site_visible: PropTypes.bool.isRequired,
  isPreviewModalOpen: PropTypes.bool.isRequired,
  onClosePreviewModal: PropTypes.func.isRequired,
  showPreview: PropTypes.bool,
  formatSiteUrl: PropTypes.func.isRequired,
};

ModalWrapper.defaultProps = {
  onCloseShareSite: () => {},
  showPreview: false,
};

function WebsiteCard({
  website,
  over_image_action,
  wide_view,
  is_premium,
  is_share_site_visible,
  onCloseShareSite,
  image_width,
  image_height,
  view_mode,
  showPreview,
  actionsList,
}) {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const formatSiteUrl = useCallback(() => {
    if (
      website.olitt_domain.startsWith("http:") ||
      website.olitt_domain.startsWith("https:")
    ) {
      return website.olitt_domain;
    }
    return `${window.location.protocol}//${website.olitt_domain}`;
  }, [website.olitt_domain]);

  const handlePreview = useCallback(() => {
    const { host } = window.location;
    if (
      extractDomain(window.location.href) !==
        extractDomain(website.olitt_domain) ||
      website.olitt_domain.includes(host)
    ) {
      window.open(formatSiteUrl(), "_blank");
    } else {
      setIsPreviewModalOpen(true);
    }
  }, [website.olitt_domain, formatSiteUrl]);

  const onClosePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  const wrapper_classes = wide_view
    ? "row no-gutters mb-4 ms-1 border col-md-12 shadow-sm p-md-0"
    : "col-md-6 row no-gutters mb-2 me-md-2";

  const image_section_classes = wide_view
    ? "col-md-4 p-0"
    : "col-md-12 border border-bottom-0 p-0";

  const content_section_classes = wide_view
    ? "col-md-8 p-0"
    : "col-md-12 border border-top-0 p-0";

  return (
    <>
      <ModalWrapper
        website={website}
        onCloseShareSite={onCloseShareSite}
        is_share_site_visible={is_share_site_visible}
        isPreviewModalOpen={isPreviewModalOpen}
        onClosePreviewModal={onClosePreviewModal}
        showPreview={showPreview}
        formatSiteUrl={formatSiteUrl}
      />
      <div className={wrapper_classes}>
        <div className={image_section_classes}>
          <ImageSection
            website={website}
            over_image_action={over_image_action}
            showPreview={showPreview}
            handlePreview={handlePreview}
            image_width={image_width}
            image_height={image_height}
          />
        </div>
        <div className={content_section_classes}>
          <ContentSection
            website={website}
            view_mode={view_mode}
            wide_view={wide_view}
            is_premium={is_premium}
            actionsList={actionsList}
            image_action={over_image_action}
          />
        </div>
      </div>
    </>
  );
}

WebsiteCard.propTypes = {
  website: PropTypes.instanceOf(Site).isRequired,
  over_image_action: PropTypes.instanceOf(Object),
  wide_view: PropTypes.bool,
  is_premium: PropTypes.bool,
  is_share_site_visible: PropTypes.bool.isRequired,
  onCloseShareSite: PropTypes.func,
  image_width: PropTypes.number,
  image_height: PropTypes.number,
  view_mode: PropTypes.oneOf(["templates", "sites"]),
  showPreview: PropTypes.bool,
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      showConfirm: PropTypes.bool,
      confirm: PropTypes.shape({
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        confirm_button_label: PropTypes.string.isRequired,
        action_done_title: PropTypes.string.isRequired,
        action_done_message: PropTypes.string.isRequired,
        confirm_button_color: PropTypes.string,
      }),
    }),
  ),
};

WebsiteCard.defaultProps = {
  is_premium: false,
  over_image_action: {},
  wide_view: false,
  view_mode: "",
  image_width: 500,
  image_height: 0,
  onCloseShareSite: () => {
    // Do nothing
  },
  actionsList: [],
  showPreview: false,
};

export default WebsiteCard;
