import _ from "lodash";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "../../base/components/Icons";
import Form from "../../core/components/Form";
import { InputType } from "../../core/constants/Enumerators";
import { FormInput } from "../../core/models/FormInput";
import { removeUnderscore } from "../../core/utilities/commonUtilities";
import { CheckPhishingDomainAPI } from "../LoudgunAPI";
import { URL_STREAM_PHISHING_CHECK } from "../LoudgunConstants";

export default function CheckPhishingDomain() {
  const [is_checking_phishing_site, setIsCheckingPhishingSite] =
    useState(false);
  const [error, setError] = useState(null);
  const [state, setState] = useState({
    checks_results: {},
    is_checking: false,
  });
  const api: any = new CheckPhishingDomainAPI();
  const inputs: Array = [
    new FormInput(
      InputType.text,
      "domain",
      "Website url",
      "Enter website url",
      "",
      true,
      true,
    ),
  ];

  const streamPhishingCheck: any = (parameters) => {
    const client: any = new WebSocket(URL_STREAM_PHISHING_CHECK);
    const payload: any = {
      action: "subscribe_instance",
      request_id: parameters.check_uuid,
      check_uuid: parameters.check_uuid,
      pk: parameters.id,
    };
    client.onopen = () => {
      client.send(JSON.stringify(payload));
    };

    client.onmessage = (e) => {
      const message: any = JSON.parse(e.data);
      const data: object = message.data || {};
      const { is_checking = true, checks_results = {} }: any = data;
      if (Object.keys(checks_results).length !== 0) {
        if ([false, "false"].includes(is_checking)) {
          setIsCheckingPhishingSite(false);
        }
        setState((prevState) => {
          return { ...prevState, ...{ checks_results, is_checking } };
        });
      }
    };
  };

  const checkPhishingWebsite: any = (parameters) => {
    setIsCheckingPhishingSite(true);
    setState((prevState) => {
      return { ...prevState, ...{ checks_results: {}, is_checking: false } };
    });
    api.checkPhishingUrl(
      Object.assign(parameters, { check_uuid: uuidv4() }),
      (is_successful, phishing_check_or_error) => {
        if (is_successful) {
          setError(null);
          streamPhishingCheck(phishing_check_or_error);
        } else {
          setError(phishing_check_or_error);
          setIsCheckingPhishingSite(false);
        }
      },
    );
  };

  const resultCheckIcon: any = (result_check) => {
    if (result_check === "progressing") {
      return (
        <div
          className="spinner-border spinner-border-sm text-primary"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      );
    }
    if (result_check) {
      return (
        <span className="text-success">
          <Icon icon="check-circle-fill" />
        </span>
      );
    }
    return (
      <span className="text-danger">
        <Icon icon="x-circle-fill" />
      </span>
    );
  };

  const { checks_results, is_checking } = state;
  let queue_processing_message: any = <div />;
  if (!is_checking && is_checking_phishing_site) {
    queue_processing_message = (
      <span className="mt-2">
        You are next on the queue
        <span className="text-success ms-1">
          <Icon icon="emoji-smile" />
        </span>
      </span>
    );
  }
  const phishingCheckResults: any = Object.keys(checks_results).map((key) => {
    return (
      <tr key={uuidv4()}>
        <td>{_.upperFirst(removeUnderscore(key))}</td>
        <td>{resultCheckIcon(checks_results[key])}</td>
      </tr>
    );
  });

  return (
    <div className="d-flex flex-column align-items-center col-md-12">
      <Form
        form_classes="col-md-8"
        onSubmit={checkPhishingWebsite}
        is_submitting={is_checking_phishing_site}
        error={error}
        submit_text="Check website for phishing"
        processing_label="checking"
        inputs={inputs}
        no_of_columns={1}
      />
      {queue_processing_message}
      <table className="table table-hover col-4 mt-5">
        <tbody>{phishingCheckResults}</tbody>
      </table>
    </div>
  );
}
