import PropTypes from "prop-types";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Widget from "modules/marketing/components/dashboard/Widget";
import MarketingAPI from "modules/marketing/MarketingAPI";

const ContactsChart = React.memo(({ match, filterParams }) => {
  const [widget, setWidget] = useState("created-leads-in-time");
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);

  const { widgetData, error, isLoading } = API.getDashboardWidget(
    widget,
    filterParams,
  );

  const widgets = [
    {
      value: "created-leads-in-time",
      label: "New Contacts Over Time",
      tooltip:
        "This chart illustrates the number of new contacts created on your site over a specific period. It helps you track the growth of your contact list by showing when and how many new leads are being added.",
    },
    {
      value: "anonymous-vs-identified-leads",
      label: "Anonymous vs Known Contacts",
      tooltip:
        "This widget compares the number of anonymous visitors (those without identifying information) to known contacts (those with identifying information) on your site. It provides insights into how effectively visitors are being converted into identifiable contacts.",
    },
  ];

  return (
    <Widget
      widget={widget}
      widgets={widgets}
      isLoading={isLoading}
      error={error}
      data={widgetData}
      onSelectWidget={setWidget}
    />
  );
});

ContactsChart.defaultProps = {
  filterParams: {},
};

ContactsChart.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  filterParams: PropTypes.shape({
    dateFrom: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired,
    timeUnit: PropTypes.string.isRequired,
  }),
};

export default withRouter(ContactsChart);
