import PropTypes from "prop-types";
import PropertySelectionForm from "./PropertySelectionForm";
import Modal from "modules/base/components/Modal";

function PropertiesModal({ properties, showCharts }) {
  const modalId = "select-property-modal";

  return (
    <Modal modal_title="Select Property" modal_id={modalId} size="md">
      <PropertySelectionForm
        currentModalId={modalId}
        properties={properties}
        showCharts={showCharts}
      />
    </Modal>
  );
}

PropertiesModal.propTypes = {
  properties: PropTypes.arrayOf(Object).isRequired,
  showCharts: PropTypes.func.isRequired,
};

export default PropertiesModal;
