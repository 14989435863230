import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";
import Modal from "modules/base/components/Modal";
import DesignFocusItemTemplate from "modules/marketing/components/Focusitems/DesignFocusItemTemplate";

const DesignTemplateModal = memo(({ state, setState }) => {
  const modalId = `design-focus-items-template-${state.id ?? "new"}-modal`;

  const handleSetState = useCallback(
    (newState) => {
      setState(newState);
    },
    [setState],
  );

  return (
    <Modal modal_title="Design Template" modal_id={modalId} size="xl">
      <DesignFocusItemTemplate
        state={state}
        setState={handleSetState}
        currentModalId={modalId}
      />
    </Modal>
  );
});

DesignTemplateModal.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
};

export default DesignTemplateModal;
