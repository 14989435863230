import { useQuery } from "@tanstack/react-query";
import ShopApi from "../ShopAPIs";

const TWO_MINUTES = 2 * 60 * 60;

/**
 * @name fetchPayment
 * @description Custom hook that returns sorted Payment JSON Details from API.
 * @param site_id
 * @param state_eq
 * @returns sortedPayments
 * @constructor
 */

function fetchPayment(site_id, state_eq) {
  const shopApi = new ShopApi();

  return new Promise((resolve, reject) => {
    shopApi.getPayments(
      site_id,
      state_eq,
      (response) => {
        const sortedPayments = response.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        resolve(sortedPayments);
      },
      (error_message) => {
        reject(error_message);
      },
    );
  });
}

function usePayments(site_id, state_eq) {
  const { isLoading, data } = useQuery({
    queryKey: ["payments", site_id, state_eq],
    queryFn: () => fetchPayment(site_id, state_eq),
    staleTime: TWO_MINUTES,
  });

  return {
    payments: data ?? [],
    isLoading,
  };
}

export default usePayments;
