import { DateUtility } from "../core/utilities/DateUtility";

export class MyDomain {
  id;

  domain_name;

  status;

  registration_period;

  purchase_price;

  renewal_price;

  payment_method;

  date_due: { year: number, month: number, day: number };

  constructor(parameters) {
    this.id = parameters.id;
    this.domain_name = parameters.domain_name;
    this.status = parameters.status;
    this.registration_period = parameters.registration_period;
    this.purchase_price = parameters.purchase_price;
    this.renewal_price = parameters.renewal_price;
    this.payment_method = parameters.payment_method;
    const { date_due } = parameters;
    this.date_due = date_due && {
      year: parseInt(date_due.year, 10),
      month: parseInt(date_due.month, 10),
      day: parseInt(date_due.day, 10),
    };
  }

  getFormattedDueDate(with_time = false) {
    const date: any = this.date_due;
    return DateUtility.formatDate(with_time, date.day, date.month, date.year);
  }
}

export class DomainSuggestion {
  id: number;

  domain_name: string;

  is_available: boolean;

  registration_price: number;

  renewal_price: number;

  transfer_price: number;

  constructor(parameters) {
    this.domain_name = parameters.domain_name;
    this.is_available = parameters.is_available;
    this.registration_price = parameters.registration_price;
    this.renewal_price = parameters.renewal_price;
    this.transfer_price = parameters.transfer_price;
  }
}

export class DomainOrder {
  id;

  ordered_by;

  domain_name;

  payment_method;

  order_id;

  invoice_id;

  domain_id;

  constructor(parameters) {
    this.id = parameters.id;
    this.ordered_by = parameters.ordered_by;
    this.domain_name = parameters.domain;
    this.payment_method = parameters.payment_method;
    this.order_id = parameters.order_id;
    this.invoice_id = parameters.invoice_id;
    this.domain_id = parameters.domain_id;
  }
}

export class DomainsMenu {
  title;

  icon;

  path;

  is_active;

  sub_menus;

  constructor(
    title: string,
    icon,
    path: string,
    is_active: boolean,
    sub_menus = [],
  ) {
    this.title = title;
    this.icon = icon;
    this.path = path;
    this.is_active = is_active;
    this.sub_menus = sub_menus;
  }
}
