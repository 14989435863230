import SegmentsList from "modules/marketing/components/segments/SegmentsList";
import SubscriptionCheck from "modules/marketing/layouts/SubscriptionCheck";
import { MARKETING_CONTACTS_SLUG } from "modules/marketing/MarketingConstants";

function Segments() {
  return (
    <SubscriptionCheck
      slug={MARKETING_CONTACTS_SLUG}
      component={<SegmentsList />}
    />
  );
}

export default Segments;
