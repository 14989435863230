import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AuthenticationActions } from "../../account/AccountActions";
import { BillingAPI } from "../../billing/BillingAPIs";
import {
  BILLING_PLANS_DATA,
  PAYMENT_TITLE,
  URL_PLANS,
} from "../../billing/BillingConstants";
import { BillingPlan } from "../../billing/BillingModels";
import InvoiceList from "../../billing/components/InvoiceList";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import { ErrorModal } from "../../core/components/ErrorModal";
import { PaymentModal } from "../../core/components/PaymentModal";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import { DateUtility } from "../../core/utilities/DateUtility";
import { PriceUtility } from "../../core/utilities/PriceUtility";
import {
  fetchUrlData,
  getUrlData,
} from "../../core/utilities/redux/componentActions";
import invoice_icon from "../assets/invoice.svg";
import PageLoadingIndicator from "../components/PageLoadingIndicator";
import WebsiteSidebar from "../components/SidebarWebsite";

import SiteApi from "modules/website/api/SiteApi";

function WebsiteBilling(props) {
  const authenticationActions: any = new AuthenticationActions();
  const user: any = authenticationActions.retrieveAuthenticatedUser();
  const site_id: any = props.match.params.id;
  const { paid_plan } = props;

  const [loading_website, setIsLoadingWebsite] = useState(true);
  const [website, setWebsite] = useState([]);
  const [is_loading_invoices, setIsLoadingInvoices] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [error, setError] = useState(null);
  const [is_fetching_plans, setIsFetchingPlans] = useState(false);
  const [is_subscribing_to_plan, setIsSubscribingToPlan] = useState(false);
  const [payment_url, setPaymentUrl] = useState(null);

  const fetchBillingPlans: any = () =>
    fetchUrlData(BILLING_PLANS_DATA, URL_PLANS, props);

  const siteApi: any = new SiteApi();
  const loadWebsiteInvoices: any = () => {
    setIsLoadingInvoices(true);
    siteApi.retrieveWebsiteInvoices(
      site_id,
      (is_successful, invoices_or_error) => {
        setIsLoadingInvoices(false);
        if (is_successful) {
          setInvoices(invoices_or_error);
        } else {
          setError(invoices_or_error);
        }
      },
    );
  };

  const loadWebsite: any = () => {
    setPaymentUrl(null);
    setIsLoadingWebsite(true);
    siteApi.retrieveWebsite_(site_id, (is_successful, sites_or_error) => {
      if (is_successful) {
        fetchBillingPlans();
        loadWebsiteInvoices();
        setWebsite(sites_or_error);
      } else {
        setError(sites_or_error);
      }
      setIsLoadingWebsite(false);
    });
  };

  useEffect(loadWebsite, []);

  const handleInvoicePayUrl: any = (pay_url) => {
    let payment_complete_url: any = pay_url;
    payment_complete_url = payment_complete_url.replace(
      new RegExp("((http):)", "u"),
      "https:",
    );
    setPaymentUrl(payment_complete_url);
  };
  const onClosePaymentModal: any = () => {
    if (payment_url) {
      loadWebsite();
    }
  };

  if (loading_website) {
    return <PageLoadingIndicator />;
  }
  if (!user) {
    loadWebsite();
    return <PageLoadingIndicator />;
  }

  let main_content;
  if (is_fetching_plans) {
    main_content = <ComponentLoadingIndicator />;
  } else {
    const upgradeSubscription: any = () => {
      const billing_api: any = new BillingAPI();
      billing_api.navigateToPricing(website, paid_plan);
    };

    let payments;
    if (is_loading_invoices) {
      payments = <ComponentLoadingIndicator />;
    } else {
      payments = (
        <InvoiceList
          user={user}
          invoices={invoices}
          handleInvoicePayUrl={handleInvoicePayUrl}
        />
      );
    }

    const header_card_style: { minWidth: string } = { minWidth: "325px" };

    let next_payment_date: string = "No Renewal";
    if (website.subscription_renewal_date.getTime() !== 0) {
      next_payment_date = `On ${DateUtility.formatDateShort(
        website.subscription_renewal_date,
      )}`;
    }
    let upgrade_btn_text: string = "Upgrade";
    if (website.subscription_plan.pricing.amount > 0) {
      upgrade_btn_text = "Change Plan";
    }

    let invoice_heading;
    if (invoices.length > 0) {
      invoice_heading = (
        <div className="flex-grow-1 d-flex align-items-center">
          <p className="h2 my-3">Invoices</p>
          <button
            type="button"
            onClick={loadWebsiteInvoices}
            className="btn text-primary shadow-none"
          >
            <i className="fa fa-refresh  my-3" />
          </button>
        </div>
      );
    }
    main_content = (
      <div>
        <p className="h2 my-3">Billing details</p>

        <div className="row no-gutters">
          <div
            className="col-sm-3 mb-2 card shadow border-primary"
            style={header_card_style}
          >
            <div className="card-body">
              <div className="embed-responsive embed-responsive-16by9">
                <div className="embed-responsive-item">
                  <div className="h-100 d-flex flex-column">
                    <div className="flex-grow-1 d-flex mb-2">
                      <div className="flex-grow-1 d-flex flex-column justify-content-between">
                        <strong className="text-muted">
                          Current subscription plan
                        </strong>
                        <div className="text-olitt-grey">
                          <span className="me-2 h3">
                            {website.subscription_plan.pricing.currency}
                          </span>
                          <span className="h3">
                            {PriceUtility.getMonthlyPrice(
                              website.subscription_plan.pricing,
                            )}
                          </span>
                          <span className="display-5">/month</span>
                        </div>
                        <strong className="text-olitt-grey">
                          {website.subscription_plan.name}
                        </strong>
                      </div>
                      <img
                        className="img-circle"
                        src={website.subscription_plan.icon}
                        height="40px"
                        alt=""
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <button
                        type="button"
                        disabled={is_subscribing_to_plan}
                        className="btn btn-olitt-grey shadow-none"
                        onClick={() => upgradeSubscription()}
                      >
                        {upgrade_btn_text}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-2" />
          <div
            className="col-sm-3 mb-2 card shadow-sm border-0"
            style={header_card_style}
          >
            <div className="card-body">
              <div className="embed-responsive embed-responsive-16by9">
                <div className="embed-responsive-item">
                  <div className="h-100 d-flex">
                    <div className="flex-grow-1 d-flex flex-column justify-content-between">
                      <strong className="text-muted">Next payment</strong>
                      <div className="text-olitt-grey">
                        <span className="me-2 h3">
                          {website.subscription_plan.pricing.currency}
                        </span>
                        <span className="h3">
                          {PriceUtility.getTotalPrice(
                            website.subscription_plan.pricing,
                          )}
                        </span>
                      </div>
                      <strong className="text-olitt-grey">
                        {next_payment_date}
                      </strong>
                    </div>

                    <img
                      className="img-circle"
                      src={invoice_icon}
                      height="40px"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {invoice_heading}
        <div className="row-no-gutters">{payments}</div>
      </div>
    );
  }

  const close_modal_button: any = (
    <button
      type="button"
      className="btn btn-olitt-grey px-4"
      onClick={() => setError(null)}
    >
      Close
    </button>
  );

  return (
    <SidebarWrapper sidebar={<WebsiteSidebar website={website} />}>
      {main_content}
      <ErrorModal
        modal_id="billing-error"
        show={error !== null}
        onClose={() => setError(null)}
        positive_button={close_modal_button}
        short_desc={"Something didn't go quite right."}
        suggestion={error}
      />
      <PaymentModal
        show={payment_url !== null}
        onClose={onClosePaymentModal}
        payment_url={payment_url}
        title={PAYMENT_TITLE}
      />
    </SidebarWrapper>
  );
}

WebsiteBilling.propTypes = {
  site_id: PropTypes.number,
  is_loading_billing_plans: PropTypes.bool,
  paid_plan: PropTypes.instanceOf(Object).isRequired,
};

WebsiteBilling.defaultProps = {
  site_id: null,
  is_loading_billing_plans: false,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const billing_plan_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    BILLING_PLANS_DATA,
  );
  const is_loading_billing_plans: any = billing_plan_data.isFetching;
  let billing_plans: null[] = [];
  let paid_plan: null[] = [];
  if (billing_plan_data.items.length > 0) {
    billing_plans = billing_plan_data.items.map(
      (parameters) => new BillingPlan(parameters),
    );
    paid_plan = billing_plans.find((billing_plan) => {
      return billing_plan.pricing.amount > 0.0;
    });
  }
  return { is_loading_billing_plans, paid_plan };
}

export default connect(mapStateToProps)(withRouter(WebsiteBilling));
