import PropTypes from "prop-types";
import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";
import { Icon } from "../../base/components/Icons";

export function ShareButtons({ is_vertical, hashtag, message, subject, url }) {
  const copyTextToClipboard: any = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success(`${text} copied to the clipboard`);
      },
      (err) => {},
    );
  };

  let wrapper_classes: string =
    "d-flex align-items-center justify-content-between";
  if (is_vertical) {
    wrapper_classes = `${wrapper_classes} flex-column`;
  }

  return (
    <div className={wrapper_classes}>
      <button
        type="button"
        className="me-2 btn btn-outline-primary shadow-none"
        onClick={() => copyTextToClipboard(url)}
      >
        <Icon icon="clipboard" />
        <span className="ms-2 d-none d-md-inline"> Copy </span>
      </button>

      <FacebookShareButton
        url={url}
        quote={message}
        hashtag={hashtag || "#olitt"}
        className="me-2"
      >
        <FacebookIcon size={36} borderRadius={6} />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={message} className="me-2">
        <TwitterIcon size={36} borderRadius={6} />
      </TwitterShareButton>

      <WhatsappShareButton url={url} title={message} className="me-2">
        <WhatsappIcon size={36} borderRadius={6} />
      </WhatsappShareButton>

      <EmailShareButton url={url} subject={subject} body={message}>
        <EmailIcon size={36} borderRadius={6} />
      </EmailShareButton>
    </div>
  );
}

ShareButtons.propTypes = {
  is_vertical: PropTypes.bool,
  hashtag: PropTypes.string,
  url: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
};

ShareButtons.defaultProps = {
  is_vertical: false,
  hashtag: false,
};
