export const ListBlock = (editor, opts = {}) => {
    const bm = editor.BlockManager;
    bm.add("bootstrap-list", {
        label: `List`,
        activate: true,
        select: true,
        category: "Basic",
        content:'<h1><ul> <li>Item 1</li><li>Item 2</li><li>Item 3</li><li>Item 4</li></ul></h1>',
        media: '<i class="oicon-editor-list-ul" style="font-size: 42px;"></i>'

    });
};