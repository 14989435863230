export class DateUtility {
  static formatDate(date: Date, show_time: boolean = false): string {
    const options: {
      weekday: string,
      year: string,
      month: string,
      day: string,
    } = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    if (show_time) {
      // ToDo: Add time serialization options
    }
    return new Intl.DateTimeFormat("en-US", options).format(date).toString();
  }

  static formatDateShort(date: Date, show_time: boolean = false): string {
    const options: { day: string, month: string, year: string } = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date).toString();
  }

  static incrementDate(date: Date, years = 0, months = 0, days = 0): string {
    date.setFullYear(date.getFullYear() + years);
    date.setMonth(date.getMonth() + months);
    return date;
  }
}
