import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useMemo } from "react";
import { Doughnut, Pie } from "react-chartjs-2";
import GaugeChart from "react-gauge-chart";

ChartJS.register(ArcElement, Tooltip, Legend);

const chartOptions = { responsive: true, maintainAspectRatio: false };

const useChartRenderer = (chartData, chartType) => {
  return useMemo(() => {
    if (!chartData) return null;
    const chartComponents = {
      doughnut: () => (
        <div className="my-2 w-100 h-100">
          <Doughnut data={chartData} options={chartOptions} />
        </div>
      ),
      pie: () =>
        chartData.labels.length > 0 ? (
          <div className="w-100 h-100">
            <Pie data={chartData} options={chartOptions} />
          </div>
        ) : (
          <p className="card-text text-secondary">No Available Data</p>
        ),
      gauge: () => (
        <div className="my-2 w-100 h-100">
          <GaugeChart
            id="gauge-chart"
            nrOfLevels={30}
            percent={chartData.datasets[0].data[0] / 100}
            needleColor="#000000"
            textColor="#000000"
            colors={["#3498db", "#f39c12", "#e74c3c"]}
            animate={false}
          />
        </div>
      ),
    };

    return chartComponents[chartType]?.() || null;
  }, [chartData, chartType]);
};

export default useChartRenderer;
