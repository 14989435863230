const defaultOptions = {
  options: {},
  customRte: {},
  position: "left",
  ckeditor: "https://cdn.ckeditor.com/4.21.0/standard-all/ckeditor.js",
  onToolbar: () => {},
};

const isString = (value) => typeof value === "string";

const loadFromCDN = (url) => {
  let scr = document.querySelector(`script[src="${url}"]`);
  if (!scr) {
    scr = document.createElement("script");
    scr.src = url;
    document.head.appendChild(scr);
  }
  return scr;
};

const forEach = (items, clb) => {
  [].forEach.call(items, clb);
};

const stopPropagation = (ev) => ev.stopPropagation();

const toggleEventListener = (element, event, handler, action = "add") => {
  element[`${action}EventListener`](event, handler);
};

const plugin = (editor, options = {}) => {
  const opts = { ...defaultOptions, ...options };

  let ck;
  const { ckeditor } = opts;
  const hasWindow = typeof window !== "undefined";
  let dynamicLoad = false;

  if (ckeditor) {
    if (isString(ckeditor)) {
      if (hasWindow) {
        dynamicLoad = true;
        const scriptEl = loadFromCDN(ckeditor);
        scriptEl.onload = () => {
          ck = window.CKEDITOR;
        };
        scriptEl.onerror = (err) => {
          console.error(`Failed to load script: ${err?.message}`);
        };
      }
    } else if (ckeditor.inline) {
      ck = ckeditor;
    }
  } else if (hasWindow) {
    ck = window.CKEDITOR;
  }

  const updateEditorToolbars = () => setTimeout(() => editor.refresh(), 0);
  const logCkError = () => {
    editor.log("CKEDITOR instance not found", { level: "error" });
  };

  if (!ck && !dynamicLoad) {
    return logCkError();
  }

  const focus = (el, rte) => {
    if (rte?.focusManager?.hasFocus) return;
    el.contentEditable = "true";
    rte?.focus();
    updateEditorToolbars();
  };

  editor.setCustomRte({
    getContent(el, rte) {
      return rte.getData();
    },

    enable(el, rte) {
      if (el.getAttribute("data-editable") !== "false") {
        el.contentEditable = "true";
      }
      if (rte && rte.status !== "destroyed") {
        focus(el, rte);
        return rte;
      }

      if (!ck) {
        logCkError();
        return;
      }

      const rteToolbar = editor.RichTextEditor.getToolbarEl();
      forEach(rteToolbar.children, (child) => {
        child.style.display = "none";
      });

      const ckOptions = { ...opts.options, versionCheck: false };
      const plgName = "sharedspace";

      if (ckOptions.extraPlugins) {
        if (typeof ckOptions.extraPlugins === "string") {
          ckOptions.extraPlugins += `,${plgName}`;
        } else if (Array.isArray(ckOptions.extraPlugins)) {
          ckOptions.extraPlugins.push(plgName);
        }
      } else {
        ckOptions.extraPlugins = plgName;
      }

      if (!ckOptions.sharedSpaces) {
        ckOptions.sharedSpaces = { top: rteToolbar };
      }

      rte = ck.inline(el, ckOptions);

      rte.on("contentDom", () => {
        const editable = rte.editable();
        editable.attachListener(editable, "click", () => el.click());
      });

      rte.on("instanceReady", () => {
        const toolbar = rteToolbar.querySelector(`#cke_${rte.name}`);
        if (toolbar) {
          toolbar.style.display = "block";
          opts.onToolbar(toolbar);
        }
        editor.refresh();
        updateEditorToolbars();
      });

      rte.on("dialogShow", () => {
        const els = document.querySelectorAll(
          ".cke_dialog_background_cover, .cke_dialog_container",
        );
        forEach(els, (child) => {
          toggleEventListener(child, "mousedown", stopPropagation, "remove");
          toggleEventListener(child, "mousedown", stopPropagation, "add");
        });
      });

      rte.on("destroy", () => {
        const els = document.querySelectorAll(
          ".cke_dialog_background_cover, .cke_dialog_container",
        );
        forEach(els, (child) => {
          toggleEventListener(child, "mousedown", stopPropagation, "remove");
        });
      });

      rte.on("key", (ev) => {
        if (ev.data.keyCode === 13) updateEditorToolbars();
      });

      focus(el, rte);

      return rte;
    },

    disable(el, rte) {
      el.contentEditable = "false";
      rte?.focusManager?.blur(true);
    },

    ...opts.customRte,
  });

  editor.on("rteToolbarPosUpdate", (pos) => {
    const { elRect } = pos;

    switch (opts.position) {
      case "center":
        pos.left = elRect.width / 2 - pos.targetWidth / 2;
        break;
      case "right":
        pos.left = "";
        pos.right = 0;
        break;
      default:
        pos.left = 0;
        break;
    }
  });
};

export default plugin;
