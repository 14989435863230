import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import {
  closeModalById,
  handleInputChange,
} from "modules/base/utilities/Actions";
import NoteFormFields from "modules/marketing/components/notes/NoteFormFields";
import MarketingAPI from "modules/marketing/MarketingAPI";
import { Note } from "modules/marketing/MarketingModels";

function EditNoteForm({ note, match, currentModalId, contactId }) {
  const { id: siteId } = match.params;
  const updatedNote = { ...note, site: siteId };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [state, setState] = useState(updatedNote);

  const API = new MarketingAPI();

  const onInputChange = useCallback((e) =>
    handleInputChange(e.target.name, e.target.value, state, setState),
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const payload = {
      ...state,
      lead: contactId,
    };
    API.updateNote(
      state.id,
      payload,
      () => {
        setIsSubmitting(false);
        closeModalById(currentModalId);
        setSuccess({ message: "Note updated" });
      },
      (error_response) => {
        setError({
          message: error_response.message,
        });
        setIsSubmitting(false);
      },
      (error_response) => {
        setError(error_response.message);
        setIsSubmitting(false);
      },
    );
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      id="edit-contact-note-form"
      button_color="gray-900"
      button_label="Update"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      <NoteFormFields state={state} onInputChange={onInputChange} />
    </Form>
  );
}

EditNoteForm.defaultProps = {
  currentModalId: null,
};

EditNoteForm.propTypes = {
  note: PropTypes.instanceOf(Note).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string,
  contactId: PropTypes.string.isRequired,
};

export default withRouter(EditNoteForm);
