import BaseModel from "modules/core/models/BaseModel";

export class PaymentMethod {
  method;

  display_name;

  logo;

  constructor(parameters) {
    this.method = parameters.method ?? "Unknown";
    this.display_name = parameters.display_name ?? "Unknown";
    this.logo = parameters.logo;
  }
}

export class OrderItem {
  type: string;

  product_type: string;

  product: string;

  domain: string;

  billing_cycle: number;

  amount: number;

  status: string;

  constructor(parameters) {
    this.type = parameters.item_type;
    this.product_type = parameters.product_type;
    this.product = parameters.product;
    this.domain = parameters.domain;
    this.billing_cycle = parameters.billing_cycle;
    this.amount = parameters.amount;
    this.status = parameters.status;
  }
}

export class Order {
  id: number;

  order_number: number;

  date: Date;

  amount: number;

  invoice_id: number;

  status: string;

  payment_method: PaymentMethod;

  items: OrderItem[];

  constructor(parameters) {
    this.id = parameters.id;
    this.order_number = parameters.order_number;
    this.date = new Date(parameters.date);
    this.amount = parameters.amount;
    this.invoice_id = parameters.invoice_id;
    this.status = parameters.order_status;
    this.payment_status = parameters.payment_status;
    this.payment_method = new PaymentMethod({
      method: parameters.payment_method,
      display_name: parameters.payment_method,
      logo: null,
    });
    this.items = parameters.order_items.map(
      (order_item_parameters) => new OrderItem(order_item_parameters),
    );
  }
}

export class AccountTransaction {
  id: number;

  invoice_id: number;

  refund_id: number;

  transaction_id: number;

  payment_method: string;

  description: string;

  date: Date;

  amount: number;

  type: number;

  fees: number;

  rate: number;

  constructor(parameters) {
    this.id = parameters.id;
    this.invoice_id = parameters.invoice;
    this.refund_id = parameters.refund_id;
    this.transaction_id = parameters.transaction_id;
    this.payment_method = parameters.payment_method;
    this.description = parameters.description;
    this.date = new Date(parameters.date);
    this.amount = parameters.amount;
    this.type = parameters.transaction_type;
    this.fees = parameters.fees;
    this.rate = parameters.rate;
  }
}

export class Invoice {
  order: Order;

  transactions: AccountTransaction[];

  id: number;

  first_name: string;

  last_name: string;

  date_created: Date;

  date_due: Date;

  date_paid: Date;

  sub_total: number;

  credit: number;

  total: number;

  status: string;

  constructor(parameters) {
    this.order = new Order(parameters.order);
    this.transactions = parameters.transactions.map(
      (transaction_parameters) =>
        new AccountTransaction(transaction_parameters),
    );

    this.id = parameters.id;
    this.first_name = parameters.first_name;
    this.last_name = parameters.last_name;
    this.payment_url = parameters.payment_url;

    this.date_created = new Date(parameters.date_created);
    this.date_due = new Date(parameters.date_due);
    this.date_paid = new Date(parameters.date_paid);

    this.sub_total = (parameters.sub_total ?? 0.0).toFixed(2);
    this.credit = (parameters.credit ?? 0.0).toFixed(2);
    this.total = (parameters.total ?? 0.0).toFixed(2);

    this.status = parameters.status;
  }
}

export class WebsiteInvoice {
  id;

  series;

  number;

  provider;

  customer;

  archived_provider;

  archived_customer;

  due_date;

  issue_date;

  paid_date;

  cancel_date;

  sales_tax_name;

  sales_tax_percent;

  currency;

  state;

  proforma;

  invoice_entries;

  total;

  pdf_url;

  transactions;

  constructor(parameters) {
    this.id = parameters.id;
    this.series = parameters.series;
    this.number = parameters.number;
    this.provider = parameters.provider;
    this.customer = parameters.customer;
    this.archived_provider = parameters.archived_provider;
    this.archived_customer = parameters.archived_customer;
    this.due_date = parameters.due_date;
    this.issue_date = parameters.issue_date;
    this.paid_date = parameters.paid_date;
    this.cancel_date = parameters.cancel_date;
    this.sales_tax_name = parameters.sales_tax_name;
    this.sales_tax_percent = parameters.sales_tax_percent;
    this.currency = parameters.currency;
    this.state = parameters.state;
    this.proforma = parameters.proforma;
    this.invoice_entries = parameters.invoice_entries;
    this.total = parameters.total;
    this.pdf_url = parameters.pdf_url;
    this.transactions = parameters.transactions;
  }
}

export class BillingPlan extends BaseModel {
  id;

  name;

  billing_cycle;

  group;

  pricing;

  features;

  no_of_sites;

  currency;

  weekly_price;

  monthly_price;

  yearly_price;

  allow_weekly_subscription;

  allow_monthly_subscription;

  allow_yearly_subscription;

  description;

  constructor(parameters: any = {}) {
    super(parameters);
    this.id = parameters.id;
    this.name = parameters.name;
    this.billing_cycle = parameters.billing_cycle;
    this.group = parameters.group;
    this.currency = parameters.currency;
    this.pricing = parameters.pricing;
    this.features = parameters.features;
    this.no_of_sites = parameters.no_of_sites;
    this.icon = parameters.icon;
    this.description = parameters.description;
    this.weekly_price = parameters.weekly_price;
    this.monthly_price = parameters.monthly_price;
    this.yearly_price = parameters.yearly_price;
    this.allow_weekly_subscription = parameters.allow_weekly_subscription;
    this.allow_monthly_subscription = parameters.allow_monthly_subscription;
    this.allow_yearly_subscription = parameters.allow_yearly_subscription;
  }
}

export class SilverPlan {
  name;

  interval;

  interval_count;

  amount;

  currency;

  trial_period_days;

  constructor(parameters) {
    this.name = parameters.name;
    this.interval = parameters.interval;
    this.interval_count = parameters.interval_count;
    this.amount = parameters.amount;
    this.currency = parameters.currency;
    this.trial_period_days = parameters.trial_period_days;
  }
}

export class BillingSubscription {
  id: number;

  name: string;

  subscription_at: string;

  billing_interval: string;

  billing_time: string;

  plan: BillingPlan;

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.subscription_at = data.subscription_at;
    this.billing_interval = data.billing_interval;
    this.billing_time = data.billing_time;
    this.plan = new BillingPlan(data.plan);
  }
}
