import React, { useMemo } from "react";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";

/**
 * A generic rich text editor component
 * @returns {JSX.Element}
 * @constructor
 */
const WYSIWYGEditor = React.memo(
  ({
    title,
    name,
    controlFunc,
    content,
    placeholder,
    required,
    isDisabled,
    wrapperClasses,
    groupClass,
    PreAddon,
    PostAddon,
    theme,
  }) => {
    const className = useMemo(
      () => `form-group ${wrapperClasses || ""}`,
      [wrapperClasses],
    );
    const ariaLabel = useMemo(() => `WYSIWYG Editor for ${title}`, [title]);
    const Input = useMemo(
      () => (
        <div id={name}>
          <ReactQuill
            id={name}
            placeholder={placeholder}
            theme={theme}
            value={content}
            onChange={controlFunc}
            disabled={isDisabled}
            aria-label={ariaLabel}
          />
        </div>
      ),
      [name, placeholder, content, controlFunc, isDisabled, ariaLabel],
    );

    return (
      <div className={className}>
        <label htmlFor={name}>
          {title} {required ? "*" : ""}
        </label>
        <div className={groupClass}>
          {PreAddon}
          {Input}
          {PostAddon}
        </div>
      </div>
    );
  },
);

WYSIWYGEditor.defaultProps = {
  content: "",
  placeholder: "",
  required: false,
  isDisabled: false,
  wrapperClasses: "",
  groupClass: "",
  PreAddon: null,
  PostAddon: null,
  theme: "snow",
};

WYSIWYGEditor.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  content: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  wrapperClasses: PropTypes.string,
  groupClass: PropTypes.string,
  PreAddon: PropTypes.element,
  PostAddon: PropTypes.element,
  theme: PropTypes.oneOf(["snow", "bubble"]),
};

export default WYSIWYGEditor;
