import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Widget from "modules/marketing/components/dashboard/Widget";
import MarketingAPI from "modules/marketing/MarketingAPI";

function AssetsChart({ match, filterParams }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);

  const { assets, error, isLoading } = API.getAssets();
  const widgets = [{ value: "popular-assets", label: "Popular Files" }];

  return (
    <Widget
      widget="popular-assets"
      isLoading={isLoading}
      error={error}
      widgets={widgets}
      assets={assets}
      onSelectWidget={() => {}}
    />
  );
}

AssetsChart.defaultProps = {
  filterParams: {},
};

AssetsChart.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  filterParams: PropTypes.instanceOf(Object),
};

export default withRouter(AssetsChart);
