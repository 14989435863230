export const typeOptions = [
  { value: "form", label: "Collect Data" },
  { value: "notice", label: "Notification" },
  { value: "link", label: "Emphasize Link" },
];

export const whenToEngageOptions = [
  { value: "immediately", label: "Upon arrival" },
  { value: "scroll_slight", label: "After slightly scrolling down" },
  { value: "scroll_middle", label: "After scrolling to middle" },
  { value: "scroll_bottom", label: "After scrolling to bottom" },
  { value: "leave", label: "Visitor intends to leave" },
];

export const howOftenToEngageOptions = [
  { value: "everypage", label: "Every page" },
  { value: "once", label: "Once per session" },
  { value: "q2min", label: "Every 2 minutes" },
  { value: "q15min", label: "Every 15 minutes" },
  { value: "hourly", label: "One per hour" },
  { value: "daily", label: "One per day" },
];

export const styleOptions = [
  {
    value: "modal",
    label: "Modal",
    description: "Display a popup in the middle of the page",
  },
  {
    value: "bar",
    label: "Bar",
    description: "Display a bar across the top of the page",
  },
  {
    value: "notification",
    label: "Notification",
    description: "Display a small window in a corner of the page",
  },
  {
    value: "page",
    label: "Full page",
    description: "Display a window that covers the entire page",
  },
];

export const styleIconsMap = {
  modal: "window-stack",
  bar: "window-desktop",
  notification: "window",
  page: "fullscreen",
};

export const prebuiltFocusTemplatesModalId =
  "prebuilt-focus-templates-45tsjkakd-modal";
