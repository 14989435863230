import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import TextInput from "modules/base/components/inputs/TextInput";
import {
  closeModalById,
  handleInputChange,
} from "modules/base/utilities/Actions";
import ConfigureNodeForm from "modules/marketing/components/campaigns/nodes/configure/ConfigureNodeForm";
import ExecutionTiming from "modules/marketing/components/campaigns/nodes/configure/ExecutionTiming";

function AddRemoveDNC({
  campaignEvent,
  setState,
  currentModalId,
  updateEvent,
}) {
  const [campaignEventState, setCampaignEventState] = useState(campaignEvent);
  useEffect(() => {
    if (!campaignEventState.properties?.channels?.length) {
      setCampaignEventState({
        ...campaignEventState,
        properties: {
          ...campaignEventState.properties,
          channels: ["email"],
        },
      });
    }
  }, []);
  const onInputChange = useCallback((e) => {
    handleInputChange(
      e.target.name,
      e.target.value,
      campaignEventState,
      setCampaignEventState,
    );
  });
  const onClickConfigure = useCallback(() => {
    setState({ ...campaignEventState });
    closeModalById(currentModalId);
  });
  return (
    <ConfigureNodeForm
      campaignEvent={campaignEvent}
      campaignEventState={campaignEventState}
      handleSubmit={onClickConfigure}
      updateEvent={updateEvent}
    >
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <TextInput
          name="name"
          title="Name"
          content={campaignEventState.name ?? ""}
          controlFunc={onInputChange}
          required
        />
        <ExecutionTiming
          campaignEvent={campaignEventState}
          setState={setCampaignEventState}
          excludedTimeOptions={["immediate"]}
        />
      </div>
    </ConfigureNodeForm>
  );
}

AddRemoveDNC.propTypes = {
  campaignEvent: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  currentModalId: PropTypes.string.isRequired,
  updateEvent: PropTypes.func.isRequired,
};

export default AddRemoveDNC;
