import { useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import { PRIVACY_POLICY_URL } from "modules/core/constants/Constants";

function CookiesConsent() {
  useEffect(() => {
    /**
     * All config. options available here:
     * https://cookieconsent.orestbida.com/reference/configuration-reference.html
     */
    CookieConsent.run({
      root: "body",
      autoShow: true,
      disablePageInteraction: false,
      hideFromBots: true,
      mode: "opt-in",
      revision: 0,

      cookie: {
        name: "cc_cookie",
        domain: window.location.hostname,
        // path: '/',
        // sameSite: "Lax",
        expiresAfterDays: 365,
      },

      // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
      guiOptions: {
        consentModal: {
          layout: "cloud inline",
          position: "bottom center",
          equalWeightButtons: true,
          flipButtons: false,
        },
        preferencesModal: {
          layout: "box",
          equalWeightButtons: true,
          flipButtons: false,
        },
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true, // this category cannot be disabled
        },
        analytics: {
          enabled: true,
          autoClear: {
            cookies: [
              {
                name: /^_ga/, // regex: match all cookies starting with '_ga'
              },
              {
                name: "_gid", // string: exact cookie name
              },
            ],
          },

          // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
          services: {
            ga: {
              label: "Google Analytics",
              onAccept: () => {},
              onReject: () => {},
            },
            hotjar: {
              label: "Hotjar Analytics",
              onAccept: () => {},
              onReject: () => {},
            },
          },
        },
        ads: {},
      },

      language: {
        default: "en",
        translations: {
          en: {
            consentModal: {
              title: "We use cookies",
              description:
                "We and our partners use cookies and other tracking technologies to improve your browsing experience on our website, to analyze our website traffic, and to understand where our visitors are coming from. By browsing our website, you consent to our use of cookies and other tracking technologies.",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage Individual preferences",
              // closeIconLabel: 'Reject all and close modal',
              footer: `
                        <a href="${PRIVACY_POLICY_URL}" target="_blank">Privacy policy</a>
                    `,
            },
            preferencesModal: {
              title: "Manage cookie preferences",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Accept current selection",
              closeIconLabel: "Close modal",
              serviceCounterLabel: "Service|Services",
              sections: [
                {
                  title: "Your Privacy Choices",
                  description:
                    "In this panel you can express some preferences related to the processing of your personal information. You may review and change expressed choices at any time by resurfacing this panel via the provided link. To deny your consent to the specific processing activities described below, switch the toggles to off or use the “Reject all” button and confirm you want to save your choices.",
                },
                {
                  title: "Strictly Necessary",
                  description:
                    "These cookies are essential for the proper functioning of the website and cannot be disabled.",

                  // this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: "necessary",
                },
                {
                  title: "Performance and Analytics",
                  description:
                    "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                  linkedCategory: "analytics",
                  cookieTable: {
                    caption: "Cookie table",
                    headers: {
                      name: "Cookie",
                      domain: "Domain",
                      desc: "Description",
                    },
                    body: [
                      {
                        name: "_ga",
                        domain: window.location.hostname,
                        desc: "Google Analytics",
                      },
                      {
                        name: "_gid",
                        domain: window.location.hostname,
                        desc: "Google Analytics",
                      },
                      {
                        name: "_hj",
                        domain: window.location.hostname,
                        desc: "Hotjar Analytics",
                      },
                    ],
                  },
                },
                {
                  title: "More information",
                  description:
                    'For any queries in relation to my policy on cookies and your choices, please <a href="mailto:info@olitt.com">contact us</a>.',
                },
              ],
            },
          },
        },
      },
    });
  }, []);
  return <></>;
}

export default CookiesConsent;
