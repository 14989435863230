// eslint-disable-next-line max-classes-per-file
import BaseModel from "modules/core/models/BaseModel";

export class WidgetData extends BaseModel {
  chartType: string;

  chartData: Object;

  constructor(data: Object = {}) {
    super(data);
    this.chartType = data.chartType;
    this.chartData = data.chartData;
  }
}

export class Widget extends BaseModel {
  success: number;

  cached: boolean;

  execution_time: number;

  data: WidgetData;

  constructor(data: Object = {}) {
    super(data);
    this.success = data.success;
    this.cached = data.cached;
    this.execution_time = data.execution_time;
    this.data = data.data;
  }
}

export class AnalyticsData {
  dimensions: string[];

  metrics: string[];

  constructor(dimensions: string[], metrics: string[]) {
    this.dimensions = dimensions;
    this.metrics = metrics;
  }
}

export class Analytics {
  dimensions: string[];

  constructor(dimensions: string[]) {
    this.dimensions = dimensions;
  }
}

export class Stage extends BaseModel {
  id: number;

  name: string;

  category: Object;

  description: string;

  weight: number;

  isPublished: boolean;

  dateAdded: string;

  createdBy: number;

  createdByUser: string;

  dateModified: string;

  modifiedBy: number;

  modifiedByUser: string;

  publishUp: string;

  publishDown: string;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.category = data.category;
    this.description = data.description;
    this.weight = data.weight;
    this.isPublished = data.isPublished;
    this.dateAdded = data.dateAdded;
    this.createdBy = data.createdBy;
    this.createdByUser = data.createdByUser;
    this.dateModified = data.dateModified;
    this.modifiedBy = data.modifiedBy;
    this.modifiedByUser = data.modifiedByUser;
    this.publishUp = data.publishUp;
    this.publishDown = data.publishDown;
  }
}

export class Contact extends BaseModel {
  id: number;

  firstname: string;

  lastname: string;

  email: string;

  phone: string;

  mobile: string;

  title: string;

  company: string;

  address1: string;

  address2: string;

  city: string;

  state: string;

  stage: Stage;

  zipcode: string;

  country: string;

  linkedin: string;

  twitter: string;

  facebook: string;

  instagram: string;

  skype: string;

  created_at: string;

  updated_at: string;

  isPublished: boolean;

  dateAdded: string;

  dateModified: string;

  doNotContact: Array<Object>;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.email = data.email;
    this.phone = data.phone;
    this.mobile = data.mobile;
    this.title = data.title;
    this.company = data.company;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.zipcode = data.zipcode;
    this.city = data.city;
    this.state = data.state;
    this.stage = data.stage;
    this.country = data.country;
    this.linkedin = data.linkedin;
    this.twitter = data.twitter;
    this.facebook = data.facebook;
    this.instagram = data.instagram;
    this.skype = data.skype;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.isPublished = data.isPublished;
    this.dateAdded = data.dateAdded;
    this.dateModified = data.dateModified;
    this.doNotContact = data.doNotContact;
  }
}

export class FormField extends BaseModel {
  id: number;

  order: number;

  label: string;

  showLabel: boolean;

  alias: string;

  type: string;

  defaultValue: string;

  isRequired: boolean;

  leadField: boolean;

  mappedObject: string;

  mappedField: string;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.order = data.order;
    this.label = data.label;
    this.showLabel = data.showLabel;
    this.alias = data.alias;
    this.type = data.type;
    this.defaultValue = data.defaultValue;
    this.isRequired = data.isRequired;
    this.leadField = data.leadField;
    this.mappedObject = data.mappedObject;
    this.mappedField = data.mappedField;
  }
}

export class FormAction extends BaseModel {
  id: number;

  name: string;

  type: string;

  order: number;

  properties: Object | Array<Object>;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
    this.order = data.order;
    this.properties = data.properties;
  }
}

export class Form extends BaseModel {
  id: number;

  name: string;

  description: string;

  alias: string;

  isPublished: boolean;

  publishUp: string;

  publishDown: string;

  dateAdded: string;

  dateModified: string;

  postAction: string;

  postActionProperty: string;

  cachedHtml: string;

  fields: FormField[];

  actions: FormAction[];

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.alias = data.alias;
    this.isPublished = data.isPublished;
    this.publishUp = data.publishUp;
    this.publishDown = data.publishDown;
    this.dateAdded = data.dateAdded;
    this.dateModified = data.dateModified;
    this.postAction = data.postAction;
    this.postActionProperty = data.postActionProperty;
    this.cachedHtml = data.cachedHtml;
    const formField = new FormField();
    this.fields = formField.fromArray(data.fields ?? []);
    const formAction = new FormAction();
    this.actions = data.actions ? formAction.fromArray(data.actions) : [];
  }
}

export class Field extends BaseModel {
  label: string;

  alias: string;

  type: string;

  defaultValue: string;

  isRequired: boolean;

  constructor(data: Object = {}) {
    super(data);
    this.label = data.label;
    this.alias = data.alias;
    this.type = data.type;
    this.defaultValue = data.defaultValue;
    this.isRequired = data.isRequired;
  }
}

export class BarProperties extends BaseModel {
  allow_hide: number;

  push_page: number;

  sticky: number;

  size: string;

  placement: string;

  constructor(data: Object = {}) {
    super(data);
    this.allow_hide = data.allow_hide;
    this.push_page = data.push_page;
    this.sticky = data.sticky;
    this.size = data.size;
    this.placement = data.placement;
  }
}

export class ModalProperties extends BaseModel {
  placement: string;

  constructor(data: Object = {}) {
    super(data);
    this.placement = data.placement;
  }
}

export class NotificationProperties extends BaseModel {
  placement: string;

  constructor(data: Object = {}) {
    super(data);
    this.placement = data.placement;
  }
}

export class ColorsProperties extends BaseModel {
  primary: string;

  text: string;

  button: string;

  button_text: string;

  constructor(data: Object = {}) {
    super(data);
    this.primary = data.primary;
    this.text = data.text;
    this.button = data.button;
    this.button_text = data.button_text;
  }
}

export class ContentProperties extends BaseModel {
  headline: string;

  tagline: string;

  link_text: string;

  link_url: string;

  link_new_window: number;

  font: string;

  css: string;

  constructor(data: Object = {}) {
    super(data);
    this.headline = data.headline;
    this.tagline = data.tagline;
    this.link_text = data.link_text;
    this.link_url = data.link_url;
    this.link_new_window = data.link_new_window;
    this.font = data.font;
    this.css = data.css;
  }
}

export class FocusItemProperties extends BaseModel {
  bar: BarProperties;

  modal: ModalProperties;

  notification: NotificationProperties;

  colors: ColorsProperties;

  content: ContentProperties;

  animate: number;

  link_activation: number;

  when: string;

  timeout: number;

  frequency: string;

  stop_after_conversion: number;

  constructor(data: Object = {}) {
    super(data);
    this.bar = new BarProperties(data.bar ?? {});
    this.modal = new ModalProperties(data.modal ?? {});
    this.notification = new NotificationProperties(data.notification ?? {});
    this.colors = new ColorsProperties(data.colors ?? {});
    this.content = new ContentProperties(data.content ?? {});
    this.animate = data.animate;
    this.link_activation = data.link_activation;
    this.when = data.when;
    this.timeout = data.timeout;
    this.frequency = data.frequency;
    this.stop_after_conversion = data.stop_after_conversion;
  }
}

export class FocusItem extends BaseModel {
  id: number;

  name: string;

  description: string;

  type: string;

  style: string;

  isPublished: boolean;

  publishUp: string;

  publishDown: string;

  dateAdded: string;

  dateModified: string;

  properties: FocusItemProperties;

  form: number;

  site: number;

  pages: number[];

  htmlMode: string;

  html: string;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.type = data.type;
    this.style = data.style;
    this.isPublished = data.isPublished;
    this.publishUp = data.publishUp;
    this.publishDown = data.publishDown;
    this.dateAdded = data.dateAdded;
    this.dateModified = data.dateModified;
    this.properties = new FocusItemProperties(data.properties ?? {});
    this.form = data.form;
    this.site = data.site;
    this.pages = data.pages;
    this.htmlMode = data.htmlMode;
    this.html = data.html;
  }
}

class FormSubmissionForm extends BaseModel {
  id: number;

  name: string;

  alias: string;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.alias = data.alias;
  }
}

class FormSubmissionLead extends BaseModel {
  id: number;

  points: number;

  color: string;

  title: string;

  firstname: string;

  lastname: string;

  company: string;

  position: string;

  email: string;

  mobile: string;

  phone: string;

  address1: string;

  address2: string;

  city: string;

  state: string;

  zipcode: string;

  timezone: string;

  country: string;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.points = data.points;
    this.color = data.color;
    this.title = data.title;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.company = data.company;
    this.position = data.position;
    this.email = data.email;
    this.mobile = data.mobile;
    this.phone = data.phone;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.city = data.city;
    this.state = data.state;
    this.zipcode = data.zipcode;
    this.timezone = data.timezone;
    this.country = data.country;
  }
}

export class FormSubmission extends BaseModel {
  id: number;

  form: FormSubmissionForm;

  lead: FormSubmissionLead;

  dateSubmitted: string;

  referer: string;

  results: Object;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.form = new FormSubmissionForm(data.form ?? {});
    this.lead = new FormSubmissionLead(data.lead ?? {});
    this.dateSubmitted = data.dateSubmitted;
    this.referer = data.referer;
    this.results = data.results;
  }
}

export class FormsSubmissionsStatistics extends BaseModel {
  total: number;

  stats: Object;

  most_popular: number;

  frequency: number;

  constructor(data: Object = {}) {
    super(data);
    this.total = data.total;
    this.stats = data.stats;
    this.most_popular = data.most_popular;
    this.frequency = data.frequency;
  }
}

export class SegmentFilterProperties extends BaseModel {
  filter: string | Array<string>;

  constructor(data: Object = {}) {
    super(data);
    this.filter = data.filter;
  }
}

export class ContactSegmentFilter extends BaseModel {
  object: string;

  glue: string;

  field: string;

  type: string;

  operator: string;

  properties: SegmentFilterProperties;

  filter: Array<string>;

  constructor(data: Object = {}) {
    super(data);
    this.object = data.object;
    this.glue = data.glue;
    this.field = data.field;
    this.type = data.type;
    this.operator = data.operator;
    this.properties = new SegmentFilterProperties(data.properties ?? {});
    this.filter = data.filter;
  }
}

export class Segment extends BaseModel {
  id: number;

  name: string;

  description: string;

  alias: string;

  isPublished: boolean;

  dateAdded: string;

  dateModified: string;

  filters: Array<ContactSegmentFilter>;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.alias = data.alias;
    this.isPublished = data.isPublished;
    this.dateAdded = data.dateAdded;
    this.dateModified = data.dateModified;
    const contactSegmentFilter = new ContactSegmentFilter();
    this.filters = contactSegmentFilter.fromArray(data.filters ?? []);
  }
}

export class ContactSegment extends BaseModel {
  id: number;

  name: string;

  alias: string;

  dateAdded: string;

  manuallyAdded: boolean;

  manuallyRemoved: boolean;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.alias = data.alias;
    this.dateAdded = data.dateAdded;
    this.manuallyAdded = data.manuallyAdded;
    this.manuallyRemoved = data.manuallyRemoved;
  }
}

export class ContactCampaign extends BaseModel {
  id: number;

  name: string;

  segmentMembership: number[];

  dateAdded: string;

  manuallyAdded: boolean;

  manuallyRemoved: boolean;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.segmentMembership = data.segmentMembership;
    this.dateAdded = data.dateAdded;
    this.manuallyAdded = data.manuallyAdded;
    this.manuallyRemoved = data.manuallyRemoved;
  }
}

export class ContactStatistics extends BaseModel {
  days: number;

  previous: number;

  new: number;

  percentage_growth: number;

  constructor(data: Object = {}) {
    super(data);
    this.days = data.days;
    this.previous = data.previous;
    this.new = data.new;
    this.percentage_growth = data.percentage_growth;
  }
}

export class EmailTemplate extends BaseModel {
  id: number;

  name: string;

  subject: string;

  fromName: string;

  fromAddress: string;

  isPublished: boolean;

  dateAdded: string;

  dateModified: string;

  readCount: number;

  sentCount: number;

  customHtml: string;

  plainText: string;

  unsubscribeForm: number;

  emailType: string;

  lists: Segment[];

  publishUp: string;

  publishDown: string;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.subject = data.subject;
    this.fromName = data.fromName;
    this.fromAddress = data.fromAddress;
    this.isPublished = data.isPublished;
    this.dateAdded = data.dateAdded;
    this.dateModified = data.dateModified;
    this.readCount = data.readCount;
    this.sentCount = data.sentCount;
    this.customHtml = data.customHtml;
    this.plainText = data.plainText;
    this.unsubscribeForm = data.unsubscribeForm;
    this.emailType = data.emailType;
    const segment = new Segment();
    this.lists = segment.fromArray(data.lists ?? []);
    this.publishDown = data.publishDown;
    this.publishUp = data.publishUp;
  }
}

export class EmailStatistics extends BaseModel {
  total: number;

  stats: Object;

  failed: number;

  read: number;

  sent: number;

  constructor(data: Object = {}) {
    super(data);
    this.total = data.total;
    this.stats = data.stats;
    this.failed = data.failed;
    this.read = data.read;
    this.sent = data.sent;
  }
}

export class CampaignEvent extends BaseModel {
  id: number;

  name: string;

  description: string;

  type: string;

  eventType: string;

  channel: string;

  channelId: number;

  order: number;

  properties: Object;

  triggerDate: string;

  triggerInterval: string;

  triggerIntervalUnit: string;

  triggerHour: string;

  triggerRestrictedStartHour: string;

  triggerRestrictedStopHour: string;

  triggerRestrictedDaysOfWeek: Array<number>;

  triggerMode: string;

  decisionPath: string;

  parent: Object;

  children: Array<Object>;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.type = data.type;
    this.eventType = data.eventType;
    this.channel = data.channel;
    this.channelId = data.channelId;
    this.order = data.order;
    this.properties = data.properties;
    this.triggerDate = data.triggerDate;
    this.triggerInterval = data.triggerInterval;
    this.triggerIntervalUnit = data.triggerIntervalUnit;
    this.triggerHour = data.triggerHour;
    this.triggerRestrictedStartHour = data.triggerRestrictedStartHour;
    this.triggerRestrictedStopHour = data.triggerRestrictedStopHour;
    this.triggerRestrictedDaysOfWeek = data.triggerRestrictedDaysOfWeek;
    this.triggerMode = data.triggerMode;
    this.decisionPath = data.decisionPath;
    this.parent = data.parent ? new CampaignEvent(data.parent) : null;
    this.children = data.children
      ? new CampaignEvent().fromArray(data.children)
      : [];
  }
}

export class CanvasSettingsNode extends BaseModel {
  id: number | string;

  positionX: number;

  positionY: number;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.positionX = parseInt(data.positionX, 10);
    this.positionY = parseInt(data.positionY, 10);
  }
}

export class ConnectionAnchors extends BaseModel {
  source: string;

  target: string;

  constructor(data: Object = {}) {
    super(data);
    this.source = data.source;
    this.target = data.target;
  }
}

export class CanvasSettingsConnection extends BaseModel {
  sourceId: number | string;

  targetId: number | string;

  anchors: ConnectionAnchors;

  constructor(data: Object = {}) {
    super(data);
    this.sourceId = data.sourceId;
    this.targetId = data.targetId;
    this.anchors = new ConnectionAnchors(data.anchors ?? {});
  }
}

export class CanvasSettings extends BaseModel {
  nodes: Array<CanvasSettingsNode>;

  connections: Array<CanvasSettingsConnection>;

  constructor(data: Object = {}) {
    super(data);
    const canvasSettingsNode = new CanvasSettingsNode();
    this.nodes = canvasSettingsNode.fromArray(data.nodes ?? []);
    const canvasSettingsConnection = new CanvasSettingsConnection();
    this.connections = canvasSettingsConnection.fromArray(
      data.connections ?? [],
    );
  }
}

export class Campaign extends BaseModel {
  id: number;

  name: string;

  alias: string;

  description: string;

  isPublished: boolean;

  dateAdded: string;

  dateModified: string;

  lists: Segment[];

  forms: Form[];

  event: CampaignEvent;

  canvasSettings: CanvasSettings;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.alias = data.alias;
    this.description = data.description;
    this.isPublished = data.isPublished;
    this.dateAdded = data.dateAdded;
    this.dateModified = data.dateModified;
    const segment = new Segment();
    this.lists = segment.fromArray(data.lists ?? []);
    const form = new Form();
    this.forms = form.fromArray(data.forms ?? []);
    const event = new CampaignEvent();
    this.events = event.fromArray(data.events ?? []);
    this.canvasSettings = new CanvasSettings(data.canvasSettings ?? {});
  }
}

export class AIEmailTemplate extends BaseModel {
  content: string;

  constructor(data: Object = {}) {
    super(data);
    this.content = data.content;
  }
}

export class Channel extends BaseModel {
  id: number;

  name: string;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
  }
}

export class ContentItem extends BaseModel {
  id: number;

  subject: string;

  channel: Channel;

  auto_generate_content: boolean;

  content: string;

  created_at: string;

  updated_at: string;

  site: number;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.subject = data.subject;
    this.channel = data.channel ? new Channel(data.channel) : null;
    this.auto_generate_content = data.auto_generate_content;
    this.content = data.content;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.site = data.site;
  }
}

export class Note extends BaseModel {
  id: id;

  text: string;

  type: string;

  lead: number;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.text = data.text;
    this.type = data.type;
    this.lead = data.lead;
  }
}

export class Asset extends BaseModel {
  id: number;

  title: string;

  description: string;

  isPublished: boolean;

  downloadUrl: string;

  dateAdded: string;

  dateModified: string;

  file: string;

  storageLocation: string;

  downloadCount: number;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.isPublished = data.isPublished;
    this.downloadUrl = data.downloadUrl;
    this.dateAdded = data.dateAdded;
    this.dateModified = data.dateModified;
    this.file = data.file;
    this.storageLocation = data.storageLocation;
    this.downloadCount = data.downloadCount;
  }
}

export class GooglePropertyStream {
  id: number;

  default_uri: string;

  constructor(data: Object = {}) {
    this.id = data.id;
    this.default_uri = data.default_uri;
  }
}

export class GoogleProperty extends BaseModel {
  name: string;

  display_name: string;

  industry_category: string;

  time_zone: string;

  currency_code: string;

  service_level: string;

  create_time: string;

  update_time: string;

  parent: string;

  account: string;

  streams: [GooglePropertyStream];

  constructor(data: Object = {}) {
    super(data);
    this.name = data.name;
    this.display_name = data.display_name;
    this.industry_category = data.industry_category;
    this.time_zone = data.time_zone;
    this.currency_code = data.currency_code;
    this.service_level = data.service_level;
    this.create_time = data.create_time;
    this.update_time = data.update_time;
    this.parent = data.parent;
    this.account = data.account;
    this.streams = data.streams;
  }
}

export class Transport extends BaseModel {
  id: number;

  username: string;

  password: string;

  host: string;

  port: number;

  scheme: string;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.username = data.username;
    this.password = data.password;
    this.host = data.host;
    this.port = data.port;
    this.scheme = data.scheme;
  }
}
