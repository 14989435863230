import React from "react";
import {
  booleanFormatter,
  convertDate,
  imageFormatter,
} from "../core/components/datagrid/DataGridUtilities";
import { API_BASE_URL } from "../core/constants/Endpoints";
import { PATH_MAIN_ROUTE } from "../core/constants/Routes";
import OrderState from "./components/orders/components/OrderState";

/**
 * API Endpoints
 */
export const URL_STORES: string = `${API_BASE_URL}/stores`;
export const URL_SHOP_DETAILS: string = `${URL_STORES}/{site_id}/`;
export const URL_PAYMENT_METHODS: string = `${URL_STORES}/{site_id}/payment-methods/`;
export const URL_PRODUCTS: string = `${URL_STORES}/{site_id}/products/`;
export const URL_TAXONOMIES: string = `${URL_STORES}/{site_id}/taxonomies/`;
export const URL_PAGES: string = `${URL_STORES}/{site_id}/pages/`;
export const URL_SHOP_FACEBOOK_PAGES: string = `${URL_STORES}/{site_id}/facebook/page/`;
export const URL_SHOP_FACEBOOK_CATALOGS: string = `${URL_STORES}/{site_id}/facebook/catalog/`;
export const URL_SHOP_FACEBOOK_PRODUCT: string = `${URL_STORES}/{site_id}/facebook/product/`;
export const URL_SHOP_FACEBOOK_PRODUCT_LOGS: string = `${URL_STORES}/{site_id}/facebook/product/logs/`;
export const URL_SHOP_INSTAGRAM_ACCOUNTS: string = `${URL_STORES}/{site_id}/facebook/instagram/`;
export const URL_SHOP_PUBLISH_PRODUCT_TO_FACEBOOK: string = `${URL_STORES}/{site_id}/facebook/product/post/facebook/`;
export const URL_SHOP_PUBLISH_PRODUCT_TO_INSTAGRAM: string = `${URL_STORES}/{site_id}/facebook/product/post/instagram/`;
export const URL_ORDER: string = `${URL_STORES}/{site_id}/orders/`;
export const URL_PAYMENTS: string = `${URL_STORES}/{site_id}/payments/`;
export const URL_ORDER_DETAILS: string = `${URL_STORES}/{site_id}/orders/{order_id}`;
/**
 * Routes
 */
export const PATH_SHOP: string = `${PATH_MAIN_ROUTE}/shop/:id`;
export const PATH_DASHBOARD: string = `${PATH_SHOP}/dashboard`;
export const PATH_SHOP_DETAILS: string = `${PATH_SHOP}/details`;
export const PATH_SHOP_TAXONOMIES: string = `${PATH_SHOP}/taxonomies`;
export const PATH_SHOP_DESIGN: string = `${PATH_SHOP}/design`;
export const PATH_SHOP_PAYMENT_METHODS: string = `${PATH_SHOP}/payment-methods`;
export const PATH_SHOP_PAYMENT_METHODS_CREATE: string = `${PATH_SHOP_PAYMENT_METHODS}/create`;
export const PATH_SHOP_PAYMENT_METHODS_EDIT: string = `${PATH_SHOP_PAYMENT_METHODS}/edit/:payment_method_id`;
export const PATH_SHOP_PRODUCTS: string = `${PATH_SHOP}/products`;
export const PATH_SHOP_SOCIAL: string = `${PATH_SHOP}/social`;
export const PATH_SHOP_SEO: string = `${PATH_SHOP}/seo`;
export const PATH_SHOP_EMAIL_SETTINGS: string = `${PATH_SHOP}/email-settings`;
/**
 * External routes
 */
export const URL_SPREE_PRODUCTS_PATH: string = "/admin/products";
export const URL_SPREE_ORDERS_PATH: string = "/admin/orders";
export const URL_SPREE_PAYMENTS_PATH: string = "/admin/payments";
export const URL_SPREE_PRODUCT_EDIT_PATH: string = `${URL_SPREE_PRODUCTS_PATH}/{product_slug}/edit`;
export const URL_SPREE_PRODUCT_CREATE_PATH: string = `${URL_SPREE_PRODUCTS_PATH}/new`;

/**
 * @name PAYMENT_METHODS_TABLE_COLUMNS
 * @type {[{Header: string, accessor: string},{Header: string, accessor: string},{Header: string, accessor: string, Cell: (function(*): *)}]}
 */
export const PAYMENT_METHODS_TABLE_COLUMNS: string[] = [
  {
    accessor: "name",
    Header: "Name",
  },
  {
    accessor: "type_display",
    Header: "Provider",
  },
  {
    accessor: "active",
    Header: "Active",
    Cell: (value: any) => booleanFormatter(value),
  },
];

export const PRODUCTS_TABLE_COLUMNS: string[] = [
  {
    Header: "",
    accessor: "image_url",
    Cell: (tableInstance: any) => imageFormatter(tableInstance),
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Price",
    accessor: "display_price",
  },
  {
    Header: "Available",
    accessor: "available",
    Cell: (tableInstance: any) => booleanFormatter(tableInstance),
  },
  {
    Header: "In stock",
    accessor: "in_stock",
    Cell: (tableInstance: any) => booleanFormatter(tableInstance),
  },
];

export const ON_DEMAND_RRODUCTS_TABLE: string[] = [
  {
    Header: "",
    accessor: "image_url",
    Cell: ({ value }) => {
      return (
        <div>
          <div className="row d-flex">
            <div className="column d-flex flex-grow-2">
              <img
                className="img-thumbnail img-fluid img-responsive w-80 h-60"
                src={value}
                alt={value}
              />
            </div>
          </div>
        </div>
      );
    },
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "In stock",
    accessor: "in_stock",
    Cell: (tableInstance: any) => booleanFormatter(tableInstance),
  },
  {
    Header: "Price",
    accessor: "display_price",
  },
];

export const TAXONOMIES_TABLE_COLUMNS: string[] = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Permalink",
    accessor: "permalink",
  },
  {
    Header: "SEO Title",
    accessor: "seo_title",
  },
];

export const ORDER_TABLE_COLUMNS = [
  {
    Header: "Order Number",
    accessor: "number",
    Cell: ({ value }) => {
      return <p>{value}</p>;
    },
  },
  {
    Header: "Price",
    accessor: "total",
  },
  {
    Header: "State",
    accessor: "state",
    Cell: ({ value }) => {
      return <OrderState type={value} />;
    },
  },
  {
    Header: "Date",
    accessor: "created_at",
    Cell: ({ value }) => {
      return <p>{convertDate(value)}</p>;
    },
  },
];

export const FACEBOOK_APP_ID: string = "2102930169869194";
export const FACEBOOK_PERMISSION_SCOPE: string =
  "instagram_basic,instagram_content_publish,business_management,pages_manage_posts,pages_read_engagement,pages_show_list,catalog_management,email,public_profile";
export const FACEBOOK_LOGIN_FIELDS: string = "name,email,picture";
