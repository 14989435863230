import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import PropTypes from "prop-types";
import { Line, Pie } from "react-chartjs-2";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import TableDisplay from "modules/marketing/components/dashboard/googleanalytics/TableDisplay";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
);

const BaseChartAnalytics = ({
  description,
  data,
  type,
  headerContent,
  tableData,
  tableHeaders,
  xLabel,
  yLabel,
  isLoading,
  error,
}) => {
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { raw: value } = tooltipItem;
            return `${value}`;
          },
        },
      },
    },
    scales:
      type === "line"
        ? {
            x: {
              title: {
                display: true,
                text: xLabel,
              },
            },
            y: {
              title: {
                display: true,
                text: yLabel,
              },
              min: 0,
            },
          }
        : undefined,
  };

  const noDataAvailable =
    !data ||
    !data.datasets ||
    data.datasets.length === 0 ||
    data.datasets[0].data.length === 0;

  return (
    <div className="card px-0 w-100 position-relative h-100">
      <div className="card-header d-flex justify-content-between align-items-center w-100 bg-dark text-white">
        <h5 className="card-title mb-0">{description}</h5>
      </div>
      <div className="card-body">
        {isLoading ? (
          <div className="rounded-3 h-stat-card shadow-sm skeleton-loading w-100 h-100" />
        ) : error ? (
          <NetworkMessageDisplay error={error} />
        ) : (
          <>
            {headerContent && <div className="row mb-3">{headerContent}</div>}
            {type === "table" ? (
              <TableDisplay tableData={tableData} tableHeaders={tableHeaders} />
            ) : noDataAvailable ? (
              <div className="d-flex justify-content-center align-items-top vh-60">
                <span>No data available</span>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center vh-49">
                <div
                  className={
                    type === "pie" ? "w-350 h-350 rounded-3" : "w-100 vh-40"
                  }
                >
                  {type === "line" ? (
                    <Line data={data} options={chartOptions} />
                  ) : (
                    <Pie data={data} options={chartOptions} />
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

BaseChartAnalytics.propTypes = {
  description: PropTypes.string,
  data: PropTypes.shape({
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
  }).isRequired,
  type: PropTypes.oneOf(["line", "pie", "table"]).isRequired,
  headerContent: PropTypes.node,
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  tableHeaders: PropTypes.arrayOf(PropTypes.string),
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

BaseChartAnalytics.defaultProps = {
  description: "",
  headerContent: null,
  tableData: [],
  tableHeaders: [],
  xLabel: "",
  yLabel: "",
  isLoading: false,
  error: null,
};

export default BaseChartAnalytics;
