import moment from "moment";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import DateTimeInput from "modules/base/components/inputs/DateTimeInput";
import SelectInput from "modules/base/components/inputs/SelectInput";
import TextInput from "modules/base/components/inputs/TextInput";
import { handleInputChange } from "modules/base/utilities/Actions";

function ExecutionWeekDays({ campaignEvent, setState }) {
  const onDayOfWeekChange = useCallback((selectedOptions) => {
    handleInputChange(
      "triggerRestrictedDaysOfWeek",
      selectedOptions.map((option) => option.value),
      campaignEvent,
      setState,
    );
  });
  const weekdaysOptions = [
    {
      label: "Monday",
      value: 1,
    },
    {
      label: "Tuesday",
      value: 2,
    },
    {
      label: "Wednesday",
      value: 3,
    },
    {
      label: "Thursday",
      value: 4,
    },
    {
      label: "Friday",
      value: 5,
    },
    {
      label: "Saturday",
      value: 6,
    },
    {
      label: "Sunday",
      value: 0,
    },
  ];
  return (
    <SelectInput
      name="triggerRestrictedDaysOfWeek"
      title="On which days should this event be executed?"
      options={weekdaysOptions}
      onChange={onDayOfWeekChange}
      predicate={(option) =>
        campaignEvent.triggerRestrictedDaysOfWeek?.includes(option.value)
      }
      isMulti
    />
  );
}

ExecutionWeekDays.propTypes = {
  campaignEvent: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
};

function ExecutionTime({ campaignEvent, setState }) {
  const [selectedExecutionTime, setSelectedExecutionTime] = useState(
    campaignEvent.triggerRestrictedStartHour ? "between" : "after",
  );
  const [stopHourMinTime, setStopHourMinTime] = useState(new Date());
  useEffect(() => {
    if (campaignEvent.triggerRestrictedStartHour) {
      const startHour = moment(campaignEvent.triggerRestrictedStartHour).utc();
      setStopHourMinTime(startHour.toDate());
    }
  }, [campaignEvent.triggerRestrictedStartHour]);
  const onInputChange = useCallback((e) => {
    handleInputChange(
      e.target.name,
      moment(e.target.value).utc().format("YYYY-MM-DDTHH:mm:ssZ"),
      campaignEvent,
      setState,
    );
  });
  const onExecutionTimeChange = useCallback((selectedOption) => {
    setSelectedExecutionTime(selectedOption.value);
    setState((prevState) => ({
      ...prevState,
      triggerRestrictedStartHour:
        selectedOption.value === "after" ? null : prevState.triggerHour,
      triggerRestrictedStopHour:
        selectedOption.value === "after"
          ? null
          : prevState.triggerRestrictedStopHour,
      triggerHour:
        selectedOption.value === "after" ? prevState.triggerHour : null,
    }));
  });
  const timingOptions = [
    {
      value: "after",
      label: "After a certain hour",
    },
    {
      value: "between",
      label: "Between certain hours",
    },
  ];
  return (
    <>
      <SelectInput
        name="executionTime"
        title="What time should this event be executed?"
        options={timingOptions}
        onChange={onExecutionTimeChange}
        predicate={(option) => option.value === selectedExecutionTime}
      />
      {selectedExecutionTime === "after" && (
        <DateTimeInput
          content={campaignEvent.triggerHour}
          name="triggerHour"
          title="After"
          controlFunc={onInputChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={60}
          timeCaption="Time"
          timeFormat="HH:mm"
          dateFormat="HH:mm"
        />
      )}
      {selectedExecutionTime === "between" && (
        <>
          <div className="col-12 col-md-6">
            <DateTimeInput
              content={campaignEvent.triggerRestrictedStartHour}
              name="triggerRestrictedStartHour"
              title="Between"
              controlFunc={onInputChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={60}
              timeCaption="Time"
              timeFormat="HH:mm"
              dateFormat="HH:mm"
            />
          </div>
          <div className="col-12 col-md-6">
            <DateTimeInput
              content={campaignEvent.triggerRestrictedStopHour}
              name="triggerRestrictedStopHour"
              title="and"
              controlFunc={onInputChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={60}
              timeCaption="Time"
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              minTime={stopHourMinTime}
              maxTime={moment("23:59", "HH:mm").toDate()}
            />
          </div>
        </>
      )}
      <ExecutionWeekDays campaignEvent={campaignEvent} setState={setState} />
    </>
  );
}

ExecutionTime.propTypes = {
  campaignEvent: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
};

function WaitTime({ campaignEvent, setState }) {
  const onInputChange = useCallback((e) => {
    handleInputChange(e.target.name, e.target.value, campaignEvent, setState);
  });
  const onTriggerIntervalUnitChange = useCallback((selectedOption) => {
    handleInputChange(
      "triggerIntervalUnit",
      selectedOption.value,
      campaignEvent,
      setState,
    );
  });
  const intervalUnitOptions = [
    {
      label: "Minute(s)",
      value: "i",
    },
    {
      label: "Hour(s)",
      value: "h",
    },
    {
      label: "Day(s)",
      value: "d",
    },
    {
      label: "Month(s)",
      value: "m",
    },
    {
      label: "Year(s)",
      value: "y",
    },
  ];
  const showExecutionTime = ["d", "m", "y"].includes(
    campaignEvent.triggerIntervalUnit,
  );
  return (
    <>
      <TextInput
        name="triggerInterval"
        title="After how long should this event be executed?"
        content={campaignEvent.triggerInterval ?? ""}
        controlFunc={onInputChange}
        required
        groupClass="input-group"
        PostAddon={
          <SelectInput
            title=""
            name="triggerIntervalUnit"
            options={intervalUnitOptions}
            onChange={onTriggerIntervalUnitChange}
            predicate={(option) =>
              option.value === campaignEvent.triggerIntervalUnit
            }
            isAddon
          />
        }
      />
      {showExecutionTime && (
        <ExecutionTime campaignEvent={campaignEvent} setState={setState} />
      )}
    </>
  );
}

WaitTime.propTypes = {
  campaignEvent: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
};

function ExecutionTiming({ campaignEvent, setState, excludedTimeOptions }) {
  const [selectedTriggerMode, setSelectedTriggerMode] = useState(
    campaignEvent.triggerMode,
  );
  const onTriggerModeChange = useCallback((selectedOption) => {
    setSelectedTriggerMode(selectedOption.value);
    setState((prevState) => ({
      ...prevState,
      triggerMode: selectedOption.value,
      triggerDate:
        selectedOption.value === "date" ? prevState.triggerDate : null,
    }));
  });
  const onInputChange = useCallback((e) => {
    handleInputChange(
      e.target.name,
      moment(e.target.value).utc().format("YYYY-MM-DDTHH:mm:ssZ"),
      campaignEvent,
      setState,
    );
  });
  let executionTimeOptions = [
    {
      label: "Immediately",
      value: "immediate",
    },
    {
      label: "At a relative time period",
      value: "interval",
    },
  ];
  if (excludedTimeOptions) {
    executionTimeOptions = executionTimeOptions.filter(
      (option) => !excludedTimeOptions.includes(option.value),
    );
  }
  return (
    <>
      <SelectInput
        name="triggerMode"
        title="When should this event be executed?"
        options={executionTimeOptions}
        onChange={onTriggerModeChange}
        predicate={(option) => option.value === campaignEvent.triggerMode}
        required
      />
      {selectedTriggerMode === "interval" && (
        <WaitTime campaignEvent={campaignEvent} setState={setState} />
      )}
      {selectedTriggerMode === "date" && (
        <DateTimeInput
          content={campaignEvent.triggerDate}
          name="triggerDate"
          title="At"
          controlFunc={onInputChange}
          showTimeSelect
          timeIntervals={60}
          timeCaption="Time"
          timeFormat="HH:mm"
          dateFormat="yyyy-MM-dd HH:mm"
          required
        />
      )}
    </>
  );
}

ExecutionTiming.defaultProps = {
  excludedTimeOptions: [],
};

ExecutionTiming.propTypes = {
  campaignEvent: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  excludedTimeOptions: PropTypes.instanceOf(Array),
};

export default ExecutionTiming;
