import { createColumnHelper } from "@tanstack/react-table";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SectionTitle from "modules/base/components/SectionTitle";
import DataTable from "modules/base/components/table/Table";
import { formatAmount, formatDate } from "modules/base/utilities/Actions";
import { formatBillingTransactionState } from "modules/base/utilities/RenderActions";
import { BillingAPI } from "modules/billing/BillingAPIs";

function Payments() {
  const billingAPI = new BillingAPI();
  const { transactions, error, isLoading } =
    billingAPI.getBillingTransactions();
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("invoice_number", {
      header: "Invoice number",
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("amount", {
      header: "Amount",
      cell: (info) =>
        formatAmount(info.row.original.amount, info.row.original.currency),
    }),
    columnHelper.accessor("invoice_issue_date", {
      header: "Invoice issued on",
      cell: (info) => formatDate(info.renderValue(), "ll"),
    }),
    columnHelper.accessor("invoice_due_date", {
      header: "Due on",
      cell: (info) => formatDate(info.renderValue(), "ll"),
    }),
    columnHelper.accessor("state", {
      header: "",
      cell: (info) => formatBillingTransactionState(info.renderValue()),
    }),
  ];
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <SectionTitle title="Payments" />
      <DataTable columns={columns} data={transactions} isLoading={isLoading} />
    </>
  );
}

export default Payments;
