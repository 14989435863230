import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import EditContentForm from "modules/marketing/components/content/EditContentForm";
import { ContentItem } from "modules/marketing/MarketingModels";

function EditContentModal(props) {
  const { row: contentItem } = props;
  const modalId = `edit-content-${contentItem.id}-modal`;
  return (
    <Modal modal_title="Edit Content Item" modal_id={modalId} size="md">
      <EditContentForm contentItem={contentItem} currentModalId={modalId} />
    </Modal>
  );
}

EditContentModal.propTypes = {
  row: PropTypes.instanceOf(ContentItem).isRequired,
};

export default EditContentModal;
