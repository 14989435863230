import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import SelectInput from "modules/base/components/inputs/SelectInput";
import TextInput from "modules/base/components/inputs/TextInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import {
  closeModalById,
  handleInputChange,
} from "modules/base/utilities/Actions";
import ConfigureNodeForm from "modules/marketing/components/campaigns/nodes/configure/ConfigureNodeForm";
import ExecutionTiming from "modules/marketing/components/campaigns/nodes/configure/ExecutionTiming";
import MarketingAPI from "modules/marketing/MarketingAPI";

const ChangeStage = ({
  campaignEvent,
  setState,
  match: {
    params: { id: siteId },
  },
  currentModalId,
  updateEvent,
}) => {
  const API = new MarketingAPI(siteId);
  const { stages, error, isLoading } = API.getStages();

  const [selectedStageId, setSelectedStageId] = useState(
    Number(campaignEvent.properties.stage),
  );
  const [campaignEventState, setCampaignEventState] = useState(campaignEvent);

  const onInputChange = useCallback(
    (e) => {
      handleInputChange(
        e.target.name,
        e.target.value,
        campaignEventState,
        setCampaignEventState,
      );
    },
    [campaignEventState, setCampaignEventState],
  );

  const onStageChange = useCallback(
    (selectedOption) => {
      setSelectedStageId(selectedOption.value);
      handleInputChange(
        "properties",
        {
          ...campaignEventState.properties,
          stage: selectedOption.value,
        },
        campaignEventState,
        setCampaignEventState,
      );
    },
    [campaignEventState, setCampaignEventState],
  );

  const onClickConfigure = useCallback(() => {
    setState({ ...campaignEventState });
    closeModalById(currentModalId);
  }, [campaignEventState, currentModalId, setState]);

  const stageOptions = useMemo(
    () =>
      stages.map((stage) => ({
        label: stage.name,
        value: stage.id,
      })),
    [stages],
  );

  if (isLoading) {
    return <NetworkActivityIndicator />;
  }

  return (
    <ConfigureNodeForm
      campaignEvent={campaignEvent}
      campaignEventState={campaignEventState}
      handleSubmit={onClickConfigure}
      updateEvent={updateEvent}
    >
      <NetworkMessageDisplay error={error} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <TextInput
          name="name"
          title="Name"
          content={campaignEventState.name ?? ""}
          controlFunc={onInputChange}
          required
        />
        <SelectInput
          name="stage"
          title="Contact Stage"
          options={stageOptions}
          onChange={onStageChange}
          predicate={(option) => option.value === selectedStageId}
          required
        />
        <ExecutionTiming
          campaignEvent={campaignEventState}
          setState={setCampaignEventState}
        />
      </div>
    </ConfigureNodeForm>
  );
};

ChangeStage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  campaignEvent: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  currentModalId: PropTypes.string.isRequired,
  updateEvent: PropTypes.func.isRequired,
};

export default withRouter(ChangeStage);
