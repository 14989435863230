import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import {
  closeModalById,
  deleteNullProperties,
  handleInputChange,
} from "modules/base/utilities/Actions";
import { formatDate } from "modules/marketing/Actions";
import EmailTemplateFormFields from "modules/marketing/components/emailstemplates/EmailTemplateFormFields";
import MarketingAPI from "modules/marketing/MarketingAPI";

import SiteApi from "modules/website/api/SiteApi";

const CreateTemplate = ({ currentModalId, prebuiltHtml, match, uuid }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [site, setSite] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const initialState = {
    id: uuid,
    customHtml: prebuiltHtml,
    scheduleEmail: false,
  };
  const [state, setState] = useState(initialState);
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const siteApi = new SiteApi();

  useEffect(() => {
    if (prebuiltHtml) {
      setState((prevState) => ({
        ...prevState,
        customHtml: prebuiltHtml,
      }));
    }
    siteApi.retrieveWebsite(
      siteId,
      (siteDetails) => {
        setSite(siteDetails);
      },
      (e) => {
        setError(e);
      },
    );
  }, [prebuiltHtml, siteId]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let fromAddress = null;
    if (site.custom_domain) {
      fromAddress = state.fromAddress
        ? `${state.fromAddress}@${site.custom_domain}`
        : null;
    }

    const formattedPublishUp = state.publishUp
      ? formatDate(state.publishUp).format("YYYY-MM-DD HH:mm")
      : null;

    const formattedPublishDown = state.publishDown
      ? formatDate(state.publishDown).format("YYYY-MM-DD HH:mm")
      : null;

    const updatedState = {
      ...state,
      fromAddress,
      publishUp: formattedPublishUp,
      publishDown: formattedPublishDown,
    };

    const unsubscribeLink =
      'If you no longer wish to receive our emails, click <a href="{unsubscribe_url}">here</a> to unsubscribe';

    let mauticcustomhtml = state.customHtml;
    if (mauticcustomhtml) {
      if (!mauticcustomhtml.includes(unsubscribeLink)) {
        mauticcustomhtml += `<p>${unsubscribeLink}</p>`;
      }
    }

    API.createEmailTemplate(
      deleteNullProperties({ ...updatedState, customHtml: mauticcustomhtml }),
      () => {
        setIsSubmitting(false);
        setError(null);
        setState(initialState);
        closeModalById(currentModalId);
        setSuccess({
          message: "Template created successfully",
        });
      },
      (error_response) => {
        setIsSubmitting(false);
        setError({
          message: error_response.message,
        });
        setSuccess(null);
      },
    );
  });

  const onInputChange = useCallback((e) =>
    handleInputChange(e.target.name, e.target.value, state, setState),
  );

  const onWYSIWYGEditorChange = useCallback(
    (value) => {
      setState((prevState) => ({
        ...prevState,
        customHtml: value,
      }));
    },
    [setState],
  );

  return (
    <Form
      handleSubmit={handleSubmit}
      id="create-template-form"
      button_color="gray-900"
      button_label="Create"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="div"
      padding="p-0"
    >
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      {site && (
        <EmailTemplateFormFields
          state={state}
          setState={setState}
          site={site}
          onInputChange={onInputChange}
          currentModalId={currentModalId}
          onWYSIWYGEditorChange={onWYSIWYGEditorChange}
        />
      )}
    </Form>
  );
};

CreateTemplate.propTypes = {
  currentModalId: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  prebuiltHtml: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

CreateTemplate.defaultProps = {
  prebuiltHtml: null,
};

export default withRouter(CreateTemplate);
