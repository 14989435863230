import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TopBar from "modules/core/components/Topbar";
import MainContainerWrapper from "modules/core/layouts/MainContainerWrapper";

export const open_sidebar_width: string = "19rem";
export const closed_sidebar_width: number = "4rem";

function SidebarWrapper({ sidebar, children }) {
  const default_sidebar_width: any =
    window.innerWidth > 768 ? open_sidebar_width : closed_sidebar_width;

  const [sidebar_width, setSidebarWidth] = useState(default_sidebar_width);

  useEffect(
    () =>
      window.addEventListener("resize", () => {
        const width: any =
          window.innerWidth > 768 ? open_sidebar_width : closed_sidebar_width;
        setSidebarWidth(width);
      }),
    [],
  );

  const sidebar_with_width: any = React.cloneElement(sidebar, {
    sidebar_width,
  });

  const main_content_style: { marginLeft: any } = { marginLeft: sidebar_width };
  return (
    <div className="min-vh-100">
      {sidebar_with_width}
      <TopBar />
      <div className="flex-grow-1" style={main_content_style}>
        <MainContainerWrapper>{children}</MainContainerWrapper>
      </div>
    </div>
  );
}

SidebarWrapper.propTypes = {
  sidebar: PropTypes.node.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default SidebarWrapper;
