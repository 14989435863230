/**
 * ExternalMenu model
 */
export class ExternalMenu {
  title;

  icon;

  url;

  constructor(title: string, icon, url: string) {
    this.title = title;
    this.icon = icon;
    this.url = url;
  }
}
