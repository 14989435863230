import PropTypes from "prop-types";
import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUrlData } from "../../../../../core/utilities/redux/componentActions";
import usePayments from "../../../../hooks/usePayments";
import useShopDetails from "../../../../hooks/useShopDetails";
import { PAYMENT_COMPLETE } from "../../../constants";

import stateCount from "./stateCounter";
import TopCard from "./TopCard";

const SHOP_DETAILS_DATA_KEY = "SHOP_DETAILS_DATA_KEY";

function RevenueCard(props) {
  const { match } = props;
  const { params } = match;
  const site_id = params.id;
  const { isLoading, payments } = usePayments(site_id);
  const { shopDetails, isLoading: isLoadingDetails } = useShopDetails(site_id);

  if (isLoading || isLoadingDetails) {
    return <div className="rounded-3 p-4 h-44r shadow-sm skeleton-loading" />;
  }

  function totalRevenue(_payments, state) {
    let totalAmount = 0;
    _payments.forEach((payment) => {
      if (payment.state === state) {
        totalAmount += parseFloat(payment.amount);
      }
    });
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: shopDetails?.default_currency ?? "USD",
      maximumFractionDigits: 1,
    }).format(totalAmount);
  }

  const states = [PAYMENT_COMPLETE];

  const cardRevenue = states.map((state) => {
    return (
      <TopCard
        key={state}
        title="Total Revenue"
        count={totalRevenue(payments, PAYMENT_COMPLETE)}
        itemsLabel={`Number of ${state} Payments`}
        itemCount={stateCount(payments, state)}
      />
    );
  });

  return <div>{cardRevenue}</div>;
}

RevenueCard.defaultProps = {};

RevenueCard.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

/**
 * @name mapStateToProps
 * Maps the state to the props.
 * @param state
 * @returns {{shop, isLoadingShop: (boolean|*)}}
 */
function mapStateToProps(state: any) {
  const { sessionVariables, dataByUrl } = state;
  const shop_details_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    SHOP_DETAILS_DATA_KEY,
  );
  return {
    isLoadingShop: shop_details_data.isFetching,
    shop: shop_details_data.items,
  };
}

export default connect(mapStateToProps)(withRouter(RevenueCard));
