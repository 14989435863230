import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ChangeSegmentsFields from "modules/marketing/components/contacts/ChangeSegmentsFields";

function ModifyContactSegments({ actionState, setActionState }) {
  const [state, setState] = useState({
    segmentsToAdd: actionState?.properties?.addToLists ?? [],
    segmentsToRemove: actionState?.properties?.removeFromLists ?? [],
  });
  useEffect(() => {
    setActionState({
      ...actionState,
      properties: {
        addToLists: state.segmentsToAdd,
        removeFromLists: state.segmentsToRemove,
      },
    });
  }, [state]);
  return (
    <>
      <ChangeSegmentsFields state={state} setState={setState} />
    </>
  );
}

ModifyContactSegments.propTypes = {
  actionState: PropTypes.instanceOf(Object).isRequired,
  setActionState: PropTypes.func.isRequired,
};

export default ModifyContactSegments;
