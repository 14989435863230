import AssetList from "modules/marketing/components/assets/AssetList";
import SubscriptionCheck from "modules/marketing/layouts/SubscriptionCheck";
import { MARKETING_ASSETS_SLUG } from "modules/marketing/MarketingConstants";

function MarketingAssets() {
  return (
    <SubscriptionCheck slug={MARKETING_ASSETS_SLUG} component={<AssetList />} />
  );
}

export default MarketingAssets;
