/**
 * @class FacebookLog
 * @description Model for Facebook Log
 */
export class FacebookLog {
  constructor(parameters) {
    this.id = parameters.id;
    this.site_id = parameters.site_id;
    this.product_id = parameters.product_id;
    this.facebook_product_id = parameters.facebook_product_id;
    this.date_posted_to_facebook = parameters.date_posted_to_facebook;
    this.date_posted_to_instagram = parameters.date_posted_to_instagram;
    this.date_posted_to_catalog = parameters.date_posted_to_catalog;
  }
}
