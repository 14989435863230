import moment from "moment";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import BaseChartAnalytics from "modules/marketing/components/dashboard/googleanalytics/BaseChartAnalytics";
import {
  combineParams,
  getTimeInterval,
} from "modules/marketing/components/dashboard/googleanalytics/utils";
import MarketingAPI from "modules/marketing/MarketingAPI";

const UserGrowthGraph = ({ match, filterParams, extraParams }) => {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);

  const allParams = combineParams(filterParams, extraParams);

  const { analyticsData, error, isLoading } =
    API.getGoogleAnalyticsData(allParams);

  const { timeLabels, format } = getTimeInterval(filterParams);

  const dateMap = new Map(timeLabels.map((label) => [label, 0]));

  let newUsers = 0;

  if (analyticsData.dimensions?.rows) {
    analyticsData.dimensions.rows.forEach((row) => {
      const rowDate = moment.utc(row.dimensions[0], "YYYYMMDD").format(format);

      if (dateMap.has(rowDate)) {
        dateMap.set(
          rowDate,
          dateMap.get(rowDate) + parseInt(row.metrics[0], 10),
        );
        newUsers += parseInt(row.metrics[1], 10);
      }
    });
  }

  const totalUsersOverTime = Array.from(dateMap.values());
  const totalVisits = totalUsersOverTime.reduce((a, b) => a + b, 0);

  const chartData = {
    labels: timeLabels,
    datasets: [
      {
        label: "Total Users",
        data: totalUsersOverTime,
        borderColor: "#6A7B8C",
        borderWidth: 1.5,
        fill: true,
        tension: 0.1,
      },
    ],
  };

  return (
    <BaseChartAnalytics
      description="User Growth Overview"
      data={chartData}
      xLabel="Time"
      yLabel="Number of users"
      type="line"
      isLoading={isLoading}
      error={error}
      headerContent={
        <div className="col">
          <div className="row">
            <div className="col-6">
              <h6>Total Visits: {totalVisits}</h6>
            </div>
            <div className="col-6 text-end">
              <h6>New Users: {newUsers}</h6>
            </div>
          </div>
        </div>
      }
    />
  );
};

UserGrowthGraph.defaultProps = {
  filterParams: {
    timeframe: "1month",
    dateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
    dateTo: moment().format("YYYY-MM-DD"),
  },
  extraParams: {},
};

UserGrowthGraph.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  filterParams: PropTypes.instanceOf(Object),
  extraParams: PropTypes.instanceOf(Object),
};

export default withRouter(UserGrowthGraph);
