import { Modal } from "bootstrap";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

/**
 * Olitt Modal
 */
export function OlittModal({
  modal_id,
  title,
  show,
  size,
  height,
  hide_padding,
  onClose,
  is_dismissable,
  children,
  isScrollable,
}) {
  const [isShown, setIsShown] = React.useState(false);
  const [modal, setModal] = React.useState(null);
  let passed_modal_id;
  if (modal_id === null || modal_id === "" || typeof modal_id === "undefined") {
    passed_modal_id = "modal";
  } else {
    passed_modal_id = modal_id;
  }

  /**
   * Show modal
   */
  const onShowModal = () => setIsShown(true);

  /**
   * Hide Modal
   */
  const onHideModal = () => {
    setIsShown(false);
    onClose();
  };

  useEffect(() => {
    const modal_status = show ? "show" : "hide";
    const modal_element = document.getElementById(passed_modal_id);
    if (!modal) {
      const modal_instance = new Modal(modal_element, {});
      setModal(modal_instance);
    }
    modal_element.addEventListener("hide.bs.modal", onHideModal);
    modal_element.addEventListener("show.bs.modal", onShowModal);
    if (modal) {
      if (modal_status === "show" && !isShown) {
        modal.show();
      } else {
        modal.hide();
      }
    }
    return () => {
      modal?.hide();
      modal_element?.removeEventListener("hide.bs.modal", onHideModal);
      modal_element?.removeEventListener("show.bs.modal", onShowModal);
    };
  }, [show]);

  let modal_dialog_classes: string = "modal-dialog modal-dialog-centered";
  let modal_body_classes = `modal-body vh-${height}`;
  if (hide_padding) {
    modal_body_classes = `${modal_body_classes} p-0`;
  }
  if (size) {
    modal_dialog_classes = `${modal_dialog_classes} modal-${size}`;
  }
  if (isScrollable) {
    modal_dialog_classes = `${modal_dialog_classes} modal-dialog-scrollable`;
  }

  let backdrop;
  if (!is_dismissable) {
    backdrop = "static";
  }

  return (
    <div
      className="modal fade"
      id={passed_modal_id}
      role="dialog"
      tabIndex="-1"
      data-keyboard="false"
      data-backdrop={backdrop}
    >
      <div className={modal_dialog_classes}>
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            />
          </div>
          <div className={modal_body_classes}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default OlittModal;

OlittModal.propTypes = {
  modal_id: PropTypes.string,
  title: PropTypes.string,
  hide_padding: PropTypes.bool,
  is_dismissable: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isScrollable: PropTypes.bool,
};

OlittModal.defaultProps = {
  title: null,
  modal_id: null,
  hide_padding: false,
  is_dismissable: false,
  size: "sm",
  isScrollable: true,
  height: 75,
};
