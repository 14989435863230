import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { UAParser } from "ua-parser-js";
import { UserApi } from "modules/account/AccountAPIs";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import BlockedPopupInstructions from "modules/core/components/BlockedPopupInstructions";
import IncompatibleDeviceWarning from "modules/core/components/IncompatibleDeviceWarning";
import ThirdPartyCookiesInstructions from "modules/core/components/ThirdPartyCookiesInstructions";
import TopBar from "modules/core/components/Topbar";
import { checkThirdPartyCookies } from "modules/core/utilities/commonUtilities";
import { Site } from "modules/website/WebsiteModels";
import SiteApi from "modules/website/api/SiteApi";

function LoadingIndicator() {
  return (
    <div className="position-absolute bottom-50 end-50">
      <NetworkActivityIndicator />
    </div>
  );
}

function CookieInstructions({ site }) {
  return (
    <div className="vh-100">
      <TopBar site={site} />
      <ThirdPartyCookiesInstructions />
    </div>
  );
}

CookieInstructions.propTypes = {
  site: PropTypes.instanceOf(Site).isRequired,
};

function PopupBlockedInstructions({ site }) {
  return (
    <div className="vh-100">
      <TopBar site={site} />
      <BlockedPopupInstructions />
    </div>
  );
}

PopupBlockedInstructions.propTypes = {
  site: PropTypes.instanceOf(Site).isRequired,
};

function IframeComponent({
  site,
  setIsStudioLoading,
  isIframeLoading,
  isMobile,
  authUrl,
  authenticationError,
}) {
  const [iframeUrl, setIframeUrl] = useState(null);
  useEffect(() => {
    if (!iframeUrl) {
      const params = new URLSearchParams({
        authUrl,
        redirectUrl: site?.studioUrl,
        projectId: site?.studioProjectId,
        studioBaseUrl: site?.studioBaseUrl,
      });
      setIframeUrl(
        `${site.studioBaseUrl}/static/sso.html?${params.toString()}`,
      );
    }
  });

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === 1 && node.classList.contains("studio-frame")) {
            node.addEventListener("load", () => setIsStudioLoading(true));
            node.addEventListener("unload", () => setIsStudioLoading(false));
          }
        });
      });
    });

    // Start observing the document body for added nodes
    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, [setIsStudioLoading]);

  if (authenticationError) {
    return <NetworkMessageDisplay error={authenticationError} />;
  }
  if (!iframeUrl) {
    return <LoadingIndicator />;
  }
  return (
    <>
      {isIframeLoading && <LoadingIndicator />}
      {isMobile && <IncompatibleDeviceWarning />}
      <iframe
        src={iframeUrl}
        width="100%"
        height="93.2%"
        style={{ border: "none" }}
        title="Website Studio"
        onLoad={() => setIsStudioLoading(false)}
      />
    </>
  );
}

IframeComponent.defaultProps = {
  authenticationError: null,
};

IframeComponent.propTypes = {
  site: PropTypes.instanceOf(Site).isRequired,
  setIsStudioLoading: PropTypes.func.isRequired,
  isIframeLoading: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  authUrl: PropTypes.string.isRequired,
  authenticationError: PropTypes.instanceOf(Error),
};

function WebsiteStudio(props) {
  const [isStudioLoading, setIsStudioLoading] = useState(true);
  const [isPopupBlocked, setIsPopupBlocked] = useState(false);
  const [authenticationError, setAuthenticationError] = useState(null);
  const [isCookieInstructionsVisible, setIsCookieInstructionsVisible] =
    useState(false);
  const userAPI = new UserApi();
  const siteApi = new SiteApi();

  const {
    match: {
      params: { id },
    },
  } = props;

  const { site, error, isLoading } = siteApi.getWebsite(id);

  const {
    studioSSO,
    error: plasmicSSOError,
    isLoading: isLoadingPlasmicSSO,
  } = userAPI.getStudioSSO();

  useEffect(() => {
    window.addEventListener("message", (event) => {
      const { origin } = new URL(site.studioBaseUrl ?? window.location.href);
      if (event.origin !== origin) {
        return;
      }
      if (event.data.eventType === "authentication-started") {
        setIsStudioLoading(true);
      } else if (event.data.eventType === "popup-blocked") {
        setIsPopupBlocked(true);
      } else if (event.data.eventType === "authentication-failed") {
        setIsStudioLoading(false);
        setAuthenticationError(
          new Error(
            "Authentication failed. Please refresh the page to try again.",
          ),
        );
      }
    });
  });

  useEffect(() => {
    checkThirdPartyCookies(
      (result) => setIsCookieInstructionsVisible(result !== true),
      () => setIsCookieInstructionsVisible(true),
    ).then();
  }, []);

  if (isPopupBlocked) {
    return <PopupBlockedInstructions site={site} />;
  }

  if (isLoadingPlasmicSSO || isLoading) {
    return <LoadingIndicator />;
  }

  if (plasmicSSOError || error) {
    return <NetworkMessageDisplay error={plasmicSSOError ?? error} />;
  }

  if (isCookieInstructionsVisible) {
    return <CookieInstructions site={site} />;
  }

  const uaParser = new UAParser(window.navigator.userAgent);
  const { device } = uaParser.getResult();
  const isMobile = device.type === "mobile";

  return (
    <div className="vh-100">
      <TopBar site={site} />
      <IframeComponent
        site={site}
        setIsStudioLoading={setIsStudioLoading}
        isIframeLoading={isStudioLoading}
        isMobile={isMobile}
        authUrl={studioSSO.auto_login_url}
        authenticationError={authenticationError}
      />
    </div>
  );
}

WebsiteStudio.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      page_id: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(WebsiteStudio);
