import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import Form from "modules/base/components/Form";
import CountryRegionSelector from "modules/base/components/inputs/CountryRegionSelect";
import PhoneNumberInput from "modules/base/components/inputs/PhoneNumberInput";
import TextInput from "modules/base/components/inputs/TextInput";
import Modal from "modules/base/components/Modal";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";

function BaseBillingDetailsForm(props) {
  const {
    handleSubmit,
    error,
    success,
    isSubmitting,
    isLoading,
    customer,
    state,
    setState,
    submit_button_color,
  } = props;
  useEffect(() => {
    setState({
      name: customer.name,
      email: customer.email,
      phone_number: customer.phone_number,
      address_line1: customer.address_line1,
      address_line2: customer.address_line2,
      zip_code: customer.zip_code,
      city: customer.city,
      state: customer.state,
      country: customer.country,
    });
  }, [isLoading]);

  function inputChange(e) {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  }

  function selectChange(field_name, field_value) {
    setState({ ...state, [field_name]: field_value });
  }

  const handleInputChange = useCallback((e) => inputChange(e));
  const handlePhoneNumberChange = useCallback((value) => {
    setState({ ...state, phone_number: value });
  });
  const handleSelectChange = useCallback((field_name, field_value) =>
    selectChange(field_name, field_value),
  );

  if (isLoading) {
    return <NetworkActivityIndicator />;
  }

  return (
    <>
      <SuccessActionIndicator success={success} />
      <Modal
        modal_title="Change details"
        modal_id="change-billing-details"
        size="md"
      >
        <NetworkMessageDisplay error={error} />
        <Form
          handleSubmit={handleSubmit}
          button_label="Update"
          id="deploy-vm"
          isSubmitting={isSubmitting}
          padding="p-0"
          fullLengthButton
          button_color={submit_button_color}
        >
          <div className="row row-cols-1 mb-2">
            <TextInput
              title="Name"
              name="name"
              content={state.name}
              controlFunc={handleInputChange}
              required
            />
            <PhoneNumberInput
              title="Phone number"
              name="phone_number"
              content={state.phone_number}
              controlFunc={handlePhoneNumberChange}
              required
            />
            <TextInput
              title="Address line 1"
              name="address_line1"
              content={state.address_line1}
              controlFunc={handleInputChange}
              required
            />
            <TextInput
              title="Address line 2"
              name="address_line2"
              content={state.address_line2}
              controlFunc={handleInputChange}
            />
            <TextInput
              title="Postal code"
              name="zip_code"
              content={state.zip_code}
              controlFunc={handleInputChange}
              required
            />
            <CountryRegionSelector
              region_field_name="state"
              controlFunc={handleSelectChange}
              current_country={state.country}
              current_region={state.state}
              current_city={state.city}
            />
          </div>
        </Form>
      </Modal>
    </>
  );
}

BaseBillingDetailsForm.defaultProps = {
  error: null,
  success: null,
  submit_button_color: "purple",
};

BaseBillingDetailsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Object),
  success: PropTypes.instanceOf(Object),
  isSubmitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  submit_button_color: PropTypes.string,
};

export default BaseBillingDetailsForm;
