import React from "react";
import CloudoonEmailApi from "../CloudoonEmailApi";

const API = new CloudoonEmailApi();

export default function useMxRecords(domain_name) {
  const [data, setData] = React.useState();
  const [is_loading, setIsLoading] = React.useState(true);
  const [is_refetching, setIsRefetching] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [is_error, setIsError] = React.useState(false);

  React.useEffect(() => {
    if (!domain_name) return;

    if (!data) {
      setIsLoading(true);
    }
    setIsRefetching(true);
    API.getDomainMxRecords(
      domain_name,
      (d) => {
        setData(d);
        if (!data) {
          setIsLoading(false);
        }
        setIsRefetching(false);
        setError(false);
        setIsError(false);
      },
      (e) => {
        setError(e);
        if (!data) {
          setIsLoading(false);
        }
        setIsRefetching(false);
        setIsError(true);
      },
    );
  }, [domain_name]);

  return {
    data,
    is_loading,
    is_refetching,
    error,
    is_error,
  };
}
