import FocusItemsList from "modules/marketing/components/Focusitems/FocusItemsList";
import SubscriptionCheck from "modules/marketing/layouts/SubscriptionCheck";
import { MARKETING_FOCUS_ITEMS_SLUG } from "modules/marketing/MarketingConstants";

function FocusItems() {
  return (
    <SubscriptionCheck
      slug={MARKETING_FOCUS_ITEMS_SLUG}
      component={<FocusItemsList />}
    />
  );
}

export default FocusItems;
