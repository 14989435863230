import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import CountryRegionSelect from "modules/base/components/inputs/CountryRegionSelect";
import EmailInput from "modules/base/components/inputs/EmailInput";
import PhoneNumberInput from "modules/base/components/inputs/PhoneNumberInput";
import SelectInput from "modules/base/components/inputs/SelectInput";
import TextInput from "modules/base/components/inputs/TextInput";
import Accordion from "modules/base/layouts/Accordion";
import AccordionItem from "modules/base/layouts/AccordionItem";
import MarketingAPI from "modules/marketing/MarketingAPI";

function ContactFormFields(props) {
  const { state, onInputChange, match } = props;
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const { segments } = API.getSegments();
  const [selectedSegment, setSelectedSegment] = useState(null);

  const segmentOptions = useMemo(
    () =>
      segments.map((segment) => ({
        value: segment.id,
        label: segment.name,
      })),
    [segments],
  );
  const handleSegmentChange = useCallback(
    (selectedOption) => {
      setSelectedSegment(selectedOption ? selectedOption.value : null);
      onInputChange({
        target: { name: "segment_id", value: selectedOption?.value ?? "" },
      });
    },
    [onInputChange],
  );

  return (
    <div className="mb-3">
      <div className="row row-cols-1 gy-md-3 gy-1">
        <TextInput
          name="firstname"
          title="First name"
          content={state.firstname ?? ""}
          controlFunc={onInputChange}
          required
        />
        <TextInput
          name="lastname"
          title="Last name"
          content={state.lastname ?? ""}
          controlFunc={onInputChange}
        />
        <EmailInput
          name="email"
          title="Email"
          content={state.email ?? ""}
          controlFunc={onInputChange}
        />
        <PhoneNumberInput
          name="mobile"
          title="Mobile"
          content={state.mobile}
          controlFunc={(value) => {
            onInputChange({ target: { name: "mobile", value } });
          }}
        />
        <PhoneNumberInput
          name="phone"
          title="Tel"
          content={state.phone}
          controlFunc={(value) => {
            onInputChange({ target: { name: "phone", value } });
          }}
        />
        <SelectInput
          name="segment"
          title="Add to Segment (Optional)"
          options={segmentOptions}
          onChange={handleSegmentChange}
          predicate={(option) => selectedSegment === option.value}
          isClearable
          placeholder="select..."
        />
        <TextInput
          name="company"
          title="Company"
          content={state.company ?? ""}
          controlFunc={onInputChange}
        />
      </div>
      <Accordion classes="mt-4">
        <AccordionItem title="Location" id="contact-location">
          <div className="row row-cols-1 gy-md-3 gy-1 md-3">
            <CountryRegionSelect
              current_country={state.country}
              current_region={state.region}
              current_city={state.city}
              useCountryNameAsValue
              country_field_title="Country"
              country_field_name="country"
              region_field_name="region"
              region_field_title="Region"
              city_field_name="city"
              controlFunc={(name, value) => {
                onInputChange({ target: { name, value } });
              }}
            />
            <TextInput
              inputType="number"
              name="zipcode"
              title="Zip code"
              content={state.zipcode ?? ""}
              controlFunc={onInputChange}
            />
            <TextInput
              name="address1"
              title="Address 1"
              content={state.address1 ?? ""}
              controlFunc={onInputChange}
            />
            <TextInput
              name="address2"
              title="Address 2"
              content={state.address2 ?? ""}
              controlFunc={onInputChange}
            />
          </div>
        </AccordionItem>
        <AccordionItem title="Socials" id="contact-socials">
          <div className="row row-cols-1 gy-md-3 gy-1 md-3">
            <TextInput
              name="website"
              title="Website"
              content={state.website ?? ""}
              controlFunc={onInputChange}
            />
            <TextInput
              name="facebook"
              title="Facebook"
              content={state.facebook ?? ""}
              controlFunc={onInputChange}
            />
            <TextInput
              name="instagram"
              title="Instagram"
              content={state.instagram ?? ""}
              controlFunc={onInputChange}
            />
            <TextInput
              name="twitter"
              title="Twitter(X)"
              content={state.twitter ?? ""}
              controlFunc={onInputChange}
            />
            <TextInput
              name="linkedin"
              title="Linkedin"
              content={state.linkedin ?? ""}
              controlFunc={onInputChange}
            />
            <TextInput
              name="skype"
              title="Skype"
              content={state.skype ?? ""}
              controlFunc={onInputChange}
            />
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

ContactFormFields.defaultProps = {
  state: {},
  onInputChange: () => {
    // Do nothing
  },
};

ContactFormFields.propTypes = {
  state: PropTypes.instanceOf(Object),
  onInputChange: PropTypes.func,
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(ContactFormFields);
