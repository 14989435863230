import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import PlanUpgrade from "modules/billing/components/PlanUpgrade";

function PlanUpgradeModal(props) {
  const { modal_id, title, website } = props;
  return (
    <Modal modal_title={title} modal_id={modal_id} size="xl">
      <PlanUpgrade website={website} parentModalId={modal_id} />
    </Modal>
  );
}

PlanUpgradeModal.defaultProps = {
  modal_id: "plan-select-modal",
  title: "Select a plan",
};

PlanUpgradeModal.propTypes = {
  modal_id: PropTypes.string,
  title: PropTypes.string,
  website: PropTypes.instanceOf(Object).isRequired,
};

export default PlanUpgradeModal;
