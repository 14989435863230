import NotesList from "modules/marketing/components/notes/NoteList";
import SubscriptionCheck from "modules/marketing/layouts/SubscriptionCheck";
import { MARKETING_CONTACTS_SLUG } from "modules/marketing/MarketingConstants";

function Notes() {
  return (
    <SubscriptionCheck
      slug={MARKETING_CONTACTS_SLUG}
      component={<NotesList />}
    />
  );
}

export default Notes;
