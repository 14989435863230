import PropTypes from "prop-types";
import { Icon } from "modules/base/components/Icons";
import { Datatable } from "modules/core/components/Datatable";
import { DateUtility } from "modules/core/utilities/DateUtility";

function PurchasedDomainList(props) {
  const { domains, manage_dns, manage_ns } = props;

  const actions: any = [
    {
      className:
        'class="btn btn-sm btn-outline-primary mx-auto dropdown-item dropdown-item dropdown-item"',
      text: "Manage DNS",
      icon: "compass",
      onclick: (domain_name, domain_id, domain_status) =>
        manage_dns(domain_status),
    },
    {
      className:
        'class="btn btn-sm btn-outline-primary mx-auto dropdown-item dropdown-item dropdown-item"',
      text: "Change Nameservers",
      icon: "globe",
      onclick: (domain_name, domain_id, domain_status) =>
        manage_ns(domain_name, domain_id, domain_status),
    },
  ];

  let action_buttons;
  if (actions) {
    action_buttons = (domain_name, domain_id, domain_status) =>
      actions.map((action) => {
        return (
          <button
            type="button"
            className={action.className}
            data-domainid={domain_id}
            data-status={domain_status}
            onClick={() =>
              action.onclick(domain_name, domain_id, domain_status)
            }
          >
            {<Icon icon={action.icon} />} {action.text}
          </button>
        );
      });
  }
  const getActionFormat: any = (row, cell) => (
    <div className="flex-grow-1 d-flex align-items-center justify-center">
      <button
        type="button"
        className="btn btn-transparent shadow-none"
        data-bs-toggle="dropdown"
      >
        <span className="btn btn-olitt-grey rounded-pill">
          <Icon icon="three-dots" />
        </span>
      </button>
      <div className="dropdown-menu">
        {action_buttons(row.domain_name, cell, row.status)}
      </div>
    </div>
  );

  function formatDate(cell) {
    const date: any = new Date(cell.year, cell.month, cell.day);
    return DateUtility.formatDate(date, false);
  }

  function formatStatus(cell) {
    let status_classes: string = "";
    if (cell === "Pending") {
      status_classes = "text-warning";
    } else if (cell === "Active") {
      status_classes = "text-success";
    } else {
      status_classes = "text-olitt-grey";
    }
    return <span className={status_classes}>{cell}</span>;
  }

  const columns: any = [
    {
      dataField: "domain_name",
      text: "Domains",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: formatStatus,
    },
    {
      dataField: "purchase_price",
      text: "Purchase Price",
    },
    {
      dataField: "renewal_price",
      text: "Renewal Price",
    },
    {
      dataField: "registration_period",
      text: "Registration Period",
    },
    {
      dataField: "date_due",
      text: "Due Date",
      formatter: formatDate,
    },
    {
      dataField: "id",
      text: "Actions",
      formatter: (cell, row) => getActionFormat(row, cell),
    },
  ];

  return (
    <div
      className="row card"
      style={{ overflowX: "hidden" }}
      data-tutorial="domain-list"
    >
      <Datatable columns={columns} data={domains} />
    </div>
  );
}

PurchasedDomainList.propTypes = {
  domains: PropTypes.instanceOf(Object).isRequired,
  manage_dns: PropTypes.func.isRequired,
  manage_ns: PropTypes.func.isRequired,
};

export default PurchasedDomainList;
