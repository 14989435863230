import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import PreviewContact from "modules/marketing/components/contacts/PreviewContact";

function PreviewContactModal({ contact, modalUUID }) {
  return (
    <Modal
      modal_title="Contact Preview"
      modal_id={`preview-contact-modal-${modalUUID}`}
      size="sm"
    >
      <PreviewContact contact={contact} />
    </Modal>
  );
}

PreviewContactModal.propTypes = {
  contact: PropTypes.instanceOf(Object).isRequired,
  modalUUID: PropTypes.string.isRequired,
};

export default PreviewContactModal;
