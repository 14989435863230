import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AuthenticationActions } from "modules/account/AccountActions";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import {
  BILLING_PLANS_DATA,
  URL_PLANS,
} from "modules/billing/BillingConstants";
import { BillingPlan } from "modules/billing/BillingModels";
import ComponentLoadingIndicator from "modules/core/components/ComponentLoadingIndicator";
import Editor from "modules/core/components/Editor";
import { IMAGE_SERVER_ENDPOINT } from "modules/core/constants/Endpoints";
import {
  fetchUrlData,
  getUrlData,
} from "modules/core/utilities/redux/componentActions";
import ShopPage from "modules/shop/models/Page";
import ShopApi from "modules/shop/ShopAPIs";

import SiteApi from "modules/website/api/SiteApi";

/**
 * @class
 * @name DesignView
 * @description This is the design view component.
 */
function DesignView(props) {
  const shopApi: any = new ShopApi();
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [homepage, setHomepage] = React.useState(null);
  const [website, setWebsite] = React.useState(null);
  const [loadCanvas, setLoadCanvas] = React.useState(false);
  const authenticationActions: any = new AuthenticationActions();
  const user: any = authenticationActions.retrieveAuthenticatedUser();
  const {
    match: {
      params: { id: site_id },
    },
    paidPlan,
  } = props;

  /**
   * @name getPages
   * @description This function gets the pages for the shop.
   */
  function getShopPages() {
    setIsLoading(true);
    shopApi.getShopPages(
      site_id,
      (results: Array<ShopPage>) => {
        const home_page: any = results.find(
          (page: any) => page.type_display === "homepage",
        );
        setHomepage(home_page);
        if (home_page) {
          home_page.page_content = home_page.html_content;
          setLoadCanvas(true);
        }
        setIsLoading(false);
      },
      (error_message: string) => {
        setError(error_message);
        setIsLoading(false);
      },
    );
  }

  useEffect(() => {
    new SiteApi().retrieveWebsite(
      site_id,
      (site) => {
        setWebsite(site);
      },
      (e) => {
        setError(e);
      },
    );
    fetchUrlData(BILLING_PLANS_DATA, URL_PLANS, props);
    getShopPages();
  }, []);

  if (isLoading) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <ComponentLoadingIndicator />
      </div>
    );
  }

  /**
   * @name buildPagePayload
   * @param components_changes
   * @returns {Object}
   */
  function buildPagePayload(components_changes: Array) {
    const payload: Object = {
      page_id: homepage.id,
    };
    const sections_payload: Array = [];
    components_changes.map((component_change) => {
      const component_attrs: Object = component_change.attributes;
      const { value } = component_change;
      const component_section_id: any = component_attrs["data-section-id"];
      let section_payload: any = sections_payload.find(
        (section_payload_) => section_payload_.id === component_section_id,
      );
      if (!section_payload) {
        section_payload = {
          id: component_section_id,
        };
        sections_payload.push(section_payload);
      }
      const field_name: string = component_attrs["data-section-field"];
      section_payload[field_name] = value;
      if (
        component_attrs["data-image-size"] &&
        !value.includes("data:image/jpeg;base64,")
      ) {
        section_payload[
          field_name
        ] = `${IMAGE_SERVER_ENDPOINT}/${component_attrs["data-image-size"]}/${value}`;
      }
      return null;
    });
    payload.sections_payload = sections_payload;
    return payload;
  }

  /**
   * @name handleSavePageContent
   * @param editorInstance
   * @param auto_save_enabled
   * @param callback
   * @param from_auto_save
   * @param components_changes
   */
  const handleSavePageContent: any = (
    editorInstance,
    auto_save_enabled,
    callback,
    from_auto_save,
    components_changes,
  ) => {
    const payload: any = buildPagePayload(components_changes);
    shopApi.updateShopPage(
      site_id,
      homepage.id,
      payload,
      () => {
        setError(null);
        callback();
      },
      (error_message: string) => {
        setError(error_message);
        callback();
      },
    );
  };
  const exclude_panel_buttons: Array = [
    {
      model: "options",
      id: "canvas-clear",
    },
    {
      model: "options",
      id: "export-template",
    },
    {
      model: "options",
      id: "gjs-open-import-webpage",
    },
    {
      model: "views",
      id: "open-sm",
    },
    {
      model: "views",
      id: "open-tm",
    },
    {
      model: "views",
      id: "open-layers",
    },
    {
      model: "views",
      id: "open-blocks",
    },
    {
      model: "options",
      id: "sw-visibility",
    },
    {
      model: "options",
      id: "visit-site",
    },
    {
      model: "options",
      id: "fullscreen",
    },
    {
      model: "options",
      id: "publish-website",
    },
    {
      model: "options",
      id: "auto-save-enabled",
    },
  ];

  return (
    <>
      <NetworkMessageDisplay error={error} />
      <Editor
        newsletter_mode={false}
        components_prefix="store"
        user={user}
        handleGoHome={() => {}}
        handleVisitSite={() => {}}
        website={website}
        page={homepage}
        paid_plan={paidPlan}
        handleSavePageContent={handleSavePageContent}
        handleTogglePublishWebsite={() => {}}
        loadCanvas={loadCanvas}
        exclude_panel_buttons={exclude_panel_buttons}
        includeCanvasCSS={false}
        includeCanvasJS={false}
        allowComponentsResize={false}
        allowComponentsDrag={false}
        allowComponentsDrop={false}
        allowComponentsStyling={false}
        allowComponentsCopy={false}
        allowComponentsDelete={false}
        allowChangeComponentsBackground={false}
        allowComponentsBadge={false}
        allowComponentsHighlight={false}
        allowImageEditing={false}
        allowImageAddLink={false}
        componentsEditAttrsRequire={["data-section-field"]}
        trackComponentsChanges
      />
    </>
  );
}

DesignView.defaultProps = {};

DesignView.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  paidPlan: PropTypes.instanceOf(Object).isRequired,
};

/**
 * Preload some props before loading component
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = (state) => {
  const { sessionVariables, dataByUrl } = state;

  const billingPlanData = getUrlData(
    dataByUrl,
    sessionVariables,
    BILLING_PLANS_DATA,
  );

  let billingPlans = [];
  let paidPlan = [];
  if (billingPlanData.items.length > 0) {
    billingPlans = billingPlanData.items.map(
      (parameters) => new BillingPlan(parameters),
    );
    paidPlan =
      billingPlans.find((billingPlan) => {
        return billingPlan.pricing.amount > 0.0;
      }) || [];
  }

  return {
    sessionVariables,
    paidPlan,
  };
};

export default connect(mapStateToProps)(withRouter(DesignView));
