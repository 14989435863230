import { pay_pal_product_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const {
    PayPalProductBlockLabel,
    PayPalProductBlockCategory,
    PayPalProductBlock,
  } = opts;

  PayPalProductBlock &&
    bm.add("PAYPAL-PRODUCT-BLOCK", {
      label: PayPalProductBlockLabel,
      activate: true,
      select: true,
      category: PayPalProductBlockCategory,
      content: { type: "PAYPAL-PRODUCT-BLOCK-COMPONENT" },
      media: pay_pal_product_logo,
    });
};
