import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import ConfigureFormField from "modules/marketing/components/forms/ConfigureFormField";
import { Field } from "modules/marketing/MarketingModels";

function ConfigureFormFieldModal(props) {
  const { field, setState, contactFields } = props;
  return (
    <Modal
      modal_title="Configure Field"
      modal_id={`configure-form-field-${field.id}-modal`}
      size="md"
    >
      <ConfigureFormField
        field={field}
        setState={setState}
        contactFields={contactFields}
      />
    </Modal>
  );
}

ConfigureFormFieldModal.propTypes = {
  field: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  contactFields: PropTypes.arrayOf(PropTypes.instanceOf(Field)).isRequired,
};

export default ConfigureFormFieldModal;
