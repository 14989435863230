import { PrivateApi } from "modules/core/utilities/api/PrivateApi";
import {
  URL_STORAGE,
  URL_UNSPLASH_IMAGES,
} from "modules/website/WebsiteConstants";
import { ObjectStorage } from "modules/website/WebsiteModels";

/**
 * Api actions for object storage:
 *  - Store a file in object storage
 *  - Retrieve a file in object storage
 */
export default class ObjectStorageApi extends PrivateApi {
  createObject(
    site_id: number,
    file: File,
    external_url: URL,
    description: string,
    callback: () => void,
  ) {
    const form_data: any = new FormData();
    form_data.append("site", site_id);
    if (file) {
      form_data.append("file", file);
    }
    form_data.append("description", description);
    if (external_url) {
      form_data.append("external_url", external_url);
    }

    this.postFormAPIRequest(
      URL_STORAGE,
      (storage_parameters) =>
        callback(true, new ObjectStorage(storage_parameters), null),
      (e) => callback(false, null, e),
      form_data,
      this.getMultipartHeaders(),
    );
  }

  deleteObjects(id: number, callback: (is_successful: boolean) => void) {
    this.deleteAPIRequest(
      `${URL_STORAGE}${id}/`,
      (success_response) => {
        callback(true, success_response);
      },
      (error_response) => {
        const error: any = this.extractResponseError(error_response);
        callback(false, error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  fetchObjects(
    callback: (
      is_successful: boolean,
      objects_or_error: ObjectStorage[] | string,
    ) => void,
  ) {
    this.getAPIRequest(
      URL_STORAGE,
      (success_response) => {
        const objects: any = success_response.map(
          (parameters) => new ObjectStorage(parameters),
        );
        callback(true, objects);
      },
      (error_response) => {
        const default_error: string = "Unable to retrieve resources";
        const error: any = error_response.detail || default_error;
        callback(false, error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  fetchUnsplashImages(
    search_string: string,
    callback: (is_successful: boolean, images_or_error: [] | string) => void,
  ) {
    this.getAPIRequest(
      `${URL_UNSPLASH_IMAGES}?search_string=${search_string}`,
      (success_response) => {
        callback(true, success_response);
      },
      (error_response) => {
        const error: any = this.extractResponseError(error_response);
        callback(false, error);
      },
      this.getAuthenticatedHeaders(),
    );
  }
}
