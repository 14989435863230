import { jira_log } from "../svgIcons";

export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const tm = editor.Panels.getButton("views", "open-tm");
  const { data_key, database_url, jira_src, jiraSupportComponent } = opts;

  const placeholder = `<div style="text-align:center;color:#f6a908;height: 50px;">${jira_log}</div><div style="text-align:center;color:#f6a908;">This is a placeholder and will not be rendered. Toggle visibility in layers</div>`;

  domc.addType("JIRA-SUPPORT-COMPONENT", {
    model: {
      defaults: {
        tagName: "div",
        name: "jiraSupport",
        draggable: "[data-gjs-type=wrapper]",
        droppable: false,
        stylable: false,
        copyable: false,
        data_key,
        database_url,
        jira_src,
        traits: [
          {
            name: "data_key",
            label: "Data Key",
            placeholder: "Enter Jira data key.",
            changeProp: 1,
          },
        ],
        content: `<div id="jiraSupport-container" style="padding: 25px;">${placeholder}</div>`,
        script() {
          (function (w, d, s, l, data_key) {
            w[l] = w[l] || [];
            w[l].push({
              "jiraSupport.start": new Date().getTime(),
              event: "jiraSupport.js",
            });
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s);
            const dl = l != "dataLayer" ? `&l=${l}` : "";
            j.async = true;
            j.setAttribute("data-jsd-embedded", "true");
            j.setAttribute("data-key", data_key);
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "{[ data_key ]}");
        },
      },
      init() {
        this.on("change:data_key change:database_url change:jira_src", () =>
          valuesOnchange()
        );
        const valuesOnchange = () => {
          this.trigger("change:script");
          editor.store();
        };
        tm.set("active", 1);
      },
      toHTML() {
        const tagName = this.get("tagName");
        return `<${tagName} id="${this.getId()}"></${tagName}>`;
      },
      ...jiraSupportComponent,
    },
    view: {},
  });
};
