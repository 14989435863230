import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import TextInput from "modules/base/components/inputs/TextInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import {
  closeModalById,
  handleInputChange,
} from "modules/base/utilities/Actions";
import ConfigureNodeForm from "modules/marketing/components/campaigns/nodes/configure/ConfigureNodeForm";
import ContactFilterField from "modules/marketing/components/ContactFilterField";
import MarketingAPI from "modules/marketing/MarketingAPI";

function ContactFieldValue({
  campaignEvent,
  setState,
  currentModalId,
  updateEvent,
  match,
}) {
  const [selectedField, setSelectedField] = useState({});
  const [campaignEventState, setCampaignEventState] = useState(campaignEvent);
  const [currentFilterValue, setCurrentFilterValue] = useState(
    campaignEventState.properties.value,
  );
  const [filterState, setFilterState] = useState({
    field: campaignEventState.properties.field,
    operator: campaignEventState.properties.operator,
  });
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const params = {
    start: 0,
    limit: 1000,
  };
  const { fields: contactFields, isLoading, error } = API.getFields(params);
  useEffect(() => {
    if (
      campaignEventState.properties.field &&
      selectedField.value !== campaignEventState.properties.field
    ) {
      const field = contactFields.find(
        (f) => f.alias === campaignEventState.properties.field,
      );
      if (field) {
        setSelectedField({
          value: field.alias,
          label: field.label,
          type: field.type,
        });
      }
    }
  }, [contactFields]);
  const onInputChange = useCallback((e) => {
    handleInputChange(
      e.target.name,
      e.target.value,
      campaignEventState,
      setCampaignEventState,
    );
  });
  const onFilterValueChange = useCallback((filterValue) => {
    setCurrentFilterValue(filterValue);
    handleInputChange(
      "properties",
      {
        ...campaignEventState.properties,
        value: filterValue,
      },
      campaignEventState,
      setCampaignEventState,
    );
  });
  const onClickConfigure = useCallback(() => {
    setState({ ...campaignEventState });
    closeModalById(currentModalId);
  });
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  return (
    <ConfigureNodeForm
      campaignEvent={campaignEvent}
      campaignEventState={campaignEventState}
      handleSubmit={onClickConfigure}
      updateEvent={updateEvent}
    >
      <NetworkMessageDisplay error={error} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <TextInput
          name="name"
          title="Name"
          content={campaignEvent.name ?? ""}
          controlFunc={onInputChange}
          required
        />
        <ContactFilterField
          contactFields={contactFields}
          setSelectedField={setSelectedField}
          setFilterState={setFilterState}
          onFilterValueChange={onFilterValueChange}
          selectedField={selectedField}
          filterState={filterState}
          currentFilterValue={currentFilterValue}
        />
      </div>
    </ConfigureNodeForm>
  );
}

ContactFieldValue.propTypes = {
  campaignEvent: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  currentModalId: PropTypes.string.isRequired,
  updateEvent: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(ContactFieldValue);
