import { fetchDataIfNeeded, setSessionVariable } from "./actions/actions";

export function getUrlData(dataByUrl, sessionVariables, url_var_name) {
  const url: any = sessionVariables[url_var_name] || "";
  return (
    dataByUrl[url] || {
      didInvalidate: false,
      isFetching: false,
      items: [],
      lastUpdated: "",
    }
  );
}

export function fetchUrlData(var_name, url, props) {
  const { dispatch } = props;
  props.dispatch(setSessionVariable(var_name, url));
  dispatch(fetchDataIfNeeded(url));
}
