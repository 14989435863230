import PropTypes from "prop-types";
import React from "react";
import { AuthenticationActions } from "../../../account/AccountActions";
import { User } from "../../../account/AccountModels";
import { OlittModal } from "../../../core/components/OlittModal";
import FacebookLogo from "../../assets/facebook_logo.png";
import FacebookLogin from "./Login";
import OnBoarding from "./OnBoarding";

/**
 * @name FacebookSetup
 * @description Facebook setup component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function FacebookSetup(props) {
  const authenticationActions: any = new AuthenticationActions();
  const [isShowingSetupModal, setIsShowingSetupModal] = React.useState(false);
  const [user, setUser] = React.useState(props.user);
  const { shop, setError } = props;

  let facebookAccountConnectedMessage;
  if (user.facebook_access_token !== null) {
    facebookAccountConnectedMessage = "View Facebook Settings";
  } else {
    facebookAccountConnectedMessage = "Sell On Facebook";
  }

  /**
   * @name toggleShowFacebookSetup
   * @description Toggle show facebook setup modal
   */
  function toggleShowFacebookSetup() {
    setIsShowingSetupModal(!isShowingSetupModal);
  }

  /**
   * @name updateUser
   * @param isSuccessful
   */
  const updateUser: any = (isSuccessful) => {
    if (isSuccessful) {
      const updated_user: User =
        authenticationActions.retrieveAuthenticatedUser();
      setUser(updated_user);
    }
  };

  let facebookLoginComponent: object = null;
  let facebookOnBoardingComponent: object = null;
  if ([null, "", undefined].includes(user.facebook_access_token)) {
    facebookLoginComponent = (
      <div className="d-flex justify-content-center align-items-center h-100">
        <FacebookLogin callback={updateUser} />
      </div>
    );
  } else {
    facebookOnBoardingComponent = (
      <OnBoarding shop={shop} setError={setError} />
    );
  }

  return (
    <>
      <button
        className="btn btn-facebook mb-3"
        onClick={() => toggleShowFacebookSetup()}
        type="button"
      >
        <img src={FacebookLogo} alt="Facebook Logo" width={18} height={18} />
        <span className="text-white ms-2">
          {facebookAccountConnectedMessage}
        </span>
      </button>
      <OlittModal
        modal_id="facebook-setup-modal"
        onClose={() => {}}
        show={isShowingSetupModal}
        size="lg"
        height={50}
      >
        {facebookOnBoardingComponent}
        {facebookLoginComponent}
      </OlittModal>
    </>
  );
}

FacebookSetup.propTypes = {
  shop: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  setError: PropTypes.func.isRequired,
};

export default FacebookSetup;
