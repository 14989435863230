import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import { UI_ROUTES } from "../../CloudoonConstants";
import CloudoonEmailApi from "../../CloudoonEmailApi";
import useMxRecords from "../../hooks/useMxRecords";
import useOwnershipRecord from "../../hooks/useOwnershipRecord";
import useVerificationStatus from "../../hooks/useVerificationStatus";
import Stepper from "./Stepper";

const API = new CloudoonEmailApi();

function InstructionStepper(props) {
  const { domain_name } = props;

  const [current_step, setCurrentStep] = React.useState(0);
  const { data: txt_record, is_loading: is_txt_record_loading } =
    useOwnershipRecord(domain_name);
  const { data: mx_records, is_loading: is_mx_records_loading } =
    useMxRecords(domain_name);

  const instructions = React.useMemo(() => {
    const first_mx_record = mx_records?.mx_records?.[0];

    const txt_record_rows = (
      <tr>
        <td>{txt_record?.txt_record?.name}</td>
        <td>{txt_record?.txt_record?.data}</td>
      </tr>
    );

    const mx_record_rows = mx_records?.mx_records?.map((mxRecord) => {
      return (
        <tr key={mxRecord?.data}>
          <td>{mxRecord?.name}</td>
          <td>{mxRecord?.data}</td>
        </tr>
      );
    });

    return [
      "Access your account on the domain provider's website (like Truehost, GoDaddy, Namecheap, Cloudflare, or similar) where your domain's nameserver is directed.",
      "Access your domain and proceed to its DNS page (DNS Manager, DNS Control Panel, or Advanced DNS editor).",
      "Find the section for adding TXT records (usually located under DNS Records).",
      "Select the Add Record option.",
      <div className="ms-2 me-auto">
        In the Name/ Host/ Alias, enter{" "}
        <span className="fw-bold">{txt_record?.txt_record?.name}</span>.
      </div>,
      <div className="ms-2 me-auto d-flex flex-column gap-3">
        <span>
          In the Value/ Points To/ Destination field, add the verification:{" "}
          <span className="fw-bold">{txt_record?.txt_record?.data}</span>.
        </span>
        <div>
          <table className="table table-bordered">
            <thead className="table-dark">
              <tr>
                <th scope="col">Name/ Host/ Alias</th>
                <th scope="col">Value/ Points To/ Destination</th>
              </tr>
            </thead>
            <tbody>{txt_record_rows}</tbody>
          </table>
        </div>
      </div>,
      "If the TTL is editable, set it to the minimum possible value recommended by your Registrar.",
      "Select the Add Record option to create another record.",
      `Specify the value in Name/ Host field as either ${first_mx_record?.name}.`,
      `Specify the first record's value as ${first_mx_record?.data}`,
      "Set the priority to 10 or lower as supported by your registrar.",
      "Follow the same steps to add another MX record for your domain, if there's any.",
      <div className="ms-2 me-auto d-flex flex-column gap-3">
        <span>
          To ensure correct email delivery to your domain, only the MX Records
          provided below should be listed.
        </span>
        <div>
          <table className="table table-bordered">
            <thead className="table-dark">
              <tr>
                <th scope="col">Host/ Domain</th>
                <th scope="col">Address/ Mail Server/ MX Entries/ Value</th>
              </tr>
            </thead>
            <tbody>{mx_record_rows}</tbody>
          </table>
        </div>
      </div>,
      "If the TTL is editable, provide the lowest possible value for the changes to take effect as soon as possible.",
      "Save the records added and give it an hour to two to propagate.",
    ];
  }, [txt_record, mx_records]);

  const { step, totalSteps, instruction, progress } = React.useMemo(() => {
    const data = {};
    for (let i = 0; i < instructions.length; i++) {
      if (current_step === i) {
        data.step = i + 1;
        data.totalSteps = instructions.length;
        data.instruction = instructions[i];
        data.progress = Math.floor(((i + 1) / instructions.length) * 100);
        break;
      }
    }
    return data;
  }, [instructions, current_step]);

  const onBack = React.useCallback(() => {
    if (current_step > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  }, [current_step]);
  const onNext = React.useCallback(() => {
    if (current_step < instructions.length - 1) {
      setCurrentStep((prev) => prev + 1);
    }
  }, [current_step, instructions]);

  if (is_txt_record_loading || is_mx_records_loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Stepper
        progressData={{
          step,
          totalSteps,
          instructions: instruction,
          progress,
        }}
        onBack={onBack}
        onNext={onNext}
      />
    </div>
  );
}

InstructionStepper.propTypes = {
  domain_name: PropTypes.string.isRequired,
};

function Verification(props) {
  const {
    match: {
      params: { domain_name },
    },
    history,
  } = props;

  const [is_verification_loading, setIsVerificationLoading] =
    React.useState(false);

  const {
    data: status,
    is_loading: is_status_loading,
    is_refetching: is_status_refetching,
  } = useVerificationStatus(domain_name);

  const onNext = React.useCallback(
    () =>
      history.push(
        UI_ROUTES.SETUP.CREATE_ACCOUNT.replace(":domain_name", domain_name),
      ),
    [history, domain_name],
  );

  if (is_status_loading || is_status_refetching) {
    return <div>Loading...</div>;
  }

  const onVerify = () => {
    setIsVerificationLoading(true);
    API.verifyDomain(
      domain_name,
      (data) => {
        setIsVerificationLoading(false);
        if (data?.verified) {
          history.push(
            UI_ROUTES.SETUP.CREATE_ACCOUNT.replace(":domain_name", domain_name),
          );
          toast.success("Domain verification successful");
          return;
        }

        if (!data?.detail?.ownership) {
          toast.error("Ownership verification failed");
        }

        if (!data?.detail?.mx_records) {
          toast.error("MX records verification failed");
        }
      },
      (error) => {
        setIsVerificationLoading(false);
        toast.error(error.detail ?? "Error verifying domain");
      },
    );
  };

  const ownership_label_classes = classNames("list-group-item", {
    "list-group-item-success": status?.ownership,
    "list-group-item-secondary": !status?.ownership,
  });

  const mx_record_label_classes = classNames("list-group-item", {
    "list-group-item-success": status?.mx_records,
    "list-group-item-secondary": !status?.mx_records,
  });

  const verification_checkboxes = (
    <div className="list-group">
      <label className={ownership_label_classes} htmlFor="ownership-verified">
        <input
          id="ownership-verified"
          className="form-check-input me-1"
          type="checkbox"
          readOnly
          checked={status?.ownership}
        />
        Ownership verified
      </label>
      <label className={mx_record_label_classes} htmlFor="mx-records-verified">
        <input
          id="mx-records-verified"
          className="form-check-input me-1"
          type="checkbox"
          readOnly
          checked={status?.mx_records}
        />
        MX records verified
      </label>
    </div>
  );

  const verification_button_loader = is_verification_loading ? (
    <span
      className="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    />
  ) : null;

  const verification_button = (
    <button
      type="button"
      className="btn btn-primary d-flex align-items-center gap-1"
      onClick={onVerify}
      disabled={is_verification_loading}
    >
      {verification_button_loader}
      <span className="visually-hidden">Loading...</span>
      Verify Domain
    </button>
  );

  return (
    <div>
      <Stepper
        progressData={{
          step: 1,
          instructions: "Domain Verification",
          progress: 33,
        }}
        onNext={onNext}
      />

      <div className="d-flex flex-column align-items-stretch gap-5">
        <div className="d-flex flex-column gap-2">
          <h1 className="fw-bold text-capitalize">Domain Verification</h1>
          <h5 className="fw-bold text-capitalize text-muted">
            Verify the domain ownership and MX records
          </h5>
        </div>
        <div className="d-flex flex-column align-items-stretch gap-5">
          <div className="d-flex flex-column align-items-stretch gap-3">
            <h5 className="fw-bold text-capitalize">Checklist</h5>
            {verification_checkboxes}
          </div>
          <InstructionStepper domain_name={domain_name} />
          {verification_button}
        </div>
      </div>
    </div>
  );
}

Verification.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      domain_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Verification;
