import PropTypes from "prop-types";
import { useState } from "react";
import { Icon } from "modules/base/components/Icons";
import { BillingAPI } from "modules/billing/BillingAPIs";
import {
  closed_sidebar_width,
  open_sidebar_width,
} from "modules/core/components/SidebarWrapper";
import { Menu } from "modules/core/models/Menu";
import { PopUptUtility } from "modules/core/utilities/PopUpUtility";
import { Site } from "modules/website/WebsiteModels";

function MenuItems(props) {
  const { actions, site, sidebar_width, navigateSideBar } = props;
  const [is_showing_sidebar, showSidebar] = useState(false);

  const billingApi = new BillingAPI();
  const { plans } = billingApi.retrievePlans();
  const menu_items: any = (actions || []).map((menu: any) => {
    let classes: string = "d-flex nav-link text-dark bg-light";
    let li_classes: string = "nav-item d-grid border-bottom";

    if (menu.showOnMobile === false) {
      li_classes = "d-none nav-item d-md-grid border-bottom";
    }
    if (menu.is_active) {
      classes = `${classes} text-white bg-dark active`;
    }

    const key: any = menu.title.replace(" ", "_");
    let menu_title_display_classes: string = "";
    if (sidebar_width === closed_sidebar_width && !is_showing_sidebar) {
      menu_title_display_classes = "d-none";
    }
    const onClick = () => {
      if (!site) {
        showSidebar(!is_showing_sidebar);
        navigateSideBar(menu.path, menu.url);
        return;
      }
      const upgradePlan = plans.find((plan) => {
        const features = plan.features.map((feature) => feature.slug);
        return features.includes(menu.slug);
      });
      const features = site.subscription_plan.features.map((feature) =>
        feature.slug.toLowerCase(),
      );
      if (!features.includes(menu.slug) && menu.slug) {
        PopUptUtility.showUpgradeModal(
          site.id,
          `Upgrade to access ${menu.title} `,
          upgradePlan,
        );
      } else {
        showSidebar(!is_showing_sidebar);
        navigateSideBar(menu.path, menu.url);
      }
    };

    return (
      <li className={li_classes} key={key}>
        <button
          type="button"
          className={classes}
          aria-current="page"
          onClick={onClick}
        >
          {menu.icon && <Icon icon={menu.icon} additionalClasses="me-2" />}
          <span className={menu_title_display_classes}>{menu.title}</span>
        </button>
      </li>
    );
  });

  return <>{menu_items}</>;
}

MenuItems.defaultProps = {
  actions: [],
  site: null,
  sidebar_width: open_sidebar_width,
  navigateSideBar: () => {},
};

MenuItems.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.instanceOf(Menu)),
  site: PropTypes.instanceOf(Site),
  sidebar_width: PropTypes.string,
  navigateSideBar: PropTypes.func,
};

export default MenuItems;
