import { IS_DEVELOPMENT } from "../core/constants/Constants";
import { API_BASE_URL } from "../core/constants/Endpoints";
import { PATH_MAIN_ROUTE } from "../core/constants/Routes";

/**
 * API Endpoints
 */
export const URL_CATEGORIES = `${API_BASE_URL}/cms/categories/`;
export const URL_WEBSITES = `${API_BASE_URL}/cms/sites/`;
export const URL_SITE_PAGES = `${URL_WEBSITES}:id/pages/`;
export const URL_WEBSITES_TEMPLATES = `${URL_WEBSITES}templates/`;
export const URL_WEBPAGES = `${API_BASE_URL}/cms/pages/`;
export const URL_DOMAINS = `${API_BASE_URL}/cms/domains/`;
export const URL_DISCONNECT_CUSTOM_DOMAIN = "/disconnect-custom-domain/";
export const URL_INTEGRATIONS = `${API_BASE_URL}/cms/integrations/`;
export const URL_SITE_JS = `${API_BASE_URL}/cms/scripts/`;
export const URL_STORAGE = `${API_BASE_URL}/cms/storage/`;
export const URL_SUBSCRIPTION_PLANS = `${API_BASE_URL}/billing/plans/`;
export const URL_WEBSITE_INVOICES = `${API_BASE_URL}/silver/invoices/`;
export const URL_UNSPLASH_IMAGES = `${API_BASE_URL}/gallery/unsplash/`;
export const URL_PRICING_PLAN = `${API_BASE_URL}/pricing/:plan_id/plan/`;
/**
 * Routes
 */
export const PATH_WEBSITES = `${PATH_MAIN_ROUTE}/websites`;
export const PATH_WEBSITE_TEMPLATES = `${PATH_WEBSITES}/new`;
export const PATH_WEBSITE_NEW = `${PATH_WEBSITE_TEMPLATES}/:template_id`;
export const PATH_WEBSITE_DETAIL = `${PATH_WEBSITES}/:id`;
export const PATH_WEBSITE_PAGE = `${PATH_WEBSITE_DETAIL}/pages/:page_id`;
export const PATH_WEBSITE_STUDIO = `${PATH_WEBSITE_DETAIL}/studio`;
export const PATH_WEBSITE_PAGE_CANVAS = `${PATH_WEBSITES}/canvas`;
export const PATH_WEBSITE_DOMAINS = `${PATH_WEBSITE_DETAIL}/domains`;
export const PATH_WEBSITE_PLUGINS = `${PATH_WEBSITE_DETAIL}/plugins`;
export const PATH_WEBSITE_BILLING = `${PATH_WEBSITE_DETAIL}/billing`;
export const PATH_WEBSITE_SEO = `${PATH_WEBSITE_DETAIL}/seo`;
export const PATH_WEBSITE_FORM_SUBMISSIONS = `${PATH_WEBSITE_DETAIL}/forms`;
export const PATH_WEBSITE_SETTINGS = `${PATH_WEBSITE_DETAIL}/settings`;

/**
 * Constants
 */
export const A_RECORD_IP = IS_DEVELOPMENT ? "127.0.0.1" : "94.130.219.24";
export const TEMPLATE_DATA = "TEMPLATE_DATA";
export const WEBSITES = "WEBSITES";
export const WEBSITE_DATA = "WEBSITE_DATA";
export const CATEGORY_DATA = "CATEGORY_DATA";
export const INTEGRATIONS_DATA = "INTEGRATIONS_DATA";
export const SCRIPTS_DATA = "SCRIPTS_DATA";
export const UNSPLASH_IMAGES = "UNSPLASH_IMAGES";
export const UNSPLASH_EMPTY_IMAGES = "No images found";
export const NO_OF_TOP_TEMPLATES_LIMIT = process.env
  .REACT_APP_NO_OF_TOP_TEMPLATES_LIMIT
  ? Number(process.env.REACT_APP_NO_OF_TOP_TEMPLATES_LIMIT)
  : 10;

export const EDITOR_BASE_CSS =
  '*{box-sizing:border-box}html,body,[data-gjs-type=wrapper]{min-height:100%}body{margin:0;height:100%;background-color:#fff}[data-gjs-type=wrapper]{overflow:auto;overflow-x:hidden}div[data-gjs-type="wrapper"]:after{content:"";display:block;height:60px;user-select:none}* ::-webkit-scrollbar-track{background:#fff}* ::-webkit-scrollbar-thumb{background-color:#161c2e;border-radius:20px}* ::-webkit-scrollbar{width:10px}';
