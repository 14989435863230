import PropTypes from "prop-types";
import React from "react";

/**
 * @function FormCardWrapper
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function FormCardWrapper(props) {
  const { children, title } = props;
  return (
    <div className="container-fluid">
      <div className="card border-0 shadow-sm">
        <div className="card-body">
          {title && <h5 className="card-title">{title}</h5>}
          {children}
        </div>
      </div>
    </div>
  );
}

FormCardWrapper.defaultProps = {
  title: "",
};

FormCardWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default FormCardWrapper;
