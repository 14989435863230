import { API_BASE_URL } from "../core/constants/Endpoints";
import { PATH_MAIN_ROUTE } from "../core/constants/Routes";

/**
 * API Endpoints
 */
export const URL_DOMAIN_ORDERS: string = `${API_BASE_URL}/domains/`;
export const URL_COMPLETE_DOMAIN_ORDER: string = `${URL_DOMAIN_ORDERS}on-complete-order/`;
export const URL_DOMAIN_SUGGESTIONS: string = `${API_BASE_URL}/domains/search/`;
export const URL_DOMAINS: string = `${API_BASE_URL}/domains/`;
export const URL_GET_DOMAIN_NAMESERVERS: string = `${URL_DOMAIN_ORDERS}get-nameservers/`;
export const URL_UPDATE_DOMAIN_NAMESERVERS: string = `${URL_DOMAIN_ORDERS}update-nameservers/`;

/**
 * Routes
 */
export const PATH_DOMAINS: string = `${PATH_MAIN_ROUTE}/domains`;
export const PATH_SEARCH: string = `${PATH_DOMAINS}/search`;
export const PATH_NAMESERVERS: string = `${PATH_DOMAINS}/nameservers/:domain_id/:domain_name`;

/**
 * Constants
 */
export const DOMAINS_DATA: string = "DOMAINS_DATA";
export const ACTIVE_DOMAIN_STATUS: string = "Active";
