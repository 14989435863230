export const InputType: {
  text: string,
  textarea: string,
  number: string,
  email: string,
  phone: string,
  country: string,
  country_region: string,
  select: string,
  checkbox: string,
  file: string,
} = {
  text: "text",
  textarea: "textarea",
  number: "number",
  email: "email",
  phone: "phone",
  country: "country",
  country_region: "country_region",
  select: "select",
  checkbox: "checkbox",
  file: "file",
};
