import $ from "jquery";
import LinkToModalContent from "./LinkToModalContent";

export default (editor, options = {}) => {
  const opts = {
    ...{
      changeCommand: "change-image",
      linkToCommand: "add-image-link",

      changeLabel: `Change image`,
      editImageCommand: "tui-image-editor",
    },
    ...options,
  };
  const {
    changeCommand,
    linkToCommand,
    linkModalTitle,
    changeLabel,
    linkToLabel,
    editImageCommand,
  } = opts;

  const domc = editor.DomComponents;
  const typeImage = domc.getType("image").model;

  domc.addType("image", {
    model: {
      initToolbar(...args) {
        typeImage.prototype.initToolbar.apply(this, args);
        const tb = this.get("toolbar");
        const tbChangeExists = tb.some(
          (item) => item.command === changeCommand,
        );
        if (!tbChangeExists) {
          tb.push({
            command: changeCommand,
            label: changeLabel,
          });
        }

        const tbLnkExists = tb.some((item) => item.command === linkToCommand);
        if (!tbLnkExists) {
          tb.push({
            command: linkToCommand,
            label: linkToLabel,
          });
        }
        this.set("toolbar", tb);
      },
    },
  });

  editor.on("component:selected", (component) => {
    const tag_name = component.get("tagName");
    const component_type = component.get("type");
    const tb = component.get("toolbar");
    if (component_type === "image") {
      const edit_cmd = tb.find((item) => item.command === editImageCommand);
      if (edit_cmd) {
        tb.push(tb.splice(tb.indexOf(edit_cmd), 1).pop()); // push the edit image to last on toolbar
      }
    }
  });

  editor.Commands.add(changeCommand, (editor) => {
    const component = editor.getSelected();
    editor.runCommand("open-assets", {
      target: this,
      types: ["image"],
      accept: "image/*",
      onClick: (asset) => {
        component.set("src", asset.get("src"));
      },
      onSelect: (asset) => {
        editor.PopUp.close();
        component.set("src", asset.get("src"));
      },
    });
  });

  editor.Commands.add(linkToCommand, {
    run() {
      this.editor = editor;
      this.options = opts;
      this.target = opts.target || editor.getSelected();
      const { target } = this;
      this.$ = editor.$;
      this.content = "";
      this.url = "";
      this.targetWindow = "";

      if (target && target.get("editable")) {
        this.showLinkInputModal();
      }
    },
    getSelectedElement() {
      const component = editor.em.getSelected();
      return component?.view.el;
    },
    showLinkInputModal() {
      const { target } = this;
      const title = options.title || linkModalTitle;
      this.getPrevValues(target);
      const content = this.getContent();
      editor.PopUp.open({
        title,
        content,
        selector: this.getSelectedElement(),
        autoPlacement: "top",
      })
        .getModel()
        .once("change:open", () => {
          editor.stopCommand(this.id);
        });
    },
    getPrevValues(target) {
      const parent_component = target.parent();
      if (parent_component.get("tagName") === "a") {
        const parent_attrs = parent_component.get("attributes");
        this.url = parent_attrs.href;
        this.targetWindow = parent_attrs.target;
      }
    },
    getContent() {
      this.options.url = this.url;
      this.options.targetWindow = this.targetWindow;
      this.options.setUrl = this.setUrl;
      this.options.saveSettings = this.saveSettings;
      this.options.setTargetWindow = this.setTargetWindow;
      const linkToModal = new LinkToModalContent(editor, this.options);
      const content = $(linkToModal.render());
      content.find("#link-to-input").on("keyup", (e) => this.setUrl(e));
      content
        .find("#link-to-target")
        .on("change", (e) => this.setTargetWindow(e));
      content.find("#add-link").on("click", (e) => this.saveSettings(e));
      return content;
    },
    setUrl(e) {
      this.url = e.currentTarget.value;
    },
    setTargetWindow(e) {
      this.targetWindow = e.currentTarget.value;
    },
    saveSettings(e) {
      const { target, url, targetWindow } = this;
      const parent_component = target.parent();
      if (parent_component.get("tagName") === "a") {
        parent_component.set("attributes", { href: url, target: targetWindow });
      } else {
        target.replaceWith(
          `<a href="${url}" target="${targetWindow}">${target.toHTML()}</a>`,
        );
      }
      editor.PopUp.close();
    },
  });
};
