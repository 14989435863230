import PropTypes from "prop-types";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import Widget from "modules/marketing/components/dashboard/Widget";
import MarketingAPI from "modules/marketing/MarketingAPI";

function CampaignsChart({ match, filterParams }) {
  const [widget, setWidget] = useState("events-in-time");

  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);

  const { widgetData, error, isLoading } = API.getDashboardWidget(
    widget,
    filterParams,
  );

  const widgets = [
    {
      value: "events-in-time",
      label: "Campaign Events Triggered Over Time",
      tooltip:
        "This chart displays the total number of successful events triggered in your campaigns over time. Events include actions such as emails sent, emails opened, changes in contact stages, and contacts added to segments. Each point on the chart represents the aggregate count of these events for the corresponding time period.",
    },
  ];

  return (
    <Widget
      widget={widget}
      isLoading={isLoading}
      error={error}
      widgets={widgets}
      data={widgetData}
      onSelectWidget={setWidget}
    />
  );
}

CampaignsChart.defaultProps = {
  filterParams: {},
};

CampaignsChart.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  filterParams: PropTypes.instanceOf(Object),
};

export default withRouter(CampaignsChart);
