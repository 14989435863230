import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import { BillingAddon } from "modules/base/models/Billing";
import { getMoneyFormat } from "modules/base/utilities/Actions";

/**
 * BaseAddonPurchaseForm is a functional component that renders a form for purchasing a billing addon.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.addon - The billing addon to be purchased.
 * @param {Function} props.handleSubmit - The function to be called when the form is submitted.
 * @param {boolean} props.isSubmitting - A boolean indicating whether the form is currently being submitted.
 * @param {string|Object} props.error - Any error that occurred while submitting the form.
 * @param {string} props.submitButtonColor - The color of the submit button.
 * @param {string|Object} props.success - Any success message to be displayed after form submission.
 *
 * @returns {JSX.Element} The rendered form for purchasing a billing addon.
 */
function BaseAddonPurchaseForm(props) {
  const {
    addon,
    handleSubmit,
    isSubmitting,
    error,
    submitButtonColor,
    success,
  } = props;
  const amountDisplay = getMoneyFormat(addon.price, addon.currency);
  const description = useCallback(() => {
    return addon.description?.split("\r\n").map((line) => (
      <React.Fragment key={uuidv4()}>
        {line}
        <br />
      </React.Fragment>
    ));
  });
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      <Form
        handleSubmit={handleSubmit}
        button_label="Complete"
        id="wallet-top-up-form"
        isSubmitting={isSubmitting}
        padding="p-0"
        fullLengthButton
        button_color={submitButtonColor}
      >
        <div className="row row-cols-1 mb-2">
          <span className="mt-2 fs-6 fw-lighter">{description()}</span>
          <div className="mt-2 fs-6">
            <i className="bi bi-credit-card-fill" /> We will charge your card{" "}
            {amountDisplay}
          </div>
        </div>
      </Form>
    </>
  );
}

BaseAddonPurchaseForm.defaultProps = {
  submitButtonColor: "primary",
  error: null,
  success: null,
};

BaseAddonPurchaseForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  success: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
  submitButtonColor: PropTypes.string,
  addon: PropTypes.instanceOf(BillingAddon).isRequired,
};

export default BaseAddonPurchaseForm;
