import { InputType } from "../../../core/constants/Enumerators";
import { FormInput } from "../../../core/models/FormInput";
import { Shop } from "../../models/Shop";

/**
 * @name EmailSettingsInputs
 * @param shop
 * @returns {[FormInput,FormInput,FormInput]}
 * @constructor
 */
export default function EmailSettingsInputs(shop: Shop) {
  return [
    new FormInput(
      InputType.email,
      "mail_from_address",
      "Mail from address",
      "",
      shop.mail_from_address,
      true,
      true,
    ),
    new FormInput(
      InputType.email,
      "customer_support_email",
      "Customer support email",
      "",
      shop.customer_support_email,
      false,
      true,
    ),
    new FormInput(
      InputType.email,
      "new_order_notification_email",
      "New order notification email",
      "",
      shop.new_order_notification_email,
      false,
      true,
    ),
  ];
}
