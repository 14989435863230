/**
 * @class FacebookPage
 * @description Model for Facebook Page
 */
export class FacebookProduct {
  id: number;

  retailer_id: String;

  name: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.retailer_id = parameters.retailer_id;
    this.name = parameters.name;
  }
}
