import PropTypes from "prop-types";

function Accordion(props) {
  const { children, id, classes } = props;
  return (
    <div className={`accordion ${classes}`} id={id}>
      {children}
    </div>
  );
}

Accordion.defaultProps = {
  id: "accordion",
  classes: "",
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  classes: PropTypes.string,
};

export default Accordion;
