import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import { openModal } from "modules/base/utilities/Actions";
import { serverGeneratePath } from "modules/base/utilities/Navigation";
import CreateFormModal from "modules/marketing/components/forms/CreateFormModal";
import EditFormModal from "modules/marketing/components/forms/EditFormModal";
import publishUnpublishActionColumn from "modules/marketing/components/PublishUnpublishActionColumn";
import { formsColumns } from "modules/marketing/components/tableColumns";
import MarketingAPI from "modules/marketing/MarketingAPI";
import { PATH_MARKETING_FORM_SUBMISSIONS } from "modules/marketing/MarketingConstants";

function FormList(props) {
  const { match } = props;
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const [error, setError] = useState(null);
  const [paginationParams, setPaginationParams] = useState({
    start: 0,
    limit: 10,
  });
  const params = useMemo(
    () => ({
      ...paginationParams,
      orderBy: "dateAdded",
      orderByDir: "desc",
    }),
    [paginationParams],
  );
  const { forms, total, isLoading, error: fetch_error } = API.getForms(params);
  const onClickCreate = useCallback(() => {
    openModal("create-form-modal");
  });

  const onClickEdit = useCallback((form) => {
    openModal(`edit-form-modal-${form.id}`);
  });

  const onClickDelete = useCallback((id, onSuccess, onError) => {
    API.deleteForm(
      id,
      () => {
        onSuccess();
      },
      (error_response) => {
        onError("Unable to delete form", error_response.message);
      },
    );
  });

  const viewSubmissionsActionColumn = {
    header: "Submissions",
    accessorKey: "viewSubmissions",
    cell: ({ row }) => {
      const { original } = row;
      const { id } = original;
      const submissionsPath = serverGeneratePath(
        PATH_MARKETING_FORM_SUBMISSIONS,
        {
          formId: id,
          id: siteId,
        },
      );
      return (
        <NavLink className="btn btn-link p-0" to={submissionsPath}>
          View submissions
        </NavLink>
      );
    },
  };
  const handlePaginationChange = useCallback((newPaginationParams) => {
    setPaginationParams(newPaginationParams);
  }, []);

  const columns = [
    ...formsColumns,
    viewSubmissionsActionColumn,
    publishUnpublishActionColumn(siteId, setError, "updateForm"),
  ];

  return (
    <>
      <NetworkMessageDisplay error={error ?? fetch_error} />
      <CreateFormModal />
      <DataTable
        data={forms}
        columns={columns}
        total={total}
        isLoading={isLoading}
        createButtonColor="gray-900"
        onClickCreate={onClickCreate}
        onClickDelete={onClickDelete}
        allowCreate
        allowEdit
        allowDelete
        editButtonColor="gray-900"
        EditModal={EditFormModal}
        onClickEdit={onClickEdit}
        onPaginationChange={handlePaginationChange}
        isServerSidePagination
        objectType="form"
      />
    </>
  );
}

FormList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(FormList);
