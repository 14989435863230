import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import { User } from "modules/account/AccountModels";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import DebitCreditCardForm from "modules/billing/components/DebitCreditCardForm";
import ComponentLoadingIndicator from "modules/core/components/ComponentLoadingIndicator";
import { NS1_RECORD, NS2_RECORD } from "modules/core/constants/Constants";
import { navigateReloadPage } from "modules/core/utilities/navigation/Navigator";
import DomainNameserversForm from "modules/domains/components/DomainNameserversForm";
import DomainSearchForm from "modules/domains/components/DomainSearchForm";
import DomainSuggestionCard from "modules/domains/components/DomainSuggestionCard";
import { DomainApi } from "modules/domains/DomainsAPIs";
import { DomainSuggestion } from "modules/domains/DomainsModels";
import "react-toastify/dist/ReactToastify.css";
import { BillingAPI } from "modules/billing/BillingAPIs";
import { openModal } from "modules/base/utilities/Actions";

function DomainSearch(props) {
  const { user, displayTitle } = props;
  const [is_checking_domain_availability, set_is_checking_domain_availability] =
    useState(false);
  const billingAPI = new BillingAPI();
  const { customer, error: billingCustomerError } =
    billingAPI.getBillingCustomer("i");
  const [domain_suggestions, setDomainSuggestions] = useState([]);
  const [ordering_domain, set_ordering_domain] = useState(false);
  const [domain_name, set_domain_name] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [state, setState] = useState({
    selected_domains: [],
    ns1: NS1_RECORD,
    ns2: NS2_RECORD,
    ns3: "",
    ns4: "",
    ns5: "",
  });

  const changeDomain: any = (event) => {
    const { value } = event.target;
    set_domain_name(value);
  };

  const refresh_selected_domains: any = () => {
    const parameters: any = state;
    parameters.selected_domains = [];
    setState((prevState) => {
      return { ...prevState, ...parameters };
    });
  };

  const api: any = new DomainApi();

  const checkForDomainAvailability: any = (event) => {
    event.preventDefault();
    if (domain_name) {
      set_is_checking_domain_availability(true);
      setDomainSuggestions([]);
      refresh_selected_domains();
      setError(null);
      api.retrieveDomainSuggestions(
        domain_name,
        (is_successful, domain_suggestions_or_error) => {
          set_is_checking_domain_availability(false);
          if (is_successful) {
            setDomainSuggestions(domain_suggestions_or_error);
          } else {
            setError(domain_suggestions_or_error);
          }
        },
      );
    } else {
      setError("Domain name is required.");
    }
  };

  const handleChange: any = (event) => {
    const { name, value } = event.target;
    const parameters: any = state;
    parameters[name] = value;
    setState((prevState) => {
      return { ...prevState, ...parameters };
    });
  };

  const is_domain_selected: any = (selected_domain_name) => {
    return state.selected_domains.indexOf(selected_domain_name) !== -1;
  };

  function toggleSelectDomain(selected_domain_name) {
    if (is_domain_selected(selected_domain_name)) {
      const index: any = state.selected_domains.indexOf(selected_domain_name);
      const new_domains: any = [...state.selected_domains];
      new_domains.splice(index, 1);
      const parameters: any = state;
      parameters.selected_domains = new_domains;
      setState((prevState) => {
        return { ...prevState, ...parameters };
      });
    } else {
      const parameters: any = state;
      parameters.selected_domains = [
        ...state.selected_domains,
        selected_domain_name,
      ];
      setState((prevState) => {
        return { ...prevState, ...parameters };
      });
    }
  }

  const orderDomain = useCallback(
    (e) => {
      e.preventDefault();
      if (!customer.has_minimum_cards_allowed) {
        openModal("add-card");
      } else {
        setError(null);
        set_ordering_domain(true);
        api.createDomainOrder(
          state.selected_domains,
          state.ns1,
          state.ns2,
          state.ns3,
          state.ns4,
          state.ns5,
          (is_successful, payment_url_or_error) => {
            set_ordering_domain(false);
            if (is_successful) {
              setSuccess({
                message: "The domain(s) order has been placed successfully.",
              });
              navigateReloadPage();
            } else {
              setError(payment_url_or_error);
            }
          },
        );
      }
    },
    [state, customer],
  );
  const handleSelectDomain: any = useCallback((domain_suggestion) => () => {
    toggleSelectDomain(domain_suggestion.domain_name);
  });
  const domainSuggestions: any = domain_suggestions.map(
    (domain_suggestion: DomainSuggestion) => {
      return (
        <DomainSuggestionCard
          key={domain_suggestion.id}
          currency={user.currency}
          domain_suggestion={domain_suggestion}
          orderDomain={() => orderDomain()}
          ordering_domain={ordering_domain}
          toggleSelectDomain={handleSelectDomain(domain_suggestion)}
          is_domain_selected={is_domain_selected(domain_suggestion.domain_name)}
        />
      );
    },
  );

  const is_any_selected_domain: any = state.selected_domains.length > 0;

  let proceed_purchase;
  if (is_any_selected_domain) {
    proceed_purchase = (
      <DomainNameserversForm
        state={state}
        onChangeText={handleChange}
        handleSubmit={orderDomain}
        isSubmitting={ordering_domain}
        error={error}
      />
    );
  }
  let domainSearchingIndicator;
  if (is_checking_domain_availability) {
    domainSearchingIndicator = <ComponentLoadingIndicator />;
  }

  return (
    <div>
      <div
        className="d-flex flex-column justify-content-center h-100"
        data-tutorial="domain-search"
      >
        <h2
          className="mb-4 text-center"
          data-tutorial="domain-welcome"
          hidden={!displayTitle}
        >
          Find your new domain name
        </h2>

        <div className="mb-4 search-domain-step">
          <DebitCreditCardForm isDismissible={false} moveModalToOuterMost />
          <SuccessActionIndicator success={success} />
          <NetworkMessageDisplay error={error ?? billingCustomerError} />
          <DomainSearchForm
            onSubmit={checkForDomainAvailability}
            value={domain_name}
            onChange={changeDomain}
            disabled={is_checking_domain_availability}
            error={error}
          />
        </div>

        <div id="accordion">
          {domainSuggestions}
          {domainSearchingIndicator}
          {proceed_purchase}
        </div>
      </div>
    </div>
  );
}

DomainSearch.propTypes = {
  user: PropTypes.instanceOf(User).isRequired,
  displayTitle: PropTypes.bool,
};

DomainSearch.defaultProps = {
  displayTitle: true,
};
export default withRouter(DomainSearch);
