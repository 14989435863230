import PropTypes from "prop-types";
import { useCallback } from "react";
import { NodeToolbar, Position, useReactFlow } from "reactflow";

function DeleteButton({ nodeId }) {
  const reactFlowInstance = useReactFlow();
  const { deleteElements } = reactFlowInstance;
  const handleClick = useCallback(() => {
    deleteElements({ nodes: [{ id: nodeId.toString() }] });
  }, [deleteElements, nodeId]);
  return (
    <NodeToolbar position={Position.Right}>
      <button type="button" className="btn btn-link p-0" onClick={handleClick}>
        <i className="bi bi-trash-fill text-danger" />
      </button>
    </NodeToolbar>
  );
}

DeleteButton.propTypes = {
  nodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default DeleteButton;
