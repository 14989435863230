import { FORM_COLLECT_URL } from "../../core/constants/Endpoints";

export default (editor, options = {}) => {
  const { site_id, removeFormCaptcha } = options;
  const domc: any = editor.DomComponents;

  const script: any = function (props) {
    const createElement: any = (tag, id) => {
      const s1: any = document.createElement(tag);
      s1.id = id;
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      return s1;
    };
    if (typeof window.jQuery === "undefined") {
      (function () {
        const s1: any = createElement("script", "jquery-script");
        s1.src = "https://code.jquery.com/jquery-3.6.0.min.js";
        s1.integrity = "sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=";
        document.head.appendChild(s1);
      })();
    }

    if (!document.getElementById("captcha-script")) {
      (function () {
        const s1: any = createElement("script", "captcha-script");
        s1.src = "https://s3.olitt.net/media/leta/blocks/forms/app.js";
        document.head.appendChild(s1);
      })();
    }
    const el: any = this;
    const { form_name } = props;
    const form_id: string = `${el.id}`;

    document.getElementById(form_id).addEventListener("submit", function (e) {
      e.preventDefault();
      const form: any = window.jQuery(this);
      const form_data: any = new FormData(e.target);
      form_data.append("form_name", form_name);
      const formObject: any = {};
      form_data.forEach(function (value, key) {
        formObject[key] = value;
      });
      const action_url: string = form.attr("action");
      const submit_btn: any = window.jQuery(`#${el.id} [type=submit]`);
      const submit_text: string = submit_btn.html();
      const alert_styles: string = "margin: 20px 0";
      const reset_ui: any = () => {
        const response_cont: any = window.jQuery("#response-cont");
        if (response_cont) {
          response_cont.remove();
        }
        submit_btn.prop({
          disabled: false,
          innerHTML: `${submit_text}`,
        });
      };
      submit_btn.prop({
        disabled: false,
        innerHTML: `${submit_text}ing..`,
      });
      fetch(action_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formObject),
      })
        .then((response) => {
          reset_ui();
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          window
            .jQuery(
              `<div class="alert alert-success" role="alert" id="response-cont" style="${alert_styles}">${data}</div>`,
            )
            .insertAfter(submit_btn);
        })
        .catch((error) => {
          reset_ui();
          try {
            error.json().then((body) => {
              window
                .jQuery(
                  `<div class="alert alert-danger" role="alert" id="response-cont" style="${alert_styles}">${body.form_data[0]}!</div>`,
                )
                .insertAfter(submit_btn);
            });
          } catch (error) {
            window
              .jQuery(
                `<div class="alert alert-danger" role="alert" id="response-cont" style="${alert_styles}">An error Occurred!</div>`,
              )
              .insertAfter(submit_btn);
          }
        });
    });
  };

  const formModel = (domc.getType("form") ?? {}).model ?? {
    prototype: {
      defaults: {
        traits: [],
      },
    },
  };

  domc.addType("form", {
    isComponent: (el) => el.tagName === "FORM",
    model: {
      defaults: {
        ...formModel.prototype.defaults,
        form_name: "Contact Form",
        script,
        traits: [
          ...formModel.prototype.defaults.traits,
          {
            label: "Form Name",
            name: "form_name",
            type: "text",
            changeProp: 1,
          },
        ],
        "script-props": ["form_name"],
      },
      init() {
        const valuesOnchange: any = () => {
          this.trigger("change:script");
        };
        this.on("change:form_name", () => valuesOnchange());
      },
    },
  });

  editor.on("component:mount", (component) => {
    const component_type: any = component.get("type");
    if (component_type === "form" && !removeFormCaptcha) {
      component.set("attributes", {
        action: "/",
        method: "post",
      });
      const submit_btn: any = component.find('[type="submit"]');
      if (submit_btn[0]) {
        const captcha_base: any = component.find(".captcha-base");
        if (captcha_base.length === 0) {
          const submit_btn_parent: any = submit_btn[0].parent();
          submit_btn_parent.append('<div class="captcha-base"></div>', {
            at: 0,
          });
        }
      }
    }
  });
};
