import PropTypes from "prop-types";
import MarketingAPI from "modules/marketing/MarketingAPI";

function TotalNotes(contacts, siteId) {
  const API = new MarketingAPI(siteId);

  return {
    header: "Notes",
    accessor: "totalNotes",
    cell: ({ row }) => {
      const { notes, error } = API.getNotes(row.id);
      if (error) {
        return <p>Error loading notes</p>;
      }
      return <p>{notes.length}</p>;
    },
  };
}

TotalNotes.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  siteId: PropTypes.string.isRequired,
};

export default TotalNotes;
