import PropTypes from "prop-types";
import React from "react";
import Label from "../Label";

/**
 * @class FormCheckbox
 * @param {object} props - Component props
 * @returns {ReactElement}
 */
class FormCheckbox extends React.Component {
  /**
   * @method render
   * @returns {ReactElement}
   */
  render() {
    const { type, label, name, value, onChangeText, is_required, is_editable } =
      this.props;

    let label_element: string = "";
    if (label) {
      label_element = (
        <Label icon={null} text={label} is_important={is_required} />
      );
    }

    const disabled_class: string = is_editable ? "" : "disabled";

    return (
      <div className="form-check">
        <input
          className={`form-check-input ${disabled_class}`}
          name={name}
          type={type}
          checked={value}
          onChange={onChangeText}
          required={is_required}
          disabled={!is_editable}
        />
        {label_element}
      </div>
    );
  }
}

FormCheckbox.defaultProps = {
  is_required: false,
  is_editable: true,
  type: "checkbox",
};

FormCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChangeText: PropTypes.func.isRequired,
  is_required: PropTypes.bool,
  is_editable: PropTypes.bool,
  type: PropTypes.string,
};

export default FormCheckbox;
