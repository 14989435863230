import { useCallback } from "react";
import { withRouter } from "react-router-dom";
import { UserApi } from "modules/account/AccountAPIs";
import { Icon } from "modules/base/components/Icons";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";

function UserDropdown(props) {
  const userApi: any = new UserApi();
  const { user, error, isLoading } = userApi.getUser();
  const logout = useCallback(() => {
    userApi.logout(props);
  });
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  if (error) {
    return <NetworkMessageDisplay error={error} />;
  }
  return (
    <div className="dropdown">
      <button
        className="btn btn-link dropdown-toggle text-decoration-none text-gray-900"
        type="button"
        data-bs-toggle="dropdown"
        data-bs-display="static"
        aria-expanded="false"
        aria-label="User menu"
      >
        <Icon icon="person-circle" additionalClasses="fs-3" />
      </button>
      <ul className="dropdown-menu dropdown-menu-end">
        <li>
          <button className="dropdown-item" type="button">
            <Icon icon="person-circle" /> {user.name}
          </button>
        </li>
        <li>
          <button className="dropdown-item" type="button">
            <Icon icon="envelope" /> {user.email}
          </button>
        </li>
        <hr />
        <li>
          <button className="dropdown-item" type="button" onClick={logout}>
            <Icon icon="box-arrow-right" /> Logout
          </button>
        </li>
      </ul>
    </div>
  );
}

export default withRouter(UserDropdown);
