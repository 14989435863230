import PropTypes from "prop-types";
import { useCallback } from "react";
import { withRouter } from "react-router-dom";
import Logo from "modules/core/assets/logo-secondary.svg";
import UserDropdown from "modules/core/components/UserDropdown";
import { navigateToPage } from "modules/core/utilities/navigation/Navigator";
import WebsiteDomain from "modules/website/components/WebsiteDomain";
import { PATH_WEBSITES } from "modules/website/WebsiteConstants";
import { Site } from "modules/website/WebsiteModels";

function TopBar(props) {
  const goHome = useCallback(() => {
    navigateToPage(PATH_WEBSITES, props);
  });
  const { site } = props;
  return (
    <nav className="z-1 navbar bg-body-tertiary shadow sticky-top p-0">
      <div className="container-fluid">
        <button type="button" className="btn btn-link p-0" onClick={goHome}>
          <img src={Logo} alt="Olitt" width={42} height={42} />
        </button>
        <div className="d-flex flex-column flex-md-row align-items-end align-items-md-center">
          {site && <WebsiteDomain website={site} view_mode="sites" />}
          <UserDropdown />
        </div>
      </div>
    </nav>
  );
}

TopBar.defaultProps = {
  site: null,
};

TopBar.propTypes = {
  site: PropTypes.instanceOf(Site),
};

export default withRouter(TopBar);
