import React from "react";

const TopCard = ({ title, count, itemsLabel, itemCount, backgroundColor }) => {
  const cardClass = `card rounded-3 shadow-sm ${
    backgroundColor === "white" ? "bg-white" : "bg-black"
  }`;
  const cardTitle = `${
    backgroundColor === "white" ? "text-black" : "text-warning"
  } text-uppercase fs-8`;

  const cardCount = `${
    backgroundColor === "white" ? "text-warning" : "text-white"
  } fw-bold`;

  const cardFooter = `${
    backgroundColor === "white" ? "text-black" : "text-white"
  } fs-7`;
  return (
    <div className={cardClass}>
      <div className="card-body">
        <div className="row">
          <div className="cl-12">
            <h6 className={cardTitle}>{title}</h6>
          </div>
          <div className="cl-12 gy-2">
            <h1 className={cardCount}>{count}</h1>
          </div>
        </div>
        <div className="row">
          <div className="cl-2 gy-4">
            <span className="fs-7 text-warning text-capitalize">
              {itemsLabel}: <span className={cardFooter}>{itemCount}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopCard;
