import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import {
  closeModalById,
  deleteNullProperties,
  handleInputChange,
} from "modules/base/utilities/Actions";
import CampaignFormFields from "modules/marketing/components/campaigns/CampaignFormFields";
import MarketingAPI from "modules/marketing/MarketingAPI";
import { Campaign } from "modules/marketing/MarketingModels";

function EditCampaign({ campaign, currentModalId, match }) {
  const { id: siteId } = match.params;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [state, setState] = useState(campaign);
  const API = new MarketingAPI(siteId);
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);
    API.updateCampaign(
      state.id,
      deleteNullProperties(state),
      (response) => {
        setIsSubmitting(false);
        setError(null);
        setState(response);
        closeModalById(currentModalId);
        setSuccess({
          message: "Campaign updated successfully",
        });
      },
      (error_response) => {
        setIsSubmitting(false);
        setError({
          message: error_response.message,
        });
        setSuccess(null);
      },
    );
  });

  const onInputChange = useCallback((e) =>
    handleInputChange(e.target.name, e.target.value, state, setState),
  );
  return (
    <Form
      handleSubmit={handleSubmit}
      id={`edit-campaign-${campaign.id}-form`}
      button_color="gray-900"
      button_label="Update"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      <CampaignFormFields
        state={state}
        setState={setState}
        onInputChange={onInputChange}
        currentModalId={currentModalId}
      />
    </Form>
  );
}

EditCampaign.propTypes = {
  campaign: PropTypes.instanceOf(Campaign).isRequired,
  currentModalId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(EditCampaign);
