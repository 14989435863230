/**
 * DNS user model
 */
export class DnsUser {
  id: number;

  username: string;

  first_name: string;

  last_name: string;

  email: string;

  role_id: number;

  role_name: string;

  constructor(parameters) {
    this.id = parameters.id;
    this.username = parameters.username;
    this.first_name = parameters.first_name;
    this.last_name = parameters.last_name;
    this.email = parameters.email;
    this.role_id = parameters.role_id;
    this.role_name = parameters.role_name;
  }
}

/**
 * DnsDomain model
 */
export class DnsDomain {
  name: string;

  constructor(parameters) {
    this.name = parameters.name;
  }
}

/**
 * DnsDomainRecord model
 */
export class DnsDomainRecord {
  name: string;

  content: string;

  disabled: boolean;

  ttl: number;

  type: string;

  formatted_name: string;

  constructor(parameters) {
    this.name = parameters.name;
    this.content = parameters.content;
    this.disabled = parameters.disabled;
    this.ttl = parameters.ttl;
    this.type = parameters.type;
    this.formatted_name = this.get_formatted_name(this.name);
  }

  get_formatted_name(name) {
    if (this.name.includes("@")) {
      return "@";
    }
    return name;
  }
}
