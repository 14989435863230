import PropTypes from "prop-types";
import React from "react";
import { IconLoading } from "../../base/components/Icons";
import { AppendedTextInput } from "../../core/components/form-controls/AppendedTextInput";
import { FormNumberInput } from "../../core/components/form-controls/FormNumberInput";
import FormSelectInput from "../../core/components/form-controls/FormSelectInput";
import { FormTextAreaInput } from "../../core/components/form-controls/FormTextAreaInput";
import FormTextInput from "../../core/components/form-controls/FormTextInput";
import { ToastUtility, toast_styles } from "../../core/utilities/ToastUtility";
import { DnsRecordsApi } from "../DnsAPIs";
import { DNS_RECORD_TYPE } from "../DnsConstants";

export class AddNewRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record_parameters: { domain_name: props.domain },
      is_adding_record: false,
      adding_record_error: null,
    };
  }

  changeRecordParameter = (event) => {
    const { name, value } = event.target;
    const { record_parameters } = this.state;
    record_parameters[name] = value;
    this.setState({ record_parameters });
  };

  getRecordFields = (
    record_type: string,
    record_parameters,
    is_adding_record: boolean,
  ) => {
    let record_fields;
    if (record_type === DNS_RECORD_TYPE.A) {
      record_fields = (
        <FormTextInput
          label="IPV4 address"
          placeholder="0.0.0.0"
          name="content"
          value={record_parameters.content ?? ""}
          is_required
          is_editable={!is_adding_record}
          onChangeText={this.changeRecordParameter}
        />
      );
    } else if (record_type === DNS_RECORD_TYPE.AAAA) {
      record_fields = (
        <FormTextInput
          label="IPV6 address"
          placeholder="::1"
          name="content"
          value={record_parameters.content ?? ""}
          is_required
          is_editable={!is_adding_record}
          onChangeText={this.changeRecordParameter}
        />
      );
    } else if (record_type === DNS_RECORD_TYPE.ALIAS) {
      record_fields = (
        <FormTextInput
          label="Target domain"
          placeholder="www.example.com."
          name="content"
          value={record_parameters.content ?? ""}
          is_required
          is_editable={!is_adding_record}
          onChangeText={this.changeRecordParameter}
        />
      );
    } else if (record_type === DNS_RECORD_TYPE.CAA) {
      record_fields = (
        <div>
          <FormNumberInput
            label="CAA Flag"
            placeholder="0"
            name="caa-flag"
            text={record_parameters["caa-flag"] ?? ""}
            is_required
            is_editable={!is_adding_record}
            onChangeText={this.changeRecordParameter}
          />
          <FormTextInput
            label="CAA Tag"
            placeholder="issue"
            name="caa-tag"
            value={record_parameters["caa-tag"] ?? ""}
            is_required
            is_editable={!is_adding_record}
            onChangeText={this.changeRecordParameter}
          />
          <FormTextInput
            label="CAA Value"
            placeholder="letsencrypt.org"
            name="caa-value"
            value={record_parameters["caa-value"] ?? ""}
            is_required
            is_editable={!is_adding_record}
            onChangeText={this.changeRecordParameter}
          />
        </div>
      );
    } else if (record_type === DNS_RECORD_TYPE.CNAME) {
      record_fields = (
        <FormTextInput
          label="Target domain"
          placeholder="www.example.com."
          name="content"
          value={record_parameters.content ?? ""}
          is_required
          is_editable={!is_adding_record}
          onChangeText={this.changeRecordParameter}
        />
      );
    } else if (record_type === DNS_RECORD_TYPE.LOC) {
      record_fields = (
        <FormTextInput
          label="Location Coordinates"
          placeholder="1 1 1.000 N 2 2 2.000 E 0.00m 1.00m 10000.00m 10.00m"
          name="content"
          value={record_parameters.content ?? ""}
          is_required
          is_editable={!is_adding_record}
          onChangeText={this.changeRecordParameter}
        />
      );
    } else if (record_type === DNS_RECORD_TYPE.MX) {
      record_fields = (
        <div>
          <FormNumberInput
            label="MX Priority"
            placeholder="0 - 65535"
            name="mx-priority"
            text={record_parameters["mx-priority"] ?? ""}
            is_required
            is_editable={!is_adding_record}
            onChangeText={this.changeRecordParameter}
          />
          <FormTextInput
            label="MX Server"
            placeholder="mail.example.com"
            name="mx-server"
            value={record_parameters["mx-server"] ?? ""}
            is_required
            is_editable={!is_adding_record}
            onChangeText={this.changeRecordParameter}
          />
        </div>
      );
    } else if (record_type === DNS_RECORD_TYPE.NS) {
      record_fields = (
        <FormTextInput
          label="Nameserver"
          placeholder="ns1.example.com"
          name="content"
          value={record_parameters.content ?? ""}
          is_required
          is_editable={!is_adding_record}
          onChangeText={this.changeRecordParameter}
        />
      );
    } else if (record_type === DNS_RECORD_TYPE.PTR) {
      record_fields = (
        <FormTextInput
          label="Domain name"
          placeholder="example.com"
          name="content"
          value={record_parameters.content ?? ""}
          is_required
          is_editable={!is_adding_record}
          onChangeText={this.changeRecordParameter}
        />
      );
    } else if (record_type === DNS_RECORD_TYPE.SRV) {
      record_fields = (
        <div>
          <FormNumberInput
            label="SRV Priority"
            placeholder="0"
            name="srv-priority"
            text={record_parameters["srv-priority"] ?? ""}
            is_required
            is_editable={!is_adding_record}
            onChangeText={this.changeRecordParameter}
          />
          <FormNumberInput
            label="SRV Weight"
            placeholder="10"
            name="srv-weight"
            text={record_parameters["srv-weight"] ?? ""}
            is_required
            is_editable={!is_adding_record}
            onChangeText={this.changeRecordParameter}
          />
          <FormNumberInput
            label="SRV Port"
            placeholder="5060"
            name="srv-port"
            text={record_parameters["srv-port"] ?? ""}
            is_required
            is_editable={!is_adding_record}
            onChangeText={this.changeRecordParameter}
          />
          <FormTextInput
            label="SRV Target"
            placeholder="sip.example.com"
            name="srv-target"
            value={record_parameters["srv-target"] ?? ""}
            is_required
            is_editable={!is_adding_record}
            onChangeText={this.changeRecordParameter}
          />
        </div>
      );
    } else if (record_type === DNS_RECORD_TYPE.TXT) {
      record_fields = (
        <FormTextAreaInput
          label="Content"
          placeholder="Your content here"
          name="content"
          text={record_parameters.content ?? ""}
          is_required
          is_editable={!is_adding_record}
          onChangeText={this.changeRecordParameter}
        />
      );
    } else {
      this.changeRecordParameter({
        target: { name: "type", value: DNS_RECORD_TYPE.A },
      });
      record_fields = "";
    }
    return record_fields;
  };

  render() {
    const { domain, cancelAddingRecord, refreshRecords } = this.props;
    const { record_parameters, is_adding_record, adding_record_error } =
      this.state;

    const onSubmit: any = (event) => {
      event.preventDefault();

      this.setState({
        is_adding_record: true,
        adding_record_error: null,
      });

      if (
        record_parameters.type === DNS_RECORD_TYPE.ALIAS ||
        record_parameters.type === DNS_RECORD_TYPE.CNAME ||
        record_parameters.type === DNS_RECORD_TYPE.NS ||
        record_parameters.type === DNS_RECORD_TYPE.PTR
      ) {
        const { content } = record_parameters;
        if (!content.endsWith(".")) {
          record_parameters.content = `${content}.`;
        }
      }

      if (record_parameters.type === DNS_RECORD_TYPE.CAA) {
        const flag: any = record_parameters["caa-flag"];
        const tag: any = record_parameters["caa-tag"];
        const value: any = record_parameters["caa-value"];
        record_parameters.content = `${flag} ${tag} "${value}"`;
      }

      if (record_parameters.type === DNS_RECORD_TYPE.MX) {
        const priority: any = record_parameters["mx-priority"];
        const server: any = record_parameters["mx-server"];
        record_parameters.content = `${priority} ${server}.`;
      }

      if (record_parameters.type === DNS_RECORD_TYPE.SRV) {
        const priority: any = record_parameters["srv-priority"];
        const weight: any = record_parameters["srv-weight"];
        const port: any = record_parameters["srv-port"];
        const target: any = record_parameters["srv-target"];
        record_parameters.content = `${priority} ${weight} ${port} ${target}.`;
      }

      const dns_records_api: any = new DnsRecordsApi(domain);
      dns_records_api.createRecord(
        record_parameters,
        (is_successful, record_or_error) => {
          let error: any = record_or_error;
          if (is_successful) {
            error = null;
            ToastUtility.toast(
              toast_styles.success,
              `Record added (${record_or_error.formatted_name})`,
            );
            refreshRecords(domain);
            cancelAddingRecord();
          }
          this.setState({
            is_adding_record: false,
            adding_record_error: error,
          });
        },
      );
    };

    let btn_add_record_text;
    if (is_adding_record) {
      btn_add_record_text = <IconLoading text="Adding" />;
    } else {
      btn_add_record_text = "Add";
    }

    // ToDo: Prevent closing form (calling Cancel button) on press enter
    return (
      <div className="card-body rounded shadow mb-4">
        <div
          className="alert alert-danger"
          role="alert"
          hidden={!adding_record_error}
        >
          {adding_record_error}
        </div>
        <form onSubmit={onSubmit} className="row g-1">
          <FormSelectInput
            label="Type"
            placeholder="Choose record type"
            name="type"
            is_required
            is_editable={!is_adding_record}
            selected_option={record_parameters.type}
            options={Object.keys(DNS_RECORD_TYPE)}
            onSelectOption={this.changeRecordParameter}
          />

          <AppendedTextInput
            label="Name"
            placeholder="Use @ for root"
            name="name"
            append_text={`.${domain}`}
            text={record_parameters.name ?? ""}
            is_required
            is_editable={!is_adding_record}
            onChangeText={this.changeRecordParameter}
          />

          {this.getRecordFields(
            record_parameters.type,
            record_parameters,
            is_adding_record,
          )}

          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-outline-olitt-grey shadow-none me-2"
              onClick={cancelAddingRecord}
            >
              Cancel
            </button>
            <button
              className="btn btn-olitt-grey shadow-none"
              type="submit"
              disabled={is_adding_record}
            >
              {btn_add_record_text}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

AddNewRecord.propTypes = {
  domain: PropTypes.string.isRequired,
  cancelAddingRecord: PropTypes.func.isRequired,
  refreshRecords: PropTypes.func.isRequired,
};
