import React from "react";
import { Icon } from "../../../base/components/Icons";
import Label from "../Label";

export class FormEmailInput extends React.Component {
  render() {
    const { label } = this.props;
    const { placeholder } = this.props;
    const { name } = this.props;
    const text: any = this.props.value;
    const { onChangeText } = this.props;
    const { is_required } = this.props;
    const { is_editable } = this.props;

    let label_element: string = "";
    if (label) {
      label_element = (
        <Label icon={null} text={label} is_important={is_required} />
      );
    }

    return (
      <>
        {label_element}
        <div className="input-group">
          <input
            type="email"
            className="form-control"
            placeholder={placeholder}
            name={name}
            value={text}
            onChange={onChangeText}
            required={is_required}
            disabled={!is_editable}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <Icon icon="envelope" />
            </span>
          </div>
        </div>
      </>
    );
  }
}
