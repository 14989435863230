import { withRouter } from "react-router-dom";
import RaceHorse from "../../core/assets/racehorse.png";
import MainNavigationSidebar from "../../core/components/MainNavigationSidebar";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import GenerateContent from "../components/GenerateContent";

function ContentGenerator() {
  return (
    <SidebarWrapper sidebar={<MainNavigationSidebar />}>
      <div className="card card-body shadow-sm border-0">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5
            className="text-olitt-grey font-weight-bold "
            data-tutorial="ai-generator"
          >
            Go Wild!{" "}
            <img src={RaceHorse} width={40} height={40} alt="go wild" />
          </h5>
        </div>
        <GenerateContent />
      </div>
    </SidebarWrapper>
  );
}

export default withRouter(ContentGenerator);
