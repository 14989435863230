import { google_translate_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const tm = editor.Panels.getButton("views", "open-tm");
  const { GoogleTranslateComponent } = opts;

  const placeholder = `<div style="text-align:center;color:#f6a908;height: 50px;">${google_translate_logo}</div><div style="text-align:center;color:#f6a908;">This is a placeholder and will not be rendered. Toggle visibility in layers</div>`;

  domc.addType("GOOGLE-TRANSLATE-COMPONENT", {
    model: {
      defaults: {
        tagName: "div",
        name: "Google Translate",
        draggable: "[data-gjs-type=wrapper]",
        droppable: false,
        stylable: false,
        copyable: false,
        content: `<div id="google-translate-container" style="padding: 25px;">${placeholder}</div>`,
        script() {
          const script_link = `https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`;
          (function (w, d, s, l) {
            w[l] = w[l] || [];
            w[l].push({
              "GT.start": new Date().getTime(),
              event: "GT.js",
            });
            if (!isScriptLinkInHeader(script_link)) {
              const f = d.getElementsByTagName("head")[0];
              const j = d.createElement(s);
              const dl = l !== "dataLayer" ? `&l=${l}` : "";
              j.type = "text/javascript";
              j.src = script_link;
              f.appendChild(j);
              const script_init =
                "function googleTranslateElementInit() { new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element'); }";
              const head = d.head || d.getElementsByTagName("head")[0];
              const script = d.createElement("script");
              const dtl = l != "dataLayer" ? `&l=${l}` : "";
              script.type = "text/javascript";
              head.appendChild(script);
              script.appendChild(d.createTextNode(script_init));
            }
          })(window, document, "script", "dataLayer");

          function isScriptLinkInHeader(url) {
            const scripts = document.getElementsByTagName("script");
            for (let i = scripts.length; i--; ) {
              if (scripts[i].src === url) return true;
            }
            return false;
          }
        },
      },
      init() {
        tm.set("active", 1);
      },
      toHTML() {
        const tagName = this.get("tagName");
        return `<${tagName} id="${this.getId()}"><div id="google_translate_element"></div></${tagName}>`;
      },
      ...GoogleTranslateComponent,
    },
    view: {},
  });
};
