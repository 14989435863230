import { useCallback, useState } from "react";
import BaseBillingDetailsForm from "modules/base/components/billing/BaseBillingDetailsForm";
import { closeModal } from "modules/base/utilities/Actions";
import { BillingAPI } from "modules/billing/BillingAPIs";

function BillingDetailsForm() {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, setState] = useState({});
  const billingAPI = new BillingAPI();
  const { customer, isLoading } = billingAPI.getBillingCustomer("i");

  function submitForm(e) {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);
    billingAPI.updateBillingCustomer(
      "i",
      state,
      () => {
        closeModal("change-billing-details");
        setSuccess({
          message: "Billing details updated successfully",
        });
        setIsSubmitting(false);
      },
      (error_response) => {
        setError({
          message: error_response.message,
        });
        setIsSubmitting(false);
      },
    );
  }

  const handleSubmit = useCallback((e) => submitForm(e));
  return (
    <BaseBillingDetailsForm
      handleSubmit={handleSubmit}
      error={error}
      success={success}
      isSubmitting={isSubmitting}
      isLoading={isLoading}
      customer={customer}
      state={state}
      setState={setState}
      submit_button_color="gray-900"
    />
  );
}

export default BillingDetailsForm;
