import $ from "jquery";
import { toast_styles, ToastUtility } from "../../core/utilities/ToastUtility";
import { GalleryActions } from "../WebsiteActions";
import { UNSPLASH_EMPTY_IMAGES } from "../WebsiteConstants";
import ObjectStorageApi from "modules/website/api/ObjectStorageApi";

export default (editor, options = {}) => {
  const opts: any = {
    ...{
      pfx: "gjs-",
    },
    ...options,
  };
  const { pfx } = opts;
  const am: any = editor.AssetManager;
  const storage_api: any = new ObjectStorageApi();

  const unsplashSearch: any = (e) => {
    e.preventDefault();
    let search_string: any = $("#unsplash-search-form #search_string").val();
    if (!search_string) {
      search_string = "random";
    }
    $("#unsplash-search-btn").prop({
      disabled: true,
      innerHTML: "searching...",
    });

    storage_api.fetchUnsplashImages(
      search_string,
      (is_successful, images_or_error) => {
        $("#unsplash-search-btn").prop({
          disabled: false,
          innerHTML: "Search",
        });
        if (is_successful) {
          const unsplash_images: any =
            GalleryActions.getUnsplashImagesObjects(images_or_error);
          if (unsplash_images.length === 0) {
            ToastUtility.toast(toast_styles.dark, UNSPLASH_EMPTY_IMAGES);
          }
          am.add(unsplash_images);
        } else {
          ToastUtility.toast(toast_styles.error, images_or_error);
        }
      },
    );
  };

  const getTabs: any = () => {
    // Disable default uploader and use uppy instead.
    $(".gjs-am-file-uploader").remove();
    const assets: any = am.getAll();
    const content: any = $(`<div class="${pfx}am-tab">
<button class="${pfx}tablinks" id="unsplash">Free Images</button>
                  <button class="${pfx}tablinks active" id="library">My Library</button>
            </div>`);
    am.render(assets.filter((asset) => asset.get("category") === "library"));
    content.find("button").on("click", (e) => {
      const target: any = e.currentTarget;
      content.find(`.${pfx}tablinks`).removeClass("active");
      $(target).addClass("active");
      const tab_name: any = target.id;
      am.render(assets.filter((asset) => asset.get("category") === tab_name));
      const search_bar: any = $(`
                <div class="gjs-field gjs-am-add-field mb-20" id="unsplash-search-input">
                <form id="unsplash-search-form">
                <input placeholder="Search for free images" name="search_string" id="search_string"/>
                <button class="gjs-btn-prim" type="submit" id="unsplash-search-btn">
                    Search
                </button>
                </form>             
            </div>
            `);
      search_bar
        .find("#unsplash-search-form")
        .on("submit", (e) => unsplashSearch(e));
      const input_selector: any = $("#unsplash-search-input");
      if (tab_name === "unsplash") {
        if (input_selector.length <= 0) {
          $(search_bar).insertAfter(`.${pfx}am-tab`);
        }
      } else {
        input_selector.remove();
      }
    });
    if ($("#unsplash-search-input")) {
      $("#unsplash-search-input").remove();
    }

    return content;
  };

  const onPopUpClose: any = () => {
    const popUpContainer = document.querySelector(`.pop-up-container`);
    popUpContainer.style = "";
    $(`.${pfx}am-tab`).remove();
  };

  editor.on("run:open-assets", () => {
    const popUp = editor.PopUp;
    popUp.onceClose(onPopUpClose);
    // const popUpContainer = document.querySelector(`.pop-up-container`);
    // popUpContainer.style = " max-width: 1000px;margin-top: 45px;";
    const popupBody = popUp.getContentEl();
    const assetsHeader = popupBody.querySelector(`.${pfx}am-assets-header`);

    const assetCont = popupBody.querySelector(`.${pfx}am-assets-cont`);

    assetCont.style.width = "100%";

    const tabs = getTabs();
    if (!$(`${pfx}am-tab`).length) {
      $(tabs).insertAfter(assetsHeader);
    }
  });

  const assets: any = am.getAll();
  assets.on("remove", function (asset) {
    const id: any = asset.get("id");
    const category: any = asset.get("category");
    if (category !== "unsplash") {
      storage_api.deleteObjects(id, (is_successful, success_or_error) => {
        if (is_successful) {
          ToastUtility.toast(toast_styles.success, "Image deleted");
        } else {
          ToastUtility.toast(toast_styles.error, success_or_error);
        }
      });
    }
  });
};
