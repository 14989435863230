import { ApmRoute as Route } from "@elastic/apm-rum-react";
import React from "react";
import { Switch } from "react-router-dom";
import { PATH_GENERATE_CONTENT, PATH_GENERATE_TEMPLATE } from "./CmsConstants";
import ContentGenerator from "./layouts/ContentGenerator";
import Page404 from "./layouts/Page404";
import TemplateGenerator from "./layouts/TemplateGenerator";

function CmsRouter() {
  return (
    <Switch>
      <Route
        key={1.1}
        exact
        path={PATH_GENERATE_CONTENT}
        component={ContentGenerator}
      />
      <Route
        key={1.1}
        exact
        path={`${PATH_GENERATE_TEMPLATE}:content_type?`}
        component={TemplateGenerator}
      />
      <Route key={1.2} path="*" component={Page404} />
    </Switch>
  );
}

export default CmsRouter;
