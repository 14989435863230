import { Model } from "backbone";

export default class PopUp extends Model {
  defaults = () => {
    return {
      content: "",
      attributes: {},
      open: false,
      maxWidth: null,
    };
  };

  open() {
    this.set("open", true);
  }

  close() {
    this.set("open", false);
  }
}
