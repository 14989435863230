export function serverGeneratePath(
  pattern: string,
  params: Record<string, string>,
) {
  return pattern.replace(/:([a-zA-Z_]+)/g, (_, key) => {
    if (key in params) {
      return `${params[key]}`;
    }
    throw new Error(`Missing parameter ${key} in pattern ${pattern}`);
  });
}
