import PropTypes from "prop-types";
import { memo, useState } from "react";
import { Handle, Position } from "reactflow";
import ConfigureButton from "modules/marketing/components/campaigns/nodes/configure/ConfigureButton";
import DeleteButton from "modules/marketing/components/campaigns/nodes/configure/DeleteButton";
import TargetHandle from "modules/marketing/components/campaigns/nodes/TargetHandle";
import { CAMPAIGN_NEW_LINE_COLOR } from "modules/marketing/MarketingConstants";

const DEFAULT_HANDLE_STYLE = {
  width: 10,
  height: 10,
  bottom: -5,
};

const NewNode = memo(function ActionNode({ data }) {
  const { campaignEvent, currentModalId, updateNode, updateEvent } = data;
  const [state, setState] = useState(campaignEvent);
  return (
    <>
      <ConfigureButton
        campaignEvent={state}
        currentModalId={currentModalId}
        setState={setState}
        updateNode={updateNode}
        updateEvent={updateEvent}
      />
      <TargetHandle />
      <div className="badge text-bg-secondary p-2">
        <i className="bi bi-activity" /> {state.name}
        <Handle
          type="source"
          id={CAMPAIGN_NEW_LINE_COLOR}
          position={Position.Bottom}
          style={{
            ...DEFAULT_HANDLE_STYLE,
            left: "50%",
            background: CAMPAIGN_NEW_LINE_COLOR,
          }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
      </div>
      <DeleteButton nodeId={campaignEvent.id} />
    </>
  );
});

NewNode.defaultProps = {
  data: {},
};

NewNode.propTypes = {
  data: PropTypes.instanceOf(Object),
};

export default NewNode;
