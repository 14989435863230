import { API_BASE_URL } from "../core/constants/Endpoints";
import { PATH_MAIN_ROUTE } from "../core/constants/Routes";

/**
 * API Endpoints
 */
export const URL_DNS_USER: string = `${API_BASE_URL}/dns/users/`;
export const URL_DNS_DOMAINS: string = `${API_BASE_URL}/dns/domains/`;
export const URL_DNS_DOMAIN_RECORDS: string = `${API_BASE_URL}/dns/records/`;

/**
 * Routes
 */
export const PATH_DNS: string = `${PATH_MAIN_ROUTE}/dns`;
export const PATH_DNS_DOMAINS: string = `${PATH_DNS}/domains`;
export const PATH_DNS_DOMAIN_RECORDS: string = `${PATH_DNS_DOMAINS}/:id`;

/**
 * Constants
 */
export const DNS_USER_DATA: string = "DNS_USER_DATA";
export const DNS_DOMAINS_DATA: string = "DNS_DOMAINS_DATA";
export const DNS_DOMAIN_RECORDS_DATA: string = "DNS_DOMAIN_RECORDS_DATA";
export const DNS_RECORD_TYPE: {
  A: string,
  AAAA: string,
  ALIAS: string,
  CAA: string,
  CNAME: string,
  LOC: string,
  MX: string,
  NS: string,
  PTR: string,
  SRV: string,
  TXT: string,
} = {
  A: "A",
  AAAA: "AAAA",
  ALIAS: "ALIAS",
  CAA: "CAA",
  CNAME: "CNAME",
  LOC: "LOC",
  MX: "MX",
  NS: "NS",
  PTR: "PTR",
  SRV: "SRV",
  TXT: "TXT",
};
