export const PAYMENT_METHODS_PROVIDERS: any = [
  {
    label: "Flutterwave (Banks, Mpesa, Cards, Mobile Money in Africa)",
    value: "flutterwave",
    accompanying_link: "https://flutterwave.com/",
    accompanying_link_text: "How to setup Flutterwave",
    configs: [
      {
        label: "Public Key",
        name: "public_key",
        type: "text",
        placeholder: "Enter your public key",
        required: true,
      },
      {
        label: "Secret Key",
        name: "secret_key",
        type: "text",
        placeholder: "Enter your secret key",
        required: true,
      },
      {
        label: "Encryption Key",
        name: "encryption_key",
        type: "text",
        placeholder: "Enter your encryption key",
        required: true,
      },
      {
        label: "Test Mode",
        name: "test_mode",
        type: "checkbox",
        required: false,
      },
    ],
  },
  {
    label: "PayPal",
    value: "paypal",
    accompanying_link: "https://paypal.com/",
    accompanying_link_text: "How to setup PayPal",
    configs: [
      {
        label: "Signature",
        name: "signature",
        type: "text",
        placeholder: "Enter your signature",
        required: true,
      },
      {
        label: "Currency code",
        name: "currency_code",
        type: "text",
        placeholder: "Enter your currency code",
        required: true,
      },
      {
        label: "Paypal email",
        name: "login",
        type: "text",
        placeholder: "Enter your login email",
        required: true,
      },
      {
        label: "Password",
        name: "password",
        type: "text",
        placeholder: "Enter your paypal password",
        required: true,
      },
      {
        label: "Test Mode",
        name: "test_mode",
        type: "checkbox",
        required: false,
      },
    ],
  },
  {
    label: "Stripe",
    value: "stripe",
    accompanying_link: "https://stripe.com/",
    accompanying_link_text: "How to setup Stripe",
    configs: [
      {
        label: "Public Key",
        name: "publishable_key",
        type: "text",
        placeholder: "Enter your public key",
        required: true,
      },
      {
        label: "Secret Key",
        key: "secret_key",
        type: "text",
        placeholder: "Enter your secret key",
        required: true,
      },
      {
        label: "Test Mode",
        key: "test_mode",
        type: "checkbox",
        required: false,
      },
    ],
  },
  {
    label: "Check",
    value: "check",
    accompanying_link: null,
    accompanying_link_text: null,
    configs: [],
  },
  {
    label: "Store Credit",
    value: "store_credit",
    accompanying_link: null,
    accompanying_link_text: null,
    configs: [],
  },
];
