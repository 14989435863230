import PropTypes from "prop-types";

const WidgetMenu = ({ widgets, handleWidgetSelect, showMenu, toggleMenu }) => {
  return (
    <div className="dropdown">
      <button
        onClick={toggleMenu}
        className="btn btn-light btn-sm"
        type="button"
        aria-expanded={showMenu}
        aria-label="Toggle widget options menu"
      >
        <i className="bi bi-three-dots-vertical" />
      </button>
      {showMenu && (
        <div
          className="dropdown-menu dropdown-menu-end show"
          role="menu"
          style={{ left: "auto", right: "0" }}
        >
          {widgets.map((wid) => (
            <button
              key={wid.value}
              type="button"
              className="dropdown-item"
              onClick={() => handleWidgetSelect(wid.value)}
            >
              {wid.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

WidgetMenu.propTypes = {
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleWidgetSelect: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default WidgetMenu;
