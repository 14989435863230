import { useState } from "react";
import { deleteNullProperties } from "modules/base/utilities/Actions";
import ContactDonotContactButton from "modules/marketing/components/DonotcontactButton";
import MarketingAPI from "modules/marketing/MarketingAPI";

function contactDoNotContactActionColumn(siteId, setError) {
  const API = new MarketingAPI(siteId);
  const [updatingStatusMap, setUpdatingStatusMap] = useState({});

  const startUpdating = (id) => {
    setUpdatingStatusMap((prev) => ({ ...prev, [id]: true }));
  };

  const stopUpdating = (id) => {
    setUpdatingStatusMap((prev) => ({ ...prev, [id]: false }));
  };

  return {
    header: "Status",
    accessorKey: "contactDoNotContact",
    cell: ({ row }) => {
      const { original } = row;
      const { id, doNotContact } = original;

      const onClick = () => {
        startUpdating(id);
        const payload = deleteNullProperties({
          ...original,
          doNotContact: !doNotContact,
          site: siteId,
        });

        const updateCallback = () => {
          stopUpdating(id);
          setError(null);
        };

        const errorCallback = (error_response) => {
          stopUpdating(id);
          setError({ message: error_response.message });
        };

        if (doNotContact.length > 0) {
          API.removeDnc(id, payload, updateCallback, errorCallback);
        } else {
          API.addDnc(id, payload, updateCallback, errorCallback);
        }
      };

      return (
        <ContactDonotContactButton
          doNotContact={doNotContact}
          onClick={onClick}
          isUpdatingContactStatus={updatingStatusMap[id] || false}
        />
      );
    },
  };
}

export default contactDoNotContactActionColumn;
