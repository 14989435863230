import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import { openModal } from "modules/base/utilities/Actions";
import ContentNoteButton from "modules/marketing/components/notes/ContentNoteButton";
import EditNoteModal from "modules/marketing/components/notes/EditNoteModal";
import { notesColumns } from "modules/marketing/components/tableColumns";
import MarketingAPI from "modules/marketing/MarketingAPI";

function NoteList({ match }) {
  const { id: siteId, contactId } = match.params;

  const [API, setAPI] = useState(new MarketingAPI(siteId));
  useEffect(() => {
    setAPI(new MarketingAPI(siteId));
  }, [siteId]);
  const [error, setError] = useState(null);

  let notesData = { notes: [], error: null, isLoading: false };
  try {
    notesData = API.getNotes(contactId) || notesData;
  } catch (e) {
    notesData.error = e.message;
  }

  const { notes, error: fetchError, isLoading } = notesData;

  const onClickEdit = useCallback(
    (note) => {
      openModal(`edit-note-modal-${note.id}`);
    },
    [API, contactId],
  );

  const onClickDelete = useCallback(
    (id, onSuccess, onError) => {
      const handleSuccess = () => {
        onSuccess();
      };

      const handleError = (e) => {
        onError("Failed", e.message);
      };

      API.deleteNote(id, handleSuccess, handleError);
    },
    [API],
  );

  const columns = [...notesColumns, ContentNoteButton(notes)];

  return (
    <>
      <NetworkMessageDisplay error={fetchError} />
      <DataTable
        data={notes}
        columns={columns}
        isLoading={isLoading}
        isDataPaginated
        enableSearch
        allowEdit
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        allowDelete
        EditModal={(props) => (
          <EditNoteModal {...props} contactId={contactId} />
        )}
        objectType="note"
        editButtonColor="gray-900"
      />
    </>
  );
}

NoteList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      contactId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(NoteList);
