import PropTypes from "prop-types";
import { useCallback } from "react";
import Form from "modules/base/components/Form";
import TextInput from "modules/base/components/inputs/TextInput";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import { BillingWallet } from "modules/base/models/Billing";
import { getMoneyFormat } from "modules/base/utilities/Actions";

function BaseWalletTopUpForm(props) {
  const {
    state,
    setState,
    handleSubmit,
    isSubmitting,
    error,
    submitButtonColor,
    success,
  } = props;
  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  });
  const { wallet } = props;
  const amountDisplay = getMoneyFormat(state.amount, wallet.currency);
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      <Form
        handleSubmit={handleSubmit}
        button_label="Complete"
        id="wallet-top-up-form"
        isSubmitting={isSubmitting}
        padding="p-0"
        fullLengthButton
        button_color={submitButtonColor}
        submitButtonEnabled={state.amount > 0}
      >
        <div className="row row-cols-1 mb-2">
          <TextInput
            title="Amount"
            name="amount"
            type="number"
            content={state.amount ?? ""}
            controlFunc={handleInputChange}
            required
          />
          <span className="mt-2 fs-6">
            We will charge your card {amountDisplay}
          </span>
        </div>
      </Form>
    </>
  );
}

BaseWalletTopUpForm.defaultProps = {
  submitButtonColor: "primary",
  error: null,
  success: null,
};

BaseWalletTopUpForm.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  success: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
  submitButtonColor: PropTypes.string,
  wallet: PropTypes.instanceOf(BillingWallet).isRequired,
};

export default BaseWalletTopUpForm;
