import logo from "modules/core/assets/olitt-logo-main.svg";
import {
  cmdClear,
  cmdCustomOpenImport,
  cmdCustomOpenPages,
  openAdditionalActions,
} from "modules/core/components/editor/consts";

/**
 * Add extra buttons
 * @param {grapesjs.default.Editor} editor
 * @param {Object} config
 */
export default (editor, config = {}) => {
  const {
    newsletter_mode = false,
    user = {},
    auto_save_enabled = false,
    website = {},
    page = {},
    pages = [],
  } = config;
  const subscription_plan = website.subscription_plan ?? {};
  const pn = editor.Panels;
  let autoSaveEnabled = auto_save_enabled;

  const commandPanel = pn.getPanel("commands");

  const additionalActions = [
    {
      icon: ["fa", "fa-arrows-alt"],
      title: "Full Screen",
      onClick: (e) => {
        const isFullScreen = e.Commands.isActive("fullscreen");
        if (isFullScreen) {
          e.Commands.stop("fullscreen");
        } else {
          e.Commands.run("fullscreen");
        }
      },
      enabled: true,
    },
    {
      icon: ["fa", "fa-trash"],
      title: "Delete",
      onClick: (e) => e.Commands.run(cmdClear),
      enabled: true,
    },
    {
      icon: ["fa", "fa-external-link"],
      title: "Visit",
      onClick: (e) => e.Commands.run("visit-site"),
      enabled: !newsletter_mode,
    },
    {
      icon: ["fa", "fa-newspaper-o"],
      title: "Toggle newsletter",
      onClick: (e) => e.Commands.run("toggle-editor-mode"),
      enabled: user.is_news_letter_enabled,
    },
    {
      icon: ["fa", "fa-cloud-upload"],
      title: "Import Site",
      onClick: (e) => {
        if (e.Commands.isActive(cmdCustomOpenImport)) {
          return e.Commands.stop(cmdCustomOpenImport);
        }
        return e.Commands.run(cmdCustomOpenImport);
      },
      enabled: true,
    },
    {
      icon: ["fa", "fa-object-group"],
      title: "Show Grids",
      onClick: (e) => {
        const status = e.Commands.isActive("sw-visibility");
        if (status) {
          e.Commands.stop("sw-visibility");
        } else {
          e.Commands.run("sw-visibility");
        }
      },
      enabled: true,
    },
    {
      icon: ["fa", "fa-toggle-off"],
      title: {
        toString() {
          return autoSaveEnabled ? "Auto-Save: On" : "Auto-Save: Off";
        },
      },
      onClick: (e, btn) => {
        const status = e.Commands.isActive("toggle-auto-save");
        if (status) {
          autoSaveEnabled = false;
          // eslint-disable-next-line no-param-reassign
          btn.querySelector(".js-title").textContent = "Auto-Save: Off";
          e.Commands.stop("toggle-auto-save");
        } else {
          autoSaveEnabled = true;
          // eslint-disable-next-line no-param-reassign
          btn.querySelector(".js-title").textContent = "Auto-Save: On";
          e.Commands.run("toggle-auto-save");
        }
      },
      enabled: true,
    },
    {
      icon: ["fa", "fa-floppy-o"],
      title: "Save Changes",
      onClick: (e) => {
        e.Commands.run("save-changes");
      },
      enabled: true,
    },
  ];

  commandPanel.get("buttons").add({
    id: openAdditionalActions,
    className: "fa fa-ellipsis-h",
    command: {
      run: (e, sender) => {
        const container = document.createElement("ul");
        container.classList.add("list-group");

        const actions = additionalActions
          .filter((action) => action.enabled)
          .map((action) => {
            const button = document.createElement("button");
            const icon = document.createElement("i");
            const title = document.createElement("div");
            title.classList.add("js-title", "d-flex", "align-items-center");
            title.textContent = action.title;
            icon.classList.add(...action.icon);
            button.classList.add(
              "list-group-item",
              "list-group-item-action",
              "d-flex",
              "align-items-center",
              "gap-2",
            );
            button.setAttribute("type", "button");
            button.appendChild(icon);
            button.appendChild(title);
            button.addEventListener("click", (event) => {
              event.preventDefault();
              action.onClick(editor, button);
              sender.set("active", false);
              e.PopUp.close();
            });
            return button;
          });
        actions.forEach((action) => container.appendChild(action));

        e.PopUp.setContent(container)
          .setMaxWidth("250px")
          .open({
            enabled: {
              size: true,
            },
            selector: ".gjs-pn-btn.fa.fa-ellipsis-h",
          });
        e.on("pop-up:close", () => {
          sender.set("active", false);
        });
      },
      stop(e) {
        e.PopUp.close();
      },
    },
  });

  const homePanel = pn.addPanel({
    id: "home",
  });

  homePanel.get("buttons").add({
    id: "open-dashboard",
    label: `<img src=${logo} height=20 width=20 />`,
    command: (e) => {
      e.Commands.run("go-home");
    },
    attributes: { title: "Home" },
  });

  const getUpgradeDetails = () => {
    if (website.can_subscription_upgrade) {
      return "Upgrade";
    }

    return subscription_plan.name;
  };

  const upgradeTitle = getUpgradeDetails();

  homePanel.get("buttons").add({
    id: "upgrade",
    label: `<span class='ms-1 small'>${upgradeTitle}</span>`,
    command: (e, sender) => {
      if (website.can_subscription_upgrade) {
        e.em.trigger("custom:payment:modal", sender);
      }
    },
    attributes: { title: "Upgrade" },
  });

  if (!website.is_shop) {
    let chevronDown = "";
    if (!newsletter_mode) {
      chevronDown = `<i class="fa fa-chevron-down" aria-hidden="true" style="font-size: 10px;"></i>`;
    }
    const pagesSwitcherLabel = `<span class='d-flex align-items-center gap-2'>
      <div class="d-flex align-items-center gap-1">
        <i class="fa fa-file-o" aria-hidden="true" style="font-size: 14px;"></i>
        <span class="">${page.page_title}</span>
      </div>
      ${chevronDown}
    </span>`;

    homePanel.get("buttons").add({
      id: "pages",
      label: pagesSwitcherLabel,
      command: (e) => {
        const status = e.Commands.isActive(cmdCustomOpenPages);
        if (status) {
          e.Commands.stop(cmdCustomOpenPages);
        } else {
          e.Commands.run(cmdCustomOpenPages, {
            pages,
            page,
          });
        }
      },
      attributes: {
        title: `${page.page_title} | Pages`,
        class: "border rounded px-2 py-1",
        id: "pages-selector",
      },
    });
  }
};
