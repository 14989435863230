import { BillingPlan, BillingSubscription } from "../billing/BillingModels";
import BaseModel from "modules/core/models/BaseModel";

export class Category {
  id: number;

  name: string;

  avatar_url: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.avatar_url = parameters.avatarUrl;
  }
}

export class Domain {
  id: number;

  sub_domain: string;

  domain: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.sub_domain = parameters.subdomain;
    this.domain = parameters.domain;
  }
}

export class Page extends BaseModel {
  id: number;

  is_start_page: boolean;

  page_title: string;

  page_content: string;

  page_json: any;

  slug: string;

  constructor(parameters: Object = {}) {
    super(parameters);
    this.id = parameters.id;
    this.is_start_page = parameters.is_start_page;
    this.page_title = parameters.page_title;
    this.page_content = parameters.page_content;
    this.page_json = parameters.page_json;
    this.slug = parameters.slug;
  }
}

/**
 * Subscription Pricing model
 */
export class SubscriptionPrice {
  currency_prefix: string;

  monthly: number;

  quarterly: number;

  semiannually: number;

  annually: number;

  biennially: number;

  triennially: number;

  constructor(parameters) {
    this.currency_prefix = parameters.currency;
    this.monthly = parameters.monthly;
    this.quarterly = parameters.quarterly;
    this.semiannually = parameters.semiannually;
    this.annually = parameters.annually;
    this.biennially = parameters.biennially;
    this.triennially = parameters.triennially;
  }
}

/**
 *  Feature model
 */

class Feature extends BaseModel {
  id: number;

  name: string;

  slug: string;

  description: string;

  constructor(parameters) {
    super(parameters);
    this.id = parameters.id;
    this.name = parameters.name;
    this.slug = parameters.slug;
    this.description = parameters.description;
  }
}

/**
 * Subscription Plan model
 */
export class SubscriptionPlan {
  id: number;

  name: string;

  billing_cycle: string;

  pricing: SubscriptionPrice;

  features: Feature[];

  constructor(parameters) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.billing_cycle = parameters.billing_cycle;
    this.pricing = new SubscriptionPrice(parameters.pricing);
    this.features = new Feature().fromArray(parameters.features);
  }
}

export class Site extends BaseModel {
  id: number;

  category: string;

  desktop_thumbnail: string;

  thumbnail: string;

  is_published: boolean;

  is_shop: boolean;

  shop_dashboard_url: string;

  olitt_domain: string;

  site_domain: string;

  custom_domain: string;

  name: string;

  title: string;

  description: string;

  favicon: string;

  can_subscription_upgrade: boolean;

  subscription_plan: SubscriptionPlan;

  billing_subscription: BillingSubscription;

  subscription_renewal_date: Date;

  time_created: string;

  time_updated: string;

  unpaid_transactions: any[];

  whatsapp_enabled: Boolean;

  whatsapp_phone_number: String;

  google_analytics_enabled: boolean;

  google_analytics_property_id: string;

  google_analytics_stream_id: string;

  isStudioProject: boolean;

  studioProjectId: string;

  studioUrl: string;

  studioBaseUrl: string;

  pages: Page[];

  status_reason: string;

  constructor(parameters: Object = {}) {
    super(parameters);
    this.id = parameters.id;
    this.category = parameters.category;
    this.desktop_thumbnail = parameters.desktop_thumbnail;
    this.thumbnail = parameters.thumbnail;

    this.is_published = parameters.is_published;
    this.is_shop = parameters.is_shop;
    this.shop_dashboard_url = parameters.shop_dashboard_url;
    this.olitt_domain = parameters.olitt_domain;
    this.custom_domain = parameters.custom_domain;
    this.site_domain = parameters.custom_domain
      ? parameters.custom_domain
      : parameters.olitt_domain;

    this.name = parameters.name;
    this.title = parameters.title;
    this.description = parameters.description;
    this.favicon = parameters.favicon ?? "";
    this.can_subscription_upgrade = parameters.can_subscription_upgrade;
    const subscription_plan =
      (parameters.billing_subscription ?? {}).plan ??
      parameters.subscription_plan ??
      {};
    this.subscription_plan = subscription_plan
      ? new BillingPlan(subscription_plan)
      : null;
    this.billing_subscription = parameters.billing_subscription
      ? new BillingSubscription(parameters.billing_subscription)
      : null;
    this.subscription_renewal_date = new Date(
      parameters.subscription_plan_expiry_date,
    );

    this.time_created = parameters.time_created;
    this.time_updated = parameters.time_updated;
    this.unpaid_transactions = parameters.unpaid_transactions ?? [];
    this.whatsapp_enabled = parameters.whatsapp_enabled;
    this.whatsapp_phone_number = parameters.whatsapp_phone_number;
    this.google_analytics_enabled = parameters.google_analytics_enabled;
    this.google_analytics_property_id = parameters.google_analytics_property_id;
    this.google_analytics_stream_id = parameters.google_analytics_stream_id;
    this.isStudioProject = parameters.is_studio_project ?? false;
    this.studioProjectId = parameters.studio_project_id ?? null;
    this.studioUrl = parameters.studio_url ?? null;
    this.studioBaseUrl = parameters.studio_base_url ?? null;
    const page = new Page();
    this.pages = parameters.pages ? page.fromArray(parameters.pages) : [];
    this.status_reason = parameters.status_reason;
  }
}

export class IntegrationParameter {
  name;

  tooltip;

  type;

  placeholder;

  constructor(parameters) {
    this.name = parameters.name;
    this.tooltip = parameters.tooltip;
    this.type = parameters.form_type;
    this.placeholder = parameters.placeholder;
  }
}

export class Integration {
  id;

  component_id;

  name;

  tooltip;

  template;

  parameters;

  constructor(parameters) {
    this.id = parameters.id;
    this.component_id = parameters.component_id;
    this.name = parameters.name;
    this.tooltip = parameters.tooltip;
    this.template = parameters.template;
    this.parameters = parameters.parameters.map(
      (parameter) => new IntegrationParameter(parameter),
    );
  }
}

export class SiteJavascript {
  id;

  script;

  parameters;

  integration_id;

  constructor(parameters) {
    this.id = parameters.id;
    this.script = parameters.script;
    this.parameters = parameters.parameters;
    this.integration_id = parameters.integration;
  }
}

export class SiteMenu {
  title;

  icon;

  path;

  is_active;

  constructor(title: string, icon, path: string, is_active: boolean) {
    this.title = title;
    this.icon = icon;
    this.path = path;
    this.is_active = is_active;
  }
}

/**
 * Object Storage model
 */
export class ObjectStorage {
  site;

  file;

  description;

  constructor(parameters) {
    this.site = parameters.site;
    this.file = parameters.file;
    this.description = parameters.description;
  }
}

// banners

export class SiteBanner {
  id;

  title;

  view_name;

  device_target;

  interval_days;

  limit;

  delay_milliseconds;

  html_content;

  header_text;

  banner_body;

  button_link;

  button_label;

  button_color;

  footer_text;

  content_color;

  banner_position;

  show_animation;

  close_animation;

  subscription_plan;

  billing_subscription;

  backdrop;

  background_color;

  pop_up_trigger;

  constructor(parameters) {
    this.id = parameters.id;
    this.title = parameters.title;
    this.view_name = parameters.view_name;
    this.device_target = parameters.device_target;
    this.interval_days = parameters.interval_days;
    this.limit = parameters.limit;
    this.delay_milliseconds = parameters.delay_milliseconds;
    this.html_content = parameters.html_content;
    this.header_text = parameters.header_text;
    this.banner_body = parameters.banner_body;
    this.button_link = parameters.button_link;
    this.button_label = parameters.button_label;
    this.button_color = parameters.button_color;
    this.footer_text = parameters.footer_text;
    this.content_color = parameters.content_color;
    this.banner_position = parameters.banner_position;
    this.show_animation = parameters.show_animation;
    this.close_animation = parameters.close_animation;
    this.subscription_plan =
      parameters.subscription_plan !== null
        ? new BillingPlan(parameters.subscription_plan)
        : null;
    this.billing_subscription =
      parameters.billing_subscription !== null
        ? new BillingSubscription(parameters.billing_subscription)
        : null;
    this.backdrop = parameters.backdrop;
    this.background_color = parameters.background_color;
    this.pop_up_trigger = parameters.pop_up_trigger;
  }
}

export class SiteForm {
  id: number;

  form_data: string;

  time_created: string;

  time_updated: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.form_data = parameters.form_data;
    this.time_created = parameters.time_created;
    this.time_updated = parameters.time_updated;
  }
}
