import PropTypes from "prop-types";
import React from "react";
import { Icon } from "../../../base/components/Icons";
import Label from "../Label";

/**
 * @name FormTextInput
 * @param {object} props - Component props
 * @returns {ReactElement}
 */
class FormTextInput extends React.Component {
  render() {
    const {
      label,
      placeholder,
      name,
      value,
      onChangeText,
      is_required,
      is_editable,
      type,
    } = this.props;

    let label_element: string = "";
    if (label) {
      label_element = (
        <Label icon={null} text={label} is_important={is_required} />
      );
    }

    const disabled_class: string = is_editable ? "" : "disabled";

    return (
      <>
        {label_element}
        <div className="input-group">
          <input
            type={type}
            className={`form-control ${disabled_class}`}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChangeText}
            required={is_required}
            disabled={!is_editable}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <Icon icon="input-cursor-text" />
            </span>
          </div>
        </div>
      </>
    );
  }
}

FormTextInput.defaultProps = {
  is_required: false,
  is_editable: true,
  label: null,
  value: "",
  type: "text",
};

FormTextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChangeText: PropTypes.func.isRequired,
  is_required: PropTypes.bool,
  is_editable: PropTypes.bool,
  type: PropTypes.string,
};

export default FormTextInput;
