import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ActionInput from "modules/base/components/ActionInput";
import Form from "modules/base/components/Form";
import SelectInput from "modules/base/components/inputs/SelectInput";
import Modal from "modules/base/components/Modal";
import { closeModalById, openModal } from "modules/base/utilities/Actions";
import ModifyContactSegments from "modules/marketing/components/forms/actions/ModifyContactSegments";
import RemoveContactFromDNC from "modules/marketing/components/forms/actions/RemoveContactFromDNC";
import SendEmailToContact from "modules/marketing/components/forms/actions/SendEmailToContact";
import MarketingAPI from "modules/marketing/MarketingAPI";
import { NEW_FORM_ACTION_PREFIX } from "modules/marketing/MarketingConstants";

function ConfigureFormAction({ formState, setFormState, actionState }) {
  const [state, setState] = useState(actionState);
  const { id: formId = "new" } = formState;
  const { id: actionId = uuidv4() } = state;
  const modalId = `configure-form-action-${formId}-${actionId}-modal`;
  const actionOptions = [
    {
      value: "lead.remove_do_not_contact",
      label: "Remove from Do Not Contact",
    },
    {
      value: "lead.changelist",
      label: "Change contact's segment",
    },
    {
      value: "email.send.lead",
      label: "Send email to contact",
    },
  ];
  const actionsMap = {
    "lead.changelist": ModifyContactSegments,
    "email.send.lead": SendEmailToContact,
    "lead.remove_do_not_contact": RemoveContactFromDNC,
  };
  const onActionTypeChange = useCallback((selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      type: selectedOption.value,
      name: selectedOption.label,
    }));
  });
  const onClickConfigure = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      actions: prevState.actions.map((action) =>
        action.id === actionId ? state : action,
      ),
    }));
    closeModalById(modalId);
  });
  const ActionComponent =
    actionsMap[state.type] ??
    function EmptyNode() {
      return (
        <div className="alert alert-warning" role="alert">
          <i className="bi bi-cone-striped" /> The configuration for that action
          type is not available yet
        </div>
      );
    };
  return (
    <Modal modal_title="Configure Action" modal_id={modalId} size="md">
      <Form
        handleSubmit={onClickConfigure}
        id={`form-action-${formId}`}
        button_color="gray-900"
        button_label="Configure"
        fullLengthButton
        isSubmitting={false}
        use_as="div"
        padding="p-0"
      >
        <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
          <SelectInput
            name="type"
            title="Action type"
            options={actionOptions}
            onChange={onActionTypeChange}
            predicate={(option) => option.value === state.type}
          />
          {state.type && (
            <ActionComponent actionState={state} setActionState={setState} />
          )}
        </div>
      </Form>
    </Modal>
  );
}

ConfigureFormAction.propTypes = {
  formState: PropTypes.instanceOf(Object).isRequired,
  setFormState: PropTypes.func.isRequired,
  actionState: PropTypes.instanceOf(Object).isRequired,
};

function ConfigureFormActions({
  match,
  formState,
  setFormState,
  currentModalId,
}) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const onClickAddAction = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      actions: [
        ...prevState.actions,
        {
          id: `${NEW_FORM_ACTION_PREFIX}-${uuidv4()}`,
          name: "New action",
          type: null,
          properties: {},
        },
      ],
    }));
  });
  const onClickDelete = useCallback((actionId, onSuccess, onError) => {
    setFormState((prevState) => ({
      ...prevState,
      actions: prevState.actions.filter((action) => action.id !== actionId),
    }));
    if (
      typeof actionId === "string" &&
      actionId.includes(NEW_FORM_ACTION_PREFIX)
    ) {
      onSuccess();
      return;
    }
    API.deleteFormActions(
      formState.id,
      [actionId],
      () => {
        onSuccess();
      },
      () => {
        onError();
      },
    );
  });
  const onClickEdit = useCallback((action) => {
    openModal(
      `configure-form-action-${formState.id ?? "new"}-${action.id}-modal`,
      currentModalId,
      true,
    );
  });
  const formActions = formState.actions?.map((action) => (
    <div key={action.id}>
      <ConfigureFormAction
        formState={formState}
        setFormState={setFormState}
        actionState={action}
      />
      <div key={action.id} className="col">
        <div className="card">
          <div className="card-body d-flex align-items-center">
            <div className="h6 flex-fill fw-semibold">{action.name}</div>
            <button
              type="button"
              className="btn btn-outline-primary mx-2"
              onClick={() => onClickEdit(action)}
            >
              <i className="bi bi-pencil-fill" />
            </button>
            <ActionInput
              label=""
              icon="trash-fill"
              subject_id={action.id}
              showConfirm
              confirm={{
                title: "Delete Action",
                message: "Are you sure you want to delete this field?",
                confirm_button_label: "Delete",
                confirm_button_color: "danger",
                action_done_message: "Action deleted",
                action_done_title: "Success",
              }}
              button_classes="btn btn-outline-danger align-self-end mx-2"
              action_type="button"
              onClick={(actionId, onSuccess, onError) =>
                onClickDelete(actionId, onSuccess, onError)
              }
            />
          </div>
        </div>
      </div>
    </div>
  ));
  return (
    <div className="col">
      <div className="card">
        <div className="h5 card-header">Actions</div>
        <div className="card-body row row-cols-1 gy-md-3 gy-1">
          {formActions}
          <div className="col">
            <button
              type="button"
              className="btn btn-outline-gray-900 btn-sm"
              onClick={onClickAddAction}
            >
              <i className="bi bi-plus-square" /> Add action
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfigureFormActions.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  formState: PropTypes.instanceOf(Object).isRequired,
  setFormState: PropTypes.func.isRequired,
  currentModalId: PropTypes.string.isRequired,
};

export default withRouter(ConfigureFormActions);
