import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import { Icon } from "../../../base/components/Icons";
import Label from "../Label";

/**
 * @class FormSelectInput
 * @description A select input component
 */
class FormSelectInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_option_state: {},
    };
  }

  onChangeOption = (target, selected_value) => {
    const { onSelectOption, options, isMulti } = this.props;
    let selected_option_state;
    if (typeof options[0] === "object") {
      if (isMulti) {
        selected_option_state = options.filter((option) =>
          selected_value.includes(option.value),
        );
      } else {
        selected_option_state = options.find(
          (option) => option.value === selected_value,
        );
      }
    } else if (isMulti) {
      selected_option_state = options.filter((option) =>
        selected_value.includes(option),
      );
    } else {
      selected_option_state = options.find(
        (option) => option === selected_value,
      );
    }
    this.setState({ selected_option_state });
    onSelectOption(target);
  };

  /**
   * @function render
   * @description Renders the component
   * @returns {JSX}
   */
  render() {
    const {
      is_editable,
      isMulti,
      is_required,
      options,
      selected_option,
      name,
      placeholder,
      label,
    } = this.props;

    let select_value: object = null;
    const select_options: any = options.map((option) => {
      let select_option;
      if (typeof option === "object") {
        select_option = {
          label: option.label,
          value: option.value,
        };
      } else {
        select_option = {
          label: option,
          value: option,
        };
      }
      if (
        typeof selected_option === "object" &&
        isMulti &&
        selected_option.includes(select_option.value)
      ) {
        select_value = select_value || [];
        select_value = [...select_value, option];
      } else if (
        typeof selected_option === "object" &&
        option === selected_option
      ) {
        select_value = select_option;
      } else if (
        typeof selected_option === "string" &&
        typeof option === "object" &&
        option.value === selected_option
      ) {
        select_value = option;
      } else if (
        typeof selected_option === "string" &&
        typeof option === "string" &&
        option === selected_option
      ) {
        select_value = select_option;
      }
      return select_option;
    });
    const { selected_option_state } = this.state;
    let accompanying_link: object = null;
    if (selected_option_state.accompanying_link) {
      accompanying_link = (
        <a
          href={selected_option_state.accompanying_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {selected_option_state.accompanying_link_text}{" "}
          <Icon icon="box-arrow-up-right" />
        </a>
      );
    }

    return (
      <>
        <Label icon={null} text={label} is_important={is_required} />
        <Select
          name={name}
          placeholder={placeholder}
          isDisabled={!is_editable}
          options={select_options}
          value={select_value}
          isSearchable
          isMulti={isMulti}
          onChange={(selected) => {
            let target: any = { name, value: selected.value };
            let selected_options: any = selected.value;
            if (isMulti) {
              selected_options = selected.map((option) => option.value);
              target = { name, value: selected_options };
            }
            this.onChangeOption({ target }, selected_options);
          }}
        />
        {accompanying_link}
      </>
    );
  }
}

FormSelectInput.defaultProps = {
  label: "",
  placeholder: "",
  name: "",
  selected_option: "",
  options: [],
  onSelectOption: () => {},
  is_required: false,
  is_editable: true,
  isMulti: false,
};

FormSelectInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  selected_option: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  onSelectOption: PropTypes.func,
  is_required: PropTypes.bool,
  is_editable: PropTypes.bool,
  isMulti: PropTypes.bool,
};

export default FormSelectInput;
