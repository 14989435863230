import { cmdCustomOpenImport } from "../consts";

const config = {
  modalImportButton: "Import",
  importTitle: "Import Template",
  importSubtitle: "Paste all your code here below and click import",
  importCnt: "",
};

/**
 * @type {{
 * run: (editor: grapesjs.default.Editor, ...args: any[]) => void;
 * stop: () => void;
 * getCodeViewer: (editor: grapesjs.default.Editor) => grapesjs.default.CodeViewer;
 * getContainer: () => HTMLElement;
 * codeViewer: grapesjs.default.CodeViewer | null;
 * container: HTMLElement | null;
 * editor: grapesjs.default.Editor | null;
 * }}
 */
export const OpenImport = {
  run(editor) {
    this.editor = editor;
    const { importCnt } = config;
    const codeContent =
      typeof importCnt === "function" ? importCnt(editor) : importCnt;
    const codeViewer = this.getCodeViewer();

    codeViewer.setContent(codeContent ?? "");
    codeViewer.refresh();
    setTimeout(() => codeViewer.focus(), 0);
    setTimeout(() => {
      this.editor.PopUp.open({
        content: this.getContainer(),
        maxWidth: "100%",
        placement: "center",
        enabled: {
          size: true,
        },
      });
      this.editor.on("pop-up:close", () => {
        this.stop();
      });
    }, 0);
  },

  stop() {
    this.editor.Commands.stop(cmdCustomOpenImport);
  },

  getContainer() {
    if (!this.container) {
      const pfx = this.editor.getConfig("stylePrefix");
      const { importTitle, importSubtitle } = config;
      const codeViewer = this.getCodeViewer();
      const container = document.createElement("div");
      container.className = `${pfx}import-container`;

      // Import Label
      if (importTitle || importSubtitle) {
        const labelContainerEl = document.createElement("div");
        labelContainerEl.className = "px-3 py-2";
        const titleEl = document.createElement("div");
        titleEl.className = "h5";
        titleEl.innerHTML = importTitle;
        const subTitleEl = document.createElement("div");
        subTitleEl.className = "h6";
        subTitleEl.innerHTML = importSubtitle;
        labelContainerEl.appendChild(titleEl);
        labelContainerEl.appendChild(subTitleEl);
        container.appendChild(labelContainerEl);
      }

      container.appendChild(codeViewer.getElement());

      // Import button
      const footerContainerEl = document.createElement("div");
      footerContainerEl.className = "px-3 py-2";
      const btnImp = document.createElement("button");
      btnImp.type = "button";
      btnImp.innerHTML = config.modalImportButton;
      btnImp.className = `${pfx}btn-prim ${pfx}btn-import`;
      btnImp.onclick = () => {
        this.editor.Css.clear();
        this.editor.setComponents(codeViewer.getContent().trim());
        this.editor.Modal.close();
      };
      footerContainerEl.appendChild(btnImp);
      container.appendChild(footerContainerEl);

      this.container = container;
    }

    return this.container;
  },

  /**
   * Return the code viewer instance
   * @param {grapesjs.default.Editor} editor
   */
  getCodeViewer() {
    if (!this.codeViewer) {
      this.codeViewer = this.editor.CodeManager.createViewer({
        codeName: "htmlmixed",
        theme: "hopscotch",
        readOnly: false,
      });
    }

    return this.codeViewer;
  },
};

export default OpenImport;
