import PropTypes from "prop-types";
import { React, useCallback } from "react";
import SelectInput from "modules/base/components/inputs/SelectInput";
import TextArea from "modules/base/components/inputs/TextArea";
import WYSIWYGEditor from "modules/base/components/inputs/WYSIWYGEditor";

const NOTE_TYPE_OPTIONS = [
  { value: "general", label: "General" },
  { value: "email", label: "Email" },
  { value: "call", label: "Call" },
  { value: "meeting", label: "Meeting" },
];

function NoteFormFields(props) {
  const { state, onInputChange, leadId } = props;

  const handleSelectChange = useCallback(
    (selectedOption) => {
      const value = selectedOption?.value || null;

      onInputChange({ target: { name: "type", value } });
    },
    [onInputChange, state.type],
  );

  const onContentChange = useCallback(
    (text) => {
      onInputChange({ target: { name: "text", value: text } });
    },
    [onInputChange],
  );

  const selectedOption = NOTE_TYPE_OPTIONS.find(
    (option) => option.value === state.type,
  );

  return (
    <div className="mb-3">
      <div>
        <WYSIWYGEditor
          name="text"
          title="Note"
          content={state.text ?? ""}
          controlFunc={onContentChange}
          required
        />
      </div>

      <div className="mb-3">
        <SelectInput
          name="type"
          title="Type"
          options={NOTE_TYPE_OPTIONS}
          onChange={handleSelectChange}
          value={selectedOption}
          placeholder="Select note type..."
          predicate={(option) => option.value === state.type}
        />
      </div>
      <input type="hidden" name="lead" value={leadId} />
    </div>
  );
}

NoteFormFields.defaultProps = {
  state: {},
  onInputChange: () => {},
};

NoteFormFields.propTypes = {
  state: PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string,
  }),
  onInputChange: PropTypes.func,
  leadId: PropTypes.number.isRequired,
};

export default NoteFormFields;
