export function navigateToPage(path, props, data = {}) {
  props.history.push(path, data);
}

export function navigateBack(props) {
  props.history.goBack();
}

export function navigateReloadPage() {
  window.location.reload();
}

export function navigateToUrl(url) {
  window.open(url, "_blank");
}
