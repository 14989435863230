import { PrivateApi } from "../core/utilities/api/PrivateApi";
import { Affiliate } from "../referral/ReferralModels";
import {
  URL_COMPLETE_DOMAIN_ORDER,
  URL_DOMAINS,
  URL_DOMAIN_ORDERS,
  URL_DOMAIN_SUGGESTIONS,
  URL_GET_DOMAIN_NAMESERVERS,
  URL_UPDATE_DOMAIN_NAMESERVERS,
} from "./DomainsConstants";
import { DomainSuggestion, MyDomain } from "./DomainsModels";

export class DomainApi extends PrivateApi {
  retrievePurchasedDomains(
    callback: (
      is_successful: boolean,
      domains_or_error: Affiliate | string,
    ) => void,
  ) {
    this.getAPIRequest(
      URL_DOMAINS,
      (success_response) => {
        if (success_response) {
          const domains: any = success_response.map(
            (parameter) => new MyDomain(parameter),
          );
          return callback(true, domains);
        }
      },
      (error_response) => {
        return callback(false, this.extractResponseError(error_response));
      },
      this.getAuthenticatedHeaders(),
    );
  }

  retrieveDomainSuggestions(
    domain_name: string,
    callback: (
      is_successful: boolean,
      domain_suggestions_or_error: DomainSuggestion[] | string,
    ) => void,
  ) {
    this.postAPIRequest(
      URL_DOMAIN_SUGGESTIONS,
      (response) => {
        if (response) {
          const domain_suggestions: any = response.map(
            (parameters) => new DomainSuggestion(parameters),
          );
          callback(true, domain_suggestions);
        }
      },
      (response) => {
        callback(false, this.extractResponseError(response));
      },
      { domain_name },
      this.getAuthenticatedHeaders(),
    );
  }

  createDomainOrder(
    domain_names: string,
    ns1: string,
    ns2: string,
    ns3: string,
    ns4: string,
    ns5: string,
    callback: (is_successful: boolean, payment_url_or_error: string) => void,
  ) {
    this.postAPIRequest(
      URL_DOMAIN_ORDERS,
      (response) => {
        if (response) {
          const { payment_url } = response;
          callback(true, payment_url);
        }
      },
      (response) => {
        callback(false, this.extractResponseError(response));
      },
      {
        domain_names,
        nameserver1: ns1,
        nameserver2: ns2,
        nameserver3: ns3,
        nameserver4: ns4,
        nameserver5: ns5,
      },
      this.getAuthenticatedHeaders(),
    );
  }

  completeDomainOrder(
    domain_name: string,
    callback: (
      is_successful: boolean,
      is_paid_or_error: boolean | string,
    ) => void,
  ) {
    this.postAPIRequest(
      URL_COMPLETE_DOMAIN_ORDER,
      (response) => {
        if (response) {
          const { is_paid } = response;
          callback(true, is_paid);
        }
      },
      (response) => {
        callback(false, this.extractResponseError(response));
      },
      { domain_name },
      this.getAuthenticatedHeaders(),
    );
  }

  deleteDomainOrder(order_id: string, callback: function) {
    this.deleteAPIRequest(
      `${URL_DOMAIN_ORDERS}${order_id}/`,
      () => callback(true, null),
      (response) => {
        const error: any = this.extractResponseError(response);
        // ToDo: Contact admin
        return callback(false, error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  retrieveDomainNameservers(
    domain_id: number,
    callback: (is_successfully: boolean, ns_or_error: [] | string) => void,
  ) {
    this.postAPIRequest(
      URL_GET_DOMAIN_NAMESERVERS,
      (response) => {
        if (response) {
          callback(true, response);
        }
      },
      (response) => {
        callback(false, this.extractResponseError(response));
      },
      { domain_id },
      this.getAuthenticatedHeaders(),
    );
  }

  updateDomainNameservers(
    domain_id: number,
    nameservers: [],
    callback: (is_successfully: boolean, ns_or_error: [] | string) => void,
  ) {
    this.postAPIRequest(
      URL_UPDATE_DOMAIN_NAMESERVERS,
      (response) => {
        if (response) {
          callback(true, response);
        }
      },
      (response) => {
        callback(false, this.extractResponseError(response));
      },
      {
        domain_id,
        nameservers,
      },
      this.getAuthenticatedHeaders(),
    );
  }
}
