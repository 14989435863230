import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import {
  closeModalById,
  deleteNullProperties,
  handleInputChange,
} from "modules/base/utilities/Actions";
import {
  processFormActions,
  processFormFields,
} from "modules/marketing/Actions";
import FormFields from "modules/marketing/components/forms/FormFields";
import MarketingAPI from "modules/marketing/MarketingAPI";

function CreateForm(props) {
  const { currentModalId, match } = props;
  const { id: siteId } = match.params;
  const initialFormState = {
    fields: [],
    actions: [],
  };
  const [state, setState] = useState(initialFormState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const API = new MarketingAPI(siteId);
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let { fields, actions } = state;
    fields = processFormFields(fields);
    actions = processFormActions(actions);
    state.fields = fields;
    state.actions = actions;
    API.createForm(
      deleteNullProperties(state),
      () => {
        setIsSubmitting(false);
        setError(false);
        setState(initialFormState);
        closeModalById(currentModalId);
        setSuccess({
          message: "Form created successfully",
        });
      },
      (error_response) => {
        setIsSubmitting(false);
        setSuccess(false);
        setError({
          message: error_response.message,
        });
      },
    );
  });
  const onInputChange = useCallback((e) =>
    handleInputChange(e.target.name, e.target.value, state, setState),
  );
  return (
    <Form
      handleSubmit={handleSubmit}
      id="create-form-form"
      button_color="gray-900"
      button_label="Create"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      <FormFields
        state={state}
        setState={setState}
        onInputChange={onInputChange}
        currentModalId={currentModalId}
      />
    </Form>
  );
}

CreateForm.defaultProps = {
  currentModalId: null,
};

CreateForm.propTypes = {
  currentModalId: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(CreateForm);
