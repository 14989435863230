import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import {
  closeModalById,
  deleteNullProperties,
  handleInputChange,
} from "modules/base/utilities/Actions";
import { formatDate } from "modules/marketing/Actions";
import EmailTemplateFormFields from "modules/marketing/components/emailstemplates/EmailTemplateFormFields";
import MarketingAPI from "modules/marketing/MarketingAPI";
import { EmailTemplate } from "modules/marketing/MarketingModels";

import SiteApi from "modules/website/api/SiteApi";

function EditTemplate({ template, currentModalId, match }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [state, setState] = useState(template);
  const [site, setSite] = useState(null);
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const siteApi = new SiteApi();
  useEffect(() => {
    siteApi.retrieveWebsite(
      siteId,
      (siteDetails) => {
        setSite(siteDetails);
      },
      (error) => {
        setError(error);
      },
    );
  }, [siteId]);

  function getFromAddress() {
    let fromAddress = null;
    if (site?.custom_domain && state.fromAddress) {
      const [username] = state.fromAddress.split("@");
      fromAddress = `${username}@${site?.custom_domain}`;
    }
    return fromAddress;
  }

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formattedPublishUp = state.publishUp
      ? formatDate(state.publishUp).format("YYYY-MM-DD HH:mm")
      : null;

    const formattedPublishDown = state.publishDown
      ? formatDate(state.publishDown).format("YYYY-MM-DD HH:mm")
      : null;

    const lists = Array.isArray(state.lists)
      ? state.lists.map((list) =>
          typeof list === "object" && list.id ? list.id : list,
        )
      : [];

    let mauticcustomhtml = state.customHtml;

    const unsubscribeLink =
      'If you no longer wish to receive our emails, click <a href="{unsubscribe_url}">here</a> to unsubscribe';

    const unsubscribeLinkRegex =
      /<a\s+href="\{unsubscribe_url\}"[^>]*>here<\/a>|<p[^>]*>\{unsubscribe_text\}<\/p>/;

    if (mauticcustomhtml && !unsubscribeLinkRegex.test(mauticcustomhtml)) {
      mauticcustomhtml += `<p>${unsubscribeLink}</p>`;
    }
    const updatedState = {
      ...state,
      customHtml: mauticcustomhtml,
      fromAddress: getFromAddress(),
      publishUp: formattedPublishUp,
      publishDown: formattedPublishDown,
      lists,
    };

    API.updateEmailTemplate(
      state.id,
      deleteNullProperties(updatedState),
      (response) => {
        setIsSubmitting(false);
        setError(null);
        setState(response);
        closeModalById(currentModalId);
        setSuccess({
          message: "Template updated successfully",
        });
      },
      (error_response) => {
        setIsSubmitting(false);
        setError({
          message: error_response.message,
        });
        setSuccess(null);
      },
    );
  });
  const onInputChange = useCallback((e) =>
    handleInputChange(e.target.name, e.target.value, state, setState),
  );

  const onWYSIWYGEditorChange = useCallback(
    (value) => {
      setState((prevState) => ({
        ...prevState,
        customHtml: value,
      }));
    },
    [setState],
  );

  return (
    <Form
      handleSubmit={handleSubmit}
      id={`edit-template-${template.id}-form`}
      button_color="gray-900"
      button_label="Update"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="div"
      padding="p-0"
    >
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      {site && (
        <EmailTemplateFormFields
          state={state}
          setState={setState}
          site={site}
          onInputChange={onInputChange}
          currentModalId={currentModalId}
          onWYSIWYGEditorChange={onWYSIWYGEditorChange}
        />
      )}
    </Form>
  );
}

EditTemplate.propTypes = {
  template: PropTypes.instanceOf(EmailTemplate).isRequired,
  currentModalId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(EditTemplate);
