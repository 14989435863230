import PropTypes from "prop-types";
import { useCallback } from "react";
import Form from "modules/base/components/Form";

function ConfigureNodeForm({
  children,
  campaignEvent,
  campaignEventState,
  handleSubmit,
  updateEvent,
}) {
  const onConfigureNode = useCallback(() => {
    updateEvent(campaignEventState);
    handleSubmit();
  });

  return (
    <Form
      handleSubmit={onConfigureNode}
      id={`configure-node-${campaignEvent.id}-form`}
      button_color="gray-900"
      button_label="Configure"
      fullLengthButton
      isSubmitting={false}
      use_as="div"
      padding="p-0"
    >
      {children}
    </Form>
  );
}

ConfigureNodeForm.defaultProps = {
  children: null,
};

ConfigureNodeForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  campaignEvent: PropTypes.instanceOf(Object).isRequired,
  campaignEventState: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
};

export default ConfigureNodeForm;
