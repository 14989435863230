import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect } from "react";
import { closeModalById } from "modules/base/utilities/Actions";
import { BillingAPI } from "modules/billing/BillingAPIs";
import PlanSelect from "modules/billing/components/PlanSelect";

const PlanUpgrade = memo(function PlanUpgrade(props) {
  const { website, handleSelectedPlan, submitFormButtonLabel, parentModalId } =
    props;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [selectedPlanId, setSelectedPlanId] = React.useState(null);
  const [selectedCycle, setSelectedCycle] = React.useState(null);

  useEffect(() => {
    if (website) {
      setSelectedPlanId(website.subscription_plan?.id);
      setSelectedCycle(website.billing_subscription?.billing_interval);
    }
  }, [website]);

  const changePlan = useCallback((plan, cycleName) => {
    const planId = plan.id;
    if (website) {
      const billing_api = new BillingAPI();
      setIsSubmitting(true);
      billing_api.changePlan(
        website.id,
        planId,
        cycleName,
        () => {
          setSuccess({
            message: "Plan changed successfully",
          });
          setIsSubmitting(false);
          setError(null);
          closeModalById(parentModalId);
        },
        (response) => {
          setError({
            message: response.message,
          });
          setIsSubmitting(false);
          setSuccess(null);
        },
      );
    } else {
      handleSelectedPlan(planId, cycleName);
    }
  });

  return (
    <PlanSelect
      selectedPlanId={selectedPlanId}
      handleSubmit={changePlan}
      isSubmitting={isSubmitting}
      success={success}
      error={error}
      formId={website ? `plan-upgrade-${website.id}` : "plan-select-form"}
      selectedCycle={selectedCycle}
      submitFormButtonLabel={submitFormButtonLabel || "Complete Upgrade"}
      parentModalId={parentModalId}
    />
  );
});

PlanUpgrade.defaultProps = {
  website: null,
  handleSelectedPlan: null,
  submitFormButtonLabel: null,
};

PlanUpgrade.propTypes = {
  website: PropTypes.instanceOf(Object),
  handleSelectedPlan: PropTypes.func,
  submitFormButtonLabel: PropTypes.string,
  parentModalId: PropTypes.string.isRequired,
};

export default PlanUpgrade;
