import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/**
 * A generic date time input component
 * @returns {JSX.Element}
 * @constructor
 */
function DateTimeInput({
  title,
  name,
  controlFunc,
  content,
  required,
  isDisabled,
  wrapperClasses,
  groupClass,
  showTimeSelect,
  showTimeSelectOnly,
  dateFormat,
  timeIntervals,
  timeFormat,
  minTime,
  maxTime,
  minDate,
  maxDate,
}) {
  const handleChange = useCallback(
    (selectedDateTime) => {
      controlFunc({
        target: {
          name,
          value: selectedDateTime,
        },
      });
    },
    [controlFunc],
  );
  const requiredAsterisk = required ? "*" : "";
  let selectedDate = content;
  if (typeof content === "string" && !_.isEmpty(content)) {
    selectedDate = moment(content).toDate();
  }
  return (
    <div className={`${groupClass} ${wrapperClasses}`}>
      <div>
        <label htmlFor={name}>
          {title} {requiredAsterisk}
        </label>
      </div>
      <DatePicker
        selected={selectedDate}
        onChange={handleChange}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        timeCaption="Time"
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        className="form-control"
        wrapperClassName="form-control"
        disabled={isDisabled}
        minTime={minTime}
        maxTime={maxTime}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
}

DateTimeInput.defaultProps = {
  content: "",
  required: false,
  isDisabled: false,
  wrapperClasses: "",
  groupClass: "form-group",
  showTimeSelect: false,
  showTimeSelectOnly: false,
  dateFormat: "yyyy-MM-dd",
  timeFormat: "HH:mm",
  timeIntervals: 60,
  minTime: null,
  maxTime: null,
  minDate: null,
  maxDate: null,
};

DateTimeInput.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  wrapperClasses: PropTypes.string,
  groupClass: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  timeIntervals: PropTypes.number,
  minTime: PropTypes.instanceOf(Date),
  maxTime: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default DateTimeInput;
