import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import Sidebar from "modules/core/components/Sidebar";
import { Menu } from "modules/core/models/Menu";
import {
  MARKETING_ASSETS_SLUG,
  MARKETING_CAMPAIGNS_SLUG,
  MARKETING_CONTACTS_SLUG,
  MARKETING_CONTENT_SLUG,
  MARKETING_EMAIL_TEMPLATES_SLUG,
  MARKETING_FOCUS_ITEMS_SLUG,
  MARKETING_FORMS_SLUG,
  MARKETING_STAGES_SLUG,
  PATH_MARKETING_ASSETS,
  PATH_MARKETING_CAMPAIGNS,
  PATH_MARKETING_CONTACTS,
  PATH_MARKETING_CONTENT,
  PATH_MARKETING_DASHBOARD,
  PATH_MARKETING_EMAIL_TEMPLATES,
  PATH_MARKETING_FOCUS_ITEMS,
  PATH_MARKETING_FORMS,
  PATH_MARKETING_SEGMENTS,
  PATH_MARKETING_STAGES,
} from "modules/marketing/MarketingConstants";
import { generateRoute, isActiveRoute } from "modules/shop/ShopActions";
import { PATH_WEBSITES } from "modules/website/WebsiteConstants";
import SiteApi from "modules/website/api/SiteApi";

function SidebarMarketing(props) {
  const { location, history, match, sidebar_width } = props;
  const { params } = match;
  const siteId = params.id;
  const siteApi = new SiteApi();
  const { site, isLoading, error } = siteApi.getWebsite(siteId);
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  const generateMarketingRoute = (path) => generateRoute(path, site.id);

  const dashboard_path = generateMarketingRoute(PATH_MARKETING_DASHBOARD);
  const contacts_path: string = generateMarketingRoute(PATH_MARKETING_CONTACTS);
  const forms_path: string = generateMarketingRoute(PATH_MARKETING_FORMS);
  const focus_items_path: string = generateMarketingRoute(
    PATH_MARKETING_FOCUS_ITEMS,
  );
  const segments_path: string = generateMarketingRoute(PATH_MARKETING_SEGMENTS);
  const email_templates_path: string = generateMarketingRoute(
    PATH_MARKETING_EMAIL_TEMPLATES,
  );
  const campaigns_path: string = generateMarketingRoute(
    PATH_MARKETING_CAMPAIGNS,
  );
  const content_path: string = generateMarketingRoute(PATH_MARKETING_CONTENT);
  const stages_path: string = generateMarketingRoute(PATH_MARKETING_STAGES);
  const assets_path: string = generateMarketingRoute(PATH_MARKETING_ASSETS);
  const current_path: any = location.pathname;

  const sidebar_menus: any = [
    new Menu(
      "Dashboard",
      "bi bi-microsoft",
      dashboard_path,
      isActiveRoute(dashboard_path, current_path),
      "",
      "",
      false,
      "",
    ),
    new Menu(
      "Contacts",
      "person-lines-fill",
      contacts_path,
      isActiveRoute(contacts_path, current_path),
      "",
      "",
      false,
      MARKETING_CONTACTS_SLUG,
    ),
    new Menu(
      "Segments",
      "pie-chart-fill",
      segments_path,
      isActiveRoute(segments_path, current_path),
      "",
      "",
      false,
      MARKETING_CONTACTS_SLUG,
    ),
    new Menu(
      "Stages",
      "bi bi-speedometer2",
      stages_path,
      isActiveRoute(stages_path, current_path),
      "",
      "",
      false,
      MARKETING_STAGES_SLUG,
    ),
    new Menu(
      "Forms",
      "file-earmark-text-fill",
      forms_path,
      isActiveRoute(forms_path, current_path),
      "",
      "",
      false,
      MARKETING_FORMS_SLUG,
    ),
    new Menu(
      "Pop Ups",
      "magnet-fill",
      focus_items_path,
      isActiveRoute(focus_items_path, current_path),
      "",
      "",
      false,
      MARKETING_FOCUS_ITEMS_SLUG,
    ),
    new Menu(
      "Email Messages",
      "envelope-fill",
      email_templates_path,
      isActiveRoute(email_templates_path, current_path),
      "",
      "",
      false,
      MARKETING_EMAIL_TEMPLATES_SLUG,
    ),
    new Menu(
      "Drip Campaigns",
      "megaphone-fill",
      campaigns_path,
      isActiveRoute(campaigns_path, current_path),
      "",
      "",
      false,
      MARKETING_CAMPAIGNS_SLUG,
    ),
    new Menu(
      "AI Generated Messages",
      "body-text",
      content_path,
      isActiveRoute(content_path, current_path),
      "",
      "",
      false,
      MARKETING_CONTENT_SLUG,
    ),
    new Menu(
      "Upload Files",
      "bi bi-folder2",
      assets_path,
      isActiveRoute(assets_path, current_path),
      "",
      "",
      false,
      MARKETING_ASSETS_SLUG,
    ),
  ];
  const bottom_action: any = new Menu(
    "All Websites",
    "chevron-double-left",
    PATH_WEBSITES,
    false,
  );
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <Sidebar
        sidebar_banner={site.desktop_thumbnail}
        sidebar_banner_width={100}
        actions={sidebar_menus}
        bottom_action={bottom_action}
        location={location}
        history={history}
        match={match}
        sidebar_width={sidebar_width}
        site={site}
      />
    </>
  );
}

SidebarMarketing.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  sidebar_width: PropTypes.string.isRequired,
};

export default withRouter(SidebarMarketing);
