import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import { LoginSocialGoogle } from "reactjs-social-login";
import { GOOGLE_CLIENT_ID } from "modules/account/AccountConstants";
import googleIcon from "modules/account/assets/google_logo.png";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import { openModal } from "modules/base/utilities/Actions";
import { APP_ORIGIN } from "modules/core/constants/Constants";
import PropertiesModal from "modules/marketing/components/dashboard/googleanalytics/PropertiesModal";
import MarketingAPI from "modules/marketing/MarketingAPI";

const GOOGLE_SCOPES = [
  "https://www.googleapis.com/auth/analytics.edit",
  "https://www.googleapis.com/auth/analytics",
].join(" ");

function GoogleAnalyticsButton({ match, googleAnalyticsEnabled, showCharts }) {
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);

  const fetchAndDisplayAnalyticsProperties = useCallback(() => {
    API.getGoogleAnalyticsProperties((is_successful, google_properties) => {
      setIsLoading(false);
      if (is_successful) {
        setProperties(google_properties);
        openModal("select-property-modal");
      } else {
        setError("Something went wrong. Please try again later.");
      }
    });
  }, [API]);

  const handleGoogleAuth = useCallback(
    (response) => {
      const { code, authuser, prompt, scope } = response.data ?? {};
      if (!code) {
        setIsLoading(false);
        setError("Something went wrong. Please try again later.");
        return;
      }

      const updatedScope = scope
        .split(" ")
        .filter((s) => !["email", "profile"].includes(s))
        .join(" ");

      const payload = {
        code,
        authuser,
        prompt,
        scopes: updatedScope,
        redirect_uri: APP_ORIGIN,
        client_type: "web",
      };
      API.sendAnalyticsAuthDetails(
        payload,
        () => {
          fetchAndDisplayAnalyticsProperties();
        },
        (error_response) => {
          setError(error_response?.message || "Something went wrong");
        },
      );
    },
    [API, fetchAndDisplayAnalyticsProperties],
  );

  const onLoginStart = useCallback(() => setIsLoading(true), []);
  const onReject = useCallback(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  return (
    <>
      {error && <NetworkMessageDisplay error={error} />}
      <PropertiesModal properties={properties} showCharts={showCharts} />
      <LoginSocialGoogle
        client_id={GOOGLE_CLIENT_ID}
        onLoginStart={onLoginStart}
        redirect_uri={APP_ORIGIN}
        scope={GOOGLE_SCOPES}
        discoveryDocs="claims_supported"
        access_type="offline"
        typeResponse="code"
        onResolve={handleGoogleAuth}
        onReject={onReject}
      >
        <button
          type="button"
          className="btn btn-outline-gray-900 mb-2 mb-md-0 me-0 me-md-2"
        >
          <img height="20px" src={googleIcon} alt="" className="me-2" />
          {googleAnalyticsEnabled ? "Switch Account" : "Connect Google"}
        </button>
      </LoginSocialGoogle>
    </>
  );
}

GoogleAnalyticsButton.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  googleAnalyticsEnabled: PropTypes.bool.isRequired,
  showCharts: PropTypes.func.isRequired,
};

export default withRouter(GoogleAnalyticsButton);
