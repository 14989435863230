import { createColumnHelper } from "@tanstack/react-table";
import _ from "lodash";
import PropTypes from "prop-types";
import CopyToClipboardButton from "modules/base/components/CopyToClipboardButton";
import Tooltip from "modules/base/components/Tooltip";
import { formatDate } from "modules/base/utilities/Actions";
import { formatBoolean } from "modules/base/utilities/RenderActions";
import { locationFormatter } from "modules/marketing/Actions";

const columnHelper = createColumnHelper();

function nameFormatter(data) {
  return `${data.firstname ?? ""} ${data.lastname ?? ""}`;
}

const parseStage = (stage) => {
  const jsonString = stage.replace(/'/g, '"').replace(/None/g, "null");
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return { error: error?.message };
  }
};

function formatStage(stage, maxLength = 15) {
  if (stage) {
    const dataObject = parseStage(stage);
    if (dataObject.error) return dataObject.error;
    let name = dataObject?.name ?? "";

    if (name.length > maxLength) {
      name = `${name.substring(0, maxLength)}...`;
    }
    return name;
  }
  return "";
}

function shortenText(text, maxLength = 25) {
  if (!text) return "";
  if (text.length <= maxLength) return text;
  return <span title={text}>{`${text.substring(0, maxLength)}...`}</span>;
}

const StageBadge = ({ stage }) => {
  return (
    <span
      className="badge rounded-pill bg-secondary mb-1 me-1 m-md-1"
      title={formatStage(stage, Infinity)}
    >
      {formatStage(stage)}
    </span>
  );
};
StageBadge.defaultProps = {
  stage: "",
};
StageBadge.propTypes = {
  stage: PropTypes.string,
};

// eslint-disable-next-line import/prefer-default-export
export const contactsColumns: Array = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: (info) => nameFormatter(info.row.original),
  }),
  columnHelper.accessor("email", {
    header: "Email",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("mobile", {
    header: "Mobile",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("company", {
    header: "Company",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("country", {
    header: "Location",
    cell: (info) => locationFormatter(info.row.original),
  }),
  columnHelper.accessor("stage", {
    header: "Stage",
    cell: (info) => <StageBadge stage={info.getValue()} />,
  }),
];

export const formsColumns: Array = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("isPublished", {
    header: "Published",
    cell: (info) => formatBoolean(info.getValue()),
  }),
  columnHelper.accessor("dateAdded", {
    header: "Date added",
    cell: (info) => formatDate(info.renderValue(), "llll"),
  }),
  columnHelper.accessor("dateModified", {
    header: "Date modified",
    cell: (info) => formatDate(info.renderValue(), "llll"),
  }),
];

export const focusItemsColumns: Array = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("isPublished", {
    header: "Published",
    cell: (info) => formatBoolean(info.getValue()),
  }),
  columnHelper.accessor("dateAdded", {
    header: "Date added",
    cell: (info) => formatDate(info.renderValue(), "llll"),
  }),
  columnHelper.accessor("dateModified", {
    header: "Date modified",
    cell: (info) => formatDate(info.renderValue(), "llll"),
  }),
];

export const formSubmissionsColumns: Array = [
  columnHelper.accessor("dateSubmitted", {
    header: "Date submitted",
    cell: (info) => formatDate(info.renderValue(), "llll"),
  }),
];

export const segmentsColumns: Array = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("isPublished", {
    header: "Published",
    cell: (info) => formatBoolean(info.getValue()),
  }),
];

const emailCountFormatter = (data) => {
  const percentageRead = Math.round(
    data.sentCount ? (data.readCount / data.sentCount) * 100 : 0,
    2,
  );
  return (
    <>
      <span className="badge text-bg-warning me-1">{data.sentCount} sent</span>
      <span className="badge text-bg-primary me-1">{data.readCount} read</span>
      <span className="badge text-bg-info">{percentageRead}% read</span>
    </>
  );
};

export const emailTemplatesColumns: Array = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("emailType", {
    header: "Type",
    cell: (info) => {
      const emailType = info.getValue();
      return emailType === "template" ? "Template" : "Segment";
    },
  }),
  columnHelper.accessor("stats", {
    header: "Stats",
    cell: (info) => emailCountFormatter(info.row.original),
  }),
  columnHelper.accessor("isPublished", {
    header: "Published",
    cell: (info) => formatBoolean(info.getValue()),
  }),
  columnHelper.accessor("dateAdded", {
    header: "Date Added",
    cell: (info) => formatDate(info.renderValue(), "llll"),
  }),
  columnHelper.accessor("dateModified", {
    header: "Date Modified",
    cell: (info) => formatDate(info.renderValue(), "llll"),
  }),
];

export const campaignsColumns: Array = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("isPublished", {
    header: "Published",
    cell: (info) => formatBoolean(info.getValue()),
  }),
];

export const ContentsColumns: Array = [
  columnHelper.accessor("subject", {
    header: "Subject",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("channel.name", {
    header: "Channel",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("auto_generate_content", {
    header: "Autogenerated content",
    cell: (info) => formatBoolean(info.getValue()),
  }),
  columnHelper.accessor("created_at", {
    header: "Date Created",
    cell: (info) => formatDate(info.renderValue(), "llll"),
  }),
  columnHelper.accessor("updated_at", {
    header: "Date Modified",
    cell: (info) => formatDate(info.renderValue(), "llll"),
  }),
];

export const notesColumns: Array = [
  columnHelper.accessor("type", {
    header: "Type",
    cell: (info) => info.getValue(),
  }),
];

export const stagesColumns = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: (info) => shortenText(info.getValue()),
  }),
  columnHelper.accessor("description", {
    header: "Description",
    cell: (info) => shortenText(info.getValue()) || "",
  }),
  columnHelper.accessor("weight", {
    header: "Weight",
    cell: (info) => info.getValue() || 0,
  }),
  columnHelper.accessor("isPublished", {
    header: "Published",
    cell: (info) => formatBoolean(info.getValue()),
  }),
];
const truncateText = (text, maxWords) => {
  const words = text.split(" ");
  return words
    .slice(0, maxWords)
    .join(" ")
    .concat(words.length > maxWords ? "..." : "");
};

export const AssetsColumns = [
  columnHelper.accessor("title", {
    header: "Title",
    cell: (info) => info.getValue(),
  }),

  columnHelper.accessor("description", {
    header: "Description",
    cell: (info) => {
      const description = info.getValue();
      if (!description) return "";
      const truncatedDescription = _.truncate(description, {
        length: 50,
        separator: " ",
        omission: "...",
      });
      return <span title={description}>{truncatedDescription}</span>;
    },
  }),

  columnHelper.accessor("downloadUrl", {
    header: "File Link",
    cell: (info) => {
      const downloadUrl = info.getValue() || "";
      const truncatedUrl = _.truncate(downloadUrl, {
        length: 13,
        separator: " ",
        omission: "...",
      });

      return (
        <div>
          <a href={downloadUrl} target="_blank" rel="noreferrer">
            {truncatedUrl}
          </a>
          <CopyToClipboardButton text={downloadUrl} noun="URL" />
        </div>
      );
    },
  }),
  columnHelper.accessor("dateAdded", {
    header: "Date added",
    cell: (info) => formatDate(info.renderValue(), "llll"),
  }),
  columnHelper.accessor("isPublished", {
    header: "Published",
    cell: (info) => formatBoolean(info.getValue()),
  }),
];
