import PropTypes from "prop-types";
import { useCallback } from "react";
import { withRouter } from "react-router-dom";
import SelectInput from "modules/base/components/inputs/SelectInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import MarketingAPI from "modules/marketing/MarketingAPI";

function ChangeSegmentsFields({ match, state, setState }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const { segments, error: segmentLoadingError, isLoading } = API.getSegments();
  const onSegmentChange = useCallback((selectedOptions, name) => {
    const selectedSegments = selectedOptions.map((option) => option.value);
    setState({ ...state, [name]: selectedSegments });
  });
  const onSegmentsToAddChange = useCallback((selectedOptions) => {
    onSegmentChange(selectedOptions, "segmentsToAdd");
  });
  const onSegmentsToRemoveChange = useCallback((selectedOptions) => {
    onSegmentChange(selectedOptions, "segmentsToRemove");
  });
  const createOptions = (selectedSegments) =>
    segments
      .filter((segment) => !selectedSegments.includes(segment.id))
      .map((segment) => ({
        label: segment.name,
        value: segment.id,
      }));
  const segmentToAddOptions = createOptions(state.segmentsToRemove);
  const segmentToRemoveOptions = createOptions(state.segmentsToAdd);
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  return (
    <>
      <NetworkMessageDisplay error={segmentLoadingError} />
      <SelectInput
        name="segmentsToAdd"
        title="Add to segments"
        options={segmentToAddOptions}
        isMulti
        onChange={onSegmentsToAddChange}
        predicate={(option) => state.segmentsToAdd.includes(option.value)}
      />
      <SelectInput
        name="segmentsToRemove"
        title="Remove from segments"
        options={segmentToRemoveOptions}
        isMulti
        onChange={onSegmentsToRemoveChange}
        predicate={(option) => state.segmentsToRemove.includes(option.value)}
      />
    </>
  );
}

ChangeSegmentsFields.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  state: PropTypes.shape({
    segmentsToAdd: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
    segmentsToRemove: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  }).isRequired,
  setState: PropTypes.func.isRequired,
};

export default withRouter(ChangeSegmentsFields);
