/**
 * Phishing domain check model
 */
export default class PhishingCheck {
  domain: string;

  check_uuid: string;

  id: number;

  constructor(parameters) {
    this.domain = parameters.domain;
    this.check_uuid = parameters.check_uuid;
    this.id = parameters.id;
  }
}
