import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { ErrorModal } from "../../../core/components/ErrorModal";
import Form from "../../../core/components/Form";
import FormCardWrapper from "../../../core/components/FormCardWrapper";
import SidebarWrapper from "../../../core/components/SidebarWrapper";
import { PaymentMethod } from "../../models/PaymentMethod";
import { togglePaymentProvider } from "../../ShopActions";
import ShopApi from "../../ShopAPIs";
import SidebarShop from "../SidebarShop";
import PaymentMethodsInputs from "./PaymentMethodsInputs";

/**
 * Payment methods create component
 * @param {Object} props
 * @returns {React.Component}
 */
function PaymentMethodsCreate(props) {
  const shopApi: any = new ShopApi();
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(null);
  const [success_message, setSuccessMessage] = React.useState(null);
  const [is_submitting, setIsSubmitting] = React.useState(false);
  const [configInputs, setConfigInputs] = React.useState([]);
  const [payment_method, setPaymentMethod] = React.useState({});
  const { match } = props;
  const { id: site_id, payment_method_id } = match.params;

  /**
   * @name handleToggleProvider
   * @description Toggle payment provider
   * @param selected_provider
   */
  const handleToggleProvider: any = (selected_provider) => {
    togglePaymentProvider(selected_provider, setConfigInputs, {});
  };
  let inputs: any = PaymentMethodsInputs(payment_method, handleToggleProvider);
  inputs = [...inputs, ...configInputs];

  /**
   * @name handleSubmit
   * @description Submit form
   * @param {Object} values
   */
  const handleSubmit: any = (values) => {
    const payload = inputs.reduce((acc, input) => {
      acc[input.name] = values[input.name] ?? input.value;
      return acc;
    }, {});
    const preferences: {} = {};
    configInputs.forEach((input) => {
      preferences[input.name] = values[input.name];
      delete payload[input.name];
    });
    payload.preferences = preferences;
    setIsSubmitting(true);
    if (payment_method_id) {
      shopApi.updatePaymentMethod(
        site_id,
        payment_method_id,
        payload,
        () => {
          setSuccessMessage("Payment method updated successfully");
          setIsSubmitting(false);
          setError(null);
        },
        (error_message) => {
          setError(error_message);
          setIsSubmitting(false);
        },
      );
    } else {
      shopApi.createPaymentMethod(
        site_id,
        payload,
        () => {
          setSuccessMessage("Payment method created successfully");
          setIsSubmitting(false);
          setError(null);
        },
        (error_message) => {
          setError(error_message);
          setIsSubmitting(false);
        },
      );
    }
  };

  /**
   * @name getPaymentMethod
   * @description Get payment method
   * @returns {null|void}
   */
  const getPaymentMethod: any = () => {
    setLoading(true);
    if (payment_method_id) {
      return shopApi.getPaymentMethod(
        site_id,
        payment_method_id,
        (payment_method_instance: PaymentMethod) => {
          togglePaymentProvider(
            payment_method_instance.provider,
            setConfigInputs,
            payment_method_instance.preferences,
          );
          setPaymentMethod(payment_method_instance);
          setLoading(false);
        },
        (error_message) => {
          setLoadingError(error_message);
          setLoading(false);
        },
      );
    }
    return null;
  };

  React.useEffect(() => {
    if (payment_method_id) {
      getPaymentMethod();
    }
  }, []);
  let submit_text: string = "Create";
  if (payment_method_id) {
    submit_text = "Update";
  }

  return (
    <SidebarWrapper sidebar={<SidebarShop />}>
      <ErrorModal
        show={loadingError !== null}
        onClose={() => setLoadingError(null)}
        short_desc={loadingError ? loadingError.short_desc : ""}
        suggestion={loadingError ? loadingError.suggestion : ""}
      />
      <FormCardWrapper title="Create payment method">
        <Form
          onSubmit={(values) => handleSubmit(values)}
          is_submitting={is_submitting}
          isLoading={loading}
          error={error}
          success_message={success_message}
          submit_text={submit_text}
          inputs={inputs}
          no_of_columns={2}
        />
      </FormCardWrapper>
    </SidebarWrapper>
  );
}

PaymentMethodsCreate.defaultProps = {};

PaymentMethodsCreate.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(PaymentMethodsCreate);
