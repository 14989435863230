import { useFloating, useHover, useInteractions } from "@floating-ui/react";
import { sanitize } from "dompurify";
import _ from "lodash";
import { useCallback, useState } from "react";

function ContentCopyButtonColumn() {
  const onCopy = useCallback((setCopied) => {
    setCopied(true);
    _.delay(() => setCopied(false), 5000);
  }, []);

  const cell = ({ row }) => {
    const [copied, setCopied] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles, context } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
    });
    const hover = useHover(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);
    const onClick = useCallback((content) => {
      navigator.clipboard.writeText(content).then(() => onCopy(setCopied));
    }, []);
    const buttonClass = copied ? "btn-success" : "btn-outline-primary";
    const iconClass = copied ? "bi-clipboard-check" : "bi-clipboard";
    const { original } = row;

    return (
      <>
        <button
          type="button"
          className={`btn btn-sm ${buttonClass}`}
          onClick={() => onClick(original.content)}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <i className={`bi ${iconClass}`} /> {copied ? "Copied" : "Copy"}
        </button>
        {isOpen && (
          <div
            className="card"
            style={{ width: "18rem", ...floatingStyles }}
            ref={refs.setFloating}
            {...getFloatingProps()}
          >
            <div
              className="card-body text-wrap"
              dangerouslySetInnerHTML={{ __html: sanitize(original.content) }}
            />
          </div>
        )}
      </>
    );
  };

  return {
    header: "Share",
    accessorKey: "share",
    cell,
  };
}

export default ContentCopyButtonColumn;
