import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Modal from "modules/base/components/Modal";
import EditNoteForm from "modules/marketing/components/notes/EditNoteForm";
import { Note } from "modules/marketing/MarketingModels";

function EditNoteModal({ row, match, contactId }) {
  const { id: siteId } = match.params;
  const modalId = `edit-note-modal-${row.id}`;

  return (
    <Modal modal_title="Edit Note" modal_id={modalId} size="lg">
      <EditNoteForm
        note={row}
        contactId={contactId}
        currentModalId={modalId}
        siteId={siteId}
      />
    </Modal>
  );
}

EditNoteModal.propTypes = {
  row: PropTypes.instanceOf(Note).isRequired,
  contactId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(EditNoteModal);
