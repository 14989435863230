import PropTypes from "prop-types";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import TextArea from "modules/base/components/inputs/TextArea";
import TextInput from "modules/base/components/inputs/TextInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SegmentFilters from "modules/marketing/components/segments/SegmentFilters";
import MarketingAPI from "modules/marketing/MarketingAPI";
import { Field } from "modules/marketing/MarketingModels";

function SegmentFormFields({
  state,
  onInputChange,
  match,
  setState,
  currentModalId,
}) {
  const { id: siteId } = match.params;
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      site: siteId,
    }));
  }, [siteId]);
  const API = new MarketingAPI(siteId);
  const params = {
    start: 0,
    limit: 1000,
  };
  const { fields: contactFields, isLoading, error } = API.getFields(params);
  contactFields.unshift(
    new Field({ alias: "device_os", label: "Device OS", type: "device_os" }),
  );
  contactFields.unshift(
    new Field({
      alias: "device_type",
      label: "Device Type",
      type: "device_type",
    }),
  );
  contactFields.unshift(
    new Field({
      alias: "device_brand",
      label: "Device Brand",
      type: "device_brand",
    }),
  );
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <TextInput
          name="name"
          title="Name"
          content={state.name ?? ""}
          controlFunc={onInputChange}
          required
        />
        <TextArea
          name="description"
          title="Description"
          content={state.description ?? ""}
          controlFunc={onInputChange}
        />
        <SegmentFilters
          state={state}
          filters={state.filters}
          setState={setState}
          currentModalId={currentModalId}
          contactFields={contactFields}
        />
      </div>
    </>
  );
}

SegmentFormFields.defaultProps = {
  state: {},
  onInputChange: () => {
    // Do nothing
  },
};

SegmentFormFields.propTypes = {
  state: PropTypes.instanceOf(Object),
  setState: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string.isRequired,
};

export default withRouter(SegmentFormFields);
