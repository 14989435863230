import { jira_log } from "../svgIcons";

export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const { jiraSupportLabel, jiraSupportCategory, jiraSupportBlock } = opts;

  jiraSupportBlock &&
    bm.add("JIRA-SUPPORT-BLOCK", {
      label: jiraSupportLabel,
      activate: true,
      select: true,
      category: jiraSupportCategory,
      content: { type: "JIRA-SUPPORT-COMPONENT" },
      media: jira_log,
    });
};
