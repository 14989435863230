export const operators = [
  { value: "=", label: "Equals" },
  { value: "!=", label: "Not Equals" },
  { value: "empty", label: "Is Empty" },
  { value: "!empty", label: "Is Not Empty" },
  { value: "like", label: "Is Like" },
  { value: "!like", label: "Is Not Like" },
  { value: "in", label: "In" },
  { value: "!in", label: "Not In" },
  { value: "startsWith", label: "Starts With" },
  { value: "endsWith", label: "Ends With" },
  { value: "contains", label: "Contains" },
];

export const operatorsMap = operators.reduce((acc, operator) => {
  acc[operator.value] = operator.label;
  return acc;
}, {});

export const deviceOperators = [
  { value: "empty", label: "Is Empty" },
  { value: "!empty", label: "Is Not Empty" },
  { value: "in", label: "In" },
  { value: "!in", label: "Not In" },
];
