import PropTypes from "prop-types";

require("bootstrap-icons/font/bootstrap-icons.css");

export function IconLoading({ text }) {
  return (
    <div>
      <span className="spinner-border spinner-border-sm" role="status" /> {text}
    </div>
  );
}

export function Icon({ icon, additional_classes, additionalClasses }) {
  if (additional_classes) {
    console.warn(
      "The `additional_classes` prop is deprecated and will be removed in a future release. Please use `additionalClasses` instead.",
    );
  }
  return (
    <i className={`bi-${icon} ${additionalClasses ?? additional_classes}`} />
  );
}

IconLoading.propTypes = {
  text: PropTypes.string,
};

IconLoading.defaultProps = {
  text: null,
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  additional_classes: PropTypes.string,
  additionalClasses: PropTypes.string,
};

Icon.defaultProps = {
  additional_classes: null,
  additionalClasses: null,
};
