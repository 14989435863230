import { withRouter } from "react-router-dom";
import { AuthenticationActions } from "modules/account/AccountActions";
import { UserApi } from "modules/account/AccountAPIs";
import { PATH_ACCOUNT } from "modules/account/AccountConstants";
import { Icon } from "modules/base/components/Icons";
import { PATH_BILLING_OVERVIEW } from "modules/billing/BillingConstants";
import { UI_ROUTES as CLOUDOON_UI_ROUTES } from "modules/cloudoon_email/CloudoonConstants";
import { PATH_GENERATE_CONTENT } from "modules/cms/CmsConstants";
import logo from "modules/core/assets/olitt-logo-main.svg";
import Sidebar from "modules/core/components/Sidebar";
import { Menu } from "modules/core/models/Menu";
import { PATH_DNS } from "modules/dns/DnsConstants";
import { PATH_DOMAINS } from "modules/domains/DomainsConstants";
import { PATH_REFERRAL } from "modules/referral/ReferralConstants";
import { PATH_WEBSITES } from "modules/website/WebsiteConstants";

function MainNavigationSidebar(props) {
  const authenticationActions: any = new AuthenticationActions();
  const userApi: any = new UserApi();
  const user: any = authenticationActions.retrieveAuthenticatedUser();

  const current_path: any = props.location.pathname;
  const sidebar_menus: any = [
    new Menu(
      "Websites",
      "collection",
      PATH_WEBSITES,
      current_path.includes(PATH_WEBSITES),
    ),
    new Menu(
      "Domains",
      "globe2",
      PATH_DOMAINS,
      current_path.includes(PATH_DOMAINS),
    ),
    new Menu(
      "DNS Management",
      "compass",
      PATH_DNS,
      current_path.includes(PATH_DNS),
    ),
    new Menu(
      "Emails",
      "envelope",
      CLOUDOON_UI_ROUTES.DOMAINS.ROOT,
      current_path.includes(CLOUDOON_UI_ROUTES.DOMAINS.ROOT),
    ),
    new Menu(
      "AI Content Generator",
      "pencil",
      PATH_GENERATE_CONTENT,
      current_path.includes(PATH_GENERATE_CONTENT),
    ),
    new Menu(
      "Billing",
      "receipt",
      PATH_BILLING_OVERVIEW,
      current_path.includes(PATH_BILLING_OVERVIEW),
    ),
    new Menu(
      "Refer A Friend & Earn $100",
      "cash-stack",
      PATH_REFERRAL,
      current_path.includes(PATH_REFERRAL),
    ),
  ];

  let bottom_action_append;
  if (user) {
    if (!current_path.includes(PATH_ACCOUNT)) {
      bottom_action_append = (
        <button
          type="button"
          className="btn btn-outline-danger rounded-0"
          onClick={() => userApi.logout(props)}
        >
          <Icon icon="box-arrow-right" />
        </button>
      );
    }
  }

  return (
    <Sidebar
      sidebar_banner_width={40}
      sidebar_banner={logo}
      actions={sidebar_menus}
      bottom_action_append={bottom_action_append}
      {...props}
    />
  );
}

export default withRouter(MainNavigationSidebar);
