import _ from "lodash";
import { PopUpManager } from "../index";

export const installPopUp = (editor, opts = {}) => {
  const em = editor.getModel();

  em.tsLoadModule(PopUpManager);

  Object.defineProperty(editor, "PopUp", {
    get() {
      return this.em.get("PopUp");
    },
    enumerable: false,
    configurable: true,
  });
};

export default installPopUp;
