import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import { handleInputChange } from "modules/base/utilities/Actions";
import MailSettingsFormFields from "modules/marketing/components/emailstemplates/MailSettingsFormFields";
import MarketingAPI from "modules/marketing/MarketingAPI";

function MailSettingsForm({ match }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [state, setState] = useState({
    id: null,
    host: "",
    port: "",
    username: "",
    password: "",
    scheme: "",
  });
  const { id: siteId } = match.params;
  const onInputChange = useCallback((e) =>
    handleInputChange(e.target.name, e.target.value, state, setState),
  );
  const API = new MarketingAPI(siteId);
  const {
    transports,
    error: transportLoadingError,
    total,
    isLoading,
  } = API.getTransports();
  useEffect(() => {
    if (!isLoading && total > 0) {
      const transport = transports[0];
      setState({
        id: transport.id,
        host: transport.host,
        port: transport.port,
        username: transport.username,
        password: transport.password,
        scheme: transport.scheme,
      });
    }
  }, [isLoading]);

  function updateTransport(payload) {
    API.updateTransport(
      payload.id,
      payload,
      () => {
        setSuccess({ message: "Mail settings updated successfully" });
        setIsSubmitting(false);
        setError(null);
      },
      (error_response) => {
        setError({
          message: error_response.message,
        });
        setIsSubmitting(false);
      },
    );
  }

  function createTransport(payload) {
    API.createTransport(
      payload,
      () => {
        setSuccess({ message: "Mail settings created successfully" });
        setIsSubmitting(false);
        setError(null);
      },
      (error_response) => {
        setError({
          message: error_response.message,
        });
        setIsSubmitting(false);
      },
    );
  }

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      if (state.id) {
        updateTransport(state);
      } else {
        createTransport(state);
      }
    },
    [state],
  );
  return (
    <Form
      handleSubmit={handleSubmit}
      id="edit-contact-form"
      button_color="gray-900"
      button_label="Update"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      <NetworkMessageDisplay error={error ?? transportLoadingError} />
      <SuccessActionIndicator success={success} />
      <MailSettingsFormFields state={state} onInputChange={onInputChange} />
    </Form>
  );
}

MailSettingsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(MailSettingsForm);
