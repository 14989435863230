import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import { formatDate } from "modules/base/utilities/Actions";
import "react-tooltip/dist/react-tooltip.css";

function BaseClaimedCoupons({ coupons, error }) {
  const Coupons = coupons.map((coupon) => {
    let { frequency } = coupon;
    let coupon_info = "";
    if (coupon.frequency === "recurring") {
      frequency = `for the next ${coupon.frequency_duration} renewals`;
    }
    if (coupon.percentage_rate) {
      coupon_info = `${coupon.percentage_rate}% off ${frequency}`;
    } else {
      let amount = coupon.amount_cents / 100;
      if (coupon.amount_cents_remaining) {
        amount = coupon.amount_cents_remaining / 100;
      }
      const locale = navigator.language;
      const amount_display = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: coupon.amount_currency,
      }).format(amount);
      coupon_info = `${amount_display} off ${frequency}`;
    }
    if (coupon.expiration_date) {
      coupon_info += ` before ${formatDate(
        coupon.expiration_date,
        "MMM DD, YYYY",
      )}`;
    }
    return (
      <div
        key={coupon.coupon_code}
        className="d-flex justify-content-start align-items-center"
      >
        <span>
          <i className="bi bi-gift text-purple" /> {coupon.coupon_code}
        </span>
        <button type="button" className="btn btn-link d-block d-md-none">
          <i
            className="bi bi-info-circle coupon-info"
            data-tooltip-content={coupon_info}
          />
        </button>
        <span className="text-muted ms-2 fs-6 font-monospace d-none d-md-block">
          {coupon_info}
        </span>
      </div>
    );
  });
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <Tooltip
        anchorSelect=".coupon-info"
        place="top-end"
        id="coupon-info"
        variant="info"
      />
      {Coupons}
    </>
  );
}

BaseClaimedCoupons.propTypes = {
  coupons: PropTypes.instanceOf(Array).isRequired,
  error: PropTypes.instanceOf(Object),
};

BaseClaimedCoupons.defaultProps = {
  error: null,
};

export default BaseClaimedCoupons;
