import Modal from "modules/base/components/Modal";
import GenerateEmailTemplate from "modules/marketing/components/emailstemplates/GenerateEmailTemplate";

function GenerateEmailTemplateModal() {
  const modalId = "generate-templates-modal";
  return (
    <Modal modal_title="Create Using AI" modal_id={modalId} size="md">
      <GenerateEmailTemplate currentModalId={modalId} />
    </Modal>
  );
}

export default GenerateEmailTemplateModal;
