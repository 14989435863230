import Modal from "modules/base/components/Modal";
import CreateForm from "modules/marketing/components/forms/CreateForm";

function CreateFormModal() {
  return (
    <Modal modal_title="Create Form" modal_id="create-form-modal" size="md">
      <CreateForm currentModalId="create-form-modal" />
    </Modal>
  );
}

export default CreateFormModal;
