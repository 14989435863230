import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { withRouter } from "react-router-dom";
import SelectInput from "modules/base/components/inputs/SelectInput";
import SwitchInput from "modules/base/components/inputs/SwitchInput";
import TextInput from "modules/base/components/inputs/TextInput";
import WYSIWYGEditor from "modules/base/components/inputs/WYSIWYGEditor";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import MarketingAPI from "modules/marketing/MarketingAPI";

function ContentFormFields({ state, setState, onInputChange, match }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const { channels, error, isLoading } = API.getChannels();
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      site: siteId,
    }));
  }, [siteId]);
  const onContentChange = useCallback(
    (content) => {
      onInputChange({ target: { name: "content", value: content } });
    },
    [onInputChange],
  );
  const onAutoGenerateChange = useCallback(
    (e) => {
      const { name, checked } = e.target;
      onInputChange({ target: { name, value: checked } });
    },
    [onInputChange],
  );
  const onChannelChange = useCallback(
    (selectedOption) => {
      onInputChange({
        target: { name: "channel", value: selectedOption.value },
      });
    },
    [onInputChange],
  );
  const showContentEditor = state.auto_generate_content === false;
  const channelOptions = channels.map((channel) => ({
    value: channel.id,
    label: channel.name,
  }));
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }

  return (
    <>
      <NetworkMessageDisplay error={error} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <TextInput
          name="subject"
          title="Subject"
          content={state.subject ?? ""}
          controlFunc={onInputChange}
          required
        />
        <SelectInput
          name="channel"
          title="Channel"
          required
          options={channelOptions}
          onChange={onChannelChange}
          predicate={(option) => option.value === state.channel}
        />
        <SwitchInput
          label="Generate using AI"
          name="auto_generate_content"
          handleInputChange={onAutoGenerateChange}
          isChecked={state.auto_generate_content ?? true}
        />
        {showContentEditor && (
          <WYSIWYGEditor
            name="content"
            title="Content"
            content={state.content ?? ""}
            controlFunc={onContentChange}
            required
          />
        )}
      </div>
    </>
  );
}

ContentFormFields.defaultProps = {
  state: {},
  onInputChange: () => {
    // do nothing
  },
};

ContentFormFields.propTypes = {
  state: PropTypes.instanceOf(Object),
  setState: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(ContentFormFields);
