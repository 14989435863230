import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import BillingAddonPurchases from "modules/billing/components/BillingAddonPurchases";
import { Site } from "modules/website/WebsiteModels";

function AddonPurchasesModal(props) {
  const { site } = props;
  return (
    <Modal
      modal_title="Addon Purchases"
      modal_id="addon-purchases-modal"
      size="lg"
    >
      <BillingAddonPurchases site={site} />
    </Modal>
  );
}

AddonPurchasesModal.defaultProps = {
  site: null,
};

AddonPurchasesModal.propTypes = {
  site: PropTypes.instanceOf(Site),
};

export default AddonPurchasesModal;
