import Modal from "modules/base/components/Modal";
import MailSettingsForm from "modules/marketing/components/emailstemplates/MailSettingsForm";

function MailSettingsModal() {
  const modalId = "mail-settings-modal";
  return (
    <Modal modal_title="Mail Settings" modal_id={modalId} size="md">
      <MailSettingsForm currentModalId={modalId} />
    </Modal>
  );
}

export default MailSettingsModal;
