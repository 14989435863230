import { ApmRoute as Route } from "@elastic/apm-rum-react";
import React from "react";
import { Switch } from "react-router-dom";
import { PATH_BILLING_OVERVIEW, PATH_CHECKOUT } from "./BillingConstants";
import PaymentsPage from "./layouts/PaymentsPage";
import BillingOverview from "modules/billing/layouts/BillingOverview";
import Page404 from "modules/dns/layouts/Page404";

function BillingRouter() {
  return (
    <Switch>
      <Route exact path={PATH_BILLING_OVERVIEW} component={BillingOverview} />
      <Route key={1.1} exact path={PATH_CHECKOUT} component={PaymentsPage} />
      <Route key={1.2} path="*" component={Page404} />
    </Switch>
  );
}

export default BillingRouter;
