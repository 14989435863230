import { ApmRoute as Route } from "@elastic/apm-rum-react";
import React from "react";
import { Switch } from "react-router-dom";
import Page404 from "../dns/layouts/Page404";
import { UI_ROUTES } from "./CloudoonConstants";
import AddDomain from "./components/AddDomain";
import Dashboard from "./components/Dashboard";
import ManageAccount from "./components/ManageAccount";
import SetupRouter from "./components/setup/SetupRouter";

function CloudoonEmailRouter() {
  return (
    <Switch>
      <Route
        key={1.1}
        exact
        path={UI_ROUTES.SETUP.ROOT}
        component={AddDomain}
      />
      <Route key={1.2} path={UI_ROUTES.SETUP.DOMAIN} component={SetupRouter} />
      <Route
        key={1.3}
        exact
        path={UI_ROUTES.DOMAINS.ROOT}
        component={Dashboard}
      />
      <Route
        key={1.4}
        path={UI_ROUTES.DOMAINS.DETAIL}
        component={ManageAccount}
      />
      <Route key={1.5} path="*" component={Page404} />
    </Switch>
  );
}

export default CloudoonEmailRouter;
