import PropTypes from "prop-types";
import { memo } from "react";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import useChartRenderer from "modules/marketing/components/dashboard/ChartRenderer";

const StatCard = ({
  title,
  value,
  subtitle,
  iconClass,
  isLoading,
  error,
  chartData,
  chartType,
}) => {
  const chartComponent = useChartRenderer(chartData, chartType);

  if (error) {
    return <NetworkMessageDisplay error={error} />;
  }

  if (isLoading) {
    return (
      <div className="rounded-3 h-stat-card col-12 col-sm-12 col-md-12 h-100 col-lg-4 mb-4 shadow-sm skeleton-loading" />
    );
  }

  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-4 d-flex align-items-stretch">
      <div className="card text-center w-100 shadow-sm">
        <div className="card-body d-flex flex-column justify-content-center align-items-center p-3">
          <div className="d-flex align-items-center mb-3">
            {iconClass && (
              <i className={`bi ${iconClass} display-6 me-2 text-primary`} />
            )}
            <h5 className="card-title fw-bold text-primary mb-0">{title}</h5>
          </div>
          <div className="mb-3 w-100">
            {Object.entries(value).map(([key, val], index) => (
              <div key={index} className="my-1">
                <span className="fw-bold me-1">{key}:</span>
                <span className="mx-2">{val}</span>
              </div>
            ))}
          </div>
          {chartComponent}
          <p className="text-muted mt-2">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

StatCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  subtitle: PropTypes.string.isRequired,
  iconClass: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  chartData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.number),
        backgroundColor: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
  }),
  chartType: PropTypes.oneOf(["pie", "doughnut", "gauge"]),
};

StatCard.defaultProps = {
  iconClass: "",
  error: null,
  chartData: null,
  chartType: "doughnut",
};

export default memo(StatCard);
