import { modelObjectsFromArray } from "../../core/utilities/commonUtilities";
import BaseModel from "modules/core/models/BaseModel";

/**
 * Taxons model
 */
export class Taxon extends BaseModel {
  id: number;

  name: string;

  position: number;

  permalink: string;

  lft: number;

  rgt: number;

  description: string;

  created_at: string;

  updated_at: string;

  meta_title: string;

  meta_description: string;

  meta_keywords: string;

  depth: number;

  public_metadata: Object;

  private_metadata: Object;

  pretty_name: string;

  seo_title: string;

  is_root: boolean;

  is_child: boolean;

  is_leaf: boolean;

  taxons: Array;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.position = data.position;
    this.permalink = data.permalink;
    this.lft = data.lft;
    this.rgt = data.rgt;
    this.description = data.description;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.meta_title = data.meta_title;
    this.meta_description = data.meta_description;
    this.meta_keywords = data.meta_keywords;
    this.depth = data.depth;
    this.public_metadata = data.public_metadata;
    this.private_metadata = data.private_metadata;
    this.pretty_name = data.pretty_name;
    this.seo_title = data.seo_title;
    this.is_root = data.is_root;
    this.is_child = data.is_child;
    this.is_leaf = data.is_leaf;
    this.setTaxons(data.taxons);
  }

  setTaxons(taxonsData) {
    const taxon = new Taxon();
    const taxons = taxon.fromArray(taxonsData);
    this.taxons = taxons;
    this.subRows = taxons;
  }
}
