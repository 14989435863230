export const IS_DEVELOPMENT: any = process.env.NODE_ENV === "development";
export const IS_BETA: any = window.location.host === "beta.olitt.com";
export const IS_STAGING: any = window.location.host === "staging.olitt.com";
export const IS_PLAYGROUND: any =
  window.location.host === "playground.olitt.com";

export const APP_ID: string = "IIK9EPbcAj0RGfL2iJUH1Kl2bkyXPa0hKlBuIHQp";

export const APP_NAME: string = process.env.REACT_APP_APM_APP_NAME ?? "Olitt";
export const APP_VERSION: string = "0.0.1";
export let FULL_APP_URL: string;
if (IS_DEVELOPMENT) {
  FULL_APP_URL = "http://localhost:3000";
} else if (IS_BETA) {
  FULL_APP_URL = "https://beta.olitt.com";
} else if (IS_PLAYGROUND) {
  FULL_APP_URL = "https://playground.olitt.com";
} else if (IS_STAGING) {
  FULL_APP_URL = "https://staging.olitt.com";
} else {
  FULL_APP_URL = "https://www.olitt.com";
}
export const APP_URL: any = FULL_APP_URL.replace("www.", "");
export const APP_PROTOCOL: any = APP_URL.split("://")[0];
export const APP_HOST: any = window.location.host;

export const APP_ORIGIN: any = window.location.origin;

export const RUM_URL: string = "https://apm.jisort.com";

export const POPUP_WIDTH: number = 450;
export const POPUP_HEIGHT: number = 600;

export const NS1_RECORD: string = "ns1.olitt.com.";
export const NS2_RECORD: string = "ns1.olitt.net.";
export const A_RECORD: string =
  process.env.A_RECORD_IP_ADDRESS ?? "51.83.2.109.";

export const HELP_URL: string = "https://help.olitt.com/";
export const PRIVACY_POLICY_URL: string = `${HELP_URL}privacy/`;
export const THIRD_PARTY_COOKIES_CHECK_URL = process.env
  .THIRD_PARTY_COOKIES_CHECK_URL
  ? process.env.THIRD_PARTY_COOKIES_CHECK_URL
  : "https://truehostcloud.github.io/third-party-cookies-checker/";
