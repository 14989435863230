import Modal from "modules/base/components/Modal";
import CreateContactForm from "modules/marketing/components/contacts/CreateContactForm";

function CreateContactModal() {
  return (
    <Modal
      modal_title="Create Contact"
      modal_id="create-contact-modal"
      size="md"
    >
      <CreateContactForm currentModalId="create-contact-modal" />
    </Modal>
  );
}

export default CreateContactModal;
