import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import PropTypes from "prop-types";
import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register Image plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export class FilePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { files: props.files || [] };
  }

  render() {
    const {
      name,
      processFile,
      abortProcessingFile,
      allowMultiple,
      allowReorder,
      maxFiles,
    } = this.props;

    const on_update_files: any = (fileItems) => {
      this.setState({
        files: fileItems.map((fileItem) => fileItem.file),
      });
    };

    const load_file: any = (source, load, error) => {
      fetch(source)
        .then((response) => response.blob())
        .then((favicon_blob) => load(favicon_blob))
        .catch(() => error("Unable to load preview"));
    };

    const process_file: any = (
      fieldName,
      file,
      metadata,
      load,
      error,
      progress,
      abort,
    ) => {
      const success_callback: any = (favicon_url) => load(favicon_url);
      const error_callback: any = (error_message) => error(error_message);
      processFile(file, success_callback, error_callback);

      // Expose an abort method so the request can be cancelled
      return {
        abort: () => {
          abortProcessingFile();
          abort();
        },
      };
    };

    return (
      <FilePond
        ref={(ref) => {
          this.pond = ref;
        }}
        files={this.state.files}
        allowMultiple={allowMultiple || false}
        allowReorder={allowReorder || false}
        maxFiles={maxFiles || 3}
        labelIdle={`Drag & drop your ${name} here or click to browse`}
        onupdatefiles={on_update_files}
        server={{
          load: load_file,
          process: process_file,
        }}
      />
    );
  }
}

FilePicker.propTypes = {
  name: PropTypes.string.isRequired,
  processFile: PropTypes.func.isRequired,
  abortProcessingFile: PropTypes.func.isRequired,
  files: PropTypes.instanceOf(Object),
  allowMultiple: PropTypes.bool,
  allowReorder: PropTypes.bool,
  maxFiles: PropTypes.bool,
};

FilePicker.defaultProps = {
  files: [],
  allowMultiple: false,
  allowReorder: false,
  maxFiles: false,
};
