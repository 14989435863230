import React from "react";

const OrderState = ({ type }) => {
  const bgColor = "";
  let textColor = "";

  switch (type) {
    case "complete":
      textColor = "bg-success text-light";
      break;
    case "canceled":
      textColor = "bg-warning text-light ";
      break;
    case "cart":
      textColor = "bg-info text-light";
      break;
    case "address":
      textColor = "bg-secondary text-light";
      break;
    default:
      textColor = "bg-success text-light";
  }

  return (
    <span
      className={`d-inline-block text-uppercase px-3 m-2 rounded-pill font-weight-bold fs-9 align-items-center text-white p-1 shadow custom-avatar-style ${bgColor} ${textColor}`}
    >
      {type}
    </span>
  );
};

export default OrderState;
