import { createColumnHelper } from "@tanstack/react-table";
import _ from "lodash";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import SelectInput from "modules/base/components/inputs/SelectInput";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import DataTable from "modules/base/components/table/Table";
import {
  closeModalById,
  deleteNullProperties,
  closeModal,
} from "modules/base/utilities/Actions";
import ContactsUploadProgress from "modules/marketing/components/contacts/import/ContactsUploadProgress";
import { useImportContext } from "modules/marketing/components/contacts/import/ImportContext";
import MarketingAPI from "modules/marketing/MarketingAPI";
import { Contact } from "modules/marketing/MarketingModels";
import { toast } from "react-toastify";

function MatchColumns({ currentModalId, rawContactsData, match }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const columnHelper = createColumnHelper();
  const context = useImportContext();
  const isSubmitting = context?.isSubmitting;
  const setIsSubmitting = context?.setIsSubmitting;
  const contactsFile = context?.contactsFile;
  const { segments } = API.getSegments();
  const [selectedSegment, setSelectedSegment] = useState(null);

  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [mappings, setMappings] = useState({});
  const isLoading = false;
  const { data: status, isLoading: progressLoading } =
    API.contactsUploadProgress();
  const dataColumns = rawContactsData.columns;
  const { data } = rawContactsData;
  const contactInstance = new Contact();
  const excludeKeys = ["id", "country", "county", "state", "city", "zip_code"];
  const fieldOptions = Object.keys(contactInstance)
    .filter((key) => !excludeKeys.includes(key))
    .map((key) => {
      return { value: key, label: key };
    });

  const contactsTableColumns = dataColumns.map((column) => {
    return columnHelper.accessor(column, {
      header: _.capitalize(column),
      cell: (info) => info.getValue(),
    });
  });

  const columns = [...contactsTableColumns];

  const onMappingChange = useCallback((column, selectedOption) => {
    setMappings((prevMappings) => ({
      ...prevMappings,
      [column]: selectedOption.value,
    }));
  });

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);
    closeModalById(currentModalId);
    toast.success("upload starts shortly");
    const payload = new FormData();
    payload.append("file", contactsFile);
    payload.append("mappings", JSON.stringify(deleteNullProperties(mappings)));
    payload.append("segment_id", selectedSegment?.id || null);

    API.importFileContacts(
      payload,
      () => {
        setIsSubmitting(false);
        closeModalById(currentModalId);
      },
      (error_response) => {
        setIsSubmitting(false);
        setError({
          message: error_response.message,
        });
        setSuccess(null);
      },
    );
  });

  const segmentOptions = segments.map((segment) => ({
    value: segment.id,
    label: segment.name,
  }));
  const handleSegmentChange = (selectedOption) => {
    setSelectedSegment(
      selectedOption
        ? { id: selectedOption.value, name: selectedOption.label }
        : null,
    );
  };

  const isSegmentSelected = (option) => {
    return selectedSegment === option.value;
  };
  return (
    <>
      {progressLoading && (
        <div className="text-center my-4">
          <div className="d-flex flex-column justify-content-center align-items-center my-3">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
              <span>Preparing Upload</span>
            </div>
          </div>
        </div>
      )}
      {status?.status === "uploading" && (
        <div className="text-center my-4">
          <ContactsUploadProgress />
        </div>
      )}

      {status?.status !== "uploading" && (
        <>
          <Form
            handleSubmit={handleSubmit}
            id="match-columns-form"
            button_color="gray-900"
            button_label="Submit"
            fullLengthButton
            isSubmitting={isSubmitting}
            use_as="form"
            padding="p-0"
          >
            <NetworkMessageDisplay error={error} />
            <SuccessActionIndicator success={success} />
            <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
              {dataColumns?.map((column) => (
                <SelectInput
                  key={column}
                  name={column}
                  title={_.capitalize(column)}
                  options={fieldOptions}
                  onChange={(selectedOption) =>
                    onMappingChange(column, selectedOption)
                  }
                  predicate={(option) => mappings[column] === option.value}
                />
              ))}
              <>
                <SelectInput
                  name="segment"
                  title="Upload to Segment (Optional)"
                  options={segmentOptions}
                  onChange={handleSegmentChange}
                  predicate={isSegmentSelected}
                  isClearable
                  placeholder="Select..."
                />
              </>
            </div>
          </Form>

          {data && (
            <DataTable data={data} columns={columns} isLoading={isLoading} />
          )}
        </>
      )}
    </>
  );
}

MatchColumns.defaultProps = {
  currentModalId: null,
  rawContactsData: {
    columns: [],
    data: [],
  },
};

MatchColumns.propTypes = {
  currentModalId: PropTypes.string,
  rawContactsData: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.instanceOf(Array),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(MatchColumns);
