import _ from "lodash";

/**
 * Formats the billing transaction state into a badge element.
 *
 * @param {string} state - The state of the billing transaction.
 *
 * @return {JSX.Element} - The formatted badge element.
 */
export function formatBillingTransactionState(state) {
  if (["settled", "succeeded"].includes(state)) {
    return <span className="badge text-bg-success">Paid</span>;
  }
  if (state === "failed") {
    return <span className="badge text-bg-danger">Failed</span>;
  }
  if (state === "pending") {
    return <span className="badge text-bg-warning">Pending</span>;
  }
  if (state === "canceled") {
    return <span className="badge text-bg-danger">Canceled</span>;
  }
  if (state === "refunded") {
    return <span className="badge text-bg-secondary">Refunded</span>;
  }
  return <span className="badge text-bg-warning">Unpaid</span>;
}

/**
 * Formats the billing transaction type into the corresponding icon JSX.
 *
 * @param {string} type - The billing transaction type ("inbound" or "outbound").
 * @return {JSX.Element} - The JSX element representing the corresponding icon.
 */
export function formatBillingTransactionType(type) {
  if (type === "inbound") {
    return (
      <span>
        <i className="bi bi-arrow-down-circle-fill text-success me-1" />
        {_.capitalize(type)}
      </span>
    );
  }
  if (type === "outbound") {
    return (
      <span>
        <i className="bi bi-arrow-up-circle-fill text-danger me-1" />
        {_.capitalize(type)}
      </span>
    );
  }
  return <i className="bi bi-question-circle-fill" />;
}

/**
 * Formats a boolean value into a corresponding icon JSX.
 *
 * @param {boolean} value - The boolean value to be formatted.
 *
 * @return {JSX.Element} - The JSX element representing the corresponding icon.
 * If the value is true, a check-circle icon with a success text color is returned.
 * If the value is false, an x-circle icon with a danger text color is returned.
 */
export function formatBoolean(value) {
  if (value) {
    return <i className="bi bi-check-circle-fill text-success" />;
  }
  return <i className="bi bi-x-circle-fill text-danger" />;
}
