import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";
import { openModal } from "modules/base/utilities/Actions";
import { prebuiltFocusTemplatesModalId } from "modules/marketing/components/Focusitems/utils";

const DesignTemplateButton = memo(
  ({ state, currentModalId, formTemplateHtml, notificationHtml, linkHtml }) => {
    const onClickDesign = useCallback(() => {
      openModal(
        `design-focus-items-template-${state.id ?? "new"}-modal`,
        currentModalId,
      );
    }, [state.id, currentModalId]);

    const onClickChooseTemplate = useCallback(() => {
      openModal(prebuiltFocusTemplatesModalId, currentModalId, false);
    }, [currentModalId]);

    const shouldDesignTemplate = useCallback(
      (html, type) => html && state.type === type,
      [state.type],
    );

    const getClickHandler = useCallback(() => {
      if (
        shouldDesignTemplate(formTemplateHtml, "form") ||
        shouldDesignTemplate(notificationHtml, "notice") ||
        shouldDesignTemplate(linkHtml, "link")
      ) {
        return onClickDesign;
      }
      return onClickChooseTemplate;
    }, [
      formTemplateHtml,
      notificationHtml,
      linkHtml,
      shouldDesignTemplate,
      onClickDesign,
      onClickChooseTemplate,
    ]);

    const getAriaLabel = useCallback(() => {
      if (
        shouldDesignTemplate(formTemplateHtml, "form") ||
        shouldDesignTemplate(notificationHtml, "notice") ||
        shouldDesignTemplate(linkHtml, "link")
      ) {
        return "Design Template";
      }
      return "Choose Template";
    }, [formTemplateHtml, notificationHtml, linkHtml, shouldDesignTemplate]);

    return (
      <div className="d-flex align-items-center">
        <button
          type="button"
          className="btn btn-sm btn-outline-primary m-2"
          disabled={state.htmlMode !== "html"}
          onClick={getClickHandler()}
          aria-label={getAriaLabel()}
        >
          <i className="bi bi-brush-fill" />
          {getAriaLabel()}
        </button>
      </div>
    );
  },
);

DesignTemplateButton.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  currentModalId: PropTypes.string.isRequired,
  formTemplateHtml: PropTypes.string,
  notificationHtml: PropTypes.string,
  linkHtml: PropTypes.string,
};

DesignTemplateButton.defaultProps = {
  formTemplateHtml: null,
  notificationHtml: null,
  linkHtml: null,
};

export default DesignTemplateButton;
