import PropTypes from "prop-types";
import { useCallback } from "react";
import { openModal } from "modules/base/utilities/Actions";
import PlanUpgradeModal from "modules/billing/components/PlanUpgradeModal";

function Upgrade({ upgradePlan, site }) {
  const upgradePlanModalId = `select-plan-${site?.id}`;
  const onClickUpgrade = useCallback(() => {
    openModal(upgradePlanModalId);
  });

  return (
    <>
      <PlanUpgradeModal modal_id={upgradePlanModalId} website={site} />
      <div className="flex justify-center align-center">
        <div className="flex flex-col items-center text-center w-75 m-auto">
          <i className="bi bi-lock w-10 h1" />
          <h1> Upgrade to {upgradePlan?.name}</h1>
          <p className="max-w-[500px] text-center text-gray-500 md:text-base/relaxed dark:text-gray-400">
            Access to this feature is limited to {upgradePlan?.name} plan.
            Upgrade your subscription to unlock the full potential of our
            platform advanced features.
          </p>
        </div>
        <div className="d-flex align-center justify-content-center">
          <button
            key="upgrade"
            type="button"
            className="btn btn-olitt-grey mb-2 mb-md-0 me-0 me-md-2"
            onClick={onClickUpgrade}
          >
            Upgrade
          </button>
        </div>
      </div>
    </>
  );
}

Upgrade.propTypes = {
  upgradePlan: PropTypes.instanceOf(Object),
  site: PropTypes.instanceOf(Object),
};

Upgrade.defaultProps = {
  upgradePlan: null,
  site: null,
};

export default Upgrade;
