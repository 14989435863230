import PropTypes from "prop-types";
import { Icon } from "modules/base/components/Icons";

function SearchResults({ searchQuery, currentResults, onClear }) {
  if (!searchQuery) return null;

  return (
    <div
      className="alert alert-info d-flex align-items-center mt-3"
      role="alert"
    >
      <div>
        <Icon icon="info-circle" additionalClasses=" me-2" />
        {currentResults.length > 0 ? (
          <>
            Showing {currentResults.length} results for "
            <strong>{searchQuery}</strong>"
          </>
        ) : (
          <>
            No results found for "<strong>{searchQuery}</strong>"
          </>
        )}
      </div>
      <button
        type="button"
        className="btn-close ms-auto"
        aria-label="Close"
        onClick={onClear}
      />
    </div>
  );
}

SearchResults.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  currentResults: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      title: PropTypes.string,
      category: PropTypes.string,
      is_shop: PropTypes.bool,
      subscription_plan: PropTypes.shape({
        name: PropTypes.string,
        price: PropTypes.number,
      }),
    }),
  ).isRequired,
  onClear: PropTypes.func.isRequired,
};

export default SearchResults;
