import PropTypes, { number } from "prop-types";
import React, { useEffect } from "react";
import {
  useExpanded,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import { Icon } from "../../../base/components/Icons";
import FacebookLogo from "../../../shop/assets/facebook_logo.png";
import { useRowSelection } from "../../../shop/contexts";
import ComponentLoadingIndicator from "../../../website/components/ComponentLoadingIndicator";
import DataGridRowActions from "./DataGridRowActions";
import DataGridTools from "./DataGridTools";
import { cellExpandRowsFormatter } from "./DataGridUtilities";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

/**
 * Table
 * @summary Renders a data grid.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Table(props) {
  const {
    columns,
    data,
    isLoading,
    isRowsExpandable,
    showCheckboxColumn,
    rowSize,
  } = props;
  const colSpan = columns.length;

  const options = {
    data,
    columns,
    initialState: {
      pageSize: rowSize,
    },
  };

  let tableInstanceArgs;
  if (isRowsExpandable) {
    tableInstanceArgs = [options, useExpanded, usePagination, useRowSelect];
  } else {
    tableInstanceArgs = [
      options,
      usePagination,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => {
          if (showCheckboxColumn) {
            // Render the checkbox in columns
            return [
              {
                id: "selection",
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps()}
                    />
                  </div>
                ),
                Cell: ({ row }) => (
                  <div>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      disabled={row.original.is_catalog_throttled}
                    />
                  </div>
                ),
              },
              ...columns,
            ];
          }
          // Return the columns without checkbox
          return columns;
        });
      },
    ];
  }

  const tableInstance = useTable(...tableInstanceArgs);

  const {
    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
  } = tableInstance;

  const { setProducts } = useRowSelection();

  useEffect(() => {
    const extractedRows = selectedFlatRows.map((row) => row.original);
    setProducts(extractedRows);
  }, [selectedFlatRows]);

  const tableHeader = headerGroups.map((headerGroup) => {
    const { headers } = headerGroup;
    return (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headers.map((column) => (
          <th {...column.getHeaderProps()}>{column.render("Header")}</th>
        ))}
      </tr>
    );
  });

  /**
   * tableRows
   * @summary Renders the table rows.
   * @returns {JSX.Element|*}
   */
  const tableRows = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={colSpan}>
            <ComponentLoadingIndicator />
          </td>
        </tr>
      );
    }
    if (data.length === 0) {
      return (
        <tr>
          <td colSpan={colSpan} className="text-center">
            No data available.
          </td>
        </tr>
      );
    }
    // returns rows per page
    return page.map((row) => {
      prepareRow(row);
      return (
        <tr {...row.getRowProps()}>
          {row.cells.map((cell) => {
            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
          })}
        </tr>
      );
    });
  };

  const handlePageChange = (pageNumber) => {
    gotoPage(pageNumber);
  };

  const paginationComponent = (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <nav aria-label="Page navigation p-0 d-flex justify-content-end">
        <ul className="pagination">
          <li className="page-item">
            <button
              type="button"
              className="page-link"
              aria-label="Previous"
              disabled={!canPreviousPage}
              onClick={previousPage}
            >
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
          {pageOptions.map((pageNumber) => (
            <li key={pageNumber} className="page-item">
              <button
                type="button"
                className="page-link"
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber + 1}
              </button>
            </li>
          ))}
          <li className="page-item">
            <button
              type="button"
              className="page-link"
              aria-label="Next"
              disabled={!canNextPage}
              onClick={nextPage}
            >
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );

  return (
    <div>
      <table {...getTableProps()} className="table table-hover">
        <thead>{tableHeader}</thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>{tableRows()}</tbody>
      </table>

      {/* Page button */}
      {pageCount > 1 && paginationComponent}
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRowsExpandable: PropTypes.bool.isRequired,
  showCheckboxColumn: PropTypes.bool.isRequired,
  rowSize: PropTypes.number.isRequired,
};

/**
 * DataTable
 * @summary Renders a data grid.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DataTable(props) {
  const {
    columns,
    data,
    isLoading,
    isDataRefreshable,
    onClickRefresh,
    isDataCreatable,
    onClickCreate,
    isDataEditable,
    onClickEdit,
    isDataDeletable,
    onClickDelete,
    isRowsExpandable,
    canPostToFacebook,
    canPostToInstagram,
    onClickPublishToFacebook,
    onClickPublishToInstagram,
    canPostToCatalog,
    showCheckboxColumn,
    rowSize,
  } = props;

  /**
   * @name addActionsColumn
   * @summary Adds the actions column to the table.
   * @returns {array}
   */
  const addActionsColumn = () => {
    if (!isDataEditable && !isDataDeletable) {
      return [];
    }

    const actionsColumnExists = columns.find(
      (column) => column.Header === "Actions",
    );

    if (actionsColumnExists) {
      return [];
    }

    const actionsColumn = {
      Header: "Actions",
      accessor: "id",
      Cell: (tableInstance) => {
        const { cell, row } = tableInstance;
        return (
          <DataGridRowActions
            isDataEditable={isDataEditable}
            isDataDeletable={isDataDeletable}
            onClickEdit={() => onClickEdit(cell.value, row.original)}
            onClickDelete={() => onClickDelete(cell.value, row.original)}
          />
        );
      },
    };

    return [actionsColumn];
  };

  /**
   * @name addFacebookCatalogColumn
   * @summary Add facebook catalog status column to the table
   * @returns {array}
   */
  const addFacebookCatalogColumn = () => {
    if (!canPostToCatalog) {
      return [];
    }

    const catalogColumnExists = columns.find(
      (column) => column.accessor === "catalog_id",
    );

    if (catalogColumnExists) {
      return [];
    }

    const catalogColumn = {
      Header: "In Facebook Catalog",
      accessor: "catalog_id",
      Cell: (tableInstance) => {
        const { row } = tableInstance;

        if (row.original.in_facebook_catalog) {
          return (
            <div className="text-success">
              <Icon icon="check-circle-fill" />
            </div>
          );
        }

        return (
          <div className="text-danger">
            <Icon icon="x-circle-fill" />
          </div>
        );
      },
    };

    return [catalogColumn];
  };

  /**
   * @name addSocialMediaButtonsColumn
   * @summary Add posting to social media buttons column to the table
   * @returns {array}
   */
  const addSocialMediaButtonsColumn = () => {
    if (!canPostToFacebook && !canPostToInstagram) {
      return [];
    }

    const socialMediaColumnExists = columns.find(
      (column) => column.accessor === "social_media_post_id",
    );

    if (socialMediaColumnExists) {
      return [];
    }

    const socialMediaColumn = {
      Header: "Post To Social Media",
      accessor: "social_media_post_id",
      Cell: (tableInstance) => {
        const { row } = tableInstance;

        const buttons = [];

        if (canPostToFacebook) {
          if (row.original.is_facebook_throttled) {
            buttons.push(
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="You will be able to post again in about 1 hour"
              >
                <button
                  type="button"
                  disabled
                  className="btn btn-facebook d-flex align-items-center gap-2"
                >
                  <Icon icon="facebook" additionalClasses="text-white" />
                  <span className="text-white">Posted!</span>
                </button>
              </div>,
            );
          } else {
            buttons.push(
              <button
                type="button"
                className="btn btn-facebook d-flex align-items-center gap-2"
                onClick={() => onClickPublishToFacebook(row.original)}
              >
                <img
                  src={FacebookLogo}
                  alt="Facebook Logo"
                  width={18}
                  height={18}
                />
                <span className="text-white">Facebook</span>
              </button>,
            );
          }
        }

        if (canPostToInstagram) {
          if (row.original.is_instagram_throttled) {
            buttons.push(
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="You will be able to post again in about 1 hour"
              >
                <button
                  type="button"
                  disabled
                  className="btn btn-instagram d-flex align-items-center gap-2"
                >
                  <Icon icon="instagram" additionalClasses="text-white" />
                  <span className="text-white">Posted!</span>
                </button>
              </div>,
            );
          } else {
            buttons.push(
              <button
                type="button"
                className="btn btn-instagram d-flex align-items-center gap-2"
                onClick={() => onClickPublishToInstagram(row.original)}
              >
                <Icon icon="instagram" />
                <span>Instagram</span>
              </button>,
            );
          }
        }

        return <div className="d-flex align-items-center gap-2">{buttons}</div>;
      },
    };
    return [socialMediaColumn];
  };

  /**
   * @name addExpandButton
   * @summary Adds the expand button to the table.
   * @returns {array}
   */
  const addExpandButton = () => {
    if (!isRowsExpandable) {
      return [];
    }

    const expandColumnExists = columns.find(
      (column) => column.id === "expander",
    );

    if (expandColumnExists) {
      return [];
    }

    return [
      {
        id: "expander",
        Cell: cellExpandRowsFormatter,
      },
    ];
  };

  const dataColumns = React.useMemo(() => {
    return [
      ...addExpandButton(),
      ...columns,
      ...addActionsColumn(),
      ...addFacebookCatalogColumn(),
      ...addSocialMediaButtonsColumn(),
    ];
  }, [isLoading, canPostToFacebook, canPostToInstagram]);

  return (
    <div className="table-responsive">
      <DataGridTools
        isDataRefreshable={isDataRefreshable}
        onClickRefresh={onClickRefresh}
        isDataCreatable={isDataCreatable}
        onClickCreate={onClickCreate}
      />
      <Table
        columns={dataColumns}
        data={data}
        isLoading={isLoading}
        isRowsExpandable={isRowsExpandable}
        showCheckboxColumn={showCheckboxColumn}
        rowSize={rowSize}
      />
    </div>
  );
}

DataTable.defaultProps = {
  data: [],
  columns: [],
  isLoading: false,
  isDataRefreshable: false,
  onClickRefresh: () => {},
  isDataCreatable: false,
  onClickCreate: () => {},
  isDataEditable: false,
  onClickEdit: () => {},
  isDataDeletable: false,
  onClickDelete: () => {},
  isRowsExpandable: false,
  canPostToFacebook: false,
  canPostToInstagram: false,
  onClickPublishToFacebook: () => {},
  onClickPublishToInstagram: () => {},
  canPostToCatalog: false,
  showCheckboxColumn: false,
  rowSize: number,
};

DataTable.propTypes = {
  data: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  isLoading: PropTypes.bool,
  isDataRefreshable: PropTypes.bool,
  onClickRefresh: PropTypes.func,
  isDataCreatable: PropTypes.bool,
  onClickCreate: PropTypes.func,
  isDataEditable: PropTypes.bool,
  onClickEdit: PropTypes.func,
  isDataDeletable: PropTypes.bool,
  onClickDelete: PropTypes.func,
  isRowsExpandable: PropTypes.bool,
  canPostToFacebook: PropTypes.bool,
  canPostToInstagram: PropTypes.bool,
  onClickPublishToFacebook: PropTypes.func,
  onClickPublishToInstagram: PropTypes.func,
  canPostToCatalog: PropTypes.bool,
  showCheckboxColumn: PropTypes.bool,
  rowSize: PropTypes.number,
};

export default DataTable;
