import _ from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

/**
 * CopyToClipboardButton component allows users to copy a given text to the clipboard.
 * It provides visual feedback by changing the button text to "Copied" for a short duration.
 *
 * @param {Object} props - The component props.
 * @param {string} props.text - The text to be copied to the clipboard.
 *
 * @returns {JSX.Element} The rendered button component.
 */
function CopyToClipboardButton({ text }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      _.delay(() => setCopied(false), 2000);
    }
  }, [copied]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(text).then(() => setCopied(true));
  });

  return (
    <button
      onClick={handleCopy}
      type="button"
      className="btn btn-sm btn-outline-gray-900"
    >
      {copied ? "Copied" : "Copy"}
    </button>
  );
}

CopyToClipboardButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CopyToClipboardButton;
