import { facebook_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const tm = editor.Panels.getButton("views", "open-tm");
  const { fbPixel_script, fbPixelComponent } = opts;

  const placeholder = `<div style="text-align:center;color:#f6a908;height: 50px;">${facebook_logo}</div>
<div  style="text-align:center;color:#f6a908">This is a placeholder and will not be rendered. Toggle visibility in layers</div>`;

  domc.addType("facebook-pixel-component", {
    model: {
      defaults: {
        tagName: "div",
        name: "Facebook Pixel",
        draggable: "[data-gjs-type=wrapper]",
        droppable: false,
        stylable: false,
        copyable: false,
        fbPixel_script,
        traits: [
          {
            name: "fbPixel_script",
            label: "Facebook Pixel Code",
            placeholder: "</> Paste Pixel Code here.",
            changeProp: 1,
          },
        ],
        content: placeholder,
      },
      init() {
        this.on("change:fbPixel_script", () => valuesOnchange());
        const valuesOnchange = () => {
          this.trigger("change:script");
          editor.store();
        };
        tm.set("active", 1);
      },
      toHTML() {
        const tagName = this.get("tagName");
        const fbPixel_script = this.get("fbPixel_script");
        return `<${tagName} id="${this.getId()}">${fbPixel_script}</${tagName}>`;
      },
      ...fbPixelComponent,
    },
    view: {},
  });
};
