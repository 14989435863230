import PropTypes from "prop-types";

function ContactDonotContactButton(props) {
  const { doNotContact, isUpdatingContactStatus, onClick } = props;
  const processSpinner = (
    <span
      className="spinner-border spinner-border-sm me-1"
      role="status"
      aria-hidden="true"
    />
  );
  return (
    <button
      type="button"
      className={`btn btn-sm btn-${
        doNotContact.length > 0 ? "danger" : "success"
      }`}
      onClick={onClick}
      disabled={isUpdatingContactStatus}
    >
      {isUpdatingContactStatus && processSpinner}
      {doNotContact && doNotContact.length > 0 ? "Inactive" : "Active"}
    </button>
  );
}

ContactDonotContactButton.propTypes = {
  doNotContact: PropTypes.bool.isRequired,
  isUpdatingContactStatus: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ContactDonotContactButton;
