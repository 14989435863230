import moment from "moment";
import {
  CAMPAIGN_ACTION_LINE_COLOR,
  CAMPAIGN_CONDITION_FALSE_LINE_COLOR,
  CAMPAIGN_CONDITION_TRUE_LINE_COLOR,
  CAMPAIGN_DECISION_NO_LINE_COLOR,
  CAMPAIGN_DECISION_YES_LINE_COLOR,
  NEW_CAMPAIGN_EVENT_PREFIX,
  NEW_FORM_ACTION_PREFIX,
  NEW_FORM_FIELD_PREFIX,
} from "modules/marketing/MarketingConstants";
import {
  CampaignEvent,
  CanvasSettingsConnection,
  CanvasSettingsNode,
} from "modules/marketing/MarketingModels";

/**
 * Processes form fields by removing the 'id' property from new fields.
 *
 * @param {Array} fields - An array of form field objects.
 * Each field object should have an 'id' property.
 * If the 'id' of a field is a string and includes the 'NEW_FORM_FIELD_PREFIX',
 * the 'id' property is removed from the field object.
 *
 * @returns {Array} - Returns a new array of processed field objects.
 */
export function processFormFields(fields) {
  return fields.map((field) => {
    if (
      typeof field.id === "string" &&
      field.id.includes(NEW_FORM_FIELD_PREFIX)
    ) {
      const { id, ...rest } = field;
      return rest;
    }
    return field;
  });
}

/**
 * Function: processFormActions
 *
 * This function processes an array of actions and filters out any actions that have an 'id' property
 * that is a string and includes the 'NEW_FORM_ACTION_PREFIX' constant. It returns a new array with
 * only the filtered actions.
 *
 * @param {Array} actions - The array of actions to be processed
 * @returns {Array} - The new array with filtered actions
 */
export function processFormActions(actions) {
  return actions.map((action) => {
    if (
      typeof action.id === "string" &&
      action.id.includes(NEW_FORM_ACTION_PREFIX)
    ) {
      const { id, ...rest } = action;
      return rest;
    }
    return action;
  });
}

/**
 * Formats the location data into a single string.
 *
 * @param {Object} data - The location data object.
 * @param {string} data.city - The city of the location.
 * @param {string} data.state - The state of the location.
 * @param {string} data.country - The country of the location.
 * @param {string} data.zipcode - The zipcode of the location.
 * @param {string} data.address1 - The first line of the address.
 * @param {string} data.address2 - The second line of the address.
 *
 * @returns {string} - Returns a string representing the location.
 */
export function locationFormatter(data) {
  const city = data.city ?? "";
  const state = data.state ?? "";
  const country = data.country ?? "";
  const zipcode = data.zipcode ?? "";
  const address1 = data.address1 ?? "";
  const address2 = data.address2 ?? "";

  const locationParts = [address1, address2, city, state, zipcode, country];
  return locationParts.filter((part) => part !== "").join(", ");
}

/**
 * Recursively finds the ancestor event of a given event.
 *
 * @param {Array} events - An array of event objects.
 * @param {Object} event - The event object for which the ancestor is to be found.
 * @param {Object} event.parent - The parent of the event. If null, the event is an ancestor.
 *
 * @returns {Object} - Returns the ancestor event object.
 */
export function getCampaignAncestorEvent(events, event) {
  if (!event) {
    return null;
  }
  if (!event.parent) {
    return events.find((e) => e.id === event.id);
  }
  return getCampaignAncestorEvent(events, event.parent);
}

/**
 * Recursively finds a specific child event in a given event's hierarchy.
 *
 * @param {Object} event - The event object in which to search for the child event.
 * @param {string} childEventId - The id of the child event to find.
 *
 * @returns {Object|null} - Returns the child event object if found, otherwise returns null.
 */
export function getCampaignEventChild(event, childEventId) {
  if (!event || !childEventId || !event.id) {
    return null;
  }
  if (event.id.toString() === childEventId.toString()) {
    return event;
  }
  if (event.children && event.children.length > 0) {
    let foundChild = null;
    for (let i = 0; i < event.children.length; i += 1) {
      foundChild = getCampaignEventChild(event.children[i], childEventId);
      if (foundChild) {
        break;
      }
    }
    return foundChild;
  }
  return null;
}

/**
 * Builds an array of CanvasSettingsConnection instances from an array of edges.
 *
 * @param {Array} edges - An array of edge objects. Each edge object should have a 'data' property.
 *
 * @returns {Array} - Returns an array of CanvasSettingsConnection instances.
 */
export function buildCampaignEventConnections(edges) {
  return edges.map((edge) => {
    return new CanvasSettingsConnection(edge.data);
  });
}

/**
 * Builds an array of CanvasSettingsNode instances from an array of nodes.
 *
 * @param {Array} nodes - An array of node objects. Each node object should have an 'id' and 'position' property.
 * The 'position' property is an object with 'x' and 'y' properties representing the position of the node.
 * If the 'position' property is not provided or its value is null, it will be replaced with an object with 'x' and 'y' properties set to 0.
 *
 * @returns {Array} - Returns an array of CanvasSettingsNode instances.
 */
export function buildCampaignCanvasNodesSettings(nodes) {
  return nodes.map((node) => {
    const nodePosition = node.position ?? { x: 0, y: 0 };
    return new CanvasSettingsNode({
      id: node.id,
      positionX: nodePosition.x,
      positionY: nodePosition.y,
    });
  });
}

/**
 * Handles the children of a given event in a campaign.
 *
 * @param {Array} events - An array of all event objects in the campaign.
 * @param {Object} parentEvent - The parent event object.
 * @param {Array} children - An array of the parent event's children.
 * @param {Object} parentPosition - The position of the parent event. It is an object with 'x' and 'y' properties.
 * @param {Object} lineColorMap - A map of line colors for different types of events.
 * @param {Array} eventNodes - An array of event nodes. This array is modified by the function.
 * @param {string} currentModalId - The id of the currently open modal.
 * @param {Array} eventEdges - An array of event edges. This array is modified by the function.
 * @param {Object} canvasNodesSettingsMap - A map of canvas node settings. The keys are event ids and the values are objects with 'positionX' and 'positionY' properties.
 * @param {Function} updateEvent - A function to update an event.
 */
export function handleCampaignEventChildren(
  events,
  parentEvent,
  children,
  parentPosition,
  lineColorMap,
  eventNodes,
  currentModalId,
  eventEdges,
  canvasNodesSettingsMap,
  updateEvent,
) {
  children.forEach((child, index) => {
    const canvasNodeSettings = canvasNodesSettingsMap[child.id] || {};
    const positionX = parentPosition.x + 200 - 200 * index;
    const positionY = parentPosition.y + 100;
    const position = {
      x: canvasNodeSettings.positionX ?? positionX,
      y: canvasNodeSettings.positionY ?? positionY,
    };
    let sourceHandle = CAMPAIGN_ACTION_LINE_COLOR;
    if (["decision", "condition"].includes(parentEvent.eventType)) {
      sourceHandle = lineColorMap.decision[child.decisionPath];
    }
    eventNodes.push({
      id: child.id.toString(),
      data: {
        label: child.name,
        id: child.id,
        campaignEvent: new CampaignEvent(child),
        currentModalId,
        updateEvent,
      },
      position,
      type: child.eventType,
    });
    eventEdges.push({
      id: `e${parentEvent.id}-${child.id}`,
      source: parentEvent.id.toString(),
      sourceHandle,
      target: child.id.toString(),
      animated: true,
      style: { stroke: sourceHandle },
      label: child.decisionPath ?? "then",
      data: {
        sourceId: parentEvent.id,
        targetId: child.id,
        anchors: {
          source: child.decisionPath ?? "bottom",
          target: "top",
        },
      },
    });
    const childEvent = events.find((event) => event.id === child.id);
    if (childEvent.children && childEvent.children.length > 0) {
      handleCampaignEventChildren(
        events,
        childEvent,
        childEvent.children,
        position,
        lineColorMap,
        eventNodes,
        currentModalId,
        eventEdges,
        canvasNodesSettingsMap,
        updateEvent,
      );
    }
  });
}

function getNumberFromString(str) {
  if (typeof str !== "string") {
    return str;
  }
  return parseInt(str.replace(/\D/g, ""), 10);
}

function getLastCampaignEventId(events) {
  if (events.length === 0) {
    return 0;
  }
  return Math.max(...events.map((event) => getNumberFromString(event.id)));
}

/**
 * Adds a new event to the campaign.
 *
 * @param {Array} events - An array of all event objects in the campaign.
 * @param {string} nodeId - The id of the node to which the new event is to be added.
 * @param {string} handleId - The id of the handle from which the new event is to be added.
 * @param {Object} newNodePosition - The position of the new node. It is an object with 'x' and 'y' properties.
 * @param {Function} updateNode - A function to update a node.
 * @param {string} currentModalId - The id of the currently open modal.
 * @param {Function} updateEvent - A function to update an event.
 *
 * @returns {Object} - Returns an object with 'newNode' and 'newEdge' properties.
 */
export function addCampaignEvent(
  events,
  nodeId,
  handleId,
  newNodePosition,
  updateNode,
  currentModalId,
  updateEvent,
) {
  const lineColorsMap = {
    decision: {
      [CAMPAIGN_DECISION_YES_LINE_COLOR]: "yes",
      [CAMPAIGN_DECISION_NO_LINE_COLOR]: "no",
    },
    condition: {
      [CAMPAIGN_CONDITION_TRUE_LINE_COLOR]: "yes",
      [CAMPAIGN_CONDITION_FALSE_LINE_COLOR]: "no",
    },
  };
  const currentEvents = [...events];
  const parentEvent =
    currentEvents.find((event) => event.id.toString() === nodeId) ?? null;
  const ancestorEvent = getCampaignAncestorEvent(events, parentEvent);
  const parentChildEvent = getCampaignEventChild(ancestorEvent, nodeId);
  let decisionPath = null;
  if (
    parentEvent &&
    ["condition", "decision"].includes(parentEvent.eventType)
  ) {
    decisionPath = lineColorsMap[parentEvent.eventType][handleId];
  }
  const newEvent = new CampaignEvent({
    id: `${NEW_CAMPAIGN_EVENT_PREFIX}${getLastCampaignEventId(events) + 1}`,
    name: "Click to configure",
    type: "new",
    eventType: "new",
    parent: parentEvent,
    decisionPath,
    children: [],
    properties: {},
  });
  if (parentChildEvent) {
    parentChildEvent.children = parentChildEvent.children || [];
    parentChildEvent.children.push(newEvent);
  }
  if (parentEvent) {
    parentEvent.children = parentEvent.children || [];
    parentEvent.children.push(newEvent);
  }
  currentEvents.push(newEvent);
  events.push(newEvent);
  const newNode = {
    id: newEvent.id.toString(),
    position: newNodePosition,
    data: {
      label: newEvent.name,
      id: newEvent.id,
      campaignEvent: newEvent,
      currentModalId,
      updateNode,
      updateEvent,
    },
    origin: [0.5, 0.0],
    type: "new",
  };
  const newEdge = {
    id: `e${nodeId}-${newEvent.id}`,
    source: nodeId,
    sourceHandle: handleId,
    target: newEvent.id.toString(),
    animated: true,
    style: { stroke: handleId },
    label: decisionPath ?? "then",
    data: {
      sourceId: nodeId,
      targetId: newEvent.id,
      anchors: {
        source: !parentEvent ? "leadsource" : decisionPath ?? "bottom",
        target: "top",
      },
    },
  };
  return { newNode, newEdge };
}

/**
 * Updates an existing event in the campaign.
 *
 * @param {Array} events - An array of all event objects in the campaign.
 * @param {Object} eventData - The event data object. It should have an 'id' property that matches the id of an event in the array of events.
 * The other properties of the event data object represent the keys to be updated in the event.
 * The values of these properties represent the new values for the keys.
 */
export function updateCampaignEvent(events, eventData) {
  const event = events.find((e) => e.id === eventData.id);
  const ancestorEvent = getCampaignAncestorEvent(events, event);
  const childEvent = getCampaignEventChild(ancestorEvent, eventData.id);
  Object.keys(eventData).forEach((key) => {
    if (Array.isArray(eventData[key])) {
      childEvent[key] = eventData[key];
      event[key] = eventData[key];
    } else if (typeof eventData[key] === "object" && eventData[key] !== null) {
      childEvent[key] = { ...childEvent[key], ...eventData[key] };
      event[key] = { ...event[key], ...eventData[key] };
    } else {
      childEvent[key] = eventData[key];
      event[key] = eventData[key];
    }
  });
}

export function formatDate(value, OUTPUT_FORMAT = "YYYY-MM-DD HH:mm") {
  const parsedDate = moment.utc(value, [moment.ISO_8601, OUTPUT_FORMAT], true);
  return parsedDate.isValid() ? parsedDate : null;
}

export function deleteCampaignEvent(events, nodeId) {
  const event = events.find((e) => e.id.toString() === nodeId);
  const ancestorEvent = getCampaignAncestorEvent(events, event) ?? { id: null };
  const parentEvent = event.parent ?? { id: null };
  const parentChildEvent = getCampaignEventChild(
    ancestorEvent,
    parentEvent.id,
  ) ?? {
    children: [],
  };
  const eventIndex = parentChildEvent.children.findIndex(
    (child) => child.id === event.id,
  );
  parentChildEvent.children.splice(eventIndex, 1);
  if (parentEvent.children) {
    const parentEventIndex = parentEvent.children.findIndex(
      (child) => child.id === event.id,
    );
    parentEvent.children.splice(parentEventIndex, 1);
  }
  const eventIndexInEvents = events.findIndex((e) => e.id === event.id);
  events.splice(eventIndexInEvents, 1);
}

/**
 * Handles the first level events in a campaign.
 *
 * @param {Array} events - An array of all event objects in the campaign.
 * @param {Array} firstLevelEvents - An array of first level event objects in the campaign.
 * @param {number} canvasWidth - The width of the canvas.
 * @param {Object} canvasNodesSettingsMap - A map of canvas node settings. The keys are event ids and the values are objects with 'positionX' and 'positionY' properties.
 * @param {string} currentModalId - The id of the currently open modal.
 * @param {Array} eventNodes - An array of event nodes. This array is modified by the function.
 * @param {Array} eventEdges - An array of event edges. This array is modified by the function.
 * @param {Object} lineColorMap - A map of line colors for different types of events.
 * @param {Function} updateEvent - A function to update an event.
 */
export function handleFirstLevelCampaignEvents(
  events,
  firstLevelEvents,
  canvasWidth,
  canvasNodesSettingsMap,
  currentModalId,
  eventNodes,
  eventEdges,
  lineColorMap,
  updateEvent,
) {
  firstLevelEvents.forEach((event, index) => {
    const x =
      canvasWidth / 2 + 200 * (firstLevelEvents.length / 2) - 200 * index;
    const canvasNodeSettings = canvasNodesSettingsMap[event.id] || {};
    const position = {
      x: canvasNodeSettings.positionX ?? x,
      y: canvasNodeSettings.positionY ?? 100,
    };
    eventNodes.push({
      id: event.id.toString(),
      data: {
        label: event.name,
        id: event.id,
        campaignEvent: new CampaignEvent(event),
        currentModalId,
        updateEvent,
      },
      position,
      type: event.eventType,
    });
    eventEdges.push({
      id: `e1-${event.id}`,
      source: "lists",
      sourceHandle: CAMPAIGN_ACTION_LINE_COLOR,
      target: event.id.toString(),
      animated: true,
      style: { stroke: CAMPAIGN_ACTION_LINE_COLOR },
      label: "then",
      data: {
        sourceId: "lists",
        targetId: event.id,
        anchors: {
          source: "leadsource",
          target: "top",
        },
      },
    });
    if (event.children && event.children.length > 0) {
      handleCampaignEventChildren(
        events,
        event,
        event.children,
        position,
        lineColorMap,
        eventNodes,
        currentModalId,
        eventEdges,
        canvasNodesSettingsMap,
        updateEvent,
      );
    }
  });
}
