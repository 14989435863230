import EmailTemplateList from "modules/marketing/components/emailstemplates/EmailTemplateList";
import SubscriptionCheck from "modules/marketing/layouts/SubscriptionCheck";
import { MARKETING_EMAIL_TEMPLATES_SLUG } from "modules/marketing/MarketingConstants";

function EmailTemplates() {
  return (
    <SubscriptionCheck
      slug={MARKETING_EMAIL_TEMPLATES_SLUG}
      component={<EmailTemplateList />}
    />
  );
}

export default EmailTemplates;
