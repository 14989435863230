export default (editor, options = {}) => {
  const opts: any = {
    ...{
      changDragCommand: "change-drag-mode",
      enableDragLabel: `Enable free drag`,
      disableDragLabel: "Disable free drag",
    },
    ...options,
  };
  const { changDragCommand, enableDragLabel, disableDragLabel } = opts;

  editor.on("component:selected", (component) => {
    const { dmode } = component.attributes;
    const label: any =
      dmode === "" || dmode === 0 ? enableDragLabel : disableDragLabel;
    const tb: any = component.get("toolbar");
    const cmdIndex: string = tb.findIndex(
      (item) => item.command === changDragCommand,
    );
    if (cmdIndex === -1) {
      tb.push({
        command: changDragCommand,
        label,
        attributes: { title: "Enable free drag" },
      });
    } else {
      tb[cmdIndex].label = label;
    }
  });

  editor.Commands.add(changDragCommand, () => {
    const target: any = editor.getSelected();
    const { dmode } = target.attributes;
    if (dmode === 0 || dmode === "") {
      target.setDragMode("translate");
    } else {
      target.setDragMode(0);
      target.setStyle({ transform: "" });
    }
    editor.selectRemove(target);
  });
};
