import { Modal as BootstrapModal } from "bootstrap";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import SelectInput from "modules/base/components/inputs/SelectInput";
import SelectOptionCard from "modules/base/components/inputs/SelectOptionCard";
import { closeModalById } from "modules/base/utilities/Actions";
import ConfigureNodeForm from "modules/marketing/components/campaigns/nodes/configure/ConfigureNodeForm";

function ConfigureNewNodeForm({
  campaignEvent,
  setState,
  updateNode,
  updateEvent,
  currentModalId,
}) {
  const [selectedEventType, setSelectedEventType] = useState(null);
  const [campaignEventState, setCampaignEventState] = useState(campaignEvent);
  const allEventOptions = [
    { value: "lead.adddnc", label: "Add do not contact", type: "action" },
    { value: "lead.removednc", label: "Remove do not contact", type: "action" },
    {
      value: "campaign.addremovelead",
      label: "Change campaigns",
      type: "action",
    },
    {
      value: "lead.changelist",
      label: "Modify contact's segment",
      type: "action",
    },
    { value: "email.send", label: "Send email", type: "action" },
    { value: "focus.show", label: "Show engagement item", type: "action" },
    { value: "email.click", label: "Clicks email", type: "decision" },
    { value: "page.devicehit", label: "Device visit", type: "decision" },
    { value: "email.open", label: "Opens email", type: "decision" },
    { value: "email.reply", label: "Replies to email", type: "decision" },
    { value: "form.submit", label: "Submits form", type: "decision" },
    { value: "page.pagehit", label: "Visits page", type: "decision" },
    { value: "lead.device", label: "Contact's device", type: "condition" },
    {
      value: "lead.field_value",
      label: "Contact's field value",
      type: "condition",
    },
    { value: "lead.segments", label: "Contact segments", type: "condition" },
    { value: "form.field_value", label: "Form field value", type: "condition" },
    {
      value: "email.validate.address",
      label: "Has valid email address",
      type: "condition",
    },
    {
      value: "campaign.addremovelead",
      label: "Change campaigns",
      type: "action",
    },
    { value: "stage.change", label: "Change contact's stage", type: "action" },
  ];
  const eventTypes = [
    {
      value: "decision",
      label: "Decision",
      icon: "signpost-split-fill",
      description:
        "A decision is made when a contact decides to take action or not (e.g. opened an email).",
    },
    {
      value: "action",
      label: "Action",
      icon: "activity",
      description: "An action is something executed (e.g. send an email)",
    },
    {
      value: "condition",
      label: "Condition",
      icon: "funnel-fill",
      description:
        "A condition is based on known profile field values or submitted form data.",
    },
  ];
  const onEventSelect = useCallback((selectedOption) => {
    setCampaignEventState((prevState) => ({
      ...prevState,
      type: selectedOption.value,
      name: selectedOption.label,
      eventType: selectedEventType,
    }));
  });
  const onEventTypeSelect = useCallback((e) => {
    setSelectedEventType(e.value);
  });
  const eventTypeOptions = eventTypes.map((eventType) => {
    const iconColor = selectedEventType === eventType.value ? "white" : "black";
    return (
      <SelectOptionCard
        key={eventType.value}
        value={eventType.value}
        label={eventType.label}
        subtitle=""
        description={eventType.description}
        onSelect={onEventTypeSelect}
        isSelected={selectedEventType === eventType.value}
        icon={<i className={`bi bi-${eventType.icon} text-${iconColor}`} />}
        icon_position="top"
        section_name={`${campaignEventState.id}-event-type`}
        selected_bg_color="gray-900"
        selected_pre_label_text_color_class="text-white"
        selected_label_text_color_class="text-white"
        selected_subtitle_text_color_class="text-white"
        selected_description_text_color_class="text-white"
        unselected_description_text_color_class="text-muted"
      />
    );
  });
  const eventOptions = allEventOptions.filter(
    (event) => event.type === selectedEventType,
  );

  function closeDisposeModal() {
    const modalElement = document.getElementById(currentModalId);
    const modal = BootstrapModal.getInstance(modalElement);
    modalElement.addEventListener("hidden.bs.modal", () => {
      modal.dispose();
      modalElement.remove();
    });
    closeModalById(currentModalId);
  }

  const onClickConfigure = useCallback(() => {
    closeDisposeModal();
    setState({ ...campaignEventState });
    updateNode(campaignEventState.id, {
      type: campaignEventState.eventType,
      data: {
        campaignEvent,
        previouslyNewNode: true,
      },
    });
  });
  return (
    <ConfigureNodeForm
      campaignEventState={campaignEventState}
      handleSubmit={onClickConfigure}
      campaignEvent={campaignEvent}
      updateEvent={updateEvent}
    >
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        {eventTypeOptions}
        <SelectInput
          name="event"
          title="Event"
          options={eventOptions}
          onChange={onEventSelect}
          predicate={(option) => option.value === campaignEventState.type}
          required
        />
      </div>
    </ConfigureNodeForm>
  );
}

ConfigureNewNodeForm.propTypes = {
  campaignEvent: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  updateNode: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  currentModalId: PropTypes.string.isRequired,
  campaignBuilderModalId: PropTypes.string.isRequired,
};

export default ConfigureNewNodeForm;
