import { facebook_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const tm = editor.Panels.getButton("views", "open-tm");
  const { facebook_post_url, facebookPostComponent } = opts;

  const placeholder = `<div style="text-align:center;color:#f6a908;height: 50px;">${facebook_logo}
    </div><div  style="text-align:center;color:#f6a908">This is a placeholder and will not be rendered. Toggle visibility in layers</div>`;

  domc.addType("FACEBOOK-POST-COMPONENT", {
    model: {
      defaults: {
        tagName: "div",
        name: "Facebook Post",
        draggable: "[data-gjs-type=wrapper]",
        droppable: false,
        stylable: false,
        copyable: false,
        facebook_post_url,
        traits: [
          {
            name: "facebook_post_url",
            label: "Post url",
            placeholder: "Enter post url.",
            changeProp: 1,
          },
        ],
        content: placeholder,
        script() {
          let url;
          (function (w, d, s, l) {
            w[l] = w[l] || [];
            w[l].push({
              "fbpost.start": new Date().getTime(),
              event: "fbpost.js",
            });
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s);
            const dl = l != "dataLayer" ? `&l=${l}` : "";
            j.async = true;
            j.crossorigin = "anonymous";
            j.nonce = "Fv2rFnpa";
            j.src =
              "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0&appId=967753237006217&autoLogAppEvents=1";
            f.parentNode.insertBefore(j, f);
            url = i + dl;
          })(window, document, "script", "dataLayer");
        },
      },
      init() {
        this.on("change:facebook_post_url", () => valuesOnchange());
        const valuesOnchange = () => {
          this.trigger("change:script");
          editor.store();
        };
        tm.set("active", 1);
      },
      toHTML() {
        const tagName = this.get("tagName");
        const facebook_post_url = this.get("facebook_post_url");
        if (facebook_post_url !== undefined || facebook_post_url !== "") {
          const new_content = `<div id="fb-root"></div><div class="fb-post" data-href="${facebook_post_url}" data-width="500" data-show-text="true"><blockquote cite="${facebook_post_url}" class="fb-xfbml-parse-ignore"></blockquote></div>`;
          return `<${tagName} id="${this.getId()}">${new_content}</${tagName}>`;
        }
      },
      ...facebookPostComponent,
    },
    view: {},
  });
};
