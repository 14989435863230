import PropTypes from "prop-types";
import { parse } from "query-string";
import { useCallback } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationActions } from "modules/account/AccountActions";
import { PATH_LOGIN } from "modules/account/AccountConstants";
import PlanPurchase from "modules/billing/components/PlanPurchase";
import { navigateToPage } from "modules/core/utilities/navigation/Navigator";
import { PATH_WEBSITE_TEMPLATES } from "modules/website/WebsiteConstants";

function PaymentsPage(props) {
  const { location } = props;
  const UrlQueryStrings: any = location.search;
  const query_values: any = parse(UrlQueryStrings);
  const plan_id: number = Number(query_values.plan_id);
  const { billing_cycle } = query_values;
  const authenticationActions: any = new AuthenticationActions();
  const user: any = authenticationActions.retrieveAuthenticatedUser();

  if (!user) {
    window.location.href = `${PATH_LOGIN}?next=${window.location.pathname}${window.location.search}`;
  }

  const onCompletePurchase = useCallback(() => {
    navigateToPage(PATH_WEBSITE_TEMPLATES, props);
  });

  return (
    <div className="min-vh-100 d-flex align-items-center justify-content-center">
      <div className="col-12 col-lg-8">
        <PlanPurchase
          selectedPlanId={plan_id}
          selectedCycle={billing_cycle}
          onCompletePurchase={onCompletePurchase}
        />
      </div>
    </div>
  );
}

PaymentsPage.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(PaymentsPage);
