import React from "react";

class Warning extends React.Component {
  render() {
    const { text, btnText, btnAction } = this.props;

    return (
      <div className="h-100 d-flex flex-column justify-content-center align-items-center m-4">
        <h4 className="text-warning mb-4">{text}</h4>
        {btnText && (
          <button className="btn btn-outline-primary" onClick={btnAction}>
            {btnText}
          </button>
        )}
      </div>
    );
  }
}

export default Warning;
