import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Icon } from "modules/base/components/Icons";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import Pagination from "modules/base/components/table/Pagination";
import { BillingAddon } from "modules/base/models/Billing";
import { openModal } from "modules/base/utilities/Actions";
import { BillingAPI } from "modules/billing/BillingAPIs";
import {
  BILLING_PLANS_DATA,
  CHECK_PAID_SITE_DATA,
  CHECK_PAID_SITE_URL,
  URL_PLANS,
} from "modules/billing/BillingConstants";
import { BillingPlan } from "modules/billing/BillingModels";
import AddonPurchaseAction from "modules/billing/components/AddonPurchaseAction";
import AddonPurchaseForm from "modules/billing/components/AddonPurchaseForm";
import AddonPurchasesModal from "modules/billing/components/AddonPurchasesModal";
import DebitCreditCardFormWrapper from "modules/billing/components/DebitCreditCardForm";
import ViewAddonPurchasesAction from "modules/billing/components/ViewAddonPurchasesAction";
import MainNavigationSidebar from "modules/core/components/MainNavigationSidebar";
import SidebarWrapper from "modules/core/components/SidebarWrapper";
import { IMAGE_SERVER_ENDPOINT } from "modules/core/constants/Endpoints";
import { navigateToPage } from "modules/core/utilities/navigation/Navigator";
import { PopUptUtility } from "modules/core/utilities/PopUpUtility";
import { invalidateData } from "modules/core/utilities/redux/actions/actions";
import {
  fetchUrlData,
  getUrlData,
} from "modules/core/utilities/redux/componentActions";
import {
  MARKETING_SLUG,
  PATH_MARKETING_DASHBOARD,
} from "modules/marketing/MarketingConstants";
import { PATH_DASHBOARD } from "modules/shop/ShopConstants";
import SiteApi from "modules/website/api/SiteApi";
import EmptyList from "modules/website/components/EmptyList";
import WebsiteCard from "modules/website/components/WebsiteCard";
import {
  PATH_WEBSITE_DOMAINS,
  PATH_WEBSITE_FORM_SUBMISSIONS,
  PATH_WEBSITE_PAGE,
  PATH_WEBSITE_SEO,
  PATH_WEBSITE_SETTINGS,
  PATH_WEBSITE_STUDIO,
  PATH_WEBSITE_TEMPLATES,
} from "modules/website/WebsiteConstants";

const ItemsPerPage = 10;

const WebsiteListActions = ({ onCreateWebsite, onCreateShop }) => {
  return (
    <div className="d-flex flex-column flex-md-row">
      <button
        type="button"
        className="btn btn-olitt-grey shadow-none mb-2 mb-md-0"
        data-tutorial="create-website-btn"
        onClick={() => onCreateWebsite(false)}
      >
        <Icon icon="plus" />
        Create website
      </button>
      <button
        type="button"
        className="btn btn-olitt-grey shadow-none ms-sm-2"
        data-tutorial="create-shop-btn"
        onClick={() => onCreateShop(true)}
      >
        <Icon icon="shop" />
        <span className="ms-1">Create shop</span>
      </button>
    </div>
  );
};

WebsiteListActions.propTypes = {
  onCreateWebsite: PropTypes.func.isRequired,
  onCreateShop: PropTypes.func.isRequired,
};

const WebsiteCardsList = ({
  websites,
  props,
  isOpeningEditor,
  setIsOpeningEditor,
  addons,
  customer,
  paid_plan,
  free_plan,
  siteApi,
  offset,
  pageSize,
}) => {
  const [error, setError] = useState(null);
  const [selectedBillingAddon, setSelectedBillingAddon] = useState(
    new BillingAddon(),
  );

  const [selectedBillingAddonWebsite, setSelectedBillingAddonWebsite] =
    useState(null);

  const [is_share_site_visible, setShareSiteVisible] = useState(false);

  const closeShareSiteModal = () => {
    setShareSiteVisible(false);
  };

  const websitesFetchParams = {
    offset,
    limit: pageSize,
    paginate: true,
  };

  function navigateToEditor(website) {
    if (website.isStudioProject) {
      const studioPath = PATH_WEBSITE_STUDIO.replace(":id", website.id);
      navigateToPage(studioPath, props);
    } else {
      setIsOpeningEditor(true);
      siteApi.retrieveWebsitePages(
        website.id,
        (is_successful, pages_or_error) => {
          if (is_successful) {
            const startPage = pages_or_error.find((page) => page.is_start_page);
            if (!startPage) {
              setError("No start page found for this website.");
              setIsOpeningEditor(false);
              return;
            }
            const editor_path = PATH_WEBSITE_PAGE.replace(
              ":id",
              website.id,
            ).replace(":page_id", startPage.id);
            setIsOpeningEditor(false);
            navigateToPage(editor_path, props);
          } else {
            setIsOpeningEditor(false);
            const page_error =
              "We cannot take you you to the editor right now.";
            setError(page_error);
          }
        },
      );
    }
  }

  const togglePublishWebsite = (website) => {
    siteApi.updateWebsiteFields(
      website.id,
      { is_published: !website.is_published },
      (is_successful, website_or_error) => {
        if (!is_successful) {
          setError(website_or_error);
        }
      },
      () => {},
      () => {},
      websitesFetchParams,
    );
  };

  const shareWebsite = () => {
    setShareSiteVisible(true);
  };

  const navigateToDomains = (website) => {
    const path = PATH_WEBSITE_DOMAINS.replace(":id", website.id);
    navigateToPage(path, props);
  };

  const navigateToSeo = (website) => {
    const path = PATH_WEBSITE_SEO.replace(":id", website.id);
    navigateToPage(path, props);
  };
  const navigateToForms = (website) => {
    const path = PATH_WEBSITE_FORM_SUBMISSIONS.replace(":id", website.id);
    navigateToPage(path, props);
  };

  const navigateToSettings = (website) => {
    const path = PATH_WEBSITE_SETTINGS.replace(":id", website.id);
    navigateToPage(path, props);
  };

  const duplicateWebsite = (website) => {
    if (website) {
      const canCreateWebsite =
        customer.has_minimum_cards_allowed ||
        (free_plan && website.subscription_plan.no_of_sites);

      if (canCreateWebsite) {
        siteApi.createWebsiteFromTemplate(
          website.id,
          website.subscription_plan.id,
          website.billing_subscription.billing_interval,
          (is_successful, site_or_error) => {
            if (!is_successful) {
              setError(site_or_error);
            }
          },
        );
      } else {
        openModal("add-card");
      }
    }
  };

  const manageShop = (website) => {
    const path = PATH_DASHBOARD.replace(":id", website.id);
    navigateToPage(path, props);
  };

  const navigateToDashboard = (website) => {
    const features = website.subscription_plan.features.map((feature) =>
      feature.slug.toLowerCase(),
    );
    if (features.includes(MARKETING_SLUG)) {
      const path = PATH_MARKETING_DASHBOARD.replace(":id", website.id);
      navigateToPage(path, props);
    } else {
      PopUptUtility.showUpgradeModal(
        website.id,
        "Upgrade to access Marketing Features",
        paid_plan,
      );
    }
  };

  if (!(paid_plan instanceof BillingPlan)) {
    return null;
  }

  return (
    <>
      <AddonPurchaseForm
        addon={selectedBillingAddon}
        website={selectedBillingAddonWebsite}
      />
      <DebitCreditCardFormWrapper isDismissible={false} />
      <AddonPurchasesModal site={selectedBillingAddonWebsite} />
      <div className="container-fluid">
        <div className="row no-gutters" data-tutorial="main-content-area">
          {websites.map((website) => {
            let over_image_action = {
              className: "btn btn-outline-primary mx-auto",
              onClick: () => navigateToEditor(website),
              text: "Go to editor",
              icon: "pencil-square",
              is_action_processing: isOpeningEditor,
            };
            if (website.is_shop) {
              over_image_action = {
                className: "btn btn-outline-primary mx-auto",
                onClick: () => manageShop(website),
                text: "Manage shop",
                icon: "shop",
                is_action_processing: false,
              };
            }

            let actionList = [
              {
                label: "View Purchased Addons",
                icon: "view-list",
                onClick: () => {},
                component: (
                  <ViewAddonPurchasesAction
                    key={website.id}
                    setSelectedWebsite={setSelectedBillingAddonWebsite}
                    website={website}
                  />
                ),
                showConfirm: false,
              },
              {
                label: "Marketing",
                icon: "megaphone",
                onClick: () => navigateToDashboard(website),
                showConfirm: false,
                hidden: website.is_shop,
              },
              {
                label: "Edit",
                icon: "pencil-square",
                onClick: () => navigateToEditor(website),
                showConfirm: false,
                hidden: website.is_shop,
              },
              {
                label: "Manage",
                icon: "shop",
                onClick: () => manageShop(website),
                showConfirm: false,
                hidden: !website.is_shop,
              },
              {
                label: website.is_published ? "Unpublish" : "Publish",
                icon: website.is_published
                  ? "cloud-download-fill"
                  : "cloud-upload-fill",
                onClick: () => togglePublishWebsite(website),
                showConfirm: false,
                hidden: website.is_shop,
              },
              {
                label: "Share",
                icon: "share-fill",
                onClick: () => shareWebsite(website),
                showConfirm: false,
              },
              {
                label: "Connect Domain",
                icon: "link-45deg",
                onClick: () => navigateToDomains(website),
                showConfirm: false,
              },
              {
                label: "SEO",
                icon: "search",
                onClick: () => navigateToSeo(website),
                showConfirm: false,
                hidden: website.is_shop,
              },
              {
                label: "Duplicate",
                icon: "stickies",
                onClick: () => duplicateWebsite(website),
                showConfirm: false,
                hidden: website.is_shop,
              },
              {
                label: "Form Submissions",
                icon: "chat-text",
                onClick: () => navigateToForms(website),
                showConfirm: false,
                hidden: website.is_shop,
              },
              {
                label: "Website Settings",
                icon: "gear",
                onClick: () => navigateToSettings(website),
                showConfirm: false,
                hidden: website.is_shop,
              },
              {
                label: "Delete",
                icon: "trash-fill",
                onClick: (id, successCallback, errorCallback) => {
                  siteApi.deleteWebsite(
                    website.id,
                    () => {
                      successCallback();
                    },
                    (deleteError) =>
                      errorCallback("Error deleting website", deleteError),
                    websitesFetchParams,
                  );
                },
                showConfirm: true,
                confirm: {
                  title: "Delete website",
                  imageUrl: `${IMAGE_SERVER_ENDPOINT}/0x200/${website.desktop_thumbnail}`,
                  message: "Are you sure you want to delete this website?",
                  confirm_button_label: "Delete",
                  action_done_title: "Website deleted",
                  action_done_message: "The website has been deleted.",
                  confirm_button_color: "danger",
                },
              },
            ];
            const billingAddonActions = addons.map((addon) => {
              return {
                label: addon.name,
                icon: "puzzle",
                onClick: () => {},
                component: (
                  <AddonPurchaseAction
                    key={addon.id}
                    addon={addon}
                    setAddon={setSelectedBillingAddon}
                    setSelectedWebsite={setSelectedBillingAddonWebsite}
                    website={website}
                  />
                ),
                showConfirm: false,
              };
            });
            actionList = billingAddonActions.concat(actionList);

            return (
              <WebsiteCard
                key={website.id}
                website={website}
                paidPlan={paid_plan}
                over_image_action={over_image_action}
                actions={[]}
                actionsList={actionList}
                wide_view
                view_mode="sites"
                onCloseShareSite={closeShareSiteModal}
                is_share_site_visible={is_share_site_visible}
              />
            );
          })}
        </div>
      </div>
      <NetworkMessageDisplay error={error} />
    </>
  );
};

WebsiteCardsList.propTypes = {
  websites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      is_published: PropTypes.bool.isRequired,
      is_shop: PropTypes.bool.isRequired,
      isStudioProject: PropTypes.bool,
      subscription_plan: PropTypes.shape({
        id: PropTypes.string.isRequired,
        features: PropTypes.arrayOf(PropTypes.string).isRequired,
        pricing: PropTypes.shape({
          amount: PropTypes.number.isRequired,
        }).isRequired,
      }).isRequired,
      desktop_thumbnail: PropTypes.string,
    }),
  ).isRequired,
  props: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isOpeningEditor: PropTypes.bool.isRequired,
  setIsOpeningEditor: PropTypes.func.isRequired,
  addons: PropTypes.arrayOf(PropTypes.instanceOf(BillingAddon)).isRequired,
  customer: PropTypes.shape({
    has_minimum_cards_allowed: PropTypes.bool.isRequired,
  }).isRequired,
  paid_plan: PropTypes.instanceOf(BillingPlan).isRequired,
  free_plan: PropTypes.instanceOf(BillingPlan).isRequired,
  siteApi: PropTypes.instanceOf(SiteApi).isRequired,
  offset: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

const PaginationSection = ({
  total,
  currentPage,
  pageSize,
  onPageChange,
  onPageSizeChange,
}) => {
  if (total <= 10) return null;

  return (
    <Pagination
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      total={total}
      pageIndex={currentPage}
      pageSize={pageSize}
      isServerSidePagination
    />
  );
};

PaginationSection.propTypes = {
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
};

function WebsiteList(props) {
  const billingAPI = new BillingAPI();
  const { addons } = billingAPI.getBillingAddons();
  const { customer, isLoading: isLoadingBillingCustomer } =
    billingAPI.getBillingCustomer("i");
  const { dispatch, paid_plan, free_plan } = props;
  const siteApi = new SiteApi();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(ItemsPerPage);
  const [isOpeningEditor, setIsOpeningEditor] = useState(false);
  const offset = currentPage * ItemsPerPage;

  const {
    sites,
    total,
    error: sitesLoadingError,
    isLoading,
  } = siteApi.getWebsites({
    offset,
    limit: pageSize,
    paginate: true,
  });

  useEffect(() => {
    dispatch(invalidateData(`${CHECK_PAID_SITE_URL}`));
    dispatch(invalidateData(`${URL_PLANS}`));

    fetchUrlData(CHECK_PAID_SITE_DATA, `${CHECK_PAID_SITE_URL}`, props);
    fetchUrlData(BILLING_PLANS_DATA, `${URL_PLANS}`, props);
  }, []);

  const navigateToNewWebsite = (is_new_shop = false) => {
    let new_website_path = PATH_WEBSITE_TEMPLATES;
    new_website_path = `${new_website_path}?is_shop=${is_new_shop}`;
    navigateToPage(new_website_path, props);
  };

  if (isLoading || isLoadingBillingCustomer) {
    return <NetworkActivityIndicator />;
  }

  let content = (
    <>
      <WebsiteCardsList
        websites={sites}
        props={props}
        isOpeningEditor={isOpeningEditor}
        setIsOpeningEditor={setIsOpeningEditor}
        addons={addons}
        customer={customer}
        paid_plan={paid_plan}
        free_plan={free_plan}
        siteApi={siteApi}
        offset={offset}
        pageSize={pageSize}
      />
      <PaginationSection
        total={total}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
        onPageSizeChange={setPageSize}
      />
    </>
  );

  if (sites.length === 0) {
    const title = "No websites here.";
    const description =
      "Create a new website for your business today. For free!";
    content = <EmptyList title={title} description={description} />;
  }

  return (
    <SidebarWrapper sidebar={<MainNavigationSidebar />}>
      <div className="mb-4 d-md-flex align-items-md-center justify-content-md-between">
        <p
          className="h5 text-olitt-grey font-weight-bold"
          data-tutorial="welcome-website"
        >
          {sites.length > 0 && `My websites (${total})`}
        </p>
        <WebsiteListActions
          onCreateWebsite={navigateToNewWebsite}
          onCreateShop={navigateToNewWebsite}
        />
      </div>
      <NetworkMessageDisplay error={sitesLoadingError} />
      {content}
    </SidebarWrapper>
  );
}

WebsiteList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  free_plan: PropTypes.instanceOf(Object).isRequired,
  paid_plan: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const billing_plan_data = getUrlData(
    dataByUrl,
    sessionVariables,
    BILLING_PLANS_DATA,
  );

  let billing_plans = [];
  let paid_plan = {};
  let free_plan = {};
  if (billing_plan_data.items.length > 0) {
    billing_plans = billing_plan_data.items.map(
      (parameters) => new BillingPlan(parameters),
    );
    free_plan = billing_plans.find((billing_plan) => {
      return billing_plan.pricing.amount <= 0.0;
    });
    paid_plan = billing_plans.find((billing_plan) => {
      return billing_plan.pricing.amount > 0.0;
    });
  }
  return { paid_plan, free_plan };
}

export default connect(mapStateToProps)(withRouter(WebsiteList));
