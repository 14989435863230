import PropTypes from "prop-types";
import React from "react";
import { Icon } from "../../base/components/Icons";
import { ToastUtility, toast_styles } from "../../core/utilities/ToastUtility";

export function VisitDomainBanner({ domain, size }) {
  function visitWebsite() {
    window.open(`https://${domain}`, "_blank");
  }

  function copyToClipboard() {
    navigator.clipboard
      .writeText(domain)
      .then(() => {
        ToastUtility.toast(toast_styles.success, "Copied to clipboard");
      })
      .catch(() => {
        ToastUtility.toast(toast_styles.error, "Something went wrong");
      });
  }

  let visit_btn_classes: string =
    "btn btn-primary shadow-none rounded-0 rounded-right";
  let copy_btn_classes: string =
    "ms-4 text-primary btn btn-transparent shadow-none";
  if (size) {
    visit_btn_classes = `${visit_btn_classes} btn-${size}`;
    copy_btn_classes = `${copy_btn_classes} btn-${size}`;
  }

  return (
    <div className="rounded border border-primary d-flex align-items-center">
      <span className="ms-4 text-primary flex-grow-1">{domain}</span>
      <button
        type="button"
        className={copy_btn_classes}
        onClick={copyToClipboard}
      >
        <Icon icon="clipboard" />
      </button>
      <button
        type="button"
        className={visit_btn_classes}
        onClick={visitWebsite}
      >
        Visit
      </button>
    </div>
  );
}

VisitDomainBanner.defaultProps = {
  domain: "",
};

VisitDomainBanner.propTypes = {
  domain: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};
VisitDomainBanner.defaultProps = {
  size: "sm",
};
