import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import {
  Handle,
  Position,
  getConnectedEdges,
  useNodeId,
  useStore,
} from "reactflow";

function TargetHandle({ isConnectable }) {
  const selector = (s) => ({
    nodeInternals: s.nodeInternals,
    edges: s.edges,
  });
  const { nodeInternals, edges } = useStore(selector);
  const nodeId = useNodeId();
  const isHandleConnectable = useMemo(() => {
    if (typeof isConnectable === "number") {
      const node = nodeInternals.get(nodeId);
      const connectedEdges = getConnectedEdges([node], edges);

      return connectedEdges.length < isConnectable;
    }

    return isConnectable;
  }, [nodeInternals, edges, nodeId, isConnectable]);
  return (
    <Handle
      type="target"
      position={Position.Top}
      style={{ background: "#555" }}
      onConnect={(params) => console.log("handle onConnect", params)}
      isConnectable={isHandleConnectable}
    />
  );
}

TargetHandle.defaultProps = {
  isConnectable: 1,
};

TargetHandle.propTypes = {
  isConnectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default TargetHandle;
