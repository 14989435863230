import { tawak_to_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const tm = editor.Panels.getButton("views", "open-tm");
  const { tawTo_script, tawToComponent } = opts;

  const placeholder = `<div style="text-align:center;color:#f6a908;height: 50px;">${tawak_to_logo}</div>
<div  style="text-align:center;color:#f6a908">This is a placeholder and will not be rendered. Toggle visibility in layers</div>`;

  domc.addType("TAWK-TO-COMPONENT", {
    model: {
      defaults: {
        tagName: "div",
        name: "TawkTo",
        draggable: "[data-gjs-type=wrapper]",
        droppable: false,
        stylable: false,
        copyable: false,
        tawTo_script,
        traits: [
          {
            name: "tawTo_script",
            label: "TawkTo Widget Code",
            placeholder: "</> Paste Widget Code here.",
            changeProp: 1,
          },
        ],
        content: placeholder,
      },
      init() {
        this.on("change:tawTo_script", () => valuesOnchange());
        const valuesOnchange = () => {
          this.trigger("change:script");
          editor.store();
        };
        tm.set("active", 1);
      },
      toHTML() {
        const tagName = this.get("tagName");
        const append_script = setComponent(this);
        return `<${tagName} id="${this.getId()}"><script type="text/javascript">${append_script}</script></${tagName}>`;
      },
      ...tawToComponent,
    },
    view: {},
  });

  const setComponent = (context) => {
    const tawTo_script = context.get("tawTo_script");
    let url = null;
    if (tawTo_script !== undefined) {
      url = getURL(tawTo_script);
    }
    const append_script = `if (!document.getElementById('tawkto')) {var Tawk_API=Tawk_API||{},Tawk_LoadStart=new Date();(function(){var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];s1.async=true;s1.id="tawkto";s1.src=${url};s1.charset='UTF-8';s1.setAttribute('crossorigin','*');s0.parentNode.insertBefore(s1,s0);})();}`;
    return append_script;
  };

  const getURL = (script) => {
    const urlRegex = /((('https?:\/\/)|('www\.))[^\s][^\;]+)/g;
    return script.match(urlRegex);
  };
};
