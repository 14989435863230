import { OpenToolbar } from "../commands";
import { cmdCustomOpenLayers, cmdCustomOpenToolbar } from "../consts";

export default (editor) => {
  // Add Custom Toolbar Options

  const openInLayers = "Show in layers";

  const cm = editor.DomComponents;
  cm.getTypes().forEach(({ id }) => {
    if (id !== "iframe") {
      cm.addType(id, {
        model: {
          initToolbar() {
            let tb = this.get("toolbar");
            const exists =
              tb.find((item) => item.label === openInLayers) !== undefined;
            if (!exists) {
              tb = [
                ...tb,
                {
                  command: (e) => e.Commands.run(cmdCustomOpenLayers),
                  label: openInLayers,
                },
                {
                  command: (e) => {
                    e.runCommand("core:copy");
                    e.runCommand("core:component-delete");
                  },
                  label: "Cut",
                },
                {
                  command: (e) => {
                    e.runCommand("core:copy");
                  },
                  label: "Copy",
                },
                {
                  command: (e) => {
                    e.runCommand("core:paste");
                  },
                  label: "Paste",
                },
              ];
              if (id === "text") {
                this.set("toolbar", [
                  ...this.get("toolbar"),
                  {
                    command: (e) => {
                      const { result } = e.em.getSelected().view.canActivate();
                      if (result) {
                        e.em.getSelected().view.onActive();
                      } else {
                        e.em.getSelected().view.onDisable();
                      }
                    },
                    label: `
                    <div class="text-center">
                      <i class="fa fa-pencil" aria-hidden="true"></i>
                    </div>
                  `,
                    attributes: { title: "Edit" },
                  },
                ]);
              }
            }
            this.set("contextmenu", tb);
          },
        },
        extendFn: ["initToolbar"],
      });
    }
  });

  // Open Context Menu on right click
  editor.Commands.add(cmdCustomOpenToolbar, OpenToolbar);

  editor.on(`component:mount`, (model) => {
    const currentView = model.getCurrentView();
    if (currentView) {
      currentView.listenTo(model, "contextmenu", ({ view, event }) => {
        editor.em.setSelected(view.model);

        if (editor.Commands.isActive(cmdCustomOpenToolbar)) {
          editor.stopCommand(cmdCustomOpenToolbar, {});
        }

        editor.runCommand(cmdCustomOpenToolbar, { event });
      });
    }
  });
};
