import { API_BASE_URL } from "../core/constants/Endpoints";
import { PATH_MAIN_ROUTE } from "../core/constants/Routes";

/**
 * API Endpoints
 */
const API_URL_CLOUDOON = `${API_BASE_URL}/v1/cloudoon/`;
const API_URL_CLOUDOON_DOMAINS = `${API_URL_CLOUDOON}domains/`;
const API_URL_CLOUDOON_DOMAIN_DETAIL = `${API_URL_CLOUDOON_DOMAINS}:domain_name/`;
const API_URL_CLOUDOON_DOMAIN_VERIFICATION = `${API_URL_CLOUDOON_DOMAIN_DETAIL}verification/`;
const API_URL_CLOUDOON_DOMAIN_VERIFICATION_STATUS = `${API_URL_CLOUDOON_DOMAIN_VERIFICATION}status/`;
const API_URL_CLOUDOON_DOMAIN_VERIFICATION_VERIFY = `${API_URL_CLOUDOON_DOMAIN_VERIFICATION}verify/`;
const API_URL_CLOUDOON_DOMAIN_VERIFICATION_OWNERSHIP = `${API_URL_CLOUDOON_DOMAIN_VERIFICATION}ownership/`;
const API_URL_CLOUDOON_DOMAIN_VERIFY_OWNERSHIP = `${API_URL_CLOUDOON_DOMAIN_VERIFICATION_OWNERSHIP}/verify/`;
const API_URL_CLOUDOON_DOMAIN_VERIFICATION_MX_RECORDS = `${API_URL_CLOUDOON_DOMAIN_VERIFICATION}mx-records/`;
const API_URL_CLOUDOON_DOMAIN_VERIFY_MX_RECORDS = `${API_URL_CLOUDOON_DOMAIN_VERIFICATION_MX_RECORDS}verify/`;
const API_URL_CLOUDOON_DOMAIN_ACCOUNTS = `${API_URL_CLOUDOON_DOMAIN_DETAIL}accounts/`;
const API_URL_CLOUDOON_DOMAIN_GET_ACCOUNT = `${API_URL_CLOUDOON_DOMAIN_ACCOUNTS}get/`;
const API_URL_CLOUDOON_DOMAIN_DELETE_ACCOUNT = `${API_URL_CLOUDOON_DOMAIN_ACCOUNTS}delete/`;
const API_URL_CLOUDOON_DOMAIN_SUSPEND_ACCOUNT = `${API_URL_CLOUDOON_DOMAIN_ACCOUNTS}suspend/`;
const API_URL_CLOUDOON_DOMAIN_UNSUSPEND_ACCOUNT = `${API_URL_CLOUDOON_DOMAIN_ACCOUNTS}unsuspend/`;
const API_URL_CLOUDOON_DOMAIN_AUTO_LOGIN_ACCOUNT = `${API_URL_CLOUDOON_DOMAIN_ACCOUNTS}auto-login/`;

export const API_ROUTES = {
  ROOT: API_URL_CLOUDOON,
  DOMAINS: {
    ROOT: API_URL_CLOUDOON_DOMAINS,
    DETAIL: API_URL_CLOUDOON_DOMAIN_DETAIL,
  },
  VERIFICATION: {
    ROOT: API_URL_CLOUDOON_DOMAIN_VERIFICATION,
    STATUS: API_URL_CLOUDOON_DOMAIN_VERIFICATION_STATUS,
    OWNERSHIP: API_URL_CLOUDOON_DOMAIN_VERIFICATION_OWNERSHIP,
    VERIFY: API_URL_CLOUDOON_DOMAIN_VERIFICATION_VERIFY,
    VERIFY_OWNERSHIP: API_URL_CLOUDOON_DOMAIN_VERIFY_OWNERSHIP,
    MX_RECORDS: API_URL_CLOUDOON_DOMAIN_VERIFICATION_MX_RECORDS,
    VERIFY_MX_RECORDS: API_URL_CLOUDOON_DOMAIN_VERIFY_MX_RECORDS,
  },
  ACCOUNTS: {
    ROOT: API_URL_CLOUDOON_DOMAIN_ACCOUNTS,
    GET: API_URL_CLOUDOON_DOMAIN_GET_ACCOUNT,
    DELETE: API_URL_CLOUDOON_DOMAIN_DELETE_ACCOUNT,
    SUSPEND: API_URL_CLOUDOON_DOMAIN_SUSPEND_ACCOUNT,
    UNSUSPEND: API_URL_CLOUDOON_DOMAIN_UNSUSPEND_ACCOUNT,
    AUTO_LOGIN: API_URL_CLOUDOON_DOMAIN_AUTO_LOGIN_ACCOUNT,
  },
};

/**
 * Routes
 */
const PATH_CLOUDOON_EMAILS = `${PATH_MAIN_ROUTE}/emails`;
const PATH_CLOUDOON_EMAILS_DOMAINS = `${PATH_CLOUDOON_EMAILS}/domains`;
const PATH_CLOUDOON_EMAILS_DOMAIN_DETAIL = `${PATH_CLOUDOON_EMAILS_DOMAINS}/:domain_name`;
const PATH_CLOUDOON_EMAILS_DOMAINS_SETUP = `${PATH_CLOUDOON_EMAILS_DOMAINS}/setup`;
const PATH_CLOUDOON_EMAILS_DOMAIN_SETUP = `${PATH_CLOUDOON_EMAILS_DOMAINS}/setup/:domain_name`;
const PATH_CLOUDOON_EMAILS_DOMAIN_SETUP_VERIFICATION = `${PATH_CLOUDOON_EMAILS_DOMAIN_SETUP}/verification`;
const PATH_CLOUDOON_EMAILS_DOMAIN_SETUP_ACCOUNT = `${PATH_CLOUDOON_EMAILS_DOMAIN_SETUP}/account`;
const PATH_CLOUDOON_EMAILS_DOMAIN_SETUP_COMPLETED = `${PATH_CLOUDOON_EMAILS_DOMAIN_SETUP}/completed`;

export const UI_ROUTES = {
  ROOT: PATH_CLOUDOON_EMAILS,
  DOMAINS: {
    ROOT: PATH_CLOUDOON_EMAILS_DOMAINS,
    DETAIL: PATH_CLOUDOON_EMAILS_DOMAIN_DETAIL,
  },
  SETUP: {
    ROOT: PATH_CLOUDOON_EMAILS_DOMAINS_SETUP,
    DOMAIN: PATH_CLOUDOON_EMAILS_DOMAIN_SETUP,
    VERIFICATION: PATH_CLOUDOON_EMAILS_DOMAIN_SETUP_VERIFICATION,
    CREATE_ACCOUNT: PATH_CLOUDOON_EMAILS_DOMAIN_SETUP_ACCOUNT,
    COMPLETED: PATH_CLOUDOON_EMAILS_DOMAIN_SETUP_COMPLETED,
  },
};

/**
 * Query Keys
 */
export const QUERY_KEYS = {
  DOMAINS: "CLOUDOON_EMAILS_DOMAINS",
  MX_RECORDS: "CLOUDOON_EMAILS_MX_RECORDS",
  OWNERSHIP: "CLOUDOON_EMAILS_OWNERSHIP",
  VERIFICATION_STATUS: "CLOUDOON_EMAILS_DOMAIN_VERIFICATION_STATUS",
  CREATE_ACCOUNT: "CLOUDOON_EMAILS_CREATE_ACCOUNT",
};

/**
 * Cloudoon Email Status
 */
export const DOMAIN_STATUSES = {
  PENDING: "pending",
  READY: "ready",
  ACTIVE: "active",
  SUSPENDED: "suspended",
  CANCELLED: "cancelled",
  TERMINATED: "terminated",
};
