import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import ManageContactStatus from "modules/marketing/components/contacts/ManageContactStatus";

function ManageContactStatusModal({ selectedContactsIds }) {
  const modalId = "change-contact-status-modal";
  return (
    <Modal modal_title="Change Status" modal_id={modalId} size="md">
      <ManageContactStatus
        selectedContactsIds={selectedContactsIds}
        currentModalId={modalId}
      />
    </Modal>
  );
}

ManageContactStatusModal.propTypes = {
  selectedContactsIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
};

export default ManageContactStatusModal;
