import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import Form from "modules/base/components/Form";
import CheckboxInput from "modules/base/components/inputs/CheckboxInput";
import SelectInput from "modules/base/components/inputs/SelectInput";
import TextInput from "modules/base/components/inputs/TextInput";
import { closeModalById } from "modules/base/utilities/Actions";
import { Field } from "modules/marketing/MarketingModels";

function ConfigureFormField(props) {
  const { field, setState, contactFields } = props;
  const [postToContactField, setPostToContactField] = useState(
    field.leadField !== null,
  );
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    closeModalById(`configure-form-field-${field.id}-modal`);
  });
  const onInputChange = useCallback(
    (e) => {
      const { name } = e.target;
      let { value } = e.target;
      if (e.target.type === "checkbox") {
        value = e.target.checked;
      }
      setState((prevState) => ({
        ...prevState,
        fields: prevState.fields.map((f) => {
          if (f.id === field.id) {
            return {
              ...f,
              [name]: value,
            };
          }
          return f;
        }),
      }));
    },
    [setState],
  );
  const togglePostToContactField = useCallback(() => {
    setPostToContactField((prevState) => !prevState);
    if (postToContactField) {
      setState((prevState) => ({
        ...prevState,
        fields: prevState.fields.map((f) => {
          if (f.id === field.id) {
            return {
              ...f,
              leadField: null,
              mappedObject: null,
              mappedField: null,
            };
          }
          return f;
        }),
      }));
    }
  });
  const onChangeLeadField = useCallback((selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      fields: prevState.fields.map((f) => {
        if (f.id === field.id) {
          return {
            ...f,
            leadField: selectedOption.value,
            mappedObject: "contact",
            mappedField: selectedOption.value,
          };
        }
        return f;
      }),
    }));
  });
  const contactFieldOptions = contactFields.map((f) => ({
    value: f.alias,
    label: f.label,
  }));
  const fieldTypes = [
    { value: "text", label: "Text", type: "text" },
    { value: "textarea", label: "Textarea", type: "text" },
    { value: "select", label: "Select", type: "text" },
    { value: "email", label: "Email", type: "email" },
    { value: "file", label: "File", type: "file" },
    { value: "date", label: "Date", type: "text" },
    { value: "datetime", label: "Date/Time", type: "text" },
    { value: "number", label: "Number", type: "number" },
    { value: "tel", label: "Phone", type: "text" },
    { value: "url", label: "URL", type: "text" },
    { value: "country", label: "Country List", type: "select" },
  ];
  const contactFieldSelect = (
    <SelectInput
      name="leadField"
      title="Contact field"
      options={contactFieldOptions}
      onChange={onChangeLeadField}
      predicate={(option) => option.value === field.leadField}
    />
  );
  return (
    <Form
      handleSubmit={handleSubmit}
      id={`form-field-${field.id}`}
      button_color="gray-900"
      button_label="Configure"
      fullLengthButton
      isSubmitting={false}
      use_as="div"
      padding="p-0"
    >
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <TextInput
          name="label"
          title="Label"
          content={field.label}
          controlFunc={onInputChange}
          required
        />
        <div className="text-start">
          <CheckboxInput
            name="isRequired"
            title="Required"
            checked={field.isRequired}
            handleChange={onInputChange}
          />
        </div>
        <SelectInput
          name="type"
          title="Type"
          options={fieldTypes}
          onChange={(selectedOption) => {
            onInputChange({
              target: {
                name: "type",
                value: selectedOption.value,
              },
            });
          }}
          predicate={(option) => option.value === field.type}
        />
        <div className="text-start">
          <CheckboxInput
            name="postToContactField"
            title="Post to contact field"
            checked={postToContactField}
            handleChange={togglePostToContactField}
          />
        </div>
        {postToContactField && contactFieldSelect}
      </div>
    </Form>
  );
}

ConfigureFormField.propTypes = {
  field: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  contactFields: PropTypes.arrayOf(PropTypes.instanceOf(Field)).isRequired,
};

export default ConfigureFormField;
