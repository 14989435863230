import BaseBillingDetails from "modules/base/components/billing/BaseBillingDetails";
import { BillingAPI } from "modules/billing/BillingAPIs";
import BillingDetailsForm from "modules/billing/components/BillingDetailsForm";

function BillingDetails() {
  const billingAPI = new BillingAPI();
  const { customer, error } = billingAPI.getBillingCustomer("i");
  return (
    <BaseBillingDetails
      customer={customer}
      error={error}
      BillingDetailsForm={BillingDetailsForm}
      edit_button_color="gray-900"
    />
  );
}

export default BillingDetails;
