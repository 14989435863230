export const HrBlock = (editor, opts = {}) => {
    const bm = editor.BlockManager;
    bm.add("bootstrap-hr", {
        label: `Divider`,
        activate: true,
        select: true,
        category: "Basic",
        content:' <hr>',
        media: '<i class="oicon-divider" style="font-size: 42px;"></i>'
   
    });
};