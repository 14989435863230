import PropTypes from "prop-types";
import BaseBillingAddonPurchases from "modules/base/components/billing/BaseBillingAddonPurchases";
import { BillingAPI } from "modules/billing/BillingAPIs";
import { Site } from "modules/website/WebsiteModels";

function BillingAddonPurchases(props) {
  const billingAPI = new BillingAPI();
  const { site } = props;
  const { addonPurchases, error, isLoading } =
    billingAPI.getBillingAddonPurchases(site?.id ?? null);
  return (
    <BaseBillingAddonPurchases
      addonPurchases={addonPurchases}
      error={error}
      isLoading={isLoading}
    />
  );
}

BillingAddonPurchases.defaultProps = {
  site: null,
};

BillingAddonPurchases.propTypes = {
  site: PropTypes.instanceOf(Site),
};

export default BillingAddonPurchases;
