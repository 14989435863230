import { InputType } from "../../../core/constants/Enumerators";
import { FormInput, LabeledFormInput } from "../../../core/models/FormInput";
import { PaymentMethod } from "../../models/PaymentMethod";
import { PAYMENT_METHODS_PROVIDERS } from "./PaymentMethodProviders";

/**
 * @name PaymentMethodsInputs
 * @param payment_method
 * @param handleToggleProvider
 * @returns {[LabeledFormInput,FormInput,FormInput,LabeledFormInput,FormInput]}
 * @constructor
 */
export default function PaymentMethodsInputs(
  payment_method: PaymentMethod,
  handleToggleProvider,
) {
  return [
    new LabeledFormInput({
      type: InputType.select,
      name: "provider",
      label: "Provider",
      value: payment_method.provider,
      is_required: true,
      is_editable: true,
      oninputChange: handleToggleProvider,
      options: PAYMENT_METHODS_PROVIDERS,
    }),
    new FormInput(
      InputType.text,
      "name",
      "Name",
      "Name of the payment method",
      payment_method.name,
      true,
      true,
    ),
    new FormInput(
      InputType.textarea,
      "description",
      "Description",
      "Description of the payment method",
      payment_method.description,
      true,
      true,
    ),
    new LabeledFormInput({
      type: InputType.select,
      name: "display_on",
      label: "Display on",
      value: payment_method.display_on,
      is_required: false,
      is_editable: true,
      options: [
        {
          label: "Both",
          value: "both",
        },
        {
          label: "Store front",
          value: "front_end",
        },
        {
          label: "Back end",
          value: "back_end",
        },
      ],
    }),
    new FormInput(
      InputType.checkbox,
      "active",
      "Enabled",
      "Enable the payment method",
      payment_method.active,
      false,
      true,
    ),
  ];
}
