import juice from "juice";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AuthenticationActions } from "modules/account/AccountActions";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import { closeModalById } from "modules/base/utilities/Actions";
import Editor from "modules/core/components/Editor";
import { canvasCss } from "modules/core/components/editor/assets";

import SiteApi from "modules/website/api/SiteApi";

function replaceTemplateForm(state) {
  const templateHtml = state.html;
  let { formHtml } = state;
  if (formHtml) {
    formHtml = formHtml
      .replace(/\\n/g, "")
      .replace(/\\"/g, '"')
      .replace(/\\'/g, "'");
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(templateHtml, "text/html");

  const newFormFragment = parser.parseFromString(formHtml, "text/html");
  // Extract and move new form's styles to the beginning of the head to give priority to template styles
  const newFormStyles = newFormFragment.querySelectorAll("style");
  const firstStyleTag = doc.querySelector("style");
  const firstScriptTag = doc.querySelector("script");

  newFormStyles.forEach((style) => {
    if (firstStyleTag) {
      firstStyleTag.parentNode.insertBefore(
        style.cloneNode(true),
        firstStyleTag,
      );
    } else if (firstScriptTag) {
      firstScriptTag.parentNode.insertBefore(
        style.cloneNode(true),
        firstScriptTag,
      );
    } else {
      doc.body.insertAdjacentElement("afterbegin", style.cloneNode(true));
    }
  });

  const newFormElement = newFormFragment.querySelector("form");

  const oldFormElement = doc.querySelector("form");

  if (oldFormElement && newFormElement) {
    oldFormElement.replaceWith(newFormElement);
  }
  return new XMLSerializer().serializeToString(doc);
}

function DesignFocusItemTemplate({ state, setState, match, currentModalId }) {
  const { id: siteId } = match.params;
  const [site, setSite] = useState(null);
  const [error, setError] = useState(null);
  const siteApi = new SiteApi();
  const [loadCanvas, setLoadCanvas] = useState(false);
  const [isLoadingCanvas, setIsLoadingCanvas] = useState(false);
  const modalElement = document.getElementById(currentModalId);
  useEffect(() => {
    if (modalElement) {
      modalElement.addEventListener("show.bs.modal", () => {
        setIsLoadingCanvas(true);
      });
      modalElement.addEventListener("shown.bs.modal", () => {
        setLoadCanvas(true);
        setIsLoadingCanvas(false);
      });
      modalElement.addEventListener("hidden.bs.modal", () => {
        setLoadCanvas(false);
      });
    }
    siteApi.retrieveWebsite(
      siteId,
      (siteDetails) => {
        setSite(siteDetails);
      },
      (e) => {
        setError(e);
      },
    );
  }, [modalElement]);

  let page_content = state.html;
  if (state.type === "form") {
    page_content = replaceTemplateForm(state);
  } else if (state.type === "notification") {
    page_content = state.notificationHtml;
  } else if (state.type === "link") {
    page_content = state.linkHtml;
  }

  const template = {
    id: state.id ?? "new",
    page_title: state.name ?? "New Engagement Item Template",
    page_content,
  };
  const authenticationActions: any = new AuthenticationActions();
  const user: any = authenticationActions.retrieveAuthenticatedUser();

  function appendCanvasCSS(pageContent) {
    const cssData = canvasCss.map((url) => {
      return `<link rel="stylesheet" href="${url}" type="text/css" />`;
    });
    const css = cssData.join("\n");
    if (pageContent.includes("</head>")) {
      return pageContent.replace("</head>", `${css}</head>`);
    }
    return `${pageContent}${css}`;
  }

  const handleSavePageContent = (
    editorInstance,
    autoSaveEnabled,
    callback,
    from_auto_save = false,
  ) => {
    if (autoSaveEnabled || !from_auto_save) {
      const html: any = editorInstance.getHtml();
      const cssData = editorInstance.getCss();
      let pageContent = juice(html, { extraCss: cssData });
      pageContent = appendCanvasCSS(pageContent);
      const data = {
        html: pageContent,
      };
      if (state.type === "form") {
        data.formTemplateHtml = pageContent;
      } else if (state.type === "notice") {
        data.notificationHtml = pageContent;
      } else if (state.type === "link") {
        data.linkHtml = pageContent;
      }
      setState((prev) => ({ ...prev, ...data }));
      callback();
      closeModalById(currentModalId);
    }
  };
  if (!loadCanvas) {
    return null;
  }
  if (isLoadingCanvas) {
    return <NetworkActivityIndicator />;
  }
  const exclude_panel_buttons: Array = [
    {
      model: "options",
      id: "export-template",
    },
    {
      model: "options",
      id: "visit-site",
    },
    {
      model: "options",
      id: "publish-website",
    },
    {
      model: "options",
      id: "auto-save-enabled",
    },
    {
      model: "options",
      id: "pages-selector",
    },
    {
      model: "options",
      id: "open-account-dropdown",
    },
    {
      model: "home",
      id: "open-dashboard",
    },
    {
      model: "home",
      id: "upgrade",
    },
  ];

  return (
    <>
      <NetworkMessageDisplay error={error} />
      {site && (
        <Editor
          canvasId={`engagement-item-template-${
            template.id ?? uuidv4()
          }-editor`}
          isNewsLetter
          components_prefix="focus-item-template"
          user={user}
          handleGoHome={() => {}}
          handleVisitSite={() => {}}
          website={site}
          page={template}
          handleSavePageContent={handleSavePageContent}
          handleTogglePublishWebsite={() => {}}
          loadCanvas={loadCanvas}
          exclude_panel_buttons={exclude_panel_buttons}
          includeCanvasCSS
          includeCanvasJS
          allowComponentsResize
          allowComponentsDrag
          allowComponentsDrop
          allowComponentsStyling
          allowComponentsCopy
          allowComponentsDelete
          allowChangeComponentsBackground
          allowComponentsBadge
          allowComponentsHighlight
          allowImageEditing
          allowImageAddLink
          trackComponentsChanges
          isInsideModal
          currentModalId={currentModalId}
          removeFormCaptcha
        />
      )}
    </>
  );
}

DesignFocusItemTemplate.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string.isRequired,
};

export default withRouter(DesignFocusItemTemplate);
