import PropTypes from "prop-types";
import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

function Tooltip({ id, content, children, placement, effect, tooltipStyling }) {
  return (
    <>
      <span data-tooltip-id={id}>{children}</span>
      <ReactTooltip
        id={id}
        place={placement}
        effect={effect}
        className={tooltipStyling}
      >
        {content}
      </ReactTooltip>
    </>
  );
}

Tooltip.defaultProps = {
  placement: "top",
  effect: "solid",
  tooltipStyling: "",
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
  effect: PropTypes.string,
  tooltipStyling: PropTypes.string,
};

export default Tooltip;
