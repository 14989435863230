import _ from "lodash";
import userSWR, { mutate } from "swr";
import { AuthenticationActions } from "modules/account/AccountActions";
import {
  PATH_LOGIN,
  URL_REFRESH_TOKEN,
} from "modules/account/AccountConstants";
import { AuthenticationTokens } from "modules/account/AccountModels";

// eslint-disable-next-line import/prefer-default-export
export class NetworkRequests {
  headers = null;

  multipartHeaders = null;

  format_json_error = true;

  constructor() {
    this.authenticationActions_ = new AuthenticationActions();
  }

  /**
   * Fetches a new set of authentication tokens using the stored refresh token.
   *     On success the callback is called with updated headers as parameter.
   *     On failure the user is redirected to the login page.
   *     The 'postAPIRequest' cannot be reused here to prevent an infinite loop.
   * @param {function} callback The networking function that needs authentication.
   * @param {object} headers The headers associated with the callback.
   */
  refreshTokenRequest = (callback, headers) => {
    const tokens: any =
      this.authenticationActions_.retrieveAuthenticationTokens();
    const options: any = {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ refresh: tokens.refresh_token }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(URL_REFRESH_TOKEN, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((json_tokens) => {
        const new_tokens: any = new AuthenticationTokens(json_tokens);
        this.authenticationActions_.storeAuthenticationTokens(new_tokens);
        headers.Authorization = `Bearer ${new_tokens.access_token}`;
        return callback(headers);
      })
      .catch((error) => {
        const path: string = `${PATH_LOGIN}?next=${window.location.pathname}`;
        return window.open(path, "_self");
      });
  };

  /**
   * @name extractResponseError
   * @description Extracts the error message from the response.
   * @param results
   * @param append_field
   * @param include_suggestion
   * @returns {string}
   */
  extractResponseError = (
    results,
    append_field: true,
    include_suggestion = false,
  ) => {
    let alert_message: string = "";
    let suggestion: string =
      "Kindly retry after some time. If the problem persists, please contact us for additional support.";
    const error_response: any = results;
    if (error_response.constructor === Array && error_response.length > 0) {
      [alert_message] = error_response;
    } else if (typeof error_response === "object") {
      if (error_response.detail) {
        alert_message = error_response.detail;
      } else if (error_response.error) {
        alert_message = error_response.error;
      } else if (error_response.non_field_errors) {
        alert_message = error_response.non_field_errors;
      } else if (Object.keys(error_response).length > 0) {
        for (const key in error_response) {
          if (Object.prototype.hasOwnProperty.call(error_response, key)) {
            if (key === "suggestion") {
              [suggestion] = error_response[key];
            } else if (append_field) {
              alert_message += `${key}: ${error_response[key]} `;
            } else {
              alert_message += `${error_response[key]} `;
            }
          }
        }
      } else {
        alert_message = results.toString();
      }
    } else if (typeof results === "string") {
      alert_message = results;
    }
    if (alert_message.constructor === Array && alert_message.length === 1) {
      [alert_message] = alert_message;
    }
    if (include_suggestion) {
      return { message: alert_message, suggestion };
    }
    return alert_message;
  };

  /**
   * Processes a message object and returns it as a string.
   *
   * @param {Object} message - The message to process.
   * This function will iterate over its keys and values to construct a string.
   *
   * @returns {string} The processed message as a string.
   */
  processObjectMessage(message) {
    if (typeof message === "string") {
      return message;
    }
    let alert_message = "";
    if (Array.isArray(message)) {
      message.forEach((error_item) => {
        alert_message += this.processObjectMessage(error_item);
      });
      return alert_message;
    }
    Object.keys(message).forEach((key) => {
      if (typeof message[key] === "object") {
        alert_message += `${key}: ${this.processObjectMessage(message[key])} `;
        return;
      }
      let error_key = key.replace("_", " ");
      error_key = _.capitalize(error_key);
      alert_message += `${error_key}: ${message[key]} `;
    });
    return alert_message;
  }

  /**
   * Process the error response
   * @param response
   * @returns {*}
   */
  processError(response) {
    if (response.status === 401) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      window.dispatchEvent(new Event("localStorageChange"));
    }
    return response.json().then((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else if (error.detail) {
        throw new Error(error.detail);
      } else if (error.non_field_errors) {
        throw new Error(error.non_field_errors);
      } else if (Array.isArray(error)) {
        if (this.format_json_error) {
          let error_message = "";
          error.forEach((error_item) => {
            error_message += `${error_item} `;
          });
          throw new Error(error_message);
        } else {
          throw new Error(error);
        }
      } else if (typeof error === "object") {
        if (this.format_json_error) {
          const error_message = this.processObjectMessage(error);
          throw new Error(error_message);
        } else {
          throw new Error(error);
        }
      } else {
        throw new Error(response.statusText);
      }
    });
  }

  /**
   * @name catchError
   * @description Catches errors and calls the appropriate callback.
   * @param error
   * @param errorCallback
   */
  catchError = (error, errorCallback) => {
    if (process.env.NODE_ENV === "development" && error.status >= 500) {
      errorCallback(error.statusText);
    } else {
      try {
        error
          .json()
          .then((body) =>
            errorCallback({ detail: this.extractResponseError(body) }),
          );
      } catch (e) {
        const msg: string =
          "We encountered an error. We noticed and are working on it. Kindly retry after some time or contact us for additional support.";
        errorCallback({ detail: msg });
        // ToDo: Contact admin
      }
    }
  };

  /**
   * Performs a post network request.
   *     On success the {{successCallback}} is executed.
   *     On failure the {{errorCallback}} is executed.
   * @param {string} url The api endpoint.
   * @param {function} successCallback Function to be executed on successful request.
   * @param {function} errorCallback Function to be executed on failed request.
   * @param {object} payload Parameters to attach to the request.
   * @param {object} headers Headers to attach to the request.
   * @param {string} method The method to use. Eg: POST, PUT, PATCH, ...
   */
  postAPIRequest(
    url,
    successCallback,
    errorCallback,
    payload,
    headers,
    method = "POST",
  ) {
    const options: any = {
      method,
      body: JSON.stringify(payload),
      headers,
    };
    fetch(url, options)
      .then((response) => {
        if (response.status === 401) {
          const callback: any = () =>
            this.postAPIRequest(
              url,
              successCallback,
              errorCallback,
              payload,
              headers,
              method,
            );
          this.refreshTokenRequest(callback, headers);
        } else if (response.ok) {
          return response.json();
        } else {
          throw response;
        }
        return false;
      })
      .then((json) => {
        if (typeof json !== "undefined") {
          return successCallback(json);
        }
        return false;
      })
      .catch((error) => {
        this.catchError(error, errorCallback);
      });
  }

  /**
   * Converts a JavaScript object into a FormData instance.
   * If the payload is already a FormData instance, it is returned as is.
   *
   * @param {object | FormData} payload - The data to convert into FormData.
   * This can be a plain JavaScript object or an existing FormData instance.
   * If it's a JavaScript object, each key-value pair in the object will be appended to the FormData.
   * If the value of a key is null, that key will not be appended to the FormData.
   *
   * @returns {FormData} The resulting FormData instance.
   */
  objectToFormData(payload) {
    let formData: any = new FormData();
    if (typeof payload === "object" && !(payload instanceof FormData)) {
      Object.keys(payload).forEach((key) => {
        if (payload[key] !== null) {
          formData.append(key, payload[key]);
        }
      });
    } else {
      formData = payload;
    }
    return formData;
  }

  /**
   * Performs a post network request.
   *     On success the {{successCallback}} is executed.
   *     On failure the {{errorCallback}} is executed.
   * @param {string} url The api endpoint.
   * @param {function} successCallback Function to be executed on successful request.
   * @param {function} errorCallback Function to be executed on failed request.
   * @param {object} payload The form data to attach to the request.
   * @param {object} headers Headers to attach to the request.
   * @param {string} method The method to use. Eg: POST, PUT, PATCH, ...
   */
  postFormAPIRequest(
    url,
    successCallback,
    errorCallback,
    payload,
    headers,
    method = "POST",
  ) {
    const formData = this.objectToFormData(payload);
    const option: { method: any, body: any, headers: any } = {
      method,
      body: formData,
      headers,
    };
    fetch(url, option)
      .then((response) => {
        if (response.status === 401) {
          const callback: any = () =>
            this.postFormAPIRequest(
              url,
              successCallback,
              errorCallback,
              payload,
              headers,
              method,
            );
          this.refreshTokenRequest(callback, headers);
        } else if (response.ok) {
          return response.json();
        } else {
          throw response;
        }
        return false;
      })
      .then((json) => {
        if (typeof json !== "undefined") {
          return successCallback(json);
        }
        return false;
      })
      .catch((error) => {
        this.catchError(error, errorCallback);
      });
  }

  /**
   * Performs a get network request.
   *     On success the {{successCallback}} is executed.
   *     On failure the {{errorCallback}} is executed.
   * @param {string} url The api endpoint.
   * @param {function} successCallback Function to be executed on successful request.
   * @param {function} errorCallback Function to be executed on failed request.
   * @param {object} headers Headers to attach to the request.
   * @param {boolean} deserialize Determines whether to convert the response to json or not.
   */
  getAPIRequest(
    url,
    successCallback,
    errorCallback,
    headers,
    deserialize = true,
  ) {
    const options: { headers: any } = {
      headers,
    };
    fetch(url, options)
      .then((response) => {
        if (response.status === 401) {
          const callback: any = () =>
            this.getAPIRequest(
              url,
              successCallback,
              errorCallback,
              headers,
              deserialize,
            );
          this.refreshTokenRequest(callback, headers);
        } else if (response.ok) {
          if (deserialize) {
            return response.json();
          }
          return response.text();
        }
        throw response;
      })
      .then((json) => {
        if (typeof json !== "undefined") {
          return successCallback(json);
        }
        return false;
      })
      .catch((error) => {
        try {
          error
            .json()
            .then((body) => {
              const detail: string = this.extractResponseError(body);
              const { message, suggestion }: string = this.extractResponseError(
                body,
                false,
                true,
              );
              errorCallback({
                detail,
                short_desc: message,
                suggestion,
              });
            })
            .catch(() => {
              const detail: string =
                "We encountered an error. We noticed and are working on it. Kindly retry after some time or contact us for additional support.";
              const short_desc: string = "We encountered an error.";
              const suggestion: string =
                "Kindly retry after some time. If the problem persists, please contact us for additional support.";
              errorCallback({ detail, short_desc, suggestion });
            });
        } catch (e) {
          const detail: string =
            "We encountered an error. We noticed and are working on it. Kindly retry after some time or contact us for additional support.";
          const short_desc: string = "We encountered an error.";
          const suggestion: string =
            "Kindly retry after some time. If the problem persists, please contact us for additional support.";
          errorCallback({ detail, short_desc, suggestion });
          // ToDo: Contact admin
        }
      });
  }

  /**
   * Performs a deleted network request.
   *     On success the {{successCallback}} is executed.
   *     On failure the {{errorCallback}} is executed.
   * @param {string} url The api endpoint.
   * @param {function} successCallback Function to be executed on successful request.
   * @param {function} errorCallback Function to be executed on failed request.
   * @param {object} headers Headers to attach to the request.
   */
  deleteAPIRequest(url, successCallback, errorCallback, headers) {
    const options: { method: string, headers: any } = {
      method: "DELETE",
      headers,
    };
    fetch(url, options)
      .then((response) => {
        if (response.status === 401) {
          const callback: any = () =>
            this.deleteAPIRequest(url, successCallback, errorCallback, headers);
          this.refreshTokenRequest(callback, headers);
        } else if (response.ok) {
          return true;
        } else {
          throw response;
        }
        return false;
      })
      .then((is_deleted) => {
        return successCallback({ is_deleted });
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development" && error.status >= 500) {
          errorCallback(error.statusText);
        } else {
          error.json().then((body) => errorCallback(body));
        }
      });
  }

  /**
   * Performs a POST request and handles the response.
   *
   * @param {string} url - The URL to send the request to.
   * @param {object} request - The request object containing method, headers, and body.
   * @param {function} successCallback - The function to call on successful response.
   * @param {function} errorCallback - The function to call on error.
   * @param {array} revalidate_urls - The URLs to revalidate after successful response (default: []).
   */
  basePostRequest(
    url,
    request,
    successCallback,
    errorCallback,
    revalidate_urls = [],
  ) {
    fetch(url, request)
      .then((response) => {
        if (response.ok) {
          if (response.status === 204) {
            return {};
          }
          return response.json();
        }
        if (response.status >= 500) {
          throw new Error("That's on us. Please try again.");
        }
        return this.processError(response);
      })
      .then((response_data) => {
        mutate(url);
        revalidate_urls.forEach((revalidate_url) => {
          mutate(revalidate_url);
        });
        successCallback(response_data);
      })
      .catch((error) => {
        errorCallback(error);
      });
  }

  /**
   * Make a POST request with JSON data
   * @param {string} url - The URL to send the request to
   * @param {object} data - The data to send as JSON
   * @param {string} method - The HTTP method to use (default: "POST")
   * @param {function} successCallback - The function to call on successful response
   * @param {function} errorCallback - The function to call on error
   * @param {array} revalidate_urls - The URLs to revalidate after successful response
   */
  postRequest(
    url,
    data,
    successCallback,
    errorCallback,
    method = "POST",
    revalidate_urls = [],
  ) {
    const request = {
      method,
      headers: this.headers,
      body: JSON.stringify(data),
    };

    this.basePostRequest(
      url,
      request,
      successCallback,
      errorCallback,
      revalidate_urls,
    );
  }

  /**
   * Performs a POST request with form data that may include files.
   *
   * @param {string} url - The URL to send the request to.
   * @param {object | FormData} data - The form data to send. This can include files.
   * @param {function} successCallback - The function to call on successful response.
   * @param {function} errorCallback - The function to call on error.
   * @param {string} method - The HTTP method to use (default: "POST").
   * @param {array} revalidate_urls - The URLs to revalidate after successful response (default: []).
   */
  postFormWithFileRequest(
    url,
    data,
    successCallback,
    errorCallback,
    method = "POST",
    revalidate_urls = [],
  ) {
    const formData = this.objectToFormData(data);
    const request = {
      method,
      headers: this.multipartHeaders,
      body: formData,
    };

    this.basePostRequest(
      url,
      request,
      successCallback,
      errorCallback,
      revalidate_urls,
    );
  }

  /**
   * Make a GET request
   * @param {string} url - The URL to send the request to
   * @param {number} refreshInterval - The interval in milliseconds to refresh the data
   */
  getRequest(url, refreshInterval = 0) {
    const { headers } = this;
    return userSWR(
      url,
      (url_) => {
        return fetch(url_, {
          headers,
        }).then((response) => {
          if (response.ok) {
            return response.json();
          }
          if (response.status >= 500) {
            throw new Error("That's on us. Please try again.");
          }
          return this.processError(response);
        });
      },
      { refreshInterval },
    );
  }

  /**
   * Make a DELETE request
   * @param {string} url - The URL to send the request to
   * @param {function} successCallback - The function to call on successful response
   * @param {function} errorCallback - The function to call on error
   * @param {array} revalidate_urls - The URLs to revalidate after successful response
   */
  deleteRequest(url, successCallback, errorCallback, revalidate_urls = []) {
    fetch(url, {
      method: "DELETE",
      headers: this.headers,
    })
      .then((response) => {
        if (response.ok) {
          if (response.status === 204) {
            return {};
          }
          return response.json();
        }
        if (response.status === 404) {
          throw new Error("Not Found");
        }
        if (response.status >= 500) {
          throw new Error("That's on us. Please try again.");
        }
        return this.processError(response);
      })
      .then((response_data) => {
        mutate(url);
        revalidate_urls.forEach((revalidate_url) => {
          mutate(revalidate_url);
        });
        successCallback(response_data);
      })
      .catch((error) => {
        errorCallback(error);
      });
  }
}
