import { createColumnHelper } from "@tanstack/react-table";
import PropTypes from "prop-types";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import { BillingWallet } from "modules/base/models/Billing";
import { formatDate, getMoneyFormat } from "modules/base/utilities/Actions";
import {
  formatBillingTransactionState,
  formatBillingTransactionType,
} from "modules/base/utilities/RenderActions";

function BaseWalletTransactions(props) {
  const { transactions, isLoading, error, wallet } = props;
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("amount", {
      header: "Amount",
      cell: (info) => getMoneyFormat(info.renderValue(), wallet.currency),
    }),
    columnHelper.accessor("created_at", {
      header: "Date",
      cell: (info) => formatDate(info.renderValue(), "ll"),
    }),
    columnHelper.accessor("credit_amount", {
      header: "Credit Amount",
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => formatBillingTransactionState(info.renderValue()),
    }),
    columnHelper.accessor("transaction_type", {
      header: "Transaction Type",
      cell: (info) => formatBillingTransactionType(info.renderValue()),
    }),
  ];

  return (
    <>
      <NetworkMessageDisplay error={error} />
      <DataTable columns={columns} data={transactions} isLoading={isLoading} />
    </>
  );
}

BaseWalletTransactions.propTypes = {
  transactions: PropTypes.instanceOf(Array),
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  wallet: PropTypes.instanceOf(BillingWallet).isRequired,
};

BaseWalletTransactions.defaultProps = {
  transactions: [],
  error: null,
};

export default BaseWalletTransactions;
