import PropTypes from "prop-types";
import { useState } from "react";
import { useAuth } from "../../../AuthProvider";
import { AuthenticationActions } from "modules/account/AccountActions";
import "regenerator-runtime/runtime";
import { AuthenticationApi } from "modules/account/AccountAPIs";
import {
  GENERIC_SOCIAL_LOGIN_ERROR,
  GOOGLE_LOGIN_ACCESS_DENIED_ERROR,
  GOOGLE_LOGIN_API_INITIALIZATION_ERROR,
  GOOGLE_LOGIN_POPUP_CLOSED_ERROR,
  SOCIAL_LOGIN_HELP_URL,
} from "modules/account/AccountConstants";
import GoogleLoginButton from "modules/account/components/GoogleLoginButton";
import { OlittAppleLoginButton } from "modules/account/components/OlittAppleLoginButton";
import { ErrorModal } from "modules/core/components/ErrorModal";
import { navigateToPage } from "modules/core/utilities/navigation/Navigator";

export default function SocialAuthentication({ onSuccess }) {
  const [logging_in_with_apple, setLoggingInWithApple] = useState(false);
  const [logging_in_with_google, setLoggingInWithGoogle] = useState(false);
  const [error, setError] = useState(null);
  const auth = useAuth();

  function googleLoginCallback(callback_is_successful, token_or_error) {
    if (callback_is_successful) {
      setLoggingInWithGoogle(true);
      const api: any = new AuthenticationApi();
      api.loginWithGoogle(token_or_error, (is_successful, tokens_or_error) => {
        if (is_successful) {
          const accountActions: any = new AuthenticationActions();
          accountActions.storeAuthenticationTokens(tokens_or_error).then(() => {
            auth.login(tokens_or_error, () => {
              setLoggingInWithGoogle(false);
              onSuccess();
            });
          });
        } else {
          const login_error: { short_desc: string, suggestion: any } = {
            short_desc: "Something went wrong while authenticating you.",
            suggestion: tokens_or_error,
          };
          setError(login_error);
        }
      });
    } else if (token_or_error === "idpiframe_initialization_failed") {
      setError(GOOGLE_LOGIN_API_INITIALIZATION_ERROR);
    } else if (token_or_error === "popup_closed_by_user") {
      setError(GOOGLE_LOGIN_POPUP_CLOSED_ERROR);
    } else if (token_or_error === "access_denied") {
      setError(GOOGLE_LOGIN_ACCESS_DENIED_ERROR);
    } else if (token_or_error === "immediate_failed") {
      setError(GENERIC_SOCIAL_LOGIN_ERROR);
    } else {
      setError(GENERIC_SOCIAL_LOGIN_ERROR);
    }
  }

  function appleLoginCallback(callback_is_successful, token_or_error) {
    if (callback_is_successful) {
      setLoggingInWithApple(true);
      const api: any = new AuthenticationApi();
      api.loginWithApple(token_or_error, (is_successful, tokens_or_error) => {
        if (is_successful) {
          const accountActions: any = new AuthenticationActions();
          accountActions.storeAuthenticationTokens(tokens_or_error).then(() => {
            auth.login(tokens_or_error, () => {
              setLoggingInWithApple(false);
              onSuccess();
            });
          });
        } else {
          const login_error: { short_desc: string, suggestion: any } = {
            short_desc: "Something went wrong while authenticating you.",
            suggestion: tokens_or_error,
          };
          setError(login_error);
        }
      });
    } else if (token_or_error === "idpiframe_initialization_failed") {
      setError(GOOGLE_LOGIN_API_INITIALIZATION_ERROR);
    } else if (token_or_error === "popup_closed_by_user") {
      setError(GOOGLE_LOGIN_POPUP_CLOSED_ERROR);
    } else if (token_or_error === "access_denied") {
      setError(GOOGLE_LOGIN_ACCESS_DENIED_ERROR);
    } else if (token_or_error === "immediate_failed") {
      setError(GENERIC_SOCIAL_LOGIN_ERROR);
    } else {
      setError(GENERIC_SOCIAL_LOGIN_ERROR);
    }
  }

  const cancelError: any = () => setError(null);

  const navigateToHelp: any = () => {
    navigateToPage(window.open(SOCIAL_LOGIN_HELP_URL, "_self"));
  };

  const close_error_modal_button: any = (
    <button
      type="button"
      className="btn btn-outline-olitt-grey px-4"
      onClick={cancelError}
    >
      Close
    </button>
  );

  const navigate_to_help_modal_button: any = (
    <button
      type="button"
      className="btn btn-olitt-grey px-4"
      onClick={navigateToHelp}
    >
      Help
    </button>
  );

  return (
    <div className="d-grid gap-2">
      <GoogleLoginButton
        callback={googleLoginCallback}
        setError={setError}
        setAuthenticating={setLoggingInWithGoogle}
        is_authenticating={logging_in_with_google}
      />
      <OlittAppleLoginButton
        is_logging_in={logging_in_with_apple}
        client_id="com.olitt.sitebuilder.oauth.apple"
        callback={appleLoginCallback}
      />

      <ErrorModal
        show={error !== null}
        onClose={cancelError}
        short_desc={error ? error.short_desc : ""}
        suggestion={error ? error.suggestion : ""}
        positive_button={navigate_to_help_modal_button}
        negative_button={close_error_modal_button}
      />
    </div>
  );
}

SocialAuthentication.propTypes = { onSuccess: PropTypes.func.isRequired };
