import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import {
  closeModalById,
  deleteNullProperties,
  handleInputChange,
} from "modules/base/utilities/Actions";
import {
  processFormActions,
  processFormFields,
} from "modules/marketing/Actions";
import FormFields from "modules/marketing/components/forms/FormFields";
import MarketingAPI from "modules/marketing/MarketingAPI";

function EditForm({ form, currentModalId, match }) {
  const { id: siteId } = match.params;
  const [state, setState] = useState(form);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const API = new MarketingAPI(siteId);
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let { fields, actions } = state;
    fields = processFormFields(fields);
    actions = processFormActions(actions);
    state.fields = fields;
    state.actions = actions;
    API.updateForm(
      form.id,
      deleteNullProperties(state),
      (response) => {
        setIsSubmitting(false);
        setError(false);
        setState(response);
        setSuccess({
          message: "Form updated successfully",
        });
        closeModalById(currentModalId);
      },
      (error_response) => {
        setIsSubmitting(false);
        setSuccess(false);
        setError({
          message: error_response.message,
        });
      },
    );
  });
  const onInputChange = useCallback((e) =>
    handleInputChange(e.target.name, e.target.value, state, setState),
  );
  return (
    <Form
      handleSubmit={handleSubmit}
      id={`edit-form-${form.id}-form`}
      button_color="gray-900"
      button_label="Update"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      <FormFields
        state={state}
        setState={setState}
        onInputChange={onInputChange}
        currentModalId={currentModalId}
      />
    </Form>
  );
}

EditForm.defaultProps = {
  currentModalId: null,
};

EditForm.propTypes = {
  form: PropTypes.instanceOf(Form).isRequired,
  currentModalId: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(EditForm);
