import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import { openModal } from "modules/base/utilities/Actions";
import { BillingAPI } from "modules/billing/BillingAPIs";
import {
  BILLING_PLANS_DATA,
  CHECK_PAID_SITE_DATA,
  CHECK_PAID_SITE_URL,
  URL_PLANS,
} from "modules/billing/BillingConstants";
import { BillingPlan } from "modules/billing/BillingModels";
import ComponentLoadingIndicator from "modules/core/components/ComponentLoadingIndicator";
import SidebarWrapper from "modules/core/components/SidebarWrapper";
import { navigateToPage } from "modules/core/utilities/navigation/Navigator";
import { invalidateData } from "modules/core/utilities/redux/actions/actions";
import {
  fetchUrlData,
  getUrlData,
} from "modules/core/utilities/redux/componentActions";
import BillingPlansModal from "modules/website/components/BillingPlansModal";
import TemplateSidebar from "modules/website/components/SidebarTemplate";
import TemplateSearch from "modules/website/components/TemplateSearch";
import WebsiteCard from "modules/website/components/WebsiteCard";
import {
  NO_OF_TOP_TEMPLATES_LIMIT,
  PATH_WEBSITE_NEW,
  TEMPLATE_DATA,
  URL_WEBSITES,
  URL_WEBSITES_TEMPLATES,
  WEBSITE_DATA,
} from "modules/website/WebsiteConstants";
import { Site } from "modules/website/WebsiteModels";

/**
 * Website Template Layout
 */
const WebsiteTemplates = memo(function WebsiteTemplates(props) {
  const {
    dispatch,
    is_loading_templates,
    websites,
    free_plan,
    location,
    templates,
  } = props;

  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const billingPlansModalId = "billing_plans_modal";

  const billingAPI = new BillingAPI();
  const { customer, error: billingCustomerError } =
    billingAPI.getBillingCustomer("i");
  const template_params = new URLSearchParams(location.search);
  const template_category = template_params.get("category");
  const is_new_shop = template_params.get("is_shop");

  useEffect(() => {
    const template_site_fields =
      "id,category,desktop_thumbnail,is_published,is_shop,name,title,subscription_plan,olitt_domain,description";
    dispatch(invalidateData(`${CHECK_PAID_SITE_URL}`));
    dispatch(
      invalidateData(
        `${URL_WEBSITES_TEMPLATES}?fields=${template_site_fields}`,
      ),
    );
    dispatch(invalidateData(`${URL_WEBSITES}`));
    dispatch(invalidateData(`${URL_PLANS}`));

    fetchUrlData(CHECK_PAID_SITE_DATA, `${CHECK_PAID_SITE_URL}`, props);
    fetchUrlData(
      TEMPLATE_DATA,
      `${URL_WEBSITES_TEMPLATES}?fields=${template_site_fields}`,
      props,
    );
    fetchUrlData(WEBSITE_DATA, `${URL_WEBSITES}`, props);
    fetchUrlData(BILLING_PLANS_DATA, `${URL_PLANS}`, props);
  }, []);

  /**
   * on Select Template
   * @param {*} template_id
   */
  const onSelectTemplate = useCallback((template_id) => {
    setSelectedTemplateId(template_id);
    openModal(billingPlansModalId);
  }, []);

  const handleSelectedPlan = (plan, cycle) => {
    const path = `${PATH_WEBSITE_NEW.replace(
      ":template_id",
      selectedTemplateId,
    )}?billing_plan=${plan}&billing_cycle=${cycle}`;
    const canCreateWebsite =
      customer.has_minimum_cards_allowed ||
      (free_plan && websites.length < free_plan.no_of_sites);

    if (canCreateWebsite) {
      navigateToPage(path, props);
    } else {
      openModal("add-card", billingPlansModalId);
    }
  };

  const handleSearchResults = useCallback((results, query) => {
    setSearchResults(results);
    setSearchQuery(query);
  }, []);

  const { templatesToDisplay } = useMemo(() => {
    if (is_loading_templates || templates.length === 0) {
      return { category_templates: [], templatesToDisplay: [] };
    }

    let filtered_templates = templates;
    if (template_category) {
      filtered_templates = templates.filter(
        (template) => template.category === template_category,
      );
    } else if (NO_OF_TOP_TEMPLATES_LIMIT > 0) {
      filtered_templates = templates.slice(0, NO_OF_TOP_TEMPLATES_LIMIT);
    }

    if ([true, "true"].includes(is_new_shop)) {
      filtered_templates = filtered_templates.filter(
        (template) => template.is_shop === true,
      );
    }

    const displayTemplates = searchQuery ? searchResults : filtered_templates;

    return {
      category_templates: filtered_templates,
      templatesToDisplay: displayTemplates,
    };
  }, [
    templates,
    is_loading_templates,
    searchQuery,
    searchResults,
    location.search,
  ]);

  if (is_loading_templates || templates.length === 0) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <ComponentLoadingIndicator />
      </div>
    );
  }

  const template_cards = templatesToDisplay.map((template) => {
    const template_subscription_plan = template.subscription_plan;
    let action_text = "Create your site";
    if (template.is_shop === true) {
      action_text = "Create your shop";
    }
    const over_image_action = {
      className: "btn btn-outline-primary mx-md-auto",
      onClick: () => onSelectTemplate(template.id),
      text: action_text,
      icon: "plus-lg",
    };
    let is_premium = false;
    if (template_subscription_plan.pricing.amount > 0) {
      is_premium = true;
    }

    return (
      <WebsiteCard
        key={template.id}
        website={template}
        over_image_action={over_image_action}
        showPreview
        view_mode="templates"
        is_premium={is_premium}
        image_width={800}
        is_share_site_visible={false}
      />
    );
  });

  const main_content = <div className="row">{template_cards}</div>;

  let categories;

  if ([true, "true"].includes(is_new_shop)) {
    categories = templates
      .filter((template) => template.is_shop === true)
      .map((template) => template.category)
      .sort();
  } else {
    categories = templates.map((template) => template.category).sort();
  }

  const sidebar = <TemplateSidebar categories={[...new Set(categories)]} />;

  return (
    <SidebarWrapper sidebar={sidebar}>
      <TemplateSearch
        templates={templates}
        onSearchResults={handleSearchResults}
      />
      {main_content}
      <NetworkMessageDisplay error={billingCustomerError} />
      <BillingPlansModal
        modal_id={billingPlansModalId}
        title="Select a plan"
        handleSelectedPlan={handleSelectedPlan}
      />
    </SidebarWrapper>
  );
});

WebsiteTemplates.propTypes = {
  dispatch: PropTypes.func.isRequired,
  is_loading_templates: PropTypes.bool.isRequired,
  templates: PropTypes.instanceOf(Object).isRequired,
  websites: PropTypes.instanceOf(Object).isRequired,
  free_plan: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    key: PropTypes.string,
  }).isRequired,
};

/**
 * mapStateToProps
 * @param {*} state
 * @returns
 */
function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  const template_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    TEMPLATE_DATA,
  );
  const is_loading_templates: any = template_data.isFetching;
  let templates: null[] = [];
  if (template_data.items) {
    templates = template_data.items.map((item) => new Site(item));
  }

  const website_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    WEBSITE_DATA,
  );
  let websites: null[] = [];
  if (website_data.items) {
    websites = website_data.items.map((item) => new Site(item));
  }
  const billing_plan_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    BILLING_PLANS_DATA,
  );

  let billing_plans: null[] = [];
  let free_plan: null[] = [];
  if (billing_plan_data.items.length > 0) {
    billing_plans = billing_plan_data.items.map(
      (parameters) => new BillingPlan(parameters),
    );
    free_plan = billing_plans.find((billing_plan) => {
      return billing_plan.pricing.amount <= 0.0;
    });
  }

  return {
    is_loading_templates,
    templates,
    websites,
    free_plan,
  };
}

export default connect(mapStateToProps)(withRouter(WebsiteTemplates));
