import React from "react";
import Label from "../Label";

export class AppendedTextInput extends React.Component {
  render() {
    const {
      label,
      placeholder,
      name,
      text,
      append_text,
      onChangeText,
      is_required,
      is_editable,
    } = this.props;

    return (
      <>
        <Label icon={null} text={label} is_important={is_required} />
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder={placeholder}
            name={name}
            value={text}
            onChange={onChangeText}
            required={is_required}
            disabled={!is_editable}
          />
          <span className="input-group-text text-olitt-grey font-weight-bold">
            {append_text}
          </span>
        </div>
      </>
    );
  }
}
