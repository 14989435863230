import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import BaseAddonPurchaseForm from "modules/base/components/billing/BaseAddonPurchaseForm";
import Modal from "modules/base/components/Modal";
import { BillingAddon } from "modules/base/models/Billing";
import { closeModalById } from "modules/base/utilities/Actions";
import { BillingAPI } from "modules/billing/BillingAPIs";
import { Site } from "modules/website/WebsiteModels";

function AddonPurchaseForm(props) {
  const { addon, website } = props;
  const billingAPI = new BillingAPI();
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsPurchasing(true);
    billingAPI.purchaseAddon(
      addon.id,
      website.id,
      () => {
        setIsPurchasing(false);
        setSuccess({ message: "Addon purchased successfully" });
        setError(null);
        closeModalById("purchase-addon-modal");
      },
      (error_response) => {
        setIsPurchasing(false);
        setError({
          message: error_response.message,
        });
        setSuccess(null);
      },
    );
  });
  return (
    <Modal
      modal_title={addon.name ?? ""}
      modal_id="purchase-addon-modal"
      size="md"
    >
      <BaseAddonPurchaseForm
        addon={addon}
        handleSubmit={handleSubmit}
        isSubmitting={isPurchasing}
        error={error}
        success={success}
        submitButtonColor="gray-900"
      />
    </Modal>
  );
}

AddonPurchaseForm.defaultProps = {
  website: null,
};

AddonPurchaseForm.propTypes = {
  addon: PropTypes.instanceOf(BillingAddon).isRequired,
  website: PropTypes.instanceOf(Site),
};

export default AddonPurchaseForm;
