import moment from "moment";

import {
  CURRENT_DAY,
  CURRENT_DAY_ORDERS,
  CURRENT_DAY_PAYMENTS,
  DAILY,
  DAILY_ORDERS,
  DAILY_PAYMENTS,
  DAYS,
  MONTHLY,
  MONTHLY_ORDERS,
  MONTHLY_PAYMENTS,
  PRIMARY_BLACK,
  PRIMARY_YELLOW,
  WEEKLY,
  WEEKLY_ORDERS,
  WEEKLY_PAYMENTS,
  WEEKS,
} from "../../../constants";

const HOURS = Array.from(Array(24).keys()).map((hour) =>
  moment(hour, "H").format("h A"),
);

export function calculateMonthlyData(orders, payments) {
  const months = moment.months();
  const monthlyTotal = Array(12).fill(0);
  const paymentTotal = Array(12).fill(0);

  orders.forEach((order) => {
    const month = moment(order.completed_at).month();
    monthlyTotal[month] += parseFloat(order.total);
  });

  payments.forEach((payment) => {
    const month = moment(payment.created_at).month();
    paymentTotal[month] += parseFloat(payment.amount);
  });

  return {
    options: {
      xaxis: {
        categories: months,
      },
    },
    series: [
      {
        name: MONTHLY_ORDERS,
        data: monthlyTotal,
        color: PRIMARY_YELLOW,
      },
      {
        name: MONTHLY_PAYMENTS,
        data: paymentTotal,
        color: PRIMARY_BLACK,
      },
    ],
  };
}

export function calculateWeeklyData(orders, payments, currentMonth) {
  const weeklyTotal = Array(4).fill(0);
  const weeklyPaymentTotal = Array(4).fill(0);

  orders.forEach((order) => {
    const week =
      moment(order.completed_at).isoWeek() -
      moment().startOf("month").isoWeek();
    if (moment(order.completed_at).month() === currentMonth) {
      weeklyTotal[week] += parseFloat(order.total);
    }
  });

  payments.forEach((payment) => {
    const week =
      moment(payment.created_at).isoWeek() -
      moment().startOf("month").isoWeek();
    if (moment(payment.created_at).month() === currentMonth) {
      weeklyPaymentTotal[week] += parseFloat(payment.amount);
    }
  });

  return {
    options: {
      xaxis: {
        categories: WEEKS,
      },
    },
    series: [
      {
        name: WEEKLY_ORDERS,
        data: weeklyTotal,
        color: PRIMARY_YELLOW,
      },
      {
        name: WEEKLY_PAYMENTS,
        data: weeklyPaymentTotal,
        color: PRIMARY_BLACK,
      },
    ],
  };
}

export function calculateDailyData(orders, payments, currentWeek) {
  const dailyTotal = Array(7).fill(0);
  const dailyPaymentTotal = Array(7).fill(0);

  orders.forEach((order) => {
    const day = moment(order.completed_at).isoWeekday() - 1;
    if (moment(order.completed_at).week() === currentWeek) {
      dailyTotal[day] += parseFloat(order.total);
    }
  });

  payments.forEach((payment) => {
    const day = moment(payment.created_at).isoWeekday() - 1;
    if (moment(payment.created_at).week() === currentWeek) {
      dailyPaymentTotal[day] += parseFloat(payment.amount);
    }
  });

  return {
    options: {
      xaxis: {
        categories: DAYS,
      },
    },
    series: [
      {
        name: DAILY_ORDERS,
        data: dailyTotal,
        color: PRIMARY_YELLOW,
      },
      {
        name: DAILY_PAYMENTS,
        data: dailyPaymentTotal,
        color: PRIMARY_BLACK,
      },
    ],
  };
}

export function calculateCurrentDayData(orders, payments) {
  const currentDay = new Array(24).fill(0);

  orders.forEach((order) => {
    if (moment(order.completed_at).isSame(moment(), "day")) {
      const hour = moment(order.completed_at).hour();
      currentDay[hour] += parseFloat(order.total);
    }
  });

  payments.forEach((payment) => {
    if (moment(payment.created_at).isSame(moment(), "day")) {
      const hour = moment(payment.created_at).hour();
      currentDay[hour] += parseFloat(payment.amount);
    }
  });

  return {
    options: {
      xaxis: {
        categories: HOURS,
      },
    },
    series: [
      {
        name: CURRENT_DAY_ORDERS,
        data: currentDay,
        color: PRIMARY_YELLOW,
      },
      {
        name: CURRENT_DAY_PAYMENTS,
        data: currentDay,
        color: PRIMARY_BLACK,
      },
    ],
  };
}

export function calculateChartData(
  interval,
  orders,
  payments,
  currentMonth,
  currentWeek,
) {
  if (interval === MONTHLY) {
    return calculateMonthlyData(orders, payments);
  }
  if (interval === WEEKLY) {
    return calculateWeeklyData(orders, payments, currentMonth);
  }
  if (interval === DAILY) {
    return calculateDailyData(orders, payments, currentWeek);
  }
  if (interval === CURRENT_DAY) {
    return calculateCurrentDayData(orders, payments);
  }

  return {};
}
