import { twitter_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const tm = editor.Panels.getButton("views", "open-tm");
  const { profile_url, twitterComponent } = opts;

  const placeholder = `<div style="text-align:center;color:#f6a908;height: 50px;">${twitter_logo}</div><div  style="text-align:center;color:#f6a908">This is a placeholder and will not be rendered. Toggle visibility in layers</div>`;

  domc.addType("TWITTER-FEEDS-COMPONENT", {
    model: {
      defaults: {
        tagName: "div",
        name: "twitter-feeds",
        draggable: "[data-gjs-type=wrapper]",
        droppable: false,
        stylable: false,
        copyable: false,
        profile_url,
        traits: [
          {
            name: "profile_url",
            label: "Profile Url",
            placeholder: "Enter profile url.",
            changeProp: 1,
          },
        ],
        content: placeholder,
        script() {
          let url;
          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
              "twitter.start": new Date().getTime(),
              event: "twitter.js",
            });
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s);
            const dl = l != "dataLayer" ? `&l=${l}` : "";
            j.async = true;
            j.src = "https://platform.twitter.com/widgets.js";
            f.parentNode.insertBefore(j, f);
            url = i + dl;
          })(window, document, "script", "dataLayer", "{[ profile_url ]}");
        },
      },
      init() {
        this.on("change:profile_url", () => valuesOnchange());
        const valuesOnchange = () => {
          this.trigger("change:script");
          editor.store();
        };
        tm.set("active", 1);
      },
      toHTML() {
        const tagName = this.get("tagName");
        const profile_url = this.get("profile_url");
        const new_content = `<a class="twitter-timeline" href="${profile_url}?ref_src=twsrc%5Etfw"></a>`;
        return `<${tagName} id="${this.getId()}">${new_content}</${tagName}>`;
      },
      ...twitterComponent,
    },
    view: {},
  });
};
