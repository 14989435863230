import PropTypes from "prop-types";

function PublishUnpublishButton(props) {
  const { isPublished, isPublishingOrUnpublishing, onClick } = props;
  const icon = isPublished ? "cloud-download" : "cloud-upload";
  const processSpinner = (
    <span
      className="spinner-border spinner-border-sm me-1"
      role="status"
      aria-hidden="true"
    />
  );
  return (
    <button
      type="button"
      className={`btn btn-sm btn-${isPublished ? "danger" : "success"}`}
      onClick={onClick}
      disabled={isPublishingOrUnpublishing}
    >
      {isPublishingOrUnpublishing && processSpinner}
      <i className={`fa fa-${icon} me-1`} />
      {isPublished ? "Unpublish" : "Publish"}
    </button>
  );
}

PublishUnpublishButton.propTypes = {
  isPublished: PropTypes.bool.isRequired,
  isPublishingOrUnpublishing: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PublishUnpublishButton;
