import PropTypes from "prop-types";
import React from "react";
import { OlittModal } from "../../core/components/OlittModal";
import { SocialShare } from "../layouts/SocialShare";
import { VisitDomainBanner } from "./VisitDomainBanner";

export function NewWebsiteModal({
  is_visible,
  onClose,
  olitt_sub_domain,
  site_domain,
  site_title,
  visitWebsite,
  connectDomain,
  should_upgrade,
  upgradePlan,
  modal_id,
  is_shop,
}) {
  let upgrade_btn;
  if (should_upgrade) {
    upgrade_btn = (
      <button
        type="button"
        className="btn btn-lg btn-olitt-grey shadow-none"
        data-dismiss="modal"
        onClick={upgradePlan}
      >
        Upgrade your Website Plan
      </button>
    );
  }
  let website_online_message: string = "Congrats. Your website is now online!";
  if (is_shop) {
    website_online_message = "Congrats. Your shop is now online!";
  }
  return (
    <OlittModal
      modal_id={modal_id}
      show={is_visible}
      onClose={onClose}
      size="lg"
      height={50}
    >
      <div className="h-100 px-4 text-olitt-grey text-center d-flex flex-column align-items-center justify-content-center">
        <h3 className="mb-3"> {website_online_message} </h3>
        <small className="mb-2 text-secondary">
          We have assigned you a free domain and you can access your website at:
        </small>
        <div className="mb-4">
          <VisitDomainBanner domain={olitt_sub_domain} size="sm" />
        </div>
        <SocialShare
          site_domain={site_domain}
          site_title={site_title}
          is_shop={is_shop}
        />
        <div className="d-flex align-items-center justify-content-center mt-4">
          <button
            type="button"
            className="btn btn-lg btn-olitt-grey shadow-none"
            data-dismiss="modal"
            onClick={connectDomain}
          >
            Connect Your Own Domain
          </button>
          <span className="mx-2" />
          {upgrade_btn}
        </div>
      </div>
    </OlittModal>
  );
}

NewWebsiteModal.defaultProps = {
  olitt_sub_domain: "",
  site_domain: "",
  site_title: "",
  modal_id: null,
  is_shop: false,
};

NewWebsiteModal.propTypes = {
  modal_id: PropTypes.string,
  is_visible: PropTypes.bool.isRequired,
  olitt_sub_domain: PropTypes.string,
  site_domain: PropTypes.string,
  site_title: PropTypes.string,
  connectDomain: PropTypes.func.isRequired,
  visitWebsite: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  should_upgrade: PropTypes.bool.isRequired,
  upgradePlan: PropTypes.func.isRequired,
  is_shop: PropTypes.bool,
};
