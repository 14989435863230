import {
  autoPlacement,
  computePosition,
  offset,
  shift,
  size,
} from "@floating-ui/dom";
import { View } from "backbone";
import { getEditorDivRect } from "modules/core/components/editor/utilities";

export default class PopUpView extends View {
  className = "gjs-pop-up";

  events = {
    click: "onClick",
    "click [data-close-modal]": "hide",
  };

  constructor(opts) {
    super(opts);
    this.listenTo(this.model, "change:open", this.updateOpen);
    this.listenTo(this.model, "change:content", this.updateContent);
  }

  getContent() {
    if (!this.$content) {
      this.$content = this.$el.find(`.content`);
    }
    return this.$content;
  }

  updateOpen() {
    this.el.style.display = this.model.get("open") ? "" : "none";
  }

  updateContent() {
    const content = this.getContent();
    const body = this.model.get("content");
    content.empty().append(body);
  }

  applyStyles = ({ x = 0, y = 0 }) => {
    this.$el.css({ top: `${y}px`, left: `${x}px` });
  };

  getDefaultReferenceElement() {
    return document.querySelector(this.model.get("config").referenceEl);
  }

  // https://stackoverflow.com/a/384380
  isElement = (o) => {
    return typeof HTMLElement === "object"
      ? o instanceof HTMLElement
      : o &&
          typeof o === "object" &&
          o !== null &&
          o.nodeType === 1 &&
          typeof o.nodeName === "string";
  };

  getReferenceElement(selector) {
    if (!selector) {
      return this.getDefaultReferenceElement();
    }

    if (typeof selector === "string") {
      return document.querySelector(selector);
    }

    if (this.isElement(selector)) {
      return selector;
    }

    throw new Error("selector is neither a string nor HTMLElement");
  }

  getMaxWidth() {
    return this.model.get("maxWidth") ?? this.model.get("config").maxWidth;
  }

  updateAttr(attr, opts = {}) {
    const { $el, el } = this;

    const currAttr = Array.from(el.attributes).map((i) => i.name);
    $el.removeAttr(currAttr.join(" "));
    $el.attr({
      ...(attr || {}),
      class: `${"pop-up-"}container ${(attr && attr.class) || ""}`.trim(),
    });

    const editorDivRect = getEditorDivRect();
    const editorDivTop = editorDivRect.top;

    const middleware = [
      shift({
        mainAxis: true,
        crossAxis: true,
      }),
      offset(
        opts.offset ?? {
          crossAxis: editorDivTop,
          mainAxis: 5,
        },
      ),
    ];

    if (
      opts.enabled?.autoPlacement === undefined ||
      opts.enabled.autoPlacement === true
    ) {
      middleware.push(
        autoPlacement(
          opts.autoPlacement ?? {
            alignment: "start",
          },
        ),
      );
    }

    if (opts.enabled?.size && opts.enabled.size === true) {
      const maxWidth = this.getMaxWidth();

      middleware.push(
        size({
          apply: ({ elements }) => {
            Object.assign(elements.floating.style, {
              maxWidth,
            });
            this.model.set("maxWidth", null);
          },
        }),
      );
    }

    computePosition(this.getReferenceElement(opts.selector), this.el, {
      placement: opts.placement ?? "right",
      strategy: "fixed",
      middleware,
    }).then(this.applyStyles);
  }

  hide() {
    this.model.close();
  }

  template = ({ content }) => {
    return `<div class="pop-up-module">
              <div class="content">${content}</div>
            </div>
            `;
  };

  render() {
    this.$el.html(this.template(this.model.toJSON()));
    this.updateAttr();
    this.updateOpen();

    return this;
  }
}
