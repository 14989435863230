/**
 * Base model class
 */
export default class BaseModel extends Object {
  /**
   * Create model instance from array
   * @param arr
   */
  fromArray(arr = []) {
    return arr.map((item) => {
      return new this.constructor(item);
    });
  }
}
