import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import SelectInput from "modules/base/components/inputs/SelectInput";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import {
  closeModalById,
  handleInputChange,
} from "modules/base/utilities/Actions";
import MarketingAPI from "modules/marketing/MarketingAPI";

function ManageContactStages({ selectedContactsIds, match, currentModalId }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const { stages, error: stageLoadingError, isLoading } = API.getStages();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errors, setErrors] = useState([]);
  const [state, setState] = useState("");

  useEffect(() => {
    if (stageLoadingError) {
      setErrors((prevErrors) => [
        ...prevErrors,
        { message: stageLoadingError },
      ]);
    }
  }, [isLoading, stageLoadingError]);

  const handleStageChange = useCallback(
    (selectedOption) => {
      handleInputChange("stage_id", selectedOption.value, state, setState);
    },
    [state, setState],
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      setErrors([]);
      API.changeStageContact(
        state.stage_id,
        selectedContactsIds,
        () => {
          setIsSubmitting(false);
          closeModalById(currentModalId);
          setSuccess({ message: "Stages updated successfully" });
        },
        (error) => {
          setErrors((prevErrors) => [
            ...prevErrors,
            { message: error.message },
          ]);
          setIsSubmitting(false);
        },
      );
    },
    [state, selectedContactsIds],
  );

  const stageOptions = stages.map((stage) => ({
    label: stage.name,
    value: stage.id,
  }));

  const errorMessages = errors.map((error) => (
    <NetworkMessageDisplay key={error.id} error={error.message} />
  ));

  return (
    <Form
      handleSubmit={handleSubmit}
      id="manage-stages-form"
      button_color="gray-900"
      button_label="Update Stage"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      {errorMessages}
      <SuccessActionIndicator success={success} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <SelectInput
          label="Add to Stage"
          title="Add to Stage"
          required
          name="stageToAdd"
          options={stageOptions}
          onChange={handleStageChange}
          predicate={(option) => option.value === state.stage_id}
        />
      </div>
    </Form>
  );
}

ManageContactStages.defaultProps = {
  currentModalId: null,
};
ManageContactStages.propTypes = {
  selectedContactsIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string,
};
export default withRouter(ManageContactStages);
