export const ListGroupBlock = (editor, opts = {}) => {
    const bm = editor.BlockManager;
    bm.add("bootstrap-list-group", {
        label: `List Group`,
        activate: true,
        select: true,
        category: "Basic",
        content:'<ul class="list-group"> <li class="list-group-item"><span>List Group Item 1</span></li><li class="list-group-item"><span>List Group Item 2</span></li><li class="list-group-item"><span>List Group Item 3</span></li></ul>',
        media: '<i class=" oicon-editor-list-ol" style="font-size: 42px;"></i>'
    });
};