import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import ImportFile from "modules/marketing/components/contacts/import/ImportFile";

function ImportFileModal({ assignRawContacts }) {
  const modalId = "import-contacts-file-modal";
  return (
    <Modal modal_title="Import Contacts File" modal_id={modalId} size="md">
      <ImportFile
        currentModalId={modalId}
        assignRawContacts={assignRawContacts}
      />
    </Modal>
  );
}

ImportFileModal.defaultProps = {
  assignRawContacts: () => {},
};

ImportFileModal.propTypes = {
  assignRawContacts: PropTypes.func,
};

export default ImportFileModal;
