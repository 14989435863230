import { ApmRoute as Route } from "@elastic/apm-rum-react";
import { Switch } from "react-router-dom";
import Page404 from "modules/core/layouts/Page404";
import PaymentMethodsCreate from "modules/shop/components/paymentmethods/PaymentMethodsCreate";
import Dashboard from "modules/shop/layouts/Dashboard";
import Design from "modules/shop/layouts/Design";
import PaymentMethods from "modules/shop/layouts/PaymentMethods";
import Products from "modules/shop/layouts/Products";
import ShopDetails from "modules/shop/layouts/ShopDetails";
import Taxonomies from "modules/shop/layouts/Taxonomies";
import {
  PATH_DASHBOARD,
  PATH_SHOP_DESIGN,
  PATH_SHOP_DETAILS,
  PATH_SHOP_PAYMENT_METHODS,
  PATH_SHOP_PAYMENT_METHODS_CREATE,
  PATH_SHOP_PAYMENT_METHODS_EDIT,
  PATH_SHOP_PRODUCTS,
  PATH_SHOP_TAXONOMIES,
} from "modules/shop/ShopConstants";

/**
 * @name ShopRouter
 * @returns {JSX.Element}
 * @constructor
 */
function ShopRouter() {
  return (
    <Switch>
      <Route key={1.1} exact path={PATH_SHOP_DETAILS} component={ShopDetails} />
      <Route
        key={1.2}
        exact
        path={PATH_SHOP_PAYMENT_METHODS}
        component={PaymentMethods}
      />
      <Route
        key={1.3}
        exact
        path={PATH_SHOP_PAYMENT_METHODS_CREATE}
        component={PaymentMethodsCreate}
      />
      <Route
        key={1.4}
        exact
        path={PATH_SHOP_PAYMENT_METHODS_EDIT}
        component={PaymentMethodsCreate}
      />
      <Route key={1.5} exact path={PATH_SHOP_PRODUCTS} component={Products} />
      <Route
        key={1.6}
        exact
        path={PATH_SHOP_TAXONOMIES}
        component={Taxonomies}
      />
      <Route key={1.7} exact path={PATH_SHOP_DESIGN} component={Design} />
      <Route key={1.9} exact path={PATH_DASHBOARD} component={Dashboard} />
      <Route key={1.8} path="*" component={Page404} />
    </Switch>
  );
}

export default ShopRouter;
