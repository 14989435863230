import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import publishUnpublishActionColumn from "../PublishUnpublishActionColumn";
import { AssetsColumns } from "../tableColumns";
import CreateAssetModal from "./CreateAssetModal";
import EditAssetModal from "./EditAssetModal";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import { openModal } from "modules/base/utilities/Actions";
import MarketingAPI from "modules/marketing/MarketingAPI";

function AssetList(props) {
  const { match } = props;
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const [error, setError] = useState(null);
  const [paginationParams, setPaginationParams] = useState({
    start: 0,
    limit: 10,
  });
  const params = useMemo(
    () => ({
      ...paginationParams,
      orderBy: "dateAdded",
      orderByDir: "desc",
    }),
    [paginationParams],
  );
  const {
    assets,
    total,
    error: fetchAssetsError,
    isLoading,
  } = API.getAssets(params);

  const onClickEdit = useCallback((asset) => {
    openModal(`edit-asset-${asset.id}-modal`);
  }, []);
  const onClickCreate = useCallback(() => {
    openModal("create-asset-modal");
  }, []);

  const onClickDelete = useCallback((id, onSuccess, onError) => {
    API.deleteAsset(
      id,
      () => {
        onSuccess();
      },
      (error_response) => {
        onError("Unable to delete file", error_response.message);
      },
    );
  }, []);
  const handlePaginationChange = useCallback((newPaginationParams) => {
    setPaginationParams(newPaginationParams);
  }, []);

  const columns = [
    ...AssetsColumns,
    publishUnpublishActionColumn(siteId, setError, "updateAsset"),
  ];
  return (
    <>
      <NetworkMessageDisplay error={error || fetchAssetsError} />
      <CreateAssetModal />
      <DataTable
        data={assets}
        columns={columns}
        total={total}
        onClickEdit={onClickEdit}
        onClickCreate={onClickCreate}
        onClickDelete={onClickDelete}
        isLoading={isLoading}
        createButtonColor="gray-900"
        allowEdit
        allowDelete
        allowCreate
        enableRowSelection
        enableSearch
        EditModal={EditAssetModal}
        enableMultiRowSelection
        editButtonColor="gray-900"
        onPaginationChange={handlePaginationChange}
        isServerSidePagination
      />
    </>
  );
}

AssetList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(AssetList);
