import Modal from "modules/base/components/Modal";
import CreateContentForm from "modules/marketing/components/content/CreateContentForm";

function CreateContentModal() {
  const modalId = "create-content-modal";
  return (
    <Modal modal_title="Create Content" modal_id={modalId} size="md">
      <CreateContentForm currentModalId={modalId} />
    </Modal>
  );
}

export default CreateContentModal;
