import moment from "moment";

import { THIRD_PARTY_COOKIES_CHECK_URL } from "modules/core/constants/Constants";

export const deepCompare: any = function (...args) {
  let i;
  let l;
  let leftChain;
  let rightChain;

  function compare2Objects(x, y) {
    let p;

    // remember that NaN === NaN returns false
    // and isNaN(undefined) returns true
    if (
      Number.isNaN(x) &&
      Number.isNaN(y) &&
      typeof x === "number" &&
      typeof y === "number"
    ) {
      return true;
    }

    // Compare primitives and functions.
    // Check if both arguments link to the same object.
    // Especially useful on the step where we compare prototypes
    if (x === y) {
      return true;
    }

    // Works in case when functions are created in constructor.
    // Comparing dates is a common scenario. Another built-ins?
    // We can even handle functions passed across iframes
    if (
      (typeof x === "function" && typeof y === "function") ||
      (x instanceof Date && y instanceof Date) ||
      (x instanceof RegExp && y instanceof RegExp) ||
      (x instanceof String && y instanceof String) ||
      (x instanceof Number && y instanceof Number)
    ) {
      return x.toString() === y.toString();
    }

    // At last checking prototypes as good as we can
    if (!(x instanceof Object && y instanceof Object)) {
      return false;
    }

    if (
      Object.prototype.isPrototypeOf.call(x, y) ||
      Object.prototype.isPrototypeOf.call(y, x)
    ) {
      return false;
    }

    if (x.constructor !== y.constructor) {
      return false;
    }

    if (x.prototype !== y.prototype) {
      return false;
    }

    // Check for infinitive linking loops
    if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
      return false;
    }

    // Quick checking of one object being a subset of another.
    // todo: cache the structure of arguments[0] for performance
    for (p in y) {
      if (
        Object.prototype.hasOwnProperty.call(y, p) !==
        Object.prototype.hasOwnProperty.call(x, p)
      ) {
        return false;
      }
      if (typeof y[p] !== typeof x[p]) {
        return false;
      }
    }

    for (p in x) {
      if (
        Object.prototype.hasOwnProperty.call(y, p) !==
        Object.prototype.hasOwnProperty.call(x, p)
      ) {
        return false;
      }
      if (typeof y[p] !== typeof x[p]) {
        return false;
      }

      switch (typeof x[p]) {
        case "object":
        case "function":
          leftChain.push(x);
          rightChain.push(y);

          if (!compare2Objects(x[p], y[p])) {
            return false;
          }

          leftChain.pop();
          break;

        default:
          if (x[p] !== y[p]) {
            return false;
          }
          break;
      }
    }

    return true;
  }

  if (arguments.length < 1) {
    return true; // Die silently? Don't know how to handle such case, please help...
    // throw "Need two or more arguments to compare";
  }

  for (i = 1, l = arguments.length; i < l; i++) {
    leftChain = []; // Todo: this can be cached
    rightChain = [];

    if (!compare2Objects(args[0], args[i])) {
      return false;
    }
  }

  return true;
};

export const getLocationProtocol: any = () => {
  if (!window || !window.location || !window.location.protocol) {
    return "http:";
  }
  return window.location.protocol;
};

export const removeUnderscore: any = (string) => {
  if (string) {
    return string.replace(/_/g, " ");
  }
  return string;
};

/**
 * @name objGetAttribute
 * @param obj
 * @param field
 * @param default_val
 * @returns {*}
 */
export const objGetAttribute: any = (obj, field, default_val) => {
  return typeof obj[field] !== "undefined" ? obj[field] : default_val;
};

/**
 * containsObject
 * description : check if an object is in an array
 * @param obj
 * @param list
 * @returns {boolean}
 */
export const containsObject: any = (obj, list) => {
  let i;
  for (i = 0; i < list.length; i += 1) {
    if (list[i] === obj) {
      return true;
    }
  }

  return false;
};

/**
 * @name modelObjectsFromArray
 * @description : convert an array of objects to an array of model objects
 * @param array
 * @param Model
 * @returns {[]}
 */
export const modelObjectsFromArray: Array<any> = (Model, array) => {
  const model_objects: null[] = [];
  array.forEach((item) => {
    model_objects.push(new Model(item));
  });
  return model_objects;
};

/**
 * @name formatDateTime
 * @description format a date to a string          rightChain.pop();

 * @param date_time
 * @param format
 * @returns {string}
 */
export const formatDateTime: any = (date_time, format = "MM/DD/YYYY") => {
  if (!date_time) {
    return "";
  }
  return moment(date_time).format(format);
};

/**
 * Appends Google Fonts to the HTML content of an editor.
 *
 * @param {Object} editor - The editor object which contains the HTML content.
 * @param {Array} fonts - An array of font names to be appended to the HTML content.
 *
 * @returns {string} - The HTML content with the appended Google Fonts.
 */
export const appendEditorGoogleFonts = (editor, fonts) => {
  const html: any = editor.getHtml();
  if (fonts.length > 0) {
    const doc: any = new DOMParser().parseFromString(html, "text/html");
    const link_id: string = "olitt-google-fonts";
    let google_link: string = "https://fonts.googleapis.com/css2?";
    const font_style_tag: any = doc.getElementById(link_id);
    const current_href_link: any = font_style_tag
      ? font_style_tag.getAttribute("href")
      : "";
    fonts.forEach((font) => {
      const google_font: any = font.replace(" ", "+");
      if (!current_href_link.includes(google_font)) {
        google_link = google_link.concat(`family=${google_font}&`);
      }
    });
    const font_link: string = `<link id="${link_id}" href="${google_link}display=swap" rel="stylesheet">`;
    const current_head_content: any = doc.head.innerHTML;
    doc.head.innerHTML = current_head_content.concat(font_link);
    return doc.documentElement.innerHTML;
  }
  return html;
};

/**
 * Extracts the base URL (protocol and hostname) from a given URL.
 *
 * @param {string} url - The full URL from which to extract the base URL.
 * @returns {string} The base URL consisting of the protocol and hostname.
 */
export function getBaseUrl(url) {
  const parsedUrl = new URL(url);
  return `${parsedUrl.protocol}//${parsedUrl.hostname}`;
}

/**
 * Checks if third-party cookies are enabled by attempting to load a URL in an iframe and listening for a message event.
 *
 * @param {Function} onCookiesEnabled - Callback function to be called if third-party cookies are enabled.
 * @param {Function} onCookiesBlocked - Callback function to be called if third-party cookies are blocked.
 * @returns {Promise<boolean>} - A promise that resolves to true if third-party cookies are enabled, false otherwise.
 */
export async function checkThirdPartyCookies(
  onCookiesEnabled,
  onCookiesBlocked,
) {
  if (!THIRD_PARTY_COOKIES_CHECK_URL) return true;

  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  document.body.appendChild(iframe);

  try {
    iframe.src = THIRD_PARTY_COOKIES_CHECK_URL;

    const cookieCheckPromise = new Promise((resolve) => {
      const handleMessage = (event) => {
        if (event.origin === getBaseUrl(THIRD_PARTY_COOKIES_CHECK_URL)) {
          window.removeEventListener("message", handleMessage);
          resolve(event.data.cookiesEnabled);
        }
      };
      window.addEventListener("message", handleMessage);
      setTimeout(
        () => window.removeEventListener("message", handleMessage),
        10000,
      );
    });

    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error("Cookie check timed out")), 5000);
    });

    const result = await Promise.race([cookieCheckPromise, timeoutPromise]);
    onCookiesEnabled(result);
    return result === true;
  } catch {
    onCookiesBlocked();
    return false;
  } finally {
    document.body.removeChild(iframe);
  }
}
