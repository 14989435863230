import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import AssetsChart from "modules/marketing/components/dashboard/AssetsChart";
import CampaignsChart from "modules/marketing/components/dashboard/CampaignsChart";
import ContactsChart from "modules/marketing/components/dashboard/ContactsChart";
import { getDefaultDateRange } from "modules/marketing/components/dashboard/dateUtils";
import EmailsChart from "modules/marketing/components/dashboard/EmailsChart";
import GoogleAnalytics from "modules/marketing/components/dashboard/googleanalytics/GoogleAnalytics";
import Statistics from "modules/marketing/components/dashboard/Statistics";
import TimeFilters from "modules/marketing/components/dashboard/TimeFilters";

import SiteApi from "modules/website/api/SiteApi";

const DashboardContainer = () => {
  const [filterParams, setFilterParams] = useState(getDefaultDateRange());
  const [activeTab, setActiveTab] = useState("marketing");
  const [{ siteDetails, isSiteLoading, siteFetchError }, setSiteState] =
    useState({
      siteDetails: null,
      isSiteLoading: true,
      siteFetchError: null,
    });
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const siteApi = useMemo(() => new SiteApi(), []);
  const { id: siteId } = useParams();

  const handleFiltersChange = useCallback((newFilters) => {
    setFilterParams(newFilters);
  }, []);

  useEffect(() => {
    siteApi.retrieveWebsite(
      siteId,
      (newSiteDetails) => {
        setSiteState((prevState) => ({
          ...prevState,
          siteDetails: newSiteDetails,
          isSiteLoading: false,
          siteFetchError: null,
        }));
      },
      (error) => {
        setSiteState((prevState) => ({
          ...prevState,
          siteDetails: null,
          isSiteLoading: false,
          siteFetchError: error,
        }));
      },
    );
  }, [siteId, siteApi, reloadTrigger]);

  return (
    <div className="container-fluid px-4 py-3">
      <div className="d-flex justify-content-end align-items-center">
        <TimeFilters
          state={filterParams}
          onFiltersChange={handleFiltersChange}
        />
      </div>
      <ul className="nav nav-tabs border-0 mb-4">
        <li className="nav-item">
          <button
            type="button"
            className={`nav-link border-0 ${
              activeTab === "marketing" ? "active fw-bolder" : "text-muted"
            }`}
            onClick={() => setActiveTab("marketing")}
          >
            <i className="bi bi-graph-up me-2" />
            Marketing
          </button>
        </li>
        <li className="nav-item">
          <button
            type="button"
            className={`nav-link border-0 ${
              activeTab === "google" ? "active fw-bolder" : "text-muted"
            }`}
            onClick={() => setActiveTab("google")}
          >
            <i className="bi bi-globe me-2" />
            Web Analytics
          </button>
        </li>
      </ul>
      {activeTab === "google" && (
        <>
          <GoogleAnalytics
            filterParams={filterParams}
            siteDetails={siteDetails}
            isSiteLoading={isSiteLoading}
            siteFetchError={siteFetchError}
            setReloadTrigger={setReloadTrigger}
          />
        </>
      )}
      {activeTab === "marketing" && (
        <>
          <div className="row">
            <Statistics filterParams={filterParams} />
          </div>
          <div className="row">
            {[EmailsChart, ContactsChart, CampaignsChart, AssetsChart].map(
              (Chart, index) => (
                <div key={index} className="col-lg-6 mb-4">
                  <Chart filterParams={filterParams} />
                </div>
              ),
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardContainer;
