import React from "react";
import { PrivateApi } from "../../core/utilities/api/PrivateApi";
import { URL_INVOICES } from "../BillingConstants";

export class SettleInvoice extends React.Component {
  componentDidMount() {
    const invoice_id: any = this.props.match.params.id;

    const api_service: any = new PrivateApi();
    api_service.getAPIRequest(
      `${URL_INVOICES}${invoice_id}/settle/`,
      ({ payment_url }) => {
        window.location.href = payment_url;
      },
      (response) => {
        const error: any =
          response.detail || "Error processing payment request";
        // Todo
      },
      api_service.getAuthenticatedHeaders(),
    );
  }

  render() {
    return (
      <div className="text-center">
        <p>Please wait...</p>
      </div>
    );
  }
}
