import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { UI_ROUTES } from "../../CloudoonConstants";
import Stepper from "./Stepper";

function Completed(props) {
  const {
    match: {
      params: { domain_name },
    },
    history,
  } = props;

  const onBack = React.useCallback(
    () =>
      history.push(
        UI_ROUTES.SETUP.CREATE_ACCOUNT.replace(":domain_name", domain_name),
      ),
    [history, domain_name],
  );

  return (
    <div>
      <Stepper
        progressData={{
          step: 3,
          instructions: "Completed",
          progress: 100,
        }}
        onBack={onBack}
      />
      <div className="d-flex flex-column align-items-stretch gap-4">
        <div className="d-flex flex-column gap-2">
          <h1 className="fw-bold text-capitalize">Congratulations!</h1>
          <h5 className="fw-bold text-capitalize text-muted">
            You have successfully setup your account. Check your email for your
            email credentials.
          </h5>
        </div>
        <div className="d-flex flex-column gap-2">
          <h5>Quick Actions</h5>
          <div>
            <Link
              className="btn btn-primary"
              to={UI_ROUTES.DOMAINS.ROOT.replace(":domain_name", domain_name)}
            >
              Go To Dashboard
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

Completed.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      domain_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Completed;
