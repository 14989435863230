import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import CreateAssets from "./CreateAssets";
import Modal from "modules/base/components/Modal";

function CreateAssetModal({ match }) {
  const { id: siteId } = match.params;

  return (
    <Modal modal_title="Create File" modal_id="create-asset-modal" size="md">
      <CreateAssets currentModalId="create-asset-modal" siteId={siteId} />
    </Modal>
  );
}

CreateAssetModal.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(CreateAssetModal);
