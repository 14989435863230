import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import Pagination from "modules/base/components/table/Pagination";
import { closeModalById, openModal } from "modules/base/utilities/Actions";
import MarketingAPI from "modules/marketing/MarketingAPI";
import WebsiteCard from "modules/website/components/WebsiteCard";

const ItemsPerPage = 10;

function PrebuiltEmailTemplates({
  setPrebuiltHtml,
  uuid,
  currentModalId,
  match,
}) {
  const { id: siteId } = match.params;
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(ItemsPerPage);
  const API = new MarketingAPI(siteId);
  const offset = currentPage * ItemsPerPage;
  const {
    prebuiltEmailTemplates,
    total,
    error: templatesError,
    isLoading: isLoadingTemplates,
  } = API.getPrebuiltEmailTemplates({
    offset,
    limit: pageSize,
    paginate: true,
  });
  const onSelectTemplate = useCallback((template) => {
    const startPage = template.pages.find((page) => page.is_start_page);
    setPrebuiltHtml(startPage.page_content);
    closeModalById(currentModalId);
    openModal(
      `design-email-template-${uuid ?? "new"}-modal`,
      `create-template-${uuid}-modal`,
    );
  }, []);

  if (isLoadingTemplates) {
    return <NetworkActivityIndicator key="loading" />;
  }

  const prebuiltTemplatesList = prebuiltEmailTemplates.map((template) => {
    let isPremium = false;
    const templateSubscriptionPlan = template.subscription_plan;
    if (templateSubscriptionPlan.pricing.amount > 0) {
      isPremium = true;
    }
    const overImageAction = {
      className: "btn btn-outline-primary mx-md-auto",
      onClick: () => onSelectTemplate(template),
      text: `Create from ${template.name}`,
      icon: "plus-lg",
    };
    return (
      <WebsiteCard
        key={template.id}
        website={template}
        over_image_action={overImageAction}
        showPreview
        view_mode="templates"
        is_premium={isPremium}
        image_width={800}
        is_share_site_visible={false}
      />
    );
  });
  return (
    <>
      <NetworkMessageDisplay error={templatesError} />
      <div className="row row-cols-md-2 row-cols-1 ms-2">
        {prebuiltTemplatesList}
      </div>
      <Pagination
        pageIndex={currentPage}
        pageSize={pageSize}
        isServerSidePagination
        total={total}
        onPageSizeChange={setPageSize}
        onPageChange={setCurrentPage}
      />
    </>
  );
}

PrebuiltEmailTemplates.propTypes = {
  setPrebuiltHtml: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  currentModalId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(PrebuiltEmailTemplates);
