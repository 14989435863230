import React, { useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import logo from "../../core/assets/olitt-logo-secondary.svg";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import FormTextInput from "../../core/components/form-controls/FormTextInput";
import { navigateToPage } from "../../core/utilities/navigation/Navigator";
import { AuthenticationApi } from "../AccountAPIs";
import { PATH_LOGIN } from "../AccountConstants";

function ResetPassword(props) {
  const { hash } = useParams();
  const [error, setError] = useState(null);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [is_resetting_password, setIsResettingPassword] = useState(false);
  const [banner_message, setBannerMessage] = useState(null);
  const [is_password_reset, setIsPasswordReset] = useState(false);

  const goToLogin: any = () => navigateToPage(PATH_LOGIN, props);

  const authenticationApi: any = new AuthenticationApi();

  function resetPassword(reset_password1, reset_password2) {
    setIsResettingPassword(true);
    setError(null);
    setBannerMessage(null);
    authenticationApi.resetPassword(
      hash,
      reset_password1,
      reset_password2,
      (is_successful, message_or_error) => {
        setIsResettingPassword(false);
        if (is_successful) {
          setBannerMessage(message_or_error);
          setIsPasswordReset(true);
        } else {
          setError(message_or_error);
        }
      },
    );
  }

  function handleOnSubmitPasswords(event) {
    event.preventDefault();
    resetPassword(password1, password2);
  }

  const card_width: any = window.innerWidth < 435 ? "100%" : "375px";
  const auth_card_style: { width: any } = { width: card_width };

  let reset_password_btn_text;
  if (is_resetting_password) {
    reset_password_btn_text = <ComponentLoadingIndicator />;
  } else {
    reset_password_btn_text = (
      <span className="text-uppercase"> Reset Password </span>
    );
  }

  let content;
  if (is_password_reset) {
    content = (
      <div>
        <div
          className="alert alert-success"
          role="alert"
          hidden={!banner_message}
        >
          <small> {banner_message} </small>
        </div>

        <button
          type="button"
          className="btn btn-olitt-grey w-100 shadow-none"
          onClick={goToLogin}
        >
          Login
        </button>
      </div>
    );
  } else {
    content = (
      <div>
        <div className="alert alert-danger" role="alert" hidden={!error}>
          <small> {error} </small>
        </div>

        <form
          className="w-100"
          hidden={is_password_reset}
          onSubmit={handleOnSubmitPasswords}
        >
          <FormTextInput
            name="password1"
            type="password"
            placeholder="Enter your password"
            value={password1}
            is_required
            is_editable={!is_resetting_password}
            onChangeText={(e) => setPassword1(e.target.value)}
          />

          <FormTextInput
            name="password2"
            type="password"
            placeholder="Repeat your password"
            value={password2}
            is_required
            is_editable={!is_resetting_password}
            onChangeText={(e) => setPassword2(e.target.value)}
          />

          <button
            type="submit"
            className="btn btn-olitt-grey w-100 d-flex align-items-center shadow-none"
            disabled={is_resetting_password}
          >
            <div className="d-flex w-100 align-items-center justify-content-center">
              {reset_password_btn_text}
            </div>
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="min-vh-100 d-flex flex-column bg-white">
      <div className="flex-grow-1 d-flex align-items-center justify-content-center p-3">
        <div className="card border-0" style={auth_card_style}>
          <div className="card-header bg-white border-0">
            <div className="d-flex flex-column align-items-center">
              <img className="my-4" width="150px" src={logo} alt="" />
              <strong className="text-center text-olitt-grey mb-2">
                {" "}
                Reset Your Password{" "}
              </strong>
            </div>
          </div>

          <div className="card-body"> {content} </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ResetPassword);
