import PropTypes from "prop-types";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import BrowserUsed from "modules/marketing/components/dashboard/googleanalytics/BrowserUsed";
import DeviceUsed from "modules/marketing/components/dashboard/googleanalytics/DeviceUsed";
import GoogleAnalyticsButton from "modules/marketing/components/dashboard/googleanalytics/GoogleAnalyticButton";
import PageViews from "modules/marketing/components/dashboard/googleanalytics/PageViews";
import SessionGrowthGraph from "modules/marketing/components/dashboard/googleanalytics/SessionGrowthGraph";
import SourceOfTraffic from "modules/marketing/components/dashboard/googleanalytics/SourceOfTraffic";
import UserGrowthGraph from "modules/marketing/components/dashboard/googleanalytics/UserGrowthGraph";
import UsersByCountries from "modules/marketing/components/dashboard/googleanalytics/UsersByCountries";

function GoogleAnalytics({
  filterParams,
  siteDetails,
  isSiteLoading,
  siteFetchError,
  setReloadTrigger,
}) {
  const showCharts = () => {
    setReloadTrigger((prev) => prev + 1);
  };

  if (isSiteLoading) {
    return <NetworkActivityIndicator />;
  }

  const {
    google_analytics_enabled,
    google_analytics_property_id,
    google_analytics_stream_id,
  } = siteDetails || {};

  const showGoogleAnalyticsButton =
    !siteFetchError &&
    (!google_analytics_enabled ||
      !google_analytics_property_id ||
      !google_analytics_stream_id);

  if (showGoogleAnalyticsButton) {
    return (
      <div className="card shadow-sm">
        <div className="card-body">
          <h5 className="card-title">Google Analytics Dashboard</h5>
          <p className="card-text text-muted mb-4">
            Connect to Google Analytics to view your data here.
          </p>
          <GoogleAnalyticsButton
            googleAnalyticsEnabled={google_analytics_enabled}
            showCharts={showCharts}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex justify-content-end mb-3">
        <GoogleAnalyticsButton
          googleAnalyticsEnabled={google_analytics_enabled}
          showCharts={showCharts}
        />
      </div>
      <div className="row">
        <div className="col-lg-6 mb-4">
          <UserGrowthGraph
            filterParams={filterParams}
            extraParams={{ metrics: "totalUsers,newUsers", dimensions: "date" }}
          />
        </div>
        <div className="col-lg-6 mb-4">
          <SessionGrowthGraph
            filterParams={filterParams}
            extraParams={{ metrics: "sessions", dimensions: "date" }}
          />
        </div>
        <div className="col-lg-6 mb-4">
          <BrowserUsed
            filterParams={filterParams}
            extraParams={{ metrics: "totalUsers", dimensions: "browser" }}
          />
        </div>
        <div className="col-lg-6 mb-4">
          <DeviceUsed
            filterParams={filterParams}
            extraParams={{
              metrics: "totalUsers",
              dimensions: "deviceCategory",
            }}
          />
        </div>
        <div className="col-lg-6 mb-4">
          <PageViews
            filterParams={filterParams}
            extraParams={{ metrics: "screenPageViews", dimensions: "pagepath" }}
          />
        </div>
        <div className="col-lg-6 mb-4">
          <UsersByCountries
            filterParams={filterParams}
            extraParams={{ metrics: "totalUsers", dimensions: "country" }}
          />
        </div>
        <div className="col-lg-6 mb-4">
          <SourceOfTraffic
            filterParams={filterParams}
            extraParams={{
              metrics: "totalUsers",
              dimensions: "firstUserSource",
            }}
          />
        </div>
      </div>
    </div>
  );
}

GoogleAnalytics.defaultProps = {
  filterParams: {},
  siteDetails: {},
  siteFetchError: {},
};

GoogleAnalytics.propTypes = {
  siteDetails: PropTypes.shape({
    google_analytics_enabled: PropTypes.bool,
    google_analytics_property_id: PropTypes.string,
    google_analytics_stream_id: PropTypes.string,
  }),
  isSiteLoading: PropTypes.bool.isRequired,
  siteFetchError: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(Error),
  ]),
  setReloadTrigger: PropTypes.func.isRequired,
  filterParams: PropTypes.instanceOf(Object),
};

export default GoogleAnalytics;
