import { InputType } from "../../../core/constants/Enumerators";
import { FormInput } from "../../../core/models/FormInput";
import { Shop } from "../../models/Shop";

/**
 * @name SocialDetailsInputs
 * @param shop
 * @returns {[FormInput,FormInput,FormInput]}
 * @constructor
 */
export default function SocialDetailsInputs(shop: Shop) {
  return [
    new FormInput(
      InputType.text,
      "facebook",
      "Facebook",
      "",
      shop.facebook,
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "twitter",
      "Twitter",
      "",
      shop.twitter,
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "instagram",
      "Instagram",
      "",
      shop.instagram,
      false,
      true,
    ),
  ];
}
