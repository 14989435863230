import React from "react";

export class ErrorCard extends React.Component {
  render() {
    const { text } = this.props;

    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        {text}
      </div>
    );
  }
}
