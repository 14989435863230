/**
 * Order model
 */

export class Order {
  id: number;

  user: string;

  created_by: string;

  approver: string;

  canceler: string;

  ship_address: string;

  bill_address: string;

  number: string;

  completed_at: Date;

  email: string;

  item_count: string;

  state: string;

  shipment_state: string;

  total: number;

  payment_state: string;

  created_at: Date;

  currency: string;

  constructor(parameters: Object) {
    this.id = parameters.id;
    this.user = parameters.user;
    this.created_by = parameters.created_by;
    this.approver = parameters.approver;
    this.canceler = parameters.canceler;
    this.ship_address = parameters.ship_address;
    this.bill_address = parameters.number;
    this.number = parameters.number;
    this.completed_at = parameters.completed_at;
    this.email = parameters.email;
    this.item_count = parameters.item_count;
    this.state = parameters.state;
    this.shipment_state = parameters.shipment_state;
    this.total = parameters.total;
    this.payment_state = parameters.payment_state;
    this.created_at = parameters.created_at;
    this.currency = parameters.currency;
  }
}

export { Order as default };
