import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AuthenticationActions } from "modules/account/AccountActions";
import { User } from "modules/account/AccountModels";
import ComponentLoadingIndicator from "modules/core/components/ComponentLoadingIndicator";
import DataTable from "modules/core/components/datagrid/DataTable";
import { ErrorModal } from "modules/core/components/ErrorModal";
import { navigateToUrl } from "modules/core/utilities/navigation/Navigator";
import {
  fetchUrlData,
  getUrlData,
} from "modules/core/utilities/redux/componentActions";
import FacebookProductsSync from "modules/shop/components/facebook/FacebookProductsSync";
import FacebookSetup from "modules/shop/components/facebook/FacebookSetup";
import { RowSelectionProvider } from "modules/shop/contexts";
import { getAutoLoginUrl } from "modules/shop/ShopActions";
import ShopApi from "modules/shop/ShopAPIs";
import {
  PRODUCTS_TABLE_COLUMNS,
  URL_SHOP_DETAILS,
  URL_SPREE_PRODUCT_CREATE_PATH,
  URL_SPREE_PRODUCT_EDIT_PATH,
} from "modules/shop/ShopConstants";

const SHOP_DETAILS_DATA_KEY: string = "SHOP_DETAILS_DATA_KEY";

/**
 * ProductsView
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ProductsView(props) {
  const [error, setError] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [facebookProducts, setFacebookProducts] = React.useState([]);
  const [facebookLogs, setFacebookLogs] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [canPostToFacebook, setCanPostToFacebook] = React.useState(false);
  const [canPostToInstagram, setCanPostToInstagram] = React.useState(false);
  const [throttledProducts, setThrottledProducts] = React.useState([]);
  const shopApi: any = new ShopApi();
  const { match, shop, isLoadingShop } = props;
  const { params } = match;
  const site_id: any = params.id;
  const authenticationActions: any = new AuthenticationActions();
  const user: User = authenticationActions.retrieveAuthenticatedUser();

  /**
   * getProducts
   */
  function getProducts() {
    setIsLoading(true);
    shopApi.getProducts(
      site_id,
      (response: any) => {
        setProducts(response);
        setIsLoading(false);
      },
      (error_message: any) => {
        setError(error_message);
        setIsLoading(false);
      },
    );
  }

  /**
   * Get products already in catalog
   */
  function getFacebookProducts() {
    shopApi.retrieveFacebookProducts(
      site_id,
      (is_successful, products_or_error) => {
        if (is_successful) {
          setFacebookProducts(products_or_error);
        }
      },
    );
  }

  /**
   * Get Facebook logs
   */
  function getFacebookLogs() {
    shopApi.retrieveFacebookLogs(site_id, (is_successful, logs_or_error) => {
      if (is_successful) {
        setFacebookLogs(logs_or_error);
      }
    });
  }

  /**
   * Disable facebook action if it's still
   * within last hour since last action
   */
  function disableFacebookActionByDate(product, log, attr) {
    if (facebookLogs.length === 0) {
      return false;
    }

    if (!log) {
      return false;
    }

    if (log[attr] === null) {
      return false;
    }

    const date = new Date(log[attr]);
    const now = new Date();
    const hours = Math.abs(now - date) / 36e5;

    if (hours > 1) {
      return false;
    }

    return true;
  }

  /**
   * Check if product is in the facebook catalog
   */
  function checkIfProductIsInCatalog(product) {
    const found = facebookProducts.find((facebookProduct) => {
      return facebookProduct.retailer_id === product.id.toString();
    });

    return found !== undefined;
  }

  useEffect(() => {
    fetchUrlData(
      SHOP_DETAILS_DATA_KEY,
      URL_SHOP_DETAILS.replace("{site_id}", site_id.toString()),
      props,
    );
    getProducts();
    getFacebookProducts();
    getFacebookLogs();
  }, []);

  useEffect(() => {
    setCanPostToFacebook(
      ![null, undefined, ""].includes(shop.facebook_page_id),
    );
    setCanPostToInstagram(
      ![null, undefined, ""].includes(shop.instagram_account_id),
    );
  }, [shop.facebook_page_id, shop.instagram_account_id]);

  const productsWithCatalogChecked = useMemo(() => {
    return products.map((product) => {
      const productLog = facebookLogs.find(
        (log) => log.product_id.toString() === product.id.toString(),
      );

      return {
        ...product,
        in_facebook_catalog: checkIfProductIsInCatalog(product),
        is_catalog_throttled: disableFacebookActionByDate(
          product,
          productLog,
          "date_posted_to_catalog",
        ),
        is_facebook_throttled: disableFacebookActionByDate(
          product,
          productLog,
          "date_posted_to_facebook",
        ),
        is_instagram_throttled: disableFacebookActionByDate(
          product,
          productLog,
          "date_posted_to_instagram",
        ),
      };
    });
  }, [products, facebookProducts, throttledProducts, facebookLogs]);

  /**
   * onClickEditProduct
   * @param product_id
   * @param product
   */
  const onClickEditProduct: any = (product_id: any, product: any) => {
    const auto_login_url: string = shop.auto_login_url
      ? shop.auto_login_url
      : getAutoLoginUrl(shop);
    let product_edit_url: string = URL_SPREE_PRODUCT_EDIT_PATH.replace(
      "{product_slug}",
      product.slug,
    );
    product_edit_url = `${auto_login_url}&next=${product_edit_url}`;
    navigateToUrl(product_edit_url);
  };

  /**
   * onClickCreateProduct
   * description - redirect to spree product create page
   */
  const onClickCreateProduct: any = () => {
    const auto_login_url: string = shop.auto_login_url
      ? shop.auto_login_url
      : getAutoLoginUrl(shop);
    const product_create_url: string = `${auto_login_url}&next=${URL_SPREE_PRODUCT_CREATE_PATH}`;
    navigateToUrl(product_create_url);
  };

  /**
   * @name onClickRefresh
   * @description - refresh the products list
   */
  const onClickRefresh: any = () => {
    getProducts();
    getFacebookProducts();
    getFacebookLogs();
  };

  if (isLoadingShop && !error) {
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <ComponentLoadingIndicator />
    </div>;
  }

  /**
   * post products to facebook catalog
   * @param {Object} chosen_products
   * @return {Promise<array>}
   */
  const postProductsToFacebookCatalog = (chosen_products) => {
    return new Promise((resolve, reject) => {
      shopApi.postProductsToFacebookCatalog(
        site_id,
        chosen_products,
        (success_response) => {
          getFacebookLogs();
          resolve(success_response);
        },
        (error_response) => {
          reject(error_response);
        },
      );
    }).then(() => {
      getFacebookProducts();
    });
  };

  /**
   * post product to facebook Page
   * @param {Object} chosen_product
   */
  const onClickPublishToFacebook = (chosen_product) => {
    setIsLoading(true);
    shopApi.postProductToFacebookPage(
      site_id,
      chosen_product,
      (_) => {
        setIsLoading(false);
        getFacebookLogs();
      },
      (error_response) => {
        setIsLoading(false);
      },
    );
  };

  /**
   * post product to Instagram Account
   * @param {Object} chosen_product
   */
  const onClickPublishToInstagram = (chosen_product) => {
    setIsLoading(true);
    shopApi.postProductToInstagram(
      site_id,
      chosen_product,
      (_) => {
        setIsLoading(false);
        getFacebookLogs();
      },
      (error_response) => {
        setIsLoading(false);
      },
    );
  };

  return (
    <>
      <RowSelectionProvider>
        <ErrorModal
          show={error !== null}
          onClose={() => setError(null)}
          short_desc={error ? error.short_desc : ""}
          suggestion={error ? error.suggestion : ""}
        />
        <div className="d-flex gap-2">
          <FacebookSetup user={user} shop={shop} setError={setError} />
          <FacebookProductsSync
            onSyncToFacebook={postProductsToFacebookCatalog}
          />
        </div>
        <DataTable
          columns={PRODUCTS_TABLE_COLUMNS}
          data={productsWithCatalogChecked}
          isLoading={isLoading}
          isDataEditable
          onClickEdit={onClickEditProduct}
          isDataCreatable
          onClickCreate={onClickCreateProduct}
          isDataRefreshable
          onClickRefresh={onClickRefresh}
          canPostToFacebook={canPostToFacebook}
          canPostToInstagram={canPostToInstagram}
          onClickPublishToFacebook={onClickPublishToFacebook}
          onClickPublishToInstagram={onClickPublishToInstagram}
          canPostToCatalog
          showCheckboxColumn
          rowSize={10}
        />
      </RowSelectionProvider>
    </>
  );
}

ProductsView.defaultProps = {};

ProductsView.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  shop: PropTypes.instanceOf(Object).isRequired,
  isLoadingShop: PropTypes.bool.isRequired,
};

/**
 * @name mapStateToProps
 * Maps the state to the props.
 * @param state
 * @returns {{shop, isLoadingShop: (boolean|*)}}
 */
function mapStateToProps(state: any) {
  const { sessionVariables, dataByUrl } = state;
  const shop_details_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    SHOP_DETAILS_DATA_KEY,
  );
  return {
    isLoadingShop: shop_details_data.isFetching,
    shop: shop_details_data.items,
  };
}

export default connect(mapStateToProps)(withRouter(ProductsView));
