import _ from "lodash";
import { ContextMenuManager } from "../index";

const addContextMenuEvent = (editor) => {
  const cm = editor.DomComponents;
  cm.getTypes().forEach(({ id, view: TypeView }) => {
    if (id !== "iframe") {
      cm.addType(id, {
        extendFn: ["initToolbar"],
        view: {
          events() {
            let toExtend;
            if (
              TypeView.prototype.events &&
              typeof TypeView.prototype.events === "function"
            ) {
              toExtend = TypeView.prototype.events();
            } else if (
              TypeView.prototype.events &&
              typeof TypeView.prototype.events === "object"
            ) {
              toExtend = TypeView.prototype.events;
            } else {
              toExtend = {};
            }
            // Add missing contextmenu event
            return _.extend({}, toExtend, {
              contextmenu(event) {
                event.preventDefault();
                event.stopPropagation();
                this.model.trigger("contextmenu", { event, view: this });
              },
            });
          },
        },
      });
    }
  });
};

export const installContextMenu = (editor, opts = {}) => {
  const config = {
    addEvent: true,
    ...opts,
  };

  const em = editor.getModel();

  em.tsLoadModule(ContextMenuManager);

  Object.defineProperty(editor, "ContextMenu", {
    get() {
      return this.em.get("ContextMenu");
    },
    enumerable: false,
    configurable: true,
  });

  if (config.addEvent === true) {
    addContextMenuEvent(editor);
  }
};

export default installContextMenu;
