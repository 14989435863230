import CampaignList from "modules/marketing/components/campaigns/CampaignList";
import SubscriptionCheck from "modules/marketing/layouts/SubscriptionCheck";
import { MARKETING_CAMPAIGNS_SLUG } from "modules/marketing/MarketingConstants";

function Campaigns() {
  return (
    <SubscriptionCheck
      slug={MARKETING_CAMPAIGNS_SLUG}
      component={<CampaignList />}
    />
  );
}

export default Campaigns;
