import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import { Datatable } from "../../core/components/Datatable";
import { ErrorCard } from "../../core/components/ErrorCard";
import MainNavigationSidebar from "../../core/components/MainNavigationSidebar";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import { invalidateData } from "../../core/utilities/redux/actions/actions";
import {
  fetchUrlData,
  getUrlData,
} from "../../core/utilities/redux/componentActions";
import { ToastUtility, toast_styles } from "../../core/utilities/ToastUtility";
import { AddNewRecord } from "../components/AddNewRecord";
import { DnsRecordsApi, DnsUserApi } from "../DnsAPIs";
import {
  DNS_DOMAIN_RECORDS_DATA,
  DNS_USER_DATA,
  URL_DNS_DOMAIN_RECORDS,
} from "../DnsConstants";
import { DnsDomainRecord, DnsUser } from "../DnsModels";

function RecordList(props) {
  const domain: any = props.match.params.id;

  const { is_loading_dns_user, dns_user, is_loading_dns_records, dns_records } =
    props;

  const [error, setError] = useState(null);
  const [is_enrolling_user_for_dns, setIsEnrollingUserForDns] = useState(false);
  const [is_adding_record, setIsAddingRecord] = useState(false);
  const [is_records_in_storage, setRecordsInStorage] = useState(false);
  const fetchDnsRecords: any = () => {
    fetchUrlData(
      DNS_DOMAIN_RECORDS_DATA,
      `${URL_DNS_DOMAIN_RECORDS}?domain=${domain}`,
      props,
    );
  };

  const enrollUserForDnsManagement: any = () => {
    setIsEnrollingUserForDns(true);
    setError(null);
    const dns_user_api: any = new DnsUserApi();
    dns_user_api.createUser((is_successful, user_or_error) => {
      let api_error: any = user_or_error;
      if (is_successful) {
        api_error = null;
        props.dispatch(invalidateData(URL_DNS_DOMAIN_RECORDS));
        fetchDnsRecords();
        setRecordsInStorage(true);
      }
      setIsEnrollingUserForDns(false);
      setError(api_error);
    });
  };
  useEffect(
    () =>
      window.addEventListener("resize", () => {
        enrollUserForDnsManagement();
      }),
    [],
  );

  useEffect(() => {
    enrollUserForDnsManagement();
  }, []);

  const refreshRecords: any = () => {
    props.dispatch(
      invalidateData(`${URL_DNS_DOMAIN_RECORDS}?domain=${domain}`),
    );
    fetchDnsRecords();
  };

  let main_content;
  if (error) {
    main_content = <ErrorCard text={error} />;
  } else if (is_enrolling_user_for_dns) {
    main_content = (
      <ComponentLoadingIndicator text="Enrolling you for DNS management" />
    );
  } else if (is_loading_dns_records) {
    main_content = <ComponentLoadingIndicator text="Loading records" />;
  } else if (is_records_in_storage) {
    const onClickDeleteRecord: any = (record: DnsDomainRecord) => {
      ToastUtility.toast(
        toast_styles.dark,
        `Deleting ${record.formatted_name}`,
      );
      const dns_records_api: any = new DnsRecordsApi();
      dns_records_api.deleteRecord(record, (is_successful, delete_error) => {
        if (is_successful) {
          ToastUtility.toast(
            toast_styles.success,
            `Deleted ${record.formatted_name}`,
          );
          return refreshRecords();
        }
        ToastUtility.toast(toast_styles.error, delete_error);
      });
    };

    const getActionFormat: any = (cell, row) => (
      <button
        type="button"
        className="btn btn-sm btn-outline-danger shadow-none ms-2"
        onClick={() => onClickDeleteRecord(row)}
      >
        <i className="la la-trash" />
        Delete
      </button>
    );

    const columns: any = [
      { dataField: "formatted_name", text: "Name" },
      { dataField: "type", text: "Type" },
      { dataField: "content", text: "Content" },
      {
        dataField: "",
        text: "Actions",
        formatter: getActionFormat,
      },
    ];

    main_content = (
      <Datatable columns={columns} data={dns_records} is_searchable={false} />
    );
  }

  let add_new_domain_component: object = null;
  if (is_adding_record) {
    add_new_domain_component = (
      <AddNewRecord
        domain={domain}
        cancelAddingRecord={() => setIsAddingRecord(false)}
        refreshRecords={refreshRecords}
      />
    );
  }

  return (
    <SidebarWrapper sidebar={<MainNavigationSidebar />}>
      <div className="card card-body shadow-sm border-0">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5 className="text-olitt-grey font-weight-bold">
            DNS Records for {domain}
          </h5>
          <button
            type="button"
            className="btn btn-olitt-grey shadow-none"
            onClick={() => setIsAddingRecord(true)}
          >
            <i className="la la-plus" /> Add Record
          </button>
        </div>

        {add_new_domain_component}

        {main_content}
      </div>
    </SidebarWrapper>
  );
}

RecordList.prototypes = {
  is_loading_dns_user: PropTypes.bool.isRequired,
  dns_user: PropTypes.instanceOf(DnsUser).isRequired,

  is_loading_dns_records: PropTypes.bool.isRequired,
  dns_records: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  const dns_user_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    DNS_USER_DATA,
  );
  const is_loading_dns_user: any = dns_user_data.isFetching;
  let dns_user: object = null;
  if (dns_user_data.items.length === 1) {
    dns_user = new DnsUser(dns_user_data.items[0]);
  }

  const dns_records_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    DNS_DOMAIN_RECORDS_DATA,
  );
  const is_loading_dns_records: any = dns_records_data.isFetching;
  let dns_records: null[] = [];
  if (dns_records_data.items.length > 0) {
    dns_records = dns_records_data.items.map(
      (parameters) => new DnsDomainRecord(parameters),
    );
  }

  return { is_loading_dns_user, dns_user, is_loading_dns_records, dns_records };
}

export default connect(mapStateToProps)(withRouter(RecordList));
