import PropTypes from "prop-types";
import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { getLocationProtocol } from "../../core/utilities/commonUtilities";

export function SocialShare({ site_domain, site_title, is_shop }) {
  const location_protocol: any = getLocationProtocol();
  const site_url: string = `${location_protocol}//${site_domain}`;
  let site_type: string = "site";
  if (is_shop) {
    site_type = "shop";
  }
  const share_title: string = `Check out the exciting new ${site_title} ${site_type} I build using Olitt!`;
  return (
    <div className="d-flex flex-column">
      <span className="mb-2 text-secondary h6">
        Share your exiting {site_type} on your network:
      </span>
      <div className="d-flex flex-row justify-content-center">
        <WhatsappShareButton url={site_url} title={share_title} separator=":: ">
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <TelegramShareButton
          url={site_url}
          title={share_title}
          className="ms-2"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
        <TwitterShareButton url={site_url} title={share_title} className="ms-2">
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <FacebookShareButton
          url={site_url}
          quote={share_title}
          className="ms-2"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <LinkedinShareButton url={site_url} className="ms-2">
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>
    </div>
  );
}

SocialShare.propTypes = {
  site_title: PropTypes.string.isRequired,
  site_domain: PropTypes.string.isRequired,
  is_shop: PropTypes.bool,
};

SocialShare.defaultProps = {
  is_shop: false,
};
