import { InputType } from "../constants/Enumerators";

/**
 * @class FormInput
 * @description A form input model
 */
export class FormInput {
  type: InputType;

  name: string;

  label: string;

  placeholder: string;

  value: string;

  is_required: boolean;

  is_editable: boolean;

  min: number;

  max: number;

  constructor(
    type: InputType,
    name: string,
    label: string,
    placeholder: string,
    value: string,
    is_required: boolean,
    is_editable: boolean,
    min: number,
    max: number,
  ) {
    this.type = type;
    this.name = name;
    this.label = label;
    this.placeholder = placeholder;
    this.value = value;
    this.is_required = is_required;
    this.is_editable = is_editable;
    this.min = min;
    this.max = max;
    this.oninputChange = () => {};
    this.options = [];
    this.selectIsMulti = false;
  }
}

/**
 * @class LabeledFormInput
 * @description Labeled Form Input
 */
export class LabeledFormInput {
  type: InputType;

  name: string;

  label: string;

  placeholder: string;

  value: string;

  is_required: boolean;

  is_editable: boolean;

  min: number;

  max: number;

  oninputChange: (value: string) => void;

  options: any[];

  constructor(params: any) {
    this.type = params.type;
    this.name = params.name;
    this.label = params.label;
    this.placeholder = params.placeholder;
    this.value = params.value;
    this.is_required = params.is_required;
    this.is_editable = params.is_editable;
    this.min = params.min;
    this.max = params.max;
    this.oninputChange = params.oninputChange || (() => {});
    this.options = params.options || [];
    this.selectIsMulti = params.selectIsMulti;
  }
}
