import { cmdCustomOpenLayers } from "../consts";

export const OpenLayers = {
  run(editor, sender, opts = {}) {
    if (!this.layers) {
      const lm = editor.LayerManager;
      const pn = editor.Panels;

      const id = "views-container";
      const layers = document.createElement("div");
      const panels = pn.getPanel(id) || pn.addPanel({ id });

      layers.appendChild(lm.render());

      panels.set("appendContent", layers).trigger("change:appendContent");
      this.layers = layers;

      this.lm = lm;
      this.popUp = editor.PopUp;
      this.editor = editor;

      editor.on("pop-up:close", () => {
        editor.Commands.stop(cmdCustomOpenLayers);
      });
    }
    this.openLayers(opts);
  },

  openLayers(opts) {
    const { layers, popUp } = this;
    layers.style.display = "block";
    layers.style.minHeight = "300px";

    popUp.setContent(layers);
    popUp.open({
      selector: opts.selector,
    });
  },

  stop() {
    const { layers, editor } = this;
    if (layers) layers.style.display = "none";

    editor.Commands.stop(cmdCustomOpenLayers);
  },
};

export default OpenLayers;
