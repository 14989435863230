/**
 * Payment model
 */

export class Payment {
  id: number;

  order: string;

  payment_method: string;

  source: string;

  amount: string;

  state: string;

  response_code: string;

  source_id: string;

  created_at: Date;

  constructor(parameters: Object) {
    this.id = parameters.id;
    this.order = parameters.order;
    this.source = parameters.source;
    this.amount = parameters.amount;
    this.state = parameters.state;
    this.response_code = parameters.response_code;
    this.source_id = parameters.source_id;
    this.created_at = parameters.created_at;
  }
}

export { Payment as default };
