import moment from "moment";

const transformFilterParams = (filterParams, allowedParams) => {
  return Object.fromEntries(
    Object.entries(filterParams)
      .filter(([key]) => allowedParams.includes(key))
      .map(([key, value]) => [
        key === "dateFrom" ? "start_date" : key === "dateTo" ? "end_date" : key,
        value,
      ]),
  );
};

export const combineParams = (
  filterParams,
  extraParams,
  allowedParams = ["dateFrom", "dateTo"],
) => {
  const transformedParameters = transformFilterParams(
    filterParams,
    allowedParams,
  );
  return { ...transformedParameters, ...extraParams };
};

const predefinedColors = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#32ce32",
  "#8e37e0",
  "#FFFF00",
  "#008080",
];

export const prepareChartData = (analyticsData) => {
  let datalabels = [];
  let Data = [];

  if (
    analyticsData?.dimensions?.rows &&
    analyticsData.dimensions.rows.length > 0
  ) {
    datalabels = analyticsData.dimensions.rows.map((row) => {
      if (row.dimensions[0] === "(not set)") {
        return "Unknown";
      }
      return row.dimensions[0];
    });
    Data = analyticsData.dimensions.rows.map((row) =>
      parseInt(row.metrics[0], 10),
    );
  }

  const combinedData = datalabels.map((label, index) => ({
    label,
    value: Data[index],
  }));

  const topData = combinedData.sort((a, b) => b.value - a.value).slice(0, 7);

  const topLabels = topData.map((item) => item.label);
  const topValues = topData.map((item) => item.value);

  const backgroundColors = predefinedColors.slice(0, topValues.length);

  return {
    labels: topLabels,
    datasets: [
      {
        data: topValues,
        backgroundColor: backgroundColors,
        borderColor: "#ffffff",
        borderWidth: 2,
      },
    ],
  };
};

export const generateLabels = (startDate, endDate, intervalUnit, format) => {
  const labels = [];
  const current = moment(startDate);

  while (current.isSameOrBefore(endDate)) {
    labels.push(current.format(format));
    current.add(1, intervalUnit);
  }

  return labels;
};

export const getTimeInterval = (filterParams) => {
  const startDate = moment.utc(filterParams.dateFrom).startOf("day");
  const endDate = moment.utc(filterParams.dateTo).endOf("day");
  const durationDays = endDate.diff(startDate, "days");

  let intervalUnit = "day";
  let format = "MMM DD";

  if (durationDays <= 1) {
    intervalUnit = "hour";
    format = "MMM D ha";
  } else if (durationDays > 90) {
    intervalUnit = "month";
    format = "MMM YYYY";
  }

  const timeLabels = generateLabels(startDate, endDate, intervalUnit, format);
  return { timeLabels, intervalUnit, format };
};
