import "./scss/custom.scss";
import "./css/animate.min.css";
import "olitt-blocks/icons.css";
import { init as flowsInit } from "@flows/js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import configureStore from "./configureStore";
import CookiesConsent from "./CookiesConsent";
import apm from "./rum";
import * as serviceWorker from "./serviceWorker";
import { GOOGLE_CLIENT_ID } from "modules/account/AccountConstants";
import { APP_NAME } from "modules/core/constants/Constants";

const store: any = configureStore();
require("bootstrap");

apm.setInitialPageLoadName(APP_NAME);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <CookiesConsent />
        <App />
      </GoogleOAuthProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
