import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import SelectInput from "modules/base/components/inputs/SelectInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import { closeModalById } from "modules/base/utilities/Actions";
import MarketingAPI from "modules/marketing/MarketingAPI";

function SendEmailTemplate({ selectedSegmentsIds, match, currentModalId }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const {
    emailTemplates,
    error: fetchTemplatesError,
    isLoading,
  } = API.getEmailTemplates();

  const [errors, setErrors] = useState(null);
  const [template, setTemplate] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setIsSubmitting(true);
      API.sendEmailToSegments(
        template,
        selectedSegmentsIds,
        () => {
          setSuccess({ message: "Email sent successfully" });
          setIsSubmitting(false);
          closeModalById(currentModalId);
        },
        (error_response) => {
          setErrors(error_response.message);
          setIsSubmitting(false);
        },
      );
    },
    [template, selectedSegmentsIds, currentModalId],
  );

  const onTemplateChange = useCallback((selectedOption) => {
    setTemplate(selectedOption.value);
  }, []);

  const templateOptions = emailTemplates.map((template) => ({
    value: template.id,
    label: template.name,
  }));

  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  if (errors) {
    const errorMessages = errors.map((error, index) => (
      <NetworkMessageDisplay key={index} error={error} />
    ));
    return { errorMessages };
  }

  return (
    <Form
      handleSubmit={onSubmit}
      id="send-email-template-form"
      button_color="gray-900"
      button_label="Send email"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      <NetworkMessageDisplay error={fetchTemplatesError} />
      <SuccessActionIndicator success={success} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <SelectInput
          name="template"
          title="Choose template"
          options={templateOptions}
          onChange={onTemplateChange}
          predicate={(option) => template === option.value}
        />
      </div>
    </Form>
  );
}

SendEmailTemplate.defaultProps = {
  currentModalId: null,
};

SendEmailTemplate.propTypes = {
  selectedSegmentsIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string,
};

export default withRouter(SendEmailTemplate);
