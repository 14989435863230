import { hot_jar_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const { hotJarLabel, hotJarCategory, hotJarBlock } = opts;

  hotJarBlock &&
    bm.add("HOT-JAR-BLOCK", {
      label: hotJarLabel,
      activate: true,
      select: true,
      category: hotJarCategory,
      content: { type: "HOT-JAR-COMPONENT" },
      media: hot_jar_logo,
    });
};
