import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { getCurrentDate } from "modules/marketing/components/dashboard/dateUtils";
import { filterConfig } from "modules/marketing/components/dashboard/filterConfig";

const FilterButton = ({ label, activeFilter, handleFilterChange }) => (
  <button
    type="button"
    className={`btn btn-outline-primary ${
      activeFilter === label ? "active" : ""
    }`}
    onClick={() => handleFilterChange(label)}
  >
    {label}
  </button>
);

FilterButton.propTypes = {
  label: PropTypes.string.isRequired,
  activeFilter: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

const TimeFilters = ({ state, onFiltersChange }) => {
  const [activeFilter, setActiveFilter] = useState("1M");

  useEffect(() => {
    onFiltersChange(state);
  }, [state, onFiltersChange]);

  const handleFilterChange = useCallback(
    (filter) => {
      setActiveFilter(filter);
      const { func, timeUnit } = filterConfig[filter] || {};
      if (func) {
        onFiltersChange({
          ...state,
          dateFrom: func(),
          dateTo: getCurrentDate(),
          timeUnit,
        });
      }
    },
    [onFiltersChange, state],
  );

  return (
    <div
      className="btn-group btn-group-sm mb-3 d-flex justify-content-center"
      role="group"
    >
      {Object.keys(filterConfig).map((filter) => (
        <FilterButton
          key={filter}
          label={filter}
          activeFilter={activeFilter}
          handleFilterChange={handleFilterChange}
        />
      ))}
    </div>
  );
};

TimeFilters.propTypes = {
  state: PropTypes.shape({
    dateFrom: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired,
    timeUnit: PropTypes.string.isRequired,
  }).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};

export default TimeFilters;
