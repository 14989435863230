import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Icon } from "../../base/components/Icons";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import { FormNumberInput } from "../../core/components/form-controls/FormNumberInput";
import { AuthenticationApi } from "../AccountAPIs";

export function VerifyOTP({ signature_hash, email, onSuccess }) {
  const [error, setError] = useState(null);
  const [hash, setHash] = useState("");
  const [otp_code, setOtpCode] = useState("");
  const [banner, setBanner] = useState(null);
  const [is_verifying_otp_code, setIsVerifyingOtp] = useState(false);

  useEffect(() => {
    const banner_message: string = `We have sent a one-time verification code to '${email}'.
         Kindly check your inbox and use it to verify your account.`;
    setBanner(banner_message);
    setHash(signature_hash);
  }, []);
  const authenticationApi: any = new AuthenticationApi();

  function resendOtpCode() {
    setError(null);
    setBanner(null);
    authenticationApi.resendOtpCode(
      email,
      (is_successful, message_or_error) => {
        if (is_successful) {
          setBanner(message_or_error.message);
          setHash(message_or_error.hash);
        } else {
          setError(message_or_error);
        }
      },
    );
  }

  function verifyOtpCode(event) {
    event.preventDefault();
    setIsVerifyingOtp(true);
    setError(null);
    setBanner(null);
    authenticationApi.verifyOtpCode(
      hash,
      email,
      otp_code,
      (is_successful, tokens_or_error) => {
        setIsVerifyingOtp(false);
        if (is_successful) {
          onSuccess(setIsVerifyingOtp);
        } else {
          setError(tokens_or_error);
        }
      },
    );
  }

  let verify_btn_text;
  if (is_verifying_otp_code) {
    verify_btn_text = <ComponentLoadingIndicator />;
  } else {
    verify_btn_text = <span className="text-uppercase"> Verify OTP Code </span>;
  }

  return (
    <div className="w-100">
      <div
        role="alert"
        className="alert alert-danger alert-dismissible fade show"
        hidden={!error}
      >
        <small> {error} </small>
        <button type="button" className="close" onClick={() => setError(null)}>
          <Icon icon="x" />
        </button>
      </div>

      <div
        role="alert"
        className="alert alert-success alert-dismissible fade show"
        hidden={error || !banner}
      >
        <small> {banner} </small>
        <button
          type="button"
          className="close"
          onClick={() => setBanner(false)}
        >
          <Icon icon="x" />
        </button>
      </div>

      <form onSubmit={verifyOtpCode}>
        <FormNumberInput
          name="otp"
          placeholder="Enter the code you received via email"
          text={otp_code}
          is_required
          is_editable={!is_verifying_otp_code}
          onChangeText={(e) => setOtpCode(e.target.value)}
        />

        <button
          type="button"
          className="btn btn-sm btn-transparent mb-1 pt-0 w-100 shadow-none"
          onClick={resendOtpCode}
        >
          Resend OTP Code
        </button>

        <button
          type="submit"
          className="btn btn-olitt-grey w-100 d-flex align-items-center shadow-none"
          disabled={is_verifying_otp_code}
        >
          <div className="d-flex w-100 align-items-center justify-content-center">
            {verify_btn_text}
          </div>
        </button>
      </form>
    </div>
  );
}

VerifyOTP.propTypes = {
  signature_hash: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
