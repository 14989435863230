import PropTypes from "prop-types";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { Icon } from "modules/base/components/Icons";
import Backdrop from "modules/core/components/Backdrop";
import MenuItems from "modules/core/components/MenuItems";
import {
  closed_sidebar_width,
  open_sidebar_width,
} from "modules/core/components/SidebarWrapper";
import { Menu } from "modules/core/models/Menu";
import {
  navigateToPage,
  navigateToUrl,
} from "modules/core/utilities/navigation/Navigator";
import WebsiteDomain from "modules/website/components/WebsiteDomain";
import { Site } from "modules/website/WebsiteModels";

function getBottomItem(
  bottom_action,
  sidebar_width,
  is_showing_sidebar,
  showSidebar,
  navigateSideBar,
) {
  let bottom_item;
  if (bottom_action) {
    let bottom_item_actions;
    let bottom_action_classes =
      "d-flex align-items-center link-dark text-decoration-none";
    let action_title_display_classes: string = "";
    if (sidebar_width === closed_sidebar_width && !is_showing_sidebar) {
      action_title_display_classes = "d-none";
    }
    if (bottom_action.actions) {
      bottom_action_classes = `${bottom_action_classes} align-items-center dropdown-toggle`;
      const bottom_item_action_items = bottom_action.actions.map(
        (action: any) => {
          let onClick: any = () => {
            showSidebar(!is_showing_sidebar);
            navigateSideBar(action.path, action.url);
          };
          if (action.onClick) {
            onClick = () => {
              showSidebar(!is_showing_sidebar);
              action.onClick();
            };
          }
          const key: any = action.title.concat(action.url).replace(" ", "_");
          return (
            <li key={key}>
              <button className="dropdown-item" type="button" onClick={onClick}>
                {action.icon && (
                  <Icon icon={action.icon} additionalClasses="me-2" />
                )}
                {action.title}
              </button>
            </li>
          );
        },
      );
      bottom_item_actions = (
        <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
          {bottom_item_action_items}
        </ul>
      );
    }
    if (bottom_action.actions) {
      bottom_item = (
        <div className="btn-group">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className={bottom_action_classes}
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {bottom_action.icon && (
              <Icon icon={bottom_action.icon} additionalClasses="me-2" />
            )}
            <span className={action_title_display_classes}>
              {bottom_action.title}
            </span>
          </a>
          {bottom_item_actions}
        </div>
      );
    } else {
      bottom_item = (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          className={bottom_action_classes}
          onClick={() => navigateSideBar(bottom_action.path)}
        >
          {bottom_action.icon && (
            <Icon icon={bottom_action.icon} additionalClasses="me-2" />
          )}
          <span className={action_title_display_classes}>
            {bottom_action.title}
          </span>
        </a>
      );
    }
  }
  return bottom_item;
}

function Sidebar(props) {
  const {
    sidebar_banner,
    sidebar_banner_width = 50,
    sidebar_width,
    actions,
    bottom_action,
    site,
  } = props;

  const [is_showing_overlay, showOverlay] = useState(false);
  const [is_showing_sidebar, showSidebar] = useState(false);

  const navigateSideBar: any = (path, url = null) => {
    if (url) {
      navigateToUrl(url);
    } else {
      navigateToPage(path, props);
      showOverlay(false);
    }
  };

  const toggleSidebar = () => {
    showOverlay(!is_showing_overlay);
    if (sidebar_width === closed_sidebar_width) {
      showSidebar(!is_showing_sidebar);
    }
  };

  let backdrop;
  let width: any = sidebar_width || closed_sidebar_width;
  let sidebarToggleDisplayClasses: string = "d-none";
  let banner_classes: string = "mt-6";
  if (sidebar_width === closed_sidebar_width && !is_showing_sidebar) {
    sidebarToggleDisplayClasses = "btn shadow-none fs-2";
    banner_classes = "d-none";
  }
  if (sidebar_width === closed_sidebar_width) {
    if (is_showing_overlay) {
      width = open_sidebar_width;
      backdrop = <Backdrop onClick={() => toggleSidebar()} />;
    }
  }

  const sidebar_style: {
    width: any,
    top: number,
    left: number,
  } = { width, top: 0, left: 0 };

  if (sidebar_banner && sidebar_banner_width !== 100) {
    banner_classes = `${banner_classes} ms-4`;
  }
  const bottom_item = getBottomItem(
    bottom_action,
    sidebar_width,
    is_showing_sidebar,
    showSidebar,
    navigateSideBar,
  );

  let siteDomain = null;
  if (site) {
    siteDomain = <WebsiteDomain website={site} view_mode="sites" />;
  }

  return (
    <>
      {backdrop}
      <div
        className="z-1 position-fixed overflow-auto d-flex flex-column
        flex-shrink-0 p-1 text-dark bg-light h-100 border-end border-1 border-gray mt-5 mt-md-0"
        style={sidebar_style}
      >
        <button
          type="button"
          className={sidebarToggleDisplayClasses}
          onClick={() => toggleSidebar()}
        >
          <Icon icon="list" />
        </button>
        <img
          className={banner_classes}
          width={`${sidebar_banner_width}%`}
          role="button"
          src={sidebar_banner}
          alt=""
        />
        {siteDomain}
        <hr />
        <ul className="nav nav-pills flex-column mb-auto text-start">
          <MenuItems
            actions={actions}
            site={site}
            toggleSidebar={toggleSidebar}
            sidebar_width={sidebar_width}
            showSidebar={showSidebar}
            navigateSideBar={navigateSideBar}
          />
        </ul>
        <hr />
        {bottom_item}
      </div>
    </>
  );
}

Sidebar.propTypes = {
  sidebar_width: PropTypes.string,
  sidebar_banner: PropTypes.string,
  sidebar_banner_width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  actions: PropTypes.instanceOf(Object),
  bottom_action: PropTypes.instanceOf(Menu),
  site: PropTypes.instanceOf(Site),
};

Sidebar.defaultProps = {
  sidebar_width: null,
  sidebar_banner: null,
  sidebar_banner_width: null,
  actions: [],
  bottom_action: [],
  site: null,
};

export default withRouter(Sidebar);
