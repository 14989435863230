import { API_BASE_URL, WS_BASE_URL } from "../core/constants/Endpoints";
import { PATH_MAIN_ROUTE } from "../core/constants/Routes";

/**
 * API Endpoints
 */
export const URL_CHECK_PHISHING_DOMAIN: string = `${API_BASE_URL}/loudgun/phishing-check/`;
export const URL_STREAM_PHISHING_CHECK: string = `${WS_BASE_URL}/loudgun/stream-phishing-check/`;
/**
 * Routes
 */
export const PATH_LOUDGUN: string = `${PATH_MAIN_ROUTE}/loudgun`;
export const PATH_CHECK_PHISHING_DOMAIN: string = `${PATH_LOUDGUN}/check-domain/`;
