import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import ManageContactStages from "modules/marketing/components/contacts/ManageContactStages";

function ManageContactStagesModal({ selectedContactsIds }) {
  const modalId = "manage-contact-stages-modal";
  return (
    <Modal modal_title="Add Contact to Stage" modal_id={modalId} size="md">
      <ManageContactStages
        selectedContactsIds={selectedContactsIds}
        currentModalId={modalId}
      />
    </Modal>
  );
}

ManageContactStagesModal.propTypes = {
  selectedContactsIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
};

export default ManageContactStagesModal;
