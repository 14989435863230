import PropTypes from "prop-types";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { User } from "../../account/AccountModels";
import { ShareButtons } from "../../core/components/ShareButtons";
import { APP_NAME } from "../../core/constants/Constants";
import {
  AFFILIATE_LINK_SCRUB,
  AFFILIATE_MESSAGE,
  AFFILIATE_SUBJECT,
} from "../ReferralConstants";
import { Affiliate } from "../ReferralModels";

function AffiliateInformation({ user, affiliate }) {
  const affiliate_link: string = `${AFFILIATE_LINK_SCRUB}${affiliate.id}`;

  return (
    <div className="card card-body shadow-sm border-0 mb-4">
      <div className="mb-3">
        <h5
          className="font-weight-bold flex-grow-1"
          data-tutorial="welcome-affiliate"
        >
          Affiliate Program
        </h5>
        <span className="text-secondary">
          To show our appreciation for using {APP_NAME}, share the link below
          with your friends and we'll share 20% of earnings from their purchases
          with you.
        </span>
      </div>

      <div className="card-body border rounded mb-4">
        <div className="row no-gutters">
          <div className="col-md my-3">
            <span className="text-primary">{affiliate_link}</span>
          </div>
          <div className="col-md-auto my-3">
            <ShareButtons
              is_vertical={false}
              hashtag="#olitt"
              subject={AFFILIATE_SUBJECT}
              url={affiliate_link}
              message={AFFILIATE_MESSAGE}
            />
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <h3 className="col-12 text-center" data-tutorial="affiliate-video">
          Three Easy Steps
        </h3>
        <div className="col-lg-4 col-sm-4">
          <div className="card card-body border-0 rounded text-center me-3">
            <h5 className="text-olitt-grey text-secondary">
              <i className="fa fa-bullhorn fa-2x" aria-hidden="true" />
            </h5>
            <span className="">
              Spread the word by email or with your link via social sharing
            </span>
          </div>
        </div>
        <div className="col-lg-4 col-sm-4">
          <div className="card card-body border-0 rounded text-center mx-2">
            <h5 className="text-olitt-grey text-secondary">
              <i className="fa fa-address-book-o fa-2x" aria-hidden="true" />
            </h5>
            <span>
              Your friend signs up to Olitt & gets 20% off their first purchase
            </span>
          </div>
        </div>
        <div className="col-lg-4 col-sm-4">
          <div className="card card-body border-0 rounded text-center ms-3">
            <h5 className="text-olitt-grey text-secondary">
              <i className="fa fa-money fa-2x" aria-hidden="true" />
            </h5>
            <span>You get 20% of their first order amount on Olitt</span>
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="col-lg-4 col-sm-4">
          <div className="card card-body border-0 rounded text-center me-3">
            <h5 className="text-olitt-grey font-weight-bold">Clicks</h5>
            <span className="display-4 text-secondary">
              {affiliate.visitors}
            </span>
          </div>
        </div>
        <div className="col-lg-4 col-sm-4">
          <div className="card card-body border-0 rounded text-center mx-2">
            <h5 className="text-olitt-grey font-weight-bold">Balance</h5>
            <span className="display-4 text-secondary">
              {user.currency} {affiliate.balance}
            </span>
          </div>
        </div>
        <div className="col-lg-4 col-sm-4">
          <div className="card card-body border-0 rounded text-center ms-3">
            <h5 className="text-olitt-grey font-weight-bold">Withdrawn</h5>
            <span className="display-4 text-secondary">
              {user.currency} {affiliate.withdrawn}
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button type="button" className="btn btn-sm btn-primary pull-right">
            <a
              className="text-white text-decoration-none"
              href={`mailto:olitt.com@gmail.com?subject=Withdrawal Request. Affiliate ID: ${affiliate.id}&body=Hi, I would like to request for a withdrawal of my earnings. Thank you.`}
            >
              Request Withdrawal
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}

AffiliateInformation.propTypes = {
  user: PropTypes.instanceOf(User).isRequired,
  affiliate: PropTypes.instanceOf(Affiliate).isRequired,
};

export default AffiliateInformation;
