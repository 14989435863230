import { ApmRoute as Route } from "@elastic/apm-rum-react";
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import {
  PATH_ACCOUNT,
  PATH_LOGIN,
  PATH_PROFILE,
  PATH_RESET_PASSWORD,
} from "./AccountConstants";
import Login from "./layout/Login";
import Page404 from "./layout/Page404";
import Profile from "./layout/Profile";
import ResetPassword from "./layout/ResetPassword";

function AccountRouter() {
  return (
    <Switch>
      <Route key={4.1} exact path={PATH_LOGIN} component={Login} />
      <Route
        key={4.2}
        exact
        path={PATH_RESET_PASSWORD}
        component={ResetPassword}
      />
      <Route key={4.3} exact path={PATH_PROFILE} component={Profile} />
      <Redirect key={4.4} exact from={PATH_ACCOUNT} to={PATH_PROFILE} />
      <Route key={4.5} path="*" component={Page404} />
    </Switch>
  );
}

export default AccountRouter;
