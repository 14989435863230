import FormSubmissionsList from "modules/marketing/components/forms/FormSubmissionsList";
import SubscriptionCheck from "modules/marketing/layouts/SubscriptionCheck";
import { MARKETING_FORMS_SLUG } from "modules/marketing/MarketingConstants";

function FormSubmissions() {
  return (
    <SubscriptionCheck
      slug={MARKETING_FORMS_SLUG}
      component={<FormSubmissionsList />}
    />
  );
}

export default FormSubmissions;
