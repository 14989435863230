import PropTypes from "prop-types";
import { memo, useState } from "react";
import { Handle, Position } from "reactflow";
import ConfigureButton from "modules/marketing/components/campaigns/nodes/configure/ConfigureButton";
import DeleteButton from "modules/marketing/components/campaigns/nodes/configure/DeleteButton";
import TargetHandle from "modules/marketing/components/campaigns/nodes/TargetHandle";
import {
  CAMPAIGN_DECISION_NO_LINE_COLOR,
  CAMPAIGN_DECISION_YES_LINE_COLOR,
} from "modules/marketing/MarketingConstants";

const DEFAULT_HANDLE_STYLE = {
  width: 10,
  height: 10,
  bottom: -5,
};

const DecisionNode = memo(function DecisionNode({ data, isConnectable }) {
  const {
    campaignEvent,
    currentModalId,
    updateNode,
    previouslyNewNode,
    updateEvent,
  } = data;
  const [state, setState] = useState(campaignEvent);
  return (
    <>
      <ConfigureButton
        campaignEvent={state}
        currentModalId={currentModalId}
        setState={setState}
        previouslyNewNode={previouslyNewNode}
        updateNode={updateNode}
        updateEvent={updateEvent}
      />
      <TargetHandle />
      <div className="badge text-bg-success p-2">
        <i className="bi bi-signpost-split-fill" /> {state.name}
        <Handle
          type="source"
          id={CAMPAIGN_DECISION_YES_LINE_COLOR}
          position={Position.Bottom}
          style={{ ...DEFAULT_HANDLE_STYLE, left: "15%", background: "green" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id={CAMPAIGN_DECISION_NO_LINE_COLOR}
          style={{ ...DEFAULT_HANDLE_STYLE, left: "85%", background: "red" }}
          isConnectable={isConnectable}
        />
      </div>
      <DeleteButton nodeId={campaignEvent.id} />
    </>
  );
});

DecisionNode.defaultProps = {
  data: {},
  isConnectable: true,
};

DecisionNode.propTypes = {
  data: PropTypes.instanceOf(Object),
  isConnectable: PropTypes.bool,
};

export default DecisionNode;
