import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import { openModal } from "modules/base/utilities/Actions";
import publishUnpublishActionColumn from "modules/marketing/components/PublishUnpublishActionColumn";
import CreateStageModal from "modules/marketing/components/stages/CreateStageModal";
import EditStageModal from "modules/marketing/components/stages/EditStageModal";
import { stagesColumns } from "modules/marketing/components/tableColumns";
import MarketingAPI from "modules/marketing/MarketingAPI";

function StageList({ match }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const [paginationParams, setPaginationParams] = useState({
    start: 0,
    limit: 10,
  });
  const params = useMemo(
    () => ({
      ...paginationParams,
      orderBy: "dateAdded",
      orderByDir: "desc",
    }),
    [paginationParams],
  );
  const { stages, total, error: fetchError, isLoading } = API.getStages(params);

  const [error, setError] = useState(null);

  const onClickCreate = useCallback(() => {
    openModal("create-stage-modal");
  });

  const onClickEdit = useCallback((stage) => {
    openModal(`edit-stage-modal-${stage.id}`);
  });

  const deleteStage = useCallback(
    (id, onSuccess, onError) => {
      const handleSuccess = () => {
        onSuccess();
      };

      const handleError = (e) => {
        onError("Failed", e.message);
      };

      API.deleteStage(id, handleSuccess, handleError);
    },
    [API],
  );
  const handlePaginationChange = useCallback((newPaginationParams) => {
    setPaginationParams(newPaginationParams);
  }, []);
  const columns = [
    ...stagesColumns,
    publishUnpublishActionColumn(siteId, setError, "updateStage"),
  ];

  return (
    <>
      <CreateStageModal />
      <NetworkMessageDisplay error={fetchError || error} />
      <DataTable
        data={stages}
        columns={columns}
        total={total}
        isLoading={isLoading}
        allowCreate
        onClickCreate={onClickCreate}
        enableSearch
        createButtonColor="gray-900"
        allowEdit
        onClickEdit={onClickEdit}
        onClickDelete={deleteStage}
        allowDelete
        EditModal={EditStageModal}
        objectType="stage"
        editButtonColor="gray-900"
        additionalTopRightButtons={[]}
        onPaginationChange={handlePaginationChange}
        isServerSidePagination
      />
    </>
  );
}

StageList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(StageList);
