import PropTypes from "prop-types";
import React from "react";
import { OlittModal } from "../../core/components/OlittModal";
import { SocialShare } from "../layouts/SocialShare";

export function ShareSiteModal({
  modal_id,
  is_visible,
  onClose,
  site_domain,
  site_title,
}) {
  return (
    <OlittModal
      modal_id={modal_id}
      show={is_visible}
      onClose={onClose}
      size="md"
      height={15}
    >
      <div className="d-flex flex-row justify-content-center">
        <SocialShare site_domain={site_domain} site_title={site_title} />
      </div>
    </OlittModal>
  );
}

ShareSiteModal.propTypes = {
  modal_id: PropTypes.string.isRequired,
  is_visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  site_domain: PropTypes.string.isRequired,
  site_title: PropTypes.string.isRequired,
};
