import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DataTable from "../../../../core/components/datagrid/DataTable";
import { getUrlData } from "../../../../core/utilities/redux/componentActions";
import useOrders from "../../../hooks/useOrders";
import { ORDER_TABLE_COLUMNS } from "../../../ShopConstants";

const SHOP_DETAILS_DATA_KEY = "SHOP_DETAILS_DATA_KEY";

function OrdersTable(props) {
  const { match } = props;
  const { params } = match;
  const site_id = params.id;
  const { isLoading, orders } = useOrders(site_id);

  if (isLoading) {
    return (
      <div className="p-4 rounded-3 w-100 vh-40 d-block overflow-hidden mt-4 w-100 h-100 skeleton-loading" />
    );
  }

  return (
    <div className="shadow p-4 rounded-3 w-100 h-auto d-block overflow-hidden mt-4 p-4">
      <h3>Orders</h3>
      <DataTable data={orders} columns={ORDER_TABLE_COLUMNS} rowSize={6} />
    </div>
  );
}

OrdersTable.defaultProps = {};
OrdersTable.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const shop_details_data = getUrlData(
    dataByUrl,
    sessionVariables,
    SHOP_DETAILS_DATA_KEY,
  );
  return {
    isLoadingShop: shop_details_data.isFetching,
    shop: shop_details_data.items,
  };
}

export default connect(mapStateToProps)(withRouter(OrdersTable));
