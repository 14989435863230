import React from "react";

export default class Label extends React.Component {
  render() {
    const { icon } = this.props;
    const { text } = this.props;
    const { is_important } = this.props;

    return (
      <label className="form-label">
        {icon && <i /> /* Fixme: Add icon to be displayed when not null */}
        {text}
        {is_important && <span className="text-danger px-1">*</span>}
      </label>
    );
  }
}
