export const GOOGLE_FONTS: any = [
  { value: "ABeeZee", name: "ABeeZee" },
  { value: "Abel", name: "Abel" },
  { value: "Abril Fatface", name: "Abril Fatface" },
  { value: "Aclonica", name: "Aclonica" },
  { value: "Acme", name: "Acme" },
  { value: "Actor", name: "Actor" },
  { value: "Adamina", name: "Adamina" },
  { value: "Advent Pro", name: "Advent Pro" },
  { value: "Aguafina Script", name: "Aguafina Script" },
  { value: "Akronim", name: "Akronim" },
  { value: "Aladin", name: "Aladin" },
  { value: "Aldrich", name: "Aldrich" },
  { value: "Alef", name: "Alef" },
  { value: "Alegreya", name: "Alegreya" },
  { value: "Alegreya SC", name: "Alegreya SC" },
  { value: "Alegreya Sans", name: "Alegreya Sans" },
  { value: "Alegreya Sans SC", name: "Alegreya Sans SC" },
  { value: "Alex Brush", name: "Alex Brush" },
  { value: "Alfa Slab One", name: "Alfa Slab One" },
  { value: "Alice", name: "Alice" },
  { value: "Alike", name: "Alike" },
  { value: "Alike Angular", name: "Alike Angular" },
  { value: "Allan", name: "Allan" },
  { value: "Allerta", name: "Allerta" },
  { value: "Allerta Stencil", name: "Allerta Stencil" },
  { value: "Allura", name: "Allura" },
  { value: "Almarai", name: "Almarai" },
  { value: "Almendra", name: "Almendra" },
  { value: "Almendra Display", name: "Almendra Display" },
  { value: "Almendra SC", name: "Almendra SC" },
  { value: "Amarante", name: "Amarante" },
  { value: "Amaranth", name: "Amaranth" },
  { value: "Amatic SC", name: "Amatic SC" },
  { value: "Amethysta", name: "Amethysta" },
  { value: "Amiri", name: "Amiri" },
  { value: "Anaheim", name: "Anaheim" },
  { value: "Andada", name: "Andada" },
  { value: "Andika", name: "Andika" },
  { value: "Angkor", name: "Angkor" },
  { value: "Annie Use Your Telescope", name: "Annie Use Your Telescope" },
  { value: "Anonymous Pro", name: "Anonymous Pro" },
  { value: "Antic", name: "Antic" },
  { value: "Antic Didone", name: "Antic Didone" },
  { value: "Antic Slab", name: "Antic Slab" },
  { value: "Anton", name: "Anton" },
  { value: "Arapey", name: "Arapey" },
  { value: "Arbutus", name: "Arbutus" },
  { value: "Arbutus Slab", name: "Arbutus Slab" },
  { value: "Architects Daughter", name: "Architects Daughter" },
  { value: "Archivo Black", name: "Archivo Black" },
  { value: "Archivo Narrow", name: "Archivo Narrow" },
  { value: "Aref Ruqaa", name: "Aref Ruqaa" },
  { value: "Arimo", name: "Arimo" },
  { value: "Arizonia", name: "Arizonia" },
  { value: "Armata", name: "Armata" },
  { value: "Artifika", name: "Artifika" },
  { value: "Arvo", name: "Arvo" },
  { value: "Asap", name: "Asap" },
  { value: "Asset", name: "Asset" },
  { value: "Astloch", name: "Astloch" },
  { value: "Asul", name: "Asul" },
  { value: "Atomic Age", name: "Atomic Age" },
  { value: "Aubrey", name: "Aubrey" },
  { value: "Audiowide", name: "Audiowide" },
  { value: "Autour One", name: "Autour One" },
  { value: "Average", name: "Average" },
  { value: "Average Sans", name: "Average Sans" },
  { value: "Averia Gruesa Libre", name: "Averia Gruesa Libre" },
  { value: "Averia Libre", name: "Averia Libre" },
  { value: "Averia Sans Libre", name: "Averia Sans Libre" },
  { value: "Averia Serif Libre", name: "Averia Serif Libre" },
  { value: "Bad Script", name: "Bad Script" },
  { value: "Balthazar", name: "Balthazar" },
  { value: "Bangers", name: "Bangers" },
  { value: "Basic", name: "Basic" },
  { value: "Battambang", name: "Battambang" },
  { value: "Baumans", name: "Baumans" },
  { value: "Bayon", name: "Bayon" },
  { value: "Belgrano", name: "Belgrano" },
  { value: "Belleza", name: "Belleza" },
  { value: "BenchNine", name: "BenchNine" },
  { value: "Bentham", name: "Bentham" },
  { value: "Berkshire Swash", name: "Berkshire Swash" },
  { value: "Bevan", name: "Bevan" },
  { value: "Bigelow Rules", name: "Bigelow Rules" },
  { value: "Bigshot One", name: "Bigshot One" },
  { value: "Bilbo", name: "Bilbo" },
  { value: "Bilbo Swash Caps", name: "Bilbo Swash Caps" },
  { value: "Bitter", name: "Bitter" },
  { value: "Black Ops One", name: "Black Ops One" },
  { value: "Bokor", name: "Bokor" },
  { value: "Bonbon", name: "Bonbon" },
  { value: "Boogaloo", name: "Boogaloo" },
  { value: "Bowlby One", name: "Bowlby One" },
  { value: "Bowlby One SC", name: "Bowlby One SC" },
  { value: "Brawler", name: "Brawler" },
  { value: "Bree Serif", name: "Bree Serif" },
  { value: "Bubblegum Sans", name: "Bubblegum Sans" },
  { value: "Bubbler One", name: "Bubbler One" },
  { value: "Buenard", name: "Buenard" },
  { value: "Butcherman", name: "Butcherman" },
  { value: "Butterfly Kids", name: "Butterfly Kids" },
  { value: "Cabin", name: "Cabin" },
  { value: "Cabin Condensed", name: "Cabin Condensed" },
  { value: "Cabin Sketch", name: "Cabin Sketch" },
  { value: "Caesar Dressing", name: "Caesar Dressing" },
  { value: "Cagliostro", name: "Cagliostro" },
  { value: "Cairo", name: "Cairo" },
  { value: "Calligraffitti", name: "Calligraffitti" },
  { value: "Cambo", name: "Cambo" },
  { value: "Candal", name: "Candal" },
  { value: "Cantarell", name: "Cantarell" },
  { value: "Cantata One", name: "Cantata One" },
  { value: "Cantora One", name: "Cantora One" },
  { value: "Capriola", name: "Capriola" },
  { value: "Cardo", name: "Cardo" },
  { value: "Carme", name: "Carme" },
  { value: "Carrois Gothic", name: "Carrois Gothic" },
  { value: "Carrois Gothic SC", name: "Carrois Gothic SC" },
  { value: "Carter One", name: "Carter One" },
  { value: "Caudex", name: "Caudex" },
  { value: "Cedarville Cursive", name: "Cedarville Cursive" },
  { value: "Ceviche One", name: "Ceviche One" },
  { value: "Changa", name: "Changa" },
  { value: "Changa One", name: "Changa One" },
  { value: "Chango", name: "Chango" },
  { value: "Chau Philomene One", name: "Chau Philomene One" },
  { value: "Chela One", name: "Chela One" },
  { value: "Chelsea Market", name: "Chelsea Market" },
  { value: "Chenla", name: "Chenla" },
  { value: "Cherry Cream Soda", name: "Cherry Cream Soda" },
  { value: "Cherry Swash", name: "Cherry Swash" },
  { value: "Chewy", name: "Chewy" },
  { value: "Chicle", name: "Chicle" },
  { value: "Chivo", name: "Chivo" },
  { value: "Cinzel", name: "Cinzel" },
  { value: "Cinzel Decorative", name: "Cinzel Decorative" },
  { value: "Clicker Script", name: "Clicker Script" },
  { value: "Coda", name: "Coda" },
  { value: "Codystar", name: "Codystar" },
  { value: "Combo", name: "Combo" },
  { value: "Comfortaa", name: "Comfortaa" },
  { value: "Coming Soon", name: "Coming Soon" },
  { value: "Concert One", name: "Concert One" },
  { value: "Condiment", name: "Condiment" },
  { value: "Content", name: "Content" },
  { value: "Contrail One", name: "Contrail One" },
  { value: "Convergence", name: "Convergence" },
  { value: "Cookie", name: "Cookie" },
  { value: "Copse", name: "Copse" },
  { value: "Corben", name: "Corben" },
  { value: "Courgette", name: "Courgette" },
  { value: "Cousine", name: "Cousine" },
  { value: "Coustard", name: "Coustard" },
  { value: "Covered By Your Grace", name: "Covered By Your Grace" },
  { value: "Crafty Girls", name: "Crafty Girls" },
  { value: "Creepster", name: "Creepster" },
  { value: "Crete Round", name: "Crete Round" },
  { value: "Crimson Text", name: "Crimson Text" },
  { value: "Croissant One", name: "Croissant One" },
  { value: "Crushed", name: "Crushed" },
  { value: "Cuprum", name: "Cuprum" },
  { value: "Cutive", name: "Cutive" },
  { value: "Cutive Mono", name: "Cutive Mono" },
  { value: "Damion", name: "Damion" },
  { value: "Dancing Script", name: "Dancing Script" },
  { value: "Dangrek", name: "Dangrek" },
  { value: "Dawning of a New Day", name: "Dawning of a New Day" },
  { value: "Days One", name: "Days One" },
  { value: "Delius", name: "Delius" },
  { value: "Delius Swash Caps", name: "Delius Swash Caps" },
  { value: "Delius Unicase", name: "Delius Unicase" },
  { value: "Della Respira", name: "Della Respira" },
  { value: "Denk One", name: "Denk One" },
  { value: "Devonshire", name: "Devonshire" },
  { value: "Didact Gothic", name: "Didact Gothic" },
  { value: "Diplomata", name: "Diplomata" },
  { value: "Diplomata SC", name: "Diplomata SC" },
  { value: "Domine", name: "Domine" },
  { value: "Donegal One", name: "Donegal One" },
  { value: "Doppio One", name: "Doppio One" },
  { value: "Dorsa", name: "Dorsa" },
  { value: "Dosis", name: "Dosis" },
  { value: "Dr Sugiyama", name: "Dr Sugiyama" },
  { value: "Droid Sans", name: "Droid Sans" },
  { value: "Droid Sans Mono", name: "Droid Sans Mono" },
  { value: "Droid Serif", name: "Droid Serif" },
  { value: "Duru Sans", name: "Duru Sans" },
  { value: "Dynalight", name: "Dynalight" },
  { value: "EB Garamond", name: "EB Garamond" },
  { value: "Eagle Lake", name: "Eagle Lake" },
  { value: "Eater", name: "Eater" },
  { value: "Economica", name: "Economica" },
  { value: "Electrolize", name: "Electrolize" },
  { value: "El Messiri", name: "El Messiri" },
  { value: "Elsie", name: "Elsie" },
  { value: "Elsie Swash Caps", name: "Elsie Swash Caps" },
  { value: "Emblema One", name: "Emblema One" },
  { value: "Emilys Candy", name: "Emilys Candy" },
  { value: "Engagement", name: "Engagement" },
  { value: "Englebert", name: "Englebert" },
  { value: "Enriqueta", name: "Enriqueta" },
  { value: "Erica One", name: "Erica One" },
  { value: "Esteban", name: "Esteban" },
  { value: "Euphoria Script", name: "Euphoria Script" },
  { value: "Ewert", name: "Ewert" },
  { value: "Exo", name: "Exo" },
  { value: "Exo 2", name: "Exo 2" },
  { value: "Expletus Sans", name: "Expletus Sans" },
  { value: "Fanwood Text", name: "Fanwood Text" },
  { value: "Fascinate", name: "Fascinate" },
  { value: "Fascinate Inline", name: "Fascinate Inline" },
  { value: "Faster One", name: "Faster One" },
  { value: "Fasthand", name: "Fasthand" },
  { value: "Fauna One", name: "Fauna One" },
  { value: "Federant", name: "Federant" },
  { value: "Federo", name: "Federo" },
  { value: "Felipa", name: "Felipa" },
  { value: "Fenix", name: "Fenix" },
  { value: "Finger Paint", name: "Finger Paint" },
  { value: "Fjalla One", name: "Fjalla One" },
  { value: "Fjord One", name: "Fjord One" },
  { value: "Flamenco", name: "Flamenco" },
  { value: "Flavors", name: "Flavors" },
  { value: "Fondamento", name: "Fondamento" },
  { value: "Fontdiner Swanky", name: "Fontdiner Swanky" },
  { value: "Forum", name: "Forum" },
  { value: "Francois One", name: "Francois One" },
  { value: "Freckle Face", name: "Freckle Face" },
  { value: "Fredericka the Great", name: "Fredericka the Great" },
  { value: "Fredoka One", name: "Fredoka One" },
  { value: "Freehand", name: "Freehand" },
  { value: "Fresca", name: "Fresca" },
  { value: "Frijole", name: "Frijole" },
  { value: "Fruktur", name: "Fruktur" },
  { value: "Fugaz One", name: "Fugaz One" },
  { value: "GFS Didot", name: "GFS Didot" },
  { value: "GFS Neohellenic", name: "GFS Neohellenic" },
  { value: "Gabriela", name: "Gabriela" },
  { value: "Gafata", name: "Gafata" },
  { value: "Galdeano", name: "Galdeano" },
  { value: "Galindo", name: "Galindo" },
  { value: "Gentium Basic", name: "Gentium Basic" },
  { value: "Gentium Book Basic", name: "Gentium Book Basic" },
  { value: "Geo", name: "Geo" },
  { value: "Geostar", name: "Geostar" },
  { value: "Geostar Fill", name: "Geostar Fill" },
  { value: "Germania One", name: "Germania One" },
  { value: "Gilda Display", name: "Gilda Display" },
  { value: "Give You Glory", name: "Give You Glory" },
  { value: "Glass Antiqua", name: "Glass Antiqua" },
  { value: "Glegoo", name: "Glegoo" },
  { value: "Gloria Hallelujah", name: "Gloria Hallelujah" },
  { value: "Goblin One", name: "Goblin One" },
  { value: "Gochi Hand", name: "Gochi Hand" },
  { value: "Gorditas", name: "Gorditas" },
  { value: "Goudy Bookletter 1911", name: "Goudy Bookletter 1911" },
  { value: "Graduate", name: "Graduate" },
  { value: "Grand Hotel", name: "Grand Hotel" },
  { value: "Gravitas One", name: "Gravitas One" },
  { value: "Great Vibes", name: "Great Vibes" },
  { value: "Griffy", name: "Griffy" },
  { value: "Gruppo", name: "Gruppo" },
  { value: "Gudea", name: "Gudea" },
  { value: "Habibi", name: "Habibi" },
  { value: "Hammersmith One", name: "Hammersmith One" },
  { value: "Hanalei", name: "Hanalei" },
  { value: "Hanalei Fill", name: "Hanalei Fill" },
  { value: "Handlee", name: "Handlee" },
  { value: "Hanuman", name: "Hanuman" },
  { value: "Happy Monkey", name: "Happy Monkey" },
  { value: "Harmattan", name: "Harmattan" },
  { value: "Headland One", name: "Headland One" },
  { value: "Henny Penny", name: "Henny Penny" },
  { value: "Herr Von Muellerhoff", name: "Herr Von Muellerhoff" },
  { value: "Holtwood One SC", name: "Holtwood One SC" },
  { value: "Homemade Apple", name: "Homemade Apple" },
  { value: "Homenaje", name: "Homenaje" },
  { value: "IM Fell DW Pica", name: "IM Fell DW Pica" },
  { value: "IM Fell DW Pica SC", name: "IM Fell DW Pica SC" },
  { value: "IM Fell Double Pica", name: "IM Fell Double Pica" },
  { value: "IM Fell Double Pica SC", name: "IM Fell Double Pica SC" },
  { value: "IM Fell English", name: "IM Fell English" },
  { value: "IM Fell English SC", name: "IM Fell English SC" },
  { value: "IM Fell French Canon", name: "IM Fell French Canon" },
  { value: "IM Fell French Canon SC", name: "IM Fell French Canon SC" },
  { value: "IM Fell Great Primer", name: "IM Fell Great Primer" },
  { value: "IM Fell Great Primer SC", name: "IM Fell Great Primer SC" },
  { value: "Iceberg", name: "Iceberg" },
  { value: "Iceland", name: "Iceland" },
  { value: "Imprima", name: "Imprima" },
  { value: "Inconsolata", name: "Inconsolata" },
  { value: "Inder", name: "Inder" },
  { value: "Indie Flower", name: "Indie Flower" },
  { value: "Inika", name: "Inika" },
  { value: "Irish Grover", name: "Irish Grover" },
  { value: "Istok Web", name: "Istok Web" },
  { value: "Italiana", name: "Italiana" },
  { value: "Italianno", name: "Italianno" },
  { value: "Jacques Francois", name: "Jacques Francois" },
  { value: "Jacques Francois Shadow", name: "Jacques Francois Shadow" },
  { value: "Jim Nightshade", name: "Jim Nightshade" },
  { value: "Jockey One", name: "Jockey One" },
  { value: "Jolly Lodger", name: "Jolly Lodger" },
  { value: "Josefin Sans", name: "Josefin Sans" },
  { value: "Josefin Slab", name: "Josefin Slab" },
  { value: "Joti One", name: "Joti One" },
  { value: "Judson", name: "Judson" },
  { value: "Julee", name: "Julee" },
  { value: "Julius Sans One", name: "Julius Sans One" },
  { value: "Jomhuria", name: "Jomhuria" },
  { value: "Junge", name: "Junge" },
  { value: "Jura", name: "Jura" },
  { value: "Just Another Hand", name: "Just Another Hand" },
  { value: "Just Me Again Down Here", name: "Just Me Again Down Here" },
  { value: "Kameron", name: "Kameron" },
  { value: "Kantumruy", name: "Kantumruy" },
  { value: "Karla", name: "Karla" },
  { value: "Katibeh", name: "Katibeh" },
  { value: "Kaushan Script", name: "Kaushan Script" },
  { value: "Kavoon", name: "Kavoon" },
  { value: "Kdam Thmor", name: "Kdam Thmor" },
  { value: "Keania One", name: "Keania One" },
  { value: "Kelly Slab", name: "Kelly Slab" },
  { value: "Kenia", name: "Kenia" },
  { value: "Khmer", name: "Khmer" },
  { value: "Kite One", name: "Kite One" },
  { value: "Knewave", name: "Knewave" },
  { value: "Kotta One", name: "Kotta One" },
  { value: "Koulen", name: "Koulen" },
  { value: "Kranky", name: "Kranky" },
  { value: "Kreon", name: "Kreon" },
  { value: "Kristi", name: "Kristi" },
  { value: "Krona One", name: "Krona One" },
  { value: "Kufam", name: "Kufam" },
  { value: "La Belle Aurore", name: "La Belle Aurore" },
  { value: "Lalezar", name: "Lalezar" },
  { value: "Lancelot", name: "Lancelot" },
  { value: "Lateef", name: "Lateef" },
  { value: "Lato", name: "Lato" },
  { value: "League Script", name: "League Script" },
  { value: "Leckerli One", name: "Leckerli One" },
  { value: "Ledger", name: "Ledger" },
  { value: "Lekton", name: "Lekton" },
  { value: "Lemon", name: "Lemon" },
  { value: "Lemonada", name: "Lemonada" },
  { value: "Libre Baskerville", name: "Libre Baskerville" },
  { value: "Life Savers", name: "Life Savers" },
  { value: "Lilita One", name: "Lilita One" },
  { value: "Lily Script One", name: "Lily Script One" },
  { value: "Limelight", name: "Limelight" },
  { value: "Linden Hill", name: "Linden Hill" },
  { value: "Lobster", name: "Lobster" },
  { value: "Lobster Two", name: "Lobster Two" },
  { value: "Londrina Outline", name: "Londrina Outline" },
  { value: "Londrina Shadow", name: "Londrina Shadow" },
  { value: "Londrina Sketch", name: "Londrina Sketch" },
  { value: "Londrina Solid", name: "Londrina Solid" },
  { value: "Lora", name: "Lora" },
  { value: "Love Ya Like A Sister", name: "Love Ya Like A Sister" },
  { value: "Loved by the King", name: "Loved by the King" },
  { value: "Lovers Quarrel", name: "Lovers Quarrel" },
  { value: "Luckiest Guy", name: "Luckiest Guy" },
  { value: "Lusitana", name: "Lusitana" },
  { value: "Lustria", name: "Lustria" },
  { value: "Macondo", name: "Macondo" },
  { value: "Macondo Swash Caps", name: "Macondo Swash Caps" },
  { value: "Mada", name: "Mada" },
  { value: "Magra", name: "Magra" },
  { value: "Maiden Orange", name: "Maiden Orange" },
  { value: "Mako", name: "Mako" },
  { value: "Marcellus", name: "Marcellus" },
  { value: "Marcellus SC", name: "Marcellus SC" },
  { value: "Marck Script", name: "Marck Script" },
  { value: "Margarine", name: "Margarine" },
  { value: "Markazi Text", name: "Markazi Text" },
  { value: "Marko One", name: "Marko One" },
  { value: "Marmelad", name: "Marmelad" },
  { value: "Marvel", name: "Marvel" },
  { value: "Mate", name: "Mate" },
  { value: "Mate SC", name: "Mate SC" },
  { value: "Maven Pro", name: "Maven Pro" },
  { value: "McLaren", name: "McLaren" },
  { value: "Meddon", name: "Meddon" },
  { value: "MedievalSharp", name: "MedievalSharp" },
  { value: "Medula One", name: "Medula One" },
  { value: "Megrim", name: "Megrim" },
  { value: "Meie Script", name: "Meie Script" },
  { value: "Merienda", name: "Merienda" },
  { value: "Merienda One", name: "Merienda One" },
  { value: "Merriweather", name: "Merriweather" },
  { value: "Merriweather Sans", name: "Merriweather Sans" },
  { value: "Metal", name: "Metal" },
  { value: "Metal Mania", name: "Metal Mania" },
  { value: "Metamorphous", name: "Metamorphous" },
  { value: "Metrophobic", name: "Metrophobic" },
  { value: "Michroma", name: "Michroma" },
  { value: "Milonga", name: "Milonga" },
  { value: "Miltonian", name: "Miltonian" },
  { value: "Miltonian Tattoo", name: "Miltonian Tattoo" },
  { value: "Miniver", name: "Miniver" },
  { value: "Mirza", name: "Mirza" },
  { value: "Miss Fajardose", name: "Miss Fajardose" },
  { value: "Modern Antiqua", name: "Modern Antiqua" },
  { value: "Molengo", name: "Molengo" },
  { value: "Monda", name: "Monda" },
  { value: "Monofett", name: "Monofett" },
  { value: "Monoton", name: "Monoton" },
  { value: "Monsieur La Doulaise", name: "Monsieur La Doulaise" },
  { value: "Montaga", name: "Montaga" },
  { value: "Montez", name: "Montez" },
  { value: "Montserrat", name: "Montserrat" },
  { value: "Montserrat Alternates", name: "Montserrat Alternates" },
  { value: "Montserrat Subrayada", name: "Montserrat Subrayada" },
  { value: "Moul", name: "Moul" },
  { value: "Moulpali", name: "Moulpali" },
  { value: "Mountains of Christmas", name: "Mountains of Christmas" },
  { value: "Mouse Memoirs", name: "Mouse Memoirs" },
  { value: "Mr Bedfort", name: "Mr Bedfort" },
  { value: "Mr Dafoe", name: "Mr Dafoe" },
  { value: "Mr De Haviland", name: "Mr De Haviland" },
  { value: "Mrs Saint Delafield", name: "Mrs Saint Delafield" },
  { value: "Mrs Sheppards", name: "Mrs Sheppards" },
  { value: "Muli", name: "Muli" },
  { value: "Mystery Quest", name: "Mystery Quest" },
  { value: "Neucha", name: "Neucha" },
  { value: "Neuton", name: "Neuton" },
  { value: "New Rocker", name: "New Rocker" },
  { value: "News Cycle", name: "News Cycle" },
  { value: "Niconne", name: "Niconne" },
  { value: "Nixie One", name: "Nixie One" },
  { value: "Nobile", name: "Nobile" },
  { value: "Nokora", name: "Nokora" },
  { value: "Norican", name: "Norican" },
  { value: "Nosifer", name: "Nosifer" },
  { value: "Nothing You Could Do", name: "Nothing You Could Do" },
  { value: "Noticia Text", name: "Noticia Text" },
  { value: "Noto Sans", name: "Noto Sans" },
  { value: "Noto Serif", name: "Noto Serif" },
  { value: "Nova Cut", name: "Nova Cut" },
  { value: "Nova Flat", name: "Nova Flat" },
  { value: "Nova Mono", name: "Nova Mono" },
  { value: "Nova Oval", name: "Nova Oval" },
  { value: "Nova Round", name: "Nova Round" },
  { value: "Nova Script", name: "Nova Script" },
  { value: "Nova Slim", name: "Nova Slim" },
  { value: "Nova Square", name: "Nova Square" },
  { value: "Numans", name: "Numans" },
  { value: "Nunito", name: "Nunito" },
  { value: "Odor Mean Chey", name: "Odor Mean Chey" },
  { value: "Offside", name: "Offside" },
  { value: "Old Standard TT", name: "Old Standard TT" },
  { value: "Oldenburg", name: "Oldenburg" },
  { value: "Oleo Script", name: "Oleo Script" },
  { value: "Oleo Script Swash Caps", name: "Oleo Script Swash Caps" },
  { value: "Open Sans", name: "Open Sans" },
  { value: "Oranienbaum", name: "Oranienbaum" },
  { value: "Orbitron", name: "Orbitron" },
  { value: "Oregano", name: "Oregano" },
  { value: "Orienta", name: "Orienta" },
  { value: "Original Surfer", name: "Original Surfer" },
  { value: "Oswald", name: "Oswald" },
  { value: "Over the Rainbow", name: "Over the Rainbow" },
  { value: "Overlock", name: "Overlock" },
  { value: "Overlock SC", name: "Overlock SC" },
  { value: "Ovo", name: "Ovo" },
  { value: "Oxygen", name: "Oxygen" },
  { value: "Oxygen Mono", name: "Oxygen Mono" },
  { value: "PT Mono", name: "PT Mono" },
  { value: "PT Sans", name: "PT Sans" },
  { value: "PT Sans Caption", name: "PT Sans Caption" },
  { value: "PT Sans Narrow", name: "PT Sans Narrow" },
  { value: "PT Serif", name: "PT Serif" },
  { value: "PT Serif Caption", name: "PT Serif Caption" },
  { value: "Pacifico", name: "Pacifico" },
  { value: "Paprika", name: "Paprika" },
  { value: "Parisienne", name: "Parisienne" },
  { value: "Passero One", name: "Passero One" },
  { value: "Passion One", name: "Passion One" },
  { value: "Pathway Gothic One", name: "Pathway Gothic One" },
  { value: "Patrick Hand", name: "Patrick Hand" },
  { value: "Patrick Hand SC", name: "Patrick Hand SC" },
  { value: "Patua One", name: "Patua One" },
  { value: "Paytone One", name: "Paytone One" },
  { value: "Peralta", name: "Peralta" },
  { value: "Permanent Marker", name: "Permanent Marker" },
  { value: "Petit Formal Script", name: "Petit Formal Script" },
  { value: "Petrona", name: "Petrona" },
  { value: "Philosopher", name: "Philosopher" },
  { value: "Piedra", name: "Piedra" },
  { value: "Pinyon Script", name: "Pinyon Script" },
  { value: "Pirata One", name: "Pirata One" },
  { value: "Plaster", name: "Plaster" },
  { value: "Play", name: "Play" },
  { value: "Playball", name: "Playball" },
  { value: "Playfair Display", name: "Playfair Display" },
  { value: "Playfair Display SC", name: "Playfair Display SC" },
  { value: "Podkova", name: "Podkova" },
  { value: "Poiret One", name: "Poiret One" },
  { value: "Poller One", name: "Poller One" },
  { value: "Poly", name: "Poly" },
  { value: "Pompiere", name: "Pompiere" },
  { value: "Pontano Sans", name: "Pontano Sans" },
  { value: "Port Lligat Sans", name: "Port Lligat Sans" },
  { value: "Port Lligat Slab", name: "Port Lligat Slab" },
  { value: "Prata", name: "Prata" },
  { value: "Preahvihear", name: "Preahvihear" },
  { value: "Press Start 2P", name: "Press Start 2P" },
  { value: "Princess Sofia", name: "Princess Sofia" },
  { value: "Prociono", name: "Prociono" },
  { value: "Prosto One", name: "Prosto One" },
  { value: "Puritan", name: "Puritan" },
  { value: "Purple Purse", name: "Purple Purse" },
  { value: "Quando", name: "Quando" },
  { value: "Quantico", name: "Quantico" },
  { value: "Quattrocento", name: "Quattrocento" },
  { value: "Quattrocento Sans", name: "Quattrocento Sans" },
  { value: "Questrial", name: "Questrial" },
  { value: "Quicksand", name: "Quicksand" },
  { value: "Quintessential", name: "Quintessential" },
  { value: "Qwigley", name: "Qwigley" },
  { value: "Racing Sans One", name: "Racing Sans One" },
  { value: "Radley", name: "Radley" },
  { value: "Rakkas", name: "Rakkas" },
  { value: "Raleway", name: "Raleway" },
  { value: "Raleway Dots", name: "Raleway Dots" },
  { value: "Rambla", name: "Rambla" },
  { value: "Rammetto One", name: "Rammetto One" },
  { value: "Ranchers", name: "Ranchers" },
  { value: "Rancho", name: "Rancho" },
  { value: "Rationale", name: "Rationale" },
  { value: "Redressed", name: "Redressed" },
  { value: "Reem Kufi", name: "Reem Kufi" },
  { value: "Reenie Beanie", name: "Reenie Beanie" },
  { value: "Revalia", name: "Revalia" },
  { value: "Ribeye", name: "Ribeye" },
  { value: "Ribeye Marrow", name: "Ribeye Marrow" },
  { value: "Righteous", name: "Righteous" },
  { value: "Risque", name: "Risque" },
  { value: "Roboto", name: "Roboto" },
  { value: "Roboto Condensed", name: "Roboto Condensed" },
  { value: "Roboto Slab", name: "Roboto Slab" },
  { value: "Rochester", name: "Rochester" },
  { value: "Rock Salt", name: "Rock Salt" },
  { value: "Rokkitt", name: "Rokkitt" },
  { value: "Romanesco", name: "Romanesco" },
  { value: "Ropa Sans", name: "Ropa Sans" },
  { value: "Rosario", name: "Rosario" },
  { value: "Rosarivo", name: "Rosarivo" },
  { value: "Rouge Script", name: "Rouge Script" },
  { value: "Ruda", name: "Ruda" },
  { value: "Rufina", name: "Rufina" },
  { value: "Ruge Boogie", name: "Ruge Boogie" },
  { value: "Ruluko", name: "Ruluko" },
  { value: "Rum Raisin", name: "Rum Raisin" },
  { value: "Ruslan Display", name: "Ruslan Display" },
  { value: "Russo One", name: "Russo One" },
  { value: "Ruthie", name: "Ruthie" },
  { value: "Rye", name: "Rye" },
  { value: "Sacramento", name: "Sacramento" },
  { value: "Sail", name: "Sail" },
  { value: "Salsa", name: "Salsa" },
  { value: "Sanchez", name: "Sanchez" },
  { value: "Sancreek", name: "Sancreek" },
  { value: "Sansita One", name: "Sansita One" },
  { value: "Sarina", name: "Sarina" },
  { value: "Satisfy", name: "Satisfy" },
  { value: "Scada", name: "Scada" },
  { value: "Scheherazade", name: "Scheherazade" },
  { value: "Schoolbell", name: "Schoolbell" },
  { value: "Seaweed Script", name: "Seaweed Script" },
  { value: "Sevillana", name: "Sevillana" },
  { value: "Seymour One", name: "Seymour One" },
  { value: "Shadows Into Light", name: "Shadows Into Light" },
  { value: "Shadows Into Light Two", name: "Shadows Into Light Two" },
  { value: "Shanti", name: "Shanti" },
  { value: "Share", name: "Share" },
  { value: "Share Tech", name: "Share Tech" },
  { value: "Share Tech Mono", name: "Share Tech Mono" },
  { value: "Shojumaru", name: "Shojumaru" },
  { value: "Short Stack", name: "Short Stack" },
  { value: "Siemreap", name: "Siemreap" },
  { value: "Sigmar One", name: "Sigmar One" },
  { value: "Signika", name: "Signika" },
  { value: "Signika Negative", name: "Signika Negative" },
  { value: "Simonetta", name: "Simonetta" },
  { value: "Sintony", name: "Sintony" },
  { value: "Sirin Stencil", name: "Sirin Stencil" },
  { value: "Six Caps", name: "Six Caps" },
  { value: "Skranji", name: "Skranji" },
  { value: "Slackey", name: "Slackey" },
  { value: "Smokum", name: "Smokum" },
  { value: "Smythe", name: "Smythe" },
  { value: "Sniglet", name: "Sniglet" },
  { value: "Snippet", name: "Snippet" },
  { value: "Snowburst One", name: "Snowburst One" },
  { value: "Sofadi One", name: "Sofadi One" },
  { value: "Sofia", name: "Sofia" },
  { value: "Sonsie One", name: "Sonsie One" },
  { value: "Sorts Mill Goudy", name: "Sorts Mill Goudy" },
  { value: "Source Code Pro", name: "Source Code Pro" },
  { value: "Source Sans Pro", name: "Source Sans Pro" },
  { value: "Special Elite", name: "Special Elite" },
  { value: "Spicy Rice", name: "Spicy Rice" },
  { value: "Spinnaker", name: "Spinnaker" },
  { value: "Spirax", name: "Spirax" },
  { value: "Squada One", name: "Squada One" },
  { value: "Stalemate", name: "Stalemate" },
  { value: "Stalinist One", name: "Stalinist One" },
  { value: "Stardos Stencil", name: "Stardos Stencil" },
  { value: "Stint Ultra Condensed", name: "Stint Ultra Condensed" },
  { value: "Stint Ultra Expanded", name: "Stint Ultra Expanded" },
  { value: "Stoke", name: "Stoke" },
  { value: "Strait", name: "Strait" },
  { value: "Sue Ellen Francisco", name: "Sue Ellen Francisco" },
  { value: "Sunshiney", name: "Sunshiney" },
  { value: "Supermercado One", name: "Supermercado One" },
  { value: "Suwannaphum", name: "Suwannaphum" },
  { value: "Swanky and Moo Moo", name: "Swanky and Moo Moo" },
  { value: "Syncopate", name: "Syncopate" },
  { value: "Tajawal", name: "Tajawal" },
  { value: "Tangerine", name: "Tangerine" },
  { value: "Taprom", name: "Taprom" },
  { value: "Tauri", name: "Tauri" },
  { value: "Telex", name: "Telex" },
  { value: "Tenor Sans", name: "Tenor Sans" },
  { value: "Text Me One", name: "Text Me One" },
  { value: "The Girl Next Door", name: "The Girl Next Door" },
  { value: "Tienne", name: "Tienne" },
  { value: "Tinos", name: "Tinos" },
  { value: "Titan One", name: "Titan One" },
  { value: "Titillium Web", name: "Titillium Web" },
  { value: "Trade Winds", name: "Trade Winds" },
  { value: "Trocchi", name: "Trocchi" },
  { value: "Trochut", name: "Trochut" },
  { value: "Trykker", name: "Trykker" },
  { value: "Tulpen One", name: "Tulpen One" },
  { value: "Ubuntu", name: "Ubuntu" },
  { value: "Ubuntu Condensed", name: "Ubuntu Condensed" },
  { value: "Ubuntu Mono", name: "Ubuntu Mono" },
  { value: "Ultra", name: "Ultra" },
  { value: "Uncial Antiqua", name: "Uncial Antiqua" },
  { value: "Underdog", name: "Underdog" },
  { value: "Unica One", name: "Unica One" },
  { value: "UnifrakturMaguntia", name: "UnifrakturMaguntia" },
  { value: "Unkempt", name: "Unkempt" },
  { value: "Unlock", name: "Unlock" },
  { value: "Unna", name: "Unna" },
  { value: "VT323", name: "VT323" },
  { value: "Vampiro One", name: "Vampiro One" },
  { value: "Varela", name: "Varela" },
  { value: "Varela Round", name: "Varela Round" },
  { value: "Vast Shadow", name: "Vast Shadow" },
  { value: "Vibes", name: "Vibes" },
  { value: "Vibur", name: "Vibur" },
  { value: "Vidaloka", name: "Vidaloka" },
  { value: "Viga", name: "Viga" },
  { value: "Voces", name: "Voces" },
  { value: "Volkhov", name: "Volkhov" },
  { value: "Vollkorn", name: "Vollkorn" },
  { value: "Voltaire", name: "Voltaire" },
  { value: "Waiting for the Sunrise", name: "Waiting for the Sunrise" },
  { value: "Wallpoet", name: "Wallpoet" },
  { value: "Walter Turncoat", name: "Walter Turncoat" },
  { value: "Warnes", name: "Warnes" },
  { value: "Wellfleet", name: "Wellfleet" },
  { value: "Wendy One", name: "Wendy One" },
  { value: "Wire One", name: "Wire One" },
  { value: "Yanone Kaffeesatz", name: "Yanone Kaffeesatz" },
  { value: "Yellowtail", name: "Yellowtail" },
  { value: "Yeseva One", name: "Yeseva One" },
  { value: "Yesteryear", name: "Yesteryear" },
  { value: "Zeyada", name: "Zeyada" },
];
