import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import SelectInput from "modules/base/components/inputs/SelectInput";
import TextInput from "modules/base/components/inputs/TextInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import {
  closeModalById,
  handleInputChange,
} from "modules/base/utilities/Actions";
import ConfigureNodeForm from "modules/marketing/components/campaigns/nodes/configure/ConfigureNodeForm";
import ExecutionTiming from "modules/marketing/components/campaigns/nodes/configure/ExecutionTiming";
import MarketingAPI from "modules/marketing/MarketingAPI";

function ShowFocusItem({
  campaignEvent,
  setState,
  match,
  currentModalId,
  updateEvent,
}) {
  const [selectedFocusItemId, setSelectedFocusItemId] = useState(
    Number(campaignEvent.properties.focus),
  );
  const [campaignEventState, setCampaignEventState] = useState(campaignEvent);
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const {
    focusItems,
    error: templatesLoadingError,
    isLoading,
  } = API.getFocusItems(siteId);
  const onInputChange = useCallback((e) => {
    handleInputChange(
      e.target.name,
      e.target.value,
      campaignEventState,
      setCampaignEventState,
    );
  });
  const onFocusItemChange = useCallback((selectedOption) => {
    setSelectedFocusItemId(selectedOption.value);
    handleInputChange(
      "properties",
      {
        ...campaignEventState.properties,
        focus: selectedOption.value,
      },
      campaignEventState,
      setCampaignEventState,
    );
  });
  const onClickConfigure = useCallback(() => {
    setState({ ...campaignEventState });
    closeModalById(currentModalId);
  });
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  const focusItemsOptions = focusItems.map((focusItem) => ({
    label: focusItem.name,
    value: focusItem.id,
  }));
  return (
    <ConfigureNodeForm
      campaignEvent={campaignEvent}
      campaignEventState={campaignEventState}
      handleSubmit={onClickConfigure}
      updateEvent={updateEvent}
    >
      <NetworkMessageDisplay error={templatesLoadingError} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <TextInput
          name="name"
          title="Name"
          content={campaignEventState.name ?? ""}
          controlFunc={onInputChange}
          required
        />
        <SelectInput
          name="focusItem"
          title="Engagement item"
          options={focusItemsOptions}
          onChange={onFocusItemChange}
          predicate={(option) => option.value === selectedFocusItemId}
          isLoading={isLoading}
          required
        />
        <ExecutionTiming
          campaignEvent={campaignEventState}
          setState={setCampaignEventState}
        />
      </div>
    </ConfigureNodeForm>
  );
}

ShowFocusItem.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  campaignEvent: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  currentModalId: PropTypes.string.isRequired,
  updateEvent: PropTypes.func.isRequired,
};

export default withRouter(ShowFocusItem);
