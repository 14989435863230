import $ from "jquery";
import React from "react";
import { renderToStaticMarkup, renderToString } from "react-dom/server";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import GenerateContent from "../../cms/components/GenerateContent";
import LinkToModalContent from "./LinkToModalContent";

export default (editor, options = {}) => {
  const opts: any = {
    ...{
      generateContentCommand: "generate-content",
      generateContentLabel: "AI writer",
    },
    ...options,
  };
  const { generateContentCommand, generateContentLabel } = opts;

  const domc: any = editor.DomComponents;
  const typeText: any = domc.getType("text").model;
  domc.addType("text", {
    model: {
      initToolbar(...args) {
        typeText.prototype.initToolbar.apply(this, args);
        const tb: any = this.get("toolbar");
        const tbAIExists: any = tb.some(
          (item) => item.command === generateContentCommand,
        );
        if (!tbAIExists) {
          tb.push({
            command: generateContentCommand,
            label: generateContentLabel,
          });
        }
        this.set("toolbar", tb);
      },
    },
  });

  editor.Commands.add(generateContentCommand, {
    run() {
      this.editor = editor;
      this.options = opts;
      this.target = opts.target || editor.getSelected();
      const { target } = this;
      this.$ = editor.$;
      this.content = "";
      if (target && target.get("editable")) {
        this.showGenerateContentModal();
      }
    },

    showGenerateContentModal() {
      const editorInsertContent: any = () => {
        const aiContent: any = $("#ai-writer").text();
        this.target.components(`${aiContent}`);
      };
      const MySwal: any = withReactContent(Swal);
      MySwal.fire({
        html: <GenerateContent editorInsertContent={editorInsertContent} />,
        showConfirmButton: false,
        showCloseButton: true,
        allowOutsideClick: false,
        backdrop: true,
        width: "1140px",
        position: "top",
        showClass: {
          popup: "animate__animated animate__slideInDown",
        },
        hideClass: {
          popup: "animate__animated animate__slideOutUp",
        },
      });
    },
  });
};
