import { intercom_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const { IntercomLabel, IntercomCategory, IntercomBlock } = opts;

  IntercomBlock &&
    bm.add("INTER-COM-BLOCK", {
      label: IntercomLabel,
      activate: true,
      select: true,
      category: IntercomCategory,
      content: { type: "INTER-COM-COMPONENT" },
      media: intercom_logo,
    });
};
