import PropTypes from "prop-types";
import React from "react";
import { Icon } from "../../../base/components/Icons";

/**
 * @name DataGridTools
 * @description DataGridTools component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DataGridTools(props) {
  const { isDataRefreshable, onClickRefresh, isDataCreatable, onClickCreate } =
    props;
  let refreshButton: object = null;
  let addButton: object = null;
  if (isDataRefreshable) {
    refreshButton = (
      <button
        className="btn btn-outline-primary btn-sm"
        onClick={onClickRefresh}
        type="button"
      >
        <Icon icon="arrow-clockwise" /> Refresh
      </button>
    );
  }
  if (isDataCreatable) {
    let margin: string = "";
    if (isDataRefreshable) {
      margin = "ms-2";
    }
    addButton = (
      <button
        className={`btn btn-outline-primary btn-sm ${margin}`}
        onClick={onClickCreate}
        type="button"
      >
        <Icon icon="plus" /> Create
      </button>
    );
  }
  return (
    <div className="mb-2">
      {refreshButton}
      {addButton}
    </div>
  );
}

DataGridTools.defaultProps = {
  isDataRefreshable: false,
  onClickRefresh: () => {},
  isDataCreatable: false,
  onClickCreate: () => {},
};

DataGridTools.propTypes = {
  isDataRefreshable: PropTypes.bool,
  onClickRefresh: PropTypes.func,
  isDataCreatable: PropTypes.bool,
  onClickCreate: PropTypes.func,
};

export default DataGridTools;
