import PropTypes from "prop-types";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "../../../core/components/Form";
import FormCardWrapper from "../../../core/components/FormCardWrapper";
import { Shop } from "../../models/Shop";
import { getFormInputs } from "../../ShopActions";
import ShopApi from "../../ShopAPIs";

/**
 * @function ShopDetailsEditor
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ShopDetailsEditor(props) {
  const { shop: shop_instance, location } = props;
  const shopApi: any = new ShopApi();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [shop, setShop] = useState(shop_instance);
  const current_path: any = location.pathname;
  const { inputs, title, submit_button_text } = getFormInputs(
    current_path,
    shop,
  );

  /**
   * @function handleSubmit
   * @param values
   * @returns {Promise<void>}
   */
  const onSubmit: any = (values: Object) => {
    setIsSubmitting(true);
    shopApi.updateShopDetails(
      shop.site,
      values,
      (updated_shop: Shop) => {
        setSuccessMessage("Shop details updated successfully");
        setError(null);
        setShop(updated_shop);
        setIsSubmitting(false);
      },
      (error_message: any) => {
        setError(error_message);
        setSuccessMessage(null);
        setIsSubmitting(false);
      },
    );
  };

  return (
    <FormCardWrapper title={title}>
      <Form
        onSubmit={(values) => onSubmit(values)}
        is_submitting={isSubmitting}
        error={error}
        success_message={successMessage}
        submit_text={submit_button_text}
        inputs={inputs}
        no_of_columns={2}
      />
    </FormCardWrapper>
  );
}

ShopDetailsEditor.propTypes = {
  shop: PropTypes.instanceOf(Shop).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(ShopDetailsEditor);
