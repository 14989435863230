import React, { useEffect, useState } from "react";
import { AuthenticationActions } from "../../account/AccountActions";
import { IconLoading } from "../../base/components/Icons";
import { ErrorModal } from "../../core/components/ErrorModal";
import { FilePicker } from "../../core/components/FilePicker";
import { FormTextAreaInput } from "../../core/components/form-controls/FormTextAreaInput";
import FormTextInput from "../../core/components/form-controls/FormTextInput";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import Warning from "../../core/components/Warning";
import PageLoadingIndicator from "../components/PageLoadingIndicator";
import WebsiteSidebar from "../components/SidebarWebsite";

import SiteApi from "modules/website/api/SiteApi";

function WebsiteSeo(props) {
  const authenticationActions: any = new AuthenticationActions();
  const user: any = authenticationActions.retrieveAuthenticatedUser();
  const site_id: any = props.match.params.id;

  const [loading_website, setIsLoadingWebsite] = useState(true);
  const [website, setWebsite] = useState([]);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [is_changing_desc, setIsChangingDesc] = useState(false);
  const [is_deleting_desc, setIsDeletingDesc] = useState(false);
  const [description_error, setDescription_error] = useState(null);
  const [is_changing_title, setIsChangingTitle] = useState(false);
  const [is_deleting_title, setIsDeletingTitle] = useState(false);
  const [title_error, setTitleError] = useState(null);
  const siteApi: any = new SiteApi();

  const loadWebsite: any = () => {
    setIsLoadingWebsite(true);
    siteApi.retrieveWebsite_(site_id, (is_successful, sites_or_error) => {
      setIsLoadingWebsite(false);
      if (is_successful) {
        setWebsite(sites_or_error);
        setTitle(sites_or_error.title);
        setDescription(sites_or_error.description);
      } else {
        setError(sites_or_error);
      }
    });
  };

  useEffect(loadWebsite, []);

  const onChangeTitle: any = (event) => {
    const { value } = event.target;
    setTitle(value);
  };

  const onChangeDescription: any = (event) => {
    const { value } = event.target;
    setDescription(value);
  };

  if (!loading_website && !user) {
    loadWebsite();
    return <PageLoadingIndicator />;
  }
  if (loading_website) {
    return <PageLoadingIndicator />;
  }

  let main_content;
  if (!website) {
    main_content = <Warning text="Error" btnText="Ok" />;
  } else {
    const saveSiteFavicon: any = (
      favicon,
      success_callback,
      error_callback,
    ) => {
      website.favicon = favicon;
      siteApi.updateWebsiteFields(
        website.id,
        { favicon },
        () => {},
        () => success_callback(website.favicon),
        (error_message) => error_callback(error_message),
      );
    };

    const setDeleteChangeTitle: any = (deleting) => {
      if (deleting) {
        setIsDeletingTitle(true);
      } else {
        setIsChangingTitle(true);
      }
    };

    const setDeleteChangeDesc: any = (deleting) => {
      if (deleting) {
        setIsDeletingDesc(true);
      } else {
        setIsChangingDesc(true);
      }
    };

    const saveSiteSeoTitle: any = (deleting = false) => {
      setDeleteChangeTitle(deleting);

      setTitleError(null);
      siteApi.updateWebsiteFields(
        website.id,
        { title: deleting ? "" : title },
        (is_successful, website_or_error) => {
          setIsDeletingTitle(false);
          setIsChangingTitle(false);
          if (!is_successful) {
            setTitleError(website_or_error);
          }
        },
      );
    };

    const saveSiteSeoDescription: any = (deleting = false) => {
      setDeleteChangeDesc(deleting);

      setDescription_error(null);
      siteApi.updateWebsiteFields(
        website.id,
        { description: deleting ? "" : description },
        (is_successful, website_or_error) => {
          setIsDeletingDesc(false);
          setIsChangingDesc(false);
          if (!is_successful) {
            setDescription_error(website_or_error);
          }
        },
      );
    };

    const favicon: null[] = [];
    if (website.favicon) {
      favicon.push({
        source: website.favicon,
        options: { type: "local" },
      });
    }

    let btn_change_title_text: string;
    if (is_changing_title) {
      btn_change_title_text = <IconLoading text="Saving" />;
    } else {
      btn_change_title_text = "Save";
    }

    let btn_delete_title_text: string;
    if (is_deleting_title) {
      btn_delete_title_text = <IconLoading text="Deleting" />;
    } else {
      btn_delete_title_text = "Delete";
    }

    let btn_change_description_text;
    if (is_changing_desc) {
      btn_change_description_text = <IconLoading text="Saving" />;
    } else {
      btn_change_description_text = "Save";
    }

    let btn_delete_description_text: string;
    if (is_deleting_desc) {
      btn_delete_description_text = <IconLoading text="Deleting" />;
    } else {
      btn_delete_description_text = "Delete";
    }

    main_content = (
      <div className="card border-0 shadow-sm card-body">
        <strong className="mb-3"> Favicon </strong>
        <div className="card-body border rounded mb-4">
          <p>
            {" "}
            Change the favicon of your website. This icon will be visible on the
            browser tab.{" "}
          </p>
          <FilePicker
            name="favicon"
            files={favicon}
            processFile={saveSiteFavicon}
            abortProcessingFile={() => {}}
          />
        </div>

        <strong className="mb-3"> Title </strong>
        <div className="card-body border rounded mb-4">
          <p>
            {" "}
            Enter a title for your site. This will show next to the favicon on
            the browser tab.{" "}
          </p>
          <div
            className="alert alert-danger"
            role="alert"
            hidden={!title_error}
          >
            {title_error}
          </div>
          <FormTextInput
            label={null}
            placeholder="A title for your website"
            name="title"
            value={title || website.title || ""}
            is_required={false}
            is_editable
            onChangeText={onChangeTitle}
          />
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-danger me-3"
              onClick={() => saveSiteSeoTitle(true)}
            >
              {btn_delete_title_text}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => saveSiteSeoTitle(false)}
            >
              {btn_change_title_text}
            </button>
          </div>
        </div>

        <strong className="mb-3"> Description </strong>
        <div className="card-body border rounded">
          <p> Enter a brief description of your site with keywords. </p>
          <div
            className="alert alert-danger"
            role="alert"
            hidden={!description_error}
          >
            {description_error}
          </div>
          <FormTextAreaInput
            label={null}
            placeholder="A brief description of your site with keywords"
            name="description"
            value={description || website.description || ""}
            is_required={false}
            is_editable
            onChangeText={onChangeDescription}
          />
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-danger me-3"
              onClick={() => saveSiteSeoDescription(true)}
            >
              {btn_delete_description_text}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => saveSiteSeoDescription(false)}
            >
              {btn_change_description_text}
            </button>
          </div>
        </div>
      </div>
    );
  }

  const close_modal_button: any = (
    <button
      type="button"
      className="btn btn-olitt-grey px-4"
      onClick={() => setError(null)}
    >
      Close
    </button>
  );

  return (
    <SidebarWrapper sidebar={<WebsiteSidebar website={website} />}>
      {main_content}
      <ErrorModal
        show={error !== null}
        onClose={() => setError(null)}
        positive_button={close_modal_button}
        short_desc={error ? error.short_desc : ""}
        suggestion={error ? error.suggestion : ""}
      />
    </SidebarWrapper>
  );
}

export default WebsiteSeo;
