export const JumbotronBlock = (editor, opts = {}) => {
    const bm = editor.BlockManager;
    bm.add("bootstrap-jumbotron", {
        label: `Jumbotron`,
        activate: true,
        select: true,
        category: "Basic",
        content:'<div class="jumbotron"> <h1>Heading text</h1> <p>Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p><p><a class="btn btn-primary" role="button">Learn more</a></p></div',
        media: '<i class=" oicon-call-to-action" style="font-size: 42px;"></i>'

     
    });
};




