import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { openModal } from "modules/base/utilities/Actions";
import ConfigureSegmentFilterModal from "modules/marketing/components/segments/ConfigureSegmentFilterModal";
import { operatorsMap } from "modules/marketing/components/segments/data/operator";
import { ContactSegmentFilter, Field } from "modules/marketing/MarketingModels";

function FieldFilter({
  filter,
  filterIndex,
  setState,
  currentModalId,
  contactFields,
  state,
}) {
  const displayField = _.capitalize(filter.field.replace(/_/g, " "));
  let filterDisplay = filter.properties.filter;
  const operatorDisplay = _.lowerCase(operatorsMap[filter.operator]);
  if (Array.isArray(filter.properties.filter)) {
    filterDisplay = filter.properties.filter.join(", ");
  }
  let display = `${displayField} ${operatorDisplay} ${filterDisplay}`;
  if (filter.properties.filter === "blank") {
    display = "Click to configure filter";
  } else if (!filter.properties.filter) {
    display = `${displayField} ${operatorDisplay}`;
  }
  const handleDelete = useCallback(() => {
    setState((prev) => {
      const newFilters = [...prev.filters];
      newFilters.splice(filterIndex, 1);
      return { ...prev, filters: newFilters };
    });
  }, []);
  const onClickConfigure = useCallback(() => {
    const segmentId = state.id ?? "new";
    openModal(
      `configure-segment-${segmentId}-filter-${filterIndex}-modal`,
      currentModalId,
    );
  });
  const badgeColor =
    filter.properties.filter === "blank" ? "warning" : "primary";
  return (
    <>
      <ConfigureSegmentFilterModal
        filter={filter}
        setState={setState}
        state={state}
        filterIndex={filterIndex}
        contactFields={contactFields}
      />
      <div className="d-flex align-items-center">
        <button
          type="button"
          className="btn btn-link text-decoration-none p-0"
          onClick={onClickConfigure}
        >
          <i className="bi bi-gear-fill" />
          <span className={`badge text-bg-${badgeColor} mx-2`}>{display}</span>
        </button>
        <button
          type="button"
          className="btn btn-link p-0"
          onClick={handleDelete}
        >
          <i className="bi bi-trash-fill text-danger" />
        </button>
      </div>
    </>
  );
}

FieldFilter.propTypes = {
  filter: PropTypes.instanceOf(ContactSegmentFilter).isRequired,
  setState: PropTypes.func.isRequired,
  filterIndex: PropTypes.number.isRequired,
  currentModalId: PropTypes.string.isRequired,
  contactFields: PropTypes.arrayOf(PropTypes.instanceOf(Field)).isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
};

function SegmentFilter({
  filters,
  setState,
  lastIndexOfFilter,
  currentModalId,
  contactFields,
  state,
}) {
  const filter = new ContactSegmentFilter({
    object: "lead",
    glue: "and",
    field: "blank",
    operator: "blank",
    properties: {
      filter: "blank",
    },
    filter: "blank",
  });
  const addFieldFilter = useCallback(() => {
    setState((prev) => {
      const newFilters = [...prev.filters];
      newFilters.splice(lastIndexOfFilter + 1, 0, filter);
      return { ...prev, filters: newFilters };
    });
  }, []);
  const fieldFilterList = filters.map((fieldFilter, index) => {
    const filterIndex = lastIndexOfFilter - filters.length + index + 1;
    return (
      <React.Fragment key={filterIndex}>
        <FieldFilter
          filter={fieldFilter}
          filterIndex={filterIndex}
          setState={setState}
          state={state}
          currentModalId={currentModalId}
          contactFields={contactFields}
        />
        {index < filters.length - 1 && <span className="fw-bolder"> and </span>}
      </React.Fragment>
    );
  });
  return (
    <div className="card" key={lastIndexOfFilter}>
      <div className="card-body d-flex flex-column align-items-center ">
        {fieldFilterList}
        <button
          type="button"
          className="btn btn-link p-0"
          onClick={addFieldFilter}
        >
          <i className="bi bi-node-plus-fill icon-rotate-90 fs-2" />
        </button>
      </div>
    </div>
  );
}

SegmentFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.instanceOf(ContactSegmentFilter))
    .isRequired,
  setState: PropTypes.func.isRequired,
  lastIndexOfFilter: PropTypes.number.isRequired,
  currentModalId: PropTypes.string.isRequired,
  contactFields: PropTypes.arrayOf(PropTypes.instanceOf(Field)).isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
};

function SegmentFilters({
  filters,
  setState,
  currentModalId,
  contactFields,
  state,
}) {
  const filterGroups = [];
  let currentFilterGroup = [];

  filters.forEach((filter) => {
    if (filter.glue === "or") {
      if (currentFilterGroup.length > 0) {
        filterGroups.push(currentFilterGroup);
      }
      currentFilterGroup = [filter];
    } else {
      currentFilterGroup.push(filter);
    }
  });

  if (currentFilterGroup.length > 0) {
    filterGroups.push(currentFilterGroup);
  }
  const filter = new ContactSegmentFilter({
    object: "lead",
    glue: "or",
    field: "blank",
    operator: "blank",
    properties: {
      filter: "blank",
    },
    filter: "blank",
  });
  const addFilter = useCallback(() => {
    setState((prev) => ({
      ...prev,
      filters: [...prev.filters, filter],
    }));
  }, []);
  const filterList = filterGroups.map((filterGroup, index) => {
    const lastIndexOfFilter = filters.lastIndexOf(
      filterGroup[filterGroup.length - 1],
    );
    return (
      <React.Fragment key={lastIndexOfFilter}>
        <SegmentFilter
          filters={filterGroup}
          setState={setState}
          state={state}
          lastIndexOfFilter={lastIndexOfFilter}
          currentModalId={currentModalId}
          contactFields={contactFields}
        />
        {index < filterGroups.length - 1 && (
          <span className="align-self-center fw-bolder"> OR </span>
        )}
      </React.Fragment>
    );
  });
  const addFilterButtonDescription =
    filters.length === 0
      ? "Would you like to automatically add contacts to this segment?"
      : null;
  return (
    <div className="d-flex flex-column">
      {filterList}
      {addFilterButtonDescription}
      <button
        type="button"
        className="btn btn-sm btn-outline-gray-900 mt-2 align-self-start"
        onClick={addFilter}
      >
        <i className="bi bi-funnel-fill" /> Add a filter
      </button>
    </div>
  );
}

SegmentFilters.defaultProps = {
  filters: [],
  currentModalId: null,
  state: {},
};

SegmentFilters.propTypes = {
  state: PropTypes.instanceOf(Object),
  filters: PropTypes.arrayOf(PropTypes.instanceOf(ContactSegmentFilter)),
  setState: PropTypes.func.isRequired,
  currentModalId: PropTypes.string,
  contactFields: PropTypes.arrayOf(PropTypes.instanceOf(Field)).isRequired,
};

export default SegmentFilters;
