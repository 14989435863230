const deviceOses = [
  { value: "Android", label: "Android" },
  { value: "AmigaOS", label: "AmigaOS" },
  { value: "BlackBerry", label: "BlackBerry" },
  { value: "Brew", label: "Brew" },
  { value: "BeOS", label: "BeOS" },
  { value: "Chrome OS", label: "Chrome OS" },
  { value: "Firefox OS", label: "Firefox OS" },
  { value: "Gaming Console", label: "Gaming Console" },
  { value: "Google TV", label: "Google TV" },
  { value: "IBM", label: "IBM" },
  { value: "iOS", label: "iOS" },
  { value: "RISC OS", label: "RISC OS" },
  { value: "GNU/Linux", label: "GNU/Linux" },
  { value: "Mac", label: "Mac" },
  { value: "Mobile Gaming Console", label: "Mobile Gaming Console" },
  { value: "Real-time OS", label: "Real-time OS" },
  { value: "Other Mobile", label: "Other Mobile" },
  { value: "Symbian", label: "Symbian" },
  { value: "Unix", label: "Unix" },
  { value: "WebTV", label: "WebTV" },
  { value: "Windows", label: "Windows" },
  { value: "Windows Mobile", label: "Windows Mobile" },
  { value: "Other Smart TV", label: "Other Smart TV" },
];

export default deviceOses;
