import SidebarWrapper from "modules/core/components/SidebarWrapper";
import DashboardView from "modules/shop/components/dashboard/DashboardView";
import SidebarShop from "modules/shop/components/SidebarShop";

/**
 * Shop Dashboard layout
 * @returns {*}
 * @constructor
 */
function Dashboard() {
  return (
    <SidebarWrapper sidebar={<SidebarShop />}>
      <DashboardView />
    </SidebarWrapper>
  );
}

export default Dashboard;
