import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import SelectInput from "modules/base/components/inputs/SelectInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import { closeModalById } from "modules/base/utilities/Actions";
import MarketingAPI from "modules/marketing/MarketingAPI";

function ManageCampaign({ selectedContactsIds, match, currentModalId }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const {
    campaigns,
    error: campaignLoadingError,
    isLoading,
  } = API.getCampaigns(siteId);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [noOfCampaignsInProgress, setNoOfCampaignsInProgress] = useState(0);
  const [success, setSuccess] = useState(null);
  const [errors, setErrors] = useState([]);
  const [state, setState] = useState({
    campaignsToAdd: [],
    campaignsToRemove: [],
  });

  useEffect(() => {
    if (noOfCampaignsInProgress > 0) {
      setIsSubmitting(true);
    } else {
      if (errors.length === 0 && isSubmitting) {
        setErrors([]);
        closeModalById(currentModalId);
        setSuccess({ message: "Campaigns Updated successfully" });
      }
      setIsSubmitting(false);
    }
  }, [noOfCampaignsInProgress]);

  const changeCampaignContacts = useCallback((campaignId, action) => {
    setNoOfCampaignsInProgress((prev) => prev + 1);

    let addContactIds = [];
    if (action === "add") {
      addContactIds = selectedContactsIds;
    }

    API.AddContactsToCampaign(
      campaignId,
      addContactIds,
      () => {
        setNoOfCampaignsInProgress((prev) => prev - 1);
      },
      (error_response) => {
        setErrors((prev) => [
          ...prev,
          { message: error_response.message, id: campaignId },
        ]);
        setNoOfCampaignsInProgress((prev) => prev - 1);
      },
    );

    let removeContactIds = [];
    if (action === "remove") {
      removeContactIds = selectedContactsIds;
    }

    API.RemoveContactsFromCampaign(
      campaignId,
      removeContactIds,
      () => {
        setNoOfCampaignsInProgress((prev) => prev - 1);
      },
      (error_response) => {
        setErrors((prev) => [
          ...prev,
          { message: error_response.message, id: campaignId },
        ]);
        setNoOfCampaignsInProgress((prev) => prev - 1);
      },
    );
  });

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors([]);
    state.campaignsToAdd.forEach((campaignId) => {
      changeCampaignContacts(campaignId, "add");
    });
    state.campaignsToRemove.forEach((campaignId) => {
      changeCampaignContacts(campaignId, "remove");
    });
  });
  const onCampaignChange = useCallback((selectedOptions, name) => {
    const selectedCampaigns = selectedOptions.map((option) => option.value);
    setState({ ...state, [name]: selectedCampaigns });
  });
  const onCampaignToAddChange = useCallback((selectedOptions) => {
    onCampaignChange(selectedOptions, "campaignsToAdd");
  });
  const onCampaignToRemoveChange = useCallback((selectedOptions) => {
    onCampaignChange(selectedOptions, "campaignsToRemove");
  });
  const createOptions = (selectedCampaigns) =>
    campaigns
      .filter((campaign) => !selectedCampaigns.includes(campaign.id))
      .map((campaign) => ({
        label: campaign.name,
        value: campaign.id,
      }));

  const campaignToAddOptions = createOptions(state.campaignsToRemove);
  const campaignToRemoveOptions = createOptions(state.campaignsToAdd);
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  const errorMessages = errors.map((error) => (
    <NetworkMessageDisplay key={error.id} error={error} />
  ));
  return (
    <Form
      handleSubmit={handleSubmit}
      id="manage-campaigns-form"
      button_color="gray-900"
      button_label="Change"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      {errorMessages}
      <NetworkMessageDisplay error={campaignLoadingError} />
      <SuccessActionIndicator success={success} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <SelectInput
          name="CampaignToAdd"
          title="Add to Campaigns"
          options={campaignToAddOptions}
          isMulti
          onChange={onCampaignToAddChange}
          predicate={(option) => state.campaignsToAdd.includes(option.value)}
        />
        <SelectInput
          name="segmentsToRemove"
          title="Remove from Campaigns"
          options={campaignToRemoveOptions}
          isMulti
          onChange={onCampaignToRemoveChange}
          predicate={(option) => state.campaignsToRemove.includes(option.value)}
        />
      </div>
    </Form>
  );
}

ManageCampaign.defaultProps = {
  currentModalId: null,
};

ManageCampaign.propTypes = {
  selectedContactsIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string,
};

export default withRouter(ManageCampaign);
