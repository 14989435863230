import { customBlocks } from "./config";

export default (editor, opts = {}) => {
  const options = {
    mediaWidth: "200px",
    ...opts,
  };
  const bm = editor.BlockManager;
  customBlocks(options).forEach(({ id, label, content, category, media }) => {
    bm.add(id, {
      label,
      content,
      category,
      media,
    });
  });
};
