import PropTypes from "prop-types";
import React from "react";
import { Icon } from "../../../base/components/Icons";

/**
 * @class DataGridRowActions
 * @description DataGridRowActions component
 */
function DataGridRowActions(props) {
  const { isDataEditable, onClickEdit, isDataDeletable, onClickDelete } = props;
  let edit_button_display: string = "d-none";
  let delete_button_display: string = "d-none";
  if (isDataEditable) {
    edit_button_display = "d-inline";
  }
  if (isDataDeletable) {
    delete_button_display = "d-inline";
  }
  return (
    <div className="text-end">
      <button
        className={`${edit_button_display} btn btn-outline-dark btn-sm`}
        type="button"
        onClick={onClickEdit}
      >
        <Icon icon="pencil-fill" /> Edit
      </button>
      <button
        className={`${delete_button_display} btn btn-outline-danger btn-sm ms-2`}
        type="button"
        onClick={onClickDelete}
      >
        <Icon icon="trash-fill" /> Delete
      </button>
    </div>
  );
}

DataGridRowActions.propTypes = {
  isDataEditable: PropTypes.bool,
  onClickEdit: PropTypes.func,
  isDataDeletable: PropTypes.bool,
  onClickDelete: PropTypes.func,
};

DataGridRowActions.defaultProps = {
  isDataEditable: false,
  onClickEdit: () => {},
  isDataDeletable: false,
  onClickDelete: () => {},
};

export default DataGridRowActions;
