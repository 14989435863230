import juice from "juice";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AuthenticationActions } from "modules/account/AccountActions";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import { closeModalById } from "modules/base/utilities/Actions";
import Editor from "modules/core/components/Editor";
import { canvasCss } from "modules/core/components/editor/assets";

function DesignEmailTemplate({ state, setState, currentModalId, site }) {
  const [loadCanvas, setLoadCanvas] = useState(false);
  const [isLoadingCanvas, setIsLoadingCanvas] = useState(false);
  const modalElement = document.getElementById(currentModalId);
  useEffect(() => {
    if (modalElement) {
      modalElement.addEventListener("show.bs.modal", () => {
        setIsLoadingCanvas(true);
      });
      modalElement.addEventListener("shown.bs.modal", () => {
        setLoadCanvas(true);
        setIsLoadingCanvas(false);
      });
      modalElement.addEventListener("hidden.bs.modal", () => {
        setLoadCanvas(false);
      });
    }
  }, [modalElement]);
  const template = {
    id: state.id ?? "new",
    page_title: state.name ?? "New Email Template",
    page_content: state.customHtml ?? "<html lang=''></html>",
  };
  const authenticationActions: any = new AuthenticationActions();
  const user: any = authenticationActions.retrieveAuthenticatedUser();

  function appendCanvasCSS(pageContent) {
    const cssData = canvasCss.map((url) => {
      return `<link rel="stylesheet" href="${url}" type="text/css" />`;
    });
    const css = cssData.join("\n");
    if (pageContent.includes("</head>")) {
      return pageContent.replace("</head>", `${css}</head>`);
    }
    return `${pageContent}${css}`;
  }

  const handleSavePageContent = (editorInstance, autoSaveEnabled, callback) => {
    const html: any = editorInstance.getHtml();
    const cssData = editorInstance.getCss();
    let pageContent = juice(html, { extraCss: cssData });
    pageContent = appendCanvasCSS(pageContent);
    setState((prev) => ({ ...prev, customHtml: pageContent }));
    callback();
    closeModalById(currentModalId);
  };
  if (!loadCanvas) {
    return null;
  }
  if (isLoadingCanvas) {
    return <NetworkActivityIndicator />;
  }
  const exclude_panel_buttons: Array = [
    {
      model: "options",
      id: "export-template",
    },
    {
      model: "options",
      id: "visit-site",
    },
    {
      model: "options",
      id: "publish-website",
    },
    {
      model: "options",
      id: "auto-save-enabled",
    },
    {
      model: "options",
      id: "pages-selector",
    },
    {
      model: "options",
      id: "open-account-dropdown",
    },
    {
      model: "home",
      id: "open-dashboard",
    },
    {
      model: "home",
      id: "upgrade",
    },
  ];
  return (
    <>
      <Editor
        canvasId={`email-template-${template.id ?? uuidv4()}-editor`}
        isNewsLetter
        components_prefix="email-template"
        user={user}
        handleGoHome={() => {}}
        handleVisitSite={() => {}}
        website={site}
        page={template}
        handleSavePageContent={handleSavePageContent}
        handleTogglePublishWebsite={() => {}}
        loadCanvas={loadCanvas}
        exclude_panel_buttons={exclude_panel_buttons}
        includeCanvasCSS
        includeCanvasJS
        allowComponentsResize
        allowComponentsDrag
        allowComponentsDrop
        allowComponentsStyling
        allowComponentsCopy
        allowComponentsDelete
        allowChangeComponentsBackground
        allowComponentsBadge
        allowComponentsHighlight
        allowImageEditing
        allowImageAddLink
        trackComponentsChanges
        isInsideModal
        currentModalId={currentModalId}
      />
    </>
  );
}

DesignEmailTemplate.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  site: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string.isRequired,
};

export default withRouter(DesignEmailTemplate);
