export class PriceUtility {
  static formatPrice(price) {
    return Number(price).toFixed(2);
  }

  static getMonthlyPrice(plan) {
    let amount: number = 0;
    if (plan.interval === "year") {
      amount = plan.amount / 12;
    } else if (plan.interval === "month") {
      amount = plan.amount;
    } else if (plan.interval === "day") {
      const { interval_count } = plan;
      if (interval_count === 30) {
        amount = plan.amount;
      } else if (interval_count === 365) {
        amount = parseFloat(plan.amount) + 0.048;
      } else if (interval_count === 730) {
        amount = parseFloat(plan.amount) + 0.04;
      } else if (interval_count === 1095) {
        amount = parseFloat(plan.amount) + 0.03;
      }
    }
    return this.formatPrice(amount);
  }

  static getTotalPrice(plan) {
    let amount: number = 0.0;
    const { interval_count } = plan;
    if (plan.interval === "day") {
      if (interval_count === 30) {
        amount = plan.amount;
      } else if (interval_count === 365) {
        amount = (parseFloat(plan.amount) + 0.048) * 12;
      } else if (interval_count === 730) {
        amount = (parseFloat(plan.amount) + 0.04) * 24;
      } else if (interval_count === 1095) {
        amount = (parseFloat(plan.amount) + 0.03) * 36;
      }
    } else {
      amount = plan.amount * interval_count;
    }
    return this.formatPrice(amount);
  }
}
