import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import EditContactForm from "modules/marketing/components/contacts/EditContactForm";
import { Contact } from "modules/marketing/MarketingModels";

function EditContactModal(props) {
  const { row } = props;
  const modalId = `edit-contact-modal-${row.id}`;
  return (
    <Modal modal_title="Edit Contact" modal_id={modalId} size="md">
      <EditContactForm contact={row} currentModalId={modalId} />
    </Modal>
  );
}

EditContactModal.propTypes = {
  row: PropTypes.instanceOf(Contact).isRequired,
};

export default EditContactModal;
