export const CodeBlock = (editor, opts = {}) => {
    const bm = editor.BlockManager;
    bm.add("bootstrap-code", {
        label: `Code`,
        activate: true,
        select: true,
        category: "Extra",
        content:'<code>Text</code>',
        media: '<i class="oicon-code" style="font-size: 42px;"></i>'
    });
};