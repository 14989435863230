import $ from "jquery";

export default class LinkToModalContent {
  constructor(editor, opts = {}) {
    this.editor = editor;
    this.target = opts.target || editor.getSelected();
    this.pfx = editor.getConfig("stylePrefix");
    this.id = editor.Storage.getConfig().id || "gjs-";
    this.opts = opts;
  }

  render() {
    const { opts, pfx } = this;
    const content: any = $(`
                <div class="form-group p-4">
                <div class="gjs-trt-trait__wrp gjs-trt-trait__wrp-href"><div class="gjs-trt-trait gjs-trt-trait--text">
                    <div class="gjs-label-wrp" ><div class="gjs-label" title="Href">Href</div></div>
                    <div class="gjs-field-wrp gjs-field-wrp--text">
                      <div class="gjs-field gjs-field-text">
                      <input type="text" placeholder="eg. https://google.com" id="link-to-input" value="${
                        opts.url
                      }">
                      </div>
                    </div>
                  </div>
                  </div>

                  <div class="gjs-trt-trait__wrp gjs-trt-trait__wrp-target">
                  <div class="gjs-trt-trait gjs-trt-trait--select">
                  <div class="gjs-label-wrp"><div class="gjs-label" title="Target">Target</div></div>
                  <div class="gjs-field-wrp gjs-field-wrp--select">
                    <div class="gjs-field gjs-field-select">
                  <div><select id="link-to-target">
                  <option value=""  ${
                    opts.targetWindow === "" ? "selected" : ""
                  }></option>
                  <option value="_self" ${
                    opts.targetWindow === "_self" ? "selected" : ""
                  }>This window</option>
                  <option value="_blank" ${
                    opts.targetWindow === "_blank" ? "selected" : ""
                  }>New window</option>
                  </select></div>
                  <div class="gjs-sel-arrow">
                    <div class="gjs-d-s-arrow"></div>
                  </div>
                </div>
                  </div>
                </div></div>

                <button class="${pfx}btn-prim" id="add-link">Save</button>
              </div>
            `);
    return content;
  }
}
