import { API_BASE_URL } from "../core/constants/Endpoints";
import {
  PATH_WEBSITE_DETAIL,
  URL_WEBSITES,
} from "modules/website/WebsiteConstants";

export const URL_MARKETING_DASHBOARD_WIDGETS = `${API_BASE_URL}/marketing/widgets/`;
export const URL_MARKETING_DASHBOARD_WIDGET_DATA = `${URL_MARKETING_DASHBOARD_WIDGETS}:widgetType/`;
export const URL_MARKETING_CONTACTS = `${API_BASE_URL}/marketing/contacts/`;
export const URL_MARKETING_IMPORT_GOOGLE_CONTACTS = `${URL_MARKETING_CONTACTS}import-google-contacts/`;
export const URL_MARKETING_ADD_DO_NOT_CONTACT = `${URL_MARKETING_CONTACTS}:id/add-donotcontact/`;
export const URL_MARKETING_REMOVE_DO_NOT_CONTACT = `${URL_MARKETING_CONTACTS}:id/remove-donotcontact/`;
export const URL_MARKETING_CONTACTS_STATISTICS = `${URL_MARKETING_CONTACTS}statistics/`;
export const URL_MARKETING_FORMS = `${API_BASE_URL}/marketing/forms/`;
export const URL_MARKETING_CONTACT = `${URL_MARKETING_CONTACTS}:id/`;
export const URL_MARKETING_FORM = `${URL_MARKETING_FORMS}:id/`;
export const URL_MARKETING_FIELDS = `${API_BASE_URL}/marketing/fields/`;
export const URL_DELETE_FORM_FIELDS = `${URL_MARKETING_FORM}delete-fields/`;
export const URL_DELETE_FORM_ACTIONS = `${URL_MARKETING_FORM}delete-actions/`;
export const URL_MARKETING_FOCUS_ITEMS = `${API_BASE_URL}/marketing/focus-items/`;
export const URL_MARKETING_FOCUS_ITEM = `${URL_MARKETING_FOCUS_ITEMS}:id/`;
export const URL_MARKETING_FORM_SUBMISSIONS = `${API_BASE_URL}/marketing/forms/:id/submissions/`;
export const URL_MARKETING_FORMS_SUBMISSIONS_STATISTICS = `${URL_MARKETING_FORMS}submissions/`;
export const URL_MARKETING_SEGMENTS = `${API_BASE_URL}/marketing/segments/`;
export const URL_MARKETING_SEGMENT = `${URL_MARKETING_SEGMENTS}:id/`;
export const URL_MARKETING_SEGMENT_CHANGE_CONTACTS = `${URL_MARKETING_SEGMENT}change-contacts/`;
export const URL_MARKETING_CONTACT_SEGMENTS = `${URL_MARKETING_CONTACT}segments/`;
export const URL_MARKETING_CONTACT_CAMPAIGNS = `${URL_MARKETING_CONTACT}campaigns/`;
export const URL_MARKETING_EMAIL_TEMPLATES = `${API_BASE_URL}/marketing/email-templates/`;
export const URL_MARKETING_EMAIL_TEMPLATE = `${URL_MARKETING_EMAIL_TEMPLATES}:id/`;
export const URL_MARKETING_SEND_EMAIL_TO_CONTACT = `${URL_MARKETING_EMAIL_TEMPLATES}:id/send-email-to-contact/`;

export const URL_MARKETING_SEND_EMAIL_TO_SEGMENT = `${URL_MARKETING_EMAIL_TEMPLATE}send-email-to-segments/`;
export const URL_MARKETING_EMAIL_STATISTICS = `${URL_MARKETING_EMAIL_TEMPLATES}statistics/`;
export const URL_MARKETING_CAMPAIGNS = `${API_BASE_URL}/marketing/campaigns/`;

export const URL_MARKETING_CAMPAIGN = `${URL_MARKETING_CAMPAIGNS}:id/`;
export const URL_MARKETING_CAMPAIGN_ADD_CONTACT = `${URL_MARKETING_CAMPAIGNS}:id/add-contacts/`;
export const URL_MARKETING_CAMPAIGN_REMOVE_CONTACT = `${URL_MARKETING_CAMPAIGNS}:id/remove-contacts/`;
export const URL_MARKETING_PREBUILT_EMAIL_TEMPLATES = `${URL_WEBSITES}email-templates/`;
export const URL_MARKETING_PREBUILT_FOCUS_ITEMS_TEMPLATES = `${URL_WEBSITES}engagement-item-templates/`;

export const URL_MARKETING_GET_PROCESSED_FILE_CONTACTS = `${URL_MARKETING_CONTACTS}get-processed-contacts/`;
export const URL_MARKETING_PROCESS_CONTACTS_FILE = `${URL_MARKETING_CONTACTS}process-contacts-file/`;
export const URL_MARKETING_IMPORT_FILE_CONTACTS = `${URL_MARKETING_CONTACTS}import-contacts-from-file/`;
export const URL_MARKETING_CONTACTS_UPLOAD_PROGRESS = `${URL_MARKETING_CONTACTS}contact-import-progress/`;
export const URL_MARKETING_GENERATE_EMAIL_TEMPLATE = `${URL_MARKETING_EMAIL_TEMPLATES}generate-email-template/`;
export const URL_MARKETING_BATCH_DELETE_CONTACTS = `${URL_MARKETING_CONTACTS}batch/`;
export const URL_MARKETING_CONTENTS = `${API_BASE_URL}/marketing/contents/`;
export const URL_MARKETING_CONTENT = `${URL_MARKETING_CONTENTS}:id/`;
export const URL_MARKETING_CHANNELS = `${API_BASE_URL}/marketing/channels/`;
export const URL_MARKETING_CANCEL_UPLOAD = `${URL_MARKETING_CONTACTS}cancel-contacts-upload/`;
export const URL_MARKETING_STAGES = `${API_BASE_URL}/marketing/stages/`;
export const URL_MARKETING_STAGE = `${URL_MARKETING_STAGES}:id/`;
export const URL_MARKETING_CHANGE_STAGE_CONTACTS = `${URL_MARKETING_STAGE}change-contacts/`;

export const URL_GOOGLE_ANALYTICS = `${API_BASE_URL}/marketing/google/`;
export const URL_MARKETING_ANALYTICS_AUTHENTICATION = `${URL_GOOGLE_ANALYTICS}authenticate/`;
export const URL_GOOGLE_ANALYTICS_DATA = `${URL_GOOGLE_ANALYTICS}analytics-data/`;
export const URL_GOOGLE_ANALYTICS_PROPERTIES = `${URL_GOOGLE_ANALYTICS_DATA}properties/`;
export const URL_CREATE_SITE_PROPERTY = `${URL_GOOGLE_ANALYTICS_DATA}create-site-property/`;

export const URL_MARKETING_ASSETS = `${API_BASE_URL}/marketing/assets/`;
export const URL_MARKETING_ASSET = `${URL_MARKETING_ASSETS}:id/`;

export const URL_MARKETING_TRANSPORTS = `${API_BASE_URL}/marketing/transports/`;
export const URL_MARKETING_TRANSPORT = `${URL_MARKETING_TRANSPORTS}:id/`;

export const PATH_MARKETING = `${PATH_WEBSITE_DETAIL}/marketing`;
export const PATH_MARKETING_DASHBOARD = `${PATH_WEBSITE_DETAIL}/marketing/dashboard`;
export const PATH_MARKETING_CONTACTS = `${PATH_MARKETING}/contacts`;
export const PATH_MARKETING_FORMS = `${PATH_MARKETING}/forms`;
export const PATH_MARKETING_FOCUS_ITEMS = `${PATH_MARKETING}/focus-items`;
export const PATH_MARKETING_FORM_SUBMISSIONS = `${PATH_MARKETING_FORMS}/:formId/submissions`;
export const PATH_MARKETING_SEGMENTS = `${PATH_MARKETING}/segments`;
export const PATH_MARKETING_EMAIL_TEMPLATES = `${PATH_MARKETING}/email-templates`;
export const PATH_MARKETING_CAMPAIGNS = `${PATH_MARKETING}/campaigns`;
export const PATH_MARKETING_CONTENT = `${PATH_MARKETING}/content`;
export const PATH_MARKETING_STAGES = `${PATH_MARKETING}/stages`;
export const PATH_MARKETING_ASSETS = `${PATH_MARKETING}/assets`;

export const NEW_FORM_FIELD_PREFIX = "new-field";
export const NEW_FORM_ACTION_PREFIX = "new-action";
export const CAMPAIGN_ACTION_LINE_COLOR = "blue";
export const CAMPAIGN_DECISION_YES_LINE_COLOR = "green";
export const CAMPAIGN_DECISION_NO_LINE_COLOR = "red";
export const CAMPAIGN_CONDITION_TRUE_LINE_COLOR = "green";
export const CAMPAIGN_CONDITION_FALSE_LINE_COLOR = "red";
export const CAMPAIGN_NEW_LINE_COLOR = "gray";
export const NEW_CAMPAIGN_EVENT_PREFIX = "new_";

export const URL_MARKETING_NOTES = `${API_BASE_URL}/marketing/contacts/:contactId/notes/`;
export const URL_MARKETING_CONTACT_NOTES = `${API_BASE_URL}/marketing/notes/`;
export const URL_MARKETING_NOTE = `${API_BASE_URL}/marketing/notes/:id/`;
export const PATH_MARKETING_NOTES = `${PATH_MARKETING}/contact/:contactId/notes/`;

const getEnvVar = (varName, fallback) => process.env[varName] ?? fallback;

export const MARKETING_SLUG = getEnvVar(
  "REACT_APP_MARKETING_SLUG",
  "marketing",
);

export const MARKETING_CONTACTS_SLUG = getEnvVar(
  "REACT_APP_MARKETING_CONTACTS_SLUG",
  "marketing-contacts",
);
export const MARKETING_FORMS_SLUG = getEnvVar(
  "REACT_APP_MARKETING_FORMS_SLUG",
  "marketing-forms",
);
export const MARKETING_FOCUS_ITEMS_SLUG = getEnvVar(
  "REACT_APP_MARKETING_FOCUS_ITEMS_SLUG",
  "marketing-focus-items",
);
export const MARKETING_EMAIL_TEMPLATES_SLUG = getEnvVar(
  "REACT_APP_MARKETING_EMAIL_TEMPLATES_SLUG",
  "marketing-email-templates",
);
export const MARKETING_CAMPAIGNS_SLUG = getEnvVar(
  "REACT_APP_MARKETING_CAMPAIGNS_SLUG",
  "marketing-campaigns",
);
export const MARKETING_CONTENT_SLUG = getEnvVar(
  "REACT_APP_MARKETING_CONTENT_SLUG",
  "marketing-content",
);
export const MARKETING_NOTES_SLUG = getEnvVar(
  "REACT_APP_MARKETING_NOTES_SLUG",
  "marketing-notes",
);
export const MARKETING_STAGES_SLUG = getEnvVar(
  "REACT_APP_MARKETING_STAGES_SLUG",
  "marketing-stages",
);
export const MARKETING_ASSETS_SLUG = getEnvVar(
  "REACT_APP_MARKETING_ASSETS_SLUG",
  "marketing-assets",
);
