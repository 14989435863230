import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUrlData } from "../../../../../core/utilities/redux/componentActions";
import useOrders from "../../../../hooks/useOrders";
import usePayments from "../../../../hooks/usePayments";
import {
  CURRENT_DAY,
  DAILY,
  MONTHLY,
  PAYMENT_COMPLETE,
  WEEKLY,
} from "../../../constants";

import { calculateChartData } from "./ChartUtils";

const SHOP_DETAILS_DATA_KEY = "SHOP_DETAILS_DATA_KEY";

const SalesChart = (props) => {
  const { match } = props;
  const { params } = match;
  const site_id = params.id;
  const [interval, setInterval] = useState(DAILY);
  const { orders } = useOrders(site_id);
  const { isLoading, payments } = usePayments(site_id, PAYMENT_COMPLETE);

  // Get the current month
  const currentMonth = moment().month();
  const currentWeek = moment().week();

  const chartData = React.useMemo(() => {
    return calculateChartData(
      interval,
      orders,
      payments,
      currentMonth,
      currentWeek,
    );
  }, [interval, orders, payments, currentMonth, currentWeek]);

  const handleIntervalChange = (_interval) => {
    setInterval(_interval);
  };

  if (isLoading) {
    return <div className="rounded p-4 vh-50 skeleton-loading" />;
  }

  function DurationState() {
    return (
      <div className="btn-group d-block p-4 mr-2">
        <button
          className={classNames("btn btn-outline-shop-chart-button p-2", {
            active: interval === MONTHLY,
          })}
          type="button"
          onClick={() => handleIntervalChange(MONTHLY)}
        >
          Monthly
        </button>
        <button
          type="button"
          className={classNames("btn btn-outline-shop-chart-button p-2", {
            active: interval === WEEKLY,
          })}
          onClick={() => handleIntervalChange(WEEKLY)}
        >
          Weekly
        </button>
        <button
          type="button"
          className={classNames("btn btn-outline-shop-chart-button p-2", {
            active: interval === DAILY,
          })}
          onClick={() => handleIntervalChange(DAILY)}
        >
          Daily
        </button>
        <button
          type="button"
          className={classNames("btn btn-outline-shop-chart-button p-2", {
            active: interval === CURRENT_DAY,
          })}
          onClick={() => handleIntervalChange(CURRENT_DAY)}
        >
          Today
        </button>
      </div>
    );
  }

  return (
    <div className="rounded-3 p-4 bg-white mh-100 shadow">
      <h3>
        <b>Sales</b>
      </h3>
      <DurationState />
      <div>
        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="area"
            height={353}
          />
        </div>
      </div>
    </div>
  );
};

SalesChart.defaultProps = {};

SalesChart.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

/**
 * @name mapStateToProps
 * Maps the state to the props.
 * @param state
 * @returns {{shop, isLoadingShop: (boolean|*)}}
 */
function mapStateToProps(state: any) {
  const { sessionVariables, dataByUrl } = state;
  const shop_details_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    SHOP_DETAILS_DATA_KEY,
  );
  return {
    isLoadingShop: shop_details_data.isFetching,
    shop: shop_details_data.items,
  };
}

export default connect(mapStateToProps)(withRouter(SalesChart));
