import { PublicApi } from "../core/utilities/api/PublicApi";
import { URL_CHECK_PHISHING_DOMAIN } from "./LoudgunConstants";
import PhishingCheck from "./LoudgunModels";

export class CheckPhishingDomainAPI extends PublicApi {
  checkPhishingUrl(
    parameters: Object,
    callback: (
      is_successful: boolean,
      phishing_check_or_error: PhishingCheck | string,
    ) => void,
  ) {
    this.postAPIRequest(
      URL_CHECK_PHISHING_DOMAIN,
      (success_response) => {
        callback(true, new PhishingCheck(success_response));
      },
      (error_response) => {
        callback(false, this.extractResponseError(error_response));
      },
      parameters,
      this.getUnauthenticatedHeaders(),
    );
  }
}
