import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import DebouncedInput from "modules/base/components/DebouncedInput";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import { openModal } from "modules/base/utilities/Actions";
import PreviewContactModal from "modules/marketing/components/contacts/PreviewContactModal";
import { formSubmissionsColumns } from "modules/marketing/components/tableColumns";
import MarketingAPI from "modules/marketing/MarketingAPI";

function FormSubmissionsList(props) {
  const { match } = props;
  const { formId, id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const [Search, setSearch] = useState("");
  const [paginationParams, setPaginationParams] = useState({
    start: 0,
    limit: 10,
  });
  const params = useMemo(
    () => ({
      ...paginationParams,
      orderBy: "s.date_submitted",
      orderByDir: "desc",
      Search,
    }),
    [paginationParams, Search],
  );
  const {
    formSubmissions,
    total,
    error: submissionsError,
    isLoading: isLoadingSubmissions,
  } = API.getFormSubmissions(formId, params);
  const {
    form,
    error: formError,
    isLoading: isFormLoading,
  } = API.getForm(formId);
  const error = submissionsError || formError;
  const isLoading = isLoadingSubmissions || isFormLoading;
  let formFields = form?.fields || [];
  formFields = formFields.filter(
    (field) => !["button", "captcha"].includes(field.type),
  );
  const data = formSubmissions.map((submission) => {
    let updatedSubmission = { ...submission };
    Object.keys(submission.results).forEach((key) => {
      const field = formFields.find((f) => f.alias === key);
      if (field) {
        updatedSubmission = {
          ...updatedSubmission,
          [field.alias]: submission.results[key],
        };
      }
    });
    return updatedSubmission;
  });

  const handlePaginationChange = useCallback((newPaginationParams) => {
    setPaginationParams(newPaginationParams);
  }, []);

  const formFieldsColumns = formFields.map((field) => ({
    header: field.label,
    accessorKey: `${field.alias}`,
    cell: (info) => info.renderValue(),
    enableSorting: true,
  }));
  const previewContactModal = useCallback((lead, modalUUID) => {
    openModal(`preview-contact-modal-${modalUUID}`);
  });
  const contactDisplay = useCallback((lead) => {
    if (!lead) {
      return null;
    }
    const { email, firstname, lastname } = lead;
    let displayName = email;
    if (firstname || lastname) {
      displayName = `${firstname ?? ""} ${lastname ?? ""}`.trim();
    }
    const modalUUID = uuidv4();
    const handlePreview = () => {
      previewContactModal(lead, modalUUID);
    };
    return (
      <>
        <PreviewContactModal contact={lead} modalUUID={modalUUID} />
        <button
          type="button"
          className="btn btn-link p-0"
          onClick={handlePreview}
        >
          {displayName}
        </button>
      </>
    );
  });
  const previewContactColumn = {
    header: "Contact",
    accessorKey: "lead",
    cell: (info) => contactDisplay(info.renderValue()),
  };
  const columns = [
    ...formSubmissionsColumns,
    previewContactColumn,
    ...formFieldsColumns,
  ];
  const handleInputChange = (value) => {
    setSearch(value);
  };
  return (
    <>
      <span>
        <div className="h2">{form?.name} submissions</div>
      </span>
      <NetworkMessageDisplay error={error} />
      <DebouncedInput
        type="text"
        placeholder="Search Submissions"
        debounceTime={1500}
        className="p-1 rounded border border-secondary text-secondary"
        onChange={handleInputChange}
      />
      <DataTable
        data={data}
        columns={columns}
        total={total}
        isLoading={isLoading}
        objectType="formSubmissions"
        allowExport
        exportFileName={`${form?.name} submissions`}
        onPaginationChange={handlePaginationChange}
        isServerSidePagination
      />
    </>
  );
}

FormSubmissionsList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      formId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(FormSubmissionsList);
