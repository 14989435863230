import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import React from "react";
import { useParams } from "react-router-dom";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import WebsiteSidebar from "./SidebarWebsite";
import WhatsAppSettingsCard from "./WhatsAppSettingsCard";
import SiteApi from "modules/website/api/SiteApi";

const siteApi = new SiteApi();

function WebsiteSettingsPanel() {
  const { id: siteId } = useParams();
  const [form, setForm] = React.useState({
    whatsapp_phone_number: "",
    whatsapp_enabled: false,
  });

  const {
    data: website,
    isLoading: isWebsiteLoading,
    isError: isWebsiteError,
    error: websiteError,
  } = useQuery({
    queryKey: ["website", siteId],
    queryFn: () =>
      new Promise((resolve, reject) =>
        siteApi.retrieveWebsite(
          siteId,
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          },
        ),
      ),
    onSuccess: (data) => {
      setForm((_form) => ({
        ..._form,
        whatsapp_phone_number: data.whatsapp_phone_number,
        whatsapp_enabled: data.whatsapp_enabled,
      }));
    },
    enabled: Boolean(siteId),
  });

  const {
    mutate: updateWebsite,
    isLoading: isUpdateWebsiteLoading,
    error: updateWebsiteError,
    isError: isUpdateWebsiteError,
  } = useMutation({
    mutationKey: ["website", siteId],
    mutationFn: (data) =>
      new Promise((resolve, reject) =>
        siteApi.updateWebsiteFields(
          siteId,
          data,
          (isSuccessful, sitesOrError) => {
            if (isSuccessful) {
              resolve(sitesOrError);
            } else {
              reject(sitesOrError);
            }
          },
        ),
      ),
    onSuccess: (data) => {
      setForm((_form) => ({
        ..._form,
        whatsapp_phone_number: data.whatsapp_phone_number,
        whatsapp_enabled: data.whatsapp_enabled,
      }));
    },
    enabled: Boolean(siteId),
  });

  if (isWebsiteLoading) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <ComponentLoadingIndicator />
      </div>
    );
  }

  const onWhatsAppChange = (data) => {
    setForm((_form) => ({ ..._form, ...data }));
  };

  const onSave = (data) => {
    updateWebsite(data);
  };

  let websiteErrorAlert = null;
  if (isWebsiteError) {
    websiteErrorAlert = (
      <div className="alert alert-danger" role="alert">
        {websiteError.message ?? "Something went wrong"}
      </div>
    );
  }

  let updateWebsiteErrorAlert = null;
  if (isUpdateWebsiteError) {
    updateWebsiteErrorAlert = (
      <div className="alert alert-danger" role="alert">
        {updateWebsiteError.message ?? "Something went wrong"}
      </div>
    );
  }

  let saveSettingsButtonLoader = null;
  if (isUpdateWebsiteLoading) {
    saveSettingsButtonLoader = (
      <>
        <span
          className="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
      </>
    );
  }
  const saveSettingsButtonClasses = classNames("btn btn-primary", {
    "btn-loading": isUpdateWebsiteLoading,
  });

  const saveSettingsButton = (
    <div>
      <button
        type="submit"
        className={saveSettingsButtonClasses}
        onClick={() => onSave(form)}
      >
        {saveSettingsButtonLoader}
        Save Settings
      </button>
    </div>
  );

  return (
    <SidebarWrapper sidebar={<WebsiteSidebar website={website} />}>
      {websiteErrorAlert}
      {updateWebsiteErrorAlert}
      <div className="card">
        <div className="card-header">
          <h5 className="fw-light text-muted">Website Settings</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <WhatsAppSettingsCard
                whatsAppNumber={form.whatsapp_phone_number}
                whatsAppEnabled={form.whatsapp_enabled}
                onChange={onWhatsAppChange}
              />
            </div>
          </div>
        </div>
        <div className="card-footer">{saveSettingsButton}</div>
      </div>
    </SidebarWrapper>
  );
}

export default WebsiteSettingsPanel;
