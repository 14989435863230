import PropTypes from "prop-types";

import { useState } from "react";
import { withRouter } from "react-router-dom";
import Widget from "modules/marketing/components/dashboard/Widget";
import MarketingAPI from "modules/marketing/MarketingAPI";

function EmailsChart({ match, filterParams }) {
  const [widget, setWidget] = useState("ignored-vs-read-emails");

  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);

  const { widgetData, error, isLoading } = API.getDashboardWidget(
    widget,
    filterParams,
  );

  const widgets = [
    {
      value: "ignored-vs-read-emails",
      label: "Emails: Ignored vs. Opened",
      tooltip:
        "This widget shows a comparison between the number of emails that were ignored and those that were opened by recipients. It helps you gauge the engagement level of your email campaigns.",
    },
    {
      value: "emails-in-time",
      label: "Email Activity Over Time",
      tooltip:
        "This chart displays the number of emails sent over a specific period. It provides insights into the volume of your email campaigns and helps track the overall email activity.",
    },
    {
      value: "emails-best-hours",
      label: "Optimal Email Engagement Times",
      tooltip:
        "This widget highlights the times of day when your emails are most likely to be opened. It helps you optimise the timing of your email sends to increase engagement and open rates.",
    },
    {
      value: "device-granularity-email",
      label: "Email Opens by Device Type",
      tooltip:
        "This chart breaks down email opens by device type (e.g., mobile, tablet, desktop). It helps you understand which devices your audience is using to engage with your emails, allowing you to tailor content and design for optimal viewing.",
    },
  ];

  return (
    <Widget
      widget={widget}
      isLoading={isLoading}
      error={error}
      widgets={widgets}
      data={widgetData}
      onSelectWidget={setWidget}
    />
  );
}

EmailsChart.defaultProps = {
  filterParams: {},
};

EmailsChart.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  filterParams: PropTypes.instanceOf(Object),
};

export default withRouter(EmailsChart);
