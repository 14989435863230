import { modelObjectsFromArray } from "../../core/utilities/commonUtilities";
import PageSection from "./PageSection";

/**
 * @class Page
 * @description Model for a page
 */
export default class ShopPage {
  id: number;

  title: string;

  meta_title: string;

  content: Object;

  meta_description: string;

  visible: boolean;

  slug: string;

  type: string;

  type_display: string;

  locale: string;

  deleted_at: string;

  created_at: string;

  updated_at: string;

  sections: Array<PageSection>;

  html_content: string;

  constructor(parameters) {
    this.id = parameters.id;
    this.title = parameters.title;
    this.meta_title = parameters.meta_title;
    this.content = parameters.content;
    this.meta_description = parameters.meta_description;
    this.visible = parameters.visible;
    this.slug = parameters.slug;
    this.type = parameters.type;
    this.type_display = parameters.type_display;
    this.locale = parameters.locale;
    this.deleted_at = parameters.deleted_at;
    this.created_at = parameters.created_at;
    this.updated_at = parameters.updated_at;
    this.sections = parameters.sections;
    this.sections = modelObjectsFromArray(PageSection, parameters.sections);
    this.html_content = parameters.html_content;
  }
}
