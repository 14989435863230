import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AuthenticationActions } from "../../account/AccountActions";
import { Icon } from "../../base/components/Icons";
import { BillingAPI } from "../../billing/BillingAPIs";
import { BILLING_PLANS_DATA, URL_PLANS } from "../../billing/BillingConstants";
import { BillingPlan } from "../../billing/BillingModels";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import { ErrorModal } from "../../core/components/ErrorModal";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import Warning from "../../core/components/Warning";
import {
  A_RECORD,
  APP_NAME,
  HELP_URL,
  NS1_RECORD,
  NS2_RECORD,
} from "../../core/constants/Constants";
import {
  fetchUrlData,
  getUrlData,
} from "../../core/utilities/redux/componentActions";
import { toast_styles, ToastUtility } from "../../core/utilities/ToastUtility";
import DomainSearch from "../../domains/components/DomainSearch";
import { Connect3rdPartyDomain } from "../components/Connect3rdPartyDomain";
import PageLoadingIndicator from "../components/PageLoadingIndicator";
import WebsiteSidebar from "../components/SidebarWebsite";
import { VisitDomainBanner } from "../components/VisitDomainBanner";

import SiteApi from "modules/website/api/SiteApi";

function WebsiteDomains(props) {
  const authenticationActions: any = new AuthenticationActions();
  const user: any = authenticationActions.retrieveAuthenticatedUser();
  const site_id: any = props.match.params.id;

  const [loading_website, setIsLoadingWebsite] = useState(true);
  const [loading_domain, setIsLoadingDomain] = useState(false);
  const [website, setWebsite] = useState([]);
  const [domains, setDomains] = useState([]);
  const [web_error, setWebsiteError] = useState(null);
  const [domain_error, setDomainError] = useState(null);
  const [is_disconnecting_domain, setIsDisconnectingDomain] = useState(false);
  const [is_refreshing_domain, setIsRefreshingDomain] = useState(false);
  const [clicked_disconnect_button_id, setClickedDisconnectButtonId] =
    useState("");
  const [clicked_refresh_button_id, setClickedRefreshButtonId] = useState("");
  const [domain_loading_text, setDomainLoadingText] =
    useState("Fetching domains");
  const siteApi: any = new SiteApi();

  const fetchBillingPlans: any = () =>
    fetchUrlData(BILLING_PLANS_DATA, URL_PLANS, props);

  const retrieveDomains: any = (loader = true) => {
    setIsLoadingDomain(loader);
    siteApi.retrieveWebsiteDomains(
      site_id,
      (is_successful, domains_or_error) => {
        setIsLoadingDomain(false);
        if (is_successful) {
          setDomains(domains_or_error);
        } else {
          setDomainError(domains_or_error);
          ToastUtility.toast(toast_styles.error, domains_or_error);
        }
        setClickedRefreshButtonId("");
        setClickedDisconnectButtonId("");
      },
    );
  };

  const loadWebsite: any = () => {
    setIsLoadingWebsite(true);
    siteApi.retrieveWebsite_(site_id, (is_successful, sites_or_error) => {
      setIsLoadingWebsite(false);
      if (is_successful) {
        setWebsite(sites_or_error);
        fetchBillingPlans();
        retrieveDomains();
      } else {
        setWebsiteError(sites_or_error);
      }
    });
  };

  useEffect(loadWebsite, []);

  const upgradeSubscription: any = () => {
    const { paid_plan } = props;
    const billing_api: any = new BillingAPI();
    billing_api.navigateToPricing(website, paid_plan);
  };

  const disconnectCustomDomain: any = (e, id) => {
    const domain_to_disconnect: any = e.currentTarget.id;
    setClickedDisconnectButtonId(domain_to_disconnect);
    setIsDisconnectingDomain(true);
    siteApi.disconnectWebsiteDomain(id, (is_deleted, error) => {
      setIsDisconnectingDomain(false);
      if (is_deleted) {
        ToastUtility.toast(toast_styles.success, "The domain is disconnected");
        retrieveDomains(false);
        // Todo: Refresh website in redux
      } else {
        ToastUtility.toast(toast_styles.error, error);
      }
    });
  };

  const refreshCustomDomain: any = (e) => {
    setIsRefreshingDomain(true);
    const domain_name: any = e.currentTarget.id;
    setClickedRefreshButtonId(domain_name);
    retrieveDomains(false);
    setIsRefreshingDomain(false);
  };

  const after_connection_actions: any = () => {
    retrieveDomains();
  };

  const toggleRefreshBtn: any = (domain_name) => {
    return clicked_refresh_button_id === domain_name;
  };
  const toggleRefreshLoaderBtn: any = (domain_name) => {
    return clicked_refresh_button_id !== domain_name;
  };

  const toggleDisconnectBtn: any = (domain_name) => {
    return clicked_disconnect_button_id === domain_name;
  };
  const toggleDisconnectLoaderBtn: any = (domain_name) => {
    return clicked_disconnect_button_id !== domain_name;
  };

  if (loading_website) {
    return <PageLoadingIndicator />;
  }
  if (!user) {
    loadWebsite();
    return <PageLoadingIndicator />;
  }
  let main_content;
  if (!website) {
    main_content = <Warning text="Error" btnText="Ok" />;
  }

  let domain_cards;
  if (loading_domain) {
    domain_cards = <ComponentLoadingIndicator text={domain_loading_text} />;
  } else {
    domain_cards = domains.map((domain) => {
      const current_nameservers_list: null[] = [];
      const current_nameservers_component: any =
        domain.custom_domain_nameservers.map((ns) => {
          current_nameservers_list.push(ns.data);
          return <li>{ns.data}</li>;
        });

      const is_ns_config_valid: any =
        current_nameservers_list.includes(NS1_RECORD) &&
        current_nameservers_list.includes(NS2_RECORD);
      const is_a_record_config_valid: any =
        `${domain.custom_domain_a_record}.` === A_RECORD;

      const connection_steps: any = (
        <div>
          <div className="alert alert-info">
            To connect, Follow the following steps:
          </div>
          <ol className="mt-3">
            <li className="my-2">
              Login in to your domain provider's dashboard (or CPanel)
            </li>
            <li className="my-2">Navigate to the 'Manage Domain' section</li>
            <li className="my-2">
              Change the nameservers of your domain to the intended nameservers
              below.
            </li>
            <li className="my-2">
              Wait for the domain to propagate. (changes can take up to 24 hours
              to propagate).
            </li>
            <li className="my-2">
              Click on the refresh button to check the configuration status.
            </li>
          </ol>
        </div>
      );
      return (
        <div className="card mb-3">
          <div className="card-header bg-white">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <strong className="me-2">{domain.domain_name}</strong>
                  <a
                    href={`http://${domain.domain_name}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon icon="box-arrow-up-right" />
                  </a>
                </div>
                <div
                  className={
                    is_a_record_config_valid ? "text-success" : "text-danger"
                  }
                >
                  <Icon
                    icon={
                      is_a_record_config_valid
                        ? "check-circle"
                        : "exclamation-circle"
                    }
                  />
                  <span className="ms-2">
                    {is_a_record_config_valid
                      ? "Valid Configuration"
                      : "Invalid Configuration"}
                  </span>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-outline-olitt-grey shadow-none me-2"
                  onClick={(e) => refreshCustomDomain(e)}
                  id={domain.domain_name}
                  disabled={is_refreshing_domain}
                  type="button"
                >
                  <div hidden={toggleRefreshBtn(domain.domain_name)}>
                    Refresh
                  </div>
                  <div hidden={toggleRefreshLoaderBtn(domain.domain_name)}>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                    />{" "}
                    Refreshing
                  </div>
                </button>
                <button
                  className="btn btn-sm btn-outline-olitt-grey shadow-none me-2"
                  onClick={(e) => disconnectCustomDomain(e, domain.id)}
                  id={domain.domain_name}
                  disabled={is_disconnecting_domain}
                  type="button"
                >
                  <div hidden={toggleDisconnectBtn(domain.domain_name)}>
                    Disconnect
                  </div>
                  <div hidden={toggleDisconnectLoaderBtn(domain.domain_name)}>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                    />{" "}
                    Disconnecting
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <ul className="nav nav-pills mb-3">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href={`#nameservers-${domain.id}`}
                  role="tab"
                >
                  Nameservers (Recommended)
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href={`#a-record-${domain.id}`}
                  role="tab"
                >
                  A Record
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id={`nameservers-${domain.id}`}
                role="tabpanel"
              >
                <div className="mb-3">
                  {is_a_record_config_valid ? "" : connection_steps}
                </div>
                <div className="container-fluid">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <span className="text-primary">
                        <Icon icon="check-circle-fill" />
                      </span>
                      <strong className="ms-2">Intended Nameservers:</strong>
                      <div className="card card-body bg-light border-0 my-3 me-md-2 me-0">
                        <ul className="text-primary list-unstyled">
                          <li>{NS1_RECORD}</li>
                          <li>{NS2_RECORD}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <span
                        className={
                          is_a_record_config_valid
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        <Icon
                          icon={
                            is_a_record_config_valid
                              ? "check-circle"
                              : "x-circle-fill"
                          }
                        />
                      </span>
                      <strong className="ms-2">Current Nameservers:</strong>
                      <div className="card card-body bg-light border-0 my-3 ms-md-2 ms-0">
                        <ul
                          className={
                            is_a_record_config_valid
                              ? "text-success list-unstyled"
                              : "text-danger list-unstyled"
                          }
                        >
                          {current_nameservers_component}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade show"
                id={`a-record-${domain.id}`}
                role="tabpanel"
              >
                <div className="mb-3">
                  Set the following record on your DNS provider to continue:
                </div>
                <div className="card card-body bg-light border-0 pt-3 pb-0 mb-3">
                  <table className="table table-sm table-borderless">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>A</td>
                        <td>@</td>
                        <td>{A_RECORD}</td>
                      </tr>

                      <tr>
                        <td>A</td>
                        <td>www</td>
                        <td>{A_RECORD}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {is_a_record_config_valid ? (
            ""
          ) : (
            <div className="card card-footer border-0 bg-white">
              <span>
                Depending on your provider, it might take some time for the
                changes to apply.{" "}
                <a className="text-primary" href={HELP_URL}>
                  Learn More
                </a>
              </span>
            </div>
          )}
        </div>
      );
    });
  }

  const onPurchaseDomain: any = (domain_name: string, is_paid: boolean) => {
    // ToDo: Connect domain to website
  };

  let unpublished_website_banner: object = null;
  if (!website.is_published) {
    unpublished_website_banner = (
      <div className="rounded text-center shadow-sm mb-3">
        <div className="alert alert-warning mb-0" role="alert">
          You have not published this website. Your friends and visitors will
          not be able to access it online.
        </div>
      </div>
    );
  }
  let no_domain_connected_banner: object = null;
  if (!domains.length > 0 && !loading_domain) {
    no_domain_connected_banner = (
      <p className="mb-4 text-muted">
        <div className="alert alert-warning">
          You have not connected a custom domain to this website yet.
        </div>
      </p>
    );
  }
  const upgrade_plan_banner: any = (
    <div className="alert alert-warning rounded d-flex align-items-center justify-content-between">
      The site plan does not allow multiple custom domains. Try Pro!
      <button
        className="shadow-none  ms-5 btn btn-primary"
        type="button"
        onClick={upgradeSubscription}
        aria-hidden="true"
      >
        Sure, lets do it
      </button>
    </div>
  );

  let custom_domain_content: any = (
    <div>
      {no_domain_connected_banner}
      <ul className="nav nav-tabs nav-fill mb-3">
        <li className="nav-item">
          <a
            className="nav-link active"
            data-bs-toggle="tab"
            href="#new"
            role="tab"
          >
            Purchase Your Own Domain
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-bs-toggle="tab"
            href="#existing"
            role="tab"
          >
            Connect An Existing Domain
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div className="tab-pane fade show active" id="new" role="tabpanel">
          <DomainSearch
            user={user}
            onPurchaseDomain={onPurchaseDomain}
            displayTitle={false}
          />
        </div>
        <div className="tab-pane fade show" id="existing" role="tabpanel">
          <Connect3rdPartyDomain
            website_id={website.id}
            after_connection_actions={after_connection_actions}
          />
        </div>
      </div>
    </div>
  );

  if (
    website.subscription_plan &&
    website.subscription_plan.pricing.amount <= 0.0 &&
    domains.length > 0
  ) {
    custom_domain_content = upgrade_plan_banner;
  }

  main_content = (
    <ul className="list-group list-group-flush">
      {unpublished_website_banner}

      <div className="list-group-item border-0 shadow-sm mb-4">
        <strong className="flex-grow-1">{APP_NAME} Domain</strong>
        <div className="my-3">
          <VisitDomainBanner domain={website.olitt_domain} />
        </div>
      </div>

      <div className="list-group-item border-0 shadow-sm mb-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <strong>Custom Domains</strong>
        </div>

        <div className="card card-body border-0 mb-3">
          {custom_domain_content}
        </div>

        <div className="mb-3">{domain_cards}</div>
      </div>
    </ul>
  );

  const close_modal_button: any = (
    <button
      className="btn btn-olitt-grey px-4"
      onClick={() => setWebsiteError(null)}
      type="button"
    >
      Close
    </button>
  );

  return (
    <SidebarWrapper sidebar={<WebsiteSidebar website={website} />}>
      {main_content}
      <ErrorModal
        show={web_error !== null}
        onClose={() => setWebsiteError(null)}
        positive_button={close_modal_button}
        short_desc={web_error ? web_error.short_desc : ""}
        suggestion={web_error ? web_error.suggestion : ""}
      />
    </SidebarWrapper>
  );
}

WebsiteDomains.propTypes = {
  billing_plans: PropTypes.instanceOf(Object),
  site_id: PropTypes.number.isRequired,
  paid_plan: PropTypes.instanceOf(Object).isRequired,
};

WebsiteDomains.defaultProps = {
  billing_plans: [],
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const billing_plan_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    BILLING_PLANS_DATA,
  );

  let billing_plans: null[] = [];
  let paid_plan: null[] = [];
  if (billing_plan_data.items.length > 0) {
    billing_plans = billing_plan_data.items.map(
      (parameters) => new BillingPlan(parameters),
    );
    paid_plan = billing_plans.find((billing_plan) => {
      return billing_plan.pricing.amount > 0.0;
    });
  }

  return { billing_plans, paid_plan };
}

export default connect(mapStateToProps)(withRouter(WebsiteDomains));
