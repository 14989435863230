import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useRowSelection } from "../../contexts";

function FacebookProductsSync(props) {
  const { onSyncToFacebook } = props;

  const [canSync, setCanSync] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { products } = useRowSelection();

  useEffect(() => {
    if (products.length > 0) {
      setCanSync(true);
    } else {
      setCanSync(false);
    }
  }, [products]);

  const handleClick = async () => {
    setIsLoading(true);
    await onSyncToFacebook(products);
    setIsLoading(false);
  };

  let buttonLabel = "Post Products To Facebook Catalog";
  if (isLoading) {
    buttonLabel = (
      <>
        <span
          className="spinner-border spinner-border-sm text-light"
          role="status"
          aria-hidden="true"
        />{" "}
        Posting...
      </>
    );
  }

  return (
    <div
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      title="Select products from the table below"
    >
      <button
        type="button"
        onClick={handleClick}
        className="btn btn-facebook"
        disabled={!canSync || isLoading}
      >
        <span className="text-white">{buttonLabel}</span>
      </button>
    </div>
  );
}

FacebookProductsSync.defaultProps = {
  onSyncToFacebook: () => {},
};

FacebookProductsSync.propTypes = {
  onSyncToFacebook: PropTypes.func,
};

export default FacebookProductsSync;
