import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import {
  Handle,
  Position,
  getOutgoers,
  useNodes,
  useReactFlow,
} from "reactflow";

const DEFAULT_HANDLE_STYLE = {
  width: 10,
  height: 10,
  bottom: -5,
};

const RootNode = memo(function ActionNode({ data, isConnectable }) {
  const { getNode, getNodes, getEdges } = useReactFlow();
  const nodes = useNodes();

  const pullIndicator = useCallback(() => {
    const outgoers = getOutgoers(getNode("lists"), getNodes(), getEdges());
    if (outgoers.length === 0) {
      return (
        <span className="fs-10">
          pull this <i className="bi bi-hand-index icon-rotate-180" /> dot down
        </span>
      );
    }
    return null;
  }, [nodes]);
  return (
    <div className="badge text-bg-info p-2">
      <div>
        <i className="bi bi-person-lines-fill" /> {data.label}
      </div>
      {pullIndicator()}
      <Handle
        type="source"
        id="blue"
        position={Position.Bottom}
        style={{ ...DEFAULT_HANDLE_STYLE, left: "50%", background: "blue" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
    </div>
  );
});

RootNode.defaultProps = {
  data: {},
  isConnectable: true,
};

RootNode.propTypes = {
  data: PropTypes.instanceOf(Object),
  isConnectable: PropTypes.bool,
};

export default RootNode;
