import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import AssetFormFields from "./AssetFormFields";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import {
  closeModalById,
  handleInputChange,
} from "modules/base/utilities/Actions";
import MarketingAPI from "modules/marketing/MarketingAPI";

function CreateAssets(props) {
  const { currentModalId, siteId } = props;
  const API = new MarketingAPI(siteId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const [state, setState] = useState({
    site: siteId,
    storageLocation: "remote",
  });
  const [success, setSuccess] = useState(null);
  const onInputChange = useCallback(
    (e) => {
      handleInputChange(e.target.name, e.target.value, state, setState);
    },
    [state, setState],
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    API.createAsset(
      state,
      () => {
        setState({
          site: siteId,
          storageLocation: "remote",
        });
        setIsSubmitting(false);
        closeModalById(currentModalId);
        setSuccess({ message: "File created successfully" });
      },
      (error_response) => {
        setError(error_response);
        setSuccess(null);
        setIsSubmitting(false);
      },
    );
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      id="create-asset-form"
      button_label="Create"
      fullLengthButton
      use_as="form"
      button_color="gray-900"
      padding="p-0"
      isSubmitting={isSubmitting}
    >
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      <AssetFormFields
        state={state}
        setState={setState}
        onInputChange={onInputChange}
      />
    </Form>
  );
}

CreateAssets.defaultProps = {
  currentModalId: null,
};

CreateAssets.propTypes = {
  currentModalId: PropTypes.string,
  siteId: PropTypes.string.isRequired,
};
export default CreateAssets;
