import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import SendEmail from "modules/marketing/components/contacts/SendEmail";

function SendEmailModal({ selectedContactsIds }) {
  return (
    <Modal modal_title="Send Email" modal_id="send-email-modal" size="md">
      <SendEmail
        selectedContactsIds={selectedContactsIds}
        currentModalId="send-email-modal"
      />
    </Modal>
  );
}

SendEmailModal.propTypes = {
  selectedContactsIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
};

export default SendEmailModal;
