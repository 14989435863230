import { isString } from "underscore";
import {
  btn_css,
  currencies,
  product_div_css,
  product_h3_css,
  product_h6_css,
  product_img_css,
  product_p_css,
} from "../constants";

export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const tm = editor.Panels.getButton("views", "open-tm");
  const {
    price_id,
    product_name = "Product Name",
    product_price = "00.00",
    product_description = "Description",
    product_image_url,
    price_currency = "USD",
    currency_position = "left",
    PUBLISHABLE_KEY,
    StripeProductBlockComponent,
  } = opts;

  domc.addType("STRIPE-PRODUCT-BLOCK-COMPONENT", {
    model: {
      defaults: {
        tagName: "div",
        name: "Stripe Product Block",
        draggable: "[data-gjs-type=wrapper]",
        droppable: false,
        stylable: false,
        copyable: false,
        resizable: true,
        price_id,
        product_name,
        product_price,
        product_description,
        product_image_url,
        price_currency,
        currency_position,
        PUBLISHABLE_KEY,
        traits: [
          {
            name: "price_id",
            label: "Product Price ID",
            placeholder: "Price Id",
            changeProp: 1,
          },
          {
            name: "product_name",
            label: "Product Name",
            placeholder: "Product name",
            changeProp: 1,
          },
          {
            name: "product_description",
            label: "Product Description",
            placeholder: "Product description",
            changeProp: 1,
          },
          {
            name: "product_image_url",
            label: "Product Image URL",
            placeholder: "Product image url.",
            changeProp: 1,
            attributes: { style: "display: none" },
          },
          {
            type: "button",
            text: "Select Image",
            label: "Product Image",
            full: true,
            command: (editor) =>
              editor.runCommand("open-assets", {
                target: this,
                types: ["image"],
                accept: "image/*",
                onSelect: (asset) => {
                  editor.Modal.close();
                  const url = isString(asset) ? asset : asset.get("src");
                  const component = editor.getSelected();
                  component.updateTrait("product_image_url", {
                    value: url,
                  });
                },
              }),
          },
          {
            name: "product_price",
            label: "Product Price",
            placeholder: "Product price",
            changeProp: 1,
          },
          {
            type: "select",
            name: "price_currency",
            label: "Currency",
            changeProp: 1,
            options: currencies,
          },
          {
            type: "select",
            name: "currency_position",
            label: "Currency Position",
            changeProp: 1,
            options: [
              { value: "left", name: "left" },
              { value: "right", name: "right" },
            ],
          },
          {
            name: "PUBLISHABLE_KEY",
            label: "Stripe Publishable Key",
            placeholder: "Stripe key",
            changeProp: 1,
          },
        ],
      },
      init() {
        this.setComponent();
        this.on(
          "change:product_price change:price_id change:product_name change:product_description" +
            " change:price_currency change:PUBLISHABLE_KEY change:currency_position change:product_image_url",
          () => valuesOnchange()
        );
        const valuesOnchange = () => {
          this.setComponent();
        };
        tm.set("active", 1);
      },
      setComponent() {
        const price_id = this.get("price_id");
        const product_image_url = this.get("product_image_url")
          ? this.get("product_image_url")
          : "https://source.unsplash.com/random/?food";
        const PUBLISHABLE_KEY = this.get("PUBLISHABLE_KEY");
        const uniq_id = this.getId();
        const StripeLink = `https://js.stripe.com/v3/`;
        const stripe_append_script = ` if (!document.getElementById('stripe_script')) {
                                const script = document.createElement('script');
                                  script.setAttribute('src', "${StripeLink}");
                                  script.setAttribute('id', "stripe_script");
                                  document.head.appendChild(script);                                               
                                   }`;
        const stripe_script = `<script type="text/javascript">${stripe_append_script}</script>`;

        const currency_position = this.get("currency_position");
        let right = "";
        let left = "";
        if (currency_position === "right") {
          right = this.get("price_currency");
        } else {
          left = this.get("price_currency");
        }
        const btn_script = `function StripePay${uniq_id}(el){let SUCCESS_URL=window.location.href+'?stripe-checkout-response=success';let ERROR_URL=window.location.href+'?stripe-checkout-response=error';let stripe=Stripe('${PUBLISHABLE_KEY}');let catchError=function(results){if(results.error){alert(results.error.message);}}; const quantity=1;const items=[{price:'${price_id}',quantity:quantity}];const mode='payment';stripe.redirectToCheckout({mode:mode,lineItems:items,successUrl:SUCCESS_URL,cancelUrl:ERROR_URL,}).then(catchError);}`;

        const stripe_order_script = `<script type="text/javascript">${btn_script}</script>`;
        const new_component = `${stripe_script}<img style="${product_img_css}" src="${product_image_url}" alt=""><h3 style="${product_h3_css}">${this.get(
          "product_name"
        )}</h3><p style="${product_p_css}">${this.get(
          "product_description"
        )}</p><h6 style="${product_h6_css}">${left} ${this.get(
          "product_price"
        )} ${right}</h6><div style="${btn_css}" onclick="StripePay${uniq_id}(this)">Checkout</div>
                ${stripe_order_script}`;
        this.set("content", new_component);
        this.setStyle(product_div_css);
        return new_component;
      },
      ...StripeProductBlockComponent,
    },
    view: {},
  });
};
