import { PrivateApi } from "../core/utilities/api/PrivateApi";
import { serverGeneratePath } from "modules/base/utilities/Navigation";
import {
  URL_CREATE_SITE_PROPERTY,
  URL_DELETE_FORM_ACTIONS,
  URL_DELETE_FORM_FIELDS,
  URL_GOOGLE_ANALYTICS_DATA,
  URL_GOOGLE_ANALYTICS_PROPERTIES,
  URL_MARKETING_ADD_DO_NOT_CONTACT,
  URL_MARKETING_ANALYTICS_AUTHENTICATION,
  URL_MARKETING_ASSET,
  URL_MARKETING_ASSETS,
  URL_MARKETING_BATCH_DELETE_CONTACTS,
  URL_MARKETING_CAMPAIGN,
  URL_MARKETING_CAMPAIGN_ADD_CONTACT,
  URL_MARKETING_CAMPAIGN_REMOVE_CONTACT,
  URL_MARKETING_CAMPAIGNS,
  URL_MARKETING_CANCEL_UPLOAD,
  URL_MARKETING_CHANGE_STAGE_CONTACTS,
  URL_MARKETING_CHANNELS,
  URL_MARKETING_CONTACT,
  URL_MARKETING_CONTACT_CAMPAIGNS,
  URL_MARKETING_CONTACT_NOTES,
  URL_MARKETING_CONTACT_SEGMENTS,
  URL_MARKETING_CONTACTS,
  URL_MARKETING_CONTACTS_STATISTICS,
  URL_MARKETING_CONTACTS_UPLOAD_PROGRESS,
  URL_MARKETING_CONTENT,
  URL_MARKETING_CONTENTS,
  URL_MARKETING_DASHBOARD_WIDGET_DATA,
  URL_MARKETING_EMAIL_STATISTICS,
  URL_MARKETING_EMAIL_TEMPLATE,
  URL_MARKETING_EMAIL_TEMPLATES,
  URL_MARKETING_FIELDS,
  URL_MARKETING_FOCUS_ITEM,
  URL_MARKETING_FOCUS_ITEMS,
  URL_MARKETING_FORM,
  URL_MARKETING_FORM_SUBMISSIONS,
  URL_MARKETING_FORMS,
  URL_MARKETING_FORMS_SUBMISSIONS_STATISTICS,
  URL_MARKETING_GENERATE_EMAIL_TEMPLATE,
  URL_MARKETING_IMPORT_FILE_CONTACTS,
  URL_MARKETING_IMPORT_GOOGLE_CONTACTS,
  URL_MARKETING_NOTE,
  URL_MARKETING_NOTES,
  URL_MARKETING_PREBUILT_EMAIL_TEMPLATES,
  URL_MARKETING_PREBUILT_FOCUS_ITEMS_TEMPLATES,
  URL_MARKETING_PROCESS_CONTACTS_FILE,
  URL_MARKETING_REMOVE_DO_NOT_CONTACT,
  URL_MARKETING_SEGMENT,
  URL_MARKETING_SEGMENT_CHANGE_CONTACTS,
  URL_MARKETING_SEGMENTS,
  URL_MARKETING_SEND_EMAIL_TO_CONTACT,
  URL_MARKETING_SEND_EMAIL_TO_SEGMENT,
  URL_MARKETING_STAGE,
  URL_MARKETING_STAGES,
  URL_MARKETING_TRANSPORT,
  URL_MARKETING_TRANSPORTS,
} from "modules/marketing/MarketingConstants";
import {
  AIEmailTemplate,
  Analytics,
  Asset,
  Campaign,
  Channel,
  Contact,
  ContactCampaign,
  ContactSegment,
  ContactStatistics,
  ContentItem,
  EmailStatistics,
  EmailTemplate,
  Field,
  FocusItem,
  Form,
  FormsSubmissionsStatistics,
  FormSubmission,
  GoogleProperty,
  Note,
  Segment,
  Stage,
  Transport,
  Widget,
} from "modules/marketing/MarketingModels";
import { Site } from "modules/website/WebsiteModels";

export default class MarketingAPI extends PrivateApi {
  constructor(siteId) {
    super();
    this.siteId = siteId;
  }

  /**
   * A description of the entire function.
   *
   * @param {string} url - description of parameter
   * @return {string} description of return value
   */
  appendSiteId(url) {
    if (!this.siteId) {
      return url;
    }
    if (url.includes("?")) {
      return `${url}&site=${this.siteId}`;
    }
    return `${url}?site=${this.siteId}`;
  }

  /**
   * Fetches a list of contacts based on the provided parameters.
   *
   * @returns {Object} - An object containing the fetched contacts, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - contacts: An array of Contact instances, each representing a fetched contact.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getContacts(params) {
    let url = this.appendSiteId(URL_MARKETING_CONTACTS);
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url, 2000);
    let contacts = data?.contacts ?? [];
    const total = data?.total ?? null;
    const contact = new Contact();
    contacts = contact.fromArray(contacts);
    return { contacts, total, error, isLoading };
  }

  /**
   * Fetches a contact by its ID.
   *
   * @param {number} id - The ID of the contact to fetch.
   *
   * @returns {Object} - An object containing the fetched contact, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - contact: A Contact instance representing the fetched contact.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getContact(id) {
    let url = serverGeneratePath(URL_MARKETING_CONTACT, { id });
    url = this.appendSiteId(url);
    const { data, error, isLoading } = this.getRequest(url);
    const contact = new Contact(data);
    return { contact, error, isLoading };
  }

  createContact(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_CONTACTS);
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [this.appendSiteId(URL_MARKETING_CONTACTS)],
    );
  }

  /**
   * Edits a contact by its ID and payload.
   *
   * @param {number} id - The ID of the contact to update.
   * @param {Object} payload - The payload containing the data to update the contact with.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The payload should contain the ID of the contact to be updated.
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  updateContact(id, payload, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_CONTACT, { id });
    url = this.appendSiteId(url);
    return this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "PUT",
      [this.appendSiteId(URL_MARKETING_CONTACTS)],
    );
  }

  fetchUploadProgress() {
    const url = this.appendSiteId(URL_MARKETING_CONTACTS_UPLOAD_PROGRESS);
    const { data, isLoading, error } = this.getRequest(url, 100);
    return { data, isLoading, error };
  }

  addDnc(id, payload, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_ADD_DO_NOT_CONTACT, { id });
    url = this.appendSiteId(url);
    return this.postRequest(
      url,
      payload,
      (response) => {
        onSuccess(response);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [this.appendSiteId(URL_MARKETING_CONTACTS)],
    );
  }

  removeDnc(id, payload, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_REMOVE_DO_NOT_CONTACT, { id });
    url = this.appendSiteId(url);
    return this.postRequest(
      url,
      payload,
      (response) => {
        onSuccess(response);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [this.appendSiteId(URL_MARKETING_CONTACTS)],
    );
  }

  /**
   * Deletes a contact by its ID.
   *
   * @param {number} id - The ID of the contact to delete.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  deleteContact(id, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_CONTACT, { id });
    url = this.appendSiteId(url);
    this.deleteRequest(
      url,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      [this.appendSiteId(URL_MARKETING_CONTACTS)],
    );
  }

  /**
   * Deletes a batch of contacts by their IDs.
   *
   * @param {number} contactIds - The IDs of the contacts to delete.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  deleteContacts(contactIds, onSuccess, onError) {
    let url = URL_MARKETING_BATCH_DELETE_CONTACTS;
    if (contactIds.length > 0) {
      url += `?ids=${contactIds}`;
      url = this.appendSiteId(url);

      this.deleteRequest(
        url,
        (data) => {
          onSuccess(data);
        },
        (error) => {
          onError(error);
        },
        [this.appendSiteId(URL_MARKETING_CONTACTS)],
      );
    }
  }

  /**
   * Fetches the contacts upload progress.
   * @returns {Object} - An object containing the fetched contacts upload progress, any error that occurred, and a loading state.
   * The returned object has the following structure:
   * - data: An object containing the contacts upload progress.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  contactsUploadProgress() {
    let url = this.appendSiteId(URL_MARKETING_CONTACTS_UPLOAD_PROGRESS);
    const { data, error, isLoading } = this.getRequest(url, 10);
    return { data, error, isLoading };
  }
  /**
   * cancels the contacts upload.
   * @param {function} onSuccess - The callback function to execute upon successful cancel of the upload.
   * @param {function} onError - The callback function to execute if there is an error while canceling the upload.
   * @returns {void}
   */

  cancelUpload(onSuccess, onError) {
    let url = this.appendSiteId(
      serverGeneratePath(URL_MARKETING_CANCEL_UPLOAD),
    );
    this.postRequest(
      url,
      {},
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
    );
  }

  /**
   * Fetches a list of forms based on the provided site ID.
   *
   * @returns {Object} - An object containing the fetched forms, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - forms: An array of Form instances, each representing a fetched form.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getForms(params) {
    let url = this.appendSiteId(URL_MARKETING_FORMS);
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url, 2000);
    let forms = data?.forms ?? [];
    const total = data?.total ?? null;
    const form = new Form();
    forms = form.fromArray(forms);
    return { forms, total, error, isLoading };
  }

  /**
   * Updates a form using its ID and the provided payload.
   *
   * @param {number} id - The ID of the form to update.
   * @param {Object} payload - The payload containing the data to update the form with.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   *
   * @returns {Promise} - A Promise that resolves to the response of the HTTP request.
   */
  updateForm(id, payload, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_FORM, { id });
    url = this.appendSiteId(url);
    return this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "PATCH",
      [this.appendSiteId(URL_MARKETING_FORMS), url],
    );
  }

  /**
   * Fetches a list of fields from the server.
   *
   * @returns {Object} - An object containing the fetched fields, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - fields: An array of `Field` instances, each representing a fetched field.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getFields(params) {
    let url = this.appendSiteId(URL_MARKETING_FIELDS);
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url);
    const field = new Field();
    const fields = field.fromArray(data?.fields ?? []);
    return { fields, error, isLoading };
  }

  /**
   * Deletes form fields by their ID.
   *
   * @param {number} id - The ID of the form to delete fields from.
   * @param {Array} fieldIds - An array of field IDs to delete.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  deleteFormFields(id, fieldIds, onSuccess, onError) {
    let url = serverGeneratePath(URL_DELETE_FORM_FIELDS, { id });
    url = this.appendSiteId(url);
    this.postRequest(
      url,
      { field_ids: fieldIds },
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [this.appendSiteId(url)],
    );
  }

  /**
   * Deletes form actions.
   *
   * @param {string} id - The ID of the form.
   * @param {Array<string>} actionIds - The IDs of the actions to be deleted.
   * @param {Function} onSuccess - The success callback function.
   * @param {Function} onError - The error callback function.
   * @returns {void}
   */
  deleteFormActions(id, actionIds, onSuccess, onError) {
    let url = serverGeneratePath(URL_DELETE_FORM_ACTIONS, { id });
    url = this.appendSiteId(url);
    this.postRequest(
      url,
      { action_ids: actionIds },
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [this.appendSiteId(url)],
    );
  }

  /**
   * Creates a new form with the provided payload.
   *
   * @param {Object} payload - The payload containing the data to create the form with.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The payload should contain the necessary data to create a new form.
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  createForm(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_FORMS);
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [this.appendSiteId(URL_MARKETING_FORMS), url],
    );
  }

  /**
   * Fetches a form by its ID.
   *
   * @param {number} id - The ID of the form to fetch.
   *
   * @returns {Object} - An object containing the fetched form, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - form: A Form instance representing the fetched form.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getForm(id) {
    let url = serverGeneratePath(URL_MARKETING_FORM, { id });
    url = this.appendSiteId(url);
    const { data, error, isLoading } = this.getRequest(url);
    const form = new Form(data);
    return { form, error, isLoading };
  }

  /**
   * Deletes a form by its ID.
   *
   * @param {number} id - The ID of the form to delete.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  deleteForm(id, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_FORM, { id });
    url = this.appendSiteId(url);
    this.deleteRequest(
      url,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      [this.appendSiteId(URL_MARKETING_FORMS), url],
    );
  }

  /**
   * Fetches a list of focus items from the server.
   *
   * @returns {Object} - An object containing the fetched focus items, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - focusItems: An array of `FocusItem` instances, each representing a fetched focus item.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getFocusItems(params) {
    let url = this.appendSiteId(URL_MARKETING_FOCUS_ITEMS);
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url, 2000);
    let focusItems = data?.focus ?? [];
    const total = data?.total ?? null;
    const focusItem = new FocusItem();
    focusItems = focusItem.fromArray(focusItems);
    return { focusItems, total, error, isLoading };
  }

  /**
   * Creates a new focus item.
   *
   * @param {Object} payload - The payload containing the data to create the focus item with.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The payload should contain the necessary data to create a new focus item.
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  createFocusItem(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_FOCUS_ITEMS);
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [this.appendSiteId(URL_MARKETING_FOCUS_ITEMS), url],
    );
  }

  /**
   * Updates a focus item using its ID and the provided payload.
   *
   * @param {number} id - The ID of the focus item to update.
   * @param {Object} payload - The payload containing the data to update the focus item with.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   *
   * @returns {Promise} - A Promise that resolves to the response of the HTTP request.
   */
  updateFocusItem(id, payload, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_FOCUS_ITEM, { id });
    url = this.appendSiteId(url);
    return this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "PATCH",
      [this.appendSiteId(URL_MARKETING_FOCUS_ITEMS), url],
    );
  }

  /**
   * Deletes a focus item by its ID.
   *
   * @param {number} id - The ID of the focus item to delete.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  deleteFocusItem(id, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_FOCUS_ITEM, { id });
    url = this.appendSiteId(url);
    this.deleteRequest(
      url,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      [this.appendSiteId(URL_MARKETING_FOCUS_ITEMS), url],
    );
  }

  /**
   * Fetches form submissions for a specific form.
   *
   * @param {number} formId - The ID of the form for which to fetch submissions.
   *
   * @returns {Object} - An object containing the fetched form submissions, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - formSubmissions: An array of `FormSubmission` instances, each representing a fetched form submission.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getFormSubmissions(formId, params) {
    let url = serverGeneratePath(URL_MARKETING_FORM_SUBMISSIONS, {
      id: formId,
    });
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `${url.includes("?") ? "&" : "?"}${paramsObj.toString()}`;
    }
    url = this.appendSiteId(url);
    const { data, error, isLoading } = this.getRequest(url, 2000);
    let formSubmissions = data?.submissions ?? [];
    const total = data?.total ?? null;
    const formSubmission = new FormSubmission();
    formSubmissions = formSubmission.fromArray(formSubmissions);
    return { formSubmissions, total, error, isLoading };
  }

  /**
   * Fetches form submissions statistics.
   *
   * @param {object} params - The dict url params.
   *
   * @returns {Object} - An object containing the fetched form submissions, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - formSubmissions: An array of `FormSubmission` instances, each representing a fetched form submission.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */

  getFormsSubmissionsStatistics(params) {
    let url = URL_MARKETING_FORMS_SUBMISSIONS_STATISTICS;
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url = `${url}?${paramsObj.toString()}`;
    }
    url = this.appendSiteId(url);
    const { data, error, isLoading } = this.getRequest(url);
    const formSubmissions = new FormsSubmissionsStatistics(data);
    return { formSubmissions, error, isLoading };
  }

  /**
   * Fetches a list of segments from the server.
   *
   * @returns {Object} - An object containing the fetched segments, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - segments: An array of `Segment` instances, each representing a fetched segment.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getSegments(params) {
    let url = this.appendSiteId(URL_MARKETING_SEGMENTS);
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url, 2000);
    let segments = data?.lists ?? [];
    const total = data?.total ?? null;
    const segment = new Segment();
    segments = segment.fromArray(segments);
    return { segments, total, error, isLoading };
  }

  /**
   * Creates a new segment.
   *
   * @param {Object} payload - The payload containing the data to create the segment with.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The payload should contain the necessary data to create a new segment.
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  createSegment(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_SEGMENTS);
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [this.appendSiteId(URL_MARKETING_SEGMENTS), url],
    );
  }

  /**
   * Updates a segment using its ID and the provided payload.
   *
   * @param {number} id - The ID of the segment to update.
   * @param {Object} payload - The payload containing the data to update the segment with.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  updateSegment(id, payload, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_SEGMENT, { id });
    url = this.appendSiteId(url);
    return this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "PATCH",
      [this.appendSiteId(URL_MARKETING_SEGMENTS), url],
    );
  }

  /**
   * Deletes a segment by its ID.
   *
   * @param {number} id - The ID of the segment to delete.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  deleteSegment(id, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_SEGMENT, { id });
    url = this.appendSiteId(url);
    this.deleteRequest(
      url,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      [this.appendSiteId(URL_MARKETING_SEGMENTS), url],
    );
  }

  /**
   * Changes the contacts of a segment.
   *
   * @param {number} segmentId - The ID of the segment to change contacts.
   * @param {Array} addContactIds - An array of contact IDs to add to the segment.
   * @param {Array} removeContactIds - An array of contact IDs to remove from the segment.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   */
  changeSegmentContacts(
    segmentId,
    addContactIds,
    removeContactIds,
    onSuccess,
    onError,
  ) {
    let url = serverGeneratePath(URL_MARKETING_SEGMENT_CHANGE_CONTACTS, {
      id: segmentId,
    });
    url = this.appendSiteId(url);
    const revalidateUrls = [...addContactIds, ...removeContactIds].map(
      (contactId) => {
        return this.appendSiteId(
          serverGeneratePath(URL_MARKETING_SEGMENT_CHANGE_CONTACTS, {
            id: contactId,
          }),
        );
      },
    );
    this.postRequest(
      url,
      { add_ids: addContactIds, remove_ids: removeContactIds },
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      revalidateUrls,
    );
  }

  /**
   * Fetches a list of segments for a contact.
   *
   * @param {number} contactId - The ID of the contact for which to fetch segments.
   *
   * @returns {Object} - An object containing the fetched segments, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - segments: An array of `ContactSegment` instances, each representing a fetched segment.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getContactSegments(contactId) {
    let url = serverGeneratePath(URL_MARKETING_CONTACT_SEGMENTS, {
      id: contactId,
    });
    url = this.appendSiteId(url);
    const { data, error, isLoading } = this.getRequest(url);
    const contactSegment = new ContactSegment();
    const segments = contactSegment.fromArray(data);
    return { segments, error, isLoading };
  }

  /**
   * Fetches a list of campaigns for a contact.
   *
   * @param {number} contactId - The ID of the contact for which to fetch campaigns.
   *
   * @returns {Object} - An object containing the fetched campaigns, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - campaigns: An array of `Campaign` instances, each representing a fetched campaign.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   *
   */
  getContactsCampaigns(contactId) {
    let url = serverGeneratePath(URL_MARKETING_CONTACT_CAMPAIGNS, {
      id: contactId,
    });
    url = this.appendSiteId(url);
    const { data, error, isLoading } = this.getRequest(url);
    const campaignContact = new ContactCampaign();
    const campaigns = campaignContact.fromArray(data);
    return { campaigns, error, isLoading };
  }

  /**
   * Fetches a list of contacts based on the provided segment aliases.
   *
   *
   * @returns {Object} - An object containing the fetched contacts, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - contacts: An array of Contact instances, each representing a fetched contact.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   * @param segmentAliases
   */
  getContactsBySegments(segmentAliases, params) {
    let url = this.appendSiteId(URL_MARKETING_CONTACTS);
    if (segmentAliases) {
      url += `&segment=${segmentAliases}`;
    }
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }

    const { data, error, isLoading } = this.getRequest(url, 2000);
    let contacts = data?.contacts ?? [];
    const total = data?.total ?? null;
    const contact = new Contact();
    contacts = contact.fromArray(contacts);
    return { contacts, total, error, isLoading };
  }

  /**
   * importGoogleContacts function to send a post request to import Google contacts.
   *
   * @param {object} payload - the data to be sent in the request
   * @param {function} onSuccess - callback function to handle successful response
   * @param {function} onError - callback function to handle error response
   */
  importGoogleContacts(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_IMPORT_GOOGLE_CONTACTS);
    this.postRequest(
      url,
      payload,
      (data) => {
        const contact = new Contact();
        const contacts = contact.fromArray(data);
        onSuccess(contacts);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [this.appendSiteId(URL_MARKETING_CONTACTS)],
    );
  }

  /**
   * Fetches contact statistics based on the provided params.
   *
   * @param {object} params - The dict url params.
   *
   * @returns {Object} - An object containing the fetched contacts statistics, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - statistics: Statistics object.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getContactsStatistics(params) {
    let url = this.appendSiteId(URL_MARKETING_CONTACTS_STATISTICS);
    const paramsObj = params
      ? new URLSearchParams(params)
      : new URLSearchParams();
    url += `&${paramsObj.toString()}`;
    const { data, error, isLoading } = this.getRequest(url);
    const statistics = new ContactStatistics(data);
    return { statistics, error, isLoading };
  }

  /**
   * Fetches a list of email templates from the server.
   *
   * @returns {Object} - An object containing the fetched email templates, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - emailTemplates: An array of `EmailTemplate` instances, each representing a fetched email template.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getEmailTemplates(params) {
    let url = this.appendSiteId(URL_MARKETING_EMAIL_TEMPLATES);
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url, 2000);
    let emailTemplates = data?.emails ?? [];
    const total = data?.total ?? null;
    const emailTemplate = new EmailTemplate();
    emailTemplates = emailTemplate.fromArray(emailTemplates);
    return { emailTemplates, total, error, isLoading };
  }

  /**
   * Creates a new email template.
   *
   * @param {Object} payload - The payload containing the data to create the email template with.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The payload should contain the necessary data to create a new email template.
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  createEmailTemplate(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_EMAIL_TEMPLATES);
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [url],
    );
  }

  /**
   * Updates an email template using its ID and the provided payload.
   *
   * @param {number} id - The ID of the email template to update.
   * @param {Object} payload - The payload containing the data to update the email template with.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   *
   * @returns {Promise} - A Promise that resolves to the response of the HTTP request.
   */
  updateEmailTemplate(id, payload, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_EMAIL_TEMPLATE, { id });
    url = this.appendSiteId(url);
    return this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "PATCH",
      [this.appendSiteId(URL_MARKETING_EMAIL_TEMPLATES), url],
    );
  }

  /**
   * Deletes an email template by its ID.
   *
   * @param {number} id - The ID of the email template to delete.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  deleteEmailTemplate(id, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_EMAIL_TEMPLATE, { id });
    url = this.appendSiteId(url);
    this.deleteRequest(
      url,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      [this.appendSiteId(URL_MARKETING_EMAIL_TEMPLATES), url],
    );
  }

  /**
   * Sends an email to a contact using a specified template.
   *
   * @param {string} contactId - The ID of the contact to send the email to.
   * @param {string} templateId - The ID of the template to use for the email.
   * @param {function} onSuccess - The callback function to execute upon successful sending of the email.
   * @param {function} onError - The callback function to execute if there is an error while sending the email.
   */
  sendEmail(contactId, templateId, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_SEND_EMAIL_TO_CONTACT, {
      id: templateId,
    });
    url = this.appendSiteId(url);
    const payload = {
      contact_id: contactId,
    };
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
    );
  }

  /**
   * Sends an email to a segment using a specified template.
   * @param {string} templateId - The ID of the template to use for the email.
   * @param {Array} selectedSegmentsIds - An array of segment IDs to send the email to.
   * @param {function} onSuccess - The callback function to execute upon successful sending of the email.
   * @param {function} onError - The callback function to execute if there is an error while sending the email.
   *
   *
   */

  sendEmailToSegments(templateId, selectedSegmentsIds, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_SEND_EMAIL_TO_SEGMENT, {
      id: templateId,
    });
    url = this.appendSiteId(url);
    const payload = {
      segment_ids: selectedSegmentsIds,
    };
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
    );
  }

  /**
   * Fetches email statistics
   * @param params - dict of query params
   * @returns {{isLoading: boolean, data: Response, error: any}}
   */
  getEmailStatistics(params) {
    const paramsObj = params
      ? new URLSearchParams(params)
      : new URLSearchParams();
    let url = `${URL_MARKETING_EMAIL_STATISTICS}?${paramsObj.toString()}`;
    url = this.appendSiteId(url);
    const { data, error, isLoading } = this.getRequest(url);
    const statistics = new EmailStatistics(data);
    return { statistics, error, isLoading };
  }

  /**
   * Fetches a list of campaigns from the server.
   * @returns {Object} - An object containing the fetched campaigns, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - campaigns: An array of `Campaign` instances, each representing a fetched campaign.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */

  getCampaigns(params) {
    let url = this.appendSiteId(URL_MARKETING_CAMPAIGNS);
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url, 2000);
    let campaigns = data?.campaigns ?? [];
    const total = data?.total ?? null;
    const campaign = new Campaign();
    campaigns = campaign.fromArray(campaigns);
    return { campaigns, total, error, isLoading };
  }

  /**
   * Creates a new campaign
   * @param {Object} payload - The payload containing the data to create the campaign with.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The payload should contain the necessary data to create a new campaign.
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */

  createCampaign(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_CAMPAIGNS);
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [url],
    );
  }

  /**
   * Updated a campaign using its ID and the provided payload.
   *
   * @param {number} id - The ID of the campaign to update.
   * @param {object} payload - The payload containing the data to update the campaign with.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   */

  updateCampaign(id, payload, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_CAMPAIGN, { id });
    url = this.appendSiteId(url);
    return this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "PUT",
      [this.appendSiteId(URL_MARKETING_CAMPAIGNS), url],
    );
  }

  /**
   * Deletes a campaign by its ID.
   *
   * @param {number} id - The ID of the campaign to delete.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error object as its argument.
   */
  deleteCampaign(id, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_CAMPAIGN, { id });
    url = this.appendSiteId(url);
    this.deleteRequest(
      url,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      [this.appendSiteId(URL_MARKETING_CAMPAIGNS)],
    );
  }

  /**
   * Adds a contact to a campaign.
   *
   * @param {*} campaignId - The ID of the campaign to add the contact to.
   * @param {*} contactIds - The ID of the contact(s) to add to the campaign.
   * @param {*} onSuccess - Callback function that is called when the request is successful.
   * @param {*} onError - Callback function that is called when the request fails.
   *
   *  The onSuccess callback receives the response data as its argument.
   *  The onError callback receives the error object as its argument.
   *
   */
  AddContactsToCampaign(campaignId, contactIds, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_CAMPAIGN_ADD_CONTACT, {
      id: campaignId,
    });
    url = this.appendSiteId(url);
    const payload = {
      contact_ids: contactIds,
    };
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
    );
  }

  /**
   * Removes a contact from a campaign.
   *
   * @param {*} campaignId - The ID of the campaign to remove the contact from.
   * @param {*} contactIds - The ID of the contact(s) to remove from the campaign.
   * @param {*} onSuccess - Callback function that is called when the request is successful.
   * @param {*} onError - Callback function that is called when the request fails.
   *
   *  The onSuccess callback receives the response data as its argument.
   *  The onError callback receives the error object as its argument.
   *
   */
  RemoveContactsFromCampaign(campaignId, contactIds, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_CAMPAIGN_REMOVE_CONTACT, {
      id: campaignId,
    });
    url = this.appendSiteId(url);
    const payload = {
      contact_ids: contactIds,
    };
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
    );
  }

  /**
   * Fetches a list of prebuilt email templates from the server.
   *
   * @returns {Object} - An object containing the fetched prebuilt email templates, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - prebuiltEmailTemplates: An array of `EmailTemplate` instances, each representing a fetched prebuilt email template.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getPrebuiltEmailTemplates(params) {
    let url = this.appendSiteId(URL_MARKETING_PREBUILT_EMAIL_TEMPLATES);
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url);
    let templates = data?.results ?? [];
    let total = data?.count || null;
    const emailTemplate = new Site();
    const prebuiltEmailTemplates = emailTemplate.fromArray(templates);
    return { prebuiltEmailTemplates, total, error, isLoading };
  }

  /**
   * Fetches a list of prebuilt focus items templates from the server.
   *
   * @returns {Object} - An object containing the fetched prebuilt focus items templates, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - prebuiltFocusItemsTemplates: An array of `Site` instances, each representing a fetched prebuilt email template.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getPrebuiltFocusItemsTemplates(params) {
    let url = this.appendSiteId(URL_MARKETING_PREBUILT_FOCUS_ITEMS_TEMPLATES);
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url);
    let templates = data?.results ?? [];
    const total = data?.count || null;
    const template = new Site();
    const prebuiltFocusItemsTemplates = template.fromArray(templates);
    return { prebuiltFocusItemsTemplates, total, error, isLoading };
  }

  /**
   * Sends contacts file to be processeed.
   *
   * @param {Object} payload - The payload containing the data to create the campaign with.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the processed contacts as its argument.
   * The onError callback receives the error object as its argument.
   */

  formatFileContacts(payload, onSuccess, onError, param = "preview=true") {
    let url = `${URL_MARKETING_PROCESS_CONTACTS_FILE}?${param}`;
    url = this.appendSiteId(url);
    this.postFormWithFileRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
    );
  }

  /**
   * Imports contacts from processed file data.
   *
   * @param {Object} payload - The payload containing the data to create the campaign with.
   * @param {function} onSuccess - Callback function that is called when the request is successful.
   * @param {function} onError - Callback function that is called when the request fails.
   *
   * The onSuccess callback receives the response data as its argument.
   * The onError callback receives the error response as its argument.
   *
   */

  importFileContacts(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_IMPORT_FILE_CONTACTS);
    this.postFormWithFileRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [this.appendSiteId(URL_MARKETING_CONTACTS)],
    );
  }

  /**
   * Generates an email template using the provided payload.
   *
   * @param {Object} payload - The payload for generating the email template.
   * @param {Function} onSuccess - The callback function to be called on success.
   * @param {Function} onError - The callback function to be called on error.
   * @returns {void}
   */
  generateEmailTemplate(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_GENERATE_EMAIL_TEMPLATE);
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(new AIEmailTemplate(data));
      },
      (error) => {
        onError(error);
      },
      "POST",
    );
  }

  /**
   * Retrieves the channels from the marketing API endpoint.
   *
   * @returns {Object} An object containing the channels, error, and isLoading status.
   */
  getChannels() {
    const url = this.appendSiteId(URL_MARKETING_CHANNELS);
    const { data, error, isLoading } = this.getRequest(url);
    const channel = new Channel();
    const channels = channel.fromArray(data);
    return { channels, error, isLoading };
  }

  /**
   * Retrieves the contents for a specific site.
   *
   * @returns {Object} - An object containing the contents, error, and isLoading status.
   */
  getContents(params) {
    let url = this.appendSiteId(URL_MARKETING_CONTENTS);
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url, 2000);
    let contents = data?.data ?? [];
    const total = data?.total ?? null;
    const content = new ContentItem();
    contents = content.fromArray(contents);
    return { contents, total, error, isLoading };
  }

  /**
   * Creates a new marketing content.
   *
   * @param {Object} payload - The payload containing the data for the new marketing content.
   * @param {Function} onSuccess - The callback function to be called on successful creation of the marketing content.
   * @param {Function} onError - The callback function to be called on error during creation of the marketing content.
   * @returns {void}
   */
  createContent(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_CONTENTS);
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [this.appendSiteId(URL_MARKETING_CONTENTS), url],
    );
  }

  /**
   * Updates the content with the specified ID.
   *
   * @param {string} id - The ID of the content to update.
   * @param {Object} payload - The payload containing the updated content data.
   * @param {function} onSuccess - The callback function to be called on successful update.
   * @param {function} onError - The callback function to be called on update error.
   * @returns {Promise} - A promise that resolves when the update is successful, and rejects when there is an error.
   */
  updateContent(id, payload, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_CONTENT, { id });
    url = this.appendSiteId(url);
    return this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "PATCH",
      [this.appendSiteId(URL_MARKETING_CONTENTS), url],
    );
  }

  /**
   * Deletes a content with the specified ID and site ID.
   *
   * @param {string} id - The ID of the content to delete.
   * @param {string} siteId - The ID of the site.
   * @param {function} onSuccess - The callback function to be called on success.
   * @param {function} onError - The callback function to be called on error.
   * @returns {void}
   */
  deleteContent(id, siteId, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_CONTENT, {
      id,
    });
    url = this.appendSiteId(url);
    this.deleteRequest(
      url,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      [this.appendSiteId(URL_MARKETING_CONTENTS), url],
    );
  }

  /**
   * Fetches a list of marketing stages based on the provided parameters.
   *
   * @returns {Object} - An object containing the fetched stages, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - stages: An array of Stage instances, each representing a fetched marketing stage.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getStages(params) {
    let url = this.appendSiteId(URL_MARKETING_STAGES);
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url, 2000);
    let stages = data?.stages ?? [];
    const total = data?.total ?? null;
    const stage = new Stage();
    stages = stage.fromArray(stages);
    return { stages, total, error, isLoading };
  }

  /**
   * Fetches a specific marketing stage based on the provided ID.
   *
   * @param {string} id - The ID of the marketing stage to fetch.
   * @returns {Object} - An object containing the fetched stage, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - stage: A Stage instance representing the fetched marketing stage.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getStage(id) {
    const url = this.appendSiteId(
      serverGeneratePath(URL_MARKETING_STAGE, { id }),
    );
    const { data, error, isLoading } = this.getRequest(url);
    const stage = new Stage(data);
    return { stage, error, isLoading };
  }

  /**
   * Deletes a marketing stage based on the provided ID.
   *
   * @param {string} id - The ID of the marketing stage to delete.
   * @param {function} onSuccess - A callback function to handle successful deletion.
   * @param {function} onError - A callback function to handle errors during deletion.
   * @returns {void}
   *
   * Upon successful deletion, the onSuccess callback is invoked.
   * If an error occurs during deletion, the onError callback is invoked.
   */
  deleteStage(id, onSuccess, onError) {
    const url = this.appendSiteId(
      serverGeneratePath(URL_MARKETING_STAGE, { id }),
    );
    this.deleteRequest(
      url,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      [this.appendSiteId(URL_MARKETING_STAGES), url],
    );
  }

  /**
   * Updates a stage with the specified ID using a PUT request.
   * @param {string} id - The ID of the stage to update.
   * @param {object} payload - The data payload containing the updates for the stage.
   * @param {Function} onSuccess - A callback function to be executed upon successful update. It receives the updated stage data as an argument.
   * @param {Function} onError - A callback function to be executed if the update fails. It receives the error object as an argument.
   * @returns {Promise} A promise representing the asynchronous PUT request. Resolves with the updated stage data if successful, rejects with an error if unsuccessful.
   */
  updateStage(id, payload, onSuccess, onError) {
    const url = this.appendSiteId(
      serverGeneratePath(URL_MARKETING_STAGE, { id }),
    );
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "PATCH",
      [this.appendSiteId(URL_MARKETING_STAGES), url],
    );
  }

  /**
   * Creates a new stage using a POST request.
   * @param {object} payload - The data payload containing the details of the new stage to be created.
   * @param {Function} onSuccess - A callback function to be executed upon successful creation of the stage. It receives the newly created stage data as an argument.
   * @param {Function} onError - A callback function to be executed if the creation of the stage fails. It receives the error object as an argument.
   * @returns {void} This method does not return a value directly. It triggers the POST request to create the stage and executes the success or error callbacks accordingly.
   */
  createStage(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_STAGES);
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [url],
    );
  }

  changeStageContact(stageId, addContactIds, onSuccess, onError) {
    const url = this.appendSiteId(
      serverGeneratePath(URL_MARKETING_CHANGE_STAGE_CONTACTS, {
        id: stageId,
      }),
    );
    this.postRequest(
      url,
      { add_ids: addContactIds },
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [this.appendSiteId(URL_MARKETING_CONTACTS), url],
    );
  }

  /**
   * Fetches contact notes for a given contact ID.
   *
   * @param {number} contactId - The ID of the contact.
   * @returns {Object} - An object containing the fetched contact notes, any error that occurred, and a loading state.
   */
  getNotes(contactId) {
    const url = this.appendSiteId(
      serverGeneratePath(URL_MARKETING_NOTES, { contactId }),
    );
    const { data, error, isLoading } = this.getRequest(url);
    if (error) {
      return { notes: [], error, isLoading };
    }
    const notes =
      data && Array.isArray(data)
        ? data.map((noteData) => new Note(noteData))
        : [];
    return { notes, error, isLoading };
  }

  /**
   * Fetches a contact note by its ID.
   *
   * @param {number} id - The ID of the contact note to fetch.
   *
   * @returns {Object} - An object containing the fetched contact note, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - contact note: A contact note instance representing the fetched contact note.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getNote(id) {
    const url = serverGeneratePath(URL_MARKETING_NOTE, { id });
    const { data, error, isLoading } = this.getRequest(url);
    const contactNote = data ? new Note(data) : null;
    return { contactNote, error, isLoading };
  }

  /**
   *
   * @param {*} payload - The data to be sent in the request body.
   * @param {*} onSuccess - his is a callback function that gets executed if the HTTP request is successful
   * @param {*} onError - This is a callback function that gets executed if the HTTP request encounters an error.
   */
  createNote(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_CONTACT_NOTES);
    return this.postRequest(
      url,
      payload,
      (data) => onSuccess?.(data),
      (error) => {
        onError(error);
      },
      "POST",
      [url],
    );
  }

  /**
   * Edits a contact note by its ID and payload.
   *
   * @param {number} id - The ID of the contact note to update.
   * @param {Object} payload - This parameter represents the data that you want to send as the payload of the HTTP request.
   * @param {function} onSuccess - This is a callback function that gets executed if the HTTP request is successful
   * @param {function} onError - This is a callback function that gets executed if the HTTP request encounters an error.
   *
   */
  updateNote(id, payload, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_NOTE, { id });
    url = this.appendSiteId(url);
    return this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "PATCH",
      [URL_MARKETING_NOTES, url],
    );
  }

  /**
   * Deletes a contact note by its ID.
   * @param {number} id - The ID of the contact note to delete.
   * @param {function} onSuccess - This is a callback function that gets executed if the HTTP request is successful
   * @param {function} onError - This is a callback function that gets executed if the HTTP request encounters an error.
   * @returns {void}
   * Upon successful deletion, the onSuccess callback is invoked.
   * If an error occurs during deletion, the onError callback is invoked.
   */
  deleteNote(id, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_NOTE, { id });
    url = this.appendSiteId(url);
    this.deleteRequest(
      url,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      [this.appendSiteId(URL_MARKETING_NOTES)],
    );
  }

  /**
   * Fetches a list of assets from the server.
   * @returns {Object} - An object containing the fetched assets, any error that occurred, and a loading state.
   * The returned object has the following structure:
   * - assets: An array of `Asset` instances, each representing a fetched asset.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  createAsset(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_ASSETS);
    return this.postFormWithFileRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [url],
    );
  }

  /**
   * Edits an asset by its ID and payload.
   * @param {number} id - The ID of the asset to update.
   * @param {Object} payload - This parameter represents the data that you want to send as the payload of the HTTP request.
   * @param {function} onSuccess - This is a callback function that gets executed if the HTTP request is successful
   * @param {function} onError - This is a callback function that gets executed if the HTTP request encounters an error.
   * @returns {Promise} - A promise that resolves to the response of the HTTP request.
   */

  updateAsset(id, payload, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_ASSET, { id });
    url = this.appendSiteId(url);
    return this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "PATCH",
      [this.appendSiteId(URL_MARKETING_ASSETS), url],
    );
  }

  /**
   * Deletes an asset by its ID.
   * @param {number} id - The ID of the asset to delete.
   * @param {function} onSuccess - This is a callback function that gets executed if the HTTP request is successful
   * @param {function} onError - This is a callback function that gets executed if the HTTP request encounters an error.
   * @returns {void}
   * Upon successful deletion, the onSuccess callback is invoked.
   * If an error occurs during deletion, the onError callback is invoked.
   *
   */
  deleteAsset(id, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_ASSET, { id });
    url = this.appendSiteId(url);
    this.deleteRequest(
      url,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      [this.appendSiteId(URL_MARKETING_ASSETS)],
    );
  }

  /**
   * Fetches a list of assets from the server.
   *
   * @returns {Object} - An object containing the fetched assets, any error that occurred, and a loading state.
   *
   * The returned object has the following structure:
   * - assets: An array of `Asset` instances, each representing a fetched asset.
   * - error: An error object if an error occurred during the request, or null if no error occurred.
   * - isLoading: A boolean indicating whether the request is still in progress.
   */
  getAssets(params) {
    let url = this.appendSiteId(URL_MARKETING_ASSETS);
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url, 2000);
    let assets = data?.assets ?? [];
    const total = data?.total ?? null;
    const asset = new Asset();
    assets = asset.fromArray(assets);
    return { assets, total, error, isLoading };
  }

  getDashboardWidget(widgetType, params) {
    let url = serverGeneratePath(URL_MARKETING_DASHBOARD_WIDGET_DATA, {
      widgetType,
    });
    const paramsObj = new URLSearchParams(params);
    url += `?${paramsObj.toString()}`;
    url = this.appendSiteId(url);
    const { data, error, isLoading } = this.getRequest(url);
    const widgetData = new Widget(data);
    return { widgetData, error, isLoading };
  }

  /**
   * Sends analytics authentication details to the server.
   *
   * This method posts the provided authentication details (e.g., code, scope, authuser, prompt)
   * to the API endpoint responsible for handling Google Analytics authentication.
   * It then invokes the appropriate callback functions based on the success or failure of the request.
   *
   * @param {Object} payload - The payload containing authentication details to be sent to the API.
   *   Expected properties include:
   *     - {string} code - The authorization code obtained from Google.
   *     - {string} scope - The scopes granted by the user.
   *     - {string} authuser - The index of the authenticated user.
   *     - {string} prompt - The prompt type used during authentication.
   * @param {Function} onSuccess - Callback function to be called if the request succeeds. Receives the response data as its argument.
   * @param {Function} onError - Callback function to be called if the request fails. Receives the error object as its argument.
   *
   * @returns {void}
   */
  sendAnalyticsAuthDetails(payload, onSuccess, onError) {
    const url = this.appendSiteId(
      serverGeneratePath(URL_MARKETING_ANALYTICS_AUTHENTICATION),
    );
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
    );
  }

  /**
   * Fetches Google Analytics properties for the current site.
   * Constructs the URL using the site ID and sends a GET request to retrieve the properties.
   *
   * @returns {Object} An object containing:
   * - {Object} data - The data returned from the request, which includes Google Analytics properties.
   * - {Object} error - Any error encountered during the request.
   * - {boolean} isLoading - A boolean indicating whether the request is still in progress.
   */
  getGoogleAnalyticsProperties(
    callback: (
      is_successful: boolean,
      properties_or_error: GoogleProperty[] | string,
    ) => void,
  ) {
    const url = this.appendSiteId(
      serverGeneratePath(URL_GOOGLE_ANALYTICS_PROPERTIES),
    );
    this.getAPIRequest(
      url,
      (response) => {
        const properties = response.map(
          (property) => new GoogleProperty(property),
        );
        callback(true, properties);
      },
      (response) => {
        callback(false, response);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * Creates a new site analytics property.
   * Constructs the URL using the site ID and sends a POST request with the provided payload.
   * Calls the provided callback functions based on the outcome of the request.
   *
   * @param {Object} payload - The data to be sent in the request body for creating the analytics property.
   * @param {Function} onSuccess - Callback function to be called if the request succeeds. Receives the response data as its argument.
   * @param {Function} onError - Callback function to be called if the request fails. Receives the error object as its argument.
   */
  createSiteAnalyticsProperty(payload, onSuccess, onError) {
    const url = this.appendSiteId(serverGeneratePath(URL_CREATE_SITE_PROPERTY));
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
    );
  }

  /**
   * Fetches Google Analytics data for the current site.
   * Constructs the URL using the site ID and sends a GET request to retrieve the data.
   *
   * @returns {Object} An object containing:
   * - {Analytics} analyticsData - The Analytics object returned from the request, which includes Google Analytics data.
   * - {Object} error - Any error encountered during the request.
   * - {boolean} isLoading - A boolean indicating whether the request is still in progress.
   */
  getGoogleAnalyticsData(params) {
    let url = this.appendSiteId(serverGeneratePath(URL_GOOGLE_ANALYTICS_DATA));
    const paramsObj = new URLSearchParams(params);
    url = `${url}&${paramsObj.toString()}`;
    const { data, error, isLoading } = this.getRequest(url);
    const analyticsData = new Analytics(data);
    return { analyticsData, error, isLoading };
  }

  /**
   * Fetches transport data based on the provided parameters.
   *
   * @param {Object} params - The parameters for fetching transport data.
   * @returns {Object} - An object containing the fetched transport data, any error that occurred, and a loading state.
   */
  getTransports(params) {
    let url = this.appendSiteId(URL_MARKETING_TRANSPORTS);
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `&${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url, 2000);
    const transportsData = data?.multipleTransports ?? [];
    const total = data?.total ?? null;
    const content = new Transport();
    const transports = content.fromArray(transportsData);
    return { transports, total, error, isLoading };
  }

  updateTransport(id, payload, onSuccess, onError) {
    let url = serverGeneratePath(URL_MARKETING_TRANSPORT, { id });
    url = this.appendSiteId(url);
    return this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "PATCH",
      [this.appendSiteId(URL_MARKETING_TRANSPORTS), url],
    );
  }

  createTransport(payload, onSuccess, onError) {
    const url = this.appendSiteId(URL_MARKETING_TRANSPORTS);
    this.postRequest(
      url,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      },
      "POST",
      [url],
    );
  }
}
