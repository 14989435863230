import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import BaseChartAnalytics from "modules/marketing/components/dashboard/googleanalytics/BaseChartAnalytics";
import {
  combineParams,
  prepareChartData,
} from "modules/marketing/components/dashboard/googleanalytics/utils";
import MarketingAPI from "modules/marketing/MarketingAPI";

const SourceOfTraffic = ({ match, filterParams, extraParams }) => {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);

  const allParams = combineParams(filterParams, extraParams);

  const { analyticsData, error, isLoading } =
    API.getGoogleAnalyticsData(allParams);

  const chartData = prepareChartData(analyticsData);

  return (
    <BaseChartAnalytics
      description="Source Of Traffic"
      data={chartData}
      type="pie"
      isLoading={isLoading}
      error={error}
    />
  );
};

SourceOfTraffic.defaultProps = {
  filterParams: {},
  extraParams: {},
};

SourceOfTraffic.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  filterParams: PropTypes.instanceOf(Object),
  extraParams: PropTypes.instanceOf(Object),
};

export default withRouter(SourceOfTraffic);
