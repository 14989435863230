import { InputType } from "../../../core/constants/Enumerators";
import { FormInput } from "../../../core/models/FormInput";
import { Shop } from "../../models/Shop";

/**
 * @name SEODetailsInputs
 * @param shop
 * @returns {[FormInput,FormInput,FormInput]}
 * @constructor
 */
export default function SEODetailsInputs(shop: Shop) {
  return [
    new FormInput(
      InputType.text,
      "seo_title",
      "SEO Title",
      "SEO Title",
      shop.seo_title,
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "meta_description",
      "Meta Description",
      "Meta Description",
      shop.meta_description,
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "meta_keywords",
      "Meta Keywords",
      "Meta Keywords",
      shop.meta_keywords,
      false,
      true,
    ),
  ];
}
