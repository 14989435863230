import { tawak_to_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const { tawToLabel, tawToCategory, tawToBlock } = opts;

  tawToBlock &&
    bm.add("TAWK-TO-BLOCK", {
      label: tawToLabel,
      activate: true,
      select: true,
      category: tawToCategory,
      content: { type: "TAWK-TO-COMPONENT" },
      media: tawak_to_logo,
    });
};
