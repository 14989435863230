import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

export const FormPhoneInput = (props) => {
  const { id, label, name, value, onChange, inputErrorText, helperText } =
    props;

  const [phoneNumber, setPhoneNumber] = React.useState(value);
  const [isValid, setIsValid] = React.useState(true);

  const containerClasses = classNames({
    "is-valid": isValid,
    "is-invalid": !isValid,
  });
  const inputClasses = classNames("form-control", {
    "is-valid": isValid,
    "is-invalid": !isValid,
  });

  const onChangePhoneNumber = (
    _isValid,
    newNumber,
    countryData,
    fullNumber,
    extension,
  ) => {
    setIsValid(_isValid);
    setPhoneNumber(newNumber);
    onChange({
      isValid: _isValid,
      newNumber,
      countryData,
      fullNumber,
      extension,
    });
  };

  const helperTextElement = helperText ? (
    <div className="form-text">{helperText}</div>
  ) : null;

  return (
    <div className="mb-2">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <div className={containerClasses}>
        <IntlTelInput
          inputClassName={inputClasses}
          fieldId={id}
          fieldName={name}
          value={phoneNumber}
          onPhoneNumberChange={onChangePhoneNumber}
        />
      </div>
      <div className="invalid-feedback">{inputErrorText}</div>
      {helperTextElement}
    </div>
  );
};

FormPhoneInput.defaultProps = {
  id: "phone-number-input",
  label: "",
  name: "",
  inputErrorText: "Invalid Phone Number!",
  helperText: "",
};

FormPhoneInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  inputErrorText: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default FormPhoneInput;
