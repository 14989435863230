import PropTypes from "prop-types";
import { Icon } from "modules/base/components/Icons";
import { Site } from "modules/website/WebsiteModels";

function WebsiteDomain(props) {
  const { website, view_mode } = props;
  const visitWebsite: any = (_website: Site) => {
    const url: any = _website.custom_domain || _website.olitt_domain;
    window.open(`https://${url}`, "_blank");
  };
  const site_domain_display = view_mode === "sites" ? "d-block" : "d-none";
  if (website.is_published) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href="#"
        className={`${site_domain_display} p-0 shadow-none text-decoration-none`}
        onClick={() => visitWebsite(website)}
        type="button"
      >
        <span className="fs-6">
          {website.custom_domain ?? website.olitt_domain}
        </span>
        <small>
          <Icon icon="box-arrow-up-right" additionalClasses="ms-1" />
        </small>
      </a>
    );
  }
  return (
    <span className={`${site_domain_display} text-start text-muted fs-6`}>
      {website.custom_domain ?? website.olitt_domain}
    </span>
  );
}

WebsiteDomain.propTypes = {
  website: PropTypes.instanceOf(Site).isRequired,
  view_mode: PropTypes.string.isRequired,
};

export default WebsiteDomain;
