/**
 * @class FacebookCatalog
 * @description Model for Facebook Catalog
 */
export class FacebookCatalog {
  id: number;

  name: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.name = parameters.name;
  }
}
