import { Integration, Site, SiteJavascript } from "./WebsiteModels";
import SiteJavascriptApi from "modules/website/api/SiteJavascriptApi";

export class SiteJavascriptActions {
  api: SiteJavascriptApi;

  constructor(site: Site) {
    this.api = new SiteJavascriptApi(site);
  }

  createScript(parameters: any, integration: Integration, callback: function) {
    this.api.createScript(parameters, integration, callback);
  }

  updateScriptParameters(
    script: SiteJavascript,
    parameters: any,
    callback: function,
  ) {
    script.parameters = parameters;
    this.api.updateScript(script, callback);
  }
}

export class GalleryActions {
  static getUnsplashImagesObjects(images) {
    return images.map((image) => {
      let name: any = image.description;
      if (Object.keys(image.user).length > 0) {
        name = `Photo By <a href="${image.user.links.html}" target="_blank">${image.user.name}</a>`;
      }
      return {
        id: image.id,
        name,
        caption: image.description,
        src: image.url,
        category: "unsplash",
      };
    });
  }
}
