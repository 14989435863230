import { API_BASE_URL } from "../core/constants/Endpoints";
import { PATH_MAIN_ROUTE } from "../core/constants/Routes";

/**
 * API Endpoints
 */

const URL_ACCOUNT_USER: string = `${API_BASE_URL}/account/`;
export const URL_REFRESH_TOKEN: string = `${URL_ACCOUNT_USER}authentication/token/refresh/`;
export const URL_USER_PROFILES: string = `${URL_ACCOUNT_USER}users/profile/`;
export const URL_USER_PROFILE: string = `${URL_USER_PROFILES}i/`;
export const URL_USER_LOGOUT: string = `${URL_USER_PROFILES}logout/`;
export const URL_SPREE_LOGIN_PATH: string = "auto_login";
export const URL_USER_FACEBOOK_BUSINESSES: string = `${URL_ACCOUNT_USER}users/facebook/facebook-businesses/`;
export const URL_USER_PLASMIC_SSO: string = `${URL_USER_PROFILES}plasmic-sso/`;

const URL_AUTHENTICATION: string = `${URL_ACCOUNT_USER}authentication/`;
const URL_SOCIAL_AUTHENTICATION: string = `${URL_AUTHENTICATION}social/`;
const URL_EMAIL_AUTHENTICATION: string = `${URL_AUTHENTICATION}email/`;
export const URL_EMAIL_REGISTRATION: any = URL_EMAIL_AUTHENTICATION;
export const URL_EMAIL_LOGIN: string = `${URL_EMAIL_AUTHENTICATION}login/`;
export const URL_EMAIL_RESEND_OTP: string = `${URL_EMAIL_AUTHENTICATION}resend-otp/`;
export const URL_EMAIL_VERIFY_OTP: string = `${URL_EMAIL_AUTHENTICATION}verify-otp/`;
export const URL_EMAIL_FORGOT_PASSWORD: string = `${URL_EMAIL_AUTHENTICATION}forgot-password/`;
export const URL_EMAIL_RESET_PASSWORD: string = `${URL_EMAIL_AUTHENTICATION}reset-password/`;
export const URL_GOOGLE_AUTHENTICATION: string = `${URL_SOCIAL_AUTHENTICATION}google/`;
export const URL_APPLE_AUTHENTICATION: string = `${URL_SOCIAL_AUTHENTICATION}apple/`;
export const URL_FACEBOOK_AUTHENTICATION: string = `${URL_SOCIAL_AUTHENTICATION}facebook/`;

/**
 * Routes
 */
export const PATH_ACCOUNT: string = `${PATH_MAIN_ROUTE}/account`;
export const PATH_LOGIN: string = `${PATH_ACCOUNT}/authentication`;
export const PATH_RESET_PASSWORD: string = `${PATH_ACCOUNT}/reset-password/:hash`;
export const PATH_PROFILE: string = `${PATH_ACCOUNT}/profile`;

/**
 * Constants
 */
export const ENABLE_GOOGLE_LOGIN: boolean = true;
export const USER_DATA: string = "USER_DATA";
export const GOOGLE_CLIENT_ID: string =
  "38226411448-k8ubjcmfpbmbucclm2949ovavj5e9ifd.apps.googleusercontent.com";
export const GENERIC_SOCIAL_LOGIN_ERROR: {
  short_desc: string,
  suggestion: string,
} = {
  short_desc: "Something went wrong while logging you in.",
  suggestion:
    "Kindly try again after some time or contact us if the issue persists.",
};
export const GOOGLE_LOGIN_API_INITIALIZATION_ERROR: {
  short_desc: string,
  suggestion: string,
} = {
  short_desc: "Google login is blocked by your browser.",
  suggestion:
    "Kindly ensure that you have allowed third-party cookies in your browser",
};
export const GOOGLE_LOGIN_ACCESS_DENIED_ERROR: {
  short_desc: string,
  suggestion: string,
} = {
  short_desc: "You have not granted the requested permissions.",
  suggestion:
    "Kindly grant the permissions requested from your google account to proceed.",
};
export const GOOGLE_LOGIN_POPUP_CLOSED_ERROR: {
  short_desc: string,
  suggestion: string,
} = {
  short_desc: "You closed the google login popup window prematurely",
  suggestion:
    "Kindly log in with your google account before closing the popup window.",
};
export const SOCIAL_LOGIN_HELP_URL: string = "https://help.olitt.com";
