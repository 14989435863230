import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import { UI_ROUTES } from "../CloudoonConstants";
import CloudoonEmailApi from "../CloudoonEmailApi";
import SetupLayout from "../layouts/SetupLayout";

const API = new CloudoonEmailApi();

function AddDomain(props) {
  const { history } = props;

  const [domainName, setDomainName] = React.useState("");
  const [is_loading, setIsLoading] = React.useState(false);

  const onCreateDomain = () => {
    setIsLoading(true);
    API.createDomain(
      domainName,
      ({ name }) => {
        setIsLoading(false);
        toast.success("Domain created successful");
        history.push(
          UI_ROUTES.SETUP.VERIFICATION.replace(":domain_name", name),
        );
      },
      (error) => {
        setIsLoading(false);
        toast.error(error.detail ?? "Error creating domain");
      },
    );
  };

  const onChangeDomainName = (e) => {
    setDomainName(e.target.value);
  };

  const verificationButtonLoader = is_loading ? (
    <span
      className="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    />
  ) : null;

  const addDomainButton = (
    <button
      type="button"
      className="btn btn-primary d-flex align-items-center gap-1"
      onClick={onCreateDomain}
      disabled={is_loading}
    >
      {verificationButtonLoader}
      <span className="visually-hidden">Loading...</span>
      Add Domain
    </button>
  );

  return (
    <SetupLayout>
      <div className="d-flex flex-column align-items-stretch gap-5">
        <div className="d-flex flex-column align-items-center gap-2">
          <h1 className="text-center fw-bold text-capitalize">
            Create unique email addresses for your business
          </h1>
          <h4 className="text-muted text-center">
            Please provide an existing domain
          </h4>
        </div>
        <div className="d-flex flex-column align-items-stretch gap-3">
          <input
            type="text"
            className="form-control form-control-lg"
            placeholder="yourdomain.com"
            value={domainName}
            onChange={onChangeDomainName}
          />
          {addDomainButton}
        </div>
      </div>
    </SetupLayout>
  );
}

AddDomain.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default AddDomain;
