import moment from "moment";
import React from "react";
import { Icon } from "../../../base/components/Icons";

/**
 * @name booleanFormatter
 * @description Formats a boolean value.
 * @returns {JSX.Element}
 * @param tableInstance
 */
export const booleanFormatter: any = (tableInstance: any) => {
  const { cell } = tableInstance;
  const { value } = cell;
  if (value === true || value === "true") {
    return (
      <span className="text-success">
        <Icon icon="check-circle-fill" />
      </span>
    );
  }
  return (
    <span className="text-danger">
      <Icon icon="x-circle-fill" />
    </span>
  );
};

/**
 * imageFormatter
 * @description Formats an image value.
 * @param tableInstance
 * @returns {JSX.Element}
 */
export const imageFormatter: any = (tableInstance: any) => {
  const { cell } = tableInstance;
  const { value } = cell;
  if (value) {
    return <img src={value} alt={value} />;
  }
  return <span>No Image</span>;
};

/**
 * @name headerExpandRowsFormatter
 * @param tableInstance
 * @returns {JSX.Element}
 */
export const headerExpandRowsFormatter: any = (tableInstance: any) => {
  const { getToggleAllRowsExpandedProps, isAllRowsExpanded } = tableInstance;
  let expandIcon: any = <Icon icon="plus-square" />;
  if (isAllRowsExpanded) {
    expandIcon = <Icon icon="dash-square" />;
  }
  return <span {...getToggleAllRowsExpandedProps()}>{expandIcon}</span>;
};

/**
 * @name cellExpandRowsFormatter
 * @param tableInstance
 * @returns {JSX.Element|null}
 */
export const cellExpandRowsFormatter: any = (tableInstance: any) => {
  const { row } = tableInstance;
  // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
  // to build the toggle for expanding a row
  let expandIcon: any = <Icon icon="plus-square" />;
  if (row.canExpand) {
    if (row.isExpanded) {
      expandIcon = <Icon icon="dash-square" />;
    }
    return (
      <span
        {...row.getToggleRowExpandedProps({
          style: {
            // We can even use the row.depth property
            // and paddingLeft to indicate the depth
            // of the row
            paddingLeft: `${row.depth * 2}rem`,
          },
        })}
      >
        {expandIcon}
      </span>
    );
  }
  return null;
};

/**
 * @name convertDate
 * @description Gets Timestamp of the JSON Date and retuns a dd/mm/yyyy tt:mm format.
 * @param dateTime
 * @returns {day month year time}
 * @constructor
 */
export const convertDate = (dateTime) => {
  return moment(dateTime).format("dddd, MMMM Do YYYY, h:mm");
};
