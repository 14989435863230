import PropTypes from "prop-types";
import React from "react";
import { Icon } from "../../../base/components/Icons";

export default function Stepper(props) {
  const { progressData, onBack, onNext } = props;

  let backButton;
  let nextButton;

  if (onBack) {
    backButton = (
      <button
        type="button"
        className="btn"
        onClick={() => onBack(progressData)}
      >
        <Icon icon="chevron-left" /> BACK
      </button>
    );
  }

  if (onNext) {
    nextButton = (
      <button
        type="button"
        className="btn"
        onClick={() => onNext(progressData)}
      >
        NEXT <Icon icon="chevron-right" />
      </button>
    );
  }

  return (
    <div className="pb-4 d-flex flex-column gap-2">
      <div className="p-3 bg-gray-200 d-flex justify-content-between">
        <span>
          step {progressData.step} of {progressData.totalSteps ?? 3}
        </span>
        <span>{progressData.progress}%</span>
      </div>
      <div className="p-3 fw-bold">{progressData.instructions}</div>
      <div className="p-3 d-flex align-items-center bg-gray-200">
        {backButton}
        <div className="progress flex-grow-1" style={{ height: 4 }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${progressData.progress}%` }}
            aria-valuenow={progressData.progress}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        {nextButton}
      </div>
    </div>
  );
}

Stepper.defaultProps = {
  onBack: null,
  onNext: null,
};

Stepper.propTypes = {
  progressData: PropTypes.shape({
    step: PropTypes.number.isRequired,
    totalSteps: PropTypes.number,
    instructions: PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
  }).isRequired,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};
