import parse from "html-react-parser";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Icon } from "../../base/components/Icons";
import Form from "../../core/components/Form";
import { OlittModal } from "../../core/components/OlittModal";
import { InputType } from "../../core/constants/Enumerators";
import { FormInput } from "../../core/models/FormInput";
import { GenerateTemplateAPI } from "../CmsAPI";

function GenerateTemplate(props) {
  const [isGeneratingTemplate, setIsGeneratingTemplate] = useState(false);
  const [error, setError] = useState(null);
  const [state, setState] = useState({
    template_content: {},
    has_generated: false,
    has_copied: false,
    has_copy_failed: false,
  });
  const { match } = props;
  const { params } = match;
  let { content_type = "privacy-policy" } = params;
  const api: any = new GenerateTemplateAPI();
  const inputs: Array = [
    new FormInput(
      InputType.text,
      "company_name",
      "Company name",
      "Enter company name",
      "",
      true,
      true,
    ),
    new FormInput(
      InputType.text,
      "company_website",
      "Company website",
      "Enter company website",
      "",
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "address",
      "Address",
      "Enter full address",
      "",
      true,
      true,
    ),
    new FormInput(InputType.text, "city", "City", "Enter city", "", true, true),
    new FormInput(
      InputType.number,
      "zip_code",
      "Zip code",
      "Enter zip code",
      "",
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "state",
      "State",
      "Enter state",
      "",
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "country",
      "Country",
      "Enter country",
      "",
      true,
      true,
    ),
    new FormInput(
      InputType.email,
      "email_address",
      "Email",
      "Enter email address",
      "",
      false,
      true,
    ),
  ];

  const copyToClipboard: any = () => {
    const { template_content } = state;
    navigator.clipboard
      .writeText(template_content.text_output)
      .then(() => {
        setState((prevState) => {
          return {
            ...prevState,
            ...{
              has_copied: true,
              has_copy_failed: false,
            },
          };
        });
      })
      .catch(() => {
        setState((prevState) => {
          return {
            ...prevState,
            ...{
              has_copied: false,
              has_copy_failed: true,
            },
          };
        });
      });
  };

  const generateTemplate: any = (parameters) => {
    content_type = content_type.replace(/-/g, "_");
    setIsGeneratingTemplate(true);
    setState((prevState) => {
      return {
        ...prevState,
        ...{ template_content_or_error: {}, has_generated: false },
      };
    });
    api.generateTemplate(
      Object.assign(parameters, { content_type }),
      (is_successful, template_content_or_error) => {
        if (is_successful) {
          setError(null);
          setIsGeneratingTemplate(false);
          setState((prevState) => {
            return {
              ...prevState,
              ...{
                template_content: template_content_or_error,
                has_generated: true,
              },
            };
          });
        } else {
          setError(template_content_or_error);
          setIsGeneratingTemplate(false);
        }
      },
    );
  };

  const { template_content, has_generated, has_copied, has_copy_failed } =
    state;
  const text_output: string = template_content.text_output || "";
  const content_label: any = content_type.replace(/-/g, " ");
  let modal_title: any = _.capitalize(content_label);
  let copy_icon: any = <Icon icon="clipboard" />;
  if (has_copied) {
    copy_icon = (
      <span className="text-success">
        <Icon icon="check-circle-fill" />
      </span>
    );
  } else if (has_copy_failed) {
    copy_icon = (
      <span className="text-danger">
        <Icon icon="x-circle-fill" />
      </span>
    );
  }
  const copy_button: any = (
    <button
      type="button"
      className="btn btn-outline-primary ms-4"
      onClick={copyToClipboard}
    >
      {copy_icon} Copy to clipboard
    </button>
  );
  modal_title = (
    <span>
      {modal_title} {copy_button}
    </span>
  );

  return (
    <div className="d-flex flex-column align-items-center col-md-12">
      <OlittModal
        show={has_generated}
        title={modal_title}
        onClose={() => {}}
        size="xl"
      >
        <div className="p-4">{parse(text_output)}</div>
      </OlittModal>
      <Form
        form_classes="col-md-8"
        onSubmit={generateTemplate}
        is_submitting={isGeneratingTemplate}
        error={error}
        submit_text={`Generate my ${content_label}`}
        processing_label="checking"
        inputs={inputs}
        no_of_columns={2}
      />
    </div>
  );
}

GenerateTemplate.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(GenerateTemplate);
