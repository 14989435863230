import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import { Datatable } from "../../core/components/Datatable";
import { ErrorCard } from "../../core/components/ErrorCard";
import MainNavigationSidebar from "../../core/components/MainNavigationSidebar";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import { navigateToPage } from "../../core/utilities/navigation/Navigator";
import { invalidateData } from "../../core/utilities/redux/actions/actions";
import {
  fetchUrlData,
  getUrlData,
} from "../../core/utilities/redux/componentActions";
import { ToastUtility, toast_styles } from "../../core/utilities/ToastUtility";
import { AddNewDomain } from "../components/AddNewDomain";
import { DnsDomainsApi, DnsUserApi } from "../DnsAPIs";
import {
  DNS_DOMAINS_DATA,
  DNS_USER_DATA,
  PATH_DNS_DOMAIN_RECORDS,
  URL_DNS_DOMAINS,
  URL_DNS_USER,
} from "../DnsConstants";
import { DnsDomain, DnsUser } from "../DnsModels";

function DomainList(props) {
  const { is_loading_dns_user, dns_user, is_loading_dns_domains, dns_domains } =
    props;

  const [error, setError] = useState(null);
  const [is_enrolling_user_for_dns, setIsEnrollingUserForDns] = useState(false);
  const [is_adding_domain, setIsAddingDomain] = useState(false);
  const [is_domains_in_storage, setDomainsInStorage] = useState(false);

  const fetchDnsDomains: any = () =>
    fetchUrlData(DNS_DOMAINS_DATA, URL_DNS_DOMAINS, props);
  const refreshDnsDomains: any = () => {
    props.dispatch(invalidateData(URL_DNS_DOMAINS));
    fetchDnsDomains();
  };

  const fetchDnsUser: any = () =>
    fetchUrlData(DNS_USER_DATA, URL_DNS_USER, props);
  const enrollUserForDnsManagement: any = () => {
    if (typeof dns_domains === "undefined" || dns_domains.length === 0) {
      setIsEnrollingUserForDns(true);
      setError(null);
      const dns_user_api: any = new DnsUserApi();
      dns_user_api.createUser((is_successful, user_or_error) => {
        let api_error: any = user_or_error;
        if (is_successful) {
          api_error = null;
          props.dispatch(invalidateData(URL_DNS_USER));
          props.dispatch(invalidateData(URL_DNS_DOMAINS));
          fetchDnsUser();
          fetchDnsDomains();
          setDomainsInStorage(true);
        }
        setIsEnrollingUserForDns(false);
        setError(api_error);
      });
    } else {
      setDomainsInStorage(true);
    }
  };
  useEffect(() => {
    enrollUserForDnsManagement();
  }, []);

  let main_content;
  if (error) {
    main_content = <ErrorCard text={error} />;
  } else if (is_enrolling_user_for_dns) {
    main_content = (
      <ComponentLoadingIndicator text="Enrolling you for DNS management" />
    );
  } else if (is_loading_dns_user) {
    main_content = (
      <ComponentLoadingIndicator text="Initializing DNS management" />
    );
  } else if (is_loading_dns_domains) {
    main_content = <ComponentLoadingIndicator text="Loading domains" />;
  } else if (is_domains_in_storage) {
    const onClickDeleteDomain: any = (domain: DnsDomain) => {
      ToastUtility.toast(toast_styles.dark, `Deleting ${domain.name}`);
      const dns_domains_api: any = new DnsDomainsApi();
      dns_domains_api.deleteDomain(
        domain.name,
        (is_successful, delete_error) => {
          if (is_successful) {
            ToastUtility.toast(toast_styles.success, `Deleted ${domain.name}`);
            return refreshDnsDomains();
          }
          ToastUtility.toast(toast_styles.error, delete_error);
        },
      );
    };

    const onClickManageDomain: any = (domain: DnsDomain) => {
      const path: any = PATH_DNS_DOMAIN_RECORDS.replace(":id", domain.name);
      navigateToPage(path, props);
    };

    const getActionFormat: any = (cell, row) => (
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-sm btn-outline-olitt-grey shadow-none"
          onClick={() => onClickManageDomain(row)}
        >
          <i className="la la-cog" />
          Manage
        </button>
        <button
          type="button"
          className="btn btn-sm btn-outline-danger shadow-none ms-2"
          onClick={() => onClickDeleteDomain(row)}
        >
          <i className="la la-trash" />
          Delete
        </button>
      </div>
    );

    const columns: any = [
      {
        dataField: "name",
        text: "Name",
      },
      {
        dataField: "",
        text: "Actions",
        formatter: getActionFormat,
      },
    ];
    main_content = (
      <Datatable
        columns={columns}
        data={dns_domains}
        onClickRow={() => null}
        is_searchable
        no_header
      />
    );
  }

  let add_new_domain_component: object = null;
  if (is_adding_domain) {
    add_new_domain_component = (
      <AddNewDomain
        cancelAddingDomain={() => setIsAddingDomain(false)}
        refreshDomains={refreshDnsDomains}
      />
    );
  }

  return (
    <SidebarWrapper sidebar={<MainNavigationSidebar />}>
      <div className="card card-body shadow-sm border-0">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5
            className="text-olitt-grey font-weight-bold"
            data-tutorial="dns-welcome"
          >
            My Domains
          </h5>
          <button
            type="button"
            className="btn btn-olitt-grey shadow-none"
            onClick={() => setIsAddingDomain(true)}
          >
            <i className="la la-plus" data-tutorial="add-domain-btn" /> Add
            Domain
          </button>
        </div>

        {add_new_domain_component}

        {main_content}
      </div>
    </SidebarWrapper>
  );
}

DomainList.propTypes = {
  is_loading_dns_user: PropTypes.bool.isRequired,
  dns_user: PropTypes.instanceOf(DnsUser).isRequired,

  is_loading_dns_domains: PropTypes.bool.isRequired,
  dns_domains: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  const dns_user_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    DNS_USER_DATA,
  );
  const is_loading_dns_user: any = dns_user_data.isFetching;
  let dns_user: object = null;
  if (dns_user_data.items.length === 1) {
    dns_user = new DnsUser(dns_user_data.items[0]);
  }

  const dns_domains_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    DNS_DOMAINS_DATA,
  );
  const is_loading_dns_domains: any = dns_domains_data.isFetching;
  let dns_domains: null[] = [];
  if (dns_domains_data.items.length > 0) {
    dns_domains = dns_domains_data.items.map(
      (parameters) => new DnsDomain(parameters),
    );
  }

  return { is_loading_dns_user, dns_user, is_loading_dns_domains, dns_domains };
}

export default connect(mapStateToProps)(DomainList);
