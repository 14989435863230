import { hot_jar_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const tm = editor.Panels.getButton("views", "open-tm");
  const { hot_jar_site_id, hotJarComponent } = opts;

  const placeholder = `<div style="text-align:center;color:#f6a908;height: 50px;">${hot_jar_logo}</div><div style="text-align:center;color:#f6a908;">This is a placeholder and will not be rendered. Toggle visibility in layers</div>`;

  domc.addType("HOT-JAR-COMPONENT", {
    model: {
      defaults: {
        tagName: "div",
        name: "hotJar",
        draggable: "[data-gjs-type=wrapper]",
        droppable: false,
        stylable: false,
        copyable: false,
        hot_jar_site_id,
        traits: [
          {
            name: "hot_jar_site_id",
            label: "Site ID",
            placeholder: "Enter Site ID.",
            changeProp: 1,
          },
        ],
        content: `<div id="hotJar-container" style="padding: 25px;">${placeholder}</div>`,
        script() {
          (function (h, o, hot_jar_site_id, t, j, a, r) {
            h.hj =
              h.hj ||
              function () {
                (h.hj.q = h.hj.q || []).push(arguments);
              };
            h._hjSettings = { hjid: hot_jar_site_id, hjsv: 6 };
            a = o.getElementsByTagName("head")[0];
            r = o.createElement("script");
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
          })(
            window,
            document,
            "{[ hot_jar_site_id ]}",
            "https://static.hotjar.com/c/hotjar-",
            ".js?sv="
          );
        },
      },
      init() {
        this.on("change:hot_jar_site_id", () => valuesOnchange());
        const valuesOnchange = () => {
          this.trigger("change:script");
          editor.store();
        };
        tm.set("active", 1);
      },
      toHTML() {
        const tagName = this.get("tagName");
        return `<${tagName} id="${this.getId()}"></${tagName}>`;
      },
      ...hotJarComponent,
    },
    view: {},
  });
};
