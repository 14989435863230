import PropTypes from "prop-types";
import React from "react";
import MainNavigationSidebar from "../../core/components/MainNavigationSidebar";
import SidebarWrapper from "../../core/components/SidebarWrapper";

function SetupLayout({ children }) {
  return (
    <SidebarWrapper sidebar={<MainNavigationSidebar />}>
      <div className="card">
        <div className="card-body">{children}</div>
      </div>
    </SidebarWrapper>
  );
}

SetupLayout.defaultProps = {
  children: null,
};

SetupLayout.propTypes = {
  children: PropTypes.node,
};

export default SetupLayout;
