import { AuthenticationTokens, User } from "./AccountModels";

/**
 * Actions for the authentication service:
 *  - Store auth tokens
 *  - Read auth tokens
 *  - Read auth status
 */
export class AuthenticationActions {
  async storeAuthenticationTokens(tokens: AuthenticationTokens) {
    if (![undefined, ""].includes(tokens.access_token)) {
      await localStorage.setItem("access_token", tokens.access_token);
      await localStorage.setItem("refresh_token", tokens.refresh_token);
    }
  }

  retrieveAuthenticationTokens() {
    return {
      access_token: localStorage.access_token || "_",
      refresh_token: localStorage.refresh_token || "_",
    };
  }

  retrieveAuthenticatedUser() {
    try {
      const token: any = this.retrieveAuthenticationTokens().access_token;
      const payload: any = JSON.parse(atob(token.split(".")[1]));
      return new User(payload);
    } catch (e) {
      return null;
    }
  }

  isAuthenticated() {
    const tokens: any = this.retrieveAuthenticationTokens();
    const { access_token, refresh_token, expiry_time } = tokens;
    if (access_token === "" || refresh_token === "" || expiry_time === 0) {
      return false;
    }
    return new Date().getTime() <= expiry_time;
  }
}
