import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import { openModal } from "modules/base/utilities/Actions";
import CreateFocusItemModal from "modules/marketing/components/Focusitems/CreateFocusItemModal";
import EditFocusItemModal from "modules/marketing/components/Focusitems/EditFocusItemModal";
import PrebuiltTemplatesModal from "modules/marketing/components/Focusitems/PreBuiltTemplatesModal";
import { TemplateTypeProvider } from "modules/marketing/components/Focusitems/TemplatesContext";
import publishUnpublishActionColumn from "modules/marketing/components/PublishUnpublishActionColumn";
import { focusItemsColumns } from "modules/marketing/components/tableColumns";
import MarketingAPI from "modules/marketing/MarketingAPI";

function FocusItemsList(props) {
  const { match } = props;
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const [uuid] = useState(uuidv4());
  const [prebuiltHtml, setPrebuiltHtml] = useState(null);
  const [error, setError] = useState(null);
  const [paginationParams, setPaginationParams] = useState({
    start: 0,
    limit: 10,
  });
  const params = useMemo(
    () => ({
      ...paginationParams,
      orderBy: "dateAdded",
      orderByDir: "desc",
    }),
    [paginationParams],
  );
  const {
    focusItems,
    total,
    isLoading,
    error: fetchError,
  } = API.getFocusItems(params);
  const onClickEdit = useCallback((focusItem) => {
    openModal(`edit-focus-item-${focusItem.id}-modal`);
  });
  const onClickCreate = useCallback(() => {
    openModal("create-focus-item-modal");
  });
  const onClickDelete = useCallback((id, onSuccess, onError) => {
    API.deleteFocusItem(
      id,
      () => {
        onSuccess();
      },
      (error_response) => {
        onError("Unable to delete item", error_response.message);
      },
    );
  });
  const handlePaginationChange = useCallback((newPaginationParams) => {
    setPaginationParams(newPaginationParams);
  }, []);
  const columns = [
    ...focusItemsColumns,
    publishUnpublishActionColumn(siteId, setError, "updateFocusItem"),
  ];

  const updateHTML = useCallback(
    (html) => {
      setPrebuiltHtml(html);
    },
    [setPrebuiltHtml],
  );

  return (
    <TemplateTypeProvider>
      <>
        <NetworkMessageDisplay error={error ?? fetchError} />
        <PrebuiltTemplatesModal
          setPrebuiltHtml={(html) => updateHTML(html)}
          uuid={uuid}
        />
        <CreateFocusItemModal uuid={uuid} prebuiltHtml={prebuiltHtml} />
        <DataTable
          data={focusItems}
          columns={columns}
          total={total}
          isLoading={isLoading}
          createButtonColor="gray-900"
          editButtonColor="gray-900"
          objectType="item"
          allowEdit
          allowDelete
          allowCreate
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          onClickCreate={onClickCreate}
          EditModal={EditFocusItemModal}
          onPaginationChange={handlePaginationChange}
          isServerSidePagination
        />
      </>
    </TemplateTypeProvider>
  );
}

FocusItemsList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(FocusItemsList);
