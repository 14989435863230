import {
  OpenAccount,
  OpenAssets,
  OpenBlocks,
  OpenImport,
  OpenLayers,
  OpenPages,
  OpenStyleManager,
  OpenTraitManager,
} from "../commands";
import {
  cmdClear,
  cmdCustomOpenAccount,
  cmdCustomOpenBlockManager,
  cmdCustomOpenImport,
  cmdCustomOpenLayers,
  cmdCustomOpenPages,
  cmdCustomOpenStyleManager,
  cmdCustomOpenTraitManager,
  cmdDeviceDesktop,
  cmdDeviceMobile,
  cmdDeviceTablet,
  cmdImport,
  cmdOpenAssets,
  expt,
  ful,
  openAssetManager,
  openBlocks,
  openLayers,
  openStyles,
  openTraits,
  prv,
  swv,
} from "../consts";

/**
 * Rearrange the panels and buttons
 * @param {grapesjs.default.Editor} editor
 * @param {Object} config
 */
export default (editor, config: {}) => {
  const { newsletter_mode } = config;
  const pn = editor.Panels;
  const eConfig = editor.getConfig();

  eConfig.showDevices = 0;

  editor.Commands.add(cmdCustomOpenStyleManager, OpenStyleManager);
  editor.Commands.add(cmdCustomOpenTraitManager, OpenTraitManager);
  editor.Commands.add(cmdCustomOpenLayers, OpenLayers);
  editor.Commands.add(cmdCustomOpenBlockManager, OpenBlocks);
  editor.Commands.add(cmdCustomOpenAccount, OpenAccount);
  editor.Commands.add(cmdOpenAssets, OpenAssets);
  editor.Commands.add(cmdCustomOpenImport, OpenImport);
  if (!newsletter_mode) {
    editor.Commands.add(cmdCustomOpenPages, OpenPages);
  }

  // Mark Commands as inactive when Modal is closed
  editor.on("modal:close", () => {
    editor.Panels.getPanel("commands").buttons.each((button) =>
      button.set("active", false),
    );
  });

  pn.getPanels().reset([
    {
      id: "commands",
      buttons: [
        {
          id: openBlocks,
          className: "bi bi-plus fs-3",
          command: {
            run: (e, sender) => {
              e.Commands.run(cmdCustomOpenBlockManager, {
                selector: ".gjs-pn-btn.bi.bi-plus",
              });
              e.on("pop-up:close", () => {
                sender.set("active", false);
              });
            },
            stop: (e) => e.Commands.stop(cmdCustomOpenBlockManager),
          },
        },
        {
          id: openTraits,
          className: "fa fa-cog fs-4",
          command: {
            run: (e, sender) => {
              e.Commands.run(cmdCustomOpenTraitManager, {
                selector: ".gjs-pn-btn.fa.fa-cog",
              });
              e.on("pop-up:close", () => {
                sender.set("active", false);
              });
            },
            stop: (e) => e.Commands.stop(cmdCustomOpenTraitManager),
          },
        },
        {
          id: openAssetManager,
          className: "bi bi-image fs-4",
          command: {
            run: (e, sender) => {
              e.Commands.run(cmdOpenAssets, {
                selector: ".gjs-pn-btn.bi.bi-image",
              });
              e.on("pop-up:close", () => {
                sender.set("active", false);
              });
            },
            stop: (e) => {
              e.Commands.stop(cmdOpenAssets);
            },
          },
          attributes: { title: "Asset Library" },
        },
        {
          id: openLayers,
          className: "bi bi-layers fs-4",
          command: {
            run: (e, sender) => {
              e.Commands.run(cmdCustomOpenLayers, {
                selector: ".gjs-pn-btn.bi.bi-layers",
              });
              e.on("pop-up:close", () => {
                sender.set("active", false);
              });
            },
            stop: (e) => e.Commands.stop(cmdCustomOpenLayers),
          },
        },
        {
          id: openStyles,
          className: "bi bi-sliders fs-4",
          command: {
            run: (e, sender) => {
              e.Commands.run(cmdCustomOpenStyleManager, {
                selector: ".gjs-pn-btn.bi.bi-sliders",
              });
              e.on("pop-up:close", () => {
                sender.set("active", false);
              });
            },
            stop: (e) => e.Commands.stop(cmdCustomOpenStyleManager),
          },
        },
        {
          id: "help-center",
          className: "bi bi-question-circle-fill fs-4",
          command() {
            window.open("https://help.olitt.com", "_blank");
          },
          attributes: { title: "Help Center" },
        },
      ],
    },
    {
      id: "options",
      buttons: [
        {
          id: swv,
          command: swv,
          context: swv,
          className: "fa fa-square-o d-none",
        },
        {
          id: ful,
          command: ful,
          context: ful,
          className: "fa fa-arrows-alt d-none",
        },
        {
          id: expt,
          className: "fa fa-code d-none",
          command: (e) => e.runCommand(expt),
        },
        {
          id: cmdClear,
          className: "fa fa-trash d-none",
          command: (e) => e.runCommand(cmdClear),
        },
        {
          id: cmdImport,
          className: "fa fa-download d-none",
          command: (e) => e.runCommand(cmdImport),
        },
        {
          id: "undo",
          className: "fa fa-undo",
          command: (e) => e.runCommand("core:undo"),
        },
        {
          id: "redo",
          className: "fa fa-repeat",
          command: (e) => e.runCommand("core:redo"),
        },
        {
          id: prv,
          context: prv,
          command: (e) => e.runCommand(prv),
          className: "fa fa-eye",
        },
      ],
    },
    {
      id: "views",
      attributes: { class: "d-none" },
      buttons: [
        {
          id: openStyles,
          command: openStyles,
          active: true,
          className: "fa fa-paint-brush",
        },
        {
          id: openTraits,
          command: openTraits,
          className: "fa fa-cog",
        },
        {
          id: openLayers,
          command: openLayers,
          className: "fa fa-bars",
        },
        {
          id: openBlocks,
          command: openBlocks,
          className: "fa fa-th-large",
        },
      ],
    },
    {
      id: "views-container",
      attributes: { class: "d-none" },
    },
  ]);

  // Add devices buttons
  const panelDevices = pn.addPanel({
    id: "devices-c",
    attributes: { class: "d-none d-lg-block" },
  });

  panelDevices.buttons.set([
    {
      id: cmdDeviceDesktop,
      command: {
        run: (e) => e.setDevice("Desktop"),
        stop() {},
      },
      className: "fa fa-desktop",
      active: true,
    },
    {
      id: cmdDeviceTablet,
      command: {
        run: (e) => e.setDevice("Tablet"),
        stop() {},
      },
      className: "fa fa-tablet",
    },
    {
      id: cmdDeviceMobile,
      command: {
        run: (e) => e.setDevice("Mobile portrait"),
        stop() {},
      },
      className: "fa fa-mobile",
    },
  ]);
};
