import PropTypes from "prop-types";
import { React, useCallback } from "react";
import DeleteAction from "modules/base/components/table/DeleteAction";
import DropdownAction from "modules/base/components/table/DropdownAction";
import EditAction from "modules/base/components/table/EditAction";
import ViewAction from "modules/base/components/table/ViewAction";

function ActionsCell(props) {
  const {
    row,
    allowEdit,
    onClickEdit,
    editButtonColor,
    allowDelete,
    onClickDelete,
    objectType,
    EditModal,
    editModalProps,
    viewButtonColor,
    allowView,
    onClickView,
    allowDropdownActions,
    dropdownActions,
    DropdownModal,
    dropdownLabel,
  } = props;

  const renderEditAction = useCallback(
    () =>
      allowEdit && (
        <EditAction
          onClick={onClickEdit}
          row={row.original}
          buttonColor={editButtonColor}
        />
      ),
    [allowEdit, onClickEdit, row.original, editButtonColor],
  );

  const renderViewAction = useCallback(
    () =>
      allowView &&
      typeof onClickView === "function" && (
        <ViewAction
          row={row.original}
          onClick={onClickView}
          viewButtonColor={viewButtonColor}
        />
      ),
    [allowView, onClickView, row.original, viewButtonColor],
  );

  const renderDropdownAction = useCallback(
    () =>
      allowDropdownActions && (
        <DropdownAction
          actionsList={dropdownActions || []}
          row={row.original}
          dropdownLabel={dropdownLabel || []}
        />
      ),
    [allowDropdownActions, dropdownActions, row.original, dropdownLabel],
  );

  const renderDeleteAction = () =>
    allowDelete && (
      <DeleteAction
        row={row.original}
        onClick={(subject_id, onSuccess, onError) => {
          onClickDelete(subject_id, onSuccess, onError);
        }}
        objectType={objectType}
      />
    );

  return (
    <div className="d-flex justify-content-center">
      {EditModal && (
        <EditModal row={row.original} editModalProps={editModalProps} />
      )}
      {renderViewAction()}
      {DropdownModal && <DropdownModal row={row.original} />}
      {renderDropdownAction()}
      {renderEditAction()}
      {renderDeleteAction()}
    </div>
  );
}

ActionsCell.defaultProps = {
  allowEdit: true,
  onClickEdit: () => {},
  editButtonColor: "primary",
  allowDelete: true,
  onClickDelete: () => {},
  objectType: "item",
  EditModal: null,
  editModalProps: {},
  allowView: false,
  onClickView: () => {},
  viewButtonColor: "primary",
  allowDropdownActions: true,
  dropdownActions: null,
  DropdownModal: null,
};

ActionsCell.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  allowEdit: PropTypes.bool,
  onClickEdit: PropTypes.func,
  editButtonColor: PropTypes.string,
  allowDelete: PropTypes.bool,
  onClickDelete: PropTypes.func,
  allowView: PropTypes.bool,
  onClickView: PropTypes.func,
  viewButtonColor: PropTypes.string,
  objectType: PropTypes.string,
  EditModal: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  editModalProps: PropTypes.instanceOf(Object),
  allowDropdownActions: PropTypes.bool,
  dropdownActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  DropdownModal: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  dropdownLabel: PropTypes.string.isRequired,
};

export default ActionsCell;
