import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { BillingAPI } from "modules/billing/BillingAPIs";
import PlanSelect from "modules/billing/components/PlanSelect";

function PlanPurchase(props) {
  const { selectedPlanId, selectedCycle, onCompletePurchase } = props;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [error, setError] = React.useState(null);

  const purchasePlan = useCallback((plan, cycleName) => {
    const planId = plan.id;
    const billing_api = new BillingAPI();
    setIsSubmitting(true);
    billing_api.createSubscription(
      planId,
      cycleName,
      null,
      () => {
        setSuccess({
          message: "Plan purchased successfully",
        });
        setIsSubmitting(false);
        setError(null);
        onCompletePurchase();
      },
      (response) => {
        setError({
          message: response.message,
        });
        setIsSubmitting(false);
        setSuccess(null);
      },
    );
  });

  return (
    <PlanSelect
      selectedPlanId={selectedPlanId}
      handleSubmit={purchasePlan}
      isSubmitting={isSubmitting}
      success={success}
      error={error}
      formId="purchase-plan-form"
      selectedCycle={selectedCycle}
      submitFormButtonLabel="Complete Purchase"
    />
  );
}

PlanPurchase.propTypes = {
  selectedPlanId: PropTypes.number,
  selectedCycle: PropTypes.string,
  onCompletePurchase: PropTypes.func.isRequired,
};

PlanPurchase.defaultProps = {
  selectedPlanId: null,
  selectedCycle: null,
};

export default PlanPurchase;
