const deviceTypes = [
  { value: "desktop", label: "desktop" },
  { value: "smartphone", label: "smartphone" },
  { value: "tablet", label: "tablet" },
  { value: "feature phone", label: "feature phone" },
  { value: "console", label: "console" },
  { value: "tv", label: "tv" },
  { value: "car browser", label: "car browser" },
  { value: "smart display", label: "smart display" },
  { value: "camera", label: "camera" },
  { value: "portable media player", label: "portable media player" },
  { value: "phablet", label: "phablet" },
  { value: "smart speaker", label: "smart speaker" },
  { value: "wearable", label: "wearable" },
  { value: "peripheral", label: "peripheral" },
];

export default deviceTypes;
