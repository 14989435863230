import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import EditCampaign from "modules/marketing/components/campaigns/EditCampaign";
import { Campaign } from "modules/marketing/MarketingModels";

function EditCampaignModal(props) {
  const { row: campaign } = props;
  const modalId = `edit-campaign-${campaign.id}-modal`;

  return (
    <Modal modal_title="Edit Campaign" modal_id={modalId} size="md">
      <EditCampaign campaign={campaign} currentModalId={modalId} />
    </Modal>
  );
}

EditCampaignModal.propTypes = {
  row: PropTypes.instanceOf(Campaign).isRequired,
};

export default EditCampaignModal;
