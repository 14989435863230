import PropTypes from "prop-types";

function BaseWalletTopUpButton(props) {
  const { buttonColor, handleClick } = props;
  return (
    <button
      type="button"
      className={`btn btn-sm btn-${buttonColor}`}
      onClick={handleClick}
    >
      <i className="bi bi-wallet-fill" /> Top Up
    </button>
  );
}

BaseWalletTopUpButton.propTypes = {
  buttonColor: PropTypes.string,
  handleClick: PropTypes.func,
};

BaseWalletTopUpButton.defaultProps = {
  buttonColor: "primary",
  handleClick: () => {
    // do nothing
  },
};

export default BaseWalletTopUpButton;
