import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ComponentLoadingIndicator from "../../../core/components/ComponentLoadingIndicator";
import { getUrlData } from "../../../core/utilities/redux/componentActions";
import OrderTable from "../orders/components/OrderTable";
import SalesChart from "./components/Charts/SalesChart";
import OnDemandProducts from "./components/OnDemandProducts";
import ShareProfile from "./components/ShareProfile";
import OrderCard from "./components/topCard/OrderCard";
import RevenueCard from "./components/topCard/RevenueCard";
import ShippingCard from "./components/topCard/ShippingCard";

const SHOP_DETAILS_DATA_KEY = "SHOP_DETAILS_DATA_KEY";

/**
 * @name DashboardView
 * @description A View that displays components Cart, Charts, Orders.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DashboardView(props) {
  const { isLoadingShop } = props;

  if (isLoadingShop) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <ComponentLoadingIndicator />
      </div>
    );
  }
  return (
    <>
      <div className="container">
        <div className="row top-row g-4">
          <div className="col-lg-8 col-md-12">
            <div className="row g-4">
              <div className="col-lg-4 col-md-4 col-sm-4">
                <RevenueCard />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <OrderCard />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <ShippingCard />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div>
              <ShareProfile />
            </div>
          </div>
        </div>
        <div className="row gx-lg-4 gx-0 gy-lg-0 gy-4 mt-4">
          <div className="col-lg-8 col-12 h-100 mh-100">
            <SalesChart />
          </div>
          <div className="col-lg-4 col-12 flex-grow-1 d-flex">
            <OnDemandProducts />
          </div>
        </div>

        <div className="row gx-4 gy-0">
          <div className="col-12d-flex justify-content-center">
            <div>
              <OrderTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

DashboardView.defaultProps = {};

DashboardView.propTypes = {
  isLoadingShop: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const shop_details_data = getUrlData(
    dataByUrl,
    sessionVariables,
    SHOP_DETAILS_DATA_KEY,
  );
  return {
    isLoadingShop: shop_details_data.isFetching,
    shop: shop_details_data.items,
  };
}

export default connect(mapStateToProps)(withRouter(DashboardView));
