import PropTypes from "prop-types";
import React from "react";
import { FormPhoneInput } from "../../core/components/form-controls/FormPhoneInput";

function WhatsAppSettingsCard(props) {
  const { onChange, whatsAppNumber, whatsAppEnabled } = props;

  const onChangeNumber = ({ fullNumber, isValid }) => {
    if (isValid) {
      onChange({
        whatsapp_phone_number: fullNumber,
      });
    }
  };

  const onChangeEnabled = (e) => {
    onChange({
      whatsapp_enabled: e.target.checked,
    });
  };

  const whatsAppEnabledInput = (
    <div className="form-check form-switch">
      <input
        id="whatsAppToggle"
        className="form-check-input"
        type="checkbox"
        role="switch"
        name="whatsapp_enabled"
        checked={whatsAppEnabled}
        onChange={onChangeEnabled}
      />
      <label className="form-check-label" htmlFor="whatsAppToggle">
        Enable WhatsApp Chat
      </label>
    </div>
  );

  return (
    <div className="d-flex flex-column gap-2">
      <FormPhoneInput
        label="WhatsApp Number"
        value={whatsAppNumber}
        onChange={onChangeNumber}
      />
      {whatsAppEnabledInput}
    </div>
  );
}

WhatsAppSettingsCard.defaultProps = {};

WhatsAppSettingsCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  whatsAppNumber: PropTypes.string.isRequired,
  whatsAppEnabled: PropTypes.bool.isRequired,
};

export default WhatsAppSettingsCard;
