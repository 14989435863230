import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import EditForm from "modules/marketing/components/forms/EditForm";
import { Form } from "modules/marketing/MarketingModels";

function EditFormModal(props) {
  const { row: form } = props;
  const modalId = `edit-form-modal-${form.id}`;
  return (
    <Modal modal_title="Edit Form" modal_id={modalId} size="md">
      <EditForm form={form} currentModalId={modalId} />
    </Modal>
  );
}

EditFormModal.propTypes = {
  row: PropTypes.instanceOf(Form).isRequired,
};

export default EditFormModal;
