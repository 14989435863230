function IncompatibleDeviceWarning() {
  return (
    <div className="container">
      <div className="alert alert-warning mt-4">
        You are using an unsupported device. This application may not work
        properly on your device.
      </div>
    </div>
  );
}

export default IncompatibleDeviceWarning;
