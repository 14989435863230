import PropTypes from "prop-types";
import SendEmailTemplate from "./SendEmailTemplate";
import Modal from "modules/base/components/Modal";

function SendEmailTemplateModal({ selectedSegmentsIds }) {
  const modalId = "send-email-template-modal";
  return (
    <Modal modal_title="Select Email Template" modal_id={modalId} size="md">
      <SendEmailTemplate
        selectedSegmentsIds={selectedSegmentsIds}
        currentModalId={modalId}
      />
    </Modal>
  );
}

SendEmailTemplateModal.propTypes = {
  selectedSegmentsIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
};

export default SendEmailTemplateModal;
