export class Affiliate {
  id: number;

  date_enrolled: Date;

  visitors: number = 0;

  balance: number = 0.0;

  withdrawn: number = 0.0;

  constructor(parameters) {
    this.id = parameters.affiliate_id;
    this.date_enrolled = new Date(parameters.date_enrolled);
    this.visitors = parameters.visitors;
    this.balance = parameters.balance;
    this.withdrawn = parameters.withdrawn;
  }
}
