import { Modal as BootstrapModal } from "bootstrap";
import PropTypes from "prop-types";
import { useCallback } from "react";
import ReactCountryFlag from "react-country-flag";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SectionCard from "modules/base/components/SectionCard";

function BaseBillingDetails(props) {
  const { customer, error, BillingDetailsForm, edit_button_color } = props;

  function editDetails() {
    const editModal = BootstrapModal.getOrCreateInstance(
      document.getElementById("change-billing-details"),
    );
    editModal.show();
  }

  const handleEdit = useCallback(editDetails);
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <BillingDetailsForm />
      <SectionCard
        title={
          <>
            Billing details{" "}
            <button
              type="button"
              className={`btn btn-sm btn-outline-${edit_button_color}`}
              onClick={handleEdit}
            >
              <i className="bi bi-pencil-square" /> Edit
            </button>
          </>
        }
      >
        <div className="row row-cols-1">
          <div className="col">
            <i className="bi bi-person-vcard" /> {customer.name}
          </div>
          <div className="col">
            <i className="bi bi-envelope" /> {customer?.email}
          </div>
          <div className="col">
            <i className="bi bi-geo-alt" /> {customer?.address_line1},{" "}
            {customer?.address_line2}, {customer?.city}, {customer?.state}
            <ReactCountryFlag
              countryCode={customer.country}
              className="ms-2"
              svg
              style={{
                width: "1.2em",
                height: "1.2em",
              }}
            />
          </div>
        </div>
      </SectionCard>
    </>
  );
}

BaseBillingDetails.defaultProps = {
  customer: {},
  error: null,
  edit_button_color: "purple",
};

BaseBillingDetails.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    address_line1: PropTypes.string,
    address_line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
  }),
  error: PropTypes.string,
  BillingDetailsForm: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
    .isRequired,
  edit_button_color: PropTypes.string,
};

export default BaseBillingDetails;
