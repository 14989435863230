import Modal from "modules/base/components/Modal";
import CreateCampaign from "modules/marketing/components/campaigns/CreateCampaign";

function CreateCampaignModal() {
  const modalId = "create-campaign-modal";
  return (
    <Modal modal_title="Create Campaign" modal_id={modalId} size="md">
      <CreateCampaign currentModalId={modalId} />
    </Modal>
  );
}

export default CreateCampaignModal;
