import PropTypes from "prop-types";
import Form from "modules/base/components/Form";
import TextInput from "modules/base/components/inputs/TextInput";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";

function DomainNameserversForm(props) {
  const { state, onChangeText, handleSubmit, isSubmitting, error } = props;
  return (
    <div>
      <div className="d-flex flex-column mb-3">
        <div className="alert alert-info" role="alert">
          <i className="bi bi-info-circle-fill me-1" />
          If you want to use custom nameservers then enter them below. By
          default, new domains will use our nameservers for hosting on our
          network.
        </div>
      </div>
      <NetworkMessageDisplay error={error} />
      <Form
        handleSubmit={handleSubmit}
        button_label="Purchase selected domains"
        id="domain-nameservers-form"
        isSubmitting={isSubmitting}
        padding="p-0"
        fullLengthButton
        button_color="gray-900"
        button_icon="bi bi-cart-check"
      >
        <div className="row row-cols-1 row-cols-lg-3 mb-2 gy-3">
          <TextInput
            title="Nameserver 1"
            name="ns1"
            content={state.ns1}
            controlFunc={onChangeText}
            required
          />
          <TextInput
            title="Nameserver 2"
            name="ns2"
            content={state.ns2}
            controlFunc={onChangeText}
            required
          />
          <TextInput
            title="Nameserver 3"
            name="ns3"
            content={state.ns3}
            controlFunc={onChangeText}
          />
          <TextInput
            title="Nameserver 4"
            name="ns4"
            content={state.ns4}
            controlFunc={onChangeText}
          />
          <TextInput
            title="Nameserver 5"
            name="ns5"
            content={state.ns5}
            controlFunc={onChangeText}
          />
        </div>
      </Form>
    </div>
  );
}

DomainNameserversForm.defaultProps = {
  state: {
    ns5: "",
    selected_domains: [],
    ns2: "",
    ns1: "",
    ns4: "",
    ns3: "",
  },
  onChangeText: () => {},
  error: null,
};

DomainNameserversForm.propTypes = {
  state: PropTypes.shape({
    ns5: PropTypes.string,
    selected_domains: PropTypes.arrayOf(PropTypes.string),
    ns2: PropTypes.string,
    ns1: PropTypes.string,
    ns4: PropTypes.string,
    ns3: PropTypes.string,
  }),
  onChangeText: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
};

export default DomainNameserversForm;
