import { useQuery } from "@tanstack/react-query";
import ShopApi from "../ShopAPIs";

function useShopDetails(site_id) {
  const shopApi = new ShopApi();

  const { isLoading, data } = useQuery({
    queryKey: ["shopDetails"],
    queryFn: () =>
      new Promise((resolve, reject) => {
        shopApi.getShopDetails(
          site_id,
          (shop) => resolve(shop),
          (e) => reject(e),
        );
      }),
    refetchOnMount: false,
    staleTime: 300000,
  });

  return {
    shopDetails: data,
    isLoading,
  };
}

export default useShopDetails;
