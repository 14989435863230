import {
  URL_BILLING_ADDON_PURCHASES,
  URL_BILLING_ADDONS,
  URL_BILLING_APPLIED_COUPONS,
  URL_BILLING_APPLY_COUPON,
  URL_BILLING_CARD,
  URL_BILLING_CARD_DEFAULT,
  URL_BILLING_CARDS,
  URL_BILLING_CUSTOMER,
  URL_BILLING_INVOICES,
  URL_BILLING_PAYMENT_METHOD,
  URL_BILLING_PAYMENT_METHODS,
  URL_BILLING_TRANSACTIONS,
  URL_BILLING_WALLET,
  URL_BILLING_WALLET_TRANSACTIONS,
  URL_CHANGE_BILLING_PLAN,
  URL_COMPLETE_PAYMENT,
  URL_CREATE_SUBSCRIPTION,
  URL_GET_PAY_URL,
  URL_TOP_UP_WALLET,
} from "./BillingConstants";
import {
  BillingAddon,
  BillingAddonPurchase,
  BillingCard,
  BillingCoupon,
  BillingCustomer,
  BillingInvoice,
  BillingPaymentMethod,
  BillingTransaction,
  BillingWallet,
  BillingWalletTransaction,
} from "modules/base/models/Billing";
import { serverGeneratePath } from "modules/base/utilities/Navigation";
import { BillingPlan } from "modules/billing/BillingModels";
import { API_BASE_URL } from "modules/core/constants/Endpoints";
import { PrivateApi } from "modules/core/utilities/api/PrivateApi";
import {
  URL_SUBSCRIPTION_PLANS,
  URL_WEBSITES,
} from "modules/website/WebsiteConstants";

export class BillingAPI extends PrivateApi {
  /**
   * Create website subscription
   * @param plan_id
   * @param billing_cycle
   * @param site_id
   * @param successCallback
   * @param errorCallback
   */
  createSubscription(
    plan_id: number,
    billing_cycle: string,
    site_id: number,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void,
  ) {
    this.postRequest(
      serverGeneratePath(URL_CREATE_SUBSCRIPTION, { plan_id }),
      {
        site_id,
        billing_cycle,
      },
      (response: any) => {
        successCallback(response);
      },
      (response: any) => {
        errorCallback(response);
      },
    );
  }

  getPayUrl(
    subscription_id: string,
    callback: (is_successful: boolean, pay_url_or_error: string) => void,
  ) {
    this.postAPIRequest(
      URL_GET_PAY_URL,
      (response: any) => {
        callback(true, response.payment_url);
      },
      (response: any) => {
        callback(false, this.extractResponseError(response));
      },
      { subscription: subscription_id },
      this.getAuthenticatedHeaders(),
    );
  }

  get_unpaid_subscription(
    id: number,
    callback: (is_successful: boolean, pay_url_or_error: string) => void,
  ) {
    this.postAPIRequest(
      URL_COMPLETE_PAYMENT,
      (response: any) => {
        callback(true, response.payment_url);
      },
      (response: any) => {
        callback(false, this.extractResponseError(response));
      },
      { id },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * Retrieve subscription plans
   * @returns {{isLoading: boolean, plans: *[], error: unknown}}
   */
  retrievePlans() {
    const { data, error, isLoading } = this.getRequest(URL_SUBSCRIPTION_PLANS);
    const billingPlan = new BillingPlan();
    const plans = billingPlan.fromArray(data);
    return { plans, error, isLoading };
  }

  navigateToPricing = (website, paid_plan) => {
    window.location.href = `${API_BASE_URL}/pricing/${paid_plan.id}/plan/?site_id=${website.id}`;
  };

  /**
   * Change subscription plan
   * @param {number} site_id
   * @param {number} plan
   * @param {string} billing_cycle
   * @param {function} successCallback
   * @param {function} errorCallback
   */
  changePlan(
    site_id: number,
    plan: number,
    billing_cycle: string,
    successCallback: () => void,
    errorCallback: () => void,
  ) {
    this.postRequest(
      serverGeneratePath(URL_CHANGE_BILLING_PLAN, { id: site_id }),
      {
        plan,
        billing_cycle,
      },
      (response: any) => {
        successCallback(response);
      },
      (response: any) => {
        errorCallback(response);
      },
      "POST",
      [URL_WEBSITES],
    );
  }

  getBillingCustomer(id: string) {
    const url = serverGeneratePath(URL_BILLING_CUSTOMER, { id });
    const { data, error, isLoading } = this.getRequest(url);
    const customer = new BillingCustomer(data);
    return { customer, error, isLoading };
  }

  /**
   * Update billing customer
   * @param id
   * @param payload
   * @param successCallback
   * @param errorCallback
   * @returns {{isLoading: boolean, error: any, customer: BillingCustomer}}
   */
  updateBillingCustomer(
    id: string,
    payload,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void,
  ) {
    const url = serverGeneratePath(URL_BILLING_CUSTOMER, { id });
    this.postRequest(url, payload, successCallback, errorCallback, "PUT");
  }

  /**
   * Get billing invoices
   * @returns {{isLoading: boolean, error: any, invoices: BillingInvoice[]}}
   */
  getBillingInvoices() {
    const { data, error, isLoading } = this.getRequest(URL_BILLING_INVOICES);
    const billingInvoice = new BillingInvoice();
    const invoices: BillingInvoice[] = billingInvoice.fromArray(data);
    return { invoices, error, isLoading };
  }

  /**
   * Get billing transactions
   * @returns {{isLoading: boolean, error: any, transactions: BillingTransaction[]}}
   */
  getBillingTransactions() {
    const { data, error, isLoading } = this.getRequest(
      URL_BILLING_TRANSACTIONS,
    );
    const billingTransaction = new BillingTransaction();
    const transactions: BillingTransaction[] =
      billingTransaction.fromArray(data);
    return { transactions, error, isLoading };
  }

  /**
   * Get billing payment methods
   * @returns {{isLoading: boolean, error: any, paymentMethods: BillingPaymentMethod[]}}
   */
  getBillingPaymentMethods() {
    const { data, error, isLoading } = this.getRequest(
      URL_BILLING_PAYMENT_METHODS,
    );
    const billingPaymentMethod = new BillingPaymentMethod();
    const payment_methods: BillingPaymentMethod[] =
      billingPaymentMethod.fromArray(data);
    return { payment_methods, error, isLoading };
  }

  /**
   * Create billing payment method
   * @param payload
   * @param successCallback
   * @param errorCallback
   * @returns {{isLoading: boolean, error: any, paymentMethod: BillingPaymentMethod}}
   */
  createBillingPaymentMethod(
    payload,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void,
  ) {
    this.postRequest(
      URL_BILLING_PAYMENT_METHODS,
      payload,
      successCallback,
      errorCallback,
    );
  }

  /**
   * Delete billing payment method
   * @param id
   * @param successCallback
   * @param errorCallback
   * @returns {{isLoading: boolean, error: any, paymentMethod: BillingPaymentMethod}}
   */
  deleteBillingPaymentMethod(
    id: string,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void,
  ) {
    const url = serverGeneratePath(URL_BILLING_PAYMENT_METHOD, { id });
    this.deleteRequest(url, successCallback, errorCallback);
  }

  /**
   * Get billing cards
   * @returns {{isLoading: boolean, error: any, cards: BillingCard[]}}
   */
  getBillingCards() {
    const { data, error, isLoading } = this.getRequest(URL_BILLING_CARDS);
    const billingCard = new BillingCard();
    const cards: BillingCard[] = billingCard.fromArray(data);
    return { cards, error, isLoading };
  }

  /**
   * Create billing card
   * @param payload
   * @param successCallback
   * @param errorCallback
   * @returns {{isLoading: boolean, error: any, card: BillingCard}}
   */
  createBillingCard(
    payload,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void,
  ) {
    this.postRequest(
      URL_BILLING_CARDS,
      payload,
      successCallback,
      errorCallback,
      "POST",
      [
        URL_BILLING_CARDS,
        serverGeneratePath(URL_BILLING_CUSTOMER, { id: "i" }),
      ],
    );
  }

  /**
   * Delete billing card
   * @param id
   * @param successCallback
   * @param errorCallback
   * @returns {{isLoading: boolean, error: any, card: BillingCard}}
   */
  deleteBillingCard(
    id: string,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void,
  ) {
    const url = serverGeneratePath(URL_BILLING_CARD, { id });
    this.deleteRequest(url, successCallback, errorCallback, [
      URL_BILLING_CARDS,
      URL_BILLING_CUSTOMER,
    ]);
  }

  /**
   * Set default billing card
   * @param id
   * @param successCallback
   * @param errorCallback
   */
  setDefaultBillingCard(
    id: string,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void,
  ) {
    const url = serverGeneratePath(URL_BILLING_CARD_DEFAULT, { id });
    this.postRequest(url, {}, successCallback, errorCallback, "PATCH", [
      URL_BILLING_CARDS,
      serverGeneratePath(URL_BILLING_CUSTOMER, { id: "i" }),
    ]);
  }

  /**
   * Apply coupon
   * @param id
   * @param payload
   * @param successCallback
   * @param errorCallback
   */
  applyCoupon(
    id: string,
    payload,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void,
  ) {
    const url = serverGeneratePath(URL_BILLING_APPLY_COUPON, { id });
    this.postRequest(url, payload, successCallback, errorCallback, "POST", [
      serverGeneratePath(URL_BILLING_APPLIED_COUPONS, { id }),
    ]);
  }

  /**
   * Get applied coupons
   * @param id
   * @returns {{isLoading: boolean, error: any, coupon: BillingCoupon}}
   */
  getAppliedCoupons(id: string) {
    const url = serverGeneratePath(URL_BILLING_APPLIED_COUPONS, { id });
    const { data, error, isLoading } = this.getRequest(url);
    const billingCoupon = new BillingCoupon();
    const coupons: BillingCoupon = billingCoupon.fromArray(data);
    return { coupons, error, isLoading };
  }

  /**
   * Get billing wallet
   * @param id
   * @returns {{isLoading: boolean, error: any, wallet: BillingWallet}}
   */
  getBillingWallet(id: string) {
    const url = serverGeneratePath(URL_BILLING_WALLET, { id });
    const { data, error, isLoading } = this.getRequest(url);
    const wallet = new BillingWallet(data);
    return { wallet, error, isLoading };
  }

  /**
   * Get billing wallet transactions
   * @param id
   * @returns {{isLoading: boolean, error: any, transactions: BillingTransaction[]}}
   */
  getBillingWalletTransactions(id: string) {
    const url = serverGeneratePath(URL_BILLING_WALLET_TRANSACTIONS, { id });
    const { data, error, isLoading } = this.getRequest(url);
    const billingWalletTransaction = new BillingWalletTransaction();
    const transactions: BillingWalletTransaction[] =
      billingWalletTransaction.fromArray(data);
    return { transactions, error, isLoading };
  }

  /**
   * Top up wallet
   * @param id
   * @param amount
   * @param successCallback
   * @param errorCallback
   */
  topUpWallet(
    id: string,
    amount,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void,
  ) {
    const url = serverGeneratePath(URL_TOP_UP_WALLET, { id });
    this.postRequest(url, { amount }, successCallback, errorCallback, "POST", [
      serverGeneratePath(URL_BILLING_WALLET_TRANSACTIONS, { id }),
    ]);
  }

  /**
   * Get billing addons
   * @returns {{isLoading: boolean, error: any, addons: BillingAddon[]}}
   */
  getBillingAddons() {
    const { data, error, isLoading } = this.getRequest(URL_BILLING_ADDONS);
    const billingAddon = new BillingAddon();
    const addons: BillingAddon[] = billingAddon.fromArray(data);
    return { addons, error, isLoading };
  }

  /**
   * Purchase billing addon
   * @param addon_id
   * @param website_id
   * @param successCallback
   * @param errorCallback
   */
  purchaseAddon(
    addon_id: number,
    website_id: number,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void,
  ) {
    this.postRequest(
      URL_BILLING_ADDON_PURCHASES,
      { addon: addon_id, site: website_id },
      successCallback,
      errorCallback,
      "POST",
      [URL_BILLING_ADDON_PURCHASES],
    );
  }

  /**
   * Get billing addon purchases
   * @param site_id
   * @returns {{isLoading: boolean, error: any, addons: BillingAddon[]}}
   */
  getBillingAddonPurchases(site_id) {
    let url = URL_BILLING_ADDON_PURCHASES;
    if (site_id) {
      url += `?site=${site_id}`;
    }
    const { data, error, isLoading } = this.getRequest(url);
    const billingAddonPurchase = new BillingAddonPurchase();
    const addonPurchases = billingAddonPurchase.fromArray(data);
    return { addonPurchases, error, isLoading };
  }
}
