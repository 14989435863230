import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { withRouter } from "react-router-dom";
import DataTable from "../../../core/components/datagrid/DataTable";
import { ErrorModal } from "../../../core/components/ErrorModal";
import { navigateToPage } from "../../../core/utilities/navigation/Navigator";
import { PopUptUtility } from "../../../core/utilities/PopUpUtility";
import ShopApi from "../../ShopAPIs";
import {
  PATH_SHOP_PAYMENT_METHODS_CREATE,
  PATH_SHOP_PAYMENT_METHODS_EDIT,
  PAYMENT_METHODS_TABLE_COLUMNS,
} from "../../ShopConstants";

/**
 * @name PaymentMethodsView
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function PaymentMethodsView(props) {
  const shopApi: any = new ShopApi();
  const { match } = props;
  const { params } = match;
  const site_id: any = params.id;
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  /**
   * @name getPaymentMethods
   * @description fetch payment methods
   */
  function getPaymentMethods() {
    setLoading(true);
    shopApi.getPaymentMethods(
      site_id,
      (response: any) => {
        setPaymentMethods(response);
        setLoading(false);
      },
      (error_message: any) => {
        setError(error_message);
        setLoading(false);
      },
    );
  }

  /**
   * @name deletePaymentMethod
   * @description delete payment method
   * @param payment_method
   */
  function deletePaymentMethod(payment_method: any) {
    setLoading(true);
    shopApi.deletePaymentMethod(
      site_id,
      payment_method.id,
      () => {
        getPaymentMethods();
      },
      (error_message: any) => {
        setError(error_message);
        setLoading(false);
      },
    );
  }

  /**
   * @name navigateToCreatePaymentMethod
   * @description navigate to create payment method
   */
  function navigateToCreatePaymentMethod() {
    const create_payment_method_path: any =
      PATH_SHOP_PAYMENT_METHODS_CREATE.replace(":id", site_id);
    navigateToPage(create_payment_method_path, props);
  }

  /**
   * @name navigateToEditPaymentMethod
   * @description navigate to edit payment method
   * @param payment_method_id
   */
  function navigateToEditPaymentMethod(payment_method_id: any) {
    let edit_payment_method_path: string =
      PATH_SHOP_PAYMENT_METHODS_EDIT.replace(":id", site_id);
    edit_payment_method_path = edit_payment_method_path.replace(
      ":payment_method_id",
      payment_method_id,
    );
    navigateToPage(edit_payment_method_path, props);
  }

  useEffect(getPaymentMethods, []);
  return (
    <>
      <ErrorModal
        show={error !== null}
        onClose={() => setError(null)}
        short_desc={error ? error.short_desc : ""}
        suggestion={error ? error.suggestion : ""}
      />
      <DataTable
        columns={PAYMENT_METHODS_TABLE_COLUMNS}
        data={paymentMethods}
        isLoading={loading}
        isDataRefreshable
        onClickRefresh={useCallback(getPaymentMethods, [])}
        onClickCreate={useCallback(navigateToCreatePaymentMethod, [])}
        isDataEditable
        isDataCreatable
        isDataDeletable
        editPath={PATH_SHOP_PAYMENT_METHODS_EDIT.replace(":id", site_id)}
        onClickEdit={useCallback(navigateToEditPaymentMethod, [])}
        onClickDelete={(payment_method) =>
          PopUptUtility.confirmDeleteObject(deletePaymentMethod, payment_method)
        }
        showCheckboxColumn
        rowSize={10}
      />
    </>
  );
}

PaymentMethodsView.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(PaymentMethodsView);
