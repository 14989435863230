import { cmdCustomOpenToolbar } from "../consts";

export const OpenToolbar = {
  run(editor, _, opts = {}) {
    if (!this.toolbar) {
      const toolbar = document.createElement("div");

      this.toolbar = toolbar;
      this.contextMenu = editor.ContextMenu;
      this.editor = editor;

      editor.on("context-menu:close", () => {
        editor.Commands.stop(cmdCustomOpenToolbar);
      });
    }
    this.openToolbar(opts?.event);
  },

  renderList() {
    const { toolbar, editor } = this;
    const { em } = editor;
    const toolbarActions = editor.em
      .getSelected()
      .get("contextmenu")
      .map((action) => {
        const button = document.createElement("button");
        const icon = document.createElement("span");
        if (!action.customLabel && /<svg/.test(action.label)) {
          if (action.label === em.getIcon("move")) {
            action.customLabel = "Move";
          }

          if (action.label === em.getIcon("copy")) {
            action.customLabel = "Duplicate";
          }

          if (action.label === em.getIcon("delete")) {
            action.customLabel = "Delete";
          }

          if (action.label === em.getIcon("arrowUp")) {
            action.customLabel = "Select parent element";
          }

          if (!action.customLabel) {
            action.customLabel = "Error: unknown option";
          }
        }

        if (action.customLabel) {
          icon.textContent = action.customLabel;
        } else {
          icon.textContent = action.label;
        }

        if (action.label === "Paste" && !em.get("clipboard")) {
          button.disabled = true;
        }

        button.classList.add(
          "list-group-item",
          "list-group-item-action",
          "d-flex",
          "align-items-center",
          "gap-2",
        );
        button.setAttribute("type", "button");
        button.appendChild(icon);
        button.addEventListener("click", (event) => {
          event.preventDefault();
          if (typeof action.command === "function") {
            this.contextMenu.close();
            action.command(editor);
            return;
          }

          this.contextMenu.close();
          editor.runCommand(action.command);
        });
        return button;
      });

    toolbarActions.forEach((action) => toolbar.appendChild(action));
  },

  openToolbar(event) {
    const { toolbar, contextMenu } = this;
    toolbar.style.display = "block";

    this.renderList();

    if (event) {
      const { clientX, clientY } = event;
      contextMenu.setPosition({
        x: clientX,
        y: clientY,
      });
    }

    if (toolbar) {
      contextMenu.setContent(toolbar);
    }

    contextMenu.setContent(toolbar);
    contextMenu.open();
  },

  stop() {
    const { toolbar } = this;
    if (toolbar) {
      toolbar.style.display = "none";
      toolbar.innerHTML = "";
    }
  },
};

export default OpenToolbar;
