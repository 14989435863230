import { facebook_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const { facebookPostLabel, facebookPostCategory, facebookPostBlock } = opts;

  facebookPostBlock &&
    bm.add("FACEBOOK-POST-BLOCK", {
      label: facebookPostLabel,
      activate: true,
      select: true,
      category: facebookPostCategory,
      content: { type: "FACEBOOK-POST-COMPONENT" },
      media: facebook_logo,
    });
};
