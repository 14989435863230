import PropTypes from "prop-types";
import { useState } from "react";
import TextArea from "modules/base/components/inputs/TextArea";
import TextInput from "modules/base/components/inputs/TextInput";
import { handleInputChange } from "modules/base/utilities/Actions";

function AssetFormFields({ state, setState }) {
  const [preview, setPreview] = useState("");

  const onChange = (e) => {
    handleInputChange(e.target.name, e.target.value, state, setState);
    if (e.target.name === "file") {
      setPreview(e.target.value);
    }
  };

  return (
    <div className="mb-3">
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <TextInput
          name="title"
          title="Title"
          content={state?.title ?? ""}
          controlFunc={onChange}
          required
        />
        <TextArea
          name="description"
          title="Description"
          content={state?.description ?? ""}
          controlFunc={onChange}
          required
        />
        <TextInput
          name="file"
          title="Link to file"
          content={state?.file ?? ""}
          controlFunc={onChange}
          placeholder="eg ..https://"
          required
        />
        <div className="row pt-1">
          {preview && (
            <img
              src={preview}
              onError={(e) => (e.target.style.display = "none")}
              className="rounded"
              alt="preview"
            />
          )}
        </div>
      </div>
    </div>
  );
}

AssetFormFields.defaultProps = {
  state: {},
};

AssetFormFields.propTypes = {
  state: PropTypes.instanceOf(Object),
  setState: PropTypes.func.isRequired,
};

export default AssetFormFields;
