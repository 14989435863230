import MarketingAPI from "modules/marketing/MarketingAPI";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { closeModal } from "modules/base/utilities/Actions";

function ContactImportProgress({ match }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const { data: progress } = API.contactsUploadProgress();

  const handleCancelUpload = useCallback((e) => {
    e.preventDefault();
    API.cancelUpload(
      (data) => {
        closeModal("match-columns-modal");
        toast.warning("Upload cancelled");
      },
      (error) => {
        toast.error("Failed. Please try again.");
      },
    );
  }, []);

  return (
    <div className="card shadow-sm p-4">
      <div className="card-body">
        <h5 className="card-title text-center mb-4">Contact Upload Progress</h5>

        <div className="row text-center mb-4">
          <div className="col-md-4 mb-3">
            <div className="card bg-light text-primary">
              <div className="card-body">
                <i className="bi bi-people-fill h4 mb-2" />
                <p className="card-text small">Total Contacts</p>
                <h6 className="card-title">{progress.total}</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card bg-light text-success">
              <div className="card-body">
                <i className="bi bi-arrow-up-circle-fill h4 mb-2" />
                <p className="card-text small">Uploaded</p>
                <h6 className="card-title">{progress.uploaded}</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card bg-light text-info">
              <div className="card-body">
                <i className="bi bi-arrow-clockwise h4 mb-2" />
                <p className="card-text small">Pending contacts</p>
                <h6 className="card-title">{progress.pending}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <div className="d-flex justify-content-between">
            <span>Upload Progress</span>
            <span>{Math.round(progress.progress_percentage)}%</span>
          </div>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated bg-info"
              role="progressbar"
              style={{ width: `${progress.progress_percentage}%` }}
              aria-valuenow={progress.progress_percentage}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
          <p className="text-muted mt-2">
            {progress.progress_percentage < 100
              ? `Processed... ${progress.uploaded} of ${progress.total} contacts`
              : "Upload complete!"}
          </p>
        </div>
        <div className="row bg-light text-center text-danger">
          <button
            className="card-text small btn btn-link text-danger"
            onClick={handleCancelUpload}
            aria-label="Cancel upload"
          >
            Cancel Upload
          </button>
        </div>
      </div>
    </div>
  );
}
ContactImportProgress.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
export default withRouter(ContactImportProgress);
