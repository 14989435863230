import { default as twitterComponent } from "./components/twitterComponent";
import { default as facebookComponent } from "./components/facebookComponent";
import { default as tawToComponent } from "./components/tawkToComponent";
import { default as fbPixelComponent } from "./components/facebookPixelComponent";
import { default as jiraSupportComponent } from "./components/JiraSupportComponent";
import { default as hotJarComponent } from "./components/hotJarComponent";
import { default as IntercomComponent } from "./components/IntercomComponent";
import { default as MailChimpEmbeddedComponent } from "./components/MailChimpComponents";
import { default as MailChimpPopUpComponent } from "./components/MailChimpComponents";
import { default as GoogleTranslateComponent } from "./components/GoogleTranslateComponent";
import { default as PayPalProductComponent } from "./components/PayPalProductComponent";
import { default as StripeProductComponent } from "./components/StripeProductComponent";
import { default as FlutterWaveProductComponent } from "./components/FlutterWaveProductComponent";

import { default as twitterBlock } from "./blocks/twitterBlock";
import { default as facebookBlock } from "./blocks/facebookBlock";
import { default as tawToBlock } from "./blocks/tawkToBlock";
import { default as fbPixelBlock } from "./blocks/facebookPixel";
import { default as jiraSupportBlock } from "./blocks/JirasSupportBlock";
import { default as hotJarBlock } from "./blocks/hotJarBlock";
import { default as IntercomBlock } from "./blocks/IntercomBlock";
import { default as MailChimpPopupBlock } from "./blocks/MailChimpBlocks";
import { default as MailChimpEmbeddedBlock } from "./blocks/MailChimpBlocks";
import { default as GoogleTranslateBlock } from "./blocks/GoogleTranslateBlock";
import { default as PayPalProductBlock } from "./blocks/PayPalProductBlock";
import { default as StripeProductBlock } from "./blocks/StripeProductBlock";
import { default as FlutterWaveProductBlock } from "./blocks/FlutterWaveProductBlock";
import { product_div_css } from "./constants";

export default (editor, opts = {}) => {
  // BEGIN PATCH

  /**
   * Fixes grapesjs bug that makes parseStyle in StyleableModel class to be undefined
   */
  const patchDefaultComponent = () => {
    const cm = editor.Components;

    const { model } = cm.getType("default");

    if (model) {
      model.prototype.parseStyle = (str) => {
        var result = {};
        var decls = str.split(";");
        for (var i = 0, len = decls.length; i < len; i++) {
          var decl = decls[i].trim();
          if (!decl) continue;
          var prop = decl.split(":");
          result[prop[0].trim()] = prop.slice(1).join(":").trim();
        }
        return result;
      };
    }
  };

  patchDefaultComponent();

  // END PATCH

  const options = {
    ...{
      //twitter
      profile_url: "",
      twitterFeedsLabel: "Twitter Feeds",
      twitterFeedsCategory: "Social",
      twitterFeedsBlock: {},
      twitterComponent: {},

      //facebook
      facebookPostUrl: "",
      facebookPostLabel: "Facebook Post",
      facebookPostCategory: "Social",
      facebookPostBlock: {},
      facebookPostComponent: {},

      //tawTo
      tawToLabel: "TawkTo Widget",
      tawToCategory: "Integrations",
      tawToBlock: {},
      tawToComponent: {},

      //facebook pixel
      fbPixelBlock: {},
      fbPixelComponent: {},

      //JiraSupportDesk
      data_key: "",
      database_url: "",
      jira_src: "",
      jiraSupportLabel: "Jira Support Desk",
      jiraSupportCategory: "Integrations",
      jiraSupportBlock: {},
      jiraSupportComponent: {},

      //hotjar
      hot_jar_api_key: "",
      hotJarLabel: "Hotjar Heatmaps",
      hotJarCategory: "Integrations",
      hotJarBlock: {},
      hotJarComponent: {},

      //Intercom
      intercom_app_id: "",
      IntercomLabel: "Intercom Integration",
      IntercomCategory: "Integrations",
      IntercomBlock: {},
      IntercomComponent: {},

      //mailChimp
      mail_chimp_url: "",
      MailChimpEmbeddedLabel: "Embedded Form",
      MailChimpPopupLabel: "Pop Up",
      MailChimpCategory: "MailChimp",
      MailChimpEmbeddedBlock: {},
      MailChimpPopupBlock: {},

      //GoogleTranslate
      GoogleTranslateLabel: "Google Translate",
      GoogleTranslateCategory: "Google",
      GoogleTranslateBlock: {},
      GoogleTranslateComponent: {},

      //paypal product
      PayPalProductBlockLabel: "PayPal",
      PayPalProductBlockCategory: "Sell Online",
      PayPalProductBlock: {},
      PayPalProductBlockComponent: {},

      //stripe block
      StripeProductBlock: {},
      StripeProductBlockComponent: {},

      //flutterwave block
      FlutterWaveProductBlock: {},
      FlutterWaveProductBlockComponent: {},
    },
    ...opts,
  };

  //  components
  twitterComponent(editor, options);
  facebookComponent(editor, options);
  tawToComponent(editor, options);
  fbPixelComponent(editor, options);
  jiraSupportComponent(editor, options);
  hotJarComponent(editor, options);
  IntercomComponent(editor, options);
  MailChimpEmbeddedComponent(editor, options);
  MailChimpPopUpComponent(editor, options);
  GoogleTranslateComponent(editor, options);
  PayPalProductComponent(editor, options);
  StripeProductComponent(editor, options);
  FlutterWaveProductComponent(editor, options);

  //  blocks
  twitterBlock(editor, options);
  facebookBlock(editor, options);
  tawToBlock(editor, options);
  fbPixelBlock(editor, options);
  jiraSupportBlock(editor, options);
  hotJarBlock(editor, options);
  IntercomBlock(editor, options);
  MailChimpEmbeddedBlock(editor, options);
  MailChimpPopupBlock(editor, options);
  GoogleTranslateBlock(editor, options);
  PayPalProductBlock(editor, options);
  StripeProductBlock(editor, options);
  FlutterWaveProductBlock(editor, options);
};
