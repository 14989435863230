import { API_BASE_URL, WS_BASE_URL } from "../core/constants/Endpoints";
import { PATH_MAIN_ROUTE } from "../core/constants/Routes";

/**
 * API Endpoints
 */
export const URL_CMS_CONTENT: string = `${API_BASE_URL}/cms/generate-content/`;
export const URL_TEMPLATE_CONTENT: string = `${API_BASE_URL}/cms/generate-template`;
export const URL_PRIVACY_POLICY_TEMPLATE: string = `${URL_TEMPLATE_CONTENT}/privacy-policy/`;
export const URL_STREAM_CONTENT: string = `${WS_BASE_URL}/cms/stream-content/`;
/**
 * Routes
 */
export const PATH_CMS: string = `${PATH_MAIN_ROUTE}/cms`;
export const PATH_GENERATE_CONTENT: string = `${PATH_CMS}/generate-content`;
export const PATH_GENERATE_TEMPLATE: string = `${PATH_CMS}/generate-template/`;
/**
 * Constants
 */
