import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationActions } from "../../account/AccountActions";
import { UserApi } from "../../account/AccountAPIs";
import { ErrorModal } from "../../core/components/ErrorModal";
import MainNavigationSidebar from "../../core/components/MainNavigationSidebar";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import PageLoadingIndicator from "../../website/components/PageLoadingIndicator";
import AffiliateInformation from "../components/AffiliateInformation";

function Referrals() {
  const authenticationActions: any = new AuthenticationActions();
  const user: any = authenticationActions.retrieveAuthenticatedUser();

  const [loading_affiliate, setIsLoadingAffiliate] = useState(true);
  const [affiliate, setAffiliate] = useState([]);
  const [error, setError] = useState(null);

  function loadAffiliateInformation() {
    setIsLoadingAffiliate(true);
    const userApi: any = new UserApi();
    userApi.retrieveAffiliateDetails((is_successful, affiliate_or_error) => {
      setIsLoadingAffiliate(false);
      if (is_successful) {
        setAffiliate(affiliate_or_error);
      } else {
        setError(affiliate_or_error);
      }
    });
  }

  useEffect(loadAffiliateInformation, []);

  if (!loading_affiliate && !user) {
    loadAffiliateInformation();
    return <PageLoadingIndicator />;
  }
  if (loading_affiliate) {
    return <PageLoadingIndicator />;
  }

  const closeErrorModal: any = () => setError(null);
  const close_modal_button: any = (
    <button
      type="button"
      className="btn btn-olitt-grey px-4"
      onClick={closeErrorModal}
    >
      Close
    </button>
  );
  return (
    <SidebarWrapper sidebar={<MainNavigationSidebar />}>
      <AffiliateInformation user={user} affiliate={affiliate} />
      <ErrorModal
        show={error !== null}
        onClose={closeErrorModal}
        positive_button={close_modal_button}
        short_desc={"Something didn't go quite right."}
        suggestion={error || ""}
      />
    </SidebarWrapper>
  );
}

export default withRouter(Referrals);
