import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import { openModal } from "modules/base/utilities/Actions";
import CreateCampaignModal from "modules/marketing/components/campaigns/CreateCampaignModal";
import EditCampaignModal from "modules/marketing/components/campaigns/EditCampaignModal";
import publishUnpublishActionColumn from "modules/marketing/components/PublishUnpublishActionColumn";
import { campaignsColumns } from "modules/marketing/components/tableColumns";
import MarketingAPI from "modules/marketing/MarketingAPI";

function CampaignList(props) {
  const { match } = props;
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const [error, setError] = useState(null);
  const [paginationParams, setPaginationParams] = useState({
    start: 0,
    limit: 10,
  });
  const params = useMemo(
    () => ({
      ...paginationParams,
      orderBy: "dateAdded",
      orderByDir: "desc",
    }),
    [paginationParams],
  );
  const {
    campaigns,
    total,
    error: campaignLoadingError,
    isLoading,
  } = API.getCampaigns(siteId, params);

  const onClickCreate = useCallback(() => {
    openModal("create-campaign-modal");
  });

  const onClickEdit = useCallback((campaign) => {
    openModal(`edit-campaign-${campaign.id}-modal`);
  });

  const onClickDelete = useCallback((id, onSuccess, onError) => {
    API.deleteCampaign(
      id,
      siteId,
      () => {
        onSuccess();
      },
      (error_response) => {
        onError("Unable to delete campaign", error_response.message);
      },
    );
  });

  const handlePaginationChange = useCallback((newPaginationParams) => {
    setPaginationParams(newPaginationParams);
  }, []);
  const columns = [
    ...campaignsColumns,
    publishUnpublishActionColumn(siteId, setError, "updateCampaign"),
  ];

  return (
    <>
      <CreateCampaignModal />
      <NetworkMessageDisplay error={error || campaignLoadingError} />
      <DataTable
        columns={columns}
        data={campaigns}
        total={total}
        objectType="campaign"
        createButtonColor="gray-900"
        allowCreate
        allowEdit
        allowDelete
        onClickCreate={onClickCreate}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        EditModal={EditCampaignModal}
        isLoading={isLoading}
        onPaginationChange={handlePaginationChange}
        isServerSidePagination
      />
    </>
  );
}

CampaignList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(CampaignList);
