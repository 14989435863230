import { isString } from "underscore";
import {
  btn_css,
  currencies,
  product_div_css,
  product_h3_css,
  product_h6_css,
  product_img_css,
  product_p_css,
} from "../constants";

export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const tm = editor.Panels.getButton("views", "open-tm");
  const {
    store_name,
    store_desc,
    store_logo_url,
    product_name = "Product Name",
    product_price = "00.00",
    product_description = "Description",
    product_image_url,
    price_currency = "USD",
    currency_position = "left",
    public_key,
    FlutterWaveProductBlockComponent,
  } = opts;
  const new_currencies = [
    { value: "KES", name: "Kenya Shillings" },
    { value: "UGX", name: "Uganda Shillings" },
    { value: "TZS", name: "Tanzania Shillings" },
  ];
  const rave_currencies = new_currencies.concat(currencies);

  domc.addType("FLUTTER-WAVE-PRODUCT-BLOCK-COMPONENT", {
    model: {
      defaults: {
        tagName: "div",
        name: "FlutterWave Product Block",
        draggable: "[data-gjs-type=wrapper]",
        droppable: false,
        stylable: true,
        copyable: false,
        resizable: true,
        store_name,
        store_desc,
        store_logo_url,
        product_name,
        product_price,
        product_description,
        product_image_url,
        price_currency,
        currency_position,
        public_key,
        traits: [
          {
            name: "store_name",
            label: "Store Name",
            placeholder: "Store name",
            changeProp: 1,
          },
          {
            name: "store_desc",
            label: "Store Description",
            placeholder: "Store description",
            changeProp: 1,
          },
          {
            name: "store_logo_url",
            label: "Store Logo URL",
            placeholder: "Store logo url",
            changeProp: 1,
            attributes: { style: "display: none" },
          },
          {
            type: "button",
            text: `<img  id="store_img" width='20' src='' alt=''> Select Image`,
            label: "Store Logo",
            full: true,
            command: (editor) =>
              editor.runCommand("open-assets", {
                target: this,
                types: ["image"],
                accept: "image/*",
                onSelect: (asset) => {
                  editor.Modal.close();
                  const url = isString(asset) ? asset : asset.get("src");
                  const component = editor.getSelected();
                  component.updateTrait("store_logo_url", {
                    value: url,
                  });
                },
              }),
          },
          {
            name: "product_name",
            label: "Product Name",
            placeholder: "Product name",
            changeProp: 1,
          },
          {
            name: "product_description",
            label: "Product Description",
            placeholder: "Product description",
            changeProp: 1,
          },
          {
            name: "product_image_url",
            label: "Product Image URL",
            placeholder: "Product image url.",
            changeProp: 1,
            attributes: { style: "display: none" },
          },
          {
            type: "button",
            text: "Select Image",
            label: "Product Image",
            full: true,
            command: (editor) =>
              editor.runCommand("open-assets", {
                target: this,
                types: ["image"],
                accept: "image/*",
                onSelect: (asset) => {
                  editor.Modal.close();
                  const url = isString(asset) ? asset : asset.get("src");
                  const component = editor.getSelected();
                  component.updateTrait("product_image_url", {
                    value: url,
                  });
                },
              }),
          },
          {
            name: "product_price",
            label: "Product Price",
            placeholder: "Product price",
            changeProp: 1,
          },
          {
            type: "select",
            name: "price_currency",
            label: "Currency",
            changeProp: 1,
            options: rave_currencies,
          },
          {
            type: "select",
            name: "currency_position",
            label: "Currency Position",
            changeProp: 1,
            options: [
              { value: "left", name: "left" },
              { value: "right", name: "right" },
            ],
          },
          {
            name: "public_key",
            label: "FlutterWave Publishable Key",
            placeholder: "Publishable Key",
            changeProp: 1,
          },
        ],
      },
      init() {
        this.setComponent();
        this.on(
          "change:product_price change:store_name change:store_desc change:store_logo_url change:product_name change:product_description" +
            " change:price_currency change:public_key change:currency_position change:product_image_url",
          () => valuesOnchange()
        );
        const valuesOnchange = () => {
          this.setComponent();
        };
        tm.set("active", 1);
      },
      setComponent() {
        const product_name = this.get("product_name");
        const product_image_url = this.get("product_image_url")
          ? this.get("product_image_url")
          : "https://source.unsplash.com/random/?food";
        const product_price = this.get("product_price");
        const public_key = this.get("public_key");
        const store_logo_url = this.get("store_logo_url");
        const uniq_id = this.getId();
        const btn_id = uniq_id + product_name;
        const flutter_wave_Link = `https://checkout.flutterwave.com/v3.js`;
        const input_styles = `text-align:center;font-size: 16px;margin:15px 0;font-family:inherit;padding:0.25em 0.5em;background-color:#fff;border:2px solid var(--input-border);border-radius:4px;`;
        const form_html = `<div id="${uniq_id}-wave-form" style="display: none"> <input type="text" id="customer_name" name="customer_name" value=""  placeholder="Enter your name" style="${input_styles}"/><input type="email" name="customer_email"  id="customer_email" value="" placeholder="Enter your email"  style="${input_styles}"/> <div style="${btn_css}" onclick="flutterWavePayment${uniq_id}()">PROCEED</div><span id="${uniq_id}-rave-error"></span> </div>`;
        const flutter_wave_append_script = `if (!document.getElementById('flutter_wave_script')) {
                                const script = document.createElement('script');
                                  script.setAttribute('src', "${flutter_wave_Link}");
                                  script.setAttribute('id', "flutter_wave_script");
                                  document.head.appendChild(script);                                               
                                   }`;
        const flutter_wave_script = `<script type="text/javascript">${flutter_wave_append_script}</script>`;

        const currency_position = this.get("currency_position");
        let right = "";
        let left = "";
        if (currency_position === "right") {
          right = this.get("price_currency");
        } else {
          left = this.get("price_currency");
        }
        const error_html = `<div class="alert alert-danger" role="alert" style="color: red;">Please fill in your name and email</div>`;
        const btn_script = `function showWaveForm${uniq_id}(){document.getElementById('${btn_id}').style.display = 'none';document.getElementById('${uniq_id}-wave-form').style.display = 'inline-block'}function flutterWavePayment${uniq_id}(){const name=document.getElementById("customer_name").value;const email=document.getElementById("customer_email").value;if(name==null||name=="",email==null||email==""){document.getElementById("${uniq_id}-rave-error").innerHTML='${error_html}';}else{const url=window.location.href;FlutterwaveCheckout({public_key:"${public_key}",tx_ref:Math.random().toString(36).substr(2,5),amount:${product_price},currency:"${this.get(
          "price_currency"
        )}",payment_options:"card",redirect_url:"+"+window.location.href+"",customer:{email:email,name:name,},callback:function(data){alert(data);},onclose:function(){location.href=url;},customizations:{title:"${this.get(
          "store_name"
        )}",description:"${this.get("store_desc")}",logo:"${this.get(
          "store_logo_url"
        )}",},});}}`;
        const flutter_wave_order_script = `<script type="text/javascript">${btn_script}</script>`;
        const new_component = `${flutter_wave_script}<img style="${product_img_css}" src="${product_image_url}" alt=""><h3 style="${product_h3_css}">${this.get(
          "product_name"
        )}</h3><p style="${product_p_css}">${this.get(
          "product_description"
        )}</p><h6 style="${product_h6_css}">${left} ${this.get(
          "product_price"
        )} ${right}</h6><div style="${btn_css}" onclick="showWaveForm${uniq_id}(this)" id="${btn_id}">Checkout</div> ${form_html} ${flutter_wave_order_script}`;
        this.set("content", new_component);
        this.setStyle(product_div_css);
        return new_component;
      },
      ...FlutterWaveProductBlockComponent,
    },
    view: {},
  });
};
