import PropTypes from "prop-types";
import { memo, useCallback, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import Pagination from "modules/base/components/table/Pagination";
import { closeModalById, openModal } from "modules/base/utilities/Actions";
import TemplatesList from "modules/marketing/components/Focusitems/TemplatesList";
import MarketingAPI from "modules/marketing/MarketingAPI";

const ItemsPerPage = 10;

const PrebuiltTemplates = memo(
  ({ setPrebuiltHtml, uuid, currentModalId, match }) => {
    const { id: siteId } = match.params;
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(ItemsPerPage);

    const API = useMemo(() => new MarketingAPI(siteId), [siteId]);

    const offset = currentPage * pageSize;

    const {
      prebuiltFocusItemsTemplates: templates,
      total,
      error,
      isLoading: isLoadingTemplates,
    } = API.getPrebuiltFocusItemsTemplates({
      offset,
      limit: pageSize,
      paginate: true,
    });

    const onSelectTemplate = useCallback(
      (template) => {
        const startPage = template.pages.find((page) => page.is_start_page);
        setPrebuiltHtml(startPage.page_content);
        closeModalById(currentModalId);
        openModal(
          `design-focus-items-template-${uuid ?? "new"}-modal`,
          "create-focus-item-modal",
        );
      },
      [setPrebuiltHtml, currentModalId, uuid],
    );

    if (isLoadingTemplates) {
      return <NetworkActivityIndicator key="loading" />;
    }

    return (
      <div>
        <NetworkMessageDisplay error={error} />
        <TemplatesList
          templates={templates}
          onSelectTemplate={onSelectTemplate}
        />
        <Pagination
          onPageChange={setCurrentPage}
          onPageSizeChange={setPageSize}
          total={total}
          pageIndex={currentPage}
          pageSize={pageSize}
          isServerSidePagination
        />
      </div>
    );
  },
);

PrebuiltTemplates.propTypes = {
  setPrebuiltHtml: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  currentModalId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(PrebuiltTemplates);
