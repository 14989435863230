import PropTypes from "prop-types";
import BaseWalletTransactions from "modules/base/components/billing/BaseWalletTransactions";
import { BillingWallet } from "modules/base/models/Billing";
import { BillingAPI } from "modules/billing/BillingAPIs";
import WalletTopUp from "modules/billing/components/WalletTopUp";

function WalletTransactions({ wallet, currentModalId }) {
  const billingAPI = new BillingAPI();
  const { transactions, error, isLoading } =
    billingAPI.getBillingWalletTransactions("i");
  return (
    <div className="d-flex flex-column">
      <div className="align-self-end">
        <WalletTopUp wallet={wallet} currentModalId={currentModalId} />
      </div>
      <BaseWalletTransactions
        transactions={transactions}
        isLoading={isLoading}
        error={error}
        wallet={wallet}
      />
    </div>
  );
}

WalletTransactions.propTypes = {
  wallet: PropTypes.instanceOf(BillingWallet).isRequired,
  currentModalId: PropTypes.string.isRequired,
};

export default WalletTransactions;
