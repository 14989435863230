import PropTypes from "prop-types";
import { memo, useState } from "react";
import { Handle, Position } from "reactflow";
import ConfigureButton from "modules/marketing/components/campaigns/nodes/configure/ConfigureButton";
import DeleteButton from "modules/marketing/components/campaigns/nodes/configure/DeleteButton";
import TargetHandle from "modules/marketing/components/campaigns/nodes/TargetHandle";
import { CAMPAIGN_ACTION_LINE_COLOR } from "modules/marketing/MarketingConstants";
import { CampaignEvent } from "modules/marketing/MarketingModels";

const DEFAULT_HANDLE_STYLE = {
  width: 10,
  height: 10,
  bottom: -5,
};

const ActionNode = memo(function ActionNode({ data, isConnectable }) {
  const {
    campaignEvent,
    currentModalId,
    updateNode,
    previouslyNewNode,
    updateEvent,
  } = data;
  const [state, setState] = useState(campaignEvent);
  return (
    <>
      <ConfigureButton
        campaignEvent={state}
        currentModalId={currentModalId}
        setState={setState}
        previouslyNewNode={previouslyNewNode}
        updateNode={updateNode}
        updateEvent={updateEvent}
      />
      <TargetHandle />
      <div className="badge text-bg-primary p-2">
        <i className="bi bi-activity" /> {state.name}
        <Handle
          type="source"
          id={CAMPAIGN_ACTION_LINE_COLOR}
          position={Position.Bottom}
          style={{ ...DEFAULT_HANDLE_STYLE, left: "50%", background: "blue" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={isConnectable}
        />
      </div>
      <DeleteButton nodeId={campaignEvent.id} />
    </>
  );
});

ActionNode.defaultProps = {
  data: {
    campaignEvent: new CampaignEvent(),
    currentModalId: "",
    previouslyNewNode: false,
    updateNode: () => {
      // do nothing
    },
  },
  isConnectable: true,
};

ActionNode.propTypes = {
  data: PropTypes.shape({
    campaignEvent: PropTypes.instanceOf(CampaignEvent),
    currentModalId: PropTypes.string,
    previouslyNewNode: PropTypes.bool,
    updateNode: PropTypes.func,
    updateEvent: PropTypes.func,
  }),
  isConnectable: PropTypes.bool,
};

export default ActionNode;
