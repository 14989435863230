import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import DataTable from "../../core/components/datagrid/DataTable";
import MainNavigationSidebar from "../../core/components/MainNavigationSidebar";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import { DOMAIN_STATUSES, UI_ROUTES } from "../CloudoonConstants";
import useDomains from "../hooks/useDomains";

export const STATUS_COLOR_MAP = {
  PENDING: "bg-warning",
  READY: "bg-primary",
  ACTIVE: "bg-success",
  SUSPENDED: "bg-danger",
  CANCELLED: "bg-secondary",
  TERMINATED: "bg-danger",
};

function Dashboard(props) {
  const { history } = props;
  const { data, is_loading, is_refetching, error, is_error } = useDomains();

  const onCreateDomain = () => {
    history.push(UI_ROUTES.SETUP.ROOT);
  };

  const onFinishSetup = (domain) => {
    history.push(UI_ROUTES.SETUP.DOMAIN.replace(":domain_name", domain));
  };

  const onManageAccount = (domain) => {
    history.push(UI_ROUTES.DOMAINS.DETAIL.replace(":domain_name", domain));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Domain",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "status",
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          // eslint-disable-next-line react/prop-types
          const { status } = row.original;
          const badgeClasses = classNames(
            "badge rounded-pill",
            // eslint-disable-next-line react/prop-types
            STATUS_COLOR_MAP[status.toUpperCase()],
          );

          return <span className={badgeClasses}>{status}</span>;
        },
      },
      {
        Header: "Actions",
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          // eslint-disable-next-line react/prop-types
          const { name, status } = row.original;

          if (
            [DOMAIN_STATUSES.PENDING, DOMAIN_STATUSES.READY].includes(status)
          ) {
            return (
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-warning rounded-pill"
                  onClick={() => onFinishSetup(name)}
                >
                  Finish Setup
                </button>
              </div>
            );
          }

          return (
            <div>
              <button
                type="button"
                className="btn btn-sm btn-primary rounded-pill"
                onClick={() => onManageAccount(name)}
              >
                Manage
              </button>
            </div>
          );
        },
      },
    ],
    [],
  );

  if (is_loading || is_refetching) {
    return (
      <SidebarWrapper sidebar={<MainNavigationSidebar />}>
        Loading...
      </SidebarWrapper>
    );
  }

  if (is_error) {
    return (
      <SidebarWrapper sidebar={<MainNavigationSidebar />}>
        {error.detail ?? "something went wrong"}
      </SidebarWrapper>
    );
  }

  if (!data || !data.length === 0) {
    return history.push(UI_ROUTES.DOMAINS.ROOT);
  }

  return (
    <SidebarWrapper sidebar={<MainNavigationSidebar />}>
      <div className="card">
        <div className="card-body">
          <h1>Dashboard</h1>
          <DataTable
            columns={columns}
            data={data}
            rowSize={10}
            onClickCreate={onCreateDomain}
            isDataCreatable
          />
        </div>
      </div>
    </SidebarWrapper>
  );
}

Dashboard.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default Dashboard;
