import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import SelectInput from "modules/base/components/inputs/SelectInput";
import TextInput from "modules/base/components/inputs/TextInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import {
  closeModalById,
  handleInputChange,
} from "modules/base/utilities/Actions";
import ConfigureNodeForm from "modules/marketing/components/campaigns/nodes/configure/ConfigureNodeForm";
import ExecutionTiming from "modules/marketing/components/campaigns/nodes/configure/ExecutionTiming";
import MarketingAPI from "modules/marketing/MarketingAPI";

function ChangeCampaigns({
  campaignEvent,
  setState,
  match,
  currentModalId,
  updateEvent,
}) {
  const [selectedAddCampaignsIds, setSelectedAddCampaignsIds] = useState(
    campaignEvent.properties.addTo ?? [],
  );
  const [selectedRemoveCampaignsIds, setSelectedRemoveCampaignsIds] = useState(
    campaignEvent.properties.removeFrom ?? [],
  );
  const [campaignEventState, setCampaignEventState] = useState(campaignEvent);
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const { campaigns, error, isLoading } = API.getCampaigns(siteId);
  const onInputChange = useCallback((e) => {
    handleInputChange(
      e.target.name,
      e.target.value,
      campaignEventState,
      setCampaignEventState,
    );
  });
  const onAddToChange = useCallback((selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    setSelectedAddCampaignsIds(selectedIds);
    handleInputChange(
      "properties",
      {
        ...campaignEventState.properties,
        addTo: selectedIds,
      },
      campaignEventState,
      setCampaignEventState,
    );
  });
  const removeFromChange = useCallback((selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    setSelectedRemoveCampaignsIds(selectedIds);
    handleInputChange(
      "properties",
      {
        ...campaignEventState.properties,
        removeFrom: selectedIds,
      },
      campaignEventState,
      setCampaignEventState,
    );
  });
  const onClickConfigure = useCallback(() => {
    setState({ ...campaignEventState });
    closeModalById(currentModalId);
  });
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  const campaignsOptions = campaigns.map((campaign) => ({
    label: campaign.name,
    value: campaign.id.toString(),
  }));
  campaignsOptions.unshift({ label: "This campaign", value: "this" });
  return (
    <ConfigureNodeForm
      campaignEvent={campaignEvent}
      campaignEventState={campaignEventState}
      handleSubmit={onClickConfigure}
      updateEvent={updateEvent}
    >
      <NetworkMessageDisplay error={error} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <TextInput
          name="name"
          title="Name"
          content={campaignEventState.name ?? ""}
          controlFunc={onInputChange}
          required
        />
        <SelectInput
          name="addTo"
          title="Add to"
          options={campaignsOptions}
          onChange={onAddToChange}
          predicate={(option) => selectedAddCampaignsIds.includes(option.value)}
          isMulti
        />
        <SelectInput
          name="removeFrom"
          title="Remove from"
          options={campaignsOptions}
          onChange={removeFromChange}
          predicate={(option) =>
            selectedRemoveCampaignsIds.includes(option.value)
          }
          isMulti
        />
        <ExecutionTiming
          campaignEvent={campaignEventState}
          setState={setCampaignEventState}
        />
      </div>
    </ConfigureNodeForm>
  );
}

ChangeCampaigns.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  campaignEvent: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  currentModalId: PropTypes.string.isRequired,
  updateEvent: PropTypes.func.isRequired,
};

export default withRouter(ChangeCampaigns);
