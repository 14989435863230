import PropTypes from "prop-types";
import { createContext, useContext, useState } from "react";

const TemplateTypeContext = createContext();

export const TemplateTypeProvider = ({ children }) => {
  const [templateType, setTemplateType] = useState(null);

  return (
    <TemplateTypeContext.Provider value={{ templateType, setTemplateType }}>
      {children}
    </TemplateTypeContext.Provider>
  );
};

TemplateTypeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTemplateType = () => {
  const context = useContext(TemplateTypeContext);
  if (!context) {
    throw new Error(
      "useTemplateType must be used within a TemplateTypeProvider",
    );
  }
  return context;
};
