import PropTypes from "prop-types";
import React from "react";

/**
 * @name StepSection
 * Renders a section of the Facebook step.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function StepSection(props) {
  const { id, name, description, step_items } = props;
  const accordion_id: any = step_items.length > 0 ? id : "";

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="flush-headingOne">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${id}`}
          aria-expanded="false"
          aria-controls="flush-collapseOne"
        >
          {name}
        </button>
      </h2>
      <div
        id={accordion_id}
        className="accordion-collapse collapse"
        aria-labelledby="flush-headingOne"
        data-bs-parent={`#${id}`}
      >
        <div className="accordion-body">
          <div>{description}</div>
          <div>{step_items}</div>
        </div>
      </div>
    </div>
  );
}

StepSection.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  step_items: PropTypes.instanceOf(Array).isRequired,
};

export default StepSection;
