import PropTypes from "prop-types";
import { useCallback } from "react";
import Form from "modules/base/components/Form";
import TextInput from "modules/base/components/inputs/TextInput";
import { closeModalById } from "modules/base/utilities/Actions";

function ConfigureSubmitButton(props) {
  const { field, setState } = props;
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    closeModalById(`configure-submit-button-${field.id}-modal`);
  });
  const onInputChange = useCallback(
    (e) => {
      const { name } = e.target;
      const { value } = e.target;
      setState((prevState) => ({
        ...prevState,
        fields: prevState.fields.map((f) => {
          if (f.id === field.id) {
            return {
              ...f,
              [name]: value,
            };
          }
          return f;
        }),
      }));
    },
    [setState],
  );
  return (
    <Form
      handleSubmit={handleSubmit}
      id={`form-field-${field.id}`}
      button_color="gray-900"
      button_label="Configure"
      fullLengthButton
      isSubmitting={false}
      use_as="div"
      padding="p-0"
    >
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <TextInput
          name="label"
          title="Label"
          content={field.label}
          controlFunc={onInputChange}
          required
        />
      </div>
    </Form>
  );
}

ConfigureSubmitButton.propTypes = {
  field: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
};

export default ConfigureSubmitButton;
