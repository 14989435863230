import React from "react";

export default class ComponentLoadingIndicator extends React.Component {
  render() {
    const style: { width: string, height: string } = {
      width: "2rem",
      height: "2rem",
    };

    return (
      <div className="d-flex flex-column h-100 justify-content-center align-items-center">
        <div
          className="spinner-border text-dark-blue-1"
          style={style}
          role="status"
        >
          <span className="sr-only">Processing...</span>
        </div>
        <span className="font-weight-bold mt-4">{this.props.text}</span>
      </div>
    );
  }
}
