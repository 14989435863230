import PropTypes from "prop-types";

const TableDisplay = ({ tableData, tableHeaders }) => (
  <table className="table table-striped min-vh-49">
    <thead>
      <tr>
        {tableHeaders.map((header, index) => (
          <th key={index}>{header}</th>
        ))}
      </tr>
    </thead>
    <tbody className="h-100 min-vh-49">
      {tableData.length > 0 ? (
        tableData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>{cell}</td>
            ))}
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={tableHeaders.length} className="text-center">
            No data available
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

TableDisplay.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TableDisplay;
