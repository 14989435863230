import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import ChangeSegments from "modules/marketing/components/contacts/ChangeSegments";

function ChangeSegmentsModal({ selectedContactsIds }) {
  const modalId = "change-segments-modal";
  return (
    <Modal modal_title="Change Segments" modal_id={modalId} size="md">
      <ChangeSegments
        selectedContactsIds={selectedContactsIds}
        currentModalId={modalId}
      />
    </Modal>
  );
}

ChangeSegmentsModal.propTypes = {
  selectedContactsIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
};

export default ChangeSegmentsModal;
