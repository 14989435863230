import { computePosition, offset, shift } from "@floating-ui/dom";
import { View } from "backbone";
import { getEditorDivRect } from "modules/core/components/editor/utilities";

export default class ContextMenuView extends View {
  className = "gjs-context-menu";

  events = {
    click: "onClick",
    "click [data-close-modal]": "hide",
  };

  constructor(opts) {
    super(opts);
    this.listenTo(this.model, "change:open", this.updateOpen);
    this.listenTo(this.model, "change:content", this.updateContent);
  }

  getContent() {
    if (!this.$content) {
      this.$content = this.$el.find(`.content`);
    }
    return this.$content;
  }

  updateOpen() {
    this.el.style.display = this.model.get("open") ? "" : "none";
  }

  updateContent() {
    const content = this.getContent();
    const body = this.model.get("content");
    content.empty().append(body);
  }

  applyStyles = ({ x = 0, y = 0 }) => {
    this.$el.css({ top: `${y}px`, left: `${x}px` });
  };

  virtualEl = (position) => {
    const { x, y } = position;
    return {
      getBoundingClientRect() {
        return {
          width: 0,
          height: 0,
          x,
          y,
          top: y,
          left: x,
          right: x,
          bottom: y,
        };
      },
    };
  };

  updateAttr(attr) {
    const { $el, el } = this;

    const currAttr = Array.from(el.attributes).map((i) => i.name);
    $el.removeAttr(currAttr.join(" "));
    $el.attr({
      ...(attr || {}),
      class: `${"ctx-"}container ${(attr && attr.class) || ""}`.trim(),
    });

    const position = this.model.get("position");

    if (position) {
      const editorDivRect = getEditorDivRect();
      const editorDivTop = editorDivRect.top;
      const editorDivLeft = editorDivRect.left;
      computePosition(this.virtualEl(position), this.el, {
        placement: "right-start",
        middleware: [
          shift({
            mainAxis: true,
            crossAxis: true,
          }),
          offset({
            crossAxis: 40 + editorDivTop,
            mainAxis: 40 + editorDivLeft,
          }),
        ],
      }).then(this.applyStyles);
    }
  }

  hide() {
    this.model.close();
  }

  template = ({ content }) => {
    return `<div class="context-menu">
              <div class="content">${content}</div>
            </div>
            `;
  };

  render() {
    this.$el.html(this.template(this.model.toJSON()));
    this.updateOpen();

    return this;
  }
}
