import PropTypes from "prop-types";
import React from "react";
import { User } from "../../account/AccountModels";
import { Icon } from "../../base/components/Icons";
import { Invoice } from "../BillingModels";

export default function InvoiceCard({
  user,
  invoice,
  is_expanded,
  onExpand,
  handleInvoicePayUrl,
}) {
  const makeInvoicePayment: any = () => {
    handleInvoicePayUrl(invoice.transactions[0].pay_url);
  };

  let wrapper_classes: string = "w-100 card mb-4";
  let header_classes: string =
    "card-header d-flex align-items-center border-bottom-0 bg-white py-4";
  let expand_btn_classes: string =
    "btn btn-outline-olitt-grey shadow-none ms-4";
  let expand_btn_icon: string = "chevron-down";
  let collapse_row_classes: string = "row card-body pt-3 collapse";
  if (is_expanded) {
    wrapper_classes = `${wrapper_classes} shadow`;
    header_classes = header_classes.replace(
      "bg-white",
      "bg-olitt-grey text-white shadow-sm",
    );
    expand_btn_classes = `${expand_btn_classes} text-white border-white`;
    expand_btn_icon = "chevron-up";
    collapse_row_classes = "row card-body pt-3 collapse show";
  }

  const { invoice_entries } = invoice;

  let invoice_item_classes: string = "col-md-auto my-1";
  const { description } = invoice_entries;
  if (!description) {
    invoice_item_classes = invoice_item_classes.replace(
      "col-md-auto",
      "col-md-12",
    );
  }
  const pending: any = invoice.state === "issued";
  const btn_disabled: any = invoice.total <= 0 || !pending;
  const display_status: any =
    invoice.total <= 0 ? "free" : pending ? "not paid" : invoice.state;
  const pay_btn_text: any =
    pending && invoice.total > 0 ? "Pay Invoice" : display_status;

  let invoice_entries_cards: null[] = [];
  if (invoice_entries) {
    invoice_entries_cards = invoice_entries.map((entry, index) => {
      return (
        <div className="row ms-1" key={entry.id}>
          <div className="col-md-6">
            <strong className="text-muted d-block mb-2">Description</strong>
            <span className="h5 font-weight-light d-block mb-2">
              {entry.description}
            </span>
          </div>

          <div className="col-md-3">
            <strong className="text-muted d-block mb-2">Unit Price</strong>
            <span className="h5 font-weight-light d-block mb-2">
              {entry.unit_price}
            </span>
          </div>
          <div className="col-md-3">
            <strong className="text-muted d-block mb-2">Quantity</strong>
            <span className="h5 font-weight-light d-block mb-2">
              {Math.trunc(entry.quantity)}
            </span>
          </div>
        </div>
      );
    });
  }

  // ToDo: Manage collapse status as in DomainSuggestionCard
  return (
    <div className={wrapper_classes}>
      <div className={header_classes}>
        <div className="flex-grow-1 d-flex align-items-center justify-content-between">
          <strong className="d-none d-md-inline">
            Invoice {invoice.series} {invoice.number}
          </strong>
          <button
            type="button"
            className="btn btn-olitt-grey d-none d-md-inline"
            disabled={btn_disabled}
            onClick={makeInvoicePayment}
          >
            {pay_btn_text}
          </button>
        </div>

        <button className={expand_btn_classes} type="button" onClick={onExpand}>
          <Icon icon={expand_btn_icon} />
        </button>
      </div>

      <div
        className={collapse_row_classes}
        id={`id_${invoice.id}`}
        data-parent="#accordion"
      >
        <div className={invoice_item_classes}>
          <div className="card card-body border-0 bg-light mb-2">
            <strong className="text-muted d-block mb-2">
              Invoice Number: {invoice.series}
              {invoice.number}
            </strong>
            <strong className="text-muted d-block mb-2">
              Status: {display_status}
            </strong>
            <strong className="text-muted d-block mb-2">
              Issue Date: {invoice.issue_date}
            </strong>
            <strong className="text-muted d-block mb-2">
              Due Date: {invoice.due_date}
            </strong>
          </div>
        </div>

        <div className="col-md my-1">
          <div className="row">
            {invoice_entries_cards}
            <div className="d-none d-md-block border-left mx-3 my-1" />

            <div className="col-md-auto my-1 d-flex flex-column">
              <strong className="text-muted d-none d-md-inline">
                Total Amount
              </strong>

              <div className="border border-thin my-2 d-md-none d-block" />

              <div className="d-flex justify-content-center">
                <div className="h1 font-weight-semibold text-olitt-grey">
                  <span className="me-2">{invoice.currency}</span>
                  <span>{invoice.total}</span>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-olitt-grey"
                disabled={btn_disabled}
                onClick={makeInvoicePayment}
              >
                {pay_btn_text}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InvoiceCard.propTypes = {
  user: PropTypes.instanceOf(User).isRequired,
  invoice: PropTypes.instanceOf(Invoice).isRequired,
  is_expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  handleInvoicePayUrl: PropTypes.func.isRequired,
};
