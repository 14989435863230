import { stripe_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const {
    StripeProductBlockLabel = "Stripe",
    StripeProductBlockCategory = "Sell Online",
    StripeProductBlock,
  } = opts;

  StripeProductBlock &&
    bm.add("STRIPE-PRODUCT-BLOCK", {
      label: StripeProductBlockLabel,
      activate: true,
      select: true,
      category: StripeProductBlockCategory,
      content: { type: "STRIPE-PRODUCT-BLOCK-COMPONENT" },
      media: stripe_logo,
    });
};
