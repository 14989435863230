import ContactList from "modules/marketing/components/contacts/ContactList";
import { ImportProvider } from "modules/marketing/components/contacts/import/ImportContext";
import SubscriptionCheck from "modules/marketing/layouts/SubscriptionCheck";
import { MARKETING_CONTACTS_SLUG } from "modules/marketing/MarketingConstants";

function Contacts() {
  return (
    <ImportProvider>
      <SubscriptionCheck
        slug={MARKETING_CONTACTS_SLUG}
        component={<ContactList />}
      />
    </ImportProvider>
  );
}

export default Contacts;
