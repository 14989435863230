import PropTypes from "prop-types";
import { useCallback } from "react";
import { openModal } from "modules/base/utilities/Actions";

function BuildCampaignButton({ currentModalId, state }) {
  const onClickBuild = useCallback(() => {
    openModal(
      `build-campaign-${state.id ?? "new"}-modal`,
      currentModalId,
      false,
    );
  });
  return (
    <div>
      <button
        type="button"
        className="btn btn-sm btn-outline-gray-900"
        onClick={onClickBuild}
      >
        <i className="bi bi-brush-fill me-1" />
        Design campaign
      </button>
    </div>
  );
}

BuildCampaignButton.defaultProps = {
  state: null,
};

BuildCampaignButton.propTypes = {
  currentModalId: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object),
};

export default BuildCampaignButton;
