/**
 * @class FacebookPage
 * @description Model for Facebook Page
 */
export class FacebookPage {
  id: number;

  name: string;

  picture: Object;

  picture_url: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.picture = parameters.picture;
    this.picture_url = parameters.picture_url;
  }
}
