import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import ConfigureSegmentFilter from "modules/marketing/components/segments/ConfigureSegmentFilter";
import { ContactSegmentFilter, Field } from "modules/marketing/MarketingModels";

function ConfigureSegmentFilterModal({
  filter,
  setState,
  filterIndex,
  contactFields,
  state,
}) {
  const segmentId = state.id ?? "new";
  return (
    <Modal
      modal_title="Configure Filter"
      modal_id={`configure-segment-${segmentId}-filter-${filterIndex}-modal`}
      size="md"
    >
      <ConfigureSegmentFilter
        filter={filter}
        setState={setState}
        state={state}
        filterIndex={filterIndex}
        contactFields={contactFields}
      />
    </Modal>
  );
}

ConfigureSegmentFilterModal.propTypes = {
  filter: PropTypes.instanceOf(ContactSegmentFilter).isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  filterIndex: PropTypes.number.isRequired,
  contactFields: PropTypes.arrayOf(PropTypes.instanceOf(Field)).isRequired,
};

export default ConfigureSegmentFilterModal;
