import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import SelectInput from "modules/base/components/inputs/SelectInput";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import { closeModalById } from "modules/base/utilities/Actions";
import MarketingAPI from "modules/marketing/MarketingAPI";

function ManageContactStatus({ selectedContactsIds, match, currentModalId }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState("");

  const handleStatusChange = useCallback(
    (selectedOption) => {
      setStatus(selectedOption.value);
    },
    [setStatus],
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      setErrors([]);

      const payload = {};
      const totalContacts = selectedContactsIds.length;
      let successfulCalls = 0;

      const apiCall =
        status === "active"
          ? (id, onSuccess, onError) => API.removeDnc(id, onSuccess, onError)
          : (id, onSuccess, onError) => API.addDnc(id, onSuccess, onError);

      const onSuccess = () => {
        successfulCalls += 1;
        if (successfulCalls === totalContacts) {
          setIsSubmitting(false);
          closeModalById(currentModalId);
          setSuccess({ message: `Status ${status}d successfully` });
        }
      };

      const onError = (error) => {
        setErrors((prevErrors) => [...prevErrors, { message: error.message }]);
        setIsSubmitting(false);
      };

      selectedContactsIds.forEach((id) => {
        apiCall(id, payload, onSuccess, onError);
      });
    },
    [status, selectedContactsIds],
  );

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  const errorMessages = errors.map((error) => (
    <NetworkMessageDisplay key={error.id} error={error.message} />
  ));

  return (
    <Form
      handleSubmit={handleSubmit}
      id="manage-status-form"
      button_color="gray-900"
      button_label="Change Status"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      {errorMessages}
      <SuccessActionIndicator success={success} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <SelectInput
          label="Change Status"
          title="Change Status"
          required
          name="statusToChange"
          options={statusOptions}
          onChange={handleStatusChange}
          predicate={(option) => option.value === status}
        />
      </div>
    </Form>
  );
}

ManageContactStatus.defaultProps = {
  currentModalId: null,
};

ManageContactStatus.propTypes = {
  selectedContactsIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string,
};
export default withRouter(ManageContactStatus);
