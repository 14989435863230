import defaults from "./config";
import ContextMenu from "./model";
import ContextMenuView from "./view";

export class ContextMenuManager {
  constructor(em) {
    this.em = em;
    this.name = "ContextMenu";
    this.config = defaults;

    this.model = new ContextMenu(this);
    this.model.on("change:open", (m, enable) => {
      em.trigger(`context-menu:${enable ? "open" : "close"}`);
    });

    this.em.on("block:drag", () => {
      this.close();
    });
    this.em.on("load", () => {
      this.em
        .get("Canvas")
        .getBody()
        .addEventListener("click", () => {
          this.close();
        });

      this.em
        .get("Canvas")
        .getBody()
        .addEventListener("touchstart", () => {
          this.close();
        });
    });
  }

  postRender(view) {
    const el = view.model.getConfig().el || view.el;
    const res = this.render();
    if (res) {
      el?.appendChild(res);
    }
  }

  setContent(content) {
    this.model.set("content", " ");
    this.model.set("content", content);
    return this;
  }

  setPosition(position) {
    this.model.set("position", position);
    return this;
  }

  open(opts = {}) {
    const attr = opts.attributes || {};
    if (opts.content) this.setContent(opts.content);
    this.model.set("attributes", attr);
    this.model.open();
    this.view?.updateAttr(attr);
    return this;
  }

  close() {
    this.model.close();
    return this;
  }

  render() {
    const el = this.view?.el;
    this.view = new ContextMenuView({
      el,
      model: this.model,
    });
    return this.view?.render().el;
  }

  destroy() {
    throw new Error("Method not implemented.");
  }
}

export default ContextMenuManager;
