import moment from "moment";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import StatCard from "modules/marketing/components/dashboard/StatCard";
import MarketingAPI from "modules/marketing/MarketingAPI";

const calculateDaysBetween = (filterParams) => {
  const start = moment(filterParams.dateFrom);
  const end = moment(filterParams.dateTo);
  const duration = moment.duration(end.diff(start));

  if (duration.asMilliseconds() === 0) {
    return "24 hrs";
  }

  let humanisedDuration = duration.humanize();
  if (humanisedDuration.startsWith("a ")) {
    humanisedDuration = humanisedDuration.replace(/^a\s/, "");
  }
  return humanisedDuration;
};

const calculateNumberOfDays = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, "days");
};

const transformFilterParams = (filterParams, allowedParams) => {
  return Object.keys(filterParams)
    .filter((key) => allowedParams.includes(key))
    .reduce((acc, key) => {
      if (key === "dateFrom" || key === "dateTo") {
        acc[key.toLowerCase().replace("date", "date_")] = filterParams[key];
      } else {
        acc[key] = filterParams[key];
      }
      return acc;
    }, {});
};

const Statistics = ({ match, filterParams }) => {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);

  const modifiedFilterParams = {
    ...filterParams,
    dateTo: moment(filterParams.dateTo).add(1, "day").format("YYYY-MM-DD"),
  };

  const allowedParams = ["dateFrom", "dateTo"];

  const transformedParams = transformFilterParams(
    modifiedFilterParams,
    allowedParams,
  );

  const numberOfDays = calculateNumberOfDays(
    transformedParams.date_from,
    transformedParams.date_to,
  );

  const {
    statistics: emailsData,
    error: emailFetchError,
    isLoading: isEmailsLoading,
  } = API.getEmailStatistics(transformedParams);
  const {
    statistics: contactsData,
    error: contactsFetchError,
    isLoading: isContactsLoading,
  } = API.getContactsStatistics({ days: numberOfDays });
  const {
    formSubmissions: formsData,
    error: formsDataFetchError,
    isLoading: isFormsLoading,
  } = API.getFormsSubmissionsStatistics(transformedParams);
  const submissions = formsData?.stats ?? [];

  const { forms, error: formsError, isLoading: formsLoading } = API.getForms();

  const mappedSubmissions = submissions.map((submission) => {
    const formId = parseInt(submission.form_id, 10);
    const formed = forms?.find((f) => f.id === formId);
    return {
      ...submission,
      formName: formed ? formed.name : "Unknown Form",
    };
  });

  const groupedByForm = mappedSubmissions.reduce((acc, submission) => {
    if (!acc[submission.formName]) {
      acc[submission.formName] = [];
    }
    acc[submission.formName].push(submission);
    return acc;
  }, {});

  const uniqueForms = Object.keys(groupedByForm).map((formName) => {
    return {
      formName,
      submissions: groupedByForm[formName],
      submissionsCount: groupedByForm[formName].length,
    };
  });

  const sortedUniqueForms = uniqueForms.sort(
    (a, b) => b.submissionsCount - a.submissionsCount,
  );

  const top5UniqueForms = sortedUniqueForms.slice(0, 4);

  const emailsDataObj = {
    Total: emailsData?.total ? Number(emailsData.total) : 0,
    Read: emailsData?.total
      ? `${((Number(emailsData.read) / Number(emailsData.total)) * 100).toFixed(
          1,
        )}%`
      : "0%",
    Failed: emailsData?.failed ? Number(emailsData.failed) : 0,
  };

  const formsDataObj = {
    "Form Submissions": formsData?.total ?? 0,
    "Top Submitted Forms": "",
  };

  const contactsDataObj = {
    "New Contacts": contactsData?.new ?? 0,
    "Previous Contacts": contactsData?.previous ?? 0,
    Growth: contactsData?.percentage_growth
      ? `${Number(contactsData.percentage_growth).toFixed(1)} %`
      : "0.0 %",
  };

  const emailsChartData = {
    labels: ["Read", "Ignored", "Failed"],
    datasets: [
      {
        data: [
          emailsData?.read ?? 0,
          (emailsData?.total ?? 0) -
            (emailsData?.read ?? 0) -
            (emailsData?.failed ?? 0),
          emailsData?.failed ?? 0,
        ],
        backgroundColor: ["#3498db", "#f39c12", "#e74c3c"],
      },
    ],
  };

  const contactsGaugeChartData = {
    labels: ["Percentage Growth"],
    datasets: [
      {
        data: [contactsData?.percentage_growth ?? 0],
        backgroundColor: ["#3498db"],
      },
    ],
  };
  const formsChartData = {
    labels: top5UniqueForms.map((form) => form.formName),
    datasets: [
      {
        data: top5UniqueForms.map((form) => form.submissionsCount),
        backgroundColor: [
          "#1abc9c",
          "#3498db",
          "#e74c3c",
          "#f39c12",
          "#9b59b6",
        ],
      },
    ],
  };

  return (
    <div className="d-lg-flex gap-2">
      <StatCard
        title="Emails"
        value={emailsDataObj}
        subtitle={`Statistics for the last ${calculateDaysBetween(
          filterParams,
        )}`}
        iconClass="bi-envelope"
        isLoading={isEmailsLoading}
        error={emailFetchError}
        chartData={emailsChartData}
        chartType="pie"
      />
      <StatCard
        title="Contacts"
        value={contactsDataObj}
        subtitle={`Statistics for the last ${calculateDaysBetween(
          filterParams,
        )}`}
        iconClass="bi-person"
        isLoading={isContactsLoading}
        error={contactsFetchError}
        chartData={contactsGaugeChartData}
        chartType="gauge"
      />
      <StatCard
        title="Forms"
        value={formsDataObj}
        subtitle={`Statistics for the last ${calculateDaysBetween(
          filterParams,
        )}`}
        iconClass="bi-file-earmark-text"
        isLoading={isFormsLoading}
        error={formsDataFetchError}
        chartData={formsChartData}
        chartType="pie"
      />
    </div>
  );
};

Statistics.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  filterParams: PropTypes.shape({
    dateTo: PropTypes.string.isRequired,
    dateFrom: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Statistics);
