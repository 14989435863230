export class CloudoonEmailDomain {
  id: string;

  name: string;

  status: string;

  constructor(parametes) {
    this.id = parametes.id;
    this.name = parametes.name;
    this.status = parametes.status;
  }
}

export class CloudoonEmailAccount {
  name: string;

  hostname: string;

  isEnabled: boolean;

  userCount: number;

  userLimit: number;

  aliasCount: number;

  aliasLimit: number;

  domainAliasCount: number;

  listCount: number;

  listLimit: number;

  size: number;

  maxSize: number;

  fileStorageSize: number;

  sizeMb: number;

  fileStorageSizeMb: number;

  lastChanged: string;

  status: string;

  constructor(parametes) {
    this.name = parametes.name;
    this.hostname = parametes.hostname;
    this.isEnabled = parametes.isEnabled;
    this.userCount = parametes.userCount;
    this.userLimit = parametes.userLimit;
    this.aliasCount = parametes.aliasCount;
    this.aliasLimit = parametes.aliasLimit;
    this.domainAliasCount = parametes.domainAliasCount;
    this.listCount = parametes.listCount;
    this.listLimit = parametes.listLimit;
    this.size = parametes.size;
    this.maxSize = parametes.maxSize;
    this.fileStorageSize = parametes.fileStorageSize;
    this.sizeMb = parametes.sizeMb;
    this.fileStorageSizeMb = parametes.fileStorageSizeMb;
    this.lastChanged = parametes.lastChanged;
    this.status = parametes.status;
  }
}
