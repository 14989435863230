import { twitter_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const { twitterFeedsLabel, twitterFeedsCategory, twitterFeedsBlock } = opts;

  twitterFeedsBlock &&
    bm.add("TWITTER-FEEDS-BLOCK", {
      label: twitterFeedsLabel,
      activate: true,
      select: true,
      category: twitterFeedsCategory,
      content: { type: "TWITTER-FEEDS-COMPONENT" },
      media: twitter_logo,
    });
};
