import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import MainNavigationSidebar from "../../core/components/MainNavigationSidebar";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import { UI_ROUTES } from "../CloudoonConstants";
import CloudoonEmailApi from "../CloudoonEmailApi";

const API = new CloudoonEmailApi();

function ManageAccount(props) {
  const {
    match: {
      params: { domain_name },
    },
    history,
  } = props;

  const [is_delete_domain_loading, setIsDeleteDomainLoading] =
    React.useState(false);
  const [is_delete_account_loading, setIsDeleteAccountLoading] =
    React.useState(false);

  const onDeleteAccount = () => {
    setIsDeleteAccountLoading(true);
    API.deleteDomainAccount(
      domain_name,
      () => {
        setIsDeleteAccountLoading(false);
        toast.success("Account deleted successfully");
        history.push(UI_ROUTES.DOMAINS.ROOT);
      },
      (error) => {
        setIsDeleteAccountLoading(false);
        toast.error(error.detail ?? "Error deleting account");
      },
    );
  };

  const onDeleteDomain = () => {
    setIsDeleteDomainLoading(true);
    API.deleteDomain(
      domain_name,
      () => {
        setIsDeleteDomainLoading(false);
        toast.success("Account deleted successfully");
        history.push(UI_ROUTES.DOMAINS.ROOT);
      },
      (error) => {
        setIsDeleteDomainLoading(false);
        toast.error(error.detail ?? "Error deleting account");
      },
    );
  };

  const delete_account_button_loader = is_delete_account_loading ? (
    <span
      className="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    />
  ) : null;

  const delete_account_button = (
    <button
      type="button"
      className="btn btn-danger d-flex align-items-center gap-1"
      onClick={onDeleteAccount}
      disabled={is_delete_account_loading}
    >
      {delete_account_button_loader}
      <span className="visually-hidden">Loading...</span>
      Delete Account
    </button>
  );

  const delete_domain_button_loader = is_delete_domain_loading ? (
    <span
      className="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    />
  ) : null;

  const delete_domain_button = (
    <button
      type="button"
      className="btn btn-danger d-flex align-items-center gap-1"
      onClick={onDeleteDomain}
      disabled={is_delete_domain_loading}
    >
      {delete_domain_button_loader}
      <span className="visually-hidden">Loading...</span>
      Delete Domain
    </button>
  );

  return (
    <SidebarWrapper sidebar={<MainNavigationSidebar />}>
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <div>
              <h3>
                Manage Account:{" "}
                <span className="text-muted">{domain_name}</span>
              </h3>
            </div>
            <div>
              <div>
                <h4>Danger Zone</h4>
                <p className="text-muted">
                  Deleting a domain will delete all the accounts and emails
                  associated with it. This action cannot be undone.
                </p>
              </div>
              <div className="d-flex gap-2">
                {delete_account_button}
                {delete_domain_button}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SidebarWrapper>
  );
}

ManageAccount.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      domain_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default ManageAccount;
