import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import GoogleDrive from "@uppy/google-drive";
import ScreenCapture from "@uppy/screen-capture";
import Webcam from "@uppy/webcam";
import XHRUpload from "@uppy/xhr-upload";
import { PrivateApi } from "../../core/utilities/api/PrivateApi";
import { URL_STORAGE } from "../WebsiteConstants";

const Instagram: any = require("@uppy/instagram");
const Transloadit: any = require("@uppy/transloadit");

export default (editor, opts = {}) => {
  const options: any = {
    ...{
      // default options
      // Custom button element which triggers Uppy modal
      btnEl: "",

      // Text for the button in case the custom one is not provided
      btnText: "Upload images",

      // File picker theme
      theme: "dark",

      // Uppy's options
      uppyOpts: {
        autoProceed: false,
        restrictions: {
          maxFileSize: 2000000,
          maxNumberOfFiles: 10,
          minNumberOfFiles: 1,
          allowedFileTypes: ["image/*"],
        },
      },

      // Uppy dashboard options
      dashboardOpts: {
        showProgressDetails: true,
        note: "Images only, 1–10 files, up to 2 MB",
        height: 470,
        metaFields: [
          { id: "name", name: "Name", placeholder: "file name" },
          {
            id: "caption",
            name: "Caption",
            placeholder: "describe what the image is about",
          },
        ],
        browserBackButtonClose: false,
      },

      // Companion URL
      companionUrl: "https://companion.uppy.io",

      // Tus URL
      endpoint: "https://tusd.tusdemo.net/files/",

      // On complete upload callback
      // assets - Array of assets, eg. [{url:'...', filename: 'name.jpeg', ...}]
      // for debug: console.log(assets);
      onComplete(assets) {},

      // On failed upload callback
      // assets - Array of assets, eg. [{url:'...', filename: 'name.jpeg', ...}]
      // for debug: console.log(assets);
      onFailed(assets) {},
    },
    ...opts,
  };

  let btnEl: object = null;
  let uppy: object = null;
  const pfx: any = editor.getConfig("stylePrefix");
  const { $ } = editor;
  const { uppyOpts, dashboardOpts, companionUrl, site_id } = options;

  /**
   * Add new assets to the editor
   * @param {Array} files
   */
  const addAssets: any = (files) => {
    const urls: any = files.map((file) => {
      return {
        id: file.id,
        name: file.meta.name,
        caption: file.meta.caption || "",
        src: file.uploadURL,
        category: "library",
      };
    });
    $(".gjs-tab #library").trigger("click");
    return editor.AssetManager.add(urls);
  };

  // When the Asset Manager modal is opened
  editor.on("run:open-assets", () => {
    const popUp = editor.PopUp;
    const popUpBody = popUp.getContentEl();
    // const uploader = popUpBody.querySelector(`.${pfx}am-file-uploader`);
    const assetsHeader = popUpBody.querySelector(`.${pfx}am-assets-header`);
    const assetsBody = popUpBody.querySelector(`.${pfx}am-assets-cont`);

    assetsBody.style.width = "100%";

    // Instance button if not yet exists
    if (!btnEl) {
      btnEl = options.btnEl
        ? $(options.btnEl)
        : $(`<button class="${pfx}btn-prim ${pfx}btn-uppy">
                    ${options.btnText}
                </button>`);
    }

    if (!uppy) {
      const privateApi: any = new PrivateApi();
      uppy = new Uppy({
        ...uppyOpts,
      })
        .use(Dashboard, {
          theme: options.theme,
          trigger: btnEl.get(0),
          ...dashboardOpts,
        })
        .use(GoogleDrive, {
          target: Dashboard,
          companionUrl: Transloadit.COMPANION,
          companionAllowedHosts: Transloadit.COMPANION_PATTERN,
        })
        .use(Instagram, {
          target: Dashboard,
          companionUrl: Transloadit.COMPANION,
          companionAllowedHosts: Transloadit.COMPANION_PATTERN,
        })
        .use(Webcam, { target: Dashboard })
        .use(ScreenCapture, { target: Dashboard })
        .use(XHRUpload, {
          endpoint: URL_STORAGE,
          fieldName: "file",
          headers: privateApi.getMultipartHeaders(),
        });

      uppy.on("complete", (result) => {
        addAssets(result.successful);
        options.onComplete(result.successful);
        options.onFailed(result.failed);
      });
      uppy.on("file-added", (file) => {
        uppy.setFileMeta(file.id, {
          site: site_id,
        });
      });
    }

    assetsHeader.appendChild(btnEl.get(0));
  });
};
