import React from "react";
import Label from "../Label";

export class FormTextAreaInput extends React.Component {
  render() {
    const { label } = this.props;
    const { placeholder } = this.props;
    const { name } = this.props;
    const text: any = this.props.value;
    const { rows } = this.props;
    const { onChangeText } = this.props;
    const { is_required } = this.props;
    const { is_editable } = this.props;

    let label_element: string = "";
    if (label) {
      label_element = (
        <Label icon={null} text={label} is_important={is_required} />
      );
    }

    return (
      <div className="col">
        {label_element}
        <textarea
          className="form-control"
          placeholder={placeholder}
          name={name}
          value={text}
          onChange={onChangeText}
          required={is_required}
          disabled={!is_editable}
          rows={rows || 3}
        />
      </div>
    );
  }
}
