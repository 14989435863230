import { ApmRoute as Route } from "@elastic/apm-rum-react";
import { init as flowsInit } from "@flows/js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthProvider, PrivateRoute } from "./AuthProvider";
import AccountRouter from "./modules/account/AccountRouter";
import { AuthenticationActions } from "modules/account/AccountActions";
import { PATH_ACCOUNT } from "modules/account/AccountConstants";
import { User } from "modules/account/AccountModels";
import {
  PATH_BILLING,
  PATH_SETTLE_INVOICE,
} from "modules/billing/BillingConstants";
import BillingRouter from "modules/billing/BillingRouter";
import { SettleInvoice } from "modules/billing/layouts/SettleInvoice";
import { UI_ROUTES } from "modules/cloudoon_email/CloudoonConstants";
import CloudoonEmailRouter from "modules/cloudoon_email/CloudoonEmailRouter";
import { PATH_CMS } from "modules/cms/CmsConstants";
import CmsRouter from "modules/cms/CmsRouter";
import { PATH_MAIN_ROUTE } from "modules/core/constants/Routes";
import { PATH_DNS } from "modules/dns/DnsConstants";
import DnsRouter from "modules/dns/DnsRouter";
import { PATH_DOMAINS } from "modules/domains/DomainsConstants";
import DomainsRouter from "modules/domains/DomainsRouter";
import { PATH_LOUDGUN } from "modules/loudgun/LoudgunConstants";
import LoudgunRouter from "modules/loudgun/LoudgunRouter";
import { PATH_MARKETING } from "modules/marketing/MarketingConstants";
import MarketingRouter from "modules/marketing/MarketingRouter";
import { PATH_REFERRAL } from "modules/referral/ReferralConstants";
import ReferralRouter from "modules/referral/ReferralRouter";
import { PATH_SHOP } from "modules/shop/ShopConstants";
import ShopRouter from "modules/shop/ShopRouter";
import OlittBanners from "modules/website/components/OlittBanners";
import { PATH_WEBSITES } from "modules/website/WebsiteConstants";
import WebsiteRouter from "modules/website/WebsiteRouter";

const queryClient = new QueryClient();
const flowsProjectId = process.env.REACT_APP_FLOWS_PROJECT_ID;
const flowsApiUrl = process.env.REACT_APP_FLOWS_API_URL;

function App() {
  const authenticationActions = new AuthenticationActions();
  const user: User = authenticationActions.retrieveAuthenticatedUser();

  function getUserName() {
    if (!user) return null;
    return `${user.first_name || ""} ${user.last_name || ""}`.trim() || null;
  }

  flowsInit({
    userId: user?.id ? user.id.toString() : null,
    userProperties: {
      email: user ? user.email : null,
      name: getUserName(),
    },
    projectId: flowsProjectId,
    customApiUrl: flowsApiUrl,
    flows: [],
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <Switch>
            <Route path={PATH_ACCOUNT} component={AccountRouter} />
            <PrivateRoute path={PATH_MARKETING} component={MarketingRouter} />
            <PrivateRoute path={PATH_WEBSITES} component={WebsiteRouter} />
            <PrivateRoute path={PATH_DOMAINS} component={DomainsRouter} />
            <PrivateRoute path={PATH_DNS} component={DnsRouter} />
            <PrivateRoute path={PATH_REFERRAL} component={ReferralRouter} />
            <PrivateRoute
              exact
              path={PATH_SETTLE_INVOICE}
              component={SettleInvoice}
            />
            <PrivateRoute path={PATH_BILLING} component={BillingRouter} />
            <Route path={PATH_CMS} component={CmsRouter} />
            <Route path={PATH_LOUDGUN} component={LoudgunRouter} />

            <PrivateRoute path={PATH_SHOP} component={ShopRouter} />
            <PrivateRoute
              path={UI_ROUTES.ROOT}
              component={CloudoonEmailRouter}
            />
            <Redirect from={PATH_MAIN_ROUTE} to={PATH_WEBSITES} />
            <Redirect from="*" to={PATH_MAIN_ROUTE} />
          </Switch>
          <ToastContainer />
          <OlittBanners />
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
