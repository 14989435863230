import Proptypes from "prop-types";
import React from "react";
import AppleSignin from "react-apple-signin-auth";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import { APP_HOST } from "../../core/constants/Constants";
import appleIcon from "../assets/apple_logo.svg";

export function OlittAppleLoginButton({ client_id, callback, is_logging_in }) {
  function onSuccess(success_response) {
    const { authorization } = success_response;
    const token_id: any = authorization.id_token;
    callback(true, token_id);
  }

  function onFailure(error_response) {
    const { error } = error_response;
    callback(false, error);
  }

  let apple_btn_text;
  if (is_logging_in) {
    apple_btn_text = (
      <div className="d-flex w-100 align-items-center justify-content-center">
        <ComponentLoadingIndicator />
      </div>
    );
  } else {
    apple_btn_text = (
      <div className="d-flex w-100 align-items-center">
        <img height="30px" src={appleIcon} alt="" />
        <strong className="flex-grow-1"> Continue With Apple </strong>
      </div>
    );
  }

  return (
    <AppleSignin
      authOptions={{
        clientId: client_id,
        scope: "email name",
        redirectURI: `https://${APP_HOST}`,
        state: "state",
        nonce: "nonce",
        usePopup: true,
      }}
      onSuccess={onSuccess}
      onFailure={onFailure}
      render={({ onClick }) => (
        <button
          type="button"
          className="btn btn-outline-olitt-grey d-flex align-items-center shadow-none"
          onClick={onClick}
          disabled={is_logging_in}
        >
          {apple_btn_text}
        </button>
      )}
    />
  );
}

OlittAppleLoginButton.propTypes = {
  client_id: Proptypes.string.isRequired,
  callback: Proptypes.func.isRequired,
  is_logging_in: Proptypes.bool.isRequired,
};
