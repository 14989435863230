import { ApmRoute as Route } from "@elastic/apm-rum-react";
import { Switch } from "react-router-dom";
import Page404 from "modules/core/layouts/Page404";
import MarketingAssets from "modules/marketing/layouts/Assets";
import Campaigns from "modules/marketing/layouts/Campaigns";
import Contacts from "modules/marketing/layouts/Contacts";
import Content from "modules/marketing/layouts/Content";
import Dashboard from "modules/marketing/layouts/Dashboard";
import EmailTemplates from "modules/marketing/layouts/EmailTemplates";
import FocusItems from "modules/marketing/layouts/FocusItems";
import Forms from "modules/marketing/layouts/Forms";
import FormSubmissions from "modules/marketing/layouts/FormSubmissions";
import Notes from "modules/marketing/layouts/Notes";
import Segments from "modules/marketing/layouts/Segments";
import Stages from "modules/marketing/layouts/Stages";
import {
  PATH_MARKETING_ASSETS,
  PATH_MARKETING_CAMPAIGNS,
  PATH_MARKETING_CONTACTS,
  PATH_MARKETING_CONTENT,
  PATH_MARKETING_DASHBOARD,
  PATH_MARKETING_EMAIL_TEMPLATES,
  PATH_MARKETING_FOCUS_ITEMS,
  PATH_MARKETING_FORMS,
  PATH_MARKETING_FORM_SUBMISSIONS,
  PATH_MARKETING_NOTES,
  PATH_MARKETING_SEGMENTS,
  PATH_MARKETING_STAGES,
} from "modules/marketing/MarketingConstants";

function MarketingRouter() {
  return (
    <Switch>
      <Route exact path={PATH_MARKETING_DASHBOARD} component={Dashboard} />
      <Route exact path={PATH_MARKETING_CONTACTS} component={Contacts} />
      <Route exact path={PATH_MARKETING_SEGMENTS} component={Segments} />
      <Route exact path={PATH_MARKETING_FORMS} component={Forms} />
      <Route exact path={PATH_MARKETING_FOCUS_ITEMS} component={FocusItems} />
      <Route exact path={PATH_MARKETING_CAMPAIGNS} component={Campaigns} />
      <Route exact path={PATH_MARKETING_CONTENT} component={Content} />
      <Route exact path={PATH_MARKETING_NOTES} component={Notes} />
      <Route exact path={PATH_MARKETING_STAGES} component={Stages} />
      <Route exact path={PATH_MARKETING_DASHBOARD} component={Dashboard} />
      <Route exact path={PATH_MARKETING_ASSETS} component={MarketingAssets} />
      <Route
        exact
        path={PATH_MARKETING_FORM_SUBMISSIONS}
        component={FormSubmissions}
      />
      <Route
        exact
        path={PATH_MARKETING_EMAIL_TEMPLATES}
        component={EmailTemplates}
      />
      <Route path="*" component={Page404} />
    </Switch>
  );
}

export default MarketingRouter;
