import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import {
  closeModalById,
  deleteNullProperties,
  handleInputChange,
} from "modules/base/utilities/Actions";
import FocusItemFormFields from "modules/marketing/components/Focusitems/FocusItemFormFields";
import MarketingAPI from "modules/marketing/MarketingAPI";

function CreateFocusItem(props) {
  const { currentModalId, prebuiltHtml, match, uuid } = props;
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const initialFocusItemState = {
    properties: {
      content: {
        css: ".mf-modal{height: fit-content;padding: 0;border:0; .mf-modal-close {z-index: 3000 !important;}.mf-content {margin: 0px;}}",
      },
    },
    style: "modal",
    id: uuid,
    html: prebuiltHtml,
    formTemplateHtml: prebuiltHtml,
    formHtml: "",
    notificationHtml: prebuiltHtml,
    linkHtml: prebuiltHtml,
    htmlMode: "basic",
  };
  const [state, setState] = useState(initialFocusItemState);
  useEffect(() => {
    if (prebuiltHtml) {
      setState({
        ...state,
        html: prebuiltHtml,
      });
    }
  }, [prebuiltHtml]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = deleteNullProperties(state);
    if (data.type === "form") {
      data.properties.content.css =
        ".mf-modal{position: fixed; bottom: 0; top: 0; right: 0; left:0; height: fit-content;padding: 0;border:0; .mf-modal-close {z-index: 3000 !important;}.mf-content {margin: 0px;}.mauticform-row{transform: translate(0, 0) !important;}.mauticform-field-hidden { display:none }}";
    }
    API.createFocusItem(
      data,
      () => {
        setIsSubmitting(false);
        setError(null);
        setState(initialFocusItemState);
        closeModalById(currentModalId);
        setSuccess({
          message: "Focus item created successfully",
        });
      },
      (error_response) => {
        setIsSubmitting(false);
        setError({
          message: error_response.message,
        });
        setSuccess(null);
      },
    );
  });
  const onInputChange = useCallback(
    (e) => handleInputChange(e.target.name, e.target.value, state, setState),
    [state, setState],
  );
  return (
    <Form
      handleSubmit={handleSubmit}
      id="create-focus-item-form"
      button_color="gray-900"
      button_label="Create"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="div"
      padding="p-0"
    >
      <NetworkMessageDisplay error={error} />
      <SuccessActionIndicator success={success} />
      <FocusItemFormFields
        state={state}
        setState={setState}
        onInputChange={onInputChange}
        currentModalId={currentModalId}
      />
    </Form>
  );
}

CreateFocusItem.defaultProps = {
  currentModalId: null,
  prebuiltHtml: null,
};

CreateFocusItem.propTypes = {
  currentModalId: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  prebuiltHtml: PropTypes.string,
  uuid: PropTypes.string.isRequired,
};

export default withRouter(CreateFocusItem);
