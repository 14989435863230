import PropTypes from "prop-types";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { LoginSocialGoogle } from "reactjs-social-login";
import { GOOGLE_CLIENT_ID } from "modules/account/AccountConstants";
import googleIcon from "modules/account/assets/google_logo.png";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import MarketingAPI from "modules/marketing/MarketingAPI";

function ImportGoogleContacts({ onSuccess, onError, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const importContacts = (response) => {
    API.importGoogleContacts(
      { google_access_token: response.data.access_token },
      (data) => {
        setIsLoading(false);
        onSuccess(data);
      },
      (err) => {
        setIsLoading(false);
        onError(err);
      },
    );
  };
  const onLoginStart = () => {
    setIsLoading(true);
  };

  const onReject = (error) => {
    setIsLoading(false);
    onError(error?.data?.message ?? "Something went wrong");
  };
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  return (
    <LoginSocialGoogle
      client_id={GOOGLE_CLIENT_ID}
      onLoginStart={onLoginStart}
      scope="https://www.googleapis.com/auth/contacts.readonly"
      discoveryDocs="claims_supported"
      access_type="online"
      typeResponse="accessToken"
      onResolve={importContacts}
      onReject={onReject}
      className="d-grid d-md-flex"
    >
      <button
        type="button"
        className="btn btn-outline-gray-900 mb-2 mb-md-0 me-0 me-md-2"
      >
        <img height="20px" src={googleIcon} alt="" className="me-2" />
        Import from Google
      </button>
    </LoginSocialGoogle>
  );
}

ImportGoogleContacts.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

ImportGoogleContacts.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

export default withRouter(ImportGoogleContacts);
