import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Modal from "modules/base/components/Modal";
import CreateNoteForm from "modules/marketing/components/notes/CreateNoteForm";
import { Note } from "modules/marketing/MarketingModels";

function CreateNoteModal({ row, match }) {
  const { id: siteId } = match.params;
  const modalId = `create-note-modal-${row.id}`;
  return (
    <Modal modal_title="Create Note" modal_id={modalId} size="lg">
      <CreateNoteForm
        note={row}
        lead={row.id}
        currentModalId={modalId}
        siteId={siteId}
      />
    </Modal>
  );
}

CreateNoteModal.propTypes = {
  row: PropTypes.instanceOf(Note).isRequired,
};

export default withRouter(CreateNoteModal);
