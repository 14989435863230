import PropTypes from "prop-types";
import React, { useState } from "react";
import { IconLoading } from "../../base/components/Icons";

import SiteApi from "modules/website/api/SiteApi";

export function Connect3rdPartyDomain({
  website_id,
  after_connection_actions,
}) {
  const [domain_name, setDomainName] = useState("");
  const [validation_error, setValidationError] = useState(null);
  const [is_connecting, setIsConnecting] = useState(false);
  const [connection_error, setConnectionError] = useState(null);
  const btn_connect_domain_text: any = is_connecting ? (
    <IconLoading text="Checking" />
  ) : (
    "Connect"
  );

  const site_api: any = new SiteApi();

  function connectCustomDomain(event) {
    event.preventDefault();
    setIsConnecting(true);
    const fields: { custom_domain: any } = { custom_domain: domain_name };
    site_api.updateWebsiteFields(
      website_id,
      fields,
      (is_successful, website_or_error) => {
        setIsConnecting(false);
        if (is_successful) {
          after_connection_actions();
        } else {
          setConnectionError(website_or_error);
        }
      },
    );
  }

  function validateDomainName(domain_to_validate) {
    setValidationError(null);
    if (domain_to_validate.length === 0) {
      setValidationError("Enter the your domain to proceed");
    } else if (domain_to_validate.length < 5) {
      setValidationError("Domain name is too short");
    }
  }

  function changeDomainName(event) {
    const { value } = event.target;
    setDomainName(value);
    validateDomainName(value);
  }

  return (
    <div>
      <form onSubmit={connectCustomDomain}>
        <div className="input-group rounded d-flex align-items-center">
          <input
            className="form-control bg-transparent me-3 rounded"
            type="text"
            required
            disabled={is_connecting}
            placeholder="mywebsite.com"
            value={domain_name}
            onChange={(e) => setDomainName(e.target.value)}
          />
          <button
            type="submit"
            className="btn btn-primary d-flex align-items-center shadow-none"
            disabled={is_connecting}
          >
            {btn_connect_domain_text}
          </button>
        </div>
        <p className="small text-danger">{connection_error}</p>
      </form>
    </div>
  );

  // unreachable code
}

Connect3rdPartyDomain.propTypes = {
  website_id: PropTypes.number.isRequired,
  after_connection_actions: PropTypes.func.isRequired,
};
