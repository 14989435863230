import { rankItem } from "@tanstack/match-sorter-utils";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import ActionsCell from "modules/base/components/table/ActionsCell";
import CreateButton from "modules/base/components/table/CreateButton";
import Export from "modules/base/components/table/Export";
import Head from "modules/base/components/table/Head";
import IndeterminateCheckbox from "modules/base/components/table/IndeterminateCheckbox";
import Pagination from "modules/base/components/table/Pagination";
import TableRows from "modules/base/components/table/Rows";
import Search from "modules/base/components/table/Search";

function Table(props) {
  const {
    columns,
    data,
    total,
    isLoading,
    allowCreate,
    create_button_label,
    create_button_icon,
    onClickCreate,
    navigateOnClickCreate,
    create_navigation_path,
    isDataPaginated,
    isServerSidePagination,
    enableSearch,
    createButtonColor,
    allowExport,
    exportFileName,
    enableRowSelection,
    enableMultiRowSelection,
    onRowSelectionChange,
    filterElement,
    additionalTopRightButtons,
    onPaginationChange,
  } = props;

  const [globalFilter, setGlobalFilter] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const fuzzyFilter = useCallback((row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({ itemRank });
    return itemRank.passed;
  }, []);

  const colSpan = columns.length;
  const handleGetRowId = useCallback((row) => row.id, []);

  useEffect(() => {
    onRowSelectionChange(rowSelection);

    if (isServerSidePagination) {
      const { pageIndex, pageSize } = pagination;
      onPaginationChange({
        limit: pageSize,
        start: pageIndex * pageSize,
      });
    }
  }, [pagination, onPaginationChange, isServerSidePagination, rowSelection]);

  const tableOptions = useMemo(() => {
    const options = {
      data,
      columns,
      filterFns: { fuzzy: fuzzyFilter },
      getRowId: handleGetRowId,
      globalFilterFn: fuzzyFilter,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      state: { globalFilter, rowSelection, pagination },
      onGlobalFilterChange: setGlobalFilter,
      enableRowSelection,
      enableMultiRowSelection,
      onRowSelectionChange: setRowSelection,
    };

    if (isServerSidePagination) {
      options.manualPagination = true;
      options.pageCount = Math.ceil(total / pagination.pageSize);
    } else if (isDataPaginated) {
      options.getPaginationRowModel = getPaginationRowModel();
    }
    return options;
  }, [
    data,
    columns,
    fuzzyFilter,
    handleGetRowId,
    globalFilter,
    rowSelection,
    pagination,
    total,
    enableRowSelection,
    enableMultiRowSelection,
    isServerSidePagination,
  ]);

  const table = useReactTable(tableOptions);

  const handlePageChange = useCallback((newPage) => {
    setPagination((prev) => ({ ...prev, pageIndex: newPage }));
  }, []);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize, pageIndex: 0 }));
  }, []);

  const { pageIndex, pageSize } = table.getState().pagination;

  const searchFilterWrapperClass =
    enableSearch && filterElement ? "col-12 col-md-6" : "col-12 col-md-3";
  const filterElementClass =
    enableSearch && filterElement ? "col-12 col-md-6 mb-2 mb-md-0" : "col-12";
  return (
    <div className="table-responsive ms-3">
      <div className="d-flex flex-column flex-md-row justify-content-md-between mb-2">
        <div
          className={`d-grid d-md-flex ${searchFilterWrapperClass} mb-2 mb-md-0`}
        >
          <div className={`${filterElementClass} me-0 me-md-2`}>
            <Search
              enableSearch={enableSearch}
              globalFilter={globalFilter}
              onChange={(value) => setGlobalFilter(String(value))}
            />
          </div>
          <div className={filterElementClass}>{filterElement}</div>
        </div>
        <div className="d-grid d-md-flex col-12 col-md-6 justify-content-md-end">
          {additionalTopRightButtons}
          {allowCreate && (
            <CreateButton
              navigationPath={create_navigation_path}
              onClick={onClickCreate}
              buttonLabel={create_button_label}
              buttonIcon={create_button_icon}
              navigateOnClick={navigateOnClickCreate}
              buttonColor={createButtonColor}
            />
          )}
        </div>
      </div>
      <div className="d-md-flex justify-content-md-end mb-2 d-grid">
        {allowExport && <Export data={data} fileName={exportFileName} />}
      </div>
      <table className="table">
        <thead>
          <Head table={table} />
        </thead>
        <tbody>
          <TableRows
            colSpan={colSpan}
            data={data}
            table={table}
            isLoading={isLoading}
          />
        </tbody>
      </table>
      <Pagination
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        isDataPaginated={isDataPaginated}
        isServerSidePagination={isServerSidePagination}
        dataLength={data.length}
        total={total}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
    </div>
  );
}

Table.defaultProps = {
  filterElement: null,
  additionalTopRightButtons: [],
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessorKey: PropTypes.string.isRequired,
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
      cell: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  isLoading: PropTypes.bool.isRequired,
  allowCreate: PropTypes.bool.isRequired,
  create_button_label: PropTypes.string.isRequired,
  create_button_icon: PropTypes.string.isRequired,
  onClickCreate: PropTypes.func.isRequired,
  navigateOnClickCreate: PropTypes.bool.isRequired,
  create_navigation_path: PropTypes.string.isRequired,
  isServerSidePagination: PropTypes.bool.isRequired,
  isDataPaginated: PropTypes.bool.isRequired,
  enableSearch: PropTypes.bool.isRequired,
  createButtonColor: PropTypes.string.isRequired,
  allowExport: PropTypes.bool.isRequired,
  exportFileName: PropTypes.string.isRequired,
  enableRowSelection: PropTypes.bool.isRequired,
  enableMultiRowSelection: PropTypes.bool.isRequired,
  onRowSelectionChange: PropTypes.func.isRequired,
  filterElement: PropTypes.element,
  additionalTopRightButtons: PropTypes.arrayOf(PropTypes.element),
};

function DataTable(props) {
  const {
    columns,
    data,
    total,
    isLoading,
    isDataPaginated,
    isServerSidePagination,
    onPaginationChange,
    allowCreate,
    create_button_label,
    create_button_icon,
    onClickCreate,
    navigateOnClickCreate,
    create_navigation_path,
    enableSearch,
    createButtonColor,
    allowEdit,
    allowDelete,
    allowView,
    onClickEdit,
    onClickDelete,
    onClickView,
    EditModal,
    editModalProps,
    objectType,
    editButtonColor,
    viewButtonColor,
    allowExport,
    exportFileName,
    enableRowSelection,
    enableMultiRowSelection,
    onRowSelectionChange,
    filterElement,
    additionalTopRightButtons,
    resetRowSelection,
    dropdownActions,
    allowDropdownActions,
    DropdownModal,
    dropdownLabel,
  } = props;

  const handleRowSelectionChange = useCallback(
    (table) => {
      return resetRowSelection
        ? table.resetRowSelection()
        : table.getToggleAllRowsSelectedHandler();
    },
    [resetRowSelection],
  );

  const actionsColumn = {
    accessorKey: "actions",
    header: "",
    cell: (tableInstance) => (
      <ActionsCell
        row={tableInstance.row}
        allowEdit={allowEdit}
        allowDelete={allowDelete}
        allowView={allowView}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        EditModal={EditModal}
        editModalProps={editModalProps}
        objectType={objectType}
        editButtonColor={editButtonColor}
        viewButtonColor={viewButtonColor}
        onClickView={onClickView}
        allowDropdownActions={allowDropdownActions}
        dropdownActions={allowDropdownActions ? dropdownActions : []}
        DropdownModal={DropdownModal}
        dropdownLabel={dropdownLabel}
      />
    ),
  };

  const selectCheckboxColumn = {
    accessorKey: "select",
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: handleRowSelectionChange(table),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  };

  const memoizedColumns = useMemo(() => {
    const updatedColumns = [...columns];

    if (allowEdit || allowDelete || allowView || allowDropdownActions) {
      const isActionsColumnAdded = updatedColumns.some(
        (column) => column.accessorKey === "actions",
      );
      if (!isActionsColumnAdded) {
        updatedColumns.push(actionsColumn);
      }
    }

    if (enableRowSelection) {
      const isSelectCheckboxColumnAdded = updatedColumns.some(
        (column) => column.accessorKey === "select",
      );
      if (!isSelectCheckboxColumnAdded) {
        updatedColumns.unshift(selectCheckboxColumn);
      }
    }

    return updatedColumns;
  }, [
    columns,
    allowEdit,
    allowDelete,
    allowView,
    allowDropdownActions,
    enableRowSelection,
  ]);

  return (
    <Table
      columns={memoizedColumns}
      data={data}
      total={total}
      onPaginationChange={onPaginationChange}
      isLoading={isLoading}
      allowCreate={allowCreate}
      create_button_label={create_button_label}
      create_button_icon={create_button_icon}
      onClickCreate={onClickCreate}
      navigateOnClickCreate={navigateOnClickCreate}
      create_navigation_path={create_navigation_path}
      isServerSidePagination={isServerSidePagination}
      isDataPaginated={isDataPaginated}
      enableSearch={enableSearch}
      createButtonColor={createButtonColor}
      allowExport={allowExport}
      exportFileName={exportFileName}
      enableRowSelection={enableRowSelection}
      enableMultiRowSelection={enableMultiRowSelection}
      onRowSelectionChange={onRowSelectionChange}
      filterElement={filterElement}
      additionalTopRightButtons={additionalTopRightButtons}
      onClickView={onClickView}
      allowDropdownActions={allowDropdownActions}
    />
  );
}

DataTable.defaultProps = {
  allowCreate: false,
  create_button_label: "Create",
  create_button_icon: "plus",
  onClickCreate: () => {
    // Do nothing
  },
  navigateOnClickCreate: false,
  create_navigation_path: "",
  isServerSidePagination: false,
  isDataPaginated: false,
  enableSearch: false,
  createButtonColor: "primary",
  allowEdit: false,
  allowDelete: false,
  allowView: false,
  onClickEdit: () => {
    // Do nothing
  },
  onClickDelete: () => {
    // Do nothing
  },
  onClickView: () => {
    // Do nothing
  },
  EditModal: () => {
    return null;
  },
  editModalProps: {},
  dropdownActions: [],
  dropdownLabel: null,
  DropdownModal: () => {
    return null;
  },

  objectType: "",
  editButtonColor: "primary",
  viewButtonColor: "primary",
  allowExport: false,
  exportFileName: "export",
  enableRowSelection: false,
  enableMultiRowSelection: false,
  onRowSelectionChange: () => {
    // Do nothing
  },
  filterElement: null,
  additionalTopRightButtons: [],
  resetRowSelection: false,
  allowDropdownActions: false,
  total: 0,
  onPaginationChange: () => {
    // Do nothing
  },
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessorKey: PropTypes.string.isRequired,
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
      cell: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  isLoading: PropTypes.bool.isRequired,
  allowCreate: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickView: PropTypes.func,
  create_button_label: PropTypes.string,
  create_button_icon: PropTypes.string,
  onClickCreate: PropTypes.func,
  navigateOnClickCreate: PropTypes.bool,
  create_navigation_path: PropTypes.string,
  isDataPaginated: PropTypes.bool,
  isServerSidePagination: PropTypes.bool,
  enableSearch: PropTypes.bool,
  createButtonColor: PropTypes.string,
  EditModal: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  editModalProps: PropTypes.instanceOf(Object),
  objectType: PropTypes.string,
  editButtonColor: PropTypes.string,
  allowExport: PropTypes.bool,
  exportFileName: PropTypes.string,
  enableRowSelection: PropTypes.bool,
  enableMultiRowSelection: PropTypes.bool,
  onRowSelectionChange: PropTypes.func,
  filterElement: PropTypes.element,
  additionalTopRightButtons: PropTypes.arrayOf(PropTypes.element),
  allowView: PropTypes.bool,
  viewButtonColor: PropTypes.string,
  resetRowSelection: PropTypes.bool,
  dropdownActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  allowDropdownActions: PropTypes.bool,
  DropdownModal: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  dropdownLabel: PropTypes.string,
  onPaginationChange: PropTypes.func,
  total: PropTypes.number,
};

export default DataTable;
