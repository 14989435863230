import PropTypes from "prop-types";

function AccordionItem(props) {
  const { children, id, title, isShowing } = props;
  const show = isShowing ? "show" : "";
  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${id}`}
          aria-expanded="true"
          aria-controls={id}
        >
          {title}
        </button>
      </h2>
      <div id={id} className={`accordion-collapse collapse ${show}`}>
        <div className="accordion-body">{children}</div>
      </div>
    </div>
  );
}

AccordionItem.defaultProps = {
  id: "accordion-item",
  title: "Accordion Item",
  isShowing: false,
};

AccordionItem.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
  isShowing: PropTypes.bool,
};

export default AccordionItem;
