import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import SelectInput from "modules/base/components/inputs/SelectInput";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import MarketingAPI from "modules/marketing/MarketingAPI";

function FilterContacts({ match, onChange, selectedSegments }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const { segments, error } = API.getSegments();

  const SegmentOptions = segments.map((segment) => ({
    label: segment.name,
    value: segment.alias,
  }));

  return (
    <>
      <NetworkMessageDisplay error={error} />
      <SelectInput
        name="filters"
        title=""
        placeholder="Filter by segments..."
        isAddon
        options={SegmentOptions}
        onChange={onChange}
        predicate={(option) => selectedSegments?.includes(option.value)}
        isClearable
      />
    </>
  );
}

FilterContacts.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,

  onChange: PropTypes.func.isRequired,
  selectedSegments: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
};

export default withRouter(FilterContacts);
