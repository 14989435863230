import { UAParser } from "ua-parser-js";
import { v4 as uuidv4 } from "uuid";
import CopyToClipboardButton from "modules/core/components/CopyToClipboardButton";

/**
 * Component that provides instructions for enabling third-party cookies
 * based on the user's browser.
 *
 * @component
 * @example
 * return (
 *   <ThirdPartyCookiesInstructions />
 * )
 *
 * @returns {JSX.Element} A React component that displays instructions for enabling third-party cookies.
 */
function ThirdPartyCookiesInstructions() {
  const { browser } = new UAParser(window.navigator.userAgent).getResult();
  const browserName = browser.name.toLowerCase();
  const instructions = {
    chrome: {
      steps: ['Ensure "Allow third-party cookies" is checked'],
      link: "chrome://settings/cookies",
    },
    firefox: {
      steps: ['Under "Enhanced Tracking Protection", select "Standard"'],
      link: "about:preferences#privacy",
    },
    safari: {
      steps: [
        'Uncheck "Prevent cross-site tracking"',
        'Under "Cookies and website data", select "Allow from current website"',
      ],
      link: "x-apple.systempreferences:com.apple.preference.security?Privacy",
    },
    edge: {
      steps: ['Ensure "Block third-party cookies" is turned OFF'],
      link: "edge://settings/content/cookies",
    },
    other: {
      steps: [
        "Open your browser settings",
        "Look for Privacy or Cookie settings",
        "Enable third-party cookies",
        "If needed, refresh the page after making changes",
      ],
    },
  };
  const cookiesSettingsInstructions =
    instructions[browserName] ?? instructions.other;
  return (
    <div className="container">
      <div className="alert alert-warning mt-4">
        <h4 className="alert-heading">
          We detected incompatible settings in your browser
        </h4>
        <p>
          We need to change a few settings for the editor to work on your
          browser.
        </p>
        <ul>
          <li>
            Open your browser settings by copying and pasting the following link
            in a new tab:
            <code className="m-1">{cookiesSettingsInstructions.link}</code>
            <CopyToClipboardButton text={cookiesSettingsInstructions.link} />
          </li>
          {cookiesSettingsInstructions.steps.map((step) => (
            <li key={uuidv4()}>{step}</li>
          ))}
          <li>
            Once you have made the changes, click the refresh button below.
          </li>
        </ul>
        <div className="d-grid d-md-block">
          <button
            type="button"
            className="btn btn-gray-900"
            onClick={() => window.location.reload()}
          >
            Refresh
          </button>
        </div>
      </div>
    </div>
  );
}

export default ThirdPartyCookiesInstructions;
