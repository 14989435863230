import PropTypes from "prop-types";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { useAuth } from "../../../AuthProvider";
import { AuthenticationActions } from "modules/account/AccountActions";
import { AuthenticationApi } from "modules/account/AccountAPIs";
import EmailAndPasswordLogin from "modules/account/components/EmailAndPasswordLogin";
import { EmailAndPasswordRegistration } from "modules/account/components/EmailAndPasswordRegistration";
import { ForgotPassword } from "modules/account/components/ForgotPassword";
import SocialAuthentication from "modules/account/components/SocialAuthentication";
import { VerifyOTP } from "modules/account/components/VerifyOTP";
import { Icon } from "modules/base/components/Icons";
import logo from "modules/core/assets/olitt-logo-secondary.svg";
import { navigateBack } from "modules/core/utilities/navigation/Navigator";
import { PATH_WEBSITES } from "modules/website/WebsiteConstants";

function Login(props) {
  const { onSuccess, location, history } = props;
  const [error, setError] = useState(null);
  const [signature_hash, setSignatureHash] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [is_registration, setRegistration] = useState(false);
  const [is_verifying_account, setIsVerifyingAccount] = useState(false);
  const [is_forgot_password, setIsForgotPassword] = useState(false);
  const accountActions: any = new AuthenticationActions();
  const auth = useAuth();

  function onAuthenticated() {
    if (onSuccess) {
      onSuccess();
    } else {
      const url_parameters: any = new URLSearchParams(location.search);
      const next: any = url_parameters.get("next");
      history.push(next || PATH_WEBSITES);
    }
  }

  const toggleLoginAndRegister: any = () => {
    setError(null);
    if (is_verifying_account || is_forgot_password || is_registration) {
      setIsVerifyingAccount(false);
      setIsForgotPassword(false);
      setRegistration(false);
    } else {
      setRegistration(true);
    }
  };

  const showForgotPasswordView: any = () => {
    setError(null);
    setIsForgotPassword(true);
  };

  const goBack: any = () => navigateBack(props);

  const authenticationApi: any = new AuthenticationApi();

  function loginWithEmailAndPassword(
    email_address,
    user_password,
    setInProgress,
  ) {
    setInProgress(true);
    setError(null);
    authenticationApi.loginWithEmail(
      email_address,
      user_password,
      (is_successful, tokens_or_error) => {
        if (is_successful) {
          accountActions.storeAuthenticationTokens(tokens_or_error).then(() => {
            auth.login(tokens_or_error, () => {
              setInProgress(false);
              onAuthenticated();
            });
          });
        } else if (tokens_or_error.hash) {
          setSignatureHash(tokens_or_error.hash);
          setEmail(email_address);
          setPassword(user_password);
          setIsVerifyingAccount(true);
        } else {
          setInProgress(false);
          setError(tokens_or_error);
        }
      },
    );
  }

  function registerWithEmailAndPassword(
    first_name,
    last_name,
    email_address,
    user_password,
    setInProgress,
  ) {
    setInProgress(true);
    setError(null);
    authenticationApi.registerWithEmail(
      first_name,
      last_name,
      email_address,
      user_password,
      (is_successful, tokens_or_error) => {
        setInProgress(false);
        if (is_successful) {
          loginWithEmailAndPassword(
            email_address,
            user_password,
            setInProgress,
          );
        } else {
          setError(tokens_or_error);
        }
      },
    );
  }

  let social_auth_view: any = (
    <>
      <small className="my-2 d-block text-center">OR</small>
      <SocialAuthentication onSuccess={onAuthenticated} />
    </>
  );

  let btn_register_text;
  let text;
  let email_auth_view;
  let forgot_password_button;
  let forgot_password_separator;
  if (is_forgot_password) {
    btn_register_text = "Log In";
    text = "Forgot your account's password";
    social_auth_view = null;
    email_auth_view = (
      <ForgotPassword onSuccess={() => setIsForgotPassword(false)} />
    );
  } else if (is_verifying_account) {
    btn_register_text = "Log In";
    text = "Verify your account";
    social_auth_view = null;
    email_auth_view = (
      <VerifyOTP
        email={email}
        signature_hash={signature_hash}
        onSuccess={(setInProgress) =>
          loginWithEmailAndPassword(email, password, setInProgress)
        }
      />
    );
  } else if (is_registration) {
    btn_register_text = "Log In";
    text = "Create an account to proceed";
    email_auth_view = (
      <EmailAndPasswordRegistration
        registerWithEmailAndPassword={(
          f_name,
          l_name,
          email_address,
          user_password,
          setInProgress,
        ) => {
          registerWithEmailAndPassword(
            f_name,
            l_name,
            email_address,
            user_password,
            setInProgress,
          );
        }}
      />
    );
  } else {
    btn_register_text = "Create account";
    text = "Log into your account to proceed";
    forgot_password_separator = <Icon icon="dot" />;
    forgot_password_button = (
      <button
        type="button"
        className="btn btn-sm btn-link shadow-none text-decoration-none"
        onClick={showForgotPasswordView}
      >
        Forgot password
      </button>
    );
    email_auth_view = (
      <EmailAndPasswordLogin
        loginWithEmailAndPassword={(
          email_address,
          user_password,
          setInProgress,
        ) => {
          loginWithEmailAndPassword(
            email_address,
            user_password,
            setInProgress,
          );
        }}
      />
    );
  }

  const card_width: any = window.innerWidth < 435 ? "100%" : "375px";
  const auth_card_style: { width: any } = { width: card_width };

  return (
    <div className="min-vh-100 d-flex flex-column bg-white">
      <div className="my-4 container-fluid fixed-top d-flex align-items-center justify-content-between">
        <button
          type="button"
          className="btn shadow-none text-olitt-grey d-flex align-items-center"
          onClick={goBack}
        >
          <i className="la la-angle-left la-2x" />
          <strong className="ms-2"> BACK </strong>
        </button>
        <button
          type="button"
          className="btn shadow-none text-olitt-grey"
          onClick={toggleLoginAndRegister}
        >
          <strong className="text-uppercase"> {btn_register_text} </strong>
        </button>
      </div>

      <div className="flex-grow-1 d-flex align-items-center justify-content-center p-3">
        <div className="card border-0" style={auth_card_style}>
          <div className="card-header bg-white border-0">
            <div className="d-flex flex-column align-items-center">
              <img
                className="my-4"
                width="150px"
                height="60px"
                src={logo}
                alt=""
              />
              <strong className="text-center text-olitt-grey mb-2">
                {text}
              </strong>
            </div>
          </div>
          <div className="card-body">
            <div className="alert alert-danger" role="alert" hidden={!error}>
              <small> {error} </small>
            </div>
            {email_auth_view}
            {social_auth_view}

            <div className="mt-4 d-flex align-items-center justify-content-center">
              {forgot_password_button}

              {forgot_password_separator}

              <button
                type="button"
                className="btn btn-sm btn-link shadow-none text-decoration-none"
                onClick={toggleLoginAndRegister}
              >
                {btn_register_text}
              </button>
            </div>

            <hr />
          </div>
        </div>
      </div>
    </div>
  );
}

Login.defaultProps = {
  onSuccess: null,
};

Login.propTypes = {
  onSuccess: PropTypes.func,
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(Login);
