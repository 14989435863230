import { useCallback } from "react";
import BasePaymentMethods from "modules/base/components/billing/BasePaymentMethods";
import { BillingAPI } from "modules/billing/BillingAPIs";
import DebitCreditCardForm from "modules/billing/components/DebitCreditCardForm";

function BillingCards() {
  const billingAPI = new BillingAPI();
  const {
    cards: billing_cards,
    error,
    isLoading,
  } = billingAPI.getBillingCards();
  const { customer } = billingAPI.getBillingCustomer("i");

  function deleteCard(card_id, successCallback, errorCallback) {
    billingAPI.deleteBillingCard(card_id, successCallback, errorCallback);
  }

  function setDefaultCard(card_id, successCallback, errorCallback) {
    billingAPI.setDefaultBillingCard(card_id, successCallback, errorCallback);
  }

  const getHandleDeleteCard = useCallback(
    (id, successCallback, errorCallback) => {
      deleteCard(id, successCallback, errorCallback);
    },
  );

  const getHandleSetDefaultCard = useCallback(
    (id, successCallback, errorCallback) => {
      setDefaultCard(id, successCallback, errorCallback);
    },
  );

  return (
    <BasePaymentMethods
      billing_cards={billing_cards}
      error={error}
      isLoading={isLoading}
      customer={customer}
      handleDeleteCard={getHandleDeleteCard}
      handleSetDefaultCard={getHandleSetDefaultCard}
      DebitCreditCardForm={DebitCreditCardForm}
      add_button_color="gray-900"
    />
  );
}

export default BillingCards;
