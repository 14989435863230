import { mailchimp_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const {
    MailChimpEmbeddedLabel,
    MailChimpPopupLabel,
    MailChimpContactLabel,
    MailChimpCategory,
    MailChimpEmbeddedBlock,
    MailChimpPopupBlock,
    MailChimpContactBlock,
  } = opts;

  MailChimpEmbeddedBlock &&
    bm.add("MAILCHIMP-EMBEDDED-BLOCK", {
      label: MailChimpEmbeddedLabel,
      activate: true,
      select: true,
      category: MailChimpCategory,
      content: { type: "MAILCHIMP-EMBEDDED-COMPONENT" },
      media: mailchimp_logo,
    });

  MailChimpPopupBlock &&
    bm.add("MAILCHIMP-POPUP-BLOCK", {
      label: MailChimpPopupLabel,
      activate: true,
      select: true,
      category: MailChimpCategory,
      content: { type: "MAILCHIMP-POPUP-COMPONENT" },
      media: mailchimp_logo,
    });
};
