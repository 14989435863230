import { google_translate_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const {
    GoogleTranslateLabel,
    GoogleTranslateCategory,
    GoogleTranslateBlock,
  } = opts;

  GoogleTranslateBlock &&
    bm.add("GOOGLE-TRANSLATE-BLOCK", {
      label: GoogleTranslateLabel,
      activate: true,
      select: true,
      category: GoogleTranslateCategory,
      content: { type: "GOOGLE-TRANSLATE-COMPONENT" },
      media: google_translate_logo,
    });
};
