import PropTypes from "prop-types";
import React from "react";
import PageCard from "./PageCard";

/**
 * PageSelector
 * @type {React.FC<{
 * editor: import('grapesjs').default.Editor
 * currentPage: any;
 * pages: any[];
 * }>}
 */
const PageSelector = (props) => {
  const { editor, currentPage, pages } = props;

  // Event Handlers

  const onSelectPage = (page) => {
    editor.em.trigger("custom:page:select", page);
  };

  const onRenamePage = (page) => {
    editor.em.trigger("custom:page:rename", page);
  };

  const onDuplicatePage = (page) => {
    editor.em.trigger("custom:page:clone", page);
  };

  const onDeletePage = (page) => {
    editor.em.trigger("custom:page:delete", page);
  };

  const onAddPage = () => {
    editor.em.trigger("custom:page:add");
  };

  // Render

  const pagesList = pages.map((page) => {
    return (
      <li key={page.id} className="list-group-item">
        <PageCard
          page={page}
          isCurrentPage={page.id === currentPage.id}
          onSelect={onSelectPage}
          onRename={onRenamePage}
          onDuplicate={onDuplicatePage}
          onDelete={onDeletePage}
        />
      </li>
    );
  });

  return (
    <div className="card">
      <h5 className="card-header text-muted">Pages</h5>
      <ul className="list-group">{pagesList}</ul>
      <div className="card-footer">
        <button type="button" className="btn btn-primary" onClick={onAddPage}>
          Add Page
        </button>
      </div>
    </div>
  );
};

PageSelector.propTypes = {
  currentPage: PropTypes.shape({
    id: PropTypes.number,
    page_title: PropTypes.string,
  }).isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      page_title: PropTypes.string,
    }),
  ).isRequired,
  editor: PropTypes.instanceOf(Object).isRequired,
};

export default PageSelector;
