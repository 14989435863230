import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

/**
 * Page
 * @type {React.FC<{
 *  isCurrentPage: boolean;
 *  onSelect: (page: any) => void;
 *  onRename: (page: any) => void;
 *  onDelete: (page: any) => void;
 *  onDuplicate: (page: any) => void;
 *  page: {
 *    id: number
 *    page_title: string
 *    is_start_page: boolean
 *    page_content: string | null
 *    page_json: object | null
 *  }
 * }>
 * }
 */
const PageCard = (props) => {
  const {
    page,
    isCurrentPage,
    onSelect: _onSelect,
    onRename: _onRename,
    onDuplicate: _onDuplicate,
    onDelete: _onDelete,
  } = props;
  const [isEditing, setIsEditing] = React.useState(false);
  const dropdown = React.useRef(null);

  const onRename = (e) => {
    setIsEditing(false);
    _onRename({ ...page, page_title: e.target.value });
  };

  const onSelect = (e) => {
    e.stopPropagation();
    _onSelect(page);
  };

  const onEdit = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const onDuplicate = (e) => {
    e.stopPropagation();
    _onDuplicate(page);
  };

  const onDelete = (e) => {
    e.stopPropagation();
    _onDelete(page);
  };

  const cancelPropagation = (e) => e.stopPropagation();

  const editTitle = isEditing ? (
    <input
      type="text"
      className="form-control form-control-sm"
      defaultValue={page.page_title}
      onClick={cancelPropagation}
      onBlur={onRename}
      onKeyPress={(e) => {
        e.stopPropagation();
        if (e.key === "Enter") {
          onRename(e);
        }
      }}
    />
  ) : (
    <span>{page.page_title}</span>
  );

  const iconClasses = classNames("fa", {
    "fa-home": page.is_start_page,
    "fa-file-o": !page.is_start_page,
  });

  const containerClasses = classNames(
    "fs-4 rounded d-flex align-items-center justify-content-center",
    {
      "bg-primary text-white": isCurrentPage,
      "bg-secondary text-white": !isCurrentPage,
    },
  );

  const buttonClasses = classNames("btn btn-sm rounded-circle", {
    "btn-outline-primary": isCurrentPage,
    "btn-outline-secondary": !isCurrentPage,
  });

  return (
    <div
      className="row"
      type="button"
      role="button"
      tabIndex={0}
      onKeyPress={onSelect}
      onClick={onSelect}
    >
      <div className="col-2 align-self-center">
        <div className="ratio ratio-1x1">
          <div className={containerClasses}>
            <i className={iconClasses} aria-hidden="true" />
          </div>
        </div>
      </div>
      <div className="col-8 align-self-center">{editTitle}</div>
      <div className="col-2 align-self-center">
        <div ref={dropdown} className="dropdown">
          <button
            onClick={cancelPropagation}
            data-bs-toggle="dropdown"
            aria-expanded="false"
            type="button"
            className={buttonClasses}
          >
            <i className="bi bi-three-dots" />
          </button>
          <ul className="dropdown-menu">
            <li>
              <button type="button" className="dropdown-item" onClick={onEdit}>
                <i className="bi bi-pencil-square" /> Rename
              </button>
            </li>
            <li>
              <button
                type="button"
                className="dropdown-item"
                onClick={onDuplicate}
              >
                <i className="bi bi-back" /> Duplicate
              </button>
            </li>
            <li>
              <button
                disabled={page.is_start_page}
                type="button"
                className="dropdown-item"
                onClick={onDelete}
              >
                <i className="bi bi-trash" /> Delete
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

PageCard.propTypes = {
  isCurrentPage: PropTypes.bool,
  page: PropTypes.shape({
    id: PropTypes.number,
    page_title: PropTypes.string,
    is_start_page: PropTypes.bool,
    page_content: PropTypes.string,
    page_json: PropTypes.instanceOf(Object),
  }).isRequired,
  onSelect: PropTypes.func,
  onRename: PropTypes.func,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
};

PageCard.defaultProps = {
  isCurrentPage: false,
  onSelect: () => {},
  onRename: () => {},
  onDelete: () => {},
  onDuplicate: () => {},
};

export default PageCard;
