import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import MatchColumns from "modules/marketing/components/contacts/import/MatchColumns";

function MatchColumnsModal({ rawContactsData }) {
  const modalId = "match-columns-modal";
  return (
    <Modal modal_title="Match Columns" modal_id={modalId} size="xl">
      <MatchColumns
        currentModalId={modalId}
        rawContactsData={rawContactsData}
      />
    </Modal>
  );
}

MatchColumnsModal.defaultProps = {
  rawContactsData: {
    columns: [],
    data: [],
  },
};

MatchColumnsModal.propTypes = {
  rawContactsData: PropTypes.shape({
    columns: PropTypes.instanceOf(Array),
    data: PropTypes.instanceOf(Array),
  }),
};

export default MatchColumnsModal;
