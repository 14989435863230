import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import CreateFocusItem from "modules/marketing/components/Focusitems/CreateFocusItem";

function CreateFocusItemModal({ prebuiltHtml, uuid }) {
  return (
    <Modal
      modal_title="Create Engagement Item"
      modal_id="create-focus-item-modal"
      size="md"
    >
      <CreateFocusItem
        currentModalId="create-focus-item-modal"
        prebuiltHtml={prebuiltHtml}
        uuid={uuid}
      />
    </Modal>
  );
}

CreateFocusItemModal.propTypes = {
  prebuiltHtml: PropTypes.string,
  uuid: PropTypes.string.isRequired,
};
CreateFocusItemModal.defaultProps = {
  prebuiltHtml: null,
};

export default CreateFocusItemModal;
