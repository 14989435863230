import { useQuery } from "@tanstack/react-query";
import ShopApi from "../ShopAPIs";

const TWO_MINUTES = 2 * 60 * 60;

function fetchOrders(site_id, state_eq) {
  const shopApi = new ShopApi();

  return new Promise((resolve, reject) => {
    shopApi.getOrders(
      site_id,
      state_eq,
      (response) => {
        const sortedOrders = response.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        resolve(sortedOrders);
      },
      (error_message) => {
        reject(error_message);
      },
    );
  });
}

function useOrders(site_id, state_eq) {
  const { isLoading, data } = useQuery({
    queryKey: ["orders", site_id, state_eq],
    queryFn: () => fetchOrders(site_id, state_eq),
    staleTime: TWO_MINUTES,
  });

  return {
    orders: data ?? [],
    isLoading,
  };
}

export default useOrders;
