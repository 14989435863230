import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import BaseWalletTopUpButton from "modules/base/components/billing/BaseWalletTopUpButton";
import BaseWalletTopUpForm from "modules/base/components/billing/BaseWalletTopUpForm";
import Modal from "modules/base/components/Modal";
import { BillingWallet } from "modules/base/models/Billing";
import { closeModal, openModal } from "modules/base/utilities/Actions";
import { BillingAPI } from "modules/billing/BillingAPIs";

function WalletTopUp({ wallet, currentModalId }) {
  const billingAPI = new BillingAPI();
  const [state, setState] = useState({
    amount: null,
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleTopUp = useCallback(() => {
    openModal("wallet-top-up-modal", currentModalId);
  });
  const handleTopUpSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);
    billingAPI.topUpWallet(
      "i",
      state.amount,
      () => {
        setError(null);
        setSuccess({
          message: "Wallet successfully topped up",
        });
        setIsSubmitting(false);
        setState({
          amount: null,
        });
        closeModal("wallet-top-up-modal");
      },
      (error_response) => {
        setError({
          message: error_response.message,
        });
        setIsSubmitting(false);
      },
    );
  });
  return (
    <>
      <BaseWalletTopUpButton buttonColor="gray-900" handleClick={handleTopUp} />
      <Modal
        modal_title="Top Up Wallet"
        modal_id="wallet-top-up-modal"
        size="md"
      >
        <BaseWalletTopUpForm
          setState={setState}
          state={state}
          handleSubmit={handleTopUpSubmit}
          isSubmitting={isSubmitting}
          submitButtonColor="gray-900"
          wallet={wallet}
          error={error}
          success={success}
        />
      </Modal>
    </>
  );
}

WalletTopUp.propTypes = {
  wallet: PropTypes.instanceOf(BillingWallet).isRequired,
  currentModalId: PropTypes.string.isRequired,
};

export default WalletTopUp;
