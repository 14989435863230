import { Modal as BootstrapModal } from "bootstrap";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BillingCard from "modules/base/components/billing/BillingCard";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SectionTitle from "modules/base/components/SectionTitle";

function BasePaymentMethods(props) {
  const [warning, setWarning] = React.useState(null);
  const {
    billing_cards,
    error,
    isLoading,
    customer,
    handleDeleteCard,
    handleSetDefaultCard,
    DebitCreditCardForm,
    add_button_color,
  } = props;
  let has_reached_max_cards_allowed = false;
  let has_minimum_cards_allowed = false;
  if (customer) {
    has_reached_max_cards_allowed = customer.has_reached_max_cards_allowed;
    has_minimum_cards_allowed = customer.has_minimum_cards_allowed;
  }

  function deleteCard(card_id) {
    let ConfirmSwal = withReactContent(Swal);
    ConfirmSwal = ConfirmSwal.mixin({
      customClass: {
        confirmButton: "btn btn-danger me-2",
        cancelButton: "btn btn-secondary",
      },
      buttonsStyling: false,
    });
    ConfirmSwal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this card",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteCard(
          card_id,
          () => {
            ConfirmSwal.fire(
              "Deleted",
              "Your card has been deleted.",
              "success",
            );
          },
          (error_response) => {
            ConfirmSwal.fire("Error", error_response.message, "error");
          },
        );
      }
    });
  }

  function setDefaultCard(card_id) {
    handleSetDefaultCard(
      card_id,
      () => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your default card has been updated.",
        });
      },
      (error_response) => {
        console.log(error_response);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error_response.message,
        });
      },
    );
  }

  useEffect(() => {
    const { minimum_cards_allowed } = customer;
    let noun = "card";
    if (minimum_cards_allowed > 1) {
      noun = "cards";
    }
    if (!has_minimum_cards_allowed && minimum_cards_allowed) {
      setWarning({
        message: `You must have at least ${minimum_cards_allowed} ${noun} on file to continue.
        You can add more cards by clicking the "Add Card" button below.`,
      });
    }
  }, [has_reached_max_cards_allowed]);

  function addCard() {
    if (has_reached_max_cards_allowed) {
      return;
    }
    const addCardModal = BootstrapModal.getOrCreateInstance(
      document.getElementById("add-card"),
    );
    addCardModal.show();
  }

  const handleAddCard = useCallback(() => {
    addCard();
  });
  let tooltip = <div />;
  if (has_reached_max_cards_allowed) {
    tooltip = (
      <Tooltip id="add-card-button">
        You have reached the maximum number of cards allowed.
      </Tooltip>
    );
  }
  const getHandleDeleteCard = useCallback((id) => {
    return () => {
      deleteCard(id);
    };
  });
  const getHandleSetDefaultCard = useCallback((id) => {
    return () => {
      setDefaultCard(id);
    };
  });
  const cards = billing_cards.map((billing_card) => {
    let tooltip_content = "Use this card for all future payments";
    if (billing_card.default) {
      tooltip_content = "This card will be used for all future payments";
    }
    return (
      <BillingCard
        key={billing_card.id}
        billingCard={billing_card}
        tooltipContent={tooltip_content}
        handleSetDefault={getHandleSetDefaultCard(billing_card.id)}
        handleDelete={getHandleDeleteCard(billing_card.id)}
      />
    );
  });
  const addCardButton = (
    <div className="col">
      <button
        type="button"
        className={`btn btn-${add_button_color}`}
        data-tooltip-id="add-card-button"
        data-tooltip-variant="error"
        data-tooltip-place="right"
        data-tooltip-content="You have reached the maximum number of cards allowed."
        onClick={handleAddCard}
      >
        <i className="bi bi-credit-card" /> Add Card
      </button>
    </div>
  );
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  return (
    <>
      {tooltip}
      <DebitCreditCardForm />
      <NetworkMessageDisplay error={error} warning={warning} />
      <SectionTitle title="Cards" />
      <div className="row row-cols-1 row-cols-lg-3 gap-2 gap-md-0 mb-3">
        {cards}
        {addCardButton}
      </div>
    </>
  );
}

BasePaymentMethods.defaultProps = {
  billing_cards: [],
  error: null,
  isLoading: false,
  customer: {},
  add_button_color: "purple",
};

BasePaymentMethods.propTypes = {
  billing_cards: PropTypes.instanceOf(Array),
  error: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  customer: PropTypes.instanceOf(Object),
  handleDeleteCard: PropTypes.func.isRequired,
  handleSetDefaultCard: PropTypes.func.isRequired,
  DebitCreditCardForm: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
    .isRequired,
  add_button_color: PropTypes.string,
};

export default BasePaymentMethods;
