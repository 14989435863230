import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import BaseChartAnalytics from "modules/marketing/components/dashboard/googleanalytics/BaseChartAnalytics";
import { combineParams } from "modules/marketing/components/dashboard/googleanalytics/utils";
import MarketingAPI from "modules/marketing/MarketingAPI";

const PageViews = ({ match, filterParams, extraParams }) => {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);

  const allParams = combineParams(filterParams, extraParams);

  const { analyticsData, error, isLoading } =
    API.getGoogleAnalyticsData(allParams);

  let totalPageViews = 0;
  let tableData = [];
  const tableHeaders = ["Page Path", "Page Views", "Percentage"];

  if (
    analyticsData.dimensions?.rows &&
    analyticsData.dimensions.rows.length > 0
  ) {
    totalPageViews = analyticsData.dimensions.rows.reduce(
      (acc, row) => acc + parseInt(row.metrics[0], 10),
      0,
    );

    const sortedRows = analyticsData.dimensions.rows.sort(
      (a, b) => parseInt(b.metrics[0], 10) - parseInt(a.metrics[0], 10),
    );
    const top5Rows = sortedRows.slice(0, 5);

    tableData = top5Rows.map((row) => {
      const pageViews = parseInt(row.metrics[0], 10);
      const percentage = ((pageViews / totalPageViews) * 100).toFixed(2);
      const percentageWithSymbol = `${percentage}%`;
      return [row.dimensions[0], pageViews, percentageWithSymbol];
    });
  }

  const headerContent = (
    <div className="col">
      <div className="row">
        <div className="col-6">
          <h6>Total Page Views: {totalPageViews}</h6>
        </div>
      </div>
    </div>
  );

  return (
    <BaseChartAnalytics
      description="Top Page Views"
      type="table"
      tableData={tableData}
      tableHeaders={tableHeaders}
      headerContent={headerContent}
      isLoading={isLoading}
      error={error}
    />
  );
};

PageViews.defaultProps = {
  filterParams: {},
  extraParams: {},
};

PageViews.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  filterParams: PropTypes.instanceOf(Object),
  extraParams: PropTypes.instanceOf(Object),
};

export default withRouter(PageViews);
