import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { UI_ROUTES } from "../../CloudoonConstants";
import useDomain from "../../hooks/useDomain";
import useVerificationStatus from "../../hooks/useVerificationStatus";
import SetupLayout from "../../layouts/SetupLayout";
import Completed from "./Completed";
import CreateAccount from "./CreateAccount";
import Verifiaction from "./Verification";

function SetupRouter(props) {
  const {
    match: {
      params: { domain_name },
    },
  } = props;

  const {
    data: domain,
    error: domain_error,
    is_error: is_domain_error,
    is_loading: is_domain_loading,
    is_refetching: is_domain_refetching,
  } = useDomain(domain_name);

  const {
    data: status,
    error: status_error,
    is_error: is_status_error,
    is_loading: is_status_loading,
    is_refetching: is_status_refetching,
  } = useVerificationStatus(domain_name);

  if (
    is_status_loading ||
    is_status_loading ||
    is_status_refetching ||
    is_domain_loading ||
    is_domain_refetching
  ) {
    return <SetupLayout>loading...</SetupLayout>;
  }

  const errors = [];

  if (is_domain_error) {
    errors.push(domain_error.detail ?? "error");
  }

  if (is_status_error) {
    errors.push(status_error.detail ?? "error");
  }

  if (errors.length > 0) {
    return <SetupLayout>{errors}</SetupLayout>;
  }

  let redirect_to_account;
  if (status?.ownership && status?.mx_records && domain.status === "ready") {
    redirect_to_account = (
      <Redirect
        to={UI_ROUTES.SETUP.CREATE_ACCOUNT.replace(":domain_name", domain_name)}
      />
    );
  } else if (
    status?.ownership &&
    status?.mx_records &&
    domain.status === "active"
  ) {
    redirect_to_account = (
      <Redirect
        to={UI_ROUTES.SETUP.COMPLETED.replace(":domain_name", domain_name)}
      />
    );
  }

  return (
    <SetupLayout>
      <Route path={UI_ROUTES.SETUP.VERIFICATION} component={Verifiaction} />
      <Route path={UI_ROUTES.SETUP.CREATE_ACCOUNT} component={CreateAccount} />
      <Route path={UI_ROUTES.SETUP.COMPLETED} component={Completed} />
      <Redirect
        to={UI_ROUTES.SETUP.VERIFICATION.replace(":domain_name", domain_name)}
      />
      {redirect_to_account}
    </SetupLayout>
  );
}

SetupRouter.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default SetupRouter;
