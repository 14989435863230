import React from "react";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import PaymentMethodsView from "../components/paymentmethods/PaymentMethodsView";
import SidebarShop from "../components/SidebarShop";

/**
 * Shop Payment Methods Layout
 *
 * @returns {React.Component}
 */
function PaymentMethods() {
  return (
    <SidebarWrapper sidebar={<SidebarShop />}>
      <PaymentMethodsView />
    </SidebarWrapper>
  );
}

export default PaymentMethods;
