import { useFloating, useHover, useInteractions } from "@floating-ui/react";
import { sanitize } from "dompurify";
import React, { useState } from "react";

function ContentNoteButton({ noteDescriptions }) {
  const cell = ({ row }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles, context } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
    });
    const hover = useHover(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);
    const { original } = row;

    const buttonClass = "btn btn-sm btn-outline-gray-900";
    const iconClass = "bi-list";

    const sanitizedText = sanitize(
      (original.text || "").replace(/<[^>]*>?/gm, ""),
      {
        ALLOWED_TAGS: [],
      },
    );

    const renderNoteDescription = () => (
      <div className="card-body text-wrap">
        <p>{sanitizedText}</p>
      </div>
    );

    const handleOpen = () => setIsOpen(window.innerWidth <= 768 || !isOpen);

    const handleMouseHover = (isEntering) =>
      setIsOpen(window.innerWidth > 768 && isEntering);

    return (
      <>
        <button
          type="button"
          className={buttonClass}
          onClick={handleOpen}
          onMouseEnter={() => handleMouseHover(true)}
          onMouseLeave={() => handleMouseHover(false)}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <i className={`bi ${iconClass}`} /> Details
        </button>

        {isOpen && (
          <div
            className="card"
            style={{ width: "30rem", ...floatingStyles }}
            ref={refs.setFloating}
            {...getFloatingProps()}
          >
            {renderNoteDescription()}
          </div>
        )}
      </>
    );
  };

  return {
    header: "Notes",
    accessorKey: "noteDescription",
    cell,
  };
}

export default ContentNoteButton;
