import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import DebouncedInput from "modules/base/components/DebouncedInput";
import { Icon } from "modules/base/components/Icons";
import SearchResults from "modules/website/components/SearchResults";

function TemplateSearch({ templates, onSearchResults }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentResults, setCurrentResults] = useState([]);

  const handleSearch = useCallback(
    (query) => {
      if (!query?.trim()) {
        setCurrentResults([]);
        onSearchResults([], query || "");
        return;
      }

      const searchTerm = query.toLowerCase();
      const normalisedSearch = searchTerm.replace(/\s+/g, "");

      const results = (templates || []).filter((template) => {
        if (!template) return false;

        const normalisedName =
          template.name?.toLowerCase().replace(/\s+/g, "") || "";
        const normalisedDescription =
          template.description?.toLowerCase().replace(/\s+/g, "") || "";
        const normalisedTitle =
          template.title?.toLowerCase().replace(/\s+/g, "") || "";

        return (
          normalisedName.includes(normalisedSearch) ||
          normalisedDescription.includes(normalisedSearch) ||
          normalisedTitle.includes(normalisedSearch)
        );
      });

      setCurrentResults(results);
      onSearchResults(results, query);
    },
    [templates, onSearchResults],
  );

  const handleClear = useCallback(() => {
    setSearchQuery("");
    setCurrentResults([]);
    onSearchResults([], "");
  }, [onSearchResults]);

  return (
    <div className="sticky-top mb-4 mx-6 pt-2">
      <div className="input-group">
        <DebouncedInput
          id="template-search"
          className="form-control"
          type="search"
          placeholder="Search template"
          value={searchQuery}
          onChange={(newValue) => {
            setSearchQuery(newValue);
            handleSearch(newValue);
          }}
          aria-label="Search templates"
        />
        <button
          className="btn btn-primary"
          type="button"
          aria-label="Submit search"
        >
          <Icon icon="search" />
        </button>
      </div>
      <SearchResults
        searchQuery={searchQuery}
        currentResults={currentResults}
        onClear={handleClear}
      />
    </div>
  );
}

TemplateSearch.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  onSearchResults: PropTypes.func.isRequired,
};

export default TemplateSearch;
