import PropTypes from "prop-types";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import { getMoneyFormat } from "modules/base/utilities/Actions";

function BaseBillingWalletBalance(props) {
  const { isLoading, error, wallet } = props;
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  const balance_cents = wallet.balance_cents ?? 0;
  const currency = wallet.currency ?? "USD";
  const balance = getMoneyFormat(balance_cents / 100, currency);
  return (
    <>
      <NetworkMessageDisplay error={error} />
      Credit: {balance}
    </>
  );
}

BaseBillingWalletBalance.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  wallet: PropTypes.shape({
    balance_cents: PropTypes.number,
    currency: PropTypes.string,
  }),
};

BaseBillingWalletBalance.defaultProps = {
  error: null,
  wallet: {
    balance_cents: 0,
    currency: "USD",
  },
};

export default BaseBillingWalletBalance;
