import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ACTIVE_DOMAIN_STATUS, PATH_NAMESERVERS } from "../DomainsConstants";
import { AuthenticationActions } from "modules/account/AccountActions";
import { ErrorModal } from "modules/core/components/ErrorModal";
import MainNavigationSidebar from "modules/core/components/MainNavigationSidebar";
import SidebarWrapper from "modules/core/components/SidebarWrapper";
import { navigateToPage } from "modules/core/utilities/navigation/Navigator";
import {
  ToastUtility,
  toast_styles,
} from "modules/core/utilities/ToastUtility";
import { PATH_DNS_DOMAINS } from "modules/dns/DnsConstants";
import DomainSearch from "modules/domains/components/DomainSearch";
import PurchasedDomainList from "modules/domains/components/PurchasedDomainList";
import { DomainApi } from "modules/domains/DomainsAPIs";
import EmptyList from "modules/website/components/EmptyList";
import PageLoadingIndicator from "modules/website/components/PageLoadingIndicator";

function DomainSearchController(props) {
  const authenticationActions: any = new AuthenticationActions();
  const user: any = authenticationActions.retrieveAuthenticatedUser();

  const [loading_domains, setIsLoadingDomains] = useState(true);
  const [domains, setDomains] = useState([]);
  const [error, setError] = useState(null);
  const not_active_error: string =
    "Domain is not active. Please complete the purchase.";

  function retrievePurchasedDomains(is_silently = false) {
    if (!is_silently) setIsLoadingDomains(true);
    const api: any = new DomainApi();
    api.retrievePurchasedDomains((is_successful, domains_or_error) => {
      if (!is_silently) setIsLoadingDomains(false);
      if (is_successful) {
        setDomains(domains_or_error);
      } else if (!is_silently) setError(domains_or_error);
    });
  }

  useEffect(retrievePurchasedDomains, []);

  const navigateToManageDNS: any = (domain_status: string) => {
    if (domain_status !== ACTIVE_DOMAIN_STATUS) {
      ToastUtility.toast(toast_styles.error, not_active_error);
    } else {
      navigateToPage(PATH_DNS_DOMAINS, props);
    }
  };
  const editNameservers: any = (
    domain_name: string,
    domain_id: string,
    domain_status: string,
  ) => {
    if (domain_status !== ACTIVE_DOMAIN_STATUS) {
      ToastUtility.toast(toast_styles.error, not_active_error);
    } else {
      const path: any = PATH_NAMESERVERS.replace(
        ":domain_name",
        domain_name,
      ).replace(":domain_id", domain_id);
      navigateToPage(path, props);
    }
  };

  if (!loading_domains && !user) {
    retrievePurchasedDomains();
    return <PageLoadingIndicator />;
  }
  if (loading_domains) {
    return <PageLoadingIndicator />;
  }

  let main_content;
  if (domains.length === 0) {
    const title: string = "No domains here.";
    const description: string = "You have not purchased any domains";
    main_content = <EmptyList title={title} description={description} />;
  } else {
    main_content = (
      <PurchasedDomainList
        domains={domains}
        manage_dns={navigateToManageDNS}
        manage_ns={editNameservers}
      />
    );
  }

  const closeErrorModal: any = () => setError(null);
  const close_modal_button: any = (
    <button
      type="button"
      className="btn btn-olitt-grey px-4"
      onClick={closeErrorModal}
    >
      Close
    </button>
  );

  return (
    <SidebarWrapper sidebar={<MainNavigationSidebar />}>
      <DomainSearch user={user} />
      {main_content}
      <ErrorModal
        modal_id="error-modal"
        show={error !== null}
        onClose={closeErrorModal}
        positive_button={close_modal_button}
        short_desc={"Something didn't go quite right."}
        suggestion={error || ""}
      />
    </SidebarWrapper>
  );
}

export default DomainSearchController;
