import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import DateTimeInput from "modules/base/components/inputs/DateTimeInput";
import SelectInput from "modules/base/components/inputs/SelectInput";
import SwitchInput from "modules/base/components/inputs/SwitchInput";
import Tooltip from "modules/base/components/Tooltip";
import MarketingAPI from "modules/marketing/MarketingAPI";

function EmailSchedulerFields({ match, state, setState, onInputChange }) {
  const [isScheduled, setIsScheduled] = useState(
    Boolean(state.publishUp || state.publishDown),
  );
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  let segments = [];
  try {
    const response = API.getSegments();
    segments = response?.segments ?? [];
  } catch (error) {
    setState((prevState) => ({
      ...prevState,
      error: "Failed to fetch segments. Please try again.",
    }));
  }

  const segmentIdsArray = useMemo(
    () =>
      Array.isArray(state.lists) ? state.lists.map((list) => list.id) : [],
    [state.lists],
  );
  const [selectedSegmentsIds, setSelectedSegmentsIds] =
    useState(segmentIdsArray);

  const onSegmentsChange = useCallback(
    (selectedOptions) => {
      const selectedSegments = selectedOptions.map((option) => option.value);
      setSelectedSegmentsIds(selectedSegments);
      setState((prevState) => ({
        ...prevState,
        lists: selectedSegments,
      }));
    },
    [setState],
  );

  const segmentsOptions = useMemo(
    () =>
      segments.map((segment) => ({
        value: segment.id,
        label: segment.name,
      })),
    [segments],
  );

  const handleScheduleChange = useCallback(() => {
    setIsScheduled((prev) => !prev);
  }, []);

  const onDateChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      if (!value || !moment(value, "YYYY-MM-DD HH:mm", true).isValid()) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      onInputChange({
        target: { name, value },
      });
    },
    [onInputChange, setState],
  );

  const currentUtcTime = moment.utc().format("YYYY-MM-DD HH:mm");

  return (
    <div>
      {state.error && <div className="error-message">{state.error}</div>}
      <SelectInput
        name="segmentsLists"
        title="Select the Segments"
        options={segmentsOptions}
        isMulti
        onChange={onSegmentsChange}
        predicate={(option) => selectedSegmentsIds.includes(option.value)}
        required
        wrapperClasses="mb-3"
      />
      <SwitchInput
        label="Schedule Email"
        name="scheduleEmail"
        content={state.scheduleEmail}
        handleInputChange={handleScheduleChange}
        isChecked={isScheduled}
        wrapperClasses="mt-3"
      />
      {isScheduled && (
        <>
          <div className="mt-3">
            <Tooltip
              id="publishUpTooltip"
              content={`Current UTC time: ${currentUtcTime}`}
            >
              <DateTimeInput
                id="publishUp"
                name="publishUp"
                title="When to Start Sending (UTC)"
                content={state.publishUp}
                controlFunc={onDateChange}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="yyyy-MM-dd HH:mm"
                minDate={new Date()}
                maxDate={moment("9999-12-31 23:59")}
              />
            </Tooltip>
          </div>
          <div className="mt-3">
            <Tooltip
              id="publishDownTooltip"
              content={`Current UTC time: ${currentUtcTime}`}
            >
              <DateTimeInput
                id="publishDown"
                name="publishDown"
                title="When to Stop Sending (UTC)"
                content={state.publishDown}
                controlFunc={onDateChange}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="yyyy-MM-dd HH:mm"
                minDate={
                  state.publishUp ? new Date(state.publishUp) : new Date()
                }
                maxDate={moment("9999-12-31 23:59")}
                required={false}
              />
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
}

EmailSchedulerFields.defaultProps = {
  state: {},
};

EmailSchedulerFields.propTypes = {
  state: PropTypes.instanceOf(Object),
  setState: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default EmailSchedulerFields;
