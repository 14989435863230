export const currencies = [
        {value: "USD", name: "United States dollar"},
        {value: "THB", name: "Thai baht"},
        {
            value: "INR",
            name: "Swiss franc",
        },
        {value: "SEK", name: "Swedish krona"},
        {value: "SGD", name: "Singapore dollar"},
        {
            value: "RUB",
            name: "Russian ruble",
        },
        {value: "GBP", name: "Pound sterling"},
        {value: "PLN", name: "Polish złoty"},
        {
            value: "PHP",
            name: "Philippine peso",
        },
        {value: "NOK", name: "Norwegian krone"},
        {value: "NZD", name: "New Zealand dollar"},
        {
            value: "TWD",
            name: "New Taiwan dollar",
        },
        {value: "MXN", name: "Mexican peso"},
        {value: "MYR", name: "Malaysian ringgit"},
        {value: "JPY", name: "Japanese yen"},
        {value: "ILS", name: "Israeli new shekel"},
        {value: "INR", name: "Indian rupee"},
        {
            value: "HUF",
            name: "Hungarian forint",
        },
        {value: "HKD", name: "Hong Kong dollar"},
        {value: "EUR", name: "Euro"},
        {
            value: "DKK",
            name: "Danish krone",
        },
        {value: "CZK", name: "Czech koruna"},
        {value: "CNY", name: "Chinese Renmenbi"},
        {
            value: "CAD",
            name: "Canadian dollar",
        },
        {value: "BRL", name: "Brazilian real"},
        {value: "AUD", name: "Australian dollar"},
    ],
    btn_css = `box-shadow: inset 0px -3px 7px 0px #161c2e;background-color: #161c2e;border-color: #161c2e;;border-radius:3px;display:inline-block;cursor:pointer;color:#fff;font-family:Arial,serif;font-size:15px;padding:12px 20px;margin:12px 0;text-decoration:none;`,
    product_img_css = `width: 100%; height:45%;text-align:center;margin:0 auto;display:block;`,
    product_div_css = `padding: 12px;height:auto; width:300px;box-sizing:border-box;text-align:center;border-radius:10px;box-shadow:0 0 10px rgba(0, 0, 0, 0.1);transition: .4s;`,
    product_h3_css = `text-align: center;font-size:22px;margin:0;padding-top:10px;`,
    product_h6_css = `font-size: 20px;text-align:center;color:#222f3e;margin:0;`,
    product_p_css = `text-align: center;color:#b2bec3;padding:0 8px;`,
    attr = {
        dropppable: false, copyable: false, removable: false, draggable: false, editable: false
    }
