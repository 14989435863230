import PropTypes from "prop-types";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { IMAGE_SERVER_ENDPOINT } from "../../core/constants/Endpoints";

export function ImageView({
  image_url,
  thumbnail_url,
  opacity,
  aspect_ratio,
  classes,
  image_width,
  image_height,
}) {
  const processed_image_url: string = `${IMAGE_SERVER_ENDPOINT}/${image_width}x${image_height}/${image_url}`;
  const wrapper_style: any = { opacity: opacity || 1 };

  let wrapper_classes;
  if (["1by1", "4by3", "16by9", "21by9"].includes(aspect_ratio)) {
    wrapper_classes = `embed-responsive embed-responsive-${aspect_ratio}`;
  }

  return (
    <div className={wrapper_classes} style={wrapper_style}>
      <div className="embed-responsive-item">
        <LazyLoadImage
          src={processed_image_url}
          placeholderSrc={thumbnail_url}
          className={classes}
          width="100%"
          height="auto"
          effect="blur"
          alt=""
        />
      </div>
    </div>
  );
}

ImageView.propTypes = {
  image_url: PropTypes.string.isRequired,
  thumbnail_url: PropTypes.string,
  classes: PropTypes.string,
  opacity: PropTypes.number,
  aspect_ratio: PropTypes.oneOf(["1by1", "4by3", "16by9", "21by9"]),
  image_width: PropTypes.number,
  image_height: PropTypes.number,
};
ImageView.defaultProps = {
  thumbnail_url: null,
  classes: null,
  aspect_ratio: "1by1",
  opacity: null,
  image_width: 500,
  image_height: 0,
};
