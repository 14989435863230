import PropTypes from "prop-types";
import { useEffect } from "react";
import Modal from "modules/base/components/Modal";
import { closeModalById, openModal } from "modules/base/utilities/Actions";

export function ErrorModal({
  modal_id,
  short_desc,
  suggestion,
  show,
  onClose,
  positive_button,
  negative_button,
}) {
  useEffect(() => {
    if (show) {
      openModal(modal_id);
    } else {
      const modalElement = document.getElementById(modal_id);
      if (modalElement) {
        modalElement.removeEventListener("hidden.bs.modal", onClose);
        closeModalById(modal_id);
      }
    }
  }, [show]);
  let button_spacer: object = null;
  if (positive_button && negative_button) {
    button_spacer = <span className="mx-2" />;
  }

  return (
    <Modal modal_id={modal_id} size="lg">
      <div className="h-100 text-olitt-grey text-center d-flex flex-column align-items-center justify-content-center">
        <p className="mb-3 h3">{short_desc}</p>
        <p className="mb-5 h6 text-muted">{suggestion}</p>
        <div className="d-flex align-items-center justify-content-center">
          {negative_button}
          {button_spacer}
          {positive_button}
        </div>
      </div>
    </Modal>
  );
}

ErrorModal.propTypes = {
  modal_id: PropTypes.string,
  short_desc: PropTypes.string.isRequired,
  suggestion: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  positive_button: PropTypes.instanceOf(Object),
  negative_button: PropTypes.instanceOf(Object),
};

ErrorModal.defaultProps = {
  modal_id: "error-modal",
  negative_button: null,
  positive_button: null,
};
