import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import { Icon } from "../../../base/components/Icons";
import { UI_ROUTES } from "../../CloudoonConstants";
import CloudoonEmailApi from "../../CloudoonEmailApi";
import Stepper from "./Stepper";

const API = new CloudoonEmailApi();

const CreateAccount = (props) => {
  const {
    match: {
      params: { domain_name },
    },
    history,
  } = props;

  const [username, set_username] = React.useState("");
  const [password, set_password] = React.useState("");
  const [is_loading, setIsLoading] = React.useState(false);

  const onUsernameChange = (e) => {
    set_username(e.target.value);
  };

  const onPasswordChange = (e) => {
    set_password(e.target.value);
  };

  const onCreateAccount = () => {
    setIsLoading(true);
    API.createDomainAccount(
      domain_name,
      username ?? "admin",
      password,
      () => {
        setIsLoading(false);
        toast.success("Account created successful");
        history.push(
          UI_ROUTES.SETUP.COMPLETED.replace(":domain_name", domain_name),
        );
      },
      (error) => {
        setIsLoading(false);
        toast.error(error.detail ?? "Error creating account");
      },
    );
  };

  const create_account_button_loader = is_loading ? (
    <span
      className="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    />
  ) : null;

  const create_account_button = (
    <button
      type="button"
      className="btn btn-primary d-flex align-items-center gap-1"
      onClick={onCreateAccount}
      disabled={is_loading}
    >
      {create_account_button_loader}
      <span className="visually-hidden">Loading...</span>
      Create Account
    </button>
  );

  const onBack = React.useCallback(
    () =>
      history.push(
        UI_ROUTES.SETUP.VERIFICATION.replace(":domain_name", domain_name),
      ),
    [history, domain_name],
  );

  const onNext = React.useCallback(
    () =>
      history.push(
        UI_ROUTES.SETUP.COMPLETED.replace(":domain_name", domain_name),
      ),
    [history, domain_name],
  );

  return (
    <div>
      <Stepper
        progressData={{
          step: 2,
          instructions: "Create Admin Account",
          progress: 66,
        }}
        onBack={onBack}
        onNext={onNext}
      />
      <div className="d-flex flex-column align-items-stretch gap-5">
        <div className="d-flex flex-column gap-2">
          <h1 className="fw-bold text-capitalize">Create Admin Account</h1>
          <h5 className="fw-bold text-capitalize text-muted">
            This will be the account you use to manage your email server.
          </h5>
        </div>
        <div className="d-flex flex-column align-items-stretch gap-5">
          <div className="card">
            <h5 className="card-header">Add Admin Account</h5>
            <div className="card-body">
              <div className="d-flex flex-column gap-4">
                <div className="d-flex flex-column gap-2">
                  <label htmlFor="username" className="form-label">
                    <span className="d-block pb-2">Email address</span>
                    <div className="input-group">
                      <input
                        id="username"
                        type="text"
                        value={username}
                        onChange={onUsernameChange}
                        className="form-control"
                        placeholder="Account's username. Defaults to admin"
                        aria-label="Account's username"
                      />
                      <span className="input-group-text">
                        <Icon icon="at" />
                        {domain_name}
                      </span>
                    </div>
                  </label>

                  <label htmlFor="inputPassword" className="form-label">
                    <span className="d-block pb-2">Password</span>
                    <input
                      id="inputPassword"
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={onPasswordChange}
                    />
                  </label>
                </div>

                {create_account_button}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateAccount.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      domain_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default CreateAccount;
