import { API_BASE_URL } from "modules/core/constants/Endpoints";
import { PATH_MAIN_ROUTE } from "modules/core/constants/Routes";
import { URL_WEBSITES } from "modules/website/WebsiteConstants";

/**
 * API Endpoints
 */
export const URL_BILLING = `${API_BASE_URL}/billing`;
export const URL_PAYMENT_METHODS: string = `${URL_BILLING}/methods/`;
export const URL_INVOICES: string = `${URL_BILLING}/invoices/`;
export const URL_SITE_BANNERS: string = `${URL_BILLING}/banners/`;
export const URL_PLANS: string = `${URL_BILLING}/plans/`;
export const URL_SUBSCRIBE: string = `${URL_PLANS}subscribe/`;
export const URL_CREATE_SUBSCRIPTION: string = `${URL_PLANS}:plan_id/create-subscription/`;
export const URL_GET_PAY_URL: string = `${URL_PLANS}checkout/`;
export const URL_COMPLETE_PAYMENT: string = `${URL_PLANS}complete-payment/`;
export const CHECK_PAID_SITE_URL: string = `${URL_PLANS}paid-site/`;
export const URL_CHANGE_BILLING_PLAN: string = `${URL_WEBSITES}:id/change-plan/`;
export const URL_BILLING_CUSTOMERS = `${URL_BILLING}/customers/`;
export const URL_BILLING_CUSTOMER = `${URL_BILLING_CUSTOMERS}:id/`;
export const URL_BILLING_INVOICES = `${URL_BILLING}/invoices/`;
export const URL_BILLING_TRANSACTIONS = `${URL_BILLING}/transactions/`;
export const URL_BILLING_PAYMENT_METHODS = `${URL_BILLING}/payment-methods/`;
export const URL_BILLING_PAYMENT_METHOD = `${URL_BILLING_PAYMENT_METHODS}:id/`;
export const URL_BILLING_CARDS = `${URL_BILLING}/cards/`;
export const URL_BILLING_CARD = `${URL_BILLING_CARDS}:id/`;
export const URL_BILLING_CARD_DEFAULT = `${URL_BILLING_CARD}default/`;
export const URL_BILLING_APPLY_COUPON = `${URL_BILLING}/customers/:id/apply-coupon/`;
export const URL_BILLING_APPLIED_COUPONS = `${URL_BILLING}/customers/:id/applied-coupons/`;
export const URL_BILLING_WALLET = `${URL_BILLING}/customers/:id/billing-wallet/`;
export const URL_BILLING_WALLET_TRANSACTIONS = `${URL_BILLING}/customers/:id/billing-wallet-transactions/`;
export const URL_TOP_UP_WALLET = `${URL_BILLING}/customers/:id/top-up-wallet/`;
export const URL_BILLING_ADDONS = `${URL_BILLING}/addons/`;
export const URL_BILLING_ADDON_PURCHASES = `${URL_BILLING}/addon-purchases/`;

/**
 * Routes
 */
export const PATH_PAYMENT: string = `${PATH_MAIN_ROUTE}/pay`;
export const PATH_BILLING: string = `${PATH_MAIN_ROUTE}/billing`;
export const PATH_BILLING_OVERVIEW: string = `${PATH_BILLING}/overview`;
export const PATH_INVOICE: string = `${PATH_PAYMENT}/invoices/:id`;
export const PATH_SETTLE_INVOICE: string = `${PATH_INVOICE}/settle`;
export const PATH_ENROLL: string = `${PATH_PAYMENT}/account`;
export const PATH_CHECKOUT: string = `${PATH_BILLING}/checkout`;

/**
 * Constants
 */
export const BILLING_ACCOUNTS_DATA: string = "BILLING_ACCOUNTS_DATA";
export const BILLING_PLANS_DATA: string = "BILLING_PLANS_DATA";
export const PAYMENT_TITLE: string = "Proceed With Payment";
export const CHECK_PAID_SITE_DATA: string = "CHECK_PAID_SITE_DATA";
export const PAID_PLUGIN_MESSAGE: string = "Integrate this plugin and more!";
export const PAID_TEMPLATE_MESSAGE: string =
  "Upgrade to :plan_name and use this template and other pro features";
