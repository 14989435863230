import PropTypes from "prop-types";
import React, { useState } from "react";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import { FormEmailInput } from "../../core/components/form-controls/FormEmailInput";
import FormTextInput from "../../core/components/form-controls/FormTextInput";

export function EmailAndPasswordRegistration({ registerWithEmailAndPassword }) {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [is_registering, setIsRegistering] = useState(false);

  function onSubmitRegistrationForm(event) {
    event.preventDefault();
    registerWithEmailAndPassword(
      first_name,
      last_name,
      email,
      password,
      setIsRegistering,
    );
  }

  let register_btn_text;
  if (is_registering) {
    register_btn_text = <ComponentLoadingIndicator />;
  } else {
    register_btn_text = <span className="text-uppercase"> Register </span>;
  }

  return (
    <form className="row g-3" onSubmit={onSubmitRegistrationForm}>
      <div className="col-12">
        <FormTextInput
          name="firstName"
          placeholder="Enter your first name"
          value={first_name}
          is_required
          is_editable={!is_registering}
          onChangeText={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="col-12">
        <FormTextInput
          name="lastName"
          placeholder="Enter your last name"
          value={last_name}
          is_required
          is_editable={!is_registering}
          onChangeText={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="col-12">
        <FormEmailInput
          name="email"
          placeholder="Enter your email address"
          text={email}
          is_required
          is_editable={!is_registering}
          onChangeText={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="col-12">
        <FormTextInput
          name="password"
          type="password"
          placeholder="Enter your password"
          value={password}
          is_required
          is_editable={!is_registering}
          onChangeText={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="col-12">
        <button
          type="submit"
          className="btn btn-olitt-grey w-100 d-flex align-items-center shadow-none"
          disabled={is_registering}
        >
          <div className="d-flex w-100 align-items-center justify-content-center">
            {register_btn_text}
          </div>
        </button>
      </div>
    </form>
  );
}

EmailAndPasswordRegistration.propTypes = {
  registerWithEmailAndPassword: PropTypes.func.isRequired,
};
