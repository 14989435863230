/**
 * Product model
 */
export class Product {
  id: number;

  name: string;

  description: string;

  available_on: string;

  deleted_at: string;

  slug: string;

  meta_description: string;

  meta_keywords: string;

  created_at: string;

  updated_at: string;

  promotionable: boolean;

  meta_title: string;

  discontinue_on: string;

  public_metadata: Object;

  private_metadata: Object;

  display_compare_at_price: string;

  display_price: string;

  purchasable: boolean;

  in_stock: boolean;

  backorderable: boolean;

  available: boolean;

  currency: string;

  price: number;

  compare_at_price: number;

  image_url: string;

  social_image_url: string;

  product_url: string;

  constructor(parameters: Object) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.description = parameters.description;
    this.available_on = parameters.available_on;
    this.deleted_at = parameters.deleted_at;
    this.slug = parameters.slug;
    this.meta_description = parameters.meta_description;
    this.meta_keywords = parameters.meta_keywords;
    this.created_at = parameters.created_at;
    this.updated_at = parameters.updated_at;
    this.promotionable = parameters.promotionable;
    this.meta_title = parameters.meta_title;
    this.discontinue_on = parameters.discontinue_on;
    this.public_metadata = parameters.public_metadata;
    this.private_metadata = parameters.private_metadata;
    this.display_compare_at_price = parameters.display_compare_at_price;
    this.display_price = parameters.display_price;
    this.purchasable = parameters.purchasable;
    this.in_stock = parameters.in_stock;
    this.backorderable = parameters.backorderable;
    this.available = parameters.available;
    this.currency = parameters.currency;
    this.price = parameters.price;
    this.compare_at_price = parameters.compare_at_price;
    this.image_url = parameters.image_url;
    this.social_image_url = parameters.social_image_url;
    this.product_url = parameters.product_url;
  }
}

export { Product as default };
