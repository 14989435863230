import { facebook_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const {fbPixelBlock } = opts;

  fbPixelBlock &&
    bm.add("facebook-pixel-block", {
      label: "Facebook Pixel",
      activate: true,
      select: true,
      category: "Integrations",
      content: { type: "facebook-pixel-component" },
      media: facebook_logo,
    });
};
