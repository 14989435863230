import { serverGeneratePath } from "modules/base/utilities/Navigation";
import { URL_SITE_BANNERS } from "modules/billing/BillingConstants";
import { WebsiteInvoice } from "modules/billing/BillingModels";
import { PrivateApi } from "modules/core/utilities/api/PrivateApi";
import {
  URL_DISCONNECT_CUSTOM_DOMAIN,
  URL_DOMAINS,
  URL_SITE_PAGES,
  URL_WEBPAGES,
  URL_WEBSITES,
  URL_WEBSITES_TEMPLATES,
} from "modules/website/WebsiteConstants";
import {
  Domain,
  Page,
  Site,
  SiteBanner,
  SiteForm,
} from "modules/website/WebsiteModels";

export default class SiteApi extends PrivateApi {
  createWebsiteFromTemplate(
    template_id: number,
    billing_plan: number,
    billing_cycle: string,
    callback: (is_successful: boolean, site_or_error: Site | string) => void,
  ) {
    this.postRequest(
      URL_WEBSITES,
      { template: template_id, billing_plan, billing_cycle },
      (response: any) => {
        const site: any = new Site(response);
        callback(true, site);
      },
      (error_response: any) => {
        const default_error: string = "Unable to create website";
        const error: any =
          error_response.message ?? error_response.detail ?? default_error;
        callback(false, error);
      },
    );
  }

  updateWebsite(
    website: Site,
    onSuccess: (website: Site) => void,
    onFailure: (error: any) => void,
  ) {
    const form_data: any = new FormData();
    form_data.append("name", website.name);
    if (typeof website.favicon !== "string") {
      form_data.append("favicon", website.favicon);
    }
    form_data.append("title", website.title);
    form_data.append("description", website.description);
    form_data.append("is_published", website.is_published);

    this.postFormAPIRequest(
      `${URL_WEBSITES}${website.id}/`,
      (response: any) => {
        const site: any = new Site(response);
        onSuccess(site);
      },
      (error_response: any) => {
        const default_error: string = "Unable to update website";
        const error: any = error_response.detail || default_error;
        onFailure(error);
      },
      form_data,
      this.getMultipartHeaders(),
      "PUT",
    );
  }

  updateWebsiteFields(
    website_id: number,
    fields,
    callback: (is_successful: boolean, website_or_error: Site | string) => void,
    successCallback = () => {},
    errorCallback = () => {},
    websitesFetchParams: Object = null,
  ) {
    let getWebsitesRequestUrl = URL_WEBSITES;
    if (websitesFetchParams) {
      const paramsObj = new URLSearchParams(websitesFetchParams);
      getWebsitesRequestUrl += `?${paramsObj.toString()}`;
    }
    const success_callback: any = (response: any) => {
      const site: any = new Site(response);
      callback(true, site);
      successCallback(site);
    };

    const error_callback: any = (error_response: any) => {
      const default_error: string = "Unable to update website";
      const error: any =
        error_response.message ??
        error_response.detail ??
        error_response.custom_domain ??
        default_error;
      callback(false, error);
      errorCallback(error);
    };

    if (Object.prototype.hasOwnProperty.call(fields, "favicon")) {
      const form_data: any = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        form_data.append(key, value);
      });
      this.postFormWithFileRequest(
        `${URL_WEBSITES}${website_id}/`,
        form_data,
        success_callback,
        error_callback,
        "PATCH",
        [`${URL_WEBSITES}${website_id}/`, URL_WEBSITES, getWebsitesRequestUrl],
      );
    } else {
      this.postRequest(
        `${URL_WEBSITES}${website_id}/`,
        fields,
        success_callback,
        error_callback,
        "PATCH",
        [`${URL_WEBSITES}${website_id}/`, URL_WEBSITES, getWebsitesRequestUrl],
      );
    }
  }

  retrieveWebsite(
    id: number,
    onSuccess: (site: Site) => void,
    onFailure: (error: any) => void,
  ) {
    this.getAPIRequest(
      `${URL_WEBSITES}${id}/`,
      (response: any) => {
        const site: any = new Site(response);
        onSuccess(site);
      },
      (error: any) => onFailure(error.detail || "Error loading website"),
      this.getAuthenticatedHeaders(),
    );
  }

  retrieveWebsite_(
    id: number,
    callback: (is_successful: boolean, website_or_error: Site | string) => void,
  ) {
    this.getAPIRequest(
      `${URL_WEBSITES}${id}/`,
      (response: any) => {
        if (response) {
          const site: any = new Site(response);
          callback(true, site);
        }
      },
      (response: any) => {
        const default_error: string = "Error loading website";
        const error: any = response.detail || default_error;
        callback(false, "");
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * Retrieves websites from the server.
   *
   * @returns {Object} An object containing the retrieved websites, error status, and loading status.
   */
  getWebsites(params) {
    let url = URL_WEBSITES;
    if (params) {
      const paramsObj = new URLSearchParams(params);
      url += `?${paramsObj.toString()}`;
    }
    const { data, error, isLoading } = this.getRequest(url, 0);
    let sites = data?.results || [];
    const total = data?.count || [];
    const site = new Site();
    sites = site.fromArray(sites);
    return { sites, total, error, isLoading };
  }

  /**
   * Fetches a website by its ID.
   *
   * @param {number} id - The ID of the website to fetch.
   * @returns {Object} An object containing the website data, any error that occurred, and a loading state.
   */
  getWebsite(id: number) {
    const { data, error, isLoading } = this.getRequest(`${URL_WEBSITES}${id}/`);
    const site: any = new Site(data);
    return { site, error, isLoading };
  }

  /**
   * Fetches the pages of a website by its ID.
   *
   * @param {number} id - The ID of the website to fetch pages for.
   * @returns {Object} An object containing the pages data, any error that occurred, and a loading state.
   */
  getSitePages(id: number) {
    const url = serverGeneratePath(URL_SITE_PAGES, { id });
    const { data, error, isLoading } = this.getRequest(url);
    const page = new Page();
    const pages = page.fromArray(data);
    return { pages, error, isLoading };
  }

  /**
   * Get pages for a website
   * @param {number} website_id
   * @param {(is_successful: boolean, pages_or_error: Page[] | string) => void} callback
   * @returns {void}
   */
  retrieveWebsitePages(
    website_id: number,
    callback: (is_successful: boolean, pages_or_error: Page[] | string) => void,
  ) {
    this.getAPIRequest(
      `${URL_WEBSITES}${website_id}/pages/`,
      (response: any) => {
        const pages: any = response.map((parameters) => new Page(parameters));
        callback(true, pages);
      },
      (response: any) => {
        const default_error: string = "Error loading pages";
        const error: any = response.detail || default_error;
        return callback(false, error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * Get page for a website
   * @param {number} website_id
   * @param {number} page_id
   * @param {(is_successful: boolean, page_or_error: Page | string) => void} callback
   * @returns {void}
   */
  retrieveWebsitePage(website_id, page_id, callback) {
    this.getAPIRequest(
      `${URL_WEBSITES}${website_id}/pages/${page_id}/`,
      (response) => {
        const page = new Page(response);
        callback(true, page);
      },
      (response) => {
        const default_error = "Error loading page";
        const error = response.detail || default_error;
        return callback(false, error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  retrieveWebsiteInvoices(
    website_id: number,
    callback: (
      is_successful: boolean,
      invoices_or_error: WebsiteInvoice[] | string,
    ) => void,
  ) {
    this.getAPIRequest(
      `${URL_WEBSITES}${website_id}/invoices/`,
      (response: any) => {
        if (response) {
          const invoices: any = response.map(
            (parameters) => new WebsiteInvoice(parameters),
          );
          callback(true, invoices);
        }
      },
      (response: any) => {
        const error: any = this.extractResponseError(response);
        return callback(false, error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  retrieveWebsiteDomains(
    website_id: number,
    callback: (
      is_successful: boolean,
      domains_or_error: Domain[] | string,
    ) => void,
  ) {
    this.getAPIRequest(
      `${URL_WEBSITES}${website_id}/domains/`,
      (response: any) => {
        if (response) {
          callback(true, response);
        }
      },
      (response: any) => {
        const default_error: string = "Error loading domains";
        const error: any = response.detail || default_error;
        return callback(false, error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  disconnectWebsiteDomain(
    id: number,
    callback: (is_deleted: boolean, error: string) => void,
  ) {
    this.deleteAPIRequest(
      `${URL_DOMAINS}${id}${URL_DISCONNECT_CUSTOM_DOMAIN}`,
      () => {
        callback(true, null);
      },
      (error_response) => {
        const default_error: string = "Unable to delete your domain name";
        callback(false, error_response.detail || default_error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  retrieveWebsites(
    is_template: boolean = false,
    callback: (is_successful: boolean, sites_or_error: Site[] | string) => void,
  ) {
    let base_url: any = URL_WEBSITES;
    let headers: any = this.getAuthenticatedHeaders();
    if (is_template) {
      base_url = URL_WEBSITES_TEMPLATES;
      headers = this.getUnauthenticatedHeaders();
    }

    this.getAPIRequest(
      base_url,
      (response: any) => {
        if (response) {
          const sites: any = response.map((parameters) => new Site(parameters));
          callback(true, sites);
        }
      },
      (response: any) => {
        callback(false, this.extractResponseError(response));
      },
      headers,
    );
  }

  deleteWebsite(
    id: number,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void,
    websitesFetchParams: Object,
  ) {
    let getWebsitesRequestUrl = URL_WEBSITES;
    if (websitesFetchParams) {
      const paramsObj = new URLSearchParams(websitesFetchParams);
      getWebsitesRequestUrl += `?${paramsObj.toString()}`;
    }
    this.deleteRequest(
      `${URL_WEBSITES}${id}/`,
      successCallback,
      errorCallback,
      [URL_WEBSITES, getWebsitesRequestUrl],
    );
  }

  upgradeWebsitePlan(
    website_id: number,
    promo_code: string,
    plan_id: number,
    callback: (is_successful: boolean, payment_url_or_error: string) => void,
  ) {
    const makeWhmcsAutologinRequest: any = (payment_url) => {
      this.getAPIRequest(
        payment_url,
        () => {
          callback(true, payment_url);
        },
        (response) => {
          const default_error: string =
            "Unable to upgrade your plan at this time";
          const error: any = response.detail || default_error;
          callback(false, error);
        },
        this.getUnauthenticatedHeaders(),
        false,
      );
    };

    this.postAPIRequest(
      `${URL_WEBSITES}${website_id}/upgrade/`,
      (response) => {
        const { payment_url } = response;
        if (payment_url) {
          makeWhmcsAutologinRequest(payment_url);
        } else {
          callback(true, payment_url);
        }
      },
      (response) => {
        const default_error: string =
          "Unable to upgrade your plan at this time";
        callback(false, response.detail || default_error);
      },
      {
        promo_code,
        plan_id,
      },
      this.getAuthenticatedHeaders(),
    );
  }

  retrieveSiteBanners(
    callback: (
      is_successful: boolean,
      banners_or_error: SiteBanner | string,
    ) => void,
  ) {
    this.getAPIRequest(
      `${URL_SITE_BANNERS}`,
      (response: any) => {
        if (response) {
          const banners: any = response.map(
            (parameters) => new SiteBanner(parameters),
          );
          callback(true, banners);
        }
      },
      (response: any) => {
        const error: any = this.extractResponseError(response);
        callback(false, error);
      },
      this.getUnauthenticatedHeaders(),
    );
  }

  retrieveWebsiteForms(
    site_id: number,
    callback: (
      is_successful: boolean,
      forms_or_error: SiteForm[] | string,
    ) => void,
  ) {
    this.getAPIRequest(
      `${URL_WEBSITES}${site_id}/forms/`,
      (response: any) => {
        const forms: any = response.map(
          (parameters) => new SiteForm(parameters),
        );
        callback(true, forms);
      },

      (response: any) => {
        const error: any = this.extractResponseError(response);
        return callback(false, error);
      },
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * Create new page for a website
   * @param {object} payload
   * @param {(is_successful: boolean, result: any) => void} callback
   */
  createPage(payload, callback) {
    this.postAPIRequest(
      URL_WEBPAGES,
      (response) => {
        callback(true, response);
      },
      (errorResponse) => {
        callback(false, errorResponse);
      },
      payload,
      this.getAuthenticatedHeaders(),
    );
  }

  /**
   * Update page for a website
   * @param {number} page_id
   * @param {object} payload
   * @param {(is_successful: boolean, result: any) => void} callback
   */
  updatePage(page_id, payload, callback) {
    this.postAPIRequest(
      `${URL_WEBPAGES}${page_id}/`,
      (response) => {
        callback(true, response);
      },
      (errorResponse) => {
        callback(false, errorResponse);
      },
      payload,
      this.getAuthenticatedHeaders(),
      "PUT",
    );
  }

  /**
   * Create new page for a website
   * @param {number} page_id
   * @param {(is_successful: boolean, result: any) => void} callback
   */
  deletePage(page_id, callback) {
    this.deleteAPIRequest(
      `${URL_WEBPAGES}${page_id}/`,
      (response) => {
        callback(true, response);
      },
      (errorResponse) => {
        callback(false, errorResponse);
      },
      this.getAuthenticatedHeaders(),
    );
  }
}
