import _ from "lodash";

export default (editor) => {
  const TextType = editor.DomComponents.getType("text");
  if (TextType) {
    const { view: TextTypeView } = TextType;

    editor.DomComponents.addType("text", {
      view: {
        events() {
          return _.extend({}, TextTypeView.prototype.events(), {
            touchend: "onActive",
          });
        },
      },
    });
  }
};
