import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

/**
 * Select Option Card Container
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function SelectOptionCardContainer(props) {
  const {
    disabled,
    section_name,
    value,
    children,
    handleSelectCard,
    id,
    icon,
    icon_position,
    isChecked,
  } = props;
  const [checked, setChecked] = useState(isChecked);
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);
  const handleChecked = useCallback((e) => {
    setChecked(e.target.checked);
  });
  const topIcon =
    icon_position === "top" ? (
      <div className="text-center offset-1">{icon}</div>
    ) : null;
  const bottomIcon =
    icon_position === "bottom" ? (
      <div className="text-center">{icon}</div>
    ) : null;
  const leftIcon = icon_position === "left" ? icon : null;
  const rightIcon = icon_position === "right" ? icon : null;
  const selectCard = (
    <div className="card">
      <div className="card-body">
        {topIcon}
        <div className="d-flex justify-content-between align-items-start">
          {leftIcon}
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id={id}
              name={section_name}
              value={value}
              checked={checked}
              onChange={handleChecked}
            />
            {children}
          </div>
          {rightIcon}
        </div>
        {bottomIcon}
      </div>
    </div>
  );
  return (
    <div className="col">
      <button
        type="button"
        className="btn btn-light w-100 p-1"
        disabled={disabled}
        onClick={handleSelectCard}
      >
        {selectCard}
      </button>
    </div>
  );
}

SelectOptionCardContainer.defaultProps = {
  icon: null,
};

SelectOptionCardContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool.isRequired,
  section_name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleSelectCard: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  icon: PropTypes.element,
  icon_position: PropTypes.oneOf(["left", "right", "top", "bottom"]).isRequired,
  isChecked: PropTypes.bool.isRequired,
};

/**
 * Select Option Card
 * @param {Object} props
 * @param {string|number} props.key
 * @param {string} props.label
 * @param {string} props.subtitle
 * @param {React.ReactNode} props.icon
 * @param {React.ReactNode} props.description
 * @param {string} props.pre_label
 */
function SelectOptionCard(props) {
  const {
    label,
    subtitle,
    icon,
    icon_position,
    description,
    pre_label,
    section_name,
    disabled,
    value,
    onSelect,
    isSelected,
    selected_bg_color,
    unselected_pre_label_text_color_class,
    unselected_label_text_color_class,
    unselected_subtitle_text_color_class,
    unselected_description_text_color_class,
    selected_pre_label_text_color_class,
    selected_label_text_color_class,
    selected_subtitle_text_color_class,
    selected_description_text_color_class,
  } = props;
  const id = uuidv4();
  const getCard = (e) => {
    const card = e.target.closest(".card");
    const cardBody = card.querySelector(".card-body");
    return { card, cardBody };
  };

  function toggleTextColor(
    cardBody,
    input_id,
    element_id_suffix,
    checked,
    selected_class,
    unselected_class,
  ) {
    const element = cardBody.querySelector(
      `[id="${input_id}-${element_id_suffix}"]`,
    );
    if (!element) {
      return;
    }
    if (checked) {
      element.classList.add(selected_class);
      element.classList.remove(unselected_class);
    } else {
      element.classList.add(unselected_class);
      element.classList.remove(selected_class);
    }
  }

  function toggleTextColors(cardBody, input_id, checked) {
    toggleTextColor(
      cardBody,
      input_id,
      "pre-label",
      checked,
      selected_pre_label_text_color_class,
      unselected_pre_label_text_color_class,
    );
    toggleTextColor(
      cardBody,
      input_id,
      "label",
      checked,
      selected_label_text_color_class,
      unselected_label_text_color_class,
    );
    toggleTextColor(
      cardBody,
      input_id,
      "subtitle",
      checked,
      selected_subtitle_text_color_class,
      unselected_subtitle_text_color_class,
    );
    toggleTextColor(
      cardBody,
      input_id,
      "description",
      checked,
      selected_description_text_color_class,
      unselected_description_text_color_class,
    );
  }

  function toggleCard(cardInput, cardBody) {
    const bg_class = `bg-${selected_bg_color}`;
    const input_id = cardInput.id;
    if (cardInput.checked) {
      cardBody.classList.add(bg_class, "bg-gradient");
      toggleTextColors(cardBody, input_id, cardInput.checked);
    } else if (cardBody.classList.contains(bg_class)) {
      cardBody.classList.remove(bg_class, "bg-gradient");
      toggleTextColors(cardBody, input_id, false);
    }
  }

  function getSelectedCard() {
    const cardInput = document.querySelector(
      `input[name="${section_name}"][value="${value}"]`,
    );
    const cardBody = cardInput.closest(".card-body");
    return { cardInput, cardBody };
  }

  function getReactProps(el) {
    const keys = Object.keys(el);
    const propKey = keys.find((key) => key.includes("reactProps"));
    return el[propKey];
  }

  function handleSelectCard(e, trigger_callback = true) {
    const { card, cardBody } = getCard(e);
    const cardInput = card.querySelector(".form-check-input");
    const cardInputs = document.querySelectorAll(
      `input[name="${section_name}"]`,
    );
    cardInputs.forEach((input) => {
      const unselectInput = input;
      if (unselectInput !== cardInput) {
        unselectInput.checked = false;
        getReactProps(unselectInput).onChange({ target: unselectInput });
        toggleCard(unselectInput, getCard({ target: unselectInput }).cardBody);
      }
    });
    cardInput.checked = true;
    getReactProps(cardInput).onChange({ target: cardInput });
    toggleCard(cardInput, cardBody);
    if (trigger_callback) {
      onSelect(cardInput);
    }
  }

  useEffect(() => {
    if (isSelected) {
      const { cardInput } = getSelectedCard();
      handleSelectCard({ target: cardInput }, false);
    }
  }, [isSelected]);
  useEffect(() => {
    if (disabled) {
      const { cardInput, cardBody } = getSelectedCard();
      cardInput.checked = false;
      toggleCard(cardInput, cardBody);
    }
  }, [disabled]);
  const getHandleSelectCard = useCallback((e) => {
    handleSelectCard(e);
  });
  return (
    <SelectOptionCardContainer
      disabled={disabled}
      section_name={section_name}
      value={value}
      handleSelectCard={getHandleSelectCard}
      id={id}
      icon={icon}
      icon_position={icon_position}
      isChecked={isSelected}
    >
      {pre_label && (
        <p
          className={`fs-6 fw-lighter ${unselected_pre_label_text_color_class}`}
          id={`${id}-pre-label`}
        >
          {pre_label}
        </p>
      )}
      <label
        className={`form-check-label fw-semibold ${unselected_label_text_color_class}`}
        htmlFor={id}
        id={`${id}-label`}
      >
        {label}
      </label>
      <p
        className={`fs-6 fw-lighter ${unselected_subtitle_text_color_class}`}
        id={`${id}-subtitle`}
      >
        {subtitle}
      </p>
      {description && (
        <span
          className={`fs-6 fw-lighter text-wrap ${unselected_description_text_color_class}`}
          id={`${id}-description`}
        >
          {description}
        </span>
      )}
    </SelectOptionCardContainer>
  );
}

SelectOptionCard.defaultProps = {
  icon: null,
  icon_position: "right",
  description: null,
  pre_label: null,
  disabled: false,
  value: "",
  onSelect: () => {
    // do nothing
  },
  isSelected: false,
  selected_bg_color: "purple-subtle",
  unselected_pre_label_text_color_class: "text-secondary",
  unselected_label_text_color_class: "text-dark",
  unselected_subtitle_text_color_class: "text-secondary",
  unselected_description_text_color_class: "text-secondary",
  selected_pre_label_text_color_class: "text-secondary",
  selected_label_text_color_class: "text-dark",
  selected_subtitle_text_color_class: "text-secondary",
  selected_description_text_color_class: "text-secondary",
};

SelectOptionCard.propTypes = {
  label: PropTypes.string.isRequired,
  pre_label: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.element,
  icon_position: PropTypes.oneOf(["left", "right", "top", "bottom"]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  section_name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  selected_bg_color: PropTypes.string,
  unselected_pre_label_text_color_class: PropTypes.string,
  unselected_label_text_color_class: PropTypes.string,
  unselected_subtitle_text_color_class: PropTypes.string,
  unselected_description_text_color_class: PropTypes.string,
  selected_pre_label_text_color_class: PropTypes.string,
  selected_label_text_color_class: PropTypes.string,
  selected_subtitle_text_color_class: PropTypes.string,
  selected_description_text_color_class: PropTypes.string,
};

export default SelectOptionCard;
