import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { withRouter } from "react-router-dom";
import CheckboxInput from "modules/base/components/inputs/CheckboxInput";
import SelectInput from "modules/base/components/inputs/SelectInput";
import SwitchInput from "modules/base/components/inputs/SwitchInput";
import TextArea from "modules/base/components/inputs/TextArea";
import TextInput from "modules/base/components/inputs/TextInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import Accordion from "modules/base/layouts/Accordion";
import AccordionItem from "modules/base/layouts/AccordionItem";
import DesignTemplateButton from "modules/marketing/components/Focusitems/DesignTemplateButton";
import DesignTemplateModal from "modules/marketing/components/Focusitems/DesignTemplateModal";
import StyleOptionCards from "modules/marketing/components/Focusitems/StyleOptionCards";
import { useTemplateType } from "modules/marketing/components/Focusitems/TemplatesContext";
import {
  howOftenToEngageOptions,
  styleIconsMap,
  styleOptions,
  typeOptions,
  whenToEngageOptions,
} from "modules/marketing/components/Focusitems/utils";
import MarketingAPI from "modules/marketing/MarketingAPI";

import SiteApi from "modules/website/api/SiteApi";

function FocusItemFormFields(props) {
  const { state, onInputChange, setState, currentModalId, match } = props;

  const { templateType, setTemplateType } = useTemplateType();
  const currentPages = state.pages ?? [];
  const { id: siteId } = match.params;
  state.site = siteId;

  const API = useMemo(() => new MarketingAPI(siteId), [siteId]);
  const siteApi = new SiteApi();
  const {
    forms,
    isLoading: isFormsLoading,
    error: formsFetchError,
  } = API.getForms();
  const {
    pages,
    isLoading: isPagesLoading,
    error: pagesFetchError,
  } = siteApi.getSitePages(siteId);
  const isLoading = isFormsLoading || isPagesLoading;
  const error = formsFetchError || pagesFetchError;
  const onChangeProperties = useCallback(
    (newProperties) => {
      setState((prevState) => ({
        ...prevState,
        properties: {
          ...prevState.properties,
          ...newProperties,
        },
      }));
    },
    [setState],
  );
  const onChangePropertiesContent = useCallback(
    (event) => {
      const newContent = {
        ...state.properties.content,
        [event.target.name]: event.target.value,
      };
      onChangeProperties({
        content: newContent,
      });
    },
    [onChangeProperties],
  );
  const onStopAfterConversionChange = useCallback(
    (event) => {
      onChangeProperties({
        stop_after_conversion: Number(event.target.checked),
      });
    },
    [onChangeProperties],
  );
  const onPagesChange = useCallback((selectedOptions) => {
    const newPages = selectedOptions.map((option) => option.value);
    onInputChange({
      target: {
        name: "pages",
        value: newPages,
      },
    });
  });

  const onFormChange = useCallback(
    (selectedOption) => {
      setState((prevState) => ({
        ...prevState,
        form: selectedOption.value,
        formHtml: selectedOption.html,
      }));
    },
    [setState],
  );
  const onChangeDesign = (event) => {
    if (event.target.checked) {
      if (templateType !== state.type) {
        setTemplateType(state.type);
      }
    }
    if (
      event.target.checked &&
      state.type === "form" &&
      state.form &&
      !state.formHtml
    ) {
      const formHtml = forms.find((form) => form.id === state.form).cachedHtml;
      setState((prevState) => ({
        ...prevState,
        htmlMode: "html",
        formHtml,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        htmlMode: event.target.checked ? "html" : "basic",
      }));
    }
  };

  const formOptions = forms.map((form) => ({
    value: form.id,
    label: form.name,
    html: form.cachedHtml,
  }));
  const pageOptions = pages.map((page) => ({
    value: page.id,
    label: page.page_title,
  }));

  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <DesignTemplateModal state={state} setState={setState} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <TextInput
          name="name"
          title="Name"
          content={state.name ?? ""}
          controlFunc={onInputChange}
          required
        />
        <TextArea
          name="description"
          title="Description"
          content={state.description ?? ""}
          controlFunc={onInputChange}
        />
        <SelectInput
          name="type"
          title="Type"
          options={typeOptions}
          required
          onChange={(selectedOption) => {
            setTemplateType(selectedOption.value);
            onInputChange({
              target: {
                name: "type",
                value: selectedOption.value,
              },
            });
          }}
          predicate={(option) => option.value === state.type}
        />
        <SelectInput
          name="form"
          title="Form"
          options={formOptions}
          required={state.type === "form"}
          isDisabled={state.type !== "form"}
          wrapperClasses={state.type !== "form" ? "d-none" : ""}
          onChange={(selectedOption) => onFormChange(selectedOption)}
          predicate={(option) => option.value === state.form}
        />
        <SelectInput
          name="pages"
          title="Pages to engage"
          options={pageOptions}
          required
          isMulti
          onChange={onPagesChange}
          predicate={(option) => currentPages.includes(option.value)}
        />
        <SelectInput
          name="when"
          title="When to engage"
          required
          options={whenToEngageOptions}
          onChange={(selectedOption) => {
            onChangeProperties({
              when: selectedOption.value,
            });
          }}
          predicate={(option) => option.value === state.properties.when}
        />
        <SelectInput
          name="frequency"
          title="How often to engage"
          required
          options={howOftenToEngageOptions}
          onChange={(selectedOption) => {
            onChangeProperties({
              frequency: selectedOption.value,
            });
          }}
          predicate={(option) => option.value === state.properties.frequency}
        />
        <div className={state.type !== "notice" ? "text-start" : "d-none"}>
          <CheckboxInput
            name="stop_after_conversion"
            title="Stop after conversion?"
            checked={state.properties.stop_after_conversion || false}
            handleChange={onStopAfterConversionChange}
          />
        </div>
        <Accordion classes="mt-4">
          <AccordionItem
            title="Display style"
            id={`focus-item-${state.id}-style`}
          >
            <div className="row row-cols-1 gy-md-3 gy-1 md-3">
              <StyleOptionCards
                styleOptions={styleOptions}
                state={state}
                onInputChange={onInputChange}
                styleIconsMap={styleIconsMap}
              />
            </div>
          </AccordionItem>
        </Accordion>
        <SwitchInput
          label="Design Template"
          name="design"
          handleInputChange={onChangeDesign}
          isChecked={state.htmlMode === "html"}
          wrapperClasses="ms-3"
        />
        <TextInput
          name="headline"
          title="Headline"
          content={state.properties.content.headline ?? ""}
          controlFunc={onChangePropertiesContent}
          wrapperClasses={state.htmlMode === "html" ? "d-none" : ""}
        />
        <TextInput
          name="tagline"
          title="Tagline"
          content={state.properties.content.tagline ?? ""}
          controlFunc={onChangePropertiesContent}
          wrapperClasses={state.htmlMode === "html" ? "d-none" : ""}
        />
        <div
          className={
            state.type === "link" && state.htmlMode !== "html" ? "" : "d-none"
          }
        >
          <TextInput
            name="link_text"
            title="Link Text"
            content={state.properties.content.link_text ?? ""}
            controlFunc={onChangePropertiesContent}
            required={state.type === "link" && state.htmlMode !== "html"}
          />
          <TextInput
            name="link_url"
            title="Link URL"
            content={state.properties.content.link_url ?? ""}
            controlFunc={onChangePropertiesContent}
            required={state.type === "link" && state.htmlMode !== "html"}
            wrapperClasses="mb-3"
          />
          <CheckboxInput
            name="link_new_window"
            title="Open in New Window?"
            checked={state.properties.content.link_new_window}
            handleChange={(event) => {
              setState((prevState) => ({
                ...prevState,
                properties: {
                  ...prevState.properties,
                  content: {
                    ...prevState.properties.content,
                    link_new_window: event.target.checked ? 1 : 0,
                  },
                },
              }));
            }}
          />
        </div>
        <div className={state.htmlMode === "html" ? "" : "d-none"}>
          <DesignTemplateButton
            state={state}
            currentModalId={currentModalId}
            formTemplateHtml={state.formTemplateHtml}
            notificationHtml={state.notificationHtml}
            linkHtml={state.linkHtml}
          />
        </div>
      </div>
    </>
  );
}

FocusItemFormFields.defaultProps = {
  state: {},
  onInputChange: () => {
    // Do nothing
  },
};

FocusItemFormFields.propTypes = {
  state: PropTypes.instanceOf(Object),
  onInputChange: PropTypes.func,
  setState: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string.isRequired,
};

export default withRouter(FocusItemFormFields);
