import React from "react";
import { withRouter } from "react-router-dom";

function Page404() {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
      <h1 className="text-primary">404. You are lost</h1>

      <h4 className="mt-5">May be we can help. What did you intend to do?</h4>
      {/*  ToDo: 404 actions for dns  */}
    </div>
  );
}

export default withRouter(Page404);
