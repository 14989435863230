import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import PrebuiltTemplates from "modules/marketing/components/Focusitems/PreBuiltTemplates";
import { prebuiltFocusTemplatesModalId } from "modules/marketing/components/Focusitems/utils";

function PrebuiltTemplatesModal({ setPrebuiltHtml, uuid }) {
  const modalId = prebuiltFocusTemplatesModalId;

  return (
    <Modal modal_title="Prebuilt Templates" modal_id={modalId} size="xl">
      <PrebuiltTemplates
        setPrebuiltHtml={setPrebuiltHtml}
        uuid={uuid}
        currentModalId={modalId}
      />
    </Modal>
  );
}

PrebuiltTemplatesModal.propTypes = {
  setPrebuiltHtml: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default PrebuiltTemplatesModal;
