import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { IconLoading } from "../../base/components/Icons";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import { ErrorCard } from "../../core/components/ErrorCard";
import FormTextInput from "../../core/components/form-controls/FormTextInput";
import Label from "../../core/components/Label";
import MainNavigationSidebar from "../../core/components/MainNavigationSidebar";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import { ToastUtility, toast_styles } from "../../core/utilities/ToastUtility";
import { DomainApi } from "../DomainsAPIs";

function NameserverList(props) {
  const { domain_name } = props.match.params;
  const { domain_id } = props.match.params;

  const [is_retrieving_ns, setIsRetrievingNs] = useState(false);
  const [retrieve_ns_error, setRetrieveNsError] = useState(null);
  const [update_error, setUpdatingError] = useState(null);
  const [is_updating_ns, setIsUpdatingNs] = useState(false);
  const [nameservers, setNameservers] = useState([]);
  const btn_connect_domain_text: any = is_updating_ns ? (
    <IconLoading text="Updating" />
  ) : (
    "Update"
  );
  const domain_api: any = new DomainApi();
  const retrieveNameservers: any = (current_domain_id) => {
    setIsRetrievingNs(true);
    domain_api.retrieveDomainNameservers(
      current_domain_id,
      (is_successful, ns_or_error) => {
        setIsRetrievingNs(false);
        if (is_successful) {
          setNameservers(ns_or_error);
        } else {
          setRetrieveNsError(ns_or_error);
        }
      },
    );
  };

  useEffect(() => {
    retrieveNameservers(domain_id);
  }, []);

  function updateNameservers(e) {
    e.preventDefault();
    setIsUpdatingNs(true);
    setUpdatingError(null);
    domain_api.updateDomainNameservers(
      domain_id,
      nameservers,
      (is_successful, ns_or_error) => {
        setIsUpdatingNs(false);
        if (is_successful) {
          ToastUtility.toast(
            toast_styles.success,
            "Nameservers updated successfully.",
          );
        } else {
          setUpdatingError(ns_or_error);
        }
      },
    );
  }

  const handleChange: any = (event) => {
    const { name, value } = event.target;
    const form_input_values: any = nameservers;
    form_input_values[name] = value;
    setNameservers((prevState) => {
      return { ...prevState, ...form_input_values };
    });
  };

  const class_names: string =
    "text-center align-items-center justify-content-center col-md-12";
  let main_content;
  if (retrieve_ns_error) {
    main_content = <ErrorCard text={retrieve_ns_error} />;
  } else if (is_retrieving_ns) {
    main_content = (
      <ComponentLoadingIndicator text="Retrieving nameservers..." />
    );
  } else {
    main_content = (
      <form onSubmit={updateNameservers} className={class_names}>
        <div className="form-group row">
          <div className="col-sm-2">
            <Label icon={null} text="Nameserver 1" is_important />
          </div>
          <div className="col-sm-10">
            <FormTextInput
              name="nameserver1"
              placeholder="Enter nameserver 1"
              value={nameservers.nameserver1 || ""}
              is_required
              is_editable
              onChangeText={handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-2">
            <Label icon={null} text="Nameserver 2" is_important />
          </div>
          <div className="col-sm-10">
            <FormTextInput
              name="nameserver2"
              placeholder="Enter Nameserver 2"
              value={nameservers.nameserver2 || ""}
              is_required
              is_editable
              onChangeText={handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-2">
            <Label icon={null} text="Nameserver 3" is_important={false} />
          </div>
          <div className="col-sm-10">
            <FormTextInput
              name="nameserver3"
              placeholder="Enter nameserver 3"
              value={nameservers.nameserver3 || ""}
              is_required={false}
              is_editable
              onChangeText={handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-2">
            <Label icon={null} text="Nameserver 4" is_important={false} />
          </div>
          <div className="col-sm-10">
            <FormTextInput
              name="nameserver4"
              placeholder="Enter nameserver 4"
              value={nameservers.nameserver4 || ""}
              is_required={false}
              is_editable
              onChangeText={handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-2">
            <Label icon={null} text="Nameserver 5" is_important={false} />
          </div>
          <div className="col-sm-10">
            <FormTextInput
              name="nameserver5"
              placeholder="Enter nameserver 5"
              value={nameservers.nameserver5 || ""}
              is_required={false}
              is_editable
              onChangeText={handleChange}
            />
          </div>
        </div>
        <p className="small text-danger">{update_error}</p>
        <button
          className="btn btn-olitt-grey px-4"
          type="submit"
          disabled={is_updating_ns}
        >
          {btn_connect_domain_text}
        </button>
      </form>
    );
  }

  return (
    <SidebarWrapper sidebar={<MainNavigationSidebar />}>
      <div className="card card-body shadow-sm border-0">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5 className="text-olitt-grey font-weight-bold">
            Nameservers for {domain_name}
          </h5>
        </div>
        {main_content}
      </div>
    </SidebarWrapper>
  );
}

NameserverList.prototypes = {
  is_loading_dns_records: PropTypes.bool.isRequired,
};

export default NameserverList;
