import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { UserApi } from "../../../account/AccountAPIs";
import { receiveData } from "../../../core/utilities/redux/actions/actions";
import ShopApi from "../../ShopAPIs";
import { URL_SHOP_DETAILS } from "../../ShopConstants";
import StepItem from "./StepItem";
import StepSection from "./StepSection";
import SiteApi from "modules/website/api/SiteApi";

/**
 * @name OnBoarding
 * @description Facebook onboarding
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function OnBoarding(props) {
  const [businesses, setBusinesses] = React.useState([]);
  const [pages, setPages] = React.useState([]);
  const [catalogs, setCatalogs] = React.useState([]);
  const [instagramAccounts, setInstagramAccounts] = React.useState([]);
  const [isConnecting, setIsConnecting] = React.useState(false);
  const [connecting_item, setConnectingItem] = React.useState({});
  const { shop, setError } = props;
  const dispatch = useDispatch();

  const {
    match: {
      params: { id: site_id },
    },
  } = props;

  const userApi: any = new UserApi();
  const siteApi: any = new SiteApi();
  const shopApi: any = new ShopApi();

  /**
   * @name getCatalogs
   * @description get catalogs
   */
  function getCatalogs() {
    shopApi.retrieveFacebookCatalogs(
      site_id,
      (is_successful, catalogs_or_error) => {
        if (is_successful) {
          setCatalogs(catalogs_or_error);
        } else {
          if (
            ["This user is not connected to facebook. "].includes(
              catalogs_or_error.detail,
            )
          ) {
            return;
          }
          setError(catalogs_or_error);
        }
      },
    );
  }

  /**
   * @name getPages
   * @description get pages
   */
  function getPages() {
    shopApi.retrieveFacebookPages(site_id, (is_successful, pages_or_error) => {
      if (is_successful) {
        setPages(pages_or_error);
      } else {
        if (
          ["This user is not connected to facebook. "].includes(
            pages_or_error.detail,
          )
        ) {
          return;
        }
        setError(pages_or_error);
      }
    });
  }

  /**
   * @name getInstagramAccounts
   * @description get instagram accounts
   */
  function getInstagramAccounts() {
    shopApi.retrieveInstagramAccounts(
      site_id,
      (is_successful, ig_accounts_or_error) => {
        if (is_successful) {
          setInstagramAccounts(ig_accounts_or_error);
        } else {
          if (
            ["This user is not connected to facebook. "].includes(
              ig_accounts_or_error.detail,
            )
          ) {
            return;
          }
          setError(ig_accounts_or_error);
        }
      },
    );
  }

  /**
   * @name getFacebookBusinesses
   * @description get facebook businesses
   */
  function getBusinesses() {
    userApi.retrieveFacebookBusinesses((is_successful, businesses_or_error) => {
      if (is_successful) {
        setBusinesses(businesses_or_error);
      } else {
        if (
          ["This user is not connected to facebook. "].includes(
            businesses_or_error.detail,
          )
        ) {
          return;
        }
        setError(businesses_or_error);
      }
    });
  }

  React.useEffect(() => {
    getBusinesses();
    getPages();
    getCatalogs();
    getInstagramAccounts();
  }, []);

  React.useEffect(() => {
    getPages();
  }, [shop.facebook_business_id]);

  React.useEffect(() => {
    getCatalogs();
  }, [shop.facebook_page_id]);

  React.useEffect(() => {
    getInstagramAccounts();
  }, [shop.facebook_page_id]);

  /**
   * @name handleConnect
   * @description handle connect
   * @param fields {object}
   */
  function handleConnect(fields: Object) {
    setIsConnecting(true);
    setConnectingItem(fields);
    siteApi.updateWebsiteFields(
      site_id,
      fields,
      (is_successful, website_or_error) => {
        if (is_successful) {
          dispatch(
            receiveData(
              URL_SHOP_DETAILS.replace("{site_id}", site_id.toString()),
              { ...shop, ...fields },
            ),
          );
        } else {
          setError(website_or_error);
        }
        setIsConnecting(false);
        setConnectingItem({});
      },
    );
  }

  /**
   * @name getStep
   * @description get step
   * @param id
   * @param title
   * @param description
   * @param item_list
   * @param connected_item_id
   * @param setup_link
   * @param item_field_key
   * @param onConnect
   * @returns {JSX.Element}
   */
  const getStep: any = (
    id,
    title,
    description,
    item_list,
    connected_item_id,
    setup_link,
    item_field_key,
    onConnect,
  ) => {
    let items: Array;
    if (item_list.length === 0) {
      items = [
        <div>
          <p>No {title} found.</p>
          <p>
            Click on{" "}
            <a href={setup_link} target="_blank" rel="noreferrer">
              {setup_link}
            </a>{" "}
            to setup {title}.
          </p>
        </div>,
      ];
    } else {
      items = item_list.map((item: any) => {
        return (
          <StepItem
            item_id={item.id}
            image_url={item.picture_url}
            name={item.name}
            step_title={title}
            setup_link={setup_link}
            onConnect={onConnect}
            connected_item_id={Number.parseInt(connected_item_id, 10)}
            item_field_key={item_field_key}
            isConnecting={isConnecting}
            connecting_item={connecting_item}
          />
        );
      });
    }

    return (
      <StepSection
        key={id}
        id={id}
        name={title}
        description={description}
        step_items={items}
      />
    );
  };

  /**
   * @name getSteps
   * @description get steps
   * @returns {JSX.Element}
   */
  const getSteps: any = () => {
    const steps_list: Array = [
      {
        name: "business",
        title: "Business Manager",
        description: "",
        id: "business",
        item_field_key: "facebook_business_id",
        connected_item_id: shop.facebook_business_id,
        setup_link:
          "https://business.facebook.com/commerce_manager/get_started/",
        items: businesses,
        onConnect: (business_id) =>
          handleConnect({
            facebook_business_id: business_id.toString(),
          }),
      },
      {
        name: "pages",
        title: "Facebook Page",
        description: "",
        id: "pages",
        item_field_key: "facebook_page_id",
        connected_item_id: shop.facebook_page_id,
        setup_link: "https://www.facebook.com/commerce/pages/",
        items: pages,
        onConnect: (page_id) =>
          handleConnect({
            facebook_page_id: page_id.toString(),
          }),
      },
      {
        name: "catalogs",
        title: "Facebook Catalog",
        description: "",
        id: "catalogs",
        item_field_key: "facebook_catalog_id",
        connected_item_id: shop.facebook_catalog_id,
        setup_link: "https://www.facebook.com/commerce/catalogs/",
        items: catalogs,
        onConnect: (catalog_id) =>
          handleConnect({
            facebook_catalog_id: catalog_id.toString(),
          }),
      },
      {
        name: "instagram",
        title: "Instagram Account",
        description: "",
        id: "instagram",
        item_field_key: "instagram_account_id",
        connected_item_id: shop.instagram_account_id,
        setup_link: "https://web.facebook.com/help/1148909221857370",
        items: instagramAccounts,
        onConnect: (instagram_account_id) =>
          handleConnect({
            instagram_account_id: instagram_account_id.toString(),
          }),
      },
    ];

    const steps: Array = steps_list.map((step) => {
      return getStep(
        step.id,
        step.title,
        step.description,
        step.items,
        step.connected_item_id,
        step.setup_link,
        step.item_field_key,
        step.onConnect,
      );
    });

    return (
      <div className="accordion accordion-flush" id="facebook-setup">
        {steps}
      </div>
    );
  };

  const stepsComponent: any = getSteps();

  return (
    <>
      <div className="card mb-3">
        <div className="card-header bg-transparent">Facebook Catalog Setup</div>
        <div className="card-body">{stepsComponent}</div>
      </div>
    </>
  );
}

OnBoarding.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  shop: PropTypes.instanceOf(Object).isRequired,
  setError: PropTypes.func.isRequired,
};

export default withRouter(OnBoarding);
