import PropTypes from "prop-types";

/**
 * MainContainerWrapper
 * @param {Object} props
 * @returns {React.Component}
 */
function MainContainerWrapper(props) {
  const { children } = props;
  return <div className="container-fluid p-0 p-md-4">{children}</div>;
}

MainContainerWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainContainerWrapper;
