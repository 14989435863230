import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import AddRemoveDNC from "modules/marketing/components/campaigns/nodes/configure/actions/AddRemoveDNC";
import ChangeCampaigns from "modules/marketing/components/campaigns/nodes/configure/actions/ChangeCampaigns";
import ChangeStage from "modules/marketing/components/campaigns/nodes/configure/actions/ChangeStage";
import SendEmail from "modules/marketing/components/campaigns/nodes/configure/actions/SendEmail";
import ShowFocusItem from "modules/marketing/components/campaigns/nodes/configure/actions/ShowFocusItem";
import ContactFieldValue from "modules/marketing/components/campaigns/nodes/configure/conditions/ContactFieldValue";
import ConfigureNewNodeForm from "modules/marketing/components/campaigns/nodes/configure/ConfigureNewNodeForm";
import EmailAction from "modules/marketing/components/campaigns/nodes/configure/decisions/EmailAction";
import FormSubmit from "modules/marketing/components/campaigns/nodes/configure/decisions/FormSubmit";
import VisitsPages from "modules/marketing/components/campaigns/nodes/configure/decisions/VisitsPages";

function ConfigureNodeModal({
  campaignEvent,
  setState,
  updateNode,
  updateEvent,
  campaignBuilderModalId,
  onModalReady,
}) {
  const configureNodeMap = {
    "email.send": SendEmail,
    "lead.adddnc": AddRemoveDNC,
    "lead.removednc": AddRemoveDNC,
    "focus.show": ShowFocusItem,
    "email.click": EmailAction,
    "email.open": EmailAction,
    "email.reply": EmailAction,
    "form.submit": FormSubmit,
    "page.pagehit": VisitsPages,
    "email.validate.address": EmailAction,
    "lead.field_value": ContactFieldValue,
    "campaign.addremovelead": ChangeCampaigns,
    "stage.change": ChangeStage,
    new: ConfigureNewNodeForm,
  };
  const ConfigureNodeComponent =
    configureNodeMap[campaignEvent.type] ??
    function EmptyNode() {
      return (
        <div className="alert alert-warning" role="alert">
          <i className="bi bi-cone-striped" /> The configuration for that event
          type is not available yet
        </div>
      );
    };
  const modalId = `configure-${campaignEvent.eventType}-node-${campaignEvent.id}-modal`;
  return (
    <Modal
      modal_title="Configure event"
      modal_id={modalId}
      size="md"
      isDismissible={false}
      onModalReady={onModalReady}
    >
      <ConfigureNodeComponent
        campaignEvent={campaignEvent}
        setState={setState}
        currentModalId={modalId}
        updateNode={updateNode}
        updateEvent={updateEvent}
        campaignBuilderModalId={campaignBuilderModalId}
      />
    </Modal>
  );
}

ConfigureNodeModal.defaultProps = {
  campaignEvent: {
    id: null,
    type: null,
  },
};

ConfigureNodeModal.propTypes = {
  campaignEvent: PropTypes.instanceOf(Object),
  setState: PropTypes.func.isRequired,
  updateNode: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  campaignBuilderModalId: PropTypes.string.isRequired,
  onModalReady: PropTypes.func.isRequired,
};

export default ConfigureNodeModal;
