import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import SelectInput from "modules/base/components/inputs/SelectInput";
import TextArea from "modules/base/components/inputs/TextArea";
import TextInput from "modules/base/components/inputs/TextInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import BuildCampaignButton from "modules/marketing/components/campaigns/BuildCampaignButton";
import BuildCampaignModal from "modules/marketing/components/campaigns/BuildCampaignModal";
import MarketingAPI from "modules/marketing/MarketingAPI";

function CampaignFormFields({
  state,
  onInputChange,
  match,
  setState,
  currentModalId,
  resetBuilderCanvas,
}) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const { segments, error, isLoading } = API.getSegments();
  const [selectedSegmentsIds, setSelectedSegmentsIds] = useState([]);
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      site: siteId,
    }));
  }, [siteId]);

  useEffect(() => {
    setSelectedSegmentsIds(state.lists.map((list) => list.id));
  }, [state]);

  const onSegmentsChange = useCallback(
    (selectedOptions) => {
      setSelectedSegmentsIds(selectedOptions.map((option) => option.value));
      const selectedSegments = selectedOptions.map((option) => ({
        id: option.value,
        name: option.label,
      }));
      setState((prevState) => ({
        ...prevState,
        lists: selectedSegments,
      }));
    },
    [setState],
  );

  const segmentsOptions = segments.map((segment) => ({
    value: segment.id,
    label: segment.name,
  }));

  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <BuildCampaignModal
        state={state}
        setState={setState}
        currentModalId={currentModalId}
        resetBuilderCanvas={resetBuilderCanvas}
      />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <TextInput
          name="name"
          title="Name"
          content={state.name ?? ""}
          controlFunc={onInputChange}
          required
        />
        <TextArea
          name="description"
          title="Description"
          content={state.description ?? ""}
          controlFunc={onInputChange}
        />
        <SelectInput
          name="lists"
          title="Contacts source"
          options={segmentsOptions}
          isMulti
          onChange={onSegmentsChange}
          predicate={(option) => selectedSegmentsIds.includes(option.value)}
        />
        <BuildCampaignButton currentModalId={currentModalId} state={state} />
      </div>
    </>
  );
}

CampaignFormFields.defaultProps = {
  state: {},
  onInputChange: () => {
    // do nothing
  },
  resetBuilderCanvas: false,
};

CampaignFormFields.propTypes = {
  state: PropTypes.instanceOf(Object),
  setState: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string.isRequired,
  resetBuilderCanvas: PropTypes.bool,
};

export default withRouter(CampaignFormFields);
