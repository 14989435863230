import { default as AssetManagerAddons } from "./AssetManagerAddons";
import { default as BlockSearch } from "./BlockAddons";
import { default as ContainerAddons } from "./ComponentBgColor";
import { default as DragModeAddon } from "./DragModeAddon";
import { default as FormAddons } from "./FormAddons";
import { default as GenerateContentAddon } from "./GenerateContentAddon";
import { default as ImageAddons } from "./ImageAddons";
import { default as LinkAddons } from "./LinkAddons";
import { default as UppyPlugin } from "./pluginUppy";

export default (editor, opts = {}) => {
  const options: any = {
    ...{
      linkToLabel: `Add link`,
      linkModalTitle: "Link Settings",
    },
    ...opts,
  };

  AssetManagerAddons(editor, options);
  BlockSearch(editor, options);
  ImageAddons(editor, options);
  UppyPlugin(editor, options);
  ContainerAddons(editor, options);
  LinkAddons(editor, options);
  DragModeAddon(editor, options);
  GenerateContentAddon(editor, options);
  FormAddons(editor, options);
};
