import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Modal from "modules/base/components/Modal";
import CreateStageForm from "modules/marketing/components/stages/CreateStageForm";

function CreateStageModal({ match }) {
  const { id: siteId } = match.params;
  const modalId = "create-stage-modal";
  return (
    <Modal modal_title="Create Stage" modal_id={modalId} size="md">
      <CreateStageForm currentModalId={modalId} siteId={siteId} />
    </Modal>
  );
}

CreateStageModal.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
export default withRouter(CreateStageModal);
