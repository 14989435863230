import { PrivateApi } from "../core/utilities/api/PrivateApi";
import {
  URL_DNS_DOMAINS,
  URL_DNS_DOMAIN_RECORDS,
  URL_DNS_USER,
} from "./DnsConstants";
import { DnsDomain, DnsDomainRecord, DnsUser } from "./DnsModels";

/**
 * Api actions for user management:
 *  - Enroll a user for dns management
 */
export class DnsUserApi extends PrivateApi {
  createUser(
    callback: (is_successful: boolean, user_or_error: DnsUser | string) => void,
  ) {
    this.postAPIRequest(
      URL_DNS_USER,
      (success_response) => {
        callback(true, new DnsUser(success_response));
      },
      (error_response) => {
        callback(false, this.extractResponseError(error_response));
      },
      {},
      this.getAuthenticatedHeaders(),
    );
  }
}

/**
 * Api actions for domain management:
 *  - Create a new domain
 *  - Delete a domain
 */
export class DnsDomainsApi extends PrivateApi {
  createDomain(
    domain: string,
    callback: (
      is_successful: boolean,
      domain_or_error: DnsUser | string,
    ) => void,
  ) {
    this.postAPIRequest(
      URL_DNS_DOMAINS,
      (success_response) => {
        callback(true, new DnsDomain(success_response));
      },
      (error_response) => {
        callback(false, this.extractResponseError(error_response));
      },
      { domain_name: domain },
      this.getAuthenticatedHeaders(),
    );
  }

  deleteDomain(
    domain: string,
    callback: (is_successful: boolean, error: string) => void,
  ) {
    this.deleteAPIRequest(
      `${URL_DNS_DOMAINS}${domain}/`,
      () => {
        callback(true, null);
      },
      (error_response) => {
        callback(false, this.extractResponseError(error_response));
      },
      this.getAuthenticatedHeaders(),
    );
  }
}

/**
 * Api actions for domain record management:
 *  - Add a new domain record
 */
export class DnsRecordsApi extends PrivateApi {
  domain: string;

  constructor(domain: string) {
    super();
    this.domain = domain;
  }

  createRecord(
    record_parameters,
    callback: (
      is_successful: boolean,
      record_or_error: DnsDomainRecord | string,
    ) => void,
  ) {
    this.postAPIRequest(
      URL_DNS_DOMAIN_RECORDS,
      (success_response) => {
        callback(true, new DnsDomainRecord(success_response));
      },
      (error_response) => {
        callback(false, this.extractResponseError(error_response));
      },
      record_parameters,
      this.getAuthenticatedHeaders(),
    );
  }

  deleteRecord(
    record: DnsDomainRecord,
    callback: (is_successful: boolean, error: string) => void,
  ) {
    this.deleteAPIRequest(
      `${URL_DNS_DOMAIN_RECORDS}${record.name}/?type=${record.type}&content=${record.content}`,
      () => {
        callback(true, null);
      },
      (error_response) => {
        callback(false, this.extractResponseError(error_response));
      },
      this.getAuthenticatedHeaders(),
    );
  }
}
