import { data } from "currency-codes";
import { InputType } from "../../../core/constants/Enumerators";
import { FormInput, LabeledFormInput } from "../../../core/models/FormInput";
import { Shop } from "../../models/Shop";

/**
 * @name BasicInfoInputs
 * @param shop
 * @returns {FormInput[]}
 * @constructor
 */
export default function BasicInfoInputs(shop: Shop) {
  const currency_options: any = data.map((currency) => ({
    value: currency.code,
    label: currency.currency,
  }));
  return [
    new FormInput(
      InputType.text,
      "name",
      "Name",
      "Name of the store",
      shop.name,
      true,
      true,
    ),
    new FormInput(
      InputType.text,
      "url",
      "Url",
      "Link to the store",
      shop.url,
      true,
      false,
    ),
    new FormInput(InputType.file, "logo", "Logo", null, null, false, true),
    new FormInput(
      InputType.file,
      "mailer_logo",
      "Mailer Logo (JPG or PNG images only)",
      null,
      null,
      false,
      true,
    ),
    new FormInput(
      InputType.file,
      "favicon_image",
      "Favicon (File format: PNG or ICO. File resolution: up to 256x256. File size: up to 1MB)",
      null,
      null,
      false,
      true,
    ),
    new FormInput(
      InputType.email,
      "mail_from_address",
      "Mail from address",
      "",
      shop.mail_from_address,
      true,
      true,
    ),
    new FormInput(
      InputType.email,
      "customer_support_email",
      "Customer support email",
      "",
      shop.customer_support_email,
      false,
      true,
    ),
    new FormInput(
      InputType.email,
      "new_order_notification_email",
      "New order notification email",
      "",
      shop.new_order_notification_email,
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "facebook",
      "Facebook",
      "",
      shop.facebook,
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "twitter",
      "Twitter",
      "",
      shop.twitter,
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "instagram",
      "Instagram",
      "",
      shop.instagram,
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "seo_title",
      "SEO Title",
      "SEO Title",
      shop.seo_title,
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "meta_description",
      "Meta Description",
      "Meta Description",
      shop.meta_description,
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "meta_keywords",
      "Meta Keywords",
      "Meta Keywords",
      shop.meta_keywords,
      false,
      true,
    ),
    new LabeledFormInput({
      type: InputType.select,
      name: "default_currency",
      label: "Default currency",
      placeholder: "Default currency",
      value: shop.default_currency,
      is_required: true,
      is_editable: true,
      options: currency_options,
    }),
    new LabeledFormInput({
      type: InputType.select,
      name: "supported_currencies_list",
      label: "Supported currencies",
      placeholder: "Supported currencies",
      value: shop.supported_currencies_list,
      is_required: true,
      is_editable: true,
      options: currency_options,
      selectIsMulti: true,
    }),
    new FormInput(
      InputType.textarea,
      "description",
      "Description",
      null,
      shop.description,
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "address",
      "Address",
      "Store physical address",
      shop.address,
      false,
      true,
    ),
    new FormInput(
      InputType.text,
      "contact_phone",
      "Contact phone",
      "Store contact phone",
      shop.contact_phone,
      false,
      true,
    ),
  ];
}
