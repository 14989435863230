import BaseModel from "modules/core/models/BaseModel";

/**
 * @class User
 * @description User model
 */
export class User extends BaseModel {
  id: string;

  first_name: string;

  last_name: string;

  name: string;

  email: string;

  currency: string;

  is_code_editor_enabled: boolean;

  country: string;

  state: string;

  city: string;

  postcode: string;

  address: string;

  phone: string;

  spree_password: string;

  facebook_access_token: string;

  constructor(parameters: Object = {}) {
    super(parameters);
    this.id = parameters.id;
    this.first_name = parameters.first_name;
    this.last_name = parameters.last_name;
    if (this.first_name && this.last_name) {
      this.name = `${this.first_name} ${this.last_name}`;
    } else {
      this.name = this.first_name ?? this.last_name;
    }
    this.email = parameters.email;
    this.currency = parameters.currency;
    this.is_code_editor_enabled = parameters.is_code_editor_enabled;
    this.is_news_letter_enabled = parameters.is_news_letter_enabled;
    this.spree_password = parameters.spree_password;
    this.facebook_access_token = parameters.facebook_access_token;

    this.country = parameters.country;
    this.state = parameters.state;
    this.city = parameters.city;
    this.postcode = parameters.postcode;
    this.address = parameters.address;
    this.phone = parameters.phone;
  }
}

export class AuthenticationTokens {
  access_token: string;

  refresh_token: string;

  constructor(parameters: any) {
    this.access_token = parameters.access;
    this.refresh_token = parameters.refresh;
  }
}

/**
 * @class FacebookBusiness
 * @description Facebook business model
 */
export class FacebookBusiness {
  id: string;

  name: string;

  picture: Object;

  picture_url: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.picture = parameters.picture;
    this.picture_url = parameters.picture_url;
  }
}

/**
 * @class StudioSSO
 * @description Studio SSO model
 */
export class StudioSSO extends BaseModel {
  auto_login_url: string;

  constructor(parameters: Object = {}) {
    super(parameters);
    this.auto_login_url = parameters.auto_login_url;
  }
}
