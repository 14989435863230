import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import PropTypes from "prop-types";
import { Bar, Line, Pie } from "react-chartjs-2";
import { WidgetData } from "modules/marketing/MarketingModels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const normaliseData = (data) => {
  const chartInfo = data.chartData ?? data.chartItems?.[0];

  if (!chartInfo) {
    return { chartType: "unknown", labels: [], datasets: [] };
  }

  return {
    chartType: data.chartType,
    labels: chartInfo.labels,
    datasets: chartInfo.datasets.map((dataset) => ({
      ...dataset,
      fill: true,
      lineTension: 0.1,
      borderWidth: 2,
    })),
  };
};

const BaseChart = ({ chartData }) => {
  const { success, data } = chartData;

  const formattedChartData = normaliseData(data);

  if (!formattedChartData) {
    return <div>Invalid data format</div>;
  }

  const renderChart = () => {
    switch (formattedChartData.chartType) {
      case "bar":
        return (
          <Bar
            data={formattedChartData}
            options={{ maintainAspectRatio: false }}
          />
        );
      case "pie":
        return (
          <Pie
            data={formattedChartData}
            options={{ maintainAspectRatio: false }}
          />
        );
      default:
        return (
          <Line
            data={formattedChartData}
            options={{ maintainAspectRatio: false }}
          />
        );
    }
  };

  return <div className="vh-40">{renderChart()}</div>;
};

BaseChart.propTypes = {
  chartData: PropTypes.shape({
    success: PropTypes.bool.isRequired,
    data: PropTypes.instanceOf(WidgetData),
  }).isRequired,
};

export default BaseChart;
