import PropTypes from "prop-types";
import EditAssetForm from "./EditAssetForm";
import Modal from "modules/base/components/Modal";
import { Asset } from "modules/marketing/MarketingModels";

function EditAssetModal({ row }) {
  const modalId = `edit-asset-${row.id}-modal`;
  return (
    <Modal modal_title="Edit File" modal_id={modalId} size="md">
      <EditAssetForm asset={row} currentModalId={modalId} />
    </Modal>
  );
}

EditAssetModal.propTypes = {
  row: PropTypes.instanceOf(Asset).isRequired,
};
export default EditAssetModal;
