function BlockedPopupInstructions() {
  return (
    <div className="container">
      <div className="alert alert-warning mt-4">
        <h4 className="alert-heading">
          Your browser is currently blocking popups
        </h4>
        <p>
          Please allow popups for this site to continue using the application.
        </p>
        <div className="d-grid d-md-block">
          <button
            type="button"
            className="btn btn-gray-900"
            onClick={() => window.location.reload()}
          >
            Refresh
          </button>
        </div>
      </div>
    </div>
  );
}

export default BlockedPopupInstructions;
