import PropTypes from "prop-types";
import React from "react";

/** @name StepItem
 * Facebook Step Item Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function StepItem(props) {
  const {
    item_id,
    connected_item_id,
    item_field_key,
    image_url,
    name,
    onConnect,
    isConnecting,
    connecting_item,
  } = props;

  let button: any = (
    <button
      className="btn btn-primary"
      onClick={() => onConnect(item_id)}
      type="button"
    >
      Connect
    </button>
  );

  if (item_id === connected_item_id) {
    button = (
      <button className="btn btn-success" disabled type="button">
        Connected
      </button>
    );
  } else if (isConnecting && connecting_item[item_field_key] === item_id) {
    button = (
      <button className="btn btn-warning" disabled type="button">
        Connecting...
      </button>
    );
  } else if (isConnecting && connecting_item[item_field_key] !== item_id) {
    button = (
      <button className="btn btn-primary" type="button" disabled>
        Connect
      </button>
    );
  }
  return (
    <div key={item_id} className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-2">
            <img src={image_url} alt={name} height="50" width="50" />
          </div>
          <div className="col-md-8 row">
            <div className="col-12">{name}</div>
            <div className="col-12">ID: {item_id}</div>
          </div>
          <div className="col-md-2 d-flex align-items-center justify-content-end">
            {button}
          </div>
        </div>
      </div>
    </div>
  );
}

StepItem.propTypes = {
  item_id: PropTypes.number.isRequired,
  connected_item_id: PropTypes.number.isRequired,
  item_field_key: PropTypes.string.isRequired,
  image_url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onConnect: PropTypes.func.isRequired,
  isConnecting: PropTypes.bool.isRequired,
  connecting_item: PropTypes.instanceOf(Object).isRequired,
};

export default StepItem;
