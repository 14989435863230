import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import { closeModalById } from "modules/base/utilities/Actions";
import ChangeSegmentsFields from "modules/marketing/components/contacts/ChangeSegmentsFields";
import MarketingAPI from "modules/marketing/MarketingAPI";

function ChangeSegments({ selectedContactsIds, match, currentModalId }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [noOfSegmentsInProgress, setNoOfSegmentsInProgress] = useState(0);
  const [success, setSuccess] = useState(null);
  const [errors, setErrors] = useState([]);
  const [state, setState] = useState({
    segmentsToAdd: [],
    segmentsToRemove: [],
  });
  useEffect(() => {
    if (noOfSegmentsInProgress > 0) {
      setIsSubmitting(true);
    } else {
      if (errors.length === 0 && isSubmitting) {
        setErrors([]);
        closeModalById(currentModalId);
        setSuccess({ message: "Segments changed successfully" });
      }
      setIsSubmitting(false);
    }
  }, [noOfSegmentsInProgress]);
  const changeSegmentContacts = useCallback((segmentId, action) => {
    setNoOfSegmentsInProgress((prev) => prev + 1);
    const addContactIds = action === "add" ? selectedContactsIds : [];
    const removeContactIds = action === "remove" ? selectedContactsIds : [];
    API.changeSegmentContacts(
      segmentId,
      addContactIds,
      removeContactIds,
      () => {
        setNoOfSegmentsInProgress((prev) => prev - 1);
      },
      (error_response) => {
        setErrors((prev) => [
          ...prev,
          { message: error_response.message, id: segmentId },
        ]);
        setNoOfSegmentsInProgress((prev) => prev - 1);
      },
    );
  });
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors([]);
    state.segmentsToAdd.forEach((segmentId) => {
      changeSegmentContacts(segmentId, "add");
    });
    state.segmentsToRemove.forEach((segmentId) => {
      changeSegmentContacts(segmentId, "remove");
    });
  });
  const errorMessages = errors.map((error) => (
    <NetworkMessageDisplay key={error.id} error={error} />
  ));
  return (
    <Form
      handleSubmit={handleSubmit}
      id="create-segments-form"
      button_color="gray-900"
      button_label="Change"
      fullLengthButton
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      {errorMessages}
      <SuccessActionIndicator success={success} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <ChangeSegmentsFields state={state} setState={setState} />
      </div>
    </Form>
  );
}

ChangeSegments.defaultProps = {
  currentModalId: null,
};

ChangeSegments.propTypes = {
  selectedContactsIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string,
};

export default withRouter(ChangeSegments);
