import $ from "jquery";
import LinkToModalContent from "./LinkToModalContent";

export default (editor, options = {}) => {
  const opts = {
    ...{
      buttonLinkCommand: "add-button-link",
    },
    ...options,
  };
  const { buttonLinkCommand, linkModalTitle, linkToLabel } = opts;
  const domc = editor.DomComponents;
  const typeImage = domc.getType("link").model;
  domc.addType("link", {
    model: {
      initToolbar() {
        typeImage.prototype.initToolbar.apply(this, arguments);
        const tb = this.get("toolbar");
        const tbLnkExists = tb.some(
          (item) => item.command === buttonLinkCommand,
        );
        if (!tbLnkExists) {
          tb.push({
            command: buttonLinkCommand,
            label: linkToLabel,
          });
        }
        this.set("toolbar", tb);
      },
    },
  });

  editor.Commands.add(buttonLinkCommand, {
    run() {
      this.editor = editor;
      this.options = opts;
      this.target = opts.target || editor.getSelected();
      const { target } = this;
      this.$ = editor.$;
      this.content = "";
      this.pfx = editor.getConfig("stylePrefix");
      this.url = "";
      this.targetWindow = "";

      if (target && target.get("editable")) {
        this.showLinkInputModal();
      }
    },
    showLinkInputModal() {
      const { target } = this;
      const title = options.title || linkModalTitle;
      this.getPrevValues(target);
      const content = this.getContent();
      editor.Modal.open({ title, content })
        .getModel()
        .once("change:open", () => {
          editor.stopCommand(this.id);
        });
    },
    getPrevValues(target) {
      this.url =
        typeof target.get("attributes").href === "undefined"
          ? "#"
          : target.get("attributes").href;
      this.targetWindow = target.get("attributes").target;
    },
    getContent() {
      this.options.url = this.url;
      this.options.targetWindow = this.targetWindow;
      this.options.setUrl = this.setUrl;
      this.options.saveSettings = this.saveSettings;
      this.options.setTargetWindow = this.setTargetWindow;
      const linkToModal = new LinkToModalContent(editor, this.options);
      const content = $(linkToModal.render());
      content.find("#link-to-input").on("keyup", (e) => this.setUrl(e));
      content
        .find("#link-to-target")
        .on("change", (e) => this.setTargetWindow(e));
      content.find("#add-link").on("click", (e) => this.saveSettings(e));

      return content;
    },
    setUrl(e) {
      this.url = e.currentTarget.value;
    },
    setTargetWindow(e) {
      this.targetWindow = e.currentTarget.value;
    },
    saveSettings(e) {
      const { target, url, targetWindow } = this;
      target.set("attributes", { href: url, target: targetWindow });
      editor.Modal.close();
    },
  });
};
