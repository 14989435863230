import React, { useEffect, useMemo, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { ErrorModal } from "../../core/components/ErrorModal";
import { navigateToPage } from "../../core/utilities/navigation/Navigator";
import ComponentLoadingIndicator from "../components/ComponentLoadingIndicator";
import {
  PATH_WEBSITE_PAGE,
  PATH_WEBSITE_STUDIO,
  PATH_WEBSITE_TEMPLATES,
} from "../WebsiteConstants";
import { Page } from "../WebsiteModels";
import SiteApi from "modules/website/api/SiteApi";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function WebsiteCreator(props) {
  let query = useQuery();

  const billing_plan = query.get("billing_plan");
  const billing_cycle = query.get("billing_cycle");
  const { template_id } = props.match.params;

  const [text, setText] = useState("Initializing");
  const [error, setError] = useState(null);

  const dismissError: any = () => {
    setError(null);
    navigateToPage(PATH_WEBSITE_TEMPLATES, props);
  };
  const navigateToEditor: any = (
    website_id: number,
    isStudioProject: boolean,
    pages: Page[],
  ) => {
    const start_page: Page = pages.find((page) => page.is_start_page);
    if (start_page) {
      const path: any = PATH_WEBSITE_PAGE.replace(":id", website_id).replace(
        ":page_id",
        start_page.id,
      );
      const studioPath: string = PATH_WEBSITE_STUDIO.replace(":id", website_id);
      if (isStudioProject) {
        navigateToPage(studioPath, props);
      } else {
        navigateToPage(path, props);
      }
    } else {
      setError("Unable to load website homepage in editor");
    }
  };

  const site_api: any = new SiteApi();
  const retrieveWebsitePages: any = (
    website_id: number,
    isStudioProject: boolean,
  ) => {
    setText("Retrieving Pages");
    site_api.retrieveWebsitePages(
      website_id,
      (is_successful, pages_or_error) => {
        if (is_successful) {
          navigateToEditor(website_id, isStudioProject, pages_or_error);
        } else {
          setError(pages_or_error);
        }
      },
    );
  };

  const createWebsiteFromTemplate: any = () => {
    setText("Creating Website");
    site_api.createWebsiteFromTemplate(
      template_id,
      billing_plan,
      billing_cycle,
      (is_successful, site_or_error) => {
        if (is_successful) {
          retrieveWebsitePages(site_or_error.id, site_or_error.isStudioProject);
        } else {
          setError(site_or_error);
        }
      },
    );
  };

  useEffect(() => createWebsiteFromTemplate(), []);

  const close_modal_button: any = (
    <button
      type="button"
      className="btn btn-olitt-grey px-4"
      data-dismiss="modal"
      onClick={dismissError}
    >
      Close
    </button>
  );

  return (
    <div className="min-vh-100  d-flex align-items-center justify-content-center">
      <ComponentLoadingIndicator text={text} />
      <ErrorModal
        modal_id="error-modal"
        show={error !== null}
        onClose={dismissError}
        positive_button={close_modal_button}
        short_desc="Something went wrong while creating your website."
        suggestion={error || ""}
      />
    </div>
  );
}

export default withRouter(WebsiteCreator);
