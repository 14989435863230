import React from "react";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import SidebarShop from "../components/SidebarShop";
import TaxonomiesView from "../components/taxonomies/TaxonomiesView";

/**
 * Shop taxonomies layout
 * @returns {*}
 * @constructor
 */
function Taxonomies() {
  return (
    <SidebarWrapper sidebar={<SidebarShop />}>
      <TaxonomiesView />
    </SidebarWrapper>
  );
}

export default Taxonomies;
