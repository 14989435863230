import BaseClaimedCoupons from "modules/base/components/billing/BaseClaimedCoupons";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import { BillingAPI } from "modules/billing/BillingAPIs";

function ClaimedCoupons() {
  const billingAPI = new BillingAPI();
  const { coupons, error, isLoading } = billingAPI.getAppliedCoupons("i");

  if (isLoading) {
    return <NetworkActivityIndicator />;
  }

  return <BaseClaimedCoupons coupons={coupons} error={error} />;
}

export default ClaimedCoupons;
