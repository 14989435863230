import isString from "underscore/modules/isString";

export const ParallaxBackgroundBlock = (editor, opts = {}) => {
    const bm = editor.BlockManager;
    bm.add("parallax-bg-component", {
        label: "Parallax Background",
        activate: true,
        select: true,
        category: "Extra",
        content: {type: "parallax-bg-component"},
        media: '<i class="oicon-parallax" style="font-size: 42px;"></i>'
    });
};


export default (editor, opts = {}) => {
    const domc = editor.DomComponents;
    const {
        parallaxBgComponent,
        parallax_bg = "https://unsplash.it/1800/900?image=1051"
    } = opts;

    domc.addType("parallax-bg-component", {
        model: {
            defaults: {
                tagName: "div",
                name: "Parallax Image",
                draggable: "#wrapper",
                droppable: false,
                resizable: true,
                parallax_bg: parallax_bg,
                components: `<div data-parallax-bg="true" style="height: 500px;background-image: url(${parallax_bg});background-position: center;background-size: cover;"></div>`,
                traits: [
                    {
                        name: "parallax_bg",
                        label: "Image URl",
                        placeholder: "Enter image url",
                        changeProp: 1,
                        attributes: {style: 'display: none'}
                    },
                    {
                        type: 'button',
                        text: 'Select Image',
                        label: 'Parallax Image',
                        full: true,
                        command: editor => editor.runCommand('open-assets', {
                            target: this,
                            types: ['image'],
                            accept: 'image/*',
                            onSelect: asset => {
                                editor.Modal.close();
                                const url = isString(asset) ? asset : asset.get('src');
                                const component = editor.getSelected();
                                component.updateTrait('parallax_bg', {
                                    value: url,
                                });
                            }
                        }),
                    },
                ],
                script: function () {
                    $(document).ready((function () {
                        !function () {
                            if ("requestAnimationFrame" in window && !/Mobile|Android/.test(navigator.userAgent)) {
                                var n = [];
                                if ($("[data-parallax-bg]").each((function () {
                                    var e = $(this), t = $("<div>");
                                    t.css({
                                        backgroundImage: e.css("background-image"),
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        position: "absolute",
                                        height: "200%",
                                        width: "100%",
                                        top: 0,
                                        left: 0,
                                        zIndex: -100
                                    }), t.appendTo(e), n.push(t[0]), e.css({
                                        position: "relative",
                                        background: "transparent",
                                        overflow: "hidden"
                                    })
                                })), n.length) {
                                    var e, t = [];
                                    $(window).on("scroll resize", o), o()
                                }
                            }

                            function o() {
                                t.length = 0;
                                for (var o = 0; o < n.length; o++) {
                                    var a = n[o].parentNode.getBoundingClientRect();
                                    a.bottom > 0 && a.top < window.innerHeight && t.push({rect: a, node: n[o]})
                                }
                                cancelAnimationFrame(e), t.length && (e = requestAnimationFrame(i))
                            }

                            function i() {
                                for (var n = 0; n < t.length; n++) {
                                    var e = t[n].rect, o = t[n].node,
                                        i = Math.max(e.bottom, 0) / (window.innerHeight + e.height);
                                    o.style.transform = "translate3d(0, " + -50 * i + "%, 0)"
                                }
                            }
                        }()
                    }));
                }
            },
            init() {
                this.on("change:parallax_bg", () => valuesOnChange());
                const valuesOnChange = () => {
                    const parallax_bg = this.get("parallax_bg");
                    const new_bg =`<div data-parallax-bg="true" style="height: 500px;background-image: url(${parallax_bg});background-position: center;background-size: cover;"></div>`;
                    this.components(new_bg);
                }
            },
            ...parallaxBgComponent,
        },
        view: {},
    });
};
