import { ApmRoute as Route } from "@elastic/apm-rum-react";
import React from "react";
import { Switch } from "react-router-dom";
import Page404 from "./layouts/Page404";
import Referrals from "./layouts/Referrals";
import { PATH_REFERRAL } from "./ReferralConstants";

function ReferralRouter() {
  return (
    <Switch>
      <Route key={5.1} exact path={PATH_REFERRAL} component={Referrals} />
      <Route key={5.2} path="*" component={Page404} />
    </Switch>
  );
}

export default ReferralRouter;
