import PropTypes from "prop-types";
import React from "react";
import FacebookLoginButton from "react17-facebook-login/dist/facebook-login-render-props";
import { useAuth } from "../../../../AuthProvider";
import { AuthenticationActions } from "modules/account/AccountActions";
import { UserApi } from "modules/account/AccountAPIs";
import ActionProcessing from "modules/core/components/ActionProcessing";
import FacebookLogo from "modules/shop/assets/facebook_logo.png";
import {
  FACEBOOK_APP_ID,
  FACEBOOK_LOGIN_FIELDS,
  FACEBOOK_PERMISSION_SCOPE,
} from "modules/shop/ShopConstants";

/**
 * @name FacebookLogin
 * @description Facebook login button
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function FacebookLogin(props) {
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const { callback } = props;
  const userApi: any = new UserApi();
  const accountActions: any = new AuthenticationActions();
  const auth = useAuth();

  /**
   * name facebookLoginCallback
   * @description Facebook login callback
   * @param callback_results
   */
  const facebookLoginCallback: any = (callback_results) => {
    userApi.loginWithFacebook(
      callback_results,
      (is_successful, tokens_or_error) => {
        if (is_successful) {
          accountActions.storeAuthenticationTokens(tokens_or_error).then(() => {
            auth.login(tokens_or_error, () => callback(is_successful));
          });
        } else {
          callback(is_successful);
        }
      },
    );
  };

  const loginButton: any = (renderProps) => {
    if (isLoggingIn) {
      return <ActionProcessing processing_label="Working" />;
    }
    return (
      <button
        onClick={renderProps.onClick}
        className="btn btn-facebook d-flex align-items-center"
        type="button"
      >
        <img src={FacebookLogo} alt="Facebook Logo" width={18} height={18} />
        <span className="ms-2 text-white">Login With Facebook</span>
      </button>
    );
  };

  return (
    <FacebookLoginButton
      appId={FACEBOOK_APP_ID}
      autoLoad={false}
      fields={FACEBOOK_LOGIN_FIELDS}
      scope={FACEBOOK_PERMISSION_SCOPE}
      version="13.0"
      authType="rerequest"
      onClick={() => setIsLoggingIn(true)}
      callback={facebookLoginCallback}
      responseType="token"
      render={(renderProps) => loginButton(renderProps)}
    />
  );
}

FacebookLogin.propTypes = {
  callback: PropTypes.func.isRequired,
};

export default FacebookLogin;
