import PropTypes from "prop-types";
import React from "react";

export default function Backdrop({ onClick }) {
  const style: { zIndex: number, background: string } = {
    zIndex: 100,
    background: "rgba(9, 11, 18, 0.3)",
  };
  return (
    <div
      className="position-fixed min-vh-100 min-vw-100"
      style={style}
      onClick={onClick}
      aria-hidden="true"
    />
  );
}

Backdrop.propTypes = { onClick: PropTypes.func.isRequired };
