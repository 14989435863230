import PropTypes from "prop-types";
import React from "react";
import { Icon } from "../../base/components/Icons";
import { DateUtility } from "../../core/utilities/DateUtility";

export default function FormSubmissionCard({
  form_submission,
  is_expanded,
  onExpand,
}) {
  let wrapper_classes: string = "w-100 card mb-4";
  let header_classes: string =
    "card-header d-flex align-items-center border-bottom-0 bg-white py-4";
  let expand_btn_classes: string =
    "btn btn-outline-olitt-grey shadow-none ms-4";
  let expand_btn_icon: string = "chevron-down";
  let collapse_row_classes: string = "row card-body pt-3 collapse";
  if (is_expanded) {
    wrapper_classes = `${wrapper_classes} shadow`;
    header_classes = header_classes.replace(
      "bg-white",
      "bg-olitt-grey text-white shadow-sm",
    );
    expand_btn_classes = `${expand_btn_classes} text-white border-white`;
    expand_btn_icon = "chevron-up";
    collapse_row_classes = "row card-body pt-3 collapse show";
  }

  const formatDate: any = (timestamp) => {
    const date: any = new Date(timestamp);
    return `${DateUtility.formatDate(date, false)}, ${date.getHours()}
          : ${date.getMinutes()}
          : ${date.getSeconds()}`;
  };

  const { form_data } = form_submission;
  let form_data_content;
  form_data_content = Object.keys(form_data).map((data, index) => {
    let data_row;
    if (data !== "form_name") {
      data_row = (
        <>
          <strong className="text-muted d-block mb-2">
            {data}: {form_data[data]}
          </strong>
        </>
      );
    }
    return data_row;
  });

  return (
    <div className={wrapper_classes}>
      <div className={header_classes}>
        <div className="flex-grow-1 d-flex align-items-center justify-content-between">
          <strong className="d-none d-md-inline">
            {form_submission.form_data.form_name}:{" "}
            {formatDate(form_submission.time_created)}
          </strong>
        </div>

        <button className={expand_btn_classes} type="button" onClick={onExpand}>
          <Icon icon={expand_btn_icon} />
        </button>
      </div>

      <div
        className={collapse_row_classes}
        id={`id_${form_submission.id}`}
        data-parent="#accordion"
      >
        <div className="col-md-12  my-1">
          <div className="card card-body border-0 bg-light mb-2">
            {form_data_content}
          </div>
        </div>
      </div>
    </div>
  );
}

FormSubmissionCard.propTypes = {
  form_submission: PropTypes.instanceOf(Object).isRequired,
  is_expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
};
