import _ from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, {
  Background,
  Controls,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import {
  addCampaignEvent,
  deleteCampaignEvent,
  handleFirstLevelCampaignEvents,
  updateCampaignEvent,
} from "modules/marketing/Actions";
import ActionNode from "modules/marketing/components/campaigns/nodes/ActionNode";
import ConditionNode from "modules/marketing/components/campaigns/nodes/ConditionNode";
import ConnectionLine from "modules/marketing/components/campaigns/nodes/ConnectionLine";
import DecisionNode from "modules/marketing/components/campaigns/nodes/DecisionNode";
import NewNode from "modules/marketing/components/campaigns/nodes/NewNode";
import RootNode from "modules/marketing/components/campaigns/nodes/RootNode";
import {
  CAMPAIGN_ACTION_LINE_COLOR,
  CAMPAIGN_CONDITION_TRUE_LINE_COLOR,
  CAMPAIGN_DECISION_NO_LINE_COLOR,
  CAMPAIGN_DECISION_YES_LINE_COLOR,
} from "modules/marketing/MarketingConstants";

import "reactflow/dist/style.css";
import "./assets/campaign.css";

function BuildCampaign({
  initialNodes,
  initialEdges,
  nodeTypes,
  addEvent,
  onCampaignSave,
  events,
  initialEvents,
  rootNodeLabel,
  resetBuilderCanvas,
}) {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const connectingNodeId = useRef(null);
  const connectingHandleId = useRef(null);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );

  const reactFlowInstance = useReactFlow();
  const {
    screenToFlowPosition,
    setNodes: setNodesHook,
    setEdges: setEdgesHook,
  } = reactFlowInstance;

  // TODO: figure out what causes the ResizeObserver loop limit exceeded error
  useEffect(() => {
    const errorHandler = (e: any) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications",
        ) ||
        e.message.includes("ResizeObserver loop limit exceeded")
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay",
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  useEffect(() => {
    if (reactFlowInstance && nodes.length > 0) {
      reactFlowInstance.fitView();
    }
  }, [reactFlowInstance, nodes]);

  const updateNode = useCallback((nodeId, data) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId.toString()) {
          const nodeData = {};
          Object.keys(data).forEach((key) => {
            if (Array.isArray(data[key])) {
              nodeData[key] = data[key];
            } else if (typeof data[key] === "object") {
              nodeData[key] = { ...node[key], ...data[key] };
            } else {
              nodeData[key] = data[key];
            }
          });
          return { ...node, ...nodeData };
        }
        return node;
      }),
    );
  });

  useEffect(() => {
    if (rootNodeLabel) {
      updateNode("lists", { data: { label: rootNodeLabel } });
      const initialRootNode = nodes.find((node) => node.id === "lists");
      if (initialRootNode) {
        initialRootNode.data.label = rootNodeLabel;
      }
    }
  }, [rootNodeLabel]);

  const onConnectStart = useCallback((_, { nodeId, handleId }) => {
    connectingNodeId.current = nodeId;
    connectingHandleId.current = handleId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current || !connectingHandleId.current) return;

      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const newNodePosition = screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        });
        const { newNode, newEdge } = addEvent(
          connectingNodeId.current,
          connectingHandleId.current,
          newNodePosition,
          updateNode,
        );
        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) => eds.concat(newEdge));
      }
    },
    [screenToFlowPosition],
  );
  const onResetCanvas = useCallback(() => {
    setNodesHook(initialNodes);
    setEdgesHook(initialEdges);
    events.splice(0, events.length, ...initialEvents);
  }, [setNodesHook, setEdgesHook]);
  useEffect(() => {
    if (resetBuilderCanvas) {
      onResetCanvas();
    }
  }, [resetBuilderCanvas]);
  const onNodesDelete = useCallback(
    (deletedNodes) => {
      deletedNodes.forEach((node) => {
        deleteCampaignEvent(events, node.id);
      });
    },
    [nodes, edges],
  );
  const handleSaveCampaign = useCallback(() => {
    onCampaignSave(nodes, edges);
  }, [nodes, edges]);
  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <button
          type="button"
          className="btn btn-outline-gray-900 me-3"
          onClick={onResetCanvas}
        >
          <i className="bi bi-arrow-clockwise" /> Reset
        </button>
        <button
          type="button"
          className="btn btn-outline-gray-900"
          onClick={handleSaveCampaign}
        >
          <i className="bi bi-floppy-fill" /> Save
        </button>
      </div>
      <div className="vh-80">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          connectionLineComponent={ConnectionLine}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          onNodesDelete={onNodesDelete}
        >
          <Controls />
          <Background />
        </ReactFlow>
      </div>
    </>
  );
}

BuildCampaign.propTypes = {
  initialNodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      position: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
      }).isRequired,
      data: PropTypes.shape({
        label: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  initialEdges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired,
    }),
  ).isRequired,
  nodeTypes: PropTypes.instanceOf(Object).isRequired,
  addEvent: PropTypes.func.isRequired,
  onCampaignSave: PropTypes.func.isRequired,
  events: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  initialEvents: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  rootNodeLabel: PropTypes.string.isRequired,
  resetBuilderCanvas: PropTypes.bool.isRequired,
};

function BuildCampaignWithProvider({
  canvasWidth,
  currentModalId,
  state,
  setState,
  onCampaignSave,
  resetBuilderCanvas,
}) {
  const { lists: segments, canvasSettings, events } = state;
  const initialEvents = _.cloneDeep(events);
  const { nodes: canvasNodesSettings } = canvasSettings;
  const rootNodeLabel = segments.map((segment) => segment.name).join(", ");
  const firstLevelEvents = events.filter((event) => event.parent === null);
  const canvasNodesSettingsMap = canvasNodesSettings.reduce((acc, setting) => {
    acc[setting.id] = setting;
    return acc;
  }, {});

  const canvasRootNodeSettings = canvasNodesSettingsMap.lists ?? {
    positionX: canvasWidth / 2,
    positionY: 0,
  };

  const initialNodes = [
    {
      id: "lists",
      type: "root",
      position: {
        x: canvasRootNodeSettings.positionX,
        y: canvasRootNodeSettings.positionY,
      },
      data: { label: rootNodeLabel, id: "lists" },
    },
  ];
  const lineColorMap = {
    decision: {
      yes: CAMPAIGN_DECISION_YES_LINE_COLOR,
      no: CAMPAIGN_DECISION_NO_LINE_COLOR,
    },
    condition: {
      yes: CAMPAIGN_CONDITION_TRUE_LINE_COLOR,
      no: CAMPAIGN_DECISION_NO_LINE_COLOR,
    },
    action: CAMPAIGN_ACTION_LINE_COLOR,
  };
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState([]);
  const [allNodesAdded, setAllNodesAdded] = useState(false);
  const updateEvent = useCallback((eventData) => {
    updateCampaignEvent(events, eventData);
  });
  useEffect(() => {
    const eventNodes = [];
    const eventEdges = [];

    handleFirstLevelCampaignEvents(
      events,
      firstLevelEvents,
      canvasWidth,
      canvasNodesSettingsMap,
      currentModalId,
      eventNodes,
      eventEdges,
      lineColorMap,
      updateEvent,
    );

    setEdges((prev) => [...prev, ...eventEdges]);
    setNodes((prev) => [...prev, ...eventNodes]);
    setAllNodesAdded(true);
  }, [events]);

  const addEvent = useCallback(
    (nodeId, handleId, newNodePosition, updateNode) => {
      return addCampaignEvent(
        events,
        nodeId,
        handleId,
        newNodePosition,
        updateNode,
        currentModalId,
        updateEvent,
      );
    },
  );

  const nodeTypes = useMemo(
    () => ({
      decision: DecisionNode,
      action: ActionNode,
      condition: ConditionNode,
      root: RootNode,
      new: NewNode,
    }),
    [],
  );
  return (
    <ReactFlowProvider>
      {allNodesAdded && (
        <BuildCampaign
          initialNodes={nodes}
          initialEdges={edges}
          nodeTypes={nodeTypes}
          addEvent={addEvent}
          onCampaignSave={onCampaignSave}
          events={events}
          initialEvents={initialEvents}
          rootNodeLabel={rootNodeLabel}
          resetBuilderCanvas={resetBuilderCanvas}
        />
      )}
    </ReactFlowProvider>
  );
}

BuildCampaignWithProvider.defaultProps = {
  state: {},
};

BuildCampaignWithProvider.propTypes = {
  canvasWidth: PropTypes.number.isRequired,
  currentModalId: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object),
  setState: PropTypes.func.isRequired,
  onCampaignSave: PropTypes.func.isRequired,
  resetBuilderCanvas: PropTypes.bool.isRequired,
};

export default BuildCampaignWithProvider;
