import { JumbotronBlock } from "./blocks/jumbotron";
import { ButtonBlock } from "./blocks/Button";
import { CodeBlock } from "./blocks/code";
import { HrBlock } from "./blocks/hr";
import { ListBlock } from "./blocks/list";
import { ListGroupBlock } from "./blocks/list-group";
import { ChartBlock } from "./blocks/chart";
import { default as chartComponent } from "./blocks/chart";
import { ParallaxBackgroundBlock } from "./blocks/parallax-bg";
import { default as ParallaxBackgroundComponent } from "./blocks/parallax-bg";
import { default as Sections } from "./blocks/section/index";
import Dropdown, { DropDownBlock, DropDownItemBlock } from "./blocks/Dropdown";
import { TableBlock } from "./blocks/Table";

export default (editor, opts = {}) => {
  const options = {
    ...{},
    ...opts,
  };

  Sections(editor, options);
  JumbotronBlock(editor, options);
  CodeBlock(editor, options);
  HrBlock(editor, options);
  ListBlock(editor, options);
  ListGroupBlock(editor, options);
  ChartBlock(editor, options);
  chartComponent(editor, options);
  ParallaxBackgroundBlock(editor, options);
  ParallaxBackgroundComponent(editor, options);
  DropDownBlock(editor, options);
  Dropdown(editor, options);
  DropDownItemBlock(editor, options);
  ButtonBlock(editor, options);
  TableBlock(editor, options);
};
