function stateCount(method, state) {
  let stateCounter = 0;
  method.map((item) => {
    if (item.state === state) {
      stateCounter += 1;
    } else {
      return stateCounter;
    }
    return stateCounter;
  });
  return stateCounter;
}

export default stateCount;
