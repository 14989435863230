import PropTypes from "prop-types";
import { getMoneyFormat } from "modules/base/utilities/Actions";
import { DomainSuggestion } from "modules/domains/DomainsModels";

export default function DomainSuggestionCard(props) {
  const {
    domain_suggestion,
    currency,
    ordering_domain,
    toggleSelectDomain,
    is_domain_selected,
  } = props;

  let headerBackground = "bg-light";
  let domainFontColor = "text-dark";
  let checkboxColor = null;
  let unavailableIndicator = null;
  let domainPricing = null;
  if (is_domain_selected) {
    headerBackground = "bg-secondary";
    domainFontColor = "text-white";
    checkboxColor = "border-black bg-gray-900";
  }
  if (!domain_suggestion.is_available) {
    unavailableIndicator = (
      <span className="badge text-bg-secondary">Unavailable</span>
    );
  }
  const registrationPrice = getMoneyFormat(
    domain_suggestion.registration_price,
    currency,
  );
  const renewalPrice = getMoneyFormat(
    domain_suggestion.renewal_price,
    currency,
  );
  const transferPrice = getMoneyFormat(
    domain_suggestion.transfer_price,
    currency,
  );
  const canSelectDomain = domain_suggestion.is_available && !ordering_domain;
  if (domain_suggestion.is_available) {
    domainPricing = (
      <div className="col d-flex flex-row me-2">
        <span className="badge text-bg-primary me-1">
          <i className="bi bi-file-earmark-plus" /> register at{" "}
          {registrationPrice}
        </span>
        <span className="badge text-bg-primary me-1">
          <i className="bi bi-arrow-repeat" /> renew at {renewalPrice}
        </span>
        <span className="badge text-bg-primary">
          <i className="bi bi-arrow-right" /> transfer at {transferPrice}
        </span>
      </div>
    );
  }

  return (
    <button
      type="button"
      className="card w-100 mb-3 p-0"
      disabled={!canSelectDomain}
      onClick={toggleSelectDomain}
    >
      <div
        className={`justify-content-between w-100 card-header d-flex align-items-center py-3 ${headerBackground}`}
      >
        <span
          className={`align-self-start font-weight-lighter overflow-hidden me-2 ${domainFontColor}`}
        >
          <input
            className={`form-check-input me-2 ms-1 ${checkboxColor}`}
            type="checkbox"
            checked={is_domain_selected}
            disabled={!canSelectDomain}
          />
          {domain_suggestion.domain_name}
        </span>
        <div className="d-flex align-items-center">
          {domainPricing}
          {unavailableIndicator}
        </div>
      </div>
    </button>
  );
}
DomainSuggestionCard.propTypes = {
  domain_suggestion: PropTypes.instanceOf(DomainSuggestion).isRequired,
  ordering_domain: PropTypes.bool,
  is_domain_selected: PropTypes.bool,
  toggleSelectDomain: PropTypes.func.isRequired,
  currency: PropTypes.string,
};

DomainSuggestionCard.defaultProps = {
  is_domain_selected: false,
  ordering_domain: false,
  currency: "USD",
};
