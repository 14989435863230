import $ from "jquery";
import { cmdCustomOpenTraitManager } from "../consts";

export const OpenTraitManager = {
  run(editor, sender, opts = {}) {
    this.sender = sender;
    this.editor = editor;

    const em = editor.getModel();

    const config = editor.Config;
    const pfx = config.stylePrefix;
    let panelC;

    if (!this.$cn) {
      this.$cn = $("<div></div>");
      this.$cn2 = $("<div></div>");
      this.$cn.append(this.$cn2);

      // Trait Manager
      const tm = editor.TraitManager;
      const confTm = tm.getConfig();
      this.$header = $("<div>").append(
        `<div class="${confTm.stylePrefix}header">${em.t(
          "traitManager.empty",
        )}</div>`,
      );
      this.$cn.append(this.$header);
      this.$cn2.append(
        `<div class="${pfx}traits-label">${em.t("traitManager.label")}</div>`,
      );
      this.$cn2.append(tm.render());
      const panels = editor.Panels;

      if (!panels.getPanel("views-container")) {
        panelC = panels.addPanel({ id: "views-container" });
      } else {
        panelC = panels.getPanel("views-container");
      }

      panelC
        .set("appendContent", this.$cn.get(0))
        .trigger("change:appendContent");

      this.target = editor.getModel();
      this.popUp = editor.PopUp;

      editor.on("pop-up:close", () => {
        editor.Commands.stop(cmdCustomOpenTraitManager);
      });
    }

    this.toggleTm(opts);
  },

  toggleTm(opts) {
    const { sender, popUp } = this;
    if (sender && sender.get && !sender.get("active")) return;

    if (this.target.getSelectedAll().length === 1) {
      this.$cn2.show();
      this.$header.hide();
    } else {
      this.$cn2.hide();
      this.$header.show();
    }

    popUp.setContent(this.$cn);
    popUp.open({
      selector: opts.selector,
    });
  },

  stop() {
    const { editor } = this;
    if (this.$cn2) this.$cn2.hide();
    if (this.$header) this.$header.hide();
    editor.Commands.stop(cmdCustomOpenTraitManager);
  },
};

export default OpenTraitManager;
