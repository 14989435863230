import { withRouter } from "react-router-dom";
import { AuthenticationActions } from "modules/account/AccountActions";
import BillingWalletBalance from "modules/billing/components/WalletBalance";
import Logo from "modules/core/assets/logo.svg";
import { navigateToPage } from "modules/core/utilities/navigation/Navigator";
import {
  PATH_WEBSITES,
  PATH_WEBSITE_TEMPLATES,
} from "modules/website/WebsiteConstants";

function TopMenu(props) {
  const authenticationActions: any = new AuthenticationActions();
  const user: any = authenticationActions.retrieveAuthenticatedUser();
  let websites_login_text: string = "Login";
  let billingWalletBalanceComponent: any = null;
  if (user) {
    websites_login_text = "My websites";
    billingWalletBalanceComponent = <BillingWalletBalance />;
  }
  return (
    <nav className="navbar navbar-dark bg-dark bg-gradient">
      <div className="container-fluid">
        <span className="navbar-brand bg-dark bg-gradient">
          <img src={Logo} alt="Olitt" width={96} height={42} />
        </span>
        <div>
          <button
            onClick={() => navigateToPage(PATH_WEBSITES, props)}
            className="btn btn-lg hover-underline-from-left text-white bg-black rounded-0 border-thick me-2"
            type="button"
          >
            {websites_login_text}
            {billingWalletBalanceComponent}
          </button>
          <button
            onClick={() => navigateToPage(PATH_WEBSITE_TEMPLATES, props)}
            className="btn btn-lg hover-grow text-white bg-black border-warning rounded-2 border-thick"
            type="button"
          >
            Create your free site
          </button>
        </div>
      </div>
    </nav>
  );
}

export default withRouter(TopMenu);
