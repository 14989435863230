import PropTypes from "prop-types";

/**
 * A generic text area component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function TextArea(props) {
  const {
    title,
    name,
    controlFunc,
    content,
    placeholder,
    required,
    isDisabled,
    wrapperClasses,
  } = props;
  const requiredAsterisk = required ? "*" : "";
  return (
    <div className={`form-group ${wrapperClasses}`}>
      <label htmlFor={name}>
        {title} {requiredAsterisk}
      </label>
      <textarea
        className="form-control"
        id={name}
        name={name}
        value={content}
        onChange={controlFunc}
        placeholder={placeholder}
        required={required}
        disabled={isDisabled}
      />
    </div>
  );
}

TextArea.defaultProps = {
  content: "",
  placeholder: "",
  required: false,
  isDisabled: false,
  wrapperClasses: "",
};

TextArea.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  content: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  wrapperClasses: PropTypes.string,
};

export default TextArea;
