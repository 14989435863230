import React from "react";
import TopMenu from "../../core/components/TopMenu";
import CheckPhishingDomain from "../components/CheckPhishingDomain";

export default function PhishingDomainChecker() {
  return (
    <>
      <TopMenu />
      <div className="d-flex vh-70 align-items-center justify-content-center">
        <CheckPhishingDomain />
      </div>
    </>
  );
}
