/**
 * Payment method model
 */
export class PaymentMethod {
  id: number;

  name: string;

  type: string;

  type_display: string;

  provider: string;

  description: string;

  active: boolean;

  display_on: string;

  created_at: string;

  updated_at: string;

  preferences: Object;

  constructor(parameters: Object) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.type = parameters.type;
    this.type_display = parameters.type_display;
    this.provider = parameters.provider;
    this.description = parameters.description;
    this.active = parameters.active;
    this.display_on = parameters.display_on;
    this.created_at = parameters.created_at;
    this.updated_at = parameters.updated_at;
    this.preferences = parameters.preferences;
  }
}
