import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import ActionInput from "modules/base/components/ActionInput";
import DebouncedInput from "modules/base/components/DebouncedInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import { closeModalById, openModal } from "modules/base/utilities/Actions";
import contactDoNotContactActionColumn from "modules/marketing/components/contactDonotcontactActionColumn";
import ChangeSegmentsModal from "modules/marketing/components/contacts/ChangeSegmentsModal";
import CreateContactModal from "modules/marketing/components/contacts/CreateContactModal";
import EditContactModal from "modules/marketing/components/contacts/EditContactModal";
import FilterContacts from "modules/marketing/components/contacts/FilterContacts";
import { useImportContext } from "modules/marketing/components/contacts/import/ImportContext";
import ImportFileModal from "modules/marketing/components/contacts/import/ImportFileModal";
import ImportGoogleContacts from "modules/marketing/components/contacts/import/ImportGoogleContacts";
import MatchColumnsModal from "modules/marketing/components/contacts/import/MatchColumnsModal";
import ManageCampaignsModal from "modules/marketing/components/contacts/ManageCampaignsModal";
import ManageContactStagesModal from "modules/marketing/components/contacts/ManageContactStagesModal";
import ManageContactStatusModal from "modules/marketing/components/contacts/ManageContactStatusModal";
import SendEmailModal from "modules/marketing/components/contacts/SendEmailModal";
import TotalNotes from "modules/marketing/components/contacts/TotalNotes";
import CreateNoteModal from "modules/marketing/components/notes/CreateNoteModal";
import { contactsColumns } from "modules/marketing/components/tableColumns";
import MarketingAPI from "modules/marketing/MarketingAPI";
import { PATH_MARKETING_NOTES } from "modules/marketing/MarketingConstants";
import { generateRoute } from "modules/shop/ShopActions";
import { toast } from "react-toastify";

function ContactList({ match, history }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);

  const [importError, setImportError] = useState(null);
  const [importSuccess, setImportSuccess] = useState(null);
  const [rawContactsData, setRawContactsData] = useState({
    columns: [],
    data: [],
  });
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [resetSelection, setRowResetSelection] = useState(false);
  const { isSubmitting } = useImportContext();
  const [isImportSubmitting, setIsImportSubmitting] = useState(isSubmitting);
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (resetSelection) {
      setRowResetSelection(false);
    }
    setIsImportSubmitting(isSubmitting);
  }, [resetSelection, isSubmitting]);

  const [paginationParams, setPaginationParams] = useState({
    start: 0,
    limit: 10,
  });
  const params = useMemo(
    () => ({
      ...paginationParams,
      orderBy: "date_identified",
      orderByDir: "desc",
      search,
    }),
    [paginationParams, search],
  );
  const { contacts, total, error, isLoading } =
    selectedSegments?.length > 0
      ? API.getContactsBySegments(selectedSegments, params)
      : API.getContacts(params);
  const { data: status } = API.contactsUploadProgress();

  const handlePaginationChange = useCallback((newPaginationParams) => {
    setPaginationParams(newPaginationParams);
  }, []);

  const handleSegmentChange = useCallback((selectedOptions) => {
    if (selectedOptions) {
      setSelectedSegments(selectedOptions.value);
    } else {
      setSelectedSegments(null);
    }
  }, []);

  const [selectedContacts, setSelectedContacts] = useState([]);

  const onImportSuccess = useCallback((data) => {
    setImportSuccess(
      `${data.length} contacts imported from your Google account`,
    );
  });

  const onImportError = useCallback((message) => {
    setImportError(message);
  });

  const onClickCreate = useCallback(() => {
    openModal("create-contact-modal");
  });
  const onClickEdit = useCallback((contact) => {
    openModal(`edit-contact-modal-${contact.id}`);
  });
  const onClickChangeSegments = useCallback(() => {
    openModal("change-segments-modal");
  });

  const onClickSendEmail = useCallback(() => {
    openModal("send-email-modal");
  });
  const onRowSelectionChange = useCallback((rowSelection) => {
    setSelectedContacts(Object.keys(rowSelection));
  });

  const onClickManageCampaign = useCallback(() => {
    openModal("manage-campaign-modal");
  });

  const onClickImportFile = useCallback(() => {
    openModal("import-contacts-file-modal");
  });

  const handleAddNote = useCallback((contact) => {
    openModal(`create-note-modal-${contact.id}`);
  }, []);

  const handleViewAllNotes = useCallback(
    (contact) => {
      const notesPath = generateRoute(PATH_MARKETING_NOTES, siteId).replace(
        ":contactId",
        contact.id,
      );
      history.push(notesPath);
    },
    [history, siteId],
  );

  const onClickChangeStage = useCallback(() => {
    openModal("manage-contact-stages-modal");
  });

  const onClickChangeStatus = useCallback(() => {
    openModal("change-contact-status-modal");
  });
  const onClickOpenMatchColumnsModal = useCallback(() => {
    openModal("match-columns-modal");
  });

  const deleteContact = (id, onSuccess, onError) => {
    API.deleteContact(
      id,
      () => {
        onSuccess();
      },
      (e) => {
        onError("Failed", e.message);
      },
    );
  };

  const deleteContacts = (contactIds, onSuccess, onError) => {
    API.deleteContacts(
      contactIds,
      () => {
        onSuccess();
        setSelectedContacts([]);
        setRowResetSelection(true);
      },
      (e) => {
        onError("Failed", e.message);
        setSelectedContacts([]);
      },
    );
  };

  let changeSegmentButtonLabel = "Change segments";
  let sendEmailButtonLabel = "Send email";
  let addContactsToCampaignButtonLabel = "Change campaigns";
  let deleteSelectedContactsButtonLabel = "Delete";
  let changeStageModal = "Change Stage";
  let changeStatusModal = "Change Status";
  if (selectedContacts.length > 0) {
    const noun = selectedContacts.length === 1 ? "contact" : "contacts";
    changeSegmentButtonLabel += ` for ${selectedContacts.length} ${noun}`;
    sendEmailButtonLabel += ` to ${selectedContacts.length} ${noun}`;
    addContactsToCampaignButtonLabel += ` for ${selectedContacts.length} ${noun}`;
    deleteSelectedContactsButtonLabel += ` ${selectedContacts.length} selected ${noun}`;
    changeStageModal += ` for ${selectedContacts.length} ${noun}`;
    changeStatusModal += ` for ${selectedContacts.length} ${noun}`;
  }

  const segmentsColumn = {
    header: "Segments",
    accessorKey: "segments",
    cell: ({ row }) => {
      const {
        segments,
        error: segmentsError,
        isLoading: segmentsLoading,
      } = API.getContactSegments(row.id, siteId);
      if (segmentsLoading) {
        return <NetworkActivityIndicator />;
      }
      if (segmentsError) {
        return <NetworkMessageDisplay error={segmentsError} />;
      }

      const segmentsPills = segments.map((segment) => (
        <span
          key={segment.id}
          className="badge rounded-pill bg-secondary mb-1 me-1 m-md-1"
        >
          {segment.name}
        </span>
      ));
      return <div className="d-flex flex-wrap">{segmentsPills}</div>;
    },
  };

  const campaignsColumn = {
    header: "Campaigns",
    accessorKey: "campaigns",
    cell: ({ row }) => {
      const {
        campaigns,
        error: campaignsError,
        isLoading: campaignsLoading,
      } = API.getContactsCampaigns(row.id);
      if (campaignsLoading) {
        return <NetworkActivityIndicator />;
      }
      if (campaignsError) {
        return <NetworkMessageDisplay error={campaignsError} />;
      }

      const campaignsPills = campaigns.map((campaign) => (
        <span
          key={campaign.id}
          className="badge rounded-pill bg-secondary mb-1 me-1 m-md-1"
        >
          {campaign.name}
        </span>
      ));
      return <div className="d-flex flex-wrap">{campaignsPills}</div>;
    },
  };

  const assignRawContacts = useCallback((data) => {
    setRawContactsData(data);
  });

  const totalNotes = TotalNotes(contacts, siteId);

  const dropdownActions = [
    {
      label: "Add Note",
      icon: "clipboard-fill",
      onClick: handleAddNote,
    },
    {
      label: "View All Notes",
      icon: "list-ul",
      onClick: handleViewAllNotes,
    },
  ];

  const columns = [
    ...contactsColumns,
    segmentsColumn,
    campaignsColumn,
    contactDoNotContactActionColumn(
      siteId,
      setImportError,
      "updatedonotcontact",
    ),
    totalNotes,
  ];
  const showToast = useCallback(() => {
    if (status?.status === "completed") {
      closeModalById("match-columns-modal");
      toast.success("Contacts upload complete");
    }
  }, [status]);

  useEffect(() => {
    showToast();
  }, [showToast]);

  const importButtons = useMemo(
    () => [
      <ImportGoogleContacts
        onSuccess={onImportSuccess}
        onError={onImportError}
        key="import-google-contacts"
      />,
      status?.status === "uploading" ? (
        <button
          type="button"
          className="btn btn-outline-gray-900 mb-2 mb-md-0 me-0 me-md-2"
          key="uploading"
          onClick={onClickOpenMatchColumnsModal}
        >
          <div className="bg-success rounded outline-white d-flex align-items-center justify-content-center">
            <span
              className="spinner-grow spinner-grow-sm bg-white m-1"
              role="status"
              aria-hidden="true"
            />
            <span className="text-center text-white m-1">
              uploading {Math.round(status?.progress_percentage)}%
            </span>
          </div>
        </button>
      ) : (
        <button
          key="import-file"
          type="button"
          className="btn btn-outline-gray-900 mb-2 mb-md-0 me-0 me-md-2"
          onClick={onClickImportFile}
        >
          <i className="bi bi-file-spreadsheet-fill ms-2 me-2" />
          Import from File
        </button>
      ),
    ],
    [
      isImportSubmitting,
      onClickImportFile,
      onClickOpenMatchColumnsModal,
      onImportError,
      onImportSuccess,
    ],
  );
  const handleInputChange = (value) => {
    setSearch(value);
  };

  return (
    <>
      <NetworkMessageDisplay
        error={error || importError}
        success={importSuccess}
      />
      <CreateContactModal />
      <ChangeSegmentsModal selectedContactsIds={selectedContacts} />
      <SendEmailModal selectedContactsIds={selectedContacts} />
      <ManageContactStagesModal selectedContactsIds={selectedContacts} />
      <ManageContactStatusModal selectedContactsIds={selectedContacts} />
      <ManageCampaignsModal selectedContactsIds={selectedContacts} />
      <ImportFileModal assignRawContacts={assignRawContacts} />
      <MatchColumnsModal rawContactsData={rawContactsData} />
      <div className="d-flex align-items-center">
        <button
          type="button"
          className="btn btn-sm btn-outline-gray-900 mb-3"
          disabled={selectedContacts.length === 0}
          onClick={onClickChangeSegments}
        >
          <i className="bi bi-pie-chart-fill" /> {changeSegmentButtonLabel}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-outline-gray-900 mb-3 ms-2"
          disabled={selectedContacts.length === 0}
          onClick={onClickSendEmail}
        >
          <i className="bi bi-envelope-arrow-up-fill" /> {sendEmailButtonLabel}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-outline-gray-900 mb-3 ms-2"
          disabled={selectedContacts.length === 0}
          onClick={onClickManageCampaign}
        >
          <i className="bi bi-megaphone-fill" />{" "}
          {addContactsToCampaignButtonLabel}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-outline-gray-900 mb-3 ms-2"
          disabled={selectedContacts.length === 0}
          onClick={onClickChangeStage}
        >
          <i className="bi bi-speedometer2" /> {changeStageModal}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-outline-gray-900 mb-3 ms-2"
          disabled={selectedContacts.length === 0}
          onClick={onClickChangeStatus}
        >
          <i className="bi bi-person-fill" /> {changeStatusModal}
        </button>

        <ActionInput
          label={deleteSelectedContactsButtonLabel}
          icon="trash-fill"
          onClick={deleteContacts}
          isButtonDisabled={selectedContacts.length === 0}
          showConfirm
          confirm={{
            title: `${deleteSelectedContactsButtonLabel}`,
            message: "Are you sure you want to delete the Selected contacts?",
            confirm_button_label: "Delete",
            confirm_button_color: "danger",
            action_done_message: "Contacts deleted",
            action_done_title: "Success",
          }}
          subject_id={selectedContacts}
          button_classes="btn btn-sm btn-danger mb-3 ms-2"
        />
      </div>
      <div>
        <DebouncedInput
          type="text"
          placeholder="Search Contacts"
          debounceTime={1500}
          className="p-1 rounded border border-secondary text-secondary"
          onChange={handleInputChange}
        />
      </div>

      <DataTable
        data={contacts}
        columns={columns}
        total={total}
        isLoading={isLoading}
        isServerSidePagination
        allowCreate
        onClickCreate={onClickCreate}
        createButtonColor="gray-900"
        allowEdit
        onClickEdit={onClickEdit}
        onClickDelete={deleteContact}
        allowDelete
        EditModal={EditContactModal}
        objectType="contact"
        editButtonColor="gray-900"
        enableRowSelection
        enableMultiRowSelection
        onRowSelectionChange={onRowSelectionChange}
        filterElement={
          <FilterContacts
            onChange={handleSegmentChange}
            selectedSegments={selectedSegments}
          />
        }
        additionalTopRightButtons={importButtons}
        resetRowSelection={resetSelection}
        allowDropdownActions
        dropdownActions={dropdownActions}
        DropdownModal={CreateNoteModal}
        dropdownLabel="Note"
        onPaginationChange={handlePaginationChange}
      />
    </>
  );
}

ContactList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ContactList);
