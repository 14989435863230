import PropTypes from "prop-types";
import SelectInput from "modules/base/components/inputs/SelectInput";
import TextInput from "modules/base/components/inputs/TextInput";

function MailSettingsFormFields({ state, onInputChange }) {
  const schemeOptions = [
    { value: "smtp", label: "SMTP" },
    { value: "imap", label: "IMAP" },
  ];
  return (
    <div className="mb-3">
      <div className="row row-cols-1 gy-md-3 gy-1">
        <TextInput
          name="username"
          title="Username"
          content={state.username ?? ""}
          controlFunc={onInputChange}
          required
        />
        <TextInput
          name="password"
          title="Password"
          inputType="password"
          content={state.password ?? ""}
          controlFunc={onInputChange}
          required
        />
        <TextInput
          name="host"
          title="Host"
          content={state.host ?? ""}
          controlFunc={onInputChange}
          required
        />
        <TextInput
          name="port"
          title="Port"
          content={state.port ?? ""}
          controlFunc={onInputChange}
          required
        />
        <SelectInput
          name="scheme"
          title="Scheme"
          required
          options={schemeOptions}
          onChange={(selectedOption) =>
            onInputChange({
              target: { name: "scheme", value: selectedOption.value },
            })
          }
          predicate={(option) => option.value === state.scheme}
        />
      </div>
    </div>
  );
}

MailSettingsFormFields.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default MailSettingsFormFields;
