import { UserApi } from "../../../../account/AccountAPIs";
import { PATH_ACCOUNT } from "../../../../account/AccountConstants";
import { navigateToPage } from "../../../utilities/navigation/Navigator";
import { cmdCustomOpenAccount, openAccount } from "../consts";

export const OpenAccount = {
  open(user, history) {
    const { container, editor, popUp } = this;

    if (this.firstRender) {
      const menuActions = [
        {
          title: "Account Settings",
          onClick: () => {
            navigateToPage(PATH_ACCOUNT, { history });
          },
        },
        {
          title: "Log Out",
          onClick: () => {
            this.userApi.logout({ history });
          },
        },
      ];

      const accountDetails = document.createElement("div");
      const accountName = document.createElement("div");
      const accountEmail = document.createElement("div");
      accountName.textContent = `${user.first_name} ${user.last_name}`;
      accountEmail.textContent = user.email;
      accountName.classList.add("fw-bold");
      accountEmail.classList.add("small", "text-muted");
      accountDetails.appendChild(accountName);
      accountDetails.appendChild(accountEmail);
      accountDetails.classList.add(
        "list-group-item",
        "list-group-item-action",
        "d-flex",
        "flex-column",
        "gap-2",
        "bg-secondaryt",
      );
      container.appendChild(accountDetails);

      const actions = menuActions.map((action) => {
        const button = document.createElement("button");
        const title = document.createElement("div");
        title.classList.add("js-title", "d-flex", "align-items-center");
        title.textContent = action.title;
        button.classList.add(
          "list-group-item",
          "list-group-item-action",
          "d-flex",
          "align-items-center",
          "gap-2",
        );
        button.setAttribute("type", "button");
        button.appendChild(title);
        button.addEventListener("click", (event) => {
          event.preventDefault();
          action.onClick();
        });
        return button;
      });

      actions.forEach((action) => container.appendChild(action));

      editor.on("pop-up:close", () => {
        editor.Panels.getButton("options", openAccount).set("active", false);
        editor.Commands.stop(cmdCustomOpenAccount);
      });
    }

    if (container) container.style.display = "block";

    popUp
      .setContent(container)
      .setMaxWidth("250px")
      .open({
        selector: ".gjs-pn-options",
        placement: "bottom",
        enabled: {
          size: true,
        },
        offset: {
          crossAxis: -20,
          mainAxis: 10,
        },
      });
  },

  close() {
    const { container, editor } = this;

    if (container) container.style.display = "none";
    editor.Commands.stop(cmdCustomOpenAccount);
  },

  run(editor, _, { user, history }) {
    this.firstRender = !this.container;
    this.container = this.container || document.createElement("div");
    this.editor = editor;
    this.popUp = editor.PopUp;
    this.userApi = this.userApi || new UserApi();

    this.open(user, history);
  },

  stop() {
    this.close();
  },
};

export default OpenAccount;
