import { createColumnHelper } from "@tanstack/react-table";
import PropTypes from "prop-types";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import { BillingAddonPurchase } from "modules/base/models/Billing";
import { formatDate } from "modules/base/utilities/Actions";
import { formatBillingTransactionState } from "modules/base/utilities/RenderActions";

function BaseBillingAddonPurchases(props) {
  const { addonPurchases, isLoading, error } = props;
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("addon.name", {
      header: "Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("payment_status", {
      header: "Payment Status",
      cell: (info) => formatBillingTransactionState(info.getValue()),
    }),
    columnHelper.accessor("purchase_time", {
      header: "Purchase Time",
      cell: (info) => formatDate(info.renderValue(), "llll"),
    }),
    columnHelper.accessor("payment_time", {
      header: "Payment Time",
      cell: (info) => formatDate(info.renderValue(), "llll"),
    }),
  ];
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <DataTable
        columns={columns}
        data={addonPurchases}
        isLoading={isLoading}
      />
    </>
  );
}

BaseBillingAddonPurchases.defaultProps = {
  error: null,
};

BaseBillingAddonPurchases.propTypes = {
  addonPurchases: PropTypes.arrayOf(BillingAddonPurchase).isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default BaseBillingAddonPurchases;
