import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import Form from "modules/base/components/Form";
import { closeModalById } from "modules/base/utilities/Actions";
import ContactFilterField from "modules/marketing/components/ContactFilterField";
import { ContactSegmentFilter, Field } from "modules/marketing/MarketingModels";

function ConfigureSegmentFilter({
  filter,
  setState,
  state,
  filterIndex,
  contactFields,
}) {
  const [selectedField, setSelectedField] = useState();
  const [filterState, setFilterState] = useState({ ...filter });
  const currentFilterValue = filterState.properties.filter;
  useEffect(() => {
    if (filter.field) {
      const field = contactFields.find((f) => f.alias === filter.field);
      if (field) {
        setSelectedField({
          value: field.alias,
          label: field.label,
          type: field.type,
        });
      }
    }
  }, [filter.field, contactFields]);
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setFilterState((prevFilterState) => {
      setState((prev) => {
        const newFilters = [...prev.filters];
        newFilters[filterIndex] = new ContactSegmentFilter(prevFilterState);
        return { ...prev, filters: newFilters };
      });
      return { ...prevFilterState };
    });
    const segmentId = state.id ?? "new";
    closeModalById(
      `configure-segment-${segmentId}-filter-${filterIndex}-modal`,
    );
  }, []);
  const onFilterValueChange = useCallback(
    (filterValue) => {
      setFilterState((prev) => {
        const newFilter = { ...prev };
        newFilter.properties.filter = filterValue;
        newFilter.filter = filterValue;
        return newFilter;
      });
    },
    [setFilterState],
  );
  return (
    <Form
      handleSubmit={handleSubmit}
      id={`configure-segment-filter-${filterIndex}-form`}
      button_color="gray-900"
      button_label="Configure"
      fullLengthButton
      isSubmitting={false}
      use_as="div"
      padding="p-0"
    >
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <ContactFilterField
          contactFields={contactFields}
          setSelectedField={setSelectedField}
          setFilterState={setFilterState}
          onFilterValueChange={onFilterValueChange}
          selectedField={selectedField}
          filterState={filterState}
          currentFilterValue={currentFilterValue}
        />
      </div>
    </Form>
  );
}

ConfigureSegmentFilter.propTypes = {
  filter: PropTypes.instanceOf(ContactSegmentFilter).isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  filterIndex: PropTypes.number.isRequired,
  contactFields: PropTypes.arrayOf(PropTypes.instanceOf(Field)).isRequired,
};

export default ConfigureSegmentFilter;
