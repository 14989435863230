import { useCallback } from "react";
import BaseBillingWalletBalance from "modules/base/components/billing/BaseWalletBalance";
import { openModal } from "modules/base/utilities/Actions";
import { BillingAPI } from "modules/billing/BillingAPIs";
import WalletTransactionsModal from "modules/billing/components/WalletTransactionsModal";

function BillingWalletBalance() {
  const billingAPI = new BillingAPI();
  const { wallet, error, isLoading } = billingAPI.getBillingWallet("i");
  const showWalletTransactions = useCallback(() => {
    openModal("wallet-transactions-modal");
  });
  return (
    <>
      <WalletTransactionsModal wallet={wallet} />
      <button
        type="button"
        className="btn btn-lime-green btn-sm"
        onClick={showWalletTransactions}
      >
        <BaseBillingWalletBalance
          isLoading={isLoading}
          wallet={wallet}
          error={error}
        />
      </button>
    </>
  );
}

export default BillingWalletBalance;
