import PropTypes from "prop-types";
import React from "react";
import { IconLoading } from "../../base/components/Icons";
import { ToastUtility, toast_styles } from "../../core/utilities/ToastUtility";
import { DnsDomainsApi } from "../DnsAPIs";

export class AddNewDomain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: "",
      is_adding_domain: false,
      adding_domain_error: null,
    };
  }

  changeDomain = (event) => {
    const domain: any = event.target.value;
    this.setState({ domain });
  };

  render() {
    const { cancelAddingDomain, refreshDomains } = this.props;
    const { domain, is_adding_domain, adding_domain_error } = this.state;

    const onSubmit: any = (event) => {
      event.preventDefault();

      this.setState({ is_adding_domain: true, adding_domain_error: null });
      const dns_domains_api: any = new DnsDomainsApi();
      dns_domains_api.createDomain(domain, (is_successful, domain_or_error) => {
        let error: any = domain_or_error;
        if (is_successful) {
          error = null;
          ToastUtility.toast(toast_styles.success, `${domain} added`);
          refreshDomains();
          cancelAddingDomain();
        }
        this.setState({ is_adding_domain: false, adding_domain_error: error });
      });
    };

    let btn_add_domain_text;
    if (is_adding_domain) {
      btn_add_domain_text = <IconLoading text="Adding" />;
    } else {
      btn_add_domain_text = "Add";
    }

    // ToDo: Prevent closing form (calling Cancel button) on press enter
    return (
      <div className="card-body rounded shadow mb-4">
        <div
          className="alert alert-danger"
          role="alert"
          hidden={!adding_domain_error}
        >
          {adding_domain_error}
        </div>
        <form onSubmit={onSubmit}>
          <input
            className="form-control bg-transparent"
            placeholder="Enter your domain (Eg example.com)"
            onChange={this.changeDomain}
            type="text"
            value={domain}
            disabled={is_adding_domain}
          />
          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-outline-olitt-grey me-2"
              onClick={cancelAddingDomain}
            >
              Cancel
            </button>
            <button
              className="btn btn-olitt-grey"
              type="submit"
              disabled={is_adding_domain}
            >
              {btn_add_domain_text}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

AddNewDomain.propTypes = {
  cancelAddingDomain: PropTypes.func.isRequired,
  refreshDomains: PropTypes.func.isRequired,
};
