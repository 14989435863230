import { toast } from "react-toastify";

export const toast_styles: { dark: string, success: string, error: string } = {
  dark: "dark",
  success: "success",
  error: "error",
};

export class ToastUtility {
  static toast(style: toast_styles, message: string) {
    if (style === toast_styles.dark) {
      toast.dark(message);
    } else if (style === toast_styles.error) {
      toast.error(message);
    } else if (style === toast_styles.success) {
      toast.success(message);
    } else {
      toast(message);
    }
  }
}
