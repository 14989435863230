import PropTypes from "prop-types";
import { useMemo } from "react";

function Button(props) {
  const { onClick, disabled, label } = props;
  return (
    <button
      type="button"
      className="border rounded me-1 mb-1 mb-md-0"
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

function Pagination({
  onPageChange,
  onPageSizeChange,
  total,
  isServerSidePagination,
  isDataPaginated,
  pageIndex,
  pageSize,
  dataLength,
}) {
  const shouldRenderPagination =
    (isServerSidePagination || isDataPaginated) &&
    (isServerSidePagination ? total > 0 : dataLength > 0);

  if (!shouldRenderPagination) {
    return null;
  }

  const totalPages = useMemo(
    () =>
      isServerSidePagination
        ? Math.ceil(total / pageSize)
        : Math.ceil(dataLength / pageSize),
    [isServerSidePagination, total, pageSize, dataLength],
  );

  const handlePageSizeChange = (e) => {
    const newPageSize = Number(e.target.value);
    onPageSizeChange(newPageSize);
    onPageChange(0);
  };
  const pageSizes = [10, 20, 30, 40, 50];

  return (
    <div className="d-flex flex-column justify-content-start mb-2">
      <div className="col-12 col-md-2 mb-2 d-flex justify-content-between">
        <Button
          onClick={() => onPageChange(0)}
          disabled={pageIndex === 0}
          label="first"
        />
        <Button
          onClick={() => onPageChange(pageIndex - 1)}
          disabled={pageIndex === 0}
          label="previous"
        />
        <Button
          onClick={() => onPageChange(pageIndex + 1)}
          disabled={pageIndex >= totalPages - 1}
          label="next"
        />
        <Button
          onClick={() => onPageChange(totalPages - 1)}
          disabled={pageIndex >= totalPages - 1}
          label="last"
        />
      </div>
      <div className="d-flex align-items-center">
        <span className="me-2">
          Page {pageIndex + 1} of {totalPages}
        </span>
        <select
          className="form-select w-auto"
          value={pageSize}
          onChange={handlePageSizeChange}
        >
          {pageSizes.map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  total: PropTypes.number,
  isServerSidePagination: PropTypes.bool,
  isDataPaginated: PropTypes.bool,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  dataLength: PropTypes.number,
};

Pagination.defaultProps = {
  total: 0,
  dataLength: 0,
  isServerSidePagination: false,
  isDataPaginated: false,
};

export default Pagination;
