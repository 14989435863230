import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import DataTable from "modules/core/components/datagrid/DataTable";
import { ErrorModal } from "modules/core/components/ErrorModal";
import ShopApi from "modules/shop/ShopAPIs";
import { TAXONOMIES_TABLE_COLUMNS } from "modules/shop/ShopConstants";

/**
 * @summary Renders the taxonomies view.
 * @param {Object} props The component props.
 * @returns {React.Component} The rendered component.
 */
function TaxonomiesView(props) {
  const shopApi: any = new ShopApi();
  const {
    match: {
      params: { id: site_id },
    },
  } = props;
  const [taxonomies, setTaxonomies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = React.useState(null);

  /**
   * @name getTaxonomies
   * @summary Loads the taxonomies.
   */
  function getTaxonomies() {
    setIsLoading(true);
    shopApi.getTaxonomies(
      site_id,
      (data) => {
        const root_taxons = data.map((taxonomy) => taxonomy.taxons).flat();
        setTaxonomies(root_taxons);
        setIsLoading(false);
      },
      (error_message) => {
        setError(error_message);
        setIsLoading(false);
      },
    );
  }

  /**
   * @name refreshTaxonomies
   * @summary Refreshes the taxonomies.
   */
  const refreshTaxonomies: any = () => {
    getTaxonomies();
  };

  useEffect(() => {
    getTaxonomies();
  }, []);

  return (
    <>
      <ErrorModal
        show={error !== null}
        onClose={() => setError(null)}
        short_desc={error ? error.short_desc : ""}
        suggestion={error ? error.suggestion : ""}
      />
      <DataTable
        columns={TAXONOMIES_TABLE_COLUMNS}
        data={taxonomies}
        isLoading={isLoading}
        isDataRefreshable
        onClickRefresh={refreshTaxonomies}
        isRowsExpandable
        rowSize={10}
      />
    </>
  );
}

TaxonomiesView.defaultProps = {};

TaxonomiesView.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(TaxonomiesView);
