/**
 * @class PageSection
 * @description Model for a page section
 */
export default class PageSection {
  id: string;

  name: string;

  content: Object;

  settings: Object;

  link: string;

  fit: string;

  destination: string;

  type: string;

  type_display: string;

  position: number;

  img_one_sm: string;

  img_one_md: string;

  img_one_lg: string;

  img_one_xl: string;

  img_two_sm: string;

  img_two_md: string;

  img_two_lg: string;

  img_two_xl: string;

  img_three_sm: string;

  img_three_md: string;

  img_three_lg: string;

  img_three_xl: string;

  linked_resource_type: string;

  created_at: string;

  updated_at: string;

  constructor(parameters) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.content = parameters.content;
    this.settings = parameters.settings;
    this.link = parameters.link;
    this.fit = parameters.fit;
    this.destination = parameters.destination;
    this.type = parameters.type;
    this.type_display = parameters.type_display;
    this.position = parameters.position;
    this.img_one_sm = parameters.img_one_sm;
    this.img_one_md = parameters.img_one_md;
    this.img_one_lg = parameters.img_one_lg;
    this.img_one_xl = parameters.img_one_xl;
    this.img_two_sm = parameters.img_two_sm;
    this.img_two_md = parameters.img_two_md;
    this.img_two_lg = parameters.img_two_lg;
    this.img_two_xl = parameters.img_two_xl;
    this.img_three_sm = parameters.img_three_sm;
    this.img_three_md = parameters.img_three_md;
    this.img_three_lg = parameters.img_three_lg;
    this.img_three_xl = parameters.img_three_xl;
    this.linked_resource_type = parameters.linked_resource_type;
    this.created_at = parameters.created_at;
    this.updated_at = parameters.updated_at;
  }
}
