import React from "react";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import DesignView from "../components/design/DesignView";
import SidebarShop from "../components/SidebarShop";

/**
 * @name Design
 * @description Design page
 * @returns {JSX.Element}
 * @constructor
 */
function Design() {
  return (
    <SidebarWrapper sidebar={<SidebarShop />}>
      <DesignView />
    </SidebarWrapper>
  );
}

export default Design;
