import React from "react";
import ReactDOM from "react-dom";
import PageSelector from "../components/PageSelector";
import { cmdCustomOpenPages } from "../consts";

/**
 * @type {{
 * run: (editor: grapesjs.default.Editor, ...args: any[]) => void;
 * stop: () => void;
 * getContainer: () => HTMLElement;
 * container: HTMLElement | null;
 * editor: grapesjs.default.Editor | null;
 * currentPage: any;
 * pages: any[];
 * }}
 */
export const OpenPages = {
  run(editor, _, opts) {
    const { page, pages } = opts;

    this.currentPage = page;
    this.pages = pages;
    this.editor = editor;

    this.editor.PopUp.open({
      content: this.getContainer(),
      maxWidth: "100%",
      selector: "#pages-selector",
      autoPlacement: true,
      offset: {
        mainAxis: 0,
        crossAxis: 0,
      },
    });

    this.editor.on("pop-up:close", () => {
      this.stop();
    });
  },

  stop() {
    this.editor.Commands.stop(cmdCustomOpenPages);
    this.editor.PopUp.close();
  },

  getContainer() {
    if (!this.container) {
      const container = document.createElement("div");

      this.container = container;
      const reactEl = React.createElement(PageSelector, {
        editor: this.editor,
        currentPage: this.currentPage,
        pages: this.pages,
      });

      ReactDOM.render(reactEl, this.container);
    }

    return this.container;
  },
};

export default OpenPages;
