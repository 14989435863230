import { PrivateApi } from "../core/utilities/api/PrivateApi";
import { modelObjectsFromArray } from "../core/utilities/commonUtilities";
import { API_ROUTES } from "./CloudoonConstants";
import {
  CloudoonEmailAccount,
  CloudoonEmailDomain,
} from "./CloudoonEmailModels";

export default class CloudoonEmailApi extends PrivateApi {
  listDomains(onSuccess, onError) {
    this.getAPIRequest(
      API_ROUTES.DOMAINS.ROOT,
      (response) => {
        const domains = modelObjectsFromArray(CloudoonEmailDomain, response);
        onSuccess(domains);
      },
      onError,
      this.getAuthenticatedHeaders(),
    );
  }

  getDomain(domain_name, onSuccess, onError) {
    this.getAPIRequest(
      API_ROUTES.DOMAINS.DETAIL.replace(":domain_name", domain_name),
      (data) => {
        const domain = new CloudoonEmailDomain(data);
        onSuccess(domain);
      },
      onError,
      this.getAuthenticatedHeaders(),
    );
  }

  createDomain(domain_name, onSuccess, onError) {
    const data = {
      name: domain_name,
    };

    this.postAPIRequest(
      API_ROUTES.DOMAINS.ROOT,
      onSuccess,
      onError,
      data,
      this.getAuthenticatedHeaders(),
    );
  }

  deleteDomain(domain_name, onSuccess, onError) {
    this.deleteAPIRequest(
      API_ROUTES.DOMAINS.DETAIL.replace(":domain_name", domain_name),
      onSuccess,
      onError,
      this.getAuthenticatedHeaders(),
    );
  }

  getDomainVerificationStatus(domain_name, onSuccess, onError) {
    this.getAPIRequest(
      API_ROUTES.VERIFICATION.STATUS.replace(":domain_name", domain_name),
      onSuccess,
      onError,
      this.getAuthenticatedHeaders(),
    );
  }

  getDomainOwnershipRecord(domain_name, onSuccess, onError) {
    this.getAPIRequest(
      API_ROUTES.VERIFICATION.OWNERSHIP.replace(":domain_name", domain_name),
      onSuccess,
      onError,
      this.getAuthenticatedHeaders(),
    );
  }

  verifyDomain(domain_name, onSuccess, onError) {
    this.postAPIRequest(
      API_ROUTES.VERIFICATION.VERIFY.replace(":domain_name", domain_name),
      onSuccess,
      onError,
      {},
      this.getAuthenticatedHeaders(),
      "PATCH",
    );
  }

  verifyDomainOwnership(domain_name, onSuccess, onError) {
    this.postAPIRequest(
      API_ROUTES.VERIFICATION.VERIFY_OWNERSHIP.replace(
        ":domain_name",
        domain_name,
      ),
      onSuccess,
      onError,
      {},
      this.getAuthenticatedHeaders(),
      "PATCH",
    );
  }

  getDomainMxRecords(domain_name, onSuccess, onError) {
    this.getAPIRequest(
      API_ROUTES.VERIFICATION.MX_RECORDS.replace(":domain_name", domain_name),
      onSuccess,
      onError,
      this.getAuthenticatedHeaders(),
    );
  }

  verifyDomainMxRecords(domain_name, onSuccess, onError) {
    this.postAPIRequest(
      API_ROUTES.VERIFICATION.VERIFY_MX_RECORDS.replace(
        ":domain_name",
        domain_name,
      ),
      onSuccess,
      onError,
      {},
      this.getAuthenticatedHeaders(),
      "PATCH",
    );
  }

  getDomainAccount(domain_name, onSuccess, onError) {
    this.getAPIRequest(
      API_ROUTES.ACCOUNTS.GET.replace(":domain_name", domain_name),
      (data) => {
        const verification = new CloudoonEmailAccount(data);
        onSuccess(verification);
      },
      onError,
      this.getAuthenticatedHeaders(),
    );
  }

  createDomainAccount(domain_name, username, password, onSuccess, onError) {
    const data = {
      username,
      password,
    };
    this.postAPIRequest(
      API_ROUTES.ACCOUNTS.ROOT.replace(":domain_name", domain_name),
      onSuccess,
      onError,
      data,
      this.getAuthenticatedHeaders(),
    );
  }

  deleteDomainAccount(domain_name, onSuccess, onError) {
    this.deleteAPIRequest(
      API_ROUTES.ACCOUNTS.DELETE.replace(":domain_name", domain_name),
      onSuccess,
      onError,
      this.getAuthenticatedHeaders(),
    );
  }
}
