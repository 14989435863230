import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import CreateTemplate from "modules/marketing/components/emailstemplates/CreateTemplate";

function CreateTemplateModal({ prebuiltHtml, uuid }) {
  const modalId = `create-template-${uuid}-modal`;
  return (
    <Modal modal_title="Create Template" modal_id={modalId} size="md">
      <CreateTemplate
        currentModalId={modalId}
        prebuiltHtml={prebuiltHtml}
        uuid={uuid}
      />
    </Modal>
  );
}

CreateTemplateModal.propTypes = {
  prebuiltHtml: PropTypes.string,
  uuid: PropTypes.string.isRequired,
};

CreateTemplateModal.defaultProps = {
  prebuiltHtml: null,
};

export default CreateTemplateModal;
