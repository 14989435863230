/**
 * Shop model
 */
export class Shop {
  id: number;

  name: string;

  url: string;

  admin_url: string;

  auto_login_url: string;

  meta_description: string;

  meta_keywords: string;

  default_currency: string;

  code: string;

  supported_locales: string;

  supported_currencies: string;

  supported_currencies_list: string[];

  default_locale: string;

  seo_title: string;

  mail_from_address: string;

  new_order_notification_email: string;

  customer_support_email: string;

  facebook: string;

  twitter: string;

  instagram: string;

  desktop_thumbnail: string;

  site: string;

  default_country: string;

  description: string;

  address: string;

  contact_phone: string;

  facebook_page_id: number;

  facebook_business_id: number;

  facebook_catalog_id: number;

  constructor(parameters: Object) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.url = parameters.url;
    this.admin_url = parameters.admin_url;
    this.auto_login_url = parameters.auto_login_url;
    this.meta_description = parameters.meta_description;
    this.meta_keywords = parameters.meta_keywords;
    this.default_currency = parameters.default_currency;
    this.code = parameters.code;
    this.supported_locales = parameters.supported_locales;
    this.supported_currencies = parameters.supported_currencies;
    this.supported_currencies_list = parameters.supported_currencies.split(",");
    this.default_locale = parameters.default_locale;
    this.seo_title = parameters.seo_title;
    this.mail_from_address = parameters.mail_from_address;
    this.new_order_notification_email = parameters.new_order_notification_email;
    this.customer_support_email = parameters.customer_support_email;
    this.facebook = parameters.facebook;
    this.twitter = parameters.twitter;
    this.instagram = parameters.instagram;
    this.desktop_thumbnail = parameters.desktop_thumbnail;
    this.site = parameters.site;
    this.default_country = parameters.default_country;
    this.description = parameters.description;
    this.address = parameters.address;
    this.contact_phone = parameters.contact_phone;
    this.facebook_page_id = parameters.facebook_page_id;
    this.facebook_business_id = parameters.facebook_business_id;
    this.facebook_catalog_id = parameters.facebook_catalog_id;
  }
}
