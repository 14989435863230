import PropTypes from "prop-types";
import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import Sidebar from "modules/core/components/Sidebar";
import { ExternalMenu } from "modules/core/models/ExternalMenu";
import { Menu } from "modules/core/models/Menu";
import {
  fetchUrlData,
  getUrlData,
} from "modules/core/utilities/redux/componentActions";
import {
  generateRoute,
  getAutoLoginUrl,
  isActiveRoute,
} from "modules/shop/ShopActions";
import {
  PATH_DASHBOARD,
  PATH_SHOP_DESIGN,
  PATH_SHOP_DETAILS,
  PATH_SHOP_PAYMENT_METHODS,
  PATH_SHOP_PRODUCTS,
  PATH_SHOP_TAXONOMIES,
  URL_SHOP_DETAILS,
  URL_SPREE_ORDERS_PATH,
} from "modules/shop/ShopConstants";
import { PATH_WEBSITES } from "modules/website/WebsiteConstants";
import SiteApi from "modules/website/api/SiteApi";

const SHOP_DETAILS_DATA_KEY: string = "SHOP_DETAILS_DATA_KEY";

/**
 * Renders the sidebar for the shop.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function SidebarShop(props) {
  const { shop, location, history, match, sidebar_width } = props;
  const { params } = match;
  const siteId: any = params.id;
  const siteApi = new SiteApi();
  const { site, isLoading, error } = siteApi.getWebsite(siteId);

  useEffect(() => {
    fetchUrlData(
      SHOP_DETAILS_DATA_KEY,
      URL_SHOP_DETAILS.replace("{site_id}", siteId.toString()),
      props,
    );
  }, []);

  if (isLoading) {
    return <NetworkActivityIndicator />;
  }

  const dashboard_path: string = generateRoute(PATH_DASHBOARD, shop.site);
  const shop_details_path: string = generateRoute(PATH_SHOP_DETAILS, shop.site);
  const shop_payment_methods_path: string = generateRoute(
    PATH_SHOP_PAYMENT_METHODS,
    shop.site,
  );
  const shop_products_path: string = generateRoute(
    PATH_SHOP_PRODUCTS,
    shop.site,
  );
  const shop_taxonomies_path: string = generateRoute(
    PATH_SHOP_TAXONOMIES,
    shop.site,
  );
  const shop_design_path: string = generateRoute(PATH_SHOP_DESIGN, shop.site);
  const current_path: any = location.pathname;
  const auto_login_url: string = shop.auto_login_url
    ? shop.auto_login_url
    : getAutoLoginUrl(shop);
  const orders_url: string = `${auto_login_url}&next=${URL_SPREE_ORDERS_PATH}`;

  const sidebar_menus: any = [
    new Menu(
      "Dashboard",
      "grid-3x3-gap",
      dashboard_path,
      isActiveRoute(dashboard_path, current_path),
    ),
    new Menu(
      "Details",
      "journal-text",
      shop_details_path,
      isActiveRoute(shop_details_path, current_path),
    ),
    new Menu(
      "Payment Methods",
      "wallet",
      shop_payment_methods_path,
      isActiveRoute(shop_payment_methods_path, current_path),
    ),
    new Menu(
      "Products",
      "box",
      shop_products_path,
      isActiveRoute(shop_products_path, current_path),
    ),
    new Menu(
      "Categories",
      "bookmarks",
      shop_taxonomies_path,
      isActiveRoute(shop_taxonomies_path, current_path),
    ),
    new Menu(
      "Design",
      "brush",
      shop_design_path,
      isActiveRoute(shop_design_path, current_path),
      null,
      null,
      false,
    ),
    new ExternalMenu("Orders", "box-arrow-up-right", orders_url),
  ];

  const bottom_action: any = new Menu(
    "All Websites",
    "chevron-double-left",
    PATH_WEBSITES,
    false,
  );
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <Sidebar
        sidebar_banner={shop.desktop_thumbnail}
        sidebar_banner_width={100}
        actions={sidebar_menus}
        bottom_action={bottom_action}
        location={location}
        history={history}
        match={match}
        sidebar_width={sidebar_width}
        site={site}
      />
    </>
  );
}

SidebarShop.propTypes = {
  shop: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  sidebar_width: PropTypes.string.isRequired,
};

SidebarShop.defaultProps = {
  shop: null,
};

/**
 * @name mapStateToProps
 * Maps the state to the props.
 * @param state
 * @returns {{shop, isLoadingShop: (boolean|*)}}
 */
function mapStateToProps(state: any) {
  const { sessionVariables, dataByUrl } = state;
  const shop_details_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    SHOP_DETAILS_DATA_KEY,
  );
  return {
    isLoadingShop: shop_details_data.isFetching,
    shop: shop_details_data.items,
  };
}

export default withRouter(connect(mapStateToProps)(SidebarShop));
