import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import Form from "modules/base/components/Form";
import SelectInput from "modules/base/components/inputs/SelectInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";
import { closeModalById } from "modules/base/utilities/Actions";
import MarketingAPI from "modules/marketing/MarketingAPI";

function SendEmail({ selectedContactsIds, match, currentModalId }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const {
    emailTemplates,
    error: templatesLoadingError,
    isLoading,
  } = API.getEmailTemplates();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errors, setErrors] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({
    label: "",
    value: "",
  });
  let successfulCount = 0;
  let failedCount = 0;
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrors([]);
    setSuccess(null);
    try {
      const sendEmailPromises = selectedContactsIds.map((contactId) => {
        return new Promise((resolve) => {
          API.sendEmail(
            contactId,
            selectedTemplate.value,
            (data) => {
              if (data.success) {
                successfulCount += 1;
              } else {
                failedCount += 1;
              }
              resolve();
            },
            () => {
              failedCount += 1;
              resolve();
            },
          );
        });
      });
      await Promise.all(sendEmailPromises);
      closeModalById(currentModalId);
      setSuccess({
        message: `${successfulCount}/${selectedContactsIds.length} emails sent, ${failedCount} failed`,
      });
    } catch (e) {
      setErrors([e.message]);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onTemplateChange = useCallback((template) => {
    setSelectedTemplate(template);
  }, []);
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }

  const errorMessages = errors.map((error) => (
    <NetworkMessageDisplay key={error.id} error={error} />
  ));

  const templateOptions = emailTemplates.map((segment) => ({
    label: segment.name,
    value: segment.id,
  }));

  return (
    <Form
      handleSubmit={handleSubmit}
      id="send-emails-form"
      button_color="gray-900"
      button_label="Send"
      fullLengthButton
      submitButtonEnabled={selectedTemplate.value !== ""}
      isSubmitting={isSubmitting}
      use_as="form"
      padding="p-0"
    >
      {errorMessages}
      <NetworkMessageDisplay error={templatesLoadingError} />
      <SuccessActionIndicator success={success} />
      <div className="row row-cols-1 gy-md-3 gy-1 mb-3">
        <SelectInput
          name="template"
          title="Template"
          options={templateOptions}
          onChange={onTemplateChange}
          predicate={(option) => option.value === selectedTemplate.value}
          isLoading={isLoading}
          isRequired
        />
      </div>
    </Form>
  );
}

SendEmail.defaultProps = {
  currentModalId: null,
};

SendEmail.propTypes = {
  selectedContactsIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentModalId: PropTypes.string,
};

export default withRouter(SendEmail);
