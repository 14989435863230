import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import SelectInput from "modules/base/components/inputs/SelectInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import MarketingAPI from "modules/marketing/MarketingAPI";

function SendEmailToContact({ match, actionState, setActionState }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);
  const { emailTemplates, error, isLoading } = API.getEmailTemplates();
  const [selectedTemplateId, setSelectedTemplateId] = useState(
    actionState?.properties?.email ?? null,
  );
  const onTemplateChange = useCallback((selectedOption) => {
    setSelectedTemplateId(selectedOption.value);
    setActionState((prevState) => ({
      ...prevState,
      properties: {
        email: selectedOption.value,
      },
    }));
  });
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  const templateOptions = emailTemplates.map((segment) => ({
    label: segment.name,
    value: segment.id,
  }));
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <SelectInput
        name="template"
        title="Email template"
        options={templateOptions}
        onChange={onTemplateChange}
        predicate={(option) => option.value === Number(selectedTemplateId)}
        required
      />
    </>
  );
}

SendEmailToContact.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  actionState: PropTypes.instanceOf(Object).isRequired,
  setActionState: PropTypes.func.isRequired,
};

export default withRouter(SendEmailToContact);
