import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { openModal } from "modules/base/utilities/Actions";
import { PATH_CHECKOUT } from "modules/billing/BillingConstants";
import { BillingPlan } from "modules/billing/BillingModels";
import { IMAGE_SERVER_ENDPOINT } from "modules/core/constants/Endpoints";
import { Site } from "modules/website/WebsiteModels";

/**
 * @class PopUptUtility
 * @description Utility class for pop up alerts
 */
export class PopUptUtility {
  /**
   * @name showUpgradeModal
   * @description Show the upgrade modal
   * @param site_id
   * @param message
   * @param plan
   */
  static showUpgradeModal(
    site_id: number = null,
    message: string = null,
    plan: BillingPlan = null,
  ) {
    const MySwal: any = withReactContent(Swal);
    const plan_name: any = plan ? plan.name : "pro";
    MySwal.fire({
      position: "center",
      title: `<h1 style="color: #000000">Try ${plan_name}!</h1>`,
      html: `<p>${message}</p>`,
      showCancelButton: true,
      confirmButtonColor: "#000",
      confirmButtonText: "Sure, let's do it",
      cancelButtonText: "Sorry, I'll pass",
      showCloseButton: true,
      allowOutsideClick: false,
      background: "#fff",
      focusConfirm: true,
      backdrop: true,
      imageUrl: "https://s3.olitt.com/media/premium-icon-70_W9kwCy0.png",
      imageHeight: "40px",
    }).then((results) => {
      if (results.isConfirmed) {
        if (site_id) {
          openModal(`select-plan-${site_id}`);
        } else {
          window.location.href = PATH_CHECKOUT;
        }
      }
    });
  }

  /**
   * @name confirmAction
   * @description Show a confirmation modal
   * @param callback
   * @param website
   */
  static confirmAction(callback, website: Site) {
    const MySwal: any = withReactContent(Swal);
    const swalWithBootstrapButtons: any = MySwal.mixin({
      customClass: {
        cancelButton: "btn btn-secondary",
        confirmButton: "btn btn-danger me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        imageUrl: `${IMAGE_SERVER_ENDPOINT}/0x200/${website.desktop_thumbnail}`,
        imageHeight: 200,
        padding: "1.4em",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        cancelButtonText: "No, cancel",
        reverseButtons: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          callback(website);
        }
      });
  }

  /**
   * @name confirmDeleteObject
   * @description Confirm delete object
   * @param callback
   * @param object
   */
  static confirmDeleteObject(callback, object) {
    const MySwal: any = withReactContent(Swal);
    const swalWithBootstrapButtons: any = MySwal.mixin({
      customClass: {
        cancelButton: "btn btn-secondary",
        confirmButton: "btn btn-danger me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        imageHeight: 200,
        padding: "1.4em",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        cancelButtonText: "No, cancel",
        reverseButtons: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          callback(object);
        }
      });
  }
}
