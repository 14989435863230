import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import DataTable from "modules/base/components/table/Table";
import { openModal } from "modules/base/utilities/Actions";
import ContentCopyButtonColumn from "modules/marketing/components/content/ContentCopyButtonColumn";
import CreateContentModal from "modules/marketing/components/content/CreateContentModal";
import EditContentModal from "modules/marketing/components/content/EditContentModal";
import { ContentsColumns } from "modules/marketing/components/tableColumns";
import MarketingAPI from "modules/marketing/MarketingAPI";

function ContentList({ match }) {
  const { id: siteId } = match.params;
  const API = new MarketingAPI(siteId);

  const [paginationParams, setPaginationParams] = useState({
    start: 0,
    limit: 10,
  });
  const params = useMemo(
    () => ({
      ...paginationParams,
    }),
    [paginationParams],
  );
  const { contents, total, error, isLoading } = API.getContents(params);
  const onClickCreate = useCallback(() => {
    openModal("create-content-modal");
  });
  const onClickEdit = useCallback((contentItem) => {
    openModal(`edit-content-${contentItem.id}-modal`);
  });
  const onClickDelete = useCallback((id, onSuccess, onError) => {
    API.deleteContent(
      id,
      siteId,
      () => {
        onSuccess();
      },
      (error_response) => {
        onError("Unable to delete content item", error_response.message);
      },
    );
  });
  const handlePaginationChange = useCallback((newPaginationParams) => {
    setPaginationParams(newPaginationParams);
  }, []);
  const columns = [...ContentsColumns, ContentCopyButtonColumn()];
  return (
    <>
      <CreateContentModal />
      <NetworkMessageDisplay error={error} />
      <DataTable
        data={contents}
        columns={columns}
        total={total}
        isLoading={isLoading}
        createButtonColor="gray-900"
        editButtonColor="gray-900"
        objectType="content"
        allowEdit
        allowDelete
        allowCreate
        EditModal={EditContentModal}
        onClickCreate={onClickCreate}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        onPaginationChange={handlePaginationChange}
        isServerSidePagination
      />
    </>
  );
}

ContentList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(ContentList);
