import parse from "html-react-parser";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { v4 as uuidv4 } from "uuid";

import Form from "../../core/components/Form";
import { InputType } from "../../core/constants/Enumerators";
import { FormInput } from "../../core/models/FormInput";
import { GenerateContentAPI } from "../CmsAPI";
import { URL_STREAM_CONTENT } from "../CmsConstants";

function GenerateContent({ editorInsertContent }) {
  const [error, setError] = useState(null);
  const [is_generating_content, set_is_generating_content] = useState(false);
  const [state, setState] = useState({
    text_output: "",
    is_done: false,
    is_started: false,
  });

  const api: any = new GenerateContentAPI();

  const streamContent: any = (parameters) => {
    const client: any = new WebSocket(URL_STREAM_CONTENT);
    const payload: any = {
      action: "subscribe_instance",
      request_id: parameters.content_uuid,
      pk: parameters.id,
    };
    client.onopen = () => {
      client.send(JSON.stringify(payload));
    };

    client.onmessage = (e) => {
      const message: any = JSON.parse(e.data);
      let text_output: object = null;
      text_output = message.text_output || "";
      const is_done: any = message.is_done || false;
      if (text_output !== "") {
        text_output = text_output.replace(/(?:\r\n|\r|\n)/g, "<br />");
        if ([true, "true"].includes(message.is_done)) {
          set_is_generating_content(false);
        }
        setState((prevState) => {
          return { ...prevState, ...{ text_output, is_done } };
        });
      }
    };
  };

  const generateContent: any = (parameters) => {
    set_is_generating_content(true);
    parameters.content_uuid = uuidv4();
    api.generateContent(parameters, (is_successful, content_or_error) => {
      if (is_successful) {
        setState((prevState) => {
          return { ...prevState, ...{ is_started: true } };
        });
        setError(null);
        streamContent(content_or_error);
      } else {
        setError(content_or_error);
        set_is_generating_content(false);
      }
    });
  };

  const Indicator: any = (color) => {
    return (
      <div
        className={`spinner-grow text-${color} spinner-grow-sm me-1`}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  const TypingIndicator: any = () => {
    return [
      Indicator("success"),
      Indicator("danger"),
      Indicator("warning"),
      Indicator("info"),
    ];
  };

  const inputs: any = [
    new FormInput(
      InputType.textarea,
      "text_prompt",
      "Opening paragraph",
      "Write two or three sentences related to your industry, goods or services",
      "",
      true,
      true,
    ),
    new FormInput(
      InputType.number,
      "max_length",
      "Number of words",
      "Number of words the article should contain (Max 1000)",
      100,
      true,
      true,
      1,
      1000,
    ),
  ];
  let typing_indicator: any = TypingIndicator();
  if (state.is_done || !state.is_started) {
    typing_indicator = "";
  }
  let editorInsertBtn: any;
  if (editorInsertContent && state.is_done) {
    editorInsertBtn = (
      <button
        type="button"
        className="btn btn-transparent shadow-none"
        onClick={editorInsertContent}
      >
        <i className="fa fa-files-o" aria-hidden="true" />
        <strong className="text-olitt-grey d-none d-lg-inline me-3">
          {" "}
          Insert Text
        </strong>
      </button>
    );
  }

  return (
    <>
      <Form
        onSubmit={generateContent}
        is_submitting={is_generating_content}
        error={error}
        submit_text="Generate Article"
        inputs={inputs}
        no_of_columns={1}
        processing_label="Generating article"
      />
      <div className="mt-4">
        <div id="ai-writer">{parse(state.text_output)}</div>
        {typing_indicator}
        {editorInsertBtn}
      </div>
    </>
  );
}

GenerateContent.propTypes = {
  editorInsertContent: PropTypes.func,
};
GenerateContent.defaultProps = {
  editorInsertContent: null,
};
export default GenerateContent;
