import PropTypes from "prop-types";

function DomainSearchForm(props) {
  const { onSubmit, value, onChange, disabled, error } = props;
  return (
    <form className="shadow-sm" onSubmit={onSubmit}>
      <div className="input-group rounded d-flex align-items-center">
        <input
          className="form-control form-control-lg"
          type="search"
          placeholder="eg. example.com"
          name="domain"
          value={value}
          onChange={onChange}
          aria-label="Search domain"
        />
        <button
          className="btn btn-lg btn-gray-900"
          type="submit"
          disabled={disabled}
        >
          <i className="bi bi-search" /> Search
        </button>
      </div>
      <p className="text-danger">{error}</p>
    </form>
  );
}

DomainSearchForm.defaultProps = {
  error: null,
};

DomainSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
};

export default DomainSearchForm;
