import { createContext, useContext, useState } from "react";

const ImportContext = createContext();

export const ImportProvider = ({ children }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contactsFile, setContactsFile] = useState(null);

  return (
    <ImportContext.Provider
      value={{ isSubmitting, setIsSubmitting, contactsFile, setContactsFile }}
    >
      {children}
    </ImportContext.Provider>
  );
};

export const useImportContext = () => useContext(ImportContext);
