import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import { Datatable } from "../../core/components/Datatable";
import { ErrorModal } from "../../core/components/ErrorModal";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import EmptyList from "../components/EmptyList";
import FormSubmissionCard from "../components/FormSubmissionCard";
import PageLoadingIndicator from "../components/PageLoadingIndicator";
import WebsiteSidebar from "../components/SidebarWebsite";

import SiteApi from "modules/website/api/SiteApi";

function WebsiteForms(props) {
  const site_id: any = props.match.params.id;

  const [loading_website, setIsLoadingWebsite] = useState(true);
  const [loading_form, setIsLoadingForm] = useState(false);
  const [website, setWebsite] = useState([]);
  const [forms, setForms] = useState([]);
  const [web_error, setWebsiteError] = useState(null);
  const [form_error, setFormError] = useState(null);
  const [form_loading_text, setDomainLoadingText] = useState("Fetching forms");
  const [expandedFormSubmission, setExpandedFormSubmission] = useState(null);
  const siteApi: any = new SiteApi();

  const retrieveForms: any = () => {
    setIsLoadingForm(true);
    siteApi.retrieveWebsiteForms(site_id, (is_successful, forms_or_error) => {
      setIsLoadingForm(false);
      if (is_successful) {
        setForms(forms_or_error);
      } else {
        setFormError(forms_or_error);
      }
    });
  };

  const loadWebsite: any = () => {
    setIsLoadingWebsite(true);
    siteApi.retrieveWebsite_(site_id, (is_successful, sites_or_error) => {
      setIsLoadingWebsite(false);
      if (is_successful) {
        setWebsite(sites_or_error);
        retrieveForms();
      } else {
        setWebsiteError(sites_or_error);
      }
    });
  };

  useEffect(loadWebsite, []);

  if (loading_website) {
    return <PageLoadingIndicator />;
  }
  let main_content;
  let data_rows;
  if (loading_form) {
    main_content = (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <ComponentLoadingIndicator text={form_loading_text} />
      </div>
    );

    // <ComponentLoadingIndicator text={form_loading_text} />;
  } else if (forms.length === 0) {
    const title: string = "No Submissions yet.";
    const description: string =
      "All of your contact form submissions can be found here.";
    main_content = <EmptyList title={title} description={description} />;
  } else {
    data_rows = forms.map((form, index) => {
      const onExpandCard: any = () => {
        const expandedFormSubmission_id: any =
          expandedFormSubmission === form.id ? null : form.id;
        setExpandedFormSubmission(expandedFormSubmission_id);
      };
      return {
        email: (
          <FormSubmissionCard
            form_submission={form}
            is_expanded={form.id === expandedFormSubmission}
            onExpand={onExpandCard}
          />
        ),
      };
    });
    const columns: any = [
      {
        dataField: "email",
        text: `Submissions (${forms.length})`,
      },
    ];
    main_content = <Datatable columns={columns} data={data_rows} />;
  }

  const close_modal_button: any = (
    <button
      className="btn btn-olitt-grey px-4"
      onClick={() => setWebsiteError(null)}
      type="button"
    >
      Close
    </button>
  );

  return (
    <SidebarWrapper sidebar={<WebsiteSidebar website={website} />}>
      <div id="accordion">{main_content}</div>
      <ErrorModal
        show={web_error !== null}
        onClose={() => setWebsiteError(null)}
        positive_button={close_modal_button}
        short_desc={web_error ? web_error.short_desc : ""}
        suggestion={web_error ? web_error.suggestion : ""}
      />
    </SidebarWrapper>
  );
}

export default withRouter(WebsiteForms);
