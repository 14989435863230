import PropTypes from "prop-types";
import { useState } from "react";
import ComponentLoadingIndicator from "modules/core/components/ComponentLoadingIndicator";
import { FormEmailInput } from "modules/core/components/form-controls/FormEmailInput";
import FormTextInput from "modules/core/components/form-controls/FormTextInput";

export default function EmailAndPasswordLogin({ loginWithEmailAndPassword }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [is_logging_in, setIsLoggingIn] = useState(false);

  function onSubmitEmailAndPassword(event) {
    event.preventDefault();
    loginWithEmailAndPassword(email, password, setIsLoggingIn);
  }

  let login_btn_text;
  if (is_logging_in) {
    login_btn_text = <ComponentLoadingIndicator />;
  } else {
    login_btn_text = <span className="text-uppercase"> Log In </span>;
  }

  return (
    <form className="row g-3" onSubmit={onSubmitEmailAndPassword}>
      <div className="col-12">
        <FormEmailInput
          name="email"
          placeholder="Enter your email address"
          text={email}
          is_required
          is_editable={!is_logging_in}
          onChangeText={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="col-12">
        <FormTextInput
          name="password"
          type="password"
          placeholder="Enter your password"
          text={password}
          is_required
          is_editable={!is_logging_in}
          onChangeText={(e) => setPassword(e.target.value)}
          value={password}
        />
      </div>

      <div className="col-12">
        <button
          type="submit"
          className="btn btn-olitt-grey w-100 d-flex align-items-center shadow-none"
          disabled={is_logging_in}
        >
          <div className="d-flex w-100 align-items-center justify-content-center">
            {login_btn_text}
          </div>
        </button>
      </div>
    </form>
  );
}

EmailAndPasswordLogin.propTypes = {
  loginWithEmailAndPassword: PropTypes.func.isRequired,
};
