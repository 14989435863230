import { modelObjectsFromArray } from "../../core/utilities/commonUtilities";
import { Taxon } from "./Taxon";

/**
 * Taxonomy model
 */
export class Taxonomy {
  id: number;

  name: string;

  created_at: string;

  updated_at: string;

  position: number;

  public_metadata: Object;

  private_metadata: Object;

  taxons: Array<Taxon>;

  subRows: Array<Taxon>;

  constructor(parameters: Object) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.created_at = parameters.created_at;
    this.updated_at = parameters.updated_at;
    this.position = parameters.position;
    this.public_metadata = parameters.public_metadata;
    this.private_metadata = parameters.private_metadata;
    const taxons: any = modelObjectsFromArray(Taxon, parameters.taxons);
    this.taxons = taxons;
    this.subRows = taxons;
  }
}
