import fetch from "cross-fetch";
import { AuthenticationActions } from "../../../../account/AccountActions";
import { NetworkRequests } from "../../network/NetworkRequests";

export const REQUEST_DATA: string = "REQUEST_DATA";
export const RECEIVE_DATA: string = "RECEIVE_DATA";
export const SELECT_API_ENDPOINT: string = "SELECT_API_ENDPOINT";
export const INVALIDATE_DATA: string = "INVALIDATE_DATA";

export function setSessionVariable(key, value) {
  return {
    type: SELECT_API_ENDPOINT,
    var_value: value,
    var_name: key,
  };
}

export function invalidateData(endpoint) {
  return {
    type: INVALIDATE_DATA,
    endpoint,
  };
}

export function receiveData(endpoint, json) {
  return {
    type: RECEIVE_DATA,
    endpoint,
    items: json,
    receivedAt: Date.now(),
  };
}

function shouldFetchData(state, endpoint) {
  const items: any = state.dataByUrl[endpoint];
  if (!items) {
    return true;
  }
  if (items.isFetching) {
    return false;
  }
  return items.didInvalidate;
}

function requestData(endpoint) {
  return {
    type: REQUEST_DATA,
    endpoint,
  };
}

function fetchData(endpoint) {
  const accountActions: any = new AuthenticationActions();
  const tokens: any = accountActions.retrieveAuthenticationTokens();
  const headers: { Authorization: string } = {
    Authorization: `Bearer ${tokens.access_token}`,
  };

  return (dispatch) => {
    dispatch(requestData(endpoint));
    return fetch(endpoint, { headers })
      .then((response) => {
        if (response.status === 401) {
          const callback: any = function () {
            dispatch(fetchData(endpoint));
          };
          const networkRequests: any = new NetworkRequests();
          networkRequests.refreshTokenRequest(callback, headers);
        } else if (response.ok) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then((json) => dispatch(receiveData(endpoint, json)))
      .catch(() => dispatch(invalidateData(endpoint)));
  };
}

export function fetchDataIfNeeded(endpoint) {
  return (dispatch, getState) => {
    if (shouldFetchData(getState(), endpoint)) {
      return dispatch(fetchData(endpoint));
    }
  };
}
