import PropTypes from "prop-types";
import React, { useEffect } from "react";
import ComponentLoadingIndicator from "../../core/components/ComponentLoadingIndicator";
import { ErrorModal } from "../../core/components/ErrorModal";
import SidebarWrapper from "../../core/components/SidebarWrapper";
import { navigateToPage } from "../../core/utilities/navigation/Navigator";
import { PATH_WEBSITES } from "../../website/WebsiteConstants";
import ShopDetailsEditor from "../components/details/ShopDetailsEditor";
import SidebarShop from "../components/SidebarShop";
import ShopApi from "../ShopAPIs";

/**
 * ShopDetails
 *
 * @param {Object} props
 * @returns {React.Component}
 */
function ShopDetails(props) {
  const shopApi: any = new ShopApi();
  const { match } = props;
  const { params } = match;
  const site_id: any = params.id;
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [shop, setShop] = React.useState({});

  /**
   * @description
   * This function is used to fetch the shop details
   */
  const loadShop: any = () => {
    setIsLoading(true);
    shopApi.getShopDetails(
      site_id,
      (response: any) => {
        setShop(response);
        setIsLoading(false);
      },
      (error_message: any) => {
        setError(error_message);
        setIsLoading(false);
      },
    );
  };

  useEffect(loadShop, []);

  const close_modal_button: any = (
    <button
      type="button"
      className="btn btn-olitt-grey px-4"
      onClick={() => setError(null)}
    >
      Close
    </button>
  );

  let shopDetailsComponent: any = null;
  if (isLoading) {
    shopDetailsComponent = (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <ComponentLoadingIndicator />
      </div>
    );
  } else {
    shopDetailsComponent = <ShopDetailsEditor shop={shop} />;
  }

  function closeErrorModal() {
    console.log("closeErrorModal");
    setError(null);
    navigateToPage(PATH_WEBSITES, props);
  }

  return (
    <>
      <SidebarWrapper sidebar={<SidebarShop />}>
        {shopDetailsComponent}
        <ErrorModal
          show={error !== null}
          onClose={() => closeErrorModal()}
          positive_button={close_modal_button}
          short_desc={error ? error.short_desc : ""}
          suggestion={error ? error.suggestion : ""}
        />
      </SidebarWrapper>
    </>
  );
}

ShopDetails.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default ShopDetails;
