import editorPlugins from "editor-plugins";
import grapesjs from "grapesjs";
import gjsBlocks from "grapesjs-blocks-basic";
import componentTwitch from "grapesjs-component-twitch";
import customCode from "grapesjs-custom-code";
import pluginGoogleAnalytics from "grapesjs-ga";
import grapesjsIndexeddb from "grapesjs-indexeddb";
import lorySlider from "grapesjs-lory-slider";
import pluginNavbar from "grapesjs-navbar";
import pluginExport from "grapesjs-plugin-export";
import gjsForms from "grapesjs-plugin-forms";
import pluginSocial from "grapesjs-plugin-social";
import presetNewsletter from "grapesjs-preset-newsletter";
import presetWebpage from "grapesjs-preset-webpage";
import pluginTabs from "grapesjs-tabs";
import pluginTouch from "grapesjs-touch";
import tuiImageEditor from "grapesjs-tui-image-editor";
import olittBlocks from "olitt-blocks";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PAID_PLUGIN_MESSAGE } from "../../billing/BillingConstants";
import icon_a from "../../website/assets/icons/icon-a.svg";
import icon_b from "../../website/assets/icons/icon-b.svg";
import icon_c from "../../website/assets/icons/icon-c.svg";
import icon_d from "../../website/assets/icons/icon-d.svg";
import { GOOGLE_FONTS } from "../../website/Fonts";
import customEditor from "../../website/plugins";
import { EDITOR_BASE_CSS } from "../../website/WebsiteConstants";
import { PopUptUtility } from "../utilities/PopUpUtility";
import { cmdCustomOpenAccount, openAccount } from "./editor/consts";
import { installContextMenu, installPopUp } from "./editor/modules";
import ContextMenuPlugin from "./editor/plugins/ContextMenu";
import extraButtonsPlugin from "./editor/plugins/ExtraButtons";
import PatchRTEOnMobile from "./editor/plugins/PatchRTEOnMobile";
import rearrangeButtonsPlugin from "./editor/plugins/RearrangeButtons";
import PaymentModalButton from "./PaymentModalButton";
import { canvasCss, canvasJS } from "modules/core/components/editor/assets";
import pluginCKEditor from "modules/core/components/plugin-editor";

/**
 * @name Editor
 * @description The main editor component.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Editor(props) {
  const {
    website,
    page,
    pages,
    user,
    handleGoHome,
    handleVisitSite,
    handleSavePageContent,
    paid_plugins,
    paid_plan,
    unsplash_images,
    handleTogglePublishWebsite,
    loadCanvas,
    exclude_panel_buttons,
    exclude_panel_models,
    includeCanvasCSS,
    includeCanvasJS,
    allowComponentsResize,
    allowComponentsDrag,
    allowComponentsDrop,
    allowComponentsStyling,
    allowComponentsCopy,
    allowComponentsDelete,
    allowChangeComponentsBackground,
    allowComponentsBadge,
    allowComponentsHighlight,
    componentsEditAttrsRequire,
    trackComponentsChanges,
    allowImageEditing,
    allowImageChange,
    allowImageAddLink,
    onEditorInit,
    canvasId,
    isNewsLetter,
    isInsideModal,
    currentModalId,
    removeFormCaptcha,
  } = props;
  const [newsletter_mode, setNewsletterMode] = React.useState(isNewsLetter);
  const [auto_save_enabled, setAutoSaveEnabled] = React.useState(false);
  const [google_fonts, setGoogleFonts] = React.useState([]);
  const [canTriggerPaymentModal, setCanTriggerPaymentModal] =
    React.useState(false);
  const components_changes: Array = [];
  const history = useHistory();

  /**
   * @name getCustomCodePlugin
   * @description Get the custom code plugin.
   * @returns {null|*}
   */
  function getCustomCodePlugin() {
    if (user.is_code_editor_enabled) {
      const pluginCustomCode: any = customCode;
      return pluginCustomCode;
    }
    return null;
  }

  /**
   * @name customStorage
   * @description Handle the storage of the editor.
   * @param {grapesjs.Editor} editorInstance
   * @param currentPage
   * @param enableBlockResizing
   */
  function customStorage(editorInstance, currentPage, enableBlockResizing) {
    const sm = editorInstance.StorageManager;

    sm.add("local-remote", {
      async load(options) {
        const local = sm.get("indexeddb");
        try {
          await local.load(options.indexeddb);
        } catch (error) {
          console.log("Loading Failed", error);
        }
      },
      async store(data, options) {
        const local = sm.get("indexeddb");
        try {
          await local.store(data, options.indexeddb);
        } catch (error) {
          console.log("Storing Failed", error);
        }
      },
    });

    editorInstance.load({
      onLoad: () => {
        const options = sm.getConfig().options["local-remote"].indexeddb;

        /**
         * load data with backwards compatibility
         * From: https://github.com/artf/grapesjs/issues/4371#issuecomment-1152480736
         * @param {Object} payload
         * @returns {Object}
         */
        const backwardsCompat = (payload = {}) => {
          const { Parser } = editorInstance;
          const { legacyId } = options;

          const data = { ...payload };

          if (!data.pages) {
            data.pages = [
              {
                id: "1",
                frames: [
                  {
                    component:
                      data[`${legacyId}components`] ??
                      Parser.parseHtml(data[`${legacyId}html`]).html,
                  },
                ],
              },
            ];
          }

          if (!data.styles) {
            data.styles = data[`${legacyId}css`]
              ? Parser.parseCss(data[`${legacyId}css`])
              : undefined;
          }

          data[`${legacyId}css`] = undefined;
          data[`${legacyId}html`] = undefined;
          data[`${legacyId}components`] = undefined;
          data.assets = data.assets ? data.assets : [];

          return data;
        };

        /**
         * convert html string to project data
         * @param {string} pageContent
         * @returns {Object}
         */
        const pageContentToProjectData = (pageContent) => {
          const data = {
            pages: [],
            styles: undefined,
            assets: [],
          };

          if (pageContent) {
            const res = editorInstance.Parser.parseHtml(pageContent);

            data.pages = [
              {
                id: "1",
                frames: [
                  {
                    component: res.html,
                  },
                ],
              },
            ];

            data.styles = res.css;
          }

          return data;
        };

        /**
         * When a user clones an existing template, the new website is cloned along
         * with the templates page_json with still references the ids of that template.
         * For example, the templates page id is 300, but the new page is 600. This
         * difference will cause the function {@link backwardsCompat} to return undefined
         * for all values as it relies on the the keys having the same page id as the current
         * page.
         * Therefore, this function aims to patch up the page_json before it is used.
         * @param {Object} payload
         * @returns {Object}
         */
        const patchMismatchedIds = (payload = {}) => {
          const data = { ...payload };

          return Object.entries(data).reduce((acc, [key, value]) => {
            // Check if the key follows the pattern we are interested in
            const isValidPrefix = /(gjs-[0-9]+-(css|html|components))/u;
            if (!isValidPrefix.test(key)) {
              acc[key] = value;
              return acc;
            }
            // Extract the id from the key
            const id = key
              .replace(/gjs-/u, "")
              .replace(/-(css|html|components)/u, "");
            // Does the id in the key match the current page id
            if (id === page.id.toString()) {
              // if so, just pass the key-value pair as is
              acc[key] = value;
            } else {
              // if not, update the page id in the key with the current page id
              acc[key.replace(/[0-9]+/u, page.id)] = value;
            }
            return acc;
          }, {});
        };

        const { page_json = {}, page_content } = currentPage;

        const payload = patchMismatchedIds(page_json);

        let projectData;
        if (Object.keys(payload).length === 0) {
          projectData = pageContentToProjectData(page_content);
        } else {
          projectData = backwardsCompat(payload);
        }

        editorInstance.loadProjectData(projectData);

        enableBlockResizing(editorInstance);
      },
    });
  }

  /**
   * @name removeToolbarAction
   * @description Remove toolbar actions disabled in props.
   * @param toolbar
   * @param commands_enabled_map
   * @returns {*}
   */
  function removeToolbarAction(toolbar, commands_enabled_map) {
    return toolbar.filter(
      (item) => commands_enabled_map[item.command] !== false,
    );
  }

  /**
   * @name componentHasEditAttrs
   * @description Check if the component has edit attrs.
   * @param html_component_attrs_keys
   * @returns {boolean}
   */
  function componentHasEditAttrs(html_component_attrs_keys) {
    const attrs_first_intersection: any = componentsEditAttrsRequire.find(
      (item) => html_component_attrs_keys.includes(item),
    );
    return Boolean(attrs_first_intersection);
  }

  /**
   * @name setAttributes
   * @description Set the attributes of the component.
   * @param component
   */
  function setAttributes(component) {
    const { attributes } = component;
    const commands_enabled_map: Object = {
      "tlb-clone": allowComponentsCopy,
      "tlb-delete": allowComponentsDelete,
      "tlb-move": allowComponentsDrag,
      "change-drag-mode": allowComponentsDrag,
      "change-background": allowChangeComponentsBackground,
      "tui-image-editor": allowImageEditing,
      "change-image": allowImageChange,
      "add-image-link": allowImageAddLink,
    };
    const toolbar: Array = removeToolbarAction(
      attributes.toolbar,
      commands_enabled_map,
    );
    component.set({
      resizable: allowComponentsResize,
      draggable: allowComponentsDrag,
      droppable: allowComponentsDrop,
      stylable: allowComponentsStyling,
      copyable: allowComponentsCopy,
      removable: allowComponentsDelete,
      badgable: allowComponentsBadge,
      highlightable: allowComponentsHighlight,
      toolbar,
    });
    const html_component_attrs: any =
      (component.attributes || {}).attributes || {};
    if (componentsEditAttrsRequire.length > 0) {
      const html_component_attrs_keys: any = Object.keys(html_component_attrs);
      if (
        html_component_attrs_keys.length === 0 ||
        !componentHasEditAttrs(html_component_attrs_keys)
      ) {
        component.set({
          editable: false,
          hoverable: false,
          selectable: false,
        });
      } else if (trackComponentsChanges) {
        component.updated = (property, value) => {
          if (typeof value === "string") {
            components_changes.push({
              attributes: component.getAttributes(),
              value,
            });
          }
        };
      }
    }
    component.get("components").each((c) => setAttributes(c));
  }

  /**
   * @name enableBlockResizing
   * @description Enable block resizing.
   * @param editorInstance
   */
  function enableBlockResizing(editorInstance) {
    editorInstance.Components.getComponents().each((c) => setAttributes(c));
  }

  /**
   * @name getSaveLabel
   * @description Get the save label based on the editor saving status.
   * @param saving
   * @returns {*}
   */
  function getSaveLabel(saving = false) {
    let save_label: any = "Save Changes";
    if (saving) {
      save_label = "Saving Changes";
    }
    return save_label;
  }

  /**
   * @name getSaveIcon
   * @description Get save icon.
   * @param saving
   * @returns {*}
   */
  function getSaveIcon(saving = false) {
    let save_icon: any = "fa fa-floppy-o";
    if (saving) {
      save_icon = "fa fa-spinner fa-spin";
    }
    return save_icon;
  }

  /**
   * @name getAutoSaveLoadingLabel
   * @description Get auto save label.
   * @param saving
   * @returns {string}
   */
  function getAutoSaveLoadingLabel(
    is_auto_save_enabled = false,
    saving = false,
  ) {
    let auto_save_label: string = "All changes are saved";
    if (saving) {
      auto_save_label = "Changes are being auto-saved";
    } else if (!is_auto_save_enabled) {
      auto_save_label = "Auto-save is disabled";
    }
    return auto_save_label;
  }

  /**
   * @name getAutoSaveLoadingIcon
   * @description Get auto save icon.
   * @param saving
   * @returns {string}
   */
  function getAutoSaveLoadingIcon(
    is_auto_save_enabled = false,
    saving = false,
  ) {
    let auto_save_icon: string = "fa fa-check-circle";
    if (saving) {
      auto_save_icon = "fa fa-spinner fa-spin";
    } else if (!is_auto_save_enabled) {
      auto_save_icon = "fa fa-check-circle d-none";
    }
    return auto_save_icon;
  }

  /**
   * @name getAutoSaveLabel
   * @description Get auto save label.
   * @param saving
   * @returns {string}
   */
  function getAutoSaveLabel(saving = false) {
    let auto_save_label: string = "Auto-save is enabled";
    if (saving) {
      auto_save_label = "Changes are being auto-saved";
    } else if (!auto_save_enabled) {
      auto_save_label = "Auto-save is disabled";
    }
    return auto_save_label;
  }

  /**
   * @name getAutoSaveIcon
   * @description Get auto save icon.
   * @param saving
   * @returns {string}
   */
  function getAutoSaveIcon(saving = false) {
    let auto_save_icon: string = "fa fa-toggle-on d-none";
    if (saving) {
      auto_save_icon = "fa fa-spinner fa-spin d-none";
    } else if (!auto_save_enabled) {
      auto_save_icon = "fa fa-toggle-off d-none";
    }
    return auto_save_icon;
  }

  /**
   * @name toggleAutoSaveLoadingState
   * @description Toggle Auto saving Loading State.
   * @param editorInstance
   * @param saving
   */
  function toggleAutoSaveLoadingState(editorInstance, saving = false) {
    const is_auto_save_enabled =
      editorInstance.Commands.isActive("toggle-auto-save");
    if (!is_auto_save_enabled) {
      return;
    }
    const autoSaveLoadingButton = editorInstance.Panels.getButton(
      "options",
      "auto-save-state",
    );
    if (!autoSaveLoadingButton) {
      return;
    }
    autoSaveLoadingButton.set(
      "className",
      getAutoSaveLoadingIcon(is_auto_save_enabled, saving),
    );
    autoSaveLoadingButton.set("attributes", {
      title: getAutoSaveLoadingLabel(is_auto_save_enabled, saving),
    });
  }

  /**
   * @name toggleEditorSaving
   * @description Toggle editor saving.
   * @param editorInstance
   * @param saving
   */
  function toggleEditorSaving(editorInstance, saving = false) {
    const auto_save_button: any = editorInstance.Panels.getButton(
      "options",
      "auto-save-enabled",
    );
    if (auto_save_button) {
      auto_save_button.set("className", getAutoSaveIcon(saving));
      auto_save_button.set("attributes", {
        title: getAutoSaveLabel(saving),
      });
    }
    if (!auto_save_enabled) {
      const save_button: any = editorInstance.Panels.getButton(
        "options",
        "save-site",
      );
      if (save_button) {
        save_button.set("className", getSaveIcon(saving));
        save_button.set("attributes", { title: getSaveLabel(saving) });
      }
    }
  }

  /**
   * @name savePageContent
   * @description Save page content.
   * @param editorInstance
   * @param from_auto_save
   */
  function savePageContent(editorInstance, from_auto_save = false) {
    const is_auto_save_enabled =
      editorInstance.Commands.isActive("toggle-auto-save");
    if (is_auto_save_enabled || !from_auto_save) {
      toggleEditorSaving(editorInstance, true);
      toggleAutoSaveLoadingState(editorInstance, true);
      handleSavePageContent(
        editorInstance,
        is_auto_save_enabled,
        () => {
          // editorInstance.store();
          toggleEditorSaving(editorInstance, false);
          toggleAutoSaveLoadingState(editorInstance, false);
        },
        from_auto_save,
        components_changes,
      );
    }
  }

  /**
   * @name addAutoSaveStateButton
   * @description Add auto save state save button to editor.
   * @param editorInstance
   */
  function addAutoSaveStateButton(editorInstance) {
    const button = editorInstance.Panels.getButton(
      "options",
      "auto-save-state",
    );
    if (!button) {
      editorInstance.Panels.addButton("options", [
        {
          id: "auto-save-state",
          className: "fa fa-check-circle",
          attributes: { title: "Save State" },
        },
      ]);
    }
  }

  /**
   * @name addSaveButton
   * @description Add save button to editor.
   * @param editorInstance
   */
  function addSaveButton(editorInstance) {
    const button = editorInstance.Panels.getButton("options", "save-site");
    if (!button) {
      editorInstance.Panels.addButton("options", [
        {
          id: "save-site",
          className: getSaveIcon(),
          command: () => savePageContent(editorInstance),
          attributes: { title: getSaveLabel() },
        },
      ]);
    }
  }

  /**
   * @name toggleAutoSave
   * @description Toggle auto save on editor.
   * @param editorInstance
   */
  function toggleAutoSave(
    editorInstance,
    is_auto_save_enabled = auto_save_enabled,
  ) {
    if (is_auto_save_enabled) {
      editorInstance.Panels.removeButton("options", "auto-save-state");
      addSaveButton(editorInstance);
    } else {
      editorInstance.Panels.removeButton("options", "save-site");
      addAutoSaveStateButton(editorInstance);
    }
    setAutoSaveEnabled(!is_auto_save_enabled);
    toggleEditorSaving(editorInstance, false);
  }

  /**
   * @name toggleEditorMode
   * @description Toggle editor mode between newsletter & web editor.
   */
  function toggleEditorMode() {
    setNewsletterMode(!newsletter_mode);
  }

  /**
   * @name getPublishLabel
   * @description Get publish label based on site publish status.
   * @returns {string}
   */
  function getPublishLabel(updated_website = website) {
    let publish_label: string = "Publish";
    if (updated_website.is_published) {
      publish_label = "Unpublish";
    }
    return publish_label;
  }

  /**
   * @name getPublishIcon
   * @description Get publish icon based on site publish status.
   * @returns {string}
   */
  function getPublishIcon() {
    let publish_icon: string = "fa fa-cloud-upload";
    if (website.is_published) {
      publish_icon = "fa fa-cloud-download";
    }
    return publish_icon;
  }

  /**
   * Checks if a plugin is allowed based on the type, paidPlugins, and newsletterMode.
   *
   * @param {string} type - The type of the plugin.
   * @param {Array} paidPlugins - The list of paid plugins.
   * @param {boolean} newsletterMode - Indicates if the editor is in newsletter mode.
   * @returns {boolean} - Returns true if the plugin is allowed, false otherwise.
   */
  function isPluginAllowed(type, paidPlugins, newsletterMode) {
    return (
      (paidPlugins.includes(type) || paidPlugins.length === 0) &&
      !newsletterMode
    );
  }

  /**
   * @name checkIfPaidPlugin
   * @description Check if dragged plugin requires a paid plan.
   * @param componentModel
   * @param website_id
   */
  function checkIfPaidPlugin(componentModel, website_id, editor) {
    if (
      isPluginAllowed(
        componentModel.attributes.type,
        paid_plugins,
        newsletter_mode,
      )
    ) {
      const subscription_plan: any = website?.subscription_plan || {};
      const subscription_plan_pricing: any = subscription_plan?.pricing || {};
      if (subscription_plan_pricing.amount <= 0.0) {
        editor.Commands.run("core:component-delete", {
          component: componentModel,
        });
        PopUptUtility.showUpgradeModal(
          website_id,
          PAID_PLUGIN_MESSAGE,
          paid_plan,
        );
      }
    }
  }

  /**
   * @name updatePublishIcon
   * @description Update publish icon based on site publish status.
   * @param updated_website
   * @param sender
   */
  function updatePublishIcon(updated_website, sender) {
    if (sender) {
      // sender.set("className", getPublishIcon());
      sender.set("attributes", { title: getPublishLabel(updated_website) });
    }
  }

  /**
   * @name publishWebsiteCallback
   * @description Callback for publishing website.
   * @param updated_website
   * @param sender
   */
  function publishWebsiteCallback(updated_website, sender) {
    sender.set("label", getPublishLabel(updated_website));
    updatePublishIcon(updated_website, sender);
  }

  /**
   * @name customizeOptionsPanel
   * @description Customize options panel based on props passed.
   * @param editorInstance
   * @param current_page
   * @param auto_save_enabled
   */
  function customizeOptionsPanel(
    editorInstance,
    current_page,
    auto_save_enabled,
  ) {
    if (!user.is_code_editor_enabled) {
      editorInstance.Panels.removeButton("options", "gjs-open-import-webpage");
    }
    if (
      website.subscription_plan &&
      website.subscription_plan.pricing.amount <= 0.0 &&
      !user.is_code_editor_enabled
    ) {
      editorInstance.Panels.removeButton("options", "export-template");
    }
    editorInstance.Panels.addButton("options", [
      {
        id: "publish-website",
        label: getPublishLabel(),
        command: (_, sender) => {
          sender.set("label", "Publishing...");
          handleTogglePublishWebsite(sender, publishWebsiteCallback);
        },
        attributes: { title: getPublishLabel() },
      },
      {
        id: "visit-site",
        className: "fa fa-external-link d-none",
        command: () => handleVisitSite(),
        attributes: { title: "Visit" },
      },
      {
        id: "enable-newsletter",
        className: "fa fa-newspaper-o d-none",
        command: () => toggleEditorMode(),
        attributes: { title: "Toggle newsletter" },
      },
      {
        id: "auto-save-enabled",
        className: getAutoSaveIcon(),
        command: (editor) => toggleAutoSave(editor),
        attributes: { title: getAutoSaveLabel() },
      },
      {
        id: openAccount,
        className: "d-none d-md-block fa fa-user-circle",
        command: {
          run: (editor) => {
            editor.Commands.run(cmdCustomOpenAccount, { user, history });
          },
          stop: (editor) => {
            editor.Commands.stop(cmdCustomOpenAccount);
          },
        },
        attributes: { title: "My Account" },
      },
    ]);
    if (!user.is_news_letter_enabled) {
      editorInstance.Panels.removeButton("options", "enable-newsletter");
    }
    if (!auto_save_enabled) {
      addSaveButton(editorInstance);
    } else {
      addAutoSaveStateButton(editorInstance);
    }

    const previewBtn = editorInstance.Panels.getButton("options", "preview");
    previewBtn.set({ label: "Preview", className: "" });

    const undoBtn = editorInstance.Panels.getButton("options", "undo");
    undoBtn.set({ className: "bi bi-reply-fill" });

    const redoBtn = editorInstance.Panels.getButton("options", "redo");
    redoBtn.set({ className: "bi bi-reply-fill icon-flipped" });

    const view_components: any = editorInstance.Panels.getButton(
      "options",
      "sw-visibility",
    );
    view_components.set("active", 0);
    exclude_panel_buttons.forEach((button_map) => {
      editorInstance.Panels.removeButton(button_map.model, button_map.id);
    });
    exclude_panel_models.forEach((model) => {
      editorInstance.Panels.removePanel(model);
    });

    // Custom Commands

    editorInstance.Commands.add("toggle-auto-save", {
      run: (e) => {
        toggleAutoSave(e, false);
      },
      stop: (e) => {
        toggleAutoSave(e, true);
      },
    });

    editorInstance.Commands.add("visit-site", {
      run: () => handleVisitSite(),
    });

    editorInstance.Commands.add("save-changes", {
      run: (e) => savePageContent(e),
    });

    editorInstance.Commands.add("go-home", {
      run: () => handleGoHome(),
    });

    editorInstance.Commands.add("toggle-editor-mode", {
      run: () => toggleEditorMode(),
    });
  }

  /**
   * @name setFonts
   * @description Set fonts based on website fonts.
   * @param font
   */
  function setFonts(font) {
    setGoogleFonts([...google_fonts, font]);
  }

  /**
   * @name customizeEditor
   * @description Customize editor based on props passed.
   * @param editorInstance
   * @param current_page
   * @param website_id
   */
  function customizeEditor(editorInstance, current_page, website_id) {
    customStorage(editorInstance, current_page, enableBlockResizing);

    customizeOptionsPanel(editorInstance, current_page, auto_save_enabled);
    editorInstance.on("load", () => {
      const fontProperty: any = editorInstance.StyleManager.getProperty(
        "typography",
        "font-family",
      );
      const current_fonts = fontProperty.getOptions();
      fontProperty.set("options", current_fonts.concat(GOOGLE_FONTS));
      const font_families: any = document.querySelectorAll(
        "div#gjs-sm-font-family select option",
      );
      if (font_families.length > 0) {
        font_families.forEach((option) => {
          option.style.fontFamily = option.value;
        });
      }
      if (unsplash_images) {
        editorInstance.AssetManager.add(unsplash_images);
      }
    });
    editorInstance.on(
      "component:styleUpdate:font-family",
      (component, font) => {
        const font_element: { value: any, name: any } = {
          value: font,
          name: font,
        };
        const current_fonts: any = google_fonts;
        if (GOOGLE_FONTS.some((value) => value.name === font_element.name)) {
          if (current_fonts.indexOf(font) === -1) {
            setFonts(font);
          }
        }
      },
    );

    editorInstance.on("block:drag", () => {
      editorInstance?.Modal.close();
      editorInstance?.PopUp.close();
    });

    editorInstance.on("block:drag:stop", (componentModel, addedBlock) => {
      checkIfPaidPlugin(addedBlock, website_id, editorInstance);
    });
    editorInstance.on("component:add", (componentModel) => {
      if (
        componentModel?.attributes?.type &&
        componentModel.attributes.type.trim() !== ""
      ) {
        checkIfPaidPlugin(componentModel, website_id, editorInstance);
      }
    });

    editorInstance.on("storage:store", () => {
      savePageContent(editorInstance, true);
    });

    editorInstance.on("custom:payment:modal", (sender) => {
      setCanTriggerPaymentModal((state) => !state);
    });
  }

  /**
   * @name getCanvasStyles
   * @description Get canvas css styles.
   * @returns {string[]|*[]}
   */
  function getCanvasStyles() {
    if (includeCanvasCSS) {
      return canvasCss;
    }
    return [];
  }

  /**
   * @name getCanvasScripts
   * @description Get the scripts to be injected into the canvas.
   * @returns {string[]|*[]}
   */
  function getCanvasScripts() {
    if (includeCanvasJS) {
      return canvasJS;
    }
    return [];
  }

  /**
   * @name handleInitializeEditor
   * @description Initialize the editor instance.
   * @param current_page
   * @param website_id
   * @param page_id
   * @param auto_save_enabled
   */
  function handleInitializeEditor(
    current_page,
    website_id,
    page_id,
    auto_save_enabled,
  ) {
    let editor_preset: any = presetWebpage;
    if (newsletter_mode) {
      editor_preset = presetNewsletter;
    }
    const page_title: any = website.title.split(" ").join("");
    let plugins = [
      installContextMenu,
      installPopUp,
      editor_preset,
      customEditor,
      pluginCKEditor,
      tuiImageEditor,
      olittBlocks,
      lorySlider,
      pluginTabs,
      getCustomCodePlugin(),
      pluginTouch,
      pluginSocial,
      grapesjsIndexeddb,
      pluginExport,
      gjsForms,
      gjsBlocks,
      pluginNavbar,
      rearrangeButtonsPlugin,
      extraButtonsPlugin,
      ContextMenuPlugin,
      PatchRTEOnMobile,
    ];
    if (!newsletter_mode) {
      plugins = plugins.concat([
        editorPlugins,
        pluginGoogleAnalytics,
        componentTwitch,
      ]);
    }

    let editorHeight = window.innerHeight;
    if (isInsideModal) {
      const modalElement = document.getElementById(currentModalId);
      const modalBody = modalElement.querySelector(".modal-body");
      const { y: yOffset } = modalBody.getBoundingClientRect();
      editorHeight = window.innerHeight - yOffset - 65;
    }

    const editorInstance: any = grapesjs.init({
      container: `#${canvasId}`,
      height: editorHeight,
      width: "auto",
      allowScripts: 1,
      forceClass: false,
      baseCss: EDITOR_BASE_CSS,
      selectorManager: {
        componentFirst: 1,
      },
      assetManager: {
        onSelect: () => {
          // Do nothing
        },
        onDblClick: (model) => {
          // Do nothing
        },
      },
      storageManager: {
        type: "local-remote",
        autosave: true,
        autoload: 1,
        stepsBeforeSave: 10,
        options: {
          "local-remote": {
            indexeddb: {
              key: `page-${page_id}`,
              legacyId: `gjs-${page_id}-`,
              dbName: "olittStore",
              objectStoreName: "pages",
            },
          },
        },
      },
      styleManager: { clearProperties: 1 },
      plugins,
      pluginsOpts: {
        [rearrangeButtonsPlugin]: {
          newsletter_mode,
        },
        [customEditor]: {
          newsletter_mode,
          site_id: website_id,
          removeFormCaptcha,
          changeBackgroundLabel: `
            <div class="text-center">
              <i class="fa fa-file-image-o" aria-hidden="true"></i>
            </div>
          `,
          enableDragLabel: `
          <div class="text-center">
            <i class="fa fa-hand-rock-o" aria-hidden="true"></i>
          </div>
        `,
        },
        [pluginExport]: {
          filenamePfx: `olitt_${page_title}`,
        },
        [editor_preset]: {
          exportOpts: false,
          blocksBasicOpts: {
            addBasicStyle: true,
            stylePrefix: "olitt-",
          },
          formsOpts: {
            blocks: [
              "input",
              "textarea",
              "select",
              "button",
              "label",
              "checkbox",
              "radio",
            ],
          },
        },
        [lorySlider]: {
          sliderBlock: {
            category: "Extra",
          },
        },
        [pluginTabs]: {
          tabsBlock: {
            category: "Extra",
          },
        },
        [pluginSocial]: {
          blocks: [
            "facebook",
            "twitter",
            "linkedin",
            "skype",
            "link",
            "link-block",
          ],
        },
        [tuiImageEditor]: {
          config: {
            includeUI: {
              initMenu: "filter",
            },
          },
          toolbarIcon: "Edit image",
          icons: {
            "menu.normalIcon.path": icon_d,
            "menu.activeIcon.path": icon_b,
            "menu.disabledIcon.path": icon_a,
            "menu.hoverIcon.path": icon_c,
            "submenu.normalIcon.path": icon_d,
            "submenu.activeIcon.path": icon_c,
          },
        },
        [extraButtonsPlugin]: {
          newsletter_mode,
          user,
          auto_save_enabled,
          website,
          page,
          pages,
        },
      },
      canvas: {
        scripts: getCanvasScripts(),
        styles: getCanvasStyles(),
      },
    });
    customizeEditor(editorInstance, current_page, website_id);
    onEditorInit(editorInstance);
  }

  useEffect(() => {
    if (page && website) {
      const page_id: number = page.id;
      const website_id: number = website.id;
      handleInitializeEditor(page, website_id, page_id, auto_save_enabled);
    }
  }, [loadCanvas, newsletter_mode, website, page, pages]);

  let paymentModal;
  if (website) {
    paymentModal = (
      <PaymentModalButton
        paidPlan={paid_plan}
        website={website}
        trigger={canTriggerPaymentModal}
      />
    );
  }
  return (
    <>
      {paymentModal}
      <div id={canvasId} />
    </>
  );
}

Editor.defaultProps = {
  pages: [],
  paid_plan: {},
  paid_plugins: [],
  loadCanvas: false,
  website: {},
  unsplash_images: [],
  exclude_panel_buttons: [],
  exclude_panel_models: [],
  includeCanvasCSS: true,
  includeCanvasJS: true,
  allowComponentsResize: true,
  allowComponentsDrag: true,
  allowComponentsDrop: true,
  allowComponentsStyling: true,
  allowComponentsCopy: true,
  allowComponentsDelete: true,
  allowChangeComponentsBackground: true,
  allowComponentsBadge: true,
  allowComponentsHighlight: true,
  componentsEditAttrsRequire: [],
  trackComponentsChanges: false,
  allowImageEditing: true,
  allowImageChange: true,
  allowImageAddLink: true,
  onEditorInit: () => {},
  canvasId: "site-editor",
  isNewsLetter: false,
  isInsideModal: false,
  currentModalId: null,
  removeFormCaptcha: false,
};

Editor.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  handleGoHome: PropTypes.func.isRequired,
  website: PropTypes.instanceOf(Object),
  handleVisitSite: PropTypes.func.isRequired,
  page: PropTypes.instanceOf(Object).isRequired,
  pages: PropTypes.instanceOf(Array),
  handleSavePageContent: PropTypes.func.isRequired,
  paid_plan: PropTypes.instanceOf(Object),
  paid_plugins: PropTypes.instanceOf(Array),
  handleTogglePublishWebsite: PropTypes.func.isRequired,
  loadCanvas: PropTypes.bool,
  unsplash_images: PropTypes.instanceOf(Array),
  exclude_panel_buttons: PropTypes.instanceOf(Array),
  exclude_panel_models: PropTypes.instanceOf(Array),
  includeCanvasCSS: PropTypes.bool,
  includeCanvasJS: PropTypes.bool,
  allowComponentsResize: PropTypes.bool,
  allowComponentsDrag: PropTypes.bool,
  allowComponentsDrop: PropTypes.bool,
  allowComponentsStyling: PropTypes.bool,
  allowComponentsCopy: PropTypes.bool,
  allowComponentsDelete: PropTypes.bool,
  allowChangeComponentsBackground: PropTypes.bool,
  allowComponentsBadge: PropTypes.bool,
  allowComponentsHighlight: PropTypes.bool,
  componentsEditAttrsRequire: PropTypes.instanceOf(Array),
  trackComponentsChanges: PropTypes.bool,
  allowImageEditing: PropTypes.bool,
  allowImageChange: PropTypes.bool,
  allowImageAddLink: PropTypes.bool,
  onEditorInit: PropTypes.func,
  canvasId: PropTypes.string,
  isNewsLetter: PropTypes.bool,
  isInsideModal: PropTypes.bool,
  currentModalId: PropTypes.string,
  removeFormCaptcha: PropTypes.bool,
};

export default Editor;
