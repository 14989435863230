import { useQuery } from "@tanstack/react-query";
import ShopApi from "../ShopAPIs";

function useProducts(site_id) {
  const shopApi = new ShopApi();
  const { isLoading, data, error } = useQuery({
    queryKey: ["products"],
    queryFn: () =>
      new Promise((resolve, reject) => {
        shopApi.getProducts(
          site_id,
          (products) => resolve(products),
          (e) => reject(e),
        );
      }),
    refetchOnMount: false,
    staleTime: 300000,
  });

  return {
    products: data,
    isLoading,
    error,
  };
}

export default useProducts;
