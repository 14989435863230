import { ApmRoute as Route } from "@elastic/apm-rum-react";
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import {
  PATH_DNS,
  PATH_DNS_DOMAINS,
  PATH_DNS_DOMAIN_RECORDS,
} from "./DnsConstants";
import DomainList from "./layouts/DomainList";
import Page404 from "./layouts/Page404";
import RecordList from "./layouts/RecordList";

function DnsRouter() {
  return (
    <Switch>
      <Route key={3.1} exact path={PATH_DNS_DOMAINS} component={DomainList} />
      <Route
        key={3.2}
        exact
        path={PATH_DNS_DOMAIN_RECORDS}
        component={RecordList}
      />
      <Redirect key={3.3} exact from={PATH_DNS} to={PATH_DNS_DOMAINS} />
      <Route key={3.4} path="*" component={Page404} />
    </Switch>
  );
}

export default DnsRouter;
