import { ApmRoute as Route } from "@elastic/apm-rum-react";
import React from "react";
import { Switch } from "react-router-dom";
import Page404 from "../core/layouts/Page404";
import PhishingDomainChecker from "./layouts/PhishingDomainChecker";
import { PATH_CHECK_PHISHING_DOMAIN } from "./LoudgunConstants";

function LoudgunRouter() {
  return (
    <Switch>
      <Route
        key={1.1}
        exact
        path={PATH_CHECK_PHISHING_DOMAIN}
        component={PhishingDomainChecker}
      />
      <Route key={1.2} path="*" component={Page404} />
    </Switch>
  );
}

export default LoudgunRouter;
