import React, { Component } from "react";

export default class PageLoadingIndicator extends Component {
  render() {
    const style: { width: string, height: string } = {
      width: "4rem",
      height: "4rem",
    };

    return (
      <div className="row justify-content-center align-content-center vh-100">
        <div
          className="spinner-grow text-olitt-grey"
          style={style}
          role="status"
        >
          <span className="sr-only">Processing...</span>
        </div>
      </div>
    );
  }
}
