import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { NodeToolbar, Position } from "reactflow";
import { openModal } from "modules/base/utilities/Actions";
import ConfigureNodeModal from "modules/marketing/components/campaigns/nodes/configure/ConfigureNodeModal";

function ConfigureButton({
  campaignEvent,
  setState,
  currentModalId,
  updateNode,
  updateEvent,
  previouslyNewNode,
}) {
  const [isModalReady, setIsModalReady] = useState(false);
  const onModalReady = useCallback(() => {
    setIsModalReady(true);
  });
  const handleConfigure = useCallback(() => {
    const reopenParentModal = campaignEvent.type !== "new";
    openModal(
      `configure-${campaignEvent.eventType}-node-${campaignEvent.id}-modal`,
      currentModalId,
      reopenParentModal,
    );
  });
  useEffect(() => {
    if (previouslyNewNode && isModalReady) {
      updateNode(campaignEvent.id, {
        data: {
          previouslyNewNode: false,
        },
      });
      handleConfigure();
    }
  }, [isModalReady]);
  return (
    <>
      <ConfigureNodeModal
        campaignEvent={campaignEvent}
        setState={setState}
        updateNode={updateNode}
        updateEvent={updateEvent}
        campaignBuilderModalId={currentModalId}
        onModalReady={onModalReady}
      />
      <NodeToolbar position={Position.Left}>
        <button
          type="button"
          className="btn btn-link p-0"
          onClick={handleConfigure}
        >
          <i className="bi bi-gear-fill" />
        </button>
      </NodeToolbar>
    </>
  );
}

ConfigureButton.defaultProps = {
  updateNode: () => {
    // do nothing
  },
  previouslyNewNode: false,
};

ConfigureButton.propTypes = {
  campaignEvent: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  currentModalId: PropTypes.string.isRequired,
  updateNode: PropTypes.func,
  updateEvent: PropTypes.func.isRequired,
  previouslyNewNode: PropTypes.bool,
};

export default ConfigureButton;
