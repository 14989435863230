import PropTypes from "prop-types";
import Modal from "modules/base/components/Modal";
import PrebuiltEmailTemplates from "modules/marketing/components/emailstemplates/PrebuiltEmailTemplates";

function PrebuiltEmailTemplatesModal({ setPrebuiltHtml, uuid }) {
  const modalId = `prebuilt-templates-${uuid}-modal`;
  return (
    <Modal modal_title="Prebuilt Templates" modal_id={modalId} size="xl">
      <PrebuiltEmailTemplates
        setPrebuiltHtml={setPrebuiltHtml}
        uuid={uuid}
        currentModalId={modalId}
      />
    </Modal>
  );
}

PrebuiltEmailTemplatesModal.propTypes = {
  setPrebuiltHtml: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default PrebuiltEmailTemplatesModal;
