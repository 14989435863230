import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import { BillingAPI } from "modules/billing/BillingAPIs";
import SidebarWrapper from "modules/core/components/SidebarWrapper";
import Upgrade from "modules/core/components/Upgrade";
import SidebarMarketing from "modules/marketing/components/SidebarMarketing";

import SiteApi from "modules/website/api/SiteApi";

function SubscriptionCheck(props) {
  const { slug, component, match } = props;
  const { id } = match.params;
  const siteApi = new SiteApi();
  const billingApi = new BillingAPI();
  const { site, isLoading, error } = siteApi.getWebsite(id);
  const {
    plans,
    error: plansError,
    isLoading: plansLoading,
  } = billingApi.retrievePlans();

  const upgradePlan = plans.find((plan) => {
    const features = plan.features.map((feature) => feature.slug);
    return features.includes(slug);
  });

  if (isLoading || plansLoading) {
    return <NetworkActivityIndicator />;
  }
  if (error || plansError) {
    return <NetworkMessageDisplay error={error} />;
  }
  if (site) {
    const plan = site.subscription_plan;
    const features = plan.features.map((feature) => feature.slug.toLowerCase());
    if (features.includes(slug)) {
      return (
        <SidebarWrapper sidebar={<SidebarMarketing />}>
          {component}
        </SidebarWrapper>
      );
    }
    return (
      <SidebarWrapper sidebar={<SidebarMarketing />}>
        <Upgrade upgradePlan={upgradePlan} site={site} />
      </SidebarWrapper>
    );
  }
  return null;
}

SubscriptionCheck.propTypes = {
  slug: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(SubscriptionCheck);
