import PropTypes from "prop-types";
import { useCallback } from "react";
import { locationFormatter } from "modules/marketing/Actions";

function PreviewContact(props) {
  const { contact } = props;
  const name = `${contact.firstname ?? ""} ${contact.lastname ?? ""}`.trim();
  const displayClass = useCallback((value) => {
    return value ? "d-block" : "d-none";
  });
  const location = locationFormatter(contact);
  return (
    <div className="row row-cols-1 gy-md-3 gy-1">
      <span className={displayClass(name)}>
        <i className="bi bi-person-badge" /> {name}
      </span>
      <span className={displayClass(contact.email)}>
        <i className="bi bi-envelope" /> {contact.email}
      </span>
      <span className={displayClass(contact.mobile)}>
        <i className="bi bi-phone" /> {contact.mobile}
      </span>
      <span className={displayClass(contact.phone)}>
        <i className="bi bi-telephone" /> {contact.phone}
      </span>
      <span className={displayClass(contact.company)}>
        <i className="bi bi-building" /> {contact.company}
      </span>
      <span className={displayClass(location)}>
        <i className="bi bi-geo-alt" /> {location}
      </span>
    </div>
  );
}

PreviewContact.propTypes = {
  contact: PropTypes.instanceOf(Object).isRequired,
};

export default PreviewContact;
