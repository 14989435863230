export const cmdImport = "gjs-open-import-webpage";

export const cmdCustomOpenImport = "custom-open-import-template";

export const cmdDeviceDesktop = "set-device-desktop";

export const cmdDeviceTablet = "set-device-tablet";

export const cmdDeviceMobile = "set-device-mobile";

export const cmdClear = "canvas-clear";

export const cmdOpenAssets = "open-assets";

export const cmdCustomOpenStyleManager = "custom-open-style-manager";

export const cmdCustomOpenTraitManager = "custom-open-trait-manager";

export const cmdCustomOpenLayers = "custom-open-layers";

export const cmdCustomOpenBlockManager = "custom-open-block-manager";

export const cmdCustomOpenToolbar = "custom-open-toolbar";

export const cmdCustomOpenAccount = "custom-open-account";

export const cmdCustomOpenPages = "custom-open-pages";

export const swv = "sw-visibility";

export const expt = "export-template";

export const openStyles = "open-sm";

export const openTraits = "open-tm";

export const openLayers = "open-layers";

export const openBlocks = "open-blocks";

export const ful = "fullscreen";

export const prv = "preview";

export const openAssetManager = "open-am";

export const openAccount = "open-account-dropdown";

export const openAdditionalActions = "open-additional-actions";
