import FormList from "modules/marketing/components/forms/FormList";
import SubscriptionCheck from "modules/marketing/layouts/SubscriptionCheck";
import { MARKETING_FORMS_SLUG } from "modules/marketing/MarketingConstants";

function Forms() {
  return (
    <SubscriptionCheck slug={MARKETING_FORMS_SLUG} component={<FormList />} />
  );
}

export default Forms;
