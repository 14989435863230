import PropTypes from "prop-types";
import { useCallback } from "react";
import Modal from "modules/base/components/Modal";
import { closeModalById, openModal } from "modules/base/utilities/Actions";
import {
  buildCampaignCanvasNodesSettings,
  buildCampaignEventConnections,
} from "modules/marketing/Actions";
import BuildCampaign from "modules/marketing/components/campaigns/BuildCampaign";

function BuildCampaignModal({
  state,
  setState,
  currentModalId,
  resetBuilderCanvas,
}) {
  const modalId = `build-campaign-${state.id ?? "new"}-modal`;
  const tempModal = document.createElement("div");
  tempModal.id = modalId;
  tempModal.className = "modal-dialog modal-xl";
  document.body.appendChild(tempModal);
  const { width: canvasWidth } = tempModal.getBoundingClientRect();
  tempModal.remove();
  const onCampaignSave = useCallback((nodes, edges) => {
    const connections = buildCampaignEventConnections(edges);
    const canvasNodesSettings = buildCampaignCanvasNodesSettings(nodes);
    setState((prevState) => ({
      ...prevState,
      canvasSettings: {
        ...prevState.canvasSettings,
        nodes: canvasNodesSettings,
        connections,
      },
    }));
    closeModalById(modalId);
    openModal(currentModalId);
  }, []);
  return (
    <Modal modal_title="Build Campaign" modal_id={modalId} size="xl">
      <BuildCampaign
        canvasWidth={canvasWidth}
        currentModalId={modalId}
        state={state}
        setState={setState}
        onCampaignSave={onCampaignSave}
        resetBuilderCanvas={resetBuilderCanvas}
      />
    </Modal>
  );
}

BuildCampaignModal.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  currentModalId: PropTypes.string.isRequired,
  resetBuilderCanvas: PropTypes.bool.isRequired,
};

export default BuildCampaignModal;
