const deviceBrands = [
  {
    label: "2E",
    value: "5E",
  },
  {
    label: "F2 Mobile",
    value: "2F",
  },
  {
    label: "3Q",
    value: "3Q",
  },
  {
    label: "7 Mobile",
    value: "J7",
  },
  {
    label: "3GNET",
    value: "2Q",
  },
  {
    label: "4Good",
    value: "4G",
  },
  {
    label: "3GO",
    value: "27",
  },
  {
    label: "4ife",
    value: "04",
  },
  {
    label: "360",
    value: "36",
  },
  {
    label: "8848",
    value: "88",
  },
  {
    label: "A1",
    value: "41",
  },
  {
    label: "Accent",
    value: "00",
  },
  {
    label: "Ace",
    value: "AE",
  },
  {
    label: "Acer",
    value: "AC",
  },
  {
    label: "Acteck",
    value: "3K",
  },
  {
    label: "Advan",
    value: "A9",
  },
  {
    label: "Advance",
    value: "AD",
  },
  {
    label: "Adronix",
    value: "76",
  },
  {
    label: "AfriOne",
    value: "AF",
  },
  {
    label: "AGM",
    value: "A3",
  },
  {
    label: "AG Mobile",
    value: "J0",
  },
  {
    label: "Ainol",
    value: "AZ",
  },
  {
    label: "Airness",
    value: "AI",
  },
  {
    label: "Airties",
    value: "AT",
  },
  {
    label: "AIS",
    value: "0A",
  },
  {
    label: "Aiwa",
    value: "AW",
  },
  {
    label: "Aiuto",
    value: "85",
  },
  {
    label: "Akai",
    value: "AK",
  },
  {
    label: "AKIRA",
    value: "Q3",
  },
  {
    label: "Alba",
    value: "1A",
  },
  {
    label: "Alcatel",
    value: "AL",
  },
  {
    label: "Alcor",
    value: "20",
  },
  {
    label: "ALDI NORD",
    value: "7L",
  },
  {
    label: "ALDI SÜD",
    value: "6L",
  },
  {
    label: "Alfawise",
    value: "3L",
  },
  {
    label: "Aligator",
    value: "4A",
  },
  {
    label: "AllCall",
    value: "AA",
  },
  {
    label: "AllDocube",
    value: "3A",
  },
  {
    label: "Allview",
    value: "A2",
  },
  {
    label: "Allwinner",
    value: "A7",
  },
  {
    label: "Altech UEC",
    value: "A1",
  },
  {
    label: "Altice",
    value: "66",
  },
  {
    label: "altron",
    value: "A5",
  },
  {
    label: "Amazon",
    value: "KN",
  },
  {
    label: "AMGOO",
    value: "AG",
  },
  {
    label: "Amigoo",
    value: "9A",
  },
  {
    label: "Amoi",
    value: "AO",
  },
  {
    label: "AMCV",
    value: "54",
  },
  {
    label: "Andowl",
    value: "60",
  },
  {
    label: "Anry",
    value: "7A",
  },
  {
    label: "ANS",
    value: "A0",
  },
  {
    label: "Anker",
    value: "74",
  },
  {
    label: "Aoson",
    value: "3N",
  },
  {
    label: "AOC",
    value: "O8",
  },
  {
    label: "AOYODKG",
    value: "J2",
  },
  {
    label: "AOpen",
    value: "55",
  },
  {
    label: "Apple",
    value: "AP",
  },
  {
    label: "Archos",
    value: "AR",
  },
  {
    label: "Arian Space",
    value: "AB",
  },
  {
    label: "Ark",
    value: "A6",
  },
  {
    label: "ArmPhone",
    value: "5A",
  },
  {
    label: "Arnova",
    value: "AN",
  },
  {
    label: "ARRIS",
    value: "AS",
  },
  {
    label: "Aspera",
    value: "AQ",
  },
  {
    label: "Artel",
    value: "40",
  },
  {
    label: "Artizlee",
    value: "21",
  },
  {
    label: "Asano",
    value: "8A",
  },
  {
    label: "Asanzo",
    value: "90",
  },
  {
    label: "Astro",
    value: "1U",
  },
  {
    label: "Ask",
    value: "A4",
  },
  {
    label: "Assistant",
    value: "A8",
  },
  {
    label: "Asus",
    value: "AU",
  },
  {
    label: "AT&T",
    value: "6A",
  },
  {
    label: "Atom",
    value: "2A",
  },
  {
    label: "Atvio",
    value: "Z2",
  },
  {
    label: "Audiovox",
    value: "AX",
  },
  {
    label: "AURIS",
    value: "AJ",
  },
  {
    label: "Avenzo",
    value: "ZA",
  },
  {
    label: "AVH",
    value: "AH",
  },
  {
    label: "Avvio",
    value: "AV",
  },
  {
    label: "Axxion",
    value: "AY",
  },
  {
    label: "Axioo",
    value: "XA",
  },
  {
    label: "Azumi Mobile",
    value: "AM",
  },
  {
    label: "BangOlufsen",
    value: "BO",
  },
  {
    label: "Barnes & Noble",
    value: "BN",
  },
  {
    label: "BBK",
    value: "BB",
  },
  {
    label: "BB Mobile",
    value: "0B",
  },
  {
    label: "BDF",
    value: "B6",
  },
  {
    label: "Becker",
    value: "BE",
  },
  {
    label: "Beeline",
    value: "B5",
  },
  {
    label: "Beelink",
    value: "B0",
  },
  {
    label: "Beetel",
    value: "BL",
  },
  {
    label: "BenQ",
    value: "BQ",
  },
  {
    label: "BenQ-Siemens",
    value: "BS",
  },
  {
    label: "Benzo",
    value: "4Y",
  },
  {
    label: "BS Mobile",
    value: "BY",
  },
  {
    label: "Bezkam",
    value: "BZ",
  },
  {
    label: "Bellphone",
    value: "9B",
  },
  {
    label: "Beyond",
    value: "63",
  },
  {
    label: "BGH",
    value: "BG",
  },
  {
    label: "Bigben",
    value: "6B",
  },
  {
    label: "BIHEE",
    value: "B8",
  },
  {
    label: "Billion",
    value: "1B",
  },
  {
    label: "BilimLand",
    value: "BA",
  },
  {
    label: "BioRugged",
    value: "BH",
  },
  {
    label: "Bird",
    value: "BI",
  },
  {
    label: "Bitel",
    value: "BT",
  },
  {
    label: "Bitmore",
    value: "B7",
  },
  {
    label: "Bkav",
    value: "BK",
  },
  {
    label: "Black Bear",
    value: "5B",
  },
  {
    label: "Black Fox",
    value: "BF",
  },
  {
    label: "Blackview",
    value: "B2",
  },
  {
    label: "Blaupunkt",
    value: "BP",
  },
  {
    label: "Blu",
    value: "BU",
  },
  {
    label: "Bluboo",
    value: "B3",
  },
  {
    label: "Bluedot",
    value: "2B",
  },
  {
    label: "Bluegood",
    value: "BD",
  },
  {
    label: "Bluewave",
    value: "LB",
  },
  {
    label: "Blloc",
    value: "7B",
  },
  {
    label: "Bleck",
    value: "UB",
  },
  {
    label: "Blow",
    value: "Q2",
  },
  {
    label: "Bmobile",
    value: "BM",
  },
  {
    label: "Bobarry",
    value: "B9",
  },
  {
    label: "bogo",
    value: "B4",
  },
  {
    label: "Boway",
    value: "BW",
  },
  {
    label: "bq",
    value: "BX",
  },
  {
    label: "Brandt",
    value: "8B",
  },
  {
    label: "Bravis",
    value: "BV",
  },
  {
    label: "Brondi",
    value: "BR",
  },
  {
    label: "BrightSign",
    value: "BJ",
  },
  {
    label: "Bush",
    value: "B1",
  },
  {
    label: "Bundy",
    value: "4Q",
  },
  {
    label: "CAGI",
    value: "C9",
  },
  {
    label: "Capitel",
    value: "CT",
  },
  {
    label: "CG Mobile",
    value: "G3",
  },
  {
    label: "CGV",
    value: "37",
  },
  {
    label: "Captiva",
    value: "CP",
  },
  {
    label: "Carrefour",
    value: "CF",
  },
  {
    label: "Casio",
    value: "CS",
  },
  {
    label: "Casper",
    value: "R4",
  },
  {
    label: "Cat",
    value: "CA",
  },
  {
    label: "Camfone",
    value: "BC",
  },
  {
    label: "Cavion",
    value: "CJ",
  },
  {
    label: "Canal Digital",
    value: "4D",
  },
  {
    label: "Cell-C",
    value: "02",
  },
  {
    label: "CellAllure",
    value: "34",
  },
  {
    label: "Celcus",
    value: "7C",
  },
  {
    label: "Celkon",
    value: "CE",
  },
  {
    label: "Centric",
    value: "62",
  },
  {
    label: "Changhong",
    value: "C2",
  },
  {
    label: "Cherry Mobile",
    value: "CH",
  },
  {
    label: "China Mobile",
    value: "C3",
  },
  {
    label: "Chico Mobile",
    value: "CI",
  },
  {
    label: "CHIA",
    value: "HG",
  },
  {
    label: "Chuwi",
    value: "1C",
  },
  {
    label: "Clarmin",
    value: "L8",
  },
  {
    label: "Claresta",
    value: "25",
  },
  {
    label: "Cloudfone",
    value: "CD",
  },
  {
    label: "Cloudpad",
    value: "6C",
  },
  {
    label: "Clout",
    value: "C0",
  },
  {
    label: "CnM",
    value: "CN",
  },
  {
    label: "Coby Kyros",
    value: "CY",
  },
  {
    label: "Cobalt",
    value: "XC",
  },
  {
    label: "Comio",
    value: "C6",
  },
  {
    label: "Compal",
    value: "CL",
  },
  {
    label: "Compaq",
    value: "CQ",
  },
  {
    label: "ComTrade Tesla",
    value: "C7",
  },
  {
    label: "Concord",
    value: "C8",
  },
  {
    label: "ConCorde",
    value: "CC",
  },
  {
    label: "Condor",
    value: "C5",
  },
  {
    label: "Conquest",
    value: "4C",
  },
  {
    label: "Contixo",
    value: "3C",
  },
  {
    label: "Connex",
    value: "8C",
  },
  {
    label: "Connectce",
    value: "53",
  },
  {
    label: "Colors",
    value: "9C",
  },
  {
    label: "Coolpad",
    value: "CO",
  },
  {
    label: "CORN",
    value: "4R",
  },
  {
    label: "Cosmote",
    value: "1O",
  },
  {
    label: "Cowon",
    value: "CW",
  },
  {
    label: "Covia",
    value: "75",
  },
  {
    label: "Clementoni",
    value: "33",
  },
  {
    label: "CreNova",
    value: "CR",
  },
  {
    label: "Crescent",
    value: "CX",
  },
  {
    label: "Cricket",
    value: "CK",
  },
  {
    label: "Crius Mea",
    value: "CM",
  },
  {
    label: "Crony",
    value: "0C",
  },
  {
    label: "Crosscall",
    value: "C1",
  },
  {
    label: "Cube",
    value: "CU",
  },
  {
    label: "CUBOT",
    value: "CB",
  },
  {
    label: "CVTE",
    value: "CV",
  },
  {
    label: "Cyrus",
    value: "C4",
  },
  {
    label: "Daewoo",
    value: "D5",
  },
  {
    label: "Danew",
    value: "DA",
  },
  {
    label: "Datang",
    value: "DT",
  },
  {
    label: "Datawind",
    value: "D7",
  },
  {
    label: "Datamini",
    value: "7D",
  },
  {
    label: "Datalogic",
    value: "6D",
  },
  {
    label: "Datsun",
    value: "D1",
  },
  {
    label: "Dbtel",
    value: "DB",
  },
  {
    label: "Dell",
    value: "DL",
  },
  {
    label: "Denver",
    value: "DE",
  },
  {
    label: "Desay",
    value: "DS",
  },
  {
    label: "DeWalt",
    value: "DW",
  },
  {
    label: "DEXP",
    value: "DX",
  },
  {
    label: "Dialog",
    value: "DG",
  },
  {
    label: "Dicam",
    value: "DI",
  },
  {
    label: "Digi",
    value: "D4",
  },
  {
    label: "Digicel",
    value: "D3",
  },
  {
    label: "Digihome",
    value: "DH",
  },
  {
    label: "Digiland",
    value: "DD",
  },
  {
    label: "DIGIFORS",
    value: "Q0",
  },
  {
    label: "Ditecma",
    value: "9D",
  },
  {
    label: "Digma",
    value: "D2",
  },
  {
    label: "Diva",
    value: "1D",
  },
  {
    label: "Divisat",
    value: "D6",
  },
  {
    label: "DIXON",
    value: "X6",
  },
  {
    label: "DMM",
    value: "DM",
  },
  {
    label: "DNS",
    value: "DN",
  },
  {
    label: "DoCoMo",
    value: "DC",
  },
  {
    label: "Doffler",
    value: "DF",
  },
  {
    label: "Dolamee",
    value: "D9",
  },
  {
    label: "Doogee",
    value: "DO",
  },
  {
    label: "Doopro",
    value: "D0",
  },
  {
    label: "Doov",
    value: "DV",
  },
  {
    label: "Dopod",
    value: "DP",
  },
  {
    label: "Doro",
    value: "DR",
  },
  {
    label: "Droxio",
    value: "D8",
  },
  {
    label: "Dragon Touch",
    value: "DJ",
  },
  {
    label: "Dune HD",
    value: "DU",
  },
  {
    label: "E-Boda",
    value: "EB",
  },
  {
    label: "Engel",
    value: "EJ",
  },
  {
    label: "E-Ceros",
    value: "2E",
  },
  {
    label: "E-tel",
    value: "E8",
  },
  {
    label: "Easypix",
    value: "EP",
  },
  {
    label: "Eagle",
    value: "EQ",
  },
  {
    label: "EBEST",
    value: "EA",
  },
  {
    label: "Echo Mobiles",
    value: "E4",
  },
  {
    label: "ECS",
    value: "ES",
  },
  {
    label: "ECON",
    value: "35",
  },
  {
    label: "EE",
    value: "E6",
  },
  {
    label: "EKO",
    value: "EK",
  },
  {
    label: "Einstein",
    value: "EY",
  },
  {
    label: "Eks Mobility",
    value: "EM",
  },
  {
    label: "EKT",
    value: "4K",
  },
  {
    label: "ELARI",
    value: "7E",
  },
  {
    label: "Electroneum",
    value: "03",
  },
  {
    label: "ELECTRONIA",
    value: "Z8",
  },
  {
    label: "Element",
    value: "L0",
  },
  {
    label: "Elenberg",
    value: "EG",
  },
  {
    label: "Elephone",
    value: "EL",
  },
  {
    label: "Elekta",
    value: "JE",
  },
  {
    label: "Eltex",
    value: "4E",
  },
  {
    label: "Energizer",
    value: "ED",
  },
  {
    label: "Energy Sistem",
    value: "E1",
  },
  {
    label: "Enot",
    value: "3E",
  },
  {
    label: "Epik One",
    value: "8E",
  },
  {
    label: "Ergo",
    value: "E7",
  },
  {
    label: "Ericsson",
    value: "EC",
  },
  {
    label: "Erisson",
    value: "05",
  },
  {
    label: "Ericy",
    value: "ER",
  },
  {
    label: "Essential",
    value: "EE",
  },
  {
    label: "Essentielb",
    value: "E2",
  },
  {
    label: "eSTAR",
    value: "6E",
  },
  {
    label: "Eton",
    value: "EN",
  },
  {
    label: "eTouch",
    value: "ET",
  },
  {
    label: "Etuline",
    value: "1E",
  },
  {
    label: "Eurostar",
    value: "EU",
  },
  {
    label: "Evercoss",
    value: "E9",
  },
  {
    label: "Evertek",
    value: "EV",
  },
  {
    label: "Evolio",
    value: "E3",
  },
  {
    label: "Evolveo",
    value: "EO",
  },
  {
    label: "EvroMedia",
    value: "E0",
  },
  {
    label: "ExMobile",
    value: "XE",
  },
  {
    label: "Exmart",
    value: "4Z",
  },
  {
    label: "EXO",
    value: "EH",
  },
  {
    label: "Explay",
    value: "EX",
  },
  {
    label: "Extrem",
    value: "E5",
  },
  {
    label: "EXCEED",
    value: "EF",
  },
  {
    label: "EWIS",
    value: "QE",
  },
  {
    label: "Ezio",
    value: "EI",
  },
  {
    label: "Ezze",
    value: "EZ",
  },
  {
    label: "F150",
    value: "5F",
  },
  {
    label: "Facebook",
    value: "F6",
  },
  {
    label: "Fairphone",
    value: "FA",
  },
  {
    label: "Famoco",
    value: "FM",
  },
  {
    label: "FarEasTone",
    value: "17",
  },
  {
    label: "FaRao Pro",
    value: "9R",
  },
  {
    label: "Fantec",
    value: "FB",
  },
  {
    label: "Fengxiang",
    value: "FE",
  },
  {
    label: "Fero",
    value: "F7",
  },
  {
    label: "FiGO",
    value: "FI",
  },
  {
    label: "FinePower",
    value: "F1",
  },
  {
    label: "Finlux",
    value: "FX",
  },
  {
    label: "FireFly Mobile",
    value: "F3",
  },
  {
    label: "FISE",
    value: "F8",
  },
  {
    label: "Fly",
    value: "FL",
  },
  {
    label: "FLYCAT",
    value: "QC",
  },
  {
    label: "FNB",
    value: "FN",
  },
  {
    label: "Fondi",
    value: "FD",
  },
  {
    label: "Fourel",
    value: "0F",
  },
  {
    label: "Four Mobile",
    value: "44",
  },
  {
    label: "Fonos",
    value: "F0",
  },
  {
    label: "FORME",
    value: "F2",
  },
  {
    label: "Formuler",
    value: "F5",
  },
  {
    label: "Forstar",
    value: "FR",
  },
  {
    label: "Fortis",
    value: "RF",
  },
  {
    label: "Foxconn",
    value: "FO",
  },
  {
    label: "Freetel",
    value: "FT",
  },
  {
    label: "F&U",
    value: "F4",
  },
  {
    label: "FMT",
    value: "1F",
  },
  {
    label: "Fuego",
    value: "FG",
  },
  {
    label: "Fujitsu",
    value: "FU",
  },
  {
    label: "FNF",
    value: "FW",
  },
  {
    label: "G-TiDE",
    value: "GT",
  },
  {
    label: "G-Touch",
    value: "G9",
  },
  {
    label: "GFive",
    value: "0G",
  },
  {
    label: "Garmin-Asus",
    value: "GM",
  },
  {
    label: "Gateway",
    value: "GA",
  },
  {
    label: "Galaxy Innovations",
    value: "99",
  },
  {
    label: "Gemini",
    value: "GD",
  },
  {
    label: "General Mobile",
    value: "GN",
  },
  {
    label: "Genesis",
    value: "2G",
  },
  {
    label: "GEOFOX",
    value: "G2",
  },
  {
    label: "Geotel",
    value: "GE",
  },
  {
    label: "Ghia",
    value: "GH",
  },
  {
    label: "Ghong",
    value: "2C",
  },
  {
    label: "Gigabyte",
    value: "GG",
  },
  {
    label: "Gigaset",
    value: "GS",
  },
  {
    label: "Ginzzu",
    value: "GZ",
  },
  {
    label: "Gini",
    value: "1G",
  },
  {
    label: "Gionee",
    value: "GI",
  },
  {
    label: "Globex",
    value: "G4",
  },
  {
    label: "GoGEN",
    value: "G7",
  },
  {
    label: "GOCLEVER",
    value: "GC",
  },
  {
    label: "Gol Mobile",
    value: "GB",
  },
  {
    label: "Goly",
    value: "GL",
  },
  {
    label: "GLX",
    value: "GX",
  },
  {
    label: "Gome",
    value: "G5",
  },
  {
    label: "GoMobile",
    value: "G1",
  },
  {
    label: "Google",
    value: "GO",
  },
  {
    label: "Goophone",
    value: "G0",
  },
  {
    label: "Gooweel",
    value: "6G",
  },
  {
    label: "Gradiente",
    value: "GR",
  },
  {
    label: "Grape",
    value: "GP",
  },
  {
    label: "Gree",
    value: "G6",
  },
  {
    label: "Greentel",
    value: "3G",
  },
  {
    label: "Gretel",
    value: "GF",
  },
  {
    label: "Gresso",
    value: "82",
  },
  {
    label: "Grundig",
    value: "GU",
  },
  {
    label: "Hafury",
    value: "HF",
  },
  {
    label: "Haier",
    value: "HA",
  },
  {
    label: "HannSpree",
    value: "HE",
  },
  {
    label: "Hardkernel",
    value: "HK",
  },
  {
    label: "Hasee",
    value: "HS",
  },
  {
    label: "Helio",
    value: "H6",
  },
  {
    label: "Hezire",
    value: "ZH",
  },
  {
    label: "Hi-Level",
    value: "HL",
  },
  {
    label: "Hi",
    value: "3H",
  },
  {
    label: "Highscreen",
    value: "H2",
  },
  {
    label: "High Q",
    value: "Q1",
  },
  {
    label: "Hipstreet",
    value: "1H",
  },
  {
    label: "Hisense",
    value: "HI",
  },
  {
    label: "Hitachi",
    value: "HC",
  },
  {
    label: "Hitech",
    value: "H8",
  },
  {
    label: "Hoffmann",
    value: "H1",
  },
  {
    label: "Hometech",
    value: "H0",
  },
  {
    label: "Homtom",
    value: "HM",
  },
  {
    label: "Hoozo",
    value: "HZ",
  },
  {
    label: "Horizon",
    value: "H7",
  },
  {
    label: "Hosin",
    value: "HO",
  },
  {
    label: "Hotel",
    value: "H3",
  },
  {
    label: "Hotwav",
    value: "HV",
  },
  {
    label: "How",
    value: "HW",
  },
  {
    label: "Honeywell",
    value: "WH",
  },
  {
    label: "HP",
    value: "HP",
  },
  {
    label: "HTC",
    value: "HT",
  },
  {
    label: "Huadoo",
    value: "HD",
  },
  {
    label: "Huawei",
    value: "HU",
  },
  {
    label: "Humax",
    value: "HX",
  },
  {
    label: "Hurricane",
    value: "HR",
  },
  {
    label: "Huskee",
    value: "H5",
  },
  {
    label: "Hyrican",
    value: "HY",
  },
  {
    label: "Hyundai",
    value: "HN",
  },
  {
    label: "Hyve",
    value: "7H",
  },
  {
    label: "i-Cherry",
    value: "3I",
  },
  {
    label: "i-Joy",
    value: "IJ",
  },
  {
    label: "i-mate",
    value: "IM",
  },
  {
    label: "i-mobile",
    value: "IO",
  },
  {
    label: "iOutdoor",
    value: "OF",
  },
  {
    label: "iBall",
    value: "IB",
  },
  {
    label: "iBerry",
    value: "IY",
  },
  {
    label: "iBrit",
    value: "7I",
  },
  {
    label: "IconBIT",
    value: "I2",
  },
  {
    label: "iDroid",
    value: "IC",
  },
  {
    label: "iGet",
    value: "IG",
  },
  {
    label: "iHunt",
    value: "IH",
  },
  {
    label: "Ikea",
    value: "IA",
  },
  {
    label: "IKU Mobile",
    value: "8I",
  },
  {
    label: "IKI Mobile",
    value: "2K",
  },
  {
    label: "iKoMo",
    value: "IK",
  },
  {
    label: "iLA",
    value: "I7",
  },
  {
    label: "iLife",
    value: "2I",
  },
  {
    label: "iMars",
    value: "1I",
  },
  {
    label: "iMan",
    value: "U4",
  },
  {
    label: "IMO Mobile",
    value: "IL",
  },
  {
    label: "Impression",
    value: "I3",
  },
  {
    label: "INCAR",
    value: "FC",
  },
  {
    label: "Inch",
    value: "2H",
  },
  {
    label: "Inco",
    value: "6I",
  },
  {
    label: "iNew",
    value: "IW",
  },
  {
    label: "Infinix",
    value: "IF",
  },
  {
    label: "InFocus",
    value: "I0",
  },
  {
    label: "Inkti",
    value: "II",
  },
  {
    label: "InfoKit",
    value: "81",
  },
  {
    label: "InnJoo",
    value: "I5",
  },
  {
    label: "Innos",
    value: "26",
  },
  {
    label: "Innostream",
    value: "IN",
  },
  {
    label: "Inoi",
    value: "I4",
  },
  {
    label: "INQ",
    value: "IQ",
  },
  {
    label: "iQ&T",
    value: "QN",
  },
  {
    label: "Insignia",
    value: "IS",
  },
  {
    label: "Intek",
    value: "IT",
  },
  {
    label: "Intex",
    value: "IX",
  },
  {
    label: "Inverto",
    value: "IV",
  },
  {
    label: "Invens",
    value: "32",
  },
  {
    label: "Invin",
    value: "4I",
  },
  {
    label: "iOcean",
    value: "I1",
  },
  {
    label: "iPro",
    value: "IP",
  },
  {
    label: "IQM",
    value: "8Q",
  },
  {
    label: "Irbis",
    value: "I6",
  },
  {
    label: "Iris",
    value: "5I",
  },
  {
    label: "iRola",
    value: "IR",
  },
  {
    label: "iRulu",
    value: "IU",
  },
  {
    label: "iSWAG",
    value: "9I",
  },
  {
    label: "IT",
    value: "86",
  },
  {
    label: "iTel",
    value: "IZ",
  },
  {
    label: "iTruck",
    value: "0I",
  },
  {
    label: "iVA",
    value: "I8",
  },
  {
    label: "iView",
    value: "IE",
  },
  {
    label: "iVooMi",
    value: "0J",
  },
  {
    label: "ivvi",
    value: "UI",
  },
  {
    label: "iZotron",
    value: "I9",
  },
  {
    label: "JAY-Tech",
    value: "JA",
  },
  {
    label: "Jiake",
    value: "KJ",
  },
  {
    label: "Jeka",
    value: "J6",
  },
  {
    label: "JFone",
    value: "JF",
  },
  {
    label: "Jiayu",
    value: "JI",
  },
  {
    label: "Jinga",
    value: "JG",
  },
  {
    label: "Jivi",
    value: "VJ",
  },
  {
    label: "JKL",
    value: "JK",
  },
  {
    label: "Jolla",
    value: "JO",
  },
  {
    label: "Just5",
    value: "J5",
  },
  {
    label: "JVC",
    value: "JV",
  },
  {
    label: "Jesy",
    value: "JS",
  },
  {
    label: "K-Touch",
    value: "KT",
  },
  {
    label: "Kaan",
    value: "K4",
  },
  {
    label: "Kaiomy",
    value: "K7",
  },
  {
    label: "Kalley",
    value: "KL",
  },
  {
    label: "Kanji",
    value: "K6",
  },
  {
    label: "Karbonn",
    value: "KA",
  },
  {
    label: "KATV1",
    value: "K5",
  },
  {
    label: "Kata",
    value: "K0",
  },
  {
    label: "Kazam",
    value: "KZ",
  },
  {
    label: "KDDI",
    value: "KD",
  },
  {
    label: "Kempler & Strauss",
    value: "KS",
  },
  {
    label: "Keneksi",
    value: "K3",
  },
  {
    label: "Kenxinda",
    value: "KX",
  },
  {
    label: "Kiano",
    value: "K1",
  },
  {
    label: "Kingsun",
    value: "KI",
  },
  {
    label: "KINGZONE",
    value: "KF",
  },
  {
    label: "Kiowa",
    value: "46",
  },
  {
    label: "Kivi",
    value: "KV",
  },
  {
    label: "Kvant",
    value: "64",
  },
  {
    label: "Klipad",
    value: "0K",
  },
  {
    label: "Kocaso",
    value: "KC",
  },
  {
    label: "Kodak",
    value: "KK",
  },
  {
    label: "Kogan",
    value: "KG",
  },
  {
    label: "Komu",
    value: "KM",
  },
  {
    label: "Konka",
    value: "KO",
  },
  {
    label: "Konrow",
    value: "KW",
  },
  {
    label: "Koobee",
    value: "KB",
  },
  {
    label: "Koolnee",
    value: "7K",
  },
  {
    label: "Kooper",
    value: "K9",
  },
  {
    label: "KOPO",
    value: "KP",
  },
  {
    label: "Koridy",
    value: "KR",
  },
  {
    label: "KRONO",
    value: "K2",
  },
  {
    label: "Krüger&Matz",
    value: "KE",
  },
  {
    label: "KREZ",
    value: "5K",
  },
  {
    label: "KT-Tech",
    value: "KH",
  },
  {
    label: "KUBO",
    value: "Z6",
  },
  {
    label: "Kuliao",
    value: "K8",
  },
  {
    label: "Kult",
    value: "8K",
  },
  {
    label: "Kumai",
    value: "KU",
  },
  {
    label: "Kurio",
    value: "6K",
  },
  {
    label: "Kyocera",
    value: "KY",
  },
  {
    label: "Kyowon",
    value: "KQ",
  },
  {
    label: "Kzen",
    value: "1K",
  },
  {
    label: "LAIQ",
    value: "LQ",
  },
  {
    label: "Land Rover",
    value: "L6",
  },
  {
    label: "Landvo",
    value: "L2",
  },
  {
    label: "Lanix",
    value: "LA",
  },
  {
    label: "Lark",
    value: "LK",
  },
  {
    label: "Laurus",
    value: "Z3",
  },
  {
    label: "Lava",
    value: "LV",
  },
  {
    label: "LCT",
    value: "LC",
  },
  {
    label: "Leagoo",
    value: "L5",
  },
  {
    label: "Leben",
    value: "U3",
  },
  {
    label: "Ledstar",
    value: "LD",
  },
  {
    label: "LeEco",
    value: "L1",
  },
  {
    label: "Leff",
    value: "4B",
  },
  {
    label: "Lemhoov",
    value: "L4",
  },
  {
    label: "Lenco",
    value: "LN",
  },
  {
    label: "Lenovo",
    value: "LE",
  },
  {
    label: "Leotec",
    value: "LT",
  },
  {
    label: "Le Pan",
    value: "LP",
  },
  {
    label: "Lephone",
    value: "L7",
  },
  {
    label: "Lesia",
    value: "LZ",
  },
  {
    label: "Lexand",
    value: "L3",
  },
  {
    label: "Lexibook",
    value: "LX",
  },
  {
    label: "LG",
    value: "LG",
  },
  {
    label: "Lifemaxx",
    value: "LF",
  },
  {
    label: "L-Max",
    value: "LJ",
  },
  {
    label: "Lingwin",
    value: "LI",
  },
  {
    label: "Linsar",
    value: "5L",
  },
  {
    label: "Linnex",
    value: "LW",
  },
  {
    label: "Loewe",
    value: "LO",
  },
  {
    label: "Loview",
    value: "YL",
  },
  {
    label: "Logic",
    value: "1L",
  },
  {
    label: "Logicom",
    value: "LM",
  },
  {
    label: "Lumigon",
    value: "0L",
  },
  {
    label: "Lumus",
    value: "LU",
  },
  {
    label: "Luna",
    value: "L9",
  },
  {
    label: "Luxor",
    value: "LR",
  },
  {
    label: "LYF",
    value: "LY",
  },
  {
    label: "Leader Phone",
    value: "LL",
  },
  {
    label: "LT Mobile",
    value: "QL",
  },
  {
    label: "M.T.T.",
    value: "MQ",
  },
  {
    label: "M4tel",
    value: "MN",
  },
  {
    label: "Macoox",
    value: "XM",
  },
  {
    label: "MAC AUDIO",
    value: "92",
  },
  {
    label: "Majestic",
    value: "MJ",
  },
  {
    label: "Magnus",
    value: "23",
  },
  {
    label: "Manhattan",
    value: "NH",
  },
  {
    label: "Mann",
    value: "5M",
  },
  {
    label: "Manta Multimedia",
    value: "MA",
  },
  {
    label: "Mantra",
    value: "Z0",
  },
  {
    label: "Masstel",
    value: "2M",
  },
  {
    label: "Matrix",
    value: "50",
  },
  {
    label: "Maxcom",
    value: "7M",
  },
  {
    label: "Maximus",
    value: "ZM",
  },
  {
    label: "Maxtron",
    value: "6X",
  },
  {
    label: "MAXVI",
    value: "0D",
  },
  {
    label: "Maxwest",
    value: "MW",
  },
  {
    label: "Maze",
    value: "M0",
  },
  {
    label: "Maze Speed",
    value: "YM",
  },
  {
    label: "Malata",
    value: "87",
  },
  {
    label: "MDC Store",
    value: "3D",
  },
  {
    label: "meanIT",
    value: "09",
  },
  {
    label: "Mecer",
    value: "M3",
  },
  {
    label: "Mecool",
    value: "0M",
  },
  {
    label: "Mediacom",
    value: "MC",
  },
  {
    label: "MediaTek",
    value: "MK",
  },
  {
    label: "Medion",
    value: "MD",
  },
  {
    label: "MEEG",
    value: "M2",
  },
  {
    label: "MegaFon",
    value: "MP",
  },
  {
    label: "mPhone",
    value: "X0",
  },
  {
    label: "Meitu",
    value: "3M",
  },
  {
    label: "Meizu",
    value: "M1",
  },
  {
    label: "Melrose",
    value: "0E",
  },
  {
    label: "Memup",
    value: "MU",
  },
  {
    label: "Metz",
    value: "ME",
  },
  {
    label: "MEU",
    value: "MX",
  },
  {
    label: "MicroMax",
    value: "MI",
  },
  {
    label: "Microsoft",
    value: "MS",
  },
  {
    label: "Minix",
    value: "1X",
  },
  {
    label: "Mintt",
    value: "OM",
  },
  {
    label: "Mio",
    value: "MO",
  },
  {
    label: "Miray",
    value: "M7",
  },
  {
    label: "Mito",
    value: "8M",
  },
  {
    label: "Mitsubishi",
    value: "MT",
  },
  {
    label: "MIXC",
    value: "M5",
  },
  {
    label: "MIVO",
    value: "2D",
  },
  {
    label: "MiXzo",
    value: "1Z",
  },
  {
    label: "MLLED",
    value: "ML",
  },
  {
    label: "MLS",
    value: "LS",
  },
  {
    label: "Mobicel",
    value: "4M",
  },
  {
    label: "Mobiistar",
    value: "M6",
  },
  {
    label: "Mobiola",
    value: "MH",
  },
  {
    label: "Mobistel",
    value: "MB",
  },
  {
    label: "MobiWire",
    value: "6W",
  },
  {
    label: "Mobo",
    value: "9M",
  },
  {
    label: "Modecom",
    value: "M4",
  },
  {
    label: "Mofut",
    value: "MF",
  },
  {
    label: "Motorola",
    value: "MR",
  },
  {
    label: "Movic",
    value: "MV",
  },
  {
    label: "Mpman",
    value: "MM",
  },
  {
    label: "MSI",
    value: "MZ",
  },
  {
    label: "MStar",
    value: "3R",
  },
  {
    label: "MTC",
    value: "M9",
  },
  {
    label: "MTN",
    value: "N4",
  },
  {
    label: "M-Tech",
    value: "72",
  },
  {
    label: "M-Horse",
    value: "9H",
  },
  {
    label: "Multilaser",
    value: "1R",
  },
  {
    label: "MYFON",
    value: "1M",
  },
  {
    label: "MyPhone",
    value: "MY",
  },
  {
    label: "Myros",
    value: "51",
  },
  {
    label: "Myria",
    value: "M8",
  },
  {
    label: "Mystery",
    value: "6M",
  },
  {
    label: "MyTab",
    value: "3T",
  },
  {
    label: "MyWigo",
    value: "MG",
  },
  {
    label: "Nabi",
    value: "08",
  },
  {
    label: "National",
    value: "N7",
  },
  {
    label: "Navcity",
    value: "NC",
  },
  {
    label: "Navitech",
    value: "6N",
  },
  {
    label: "Navitel",
    value: "7V",
  },
  {
    label: "Navon",
    value: "N3",
  },
  {
    label: "Naomi Phone",
    value: "NP",
  },
  {
    label: "NEC",
    value: "NE",
  },
  {
    label: "Necnot",
    value: "8N",
  },
  {
    label: "Neffos",
    value: "NF",
  },
  {
    label: "Neomi",
    value: "1N",
  },
  {
    label: "Netgear",
    value: "NA",
  },
  {
    label: "NeuImage",
    value: "NU",
  },
  {
    label: "Newgen",
    value: "NW",
  },
  {
    label: "Newland",
    value: "N9",
  },
  {
    label: "Newman",
    value: "0N",
  },
  {
    label: "NewsMy",
    value: "NS",
  },
  {
    label: "Newsday",
    value: "ND",
  },
  {
    label: "New Balance",
    value: "HB",
  },
  {
    label: "NEXBOX",
    value: "XB",
  },
  {
    label: "Nexian",
    value: "NX",
  },
  {
    label: "NEXON",
    value: "N8",
  },
  {
    label: "Nextbit",
    value: "N2",
  },
  {
    label: "NextBook",
    value: "NT",
  },
  {
    label: "NextTab",
    value: "4N",
  },
  {
    label: "NGM",
    value: "NG",
  },
  {
    label: "NG Optics",
    value: "NZ",
  },
  {
    label: "Nikon",
    value: "NN",
  },
  {
    label: "Nintendo",
    value: "NI",
  },
  {
    label: "NOA",
    value: "N5",
  },
  {
    label: "Noain",
    value: "N1",
  },
  {
    label: "Nobby",
    value: "N6",
  },
  {
    label: "NOBUX",
    value: "JN",
  },
  {
    label: "Noblex",
    value: "NB",
  },
  {
    label: "Nokia",
    value: "NK",
  },
  {
    label: "Nomi",
    value: "NM",
  },
  {
    label: "Nomu",
    value: "2N",
  },
  {
    label: "Nordmende",
    value: "NR",
  },
  {
    label: "NorthTech",
    value: "7N",
  },
  {
    label: "Nos",
    value: "5N",
  },
  {
    label: "Nous",
    value: "NO",
  },
  {
    label: "Novex",
    value: "NQ",
  },
  {
    label: "NuAns",
    value: "NJ",
  },
  {
    label: "NUU Mobile",
    value: "NL",
  },
  {
    label: "Nuvo",
    value: "N0",
  },
  {
    label: "Nvidia",
    value: "NV",
  },
  {
    label: "NYX Mobile",
    value: "NY",
  },
  {
    label: "O+",
    value: "O3",
  },
  {
    label: "O2",
    value: "OT",
  },
  {
    label: "Oale",
    value: "O7",
  },
  {
    label: "OASYS",
    value: "OC",
  },
  {
    label: "Obi",
    value: "OB",
  },
  {
    label: "Odys",
    value: "O1",
  },
  {
    label: "Ok",
    value: "O9",
  },
  {
    label: "Okapia",
    value: "OA",
  },
  {
    label: "Onda",
    value: "OD",
  },
  {
    label: "OnePlus",
    value: "ON",
  },
  {
    label: "Onix",
    value: "OX",
  },
  {
    label: "ONYX BOOX",
    value: "3O",
  },
  {
    label: "ONN",
    value: "O4",
  },
  {
    label: "OpelMobile",
    value: "2O",
  },
  {
    label: "Openbox",
    value: "OH",
  },
  {
    label: "OPPO",
    value: "OP",
  },
  {
    label: "Opsson",
    value: "OO",
  },
  {
    label: "Orange",
    value: "OR",
  },
  {
    label: "Orbic",
    value: "O5",
  },
  {
    label: "Ordissimo",
    value: "OS",
  },
  {
    label: "Ouki",
    value: "OK",
  },
  {
    label: "OINOM",
    value: "0O",
  },
  {
    label: "OKWU",
    value: "QK",
  },
  {
    label: "Oukitel",
    value: "OE",
  },
  {
    label: "OUYA",
    value: "OU",
  },
  {
    label: "Overmax",
    value: "OV",
  },
  {
    label: "Ovvi",
    value: "30",
  },
  {
    label: "Owwo",
    value: "O2",
  },
  {
    label: "Oysters",
    value: "OY",
  },
  {
    label: "Oyyu",
    value: "O6",
  },
  {
    label: "OzoneHD",
    value: "OZ",
  },
  {
    label: "P-UP",
    value: "7P",
  },
  {
    label: "Palm",
    value: "PM",
  },
  {
    label: "Panacom",
    value: "PN",
  },
  {
    label: "Panasonic",
    value: "PA",
  },
  {
    label: "Pantech",
    value: "PT",
  },
  {
    label: "PCBOX",
    value: "PB",
  },
  {
    label: "PCD",
    value: "PC",
  },
  {
    label: "PCD Argentina",
    value: "PD",
  },
  {
    label: "PEAQ",
    value: "PE",
  },
  {
    label: "Pentagram",
    value: "PG",
  },
  {
    label: "Pendoo",
    value: "PQ",
  },
  {
    label: "Perfeo",
    value: "93",
  },
  {
    label: "Phicomm",
    value: "1P",
  },
  {
    label: "Philco",
    value: "4P",
  },
  {
    label: "Philips",
    value: "PH",
  },
  {
    label: "Phonemax",
    value: "5P",
  },
  {
    label: "phoneOne",
    value: "PO",
  },
  {
    label: "Pioneer",
    value: "PI",
  },
  {
    label: "PiPO",
    value: "PJ",
  },
  {
    label: "Pixelphone",
    value: "8P",
  },
  {
    label: "Pixela",
    value: "9O",
  },
  {
    label: "Pixus",
    value: "PX",
  },
  {
    label: "Pico",
    value: "QP",
  },
  {
    label: "Planet Computers",
    value: "9P",
  },
  {
    label: "Ployer",
    value: "PY",
  },
  {
    label: "Plum",
    value: "P4",
  },
  {
    label: "Pluzz",
    value: "22",
  },
  {
    label: "PocketBook",
    value: "P8",
  },
  {
    label: "POCO",
    value: "0P",
  },
  {
    label: "Point of View",
    value: "PV",
  },
  {
    label: "Polaroid",
    value: "PL",
  },
  {
    label: "PolyPad",
    value: "PP",
  },
  {
    label: "Polytron",
    value: "P5",
  },
  {
    label: "Pomp",
    value: "P2",
  },
  {
    label: "Poppox",
    value: "P0",
  },
  {
    label: "Positivo",
    value: "PS",
  },
  {
    label: "Positivo BGH",
    value: "3P",
  },
  {
    label: "PPTV",
    value: "P3",
  },
  {
    label: "Premio",
    value: "FP",
  },
  {
    label: "Prestigio",
    value: "PR",
  },
  {
    label: "Primepad",
    value: "P9",
  },
  {
    label: "Primux",
    value: "6P",
  },
  {
    label: "Prixton",
    value: "2P",
  },
  {
    label: "PROFiLO",
    value: "PF",
  },
  {
    label: "Proline",
    value: "P6",
  },
  {
    label: "ProScan",
    value: "P1",
  },
  {
    label: "Protruly",
    value: "P7",
  },
  {
    label: "ProVision",
    value: "R0",
  },
  {
    label: "PULID",
    value: "PU",
  },
  {
    label: "Q-Touch",
    value: "QH",
  },
  {
    label: "Q.Bell",
    value: "QB",
  },
  {
    label: "Qilive",
    value: "QI",
  },
  {
    label: "QMobile",
    value: "QM",
  },
  {
    label: "Qtek",
    value: "QT",
  },
  {
    label: "Quantum",
    value: "QA",
  },
  {
    label: "Quechua",
    value: "QU",
  },
  {
    label: "Qumo",
    value: "QO",
  },
  {
    label: "Qubo",
    value: "UQ",
  },
  {
    label: "R-TV",
    value: "R2",
  },
  {
    label: "Ramos",
    value: "RA",
  },
  {
    label: "Raspberry",
    value: "0R",
  },
  {
    label: "Ravoz",
    value: "R9",
  },
  {
    label: "Razer",
    value: "RZ",
  },
  {
    label: "RCA Tablets",
    value: "RC",
  },
  {
    label: "Reach",
    value: "2R",
  },
  {
    label: "Readboy",
    value: "RB",
  },
  {
    label: "Realme",
    value: "RE",
  },
  {
    label: "RED",
    value: "R8",
  },
  {
    label: "Reeder",
    value: "RD",
  },
  {
    label: "REGAL",
    value: "Z9",
  },
  {
    label: "Revo",
    value: "RP",
  },
  {
    label: "Rikomagic",
    value: "RI",
  },
  {
    label: "RIM",
    value: "RM",
  },
  {
    label: "Rinno",
    value: "RN",
  },
  {
    label: "Ritmix",
    value: "RX",
  },
  {
    label: "Ritzviva",
    value: "R7",
  },
  {
    label: "Riviera",
    value: "RV",
  },
  {
    label: "Rivo",
    value: "6R",
  },
  {
    label: "Roadrover",
    value: "RR",
  },
  {
    label: "Rokit",
    value: "R1",
  },
  {
    label: "Roku",
    value: "RK",
  },
  {
    label: "Rombica",
    value: "R3",
  },
  {
    label: "Ross&Moor",
    value: "R5",
  },
  {
    label: "Rover",
    value: "RO",
  },
  {
    label: "RoverPad",
    value: "R6",
  },
  {
    label: "RoyQueen",
    value: "RQ",
  },
  {
    label: "RT Project",
    value: "RT",
  },
  {
    label: "RugGear",
    value: "RG",
  },
  {
    label: "Runbo",
    value: "RU",
  },
  {
    label: "Ruio",
    value: "RL",
  },
  {
    label: "Ryte",
    value: "RY",
  },
  {
    label: "Saba",
    value: "X5",
  },
  {
    label: "S-TELL",
    value: "8L",
  },
  {
    label: "Seatel",
    value: "89",
  },
  {
    label: "Safaricom",
    value: "X1",
  },
  {
    label: "Sagem",
    value: "SG",
  },
  {
    label: "Salora",
    value: "4L",
  },
  {
    label: "Samsung",
    value: "SA",
  },
  {
    label: "Sanei",
    value: "S0",
  },
  {
    label: "Sansui",
    value: "12",
  },
  {
    label: "Santin",
    value: "SQ",
  },
  {
    label: "Sanyo",
    value: "SY",
  },
  {
    label: "Savio",
    value: "S9",
  },
  {
    label: "SCBC",
    value: "Y4",
  },
  {
    label: "Schneider",
    value: "CZ",
  },
  {
    label: "SEG",
    value: "G8",
  },
  {
    label: "Sega",
    value: "SD",
  },
  {
    label: "Selenga",
    value: "9G",
  },
  {
    label: "Selevision",
    value: "SV",
  },
  {
    label: "Selfix",
    value: "SL",
  },
  {
    label: "SEMP TCL",
    value: "0S",
  },
  {
    label: "Sencor",
    value: "S1",
  },
  {
    label: "Sendo",
    value: "SN",
  },
  {
    label: "Senkatel",
    value: "01",
  },
  {
    label: "Senseit",
    value: "S6",
  },
  {
    label: "Senwa",
    value: "EW",
  },
  {
    label: "Seeken",
    value: "24",
  },
  {
    label: "Seuic",
    value: "61",
  },
  {
    label: "SFR",
    value: "SX",
  },
  {
    label: "Sharp",
    value: "SH",
  },
  {
    label: "Shift Phones",
    value: "7S",
  },
  {
    label: "Shtrikh-M",
    value: "RS",
  },
  {
    label: "Shuttle",
    value: "3S",
  },
  {
    label: "Sico",
    value: "13",
  },
  {
    label: "Siemens",
    value: "SI",
  },
  {
    label: "Sigma",
    value: "1S",
  },
  {
    label: "Silelis",
    value: "70",
  },
  {
    label: "Silent Circle",
    value: "SJ",
  },
  {
    label: "Simbans",
    value: "10",
  },
  {
    label: "Simply",
    value: "98",
  },
  {
    label: "Singtech",
    value: "52",
  },
  {
    label: "Siragon",
    value: "31",
  },
  {
    label: "Sirin labs",
    value: "83",
  },
  {
    label: "SKG",
    value: "GK",
  },
  {
    label: "Sky",
    value: "SW",
  },
  {
    label: "Skyworth",
    value: "SK",
  },
  {
    label: "Smadl",
    value: "14",
  },
  {
    label: "Smailo",
    value: "19",
  },
  {
    label: "Smart Electronic",
    value: "SR",
  },
  {
    label: "Smart",
    value: "49",
  },
  {
    label: "SmartBook",
    value: "47",
  },
  {
    label: "Smartab",
    value: "3B",
  },
  {
    label: "SMARTEC",
    value: "80",
  },
  {
    label: "Smartfren",
    value: "SC",
  },
  {
    label: "Smartisan",
    value: "S7",
  },
  {
    label: "Smotreshka",
    value: "1Q",
  },
  {
    label: "Softbank",
    value: "SF",
  },
  {
    label: "SOLE",
    value: "9L",
  },
  {
    label: "SOLO",
    value: "JL",
  },
  {
    label: "Solone",
    value: "16",
  },
  {
    label: "Sonim",
    value: "OI",
  },
  {
    label: "Sony",
    value: "SO",
  },
  {
    label: "Sony Ericsson",
    value: "SE",
  },
  {
    label: "Soundmax",
    value: "X2",
  },
  {
    label: "Soyes",
    value: "8S",
  },
  {
    label: "SONOS",
    value: "77",
  },
  {
    label: "Spark",
    value: "PK",
  },
  {
    label: "SPC",
    value: "FS",
  },
  {
    label: "Spectrum",
    value: "6S",
  },
  {
    label: "Spectralink",
    value: "43",
  },
  {
    label: "Spice",
    value: "SP",
  },
  {
    label: "Sprint",
    value: "84",
  },
  {
    label: "SQOOL",
    value: "QS",
  },
  {
    label: "Star",
    value: "S4",
  },
  {
    label: "Starlight",
    value: "OL",
  },
  {
    label: "Starmobile",
    value: "18",
  },
  {
    label: "Starway",
    value: "2S",
  },
  {
    label: "Starwind",
    value: "45",
  },
  {
    label: "STF Mobile",
    value: "SB",
  },
  {
    label: "STK",
    value: "S8",
  },
  {
    label: "STG Telecom",
    value: "GQ",
  },
  {
    label: "Stonex",
    value: "S2",
  },
  {
    label: "Storex",
    value: "ST",
  },
  {
    label: "StrawBerry",
    value: "71",
  },
  {
    label: "Stylo",
    value: "69",
  },
  {
    label: "Sugar",
    value: "9S",
  },
  {
    label: "Subor",
    value: "06",
  },
  {
    label: "Sumvision",
    value: "SZ",
  },
  {
    label: "Sunstech",
    value: "0H",
  },
  {
    label: "SunVan",
    value: "S3",
  },
  {
    label: "Sunvell",
    value: "5S",
  },
  {
    label: "Sunny",
    value: "5Y",
  },
  {
    label: "SuperSonic",
    value: "SU",
  },
  {
    label: "SuperTab",
    value: "79",
  },
  {
    label: "Supra",
    value: "S5",
  },
  {
    label: "Suzuki",
    value: "ZS",
  },
  {
    label: "Swipe",
    value: "0W",
  },
  {
    label: "SWISSMOBILITY",
    value: "SS",
  },
  {
    label: "Swisstone",
    value: "1W",
  },
  {
    label: "SWTV",
    value: "W7",
  },
  {
    label: "Symphony",
    value: "SM",
  },
  {
    label: "Syrox",
    value: "4S",
  },
  {
    label: "T-Mobile",
    value: "TM",
  },
  {
    label: "Takara",
    value: "TK",
  },
  {
    label: "Tambo",
    value: "73",
  },
  {
    label: "Tanix",
    value: "9N",
  },
  {
    label: "TB Touch",
    value: "T5",
  },
  {
    label: "TCL",
    value: "TC",
  },
  {
    label: "TD Systems",
    value: "T0",
  },
  {
    label: "Technicolor",
    value: "H4",
  },
  {
    label: "Technika",
    value: "Z5",
  },
  {
    label: "TechniSat",
    value: "TX",
  },
  {
    label: "TechnoTrend",
    value: "TT",
  },
  {
    label: "TechPad",
    value: "TP",
  },
  {
    label: "Techwood",
    value: "9E",
  },
  {
    label: "Teclast",
    value: "T7",
  },
  {
    label: "Tecno Mobile",
    value: "TB",
  },
  {
    label: "TEENO",
    value: "91",
  },
  {
    label: "Tele2",
    value: "2L",
  },
  {
    label: "Telefunken",
    value: "TL",
  },
  {
    label: "Telego",
    value: "TG",
  },
  {
    label: "Telenor",
    value: "T2",
  },
  {
    label: "Telit",
    value: "TE",
  },
  {
    label: "Telia",
    value: "65",
  },
  {
    label: "Tesco",
    value: "TD",
  },
  {
    label: "Tesla",
    value: "TA",
  },
  {
    label: "Tetratab",
    value: "9T",
  },
  {
    label: "teXet",
    value: "TZ",
  },
  {
    label: "Teknosa",
    value: "29",
  },
  {
    label: "ThL",
    value: "T4",
  },
  {
    label: "Thomson",
    value: "TN",
  },
  {
    label: "Thuraya",
    value: "O0",
  },
  {
    label: "TIANYU",
    value: "TI",
  },
  {
    label: "Time2",
    value: "8T",
  },
  {
    label: "Timovi",
    value: "TQ",
  },
  {
    label: "Tinai",
    value: "2T",
  },
  {
    label: "Tinmo",
    value: "TF",
  },
  {
    label: "TiPhone",
    value: "TH",
  },
  {
    label: "TOKYO",
    value: "Y3",
  },
  {
    label: "Tolino",
    value: "T1",
  },
  {
    label: "Tone",
    value: "0T",
  },
  {
    label: "Tooky",
    value: "TY",
  },
  {
    label: "Top House",
    value: "T9",
  },
  {
    label: "Topway",
    value: "42",
  },
  {
    label: "Toplux",
    value: "TO",
  },
  {
    label: "Torex",
    value: "7T",
  },
  {
    label: "Toshiba",
    value: "TS",
  },
  {
    label: "Touchmate",
    value: "T8",
  },
  {
    label: "Transpeed",
    value: "5R",
  },
  {
    label: "TrekStor",
    value: "T6",
  },
  {
    label: "Trevi",
    value: "T3",
  },
  {
    label: "Trifone",
    value: "TJ",
  },
  {
    label: "Tronsmart",
    value: "4T",
  },
  {
    label: "True",
    value: "11",
  },
  {
    label: "True Slim",
    value: "JT",
  },
  {
    label: "Trio",
    value: "J1",
  },
  {
    label: "TTEC",
    value: "5C",
  },
  {
    label: "Tunisie Telecom",
    value: "TU",
  },
  {
    label: "Turbo",
    value: "1T",
  },
  {
    label: "Turbo-X",
    value: "TR",
  },
  {
    label: "TurboPad",
    value: "5X",
  },
  {
    label: "TurboKids",
    value: "5T",
  },
  {
    label: "TVC",
    value: "TV",
  },
  {
    label: "TWM",
    value: "TW",
  },
  {
    label: "TWZ",
    value: "Z1",
  },
  {
    label: "Twoe",
    value: "6T",
  },
  {
    label: "Tymes",
    value: "15",
  },
  {
    label: "U.S. Cellular",
    value: "UC",
  },
  {
    label: "Ugoos",
    value: "UG",
  },
  {
    label: "Uhans",
    value: "U1",
  },
  {
    label: "Uhappy",
    value: "UH",
  },
  {
    label: "Ulefone",
    value: "UL",
  },
  {
    label: "Umax",
    value: "UA",
  },
  {
    label: "UMIDIGI",
    value: "UM",
  },
  {
    label: "Unihertz",
    value: "UZ",
  },
  {
    label: "UZ Mobile",
    value: "3Z",
  },
  {
    label: "Unimax",
    value: "UX",
  },
  {
    label: "Uniscope",
    value: "US",
  },
  {
    label: "UNIWA",
    value: "U2",
  },
  {
    label: "Unnecto",
    value: "UO",
  },
  {
    label: "Unonu",
    value: "UU",
  },
  {
    label: "Unowhy",
    value: "UN",
  },
  {
    label: "UTOK",
    value: "UK",
  },
  {
    label: "IUNI",
    value: "3U",
  },
  {
    label: "UTStarcom",
    value: "UT",
  },
  {
    label: "UTime",
    value: "6U",
  },
  {
    label: "VAIO",
    value: "5V",
  },
  {
    label: "VAVA",
    value: "WV",
  },
  {
    label: "Vastking",
    value: "VA",
  },
  {
    label: "Vargo",
    value: "VP",
  },
  {
    label: "VC",
    value: "VB",
  },
  {
    label: "Venso",
    value: "VN",
  },
  {
    label: "Vega",
    value: "VQ",
  },
  {
    label: "Verico",
    value: "4V",
  },
  {
    label: "Verizon",
    value: "V4",
  },
  {
    label: "Vernee",
    value: "VR",
  },
  {
    label: "Vertex",
    value: "VX",
  },
  {
    label: "Vertu",
    value: "VE",
  },
  {
    label: "Verykool",
    value: "VL",
  },
  {
    label: "Vesta",
    value: "V8",
  },
  {
    label: "Vestel",
    value: "VT",
  },
  {
    label: "VGO TEL",
    value: "V6",
  },
  {
    label: "Videocon",
    value: "VD",
  },
  {
    label: "Videoweb",
    value: "VW",
  },
  {
    label: "ViewSonic",
    value: "VS",
  },
  {
    label: "Vinga",
    value: "V7",
  },
  {
    label: "Vinsoc",
    value: "V3",
  },
  {
    label: "Vipro",
    value: "0V",
  },
  {
    label: "Vitelcom",
    value: "VI",
  },
  {
    label: "Viumee",
    value: "8V",
  },
  {
    label: "Vivax",
    value: "V5",
  },
  {
    label: "Vivo",
    value: "VV",
  },
  {
    label: "VIWA",
    value: "6V",
  },
  {
    label: "Vizio",
    value: "VZ",
  },
  {
    label: "Vision Touch",
    value: "9V",
  },
  {
    label: "VK Mobile",
    value: "VK",
  },
  {
    label: "v-mobile",
    value: "JM",
  },
  {
    label: "VKworld",
    value: "V0",
  },
  {
    label: "Vodacom",
    value: "VM",
  },
  {
    label: "Vodafone",
    value: "VF",
  },
  {
    label: "Vonino",
    value: "V2",
  },
  {
    label: "Vontar",
    value: "1V",
  },
  {
    label: "Vorago",
    value: "VG",
  },
  {
    label: "Vorke",
    value: "2V",
  },
  {
    label: "Voto",
    value: "V1",
  },
  {
    label: "VOX",
    value: "Z7",
  },
  {
    label: "Voxtel",
    value: "VO",
  },
  {
    label: "Voyo",
    value: "VY",
  },
  {
    label: "Vsmart",
    value: "VH",
  },
  {
    label: "Vsun",
    value: "V9",
  },
  {
    label: "Vulcan",
    value: "VU",
  },
  {
    label: "VVETIME",
    value: "3V",
  },
  {
    label: "Walton",
    value: "WA",
  },
  {
    label: "Weimei",
    value: "WM",
  },
  {
    label: "WellcoM",
    value: "WE",
  },
  {
    label: "WELLINGTON",
    value: "W6",
  },
  {
    label: "Western Digital",
    value: "WD",
  },
  {
    label: "Westpoint",
    value: "WT",
  },
  {
    label: "Wexler",
    value: "WY",
  },
  {
    label: "WE",
    value: "3W",
  },
  {
    label: "Wieppo",
    value: "WP",
  },
  {
    label: "Wigor",
    value: "W2",
  },
  {
    label: "Wiko",
    value: "WI",
  },
  {
    label: "Wileyfox",
    value: "WF",
  },
  {
    label: "Winds",
    value: "WS",
  },
  {
    label: "Wink",
    value: "WN",
  },
  {
    label: "Winmax",
    value: "9W",
  },
  {
    label: "Winnovo",
    value: "W5",
  },
  {
    label: "Wintouch",
    value: "WU",
  },
  {
    label: "Wiseasy",
    value: "W0",
  },
  {
    label: "Wizz",
    value: "2W",
  },
  {
    label: "WIWA",
    value: "W4",
  },
  {
    label: "Wolder",
    value: "WL",
  },
  {
    label: "Wolfgang",
    value: "WG",
  },
  {
    label: "Wonu",
    value: "WO",
  },
  {
    label: "Woo",
    value: "W1",
  },
  {
    label: "Wortmann",
    value: "WR",
  },
  {
    label: "Woxter",
    value: "WX",
  },
  {
    label: "X-BO",
    value: "X3",
  },
  {
    label: "X-TIGI",
    value: "XT",
  },
  {
    label: "X-View",
    value: "XV",
  },
  {
    label: "X.Vision",
    value: "X4",
  },
  {
    label: "Xgody",
    value: "XG",
  },
  {
    label: "XGIMI",
    value: "QX",
  },
  {
    label: "Xiaolajiao",
    value: "XL",
  },
  {
    label: "Xiaomi",
    value: "XI",
  },
  {
    label: "Xion",
    value: "XN",
  },
  {
    label: "Xolo",
    value: "XO",
  },
  {
    label: "Xoro",
    value: "XR",
  },
  {
    label: "Xshitou",
    value: "XS",
  },
  {
    label: "Xtouch",
    value: "4X",
  },
  {
    label: "Xtratech",
    value: "X8",
  },
  {
    label: "Yandex",
    value: "YD",
  },
  {
    label: "Yarvik",
    value: "YA",
  },
  {
    label: "Yes",
    value: "Y2",
  },
  {
    label: "Yezz",
    value: "YE",
  },
  {
    label: "Yoka TV",
    value: "YK",
  },
  {
    label: "Yota",
    value: "YO",
  },
  {
    label: "Ytone",
    value: "YT",
  },
  {
    label: "Yu",
    value: "Y1",
  },
  {
    label: "YUHO",
    value: "Y0",
  },
  {
    label: "Yuno",
    value: "YN",
  },
  {
    label: "Yuandao",
    value: "YU",
  },
  {
    label: "Yusun",
    value: "YS",
  },
  {
    label: "YASIN",
    value: "YJ",
  },
  {
    label: "Yxtel",
    value: "YX",
  },
  {
    label: "Zatec",
    value: "0Z",
  },
  {
    label: "Zaith",
    value: "2Z",
  },
  {
    label: "Zebra",
    value: "PZ",
  },
  {
    label: "Zeemi",
    value: "ZE",
  },
  {
    label: "Zen",
    value: "ZN",
  },
  {
    label: "Zenek",
    value: "ZK",
  },
  {
    label: "Zentality",
    value: "ZL",
  },
  {
    label: "Zfiner",
    value: "ZF",
  },
  {
    label: "Zidoo",
    value: "ZI",
  },
  {
    label: "ZIFRO",
    value: "FZ",
  },
  {
    label: "Ziox",
    value: "ZX",
  },
  {
    label: "Zonda",
    value: "ZO",
  },
  {
    label: "Zopo",
    value: "ZP",
  },
  {
    label: "ZTE",
    value: "ZT",
  },
  {
    label: "Zuum",
    value: "ZU",
  },
  {
    label: "Zync",
    value: "ZY",
  },
  {
    label: "ZYQ",
    value: "ZQ",
  },
  {
    label: "ZH&K",
    value: "Z4",
  },
  {
    label: "öwn",
    value: "OW",
  },
  {
    label: "Web TV",
    value: "WB",
  },
  {
    label: "Unknown",
    value: "XX",
  },
];

export default deviceBrands;
