import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUrlData } from "../../../../../core/utilities/redux/componentActions";
import useOrders from "../../../../hooks/useOrders";
import { SHIPMENT_READY, SHIPMENT_SHIPPED } from "../../../constants";
import stateCount from "./stateCounter";
import TopCard from "./TopCard";

const SHOP_DETAILS_DATA_KEY = "SHOP_DETAILS_DATA_KEY";

function stateShippingCount(orders, state) {
  const filteredItems = orders.filter((item) => item.shipment_state === state);
  return filteredItems.length;
}

function ShippingCard(props) {
  const { match } = props;
  const { params } = match;
  const site_id = params.id;
  const site = site_id.toString();
  const { isLoading, orders } = useOrders(site);
  const states = [SHIPMENT_READY];

  if (isLoading) {
    return <div className="rounded-3 p-4 h-44r shadow-sm skeleton-loading" />;
  }

  const cardState = states.map((state) => {
    return (
      <TopCard
        key={state}
        title="Ready Shipments"
        count={stateShippingCount(orders, SHIPMENT_SHIPPED)}
        itemsLabel="Items shipped"
        itemCount={stateShippingCount(orders, state)}
      />
    );
  });

  return <div>{cardState}</div>;
}

ShippingCard.defaultProps = {};

ShippingCard.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

/**
 * @name mapStateToProps
 * Maps the state to the props.
 * @param state
 * @returns {{shop, isLoadingShop: (boolean|*)}}
 */
function mapStateToProps(state: any) {
  const { sessionVariables, dataByUrl } = state;
  const shop_details_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    SHOP_DETAILS_DATA_KEY,
  );
  return {
    isLoadingShop: shop_details_data.isFetching,
    shop: shop_details_data.items,
  };
}

export default connect(mapStateToProps)(withRouter(ShippingCard));
