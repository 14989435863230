import { intercom_logo } from "../svgIcons";

export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const tm = editor.Panels.getButton("views", "open-tm");
  const { intercom_app_id, IntercomComponent } = opts;

  const placeholder = `<div style="text-align:center;color:#f6a908;height: 50px;">${intercom_logo}</div><div style="text-align:center;color:#f6a908;">This is a placeholder and will not be rendered. Toggle visibility in layers</div>`;

  domc.addType("INTER-COM-COMPONENT", {
    model: {
      defaults: {
        tagName: "div",
        name: "Intercom",
        draggable: "[data-gjs-type=wrapper]",
        droppable: false,
        stylable: false,
        copyable: false,
        intercom_app_id,
        traits: [
          {
            name: "intercom_app_id",
            label: "App ID",
            placeholder: "Enter Intercom App ID.",
            changeProp: 1,
          },
        ],
        content: `<div id="Intercom-container" style="padding: 25px;">${placeholder}</div>`,
        script() {
          (function (app_id) {
            window.intercomSettings = {
              app_id,
            };
            const w = window;
            const ic = w.Intercom;
            if (typeof ic === "function") {
              ic("reattach_activator");
              ic("update", w.intercomSettings);
            } else {
              const d = document;
              var i = function () {
                i.c(arguments);
              };
              i.q = [];
              i.c = function (args) {
                i.q.push(args);
              };
              w.Intercom = i;
              const l = function () {
                const s = d.createElement("script");
                s.type = "text/javascript";
                s.async = true;
                s.src = `https://widget.intercom.io/widget/${app_id}`;
                const x = d.getElementsByTagName("script")[0];
                x.parentNode.insertBefore(s, x);
              };
              if (w.attachEvent) {
                w.attachEvent("onload", l);
              } else {
                w.addEventListener("load", l, false);
              }
            }
          })("{[intercom_app_id]}");
        },
      },
      init() {
        this.on("change:intercom_app_id", () => valuesOnchange());
        const valuesOnchange = () => {
          this.trigger("change:script");
          editor.store();
        };
        tm.set("active", 1);
      },
      toHTML() {
        const tagName = this.get("tagName");
        return `<${tagName} id="${this.getId()}"></${tagName}>`;
      },
      ...IntercomComponent,
    },
    view: {},
  });
};
