import { cmdCustomOpenBlockManager } from "../consts";

export const OpenBlocks = {
  open(opts) {
    const { container, editor, bm, popUp } = this;

    if (this.firstRender) {
      const id = "views-container";
      const pn = editor.Panels;
      const panels = pn.getPanel(id) || pn.addPanel({ id });
      panels.set("appendContent", container).trigger("change:appendContent");
      container.appendChild(bm.getContainer());
      container.style.height = "100%";
      container.style.overflowX = "scroll";
      container.style.setProperty("max-height", "50%");

      editor.on("pop-up:close", () => {
        editor.Commands.stop(cmdCustomOpenBlockManager);
      });
    }

    if (container) container.style.display = "block";

    popUp.setContent(container);
    popUp.open({
      selector: opts.selector,
    });
  },

  close() {
    const { container, editor } = this;

    editor.Commands.stop(cmdCustomOpenBlockManager);
    if (container) container.style.display = "none";
  },

  run(editor, sender, opts = {}) {
    const bm = editor.Blocks;
    this.firstRender = !this.container;
    this.container = this.container || document.createElement("div");
    this.editor = editor;
    this.bm = bm;
    this.popUp = editor.PopUp;

    const { container } = this;
    bm.__behaviour({
      container,
    });
    this.open(opts);
  },

  stop() {
    this.close();
  },
};

export default OpenBlocks;
