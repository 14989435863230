import { isString } from "underscore";
import {
  btn_css,
  currencies,
  product_div_css,
  product_h3_css,
  product_h6_css,
  product_img_css,
  product_p_css,
} from "../constants";

export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const tm = editor.Panels.getButton("views", "open-tm");
  const {
    product_id,
    product_name = "Product Name",
    product_price = "00.00",
    product_description = "Description",
    product_image_url,
    price_currency = "USD",
    currency_position = "left",
    paypal_client_id,
    PayPalProductBlockComponent,
  } = opts;

  domc.addType("PAYPAL-PRODUCT-BLOCK-COMPONENT", {
    model: {
      defaults: {
        tagName: "div",
        name: "PayPal Product Block",
        draggable: "[data-gjs-type=wrapper]",
        droppable: false,
        stylable: true,
        copyable: false,
        resizable: true,
        product_id,
        product_name,
        product_price,
        product_description,
        product_image_url,
        price_currency,
        currency_position,
        paypal_client_id,
        traits: [
          {
            name: "product_id",
            label: "Unique Product ID",
            placeholder: "Product Id",
            changeProp: 1,
          },
          {
            name: "product_name",
            label: "Product Name",
            placeholder: "Product name",
            changeProp: 1,
          },
          {
            name: "product_description",
            label: "Product Description",
            placeholder: "Product description",
            changeProp: 1,
          },
          {
            name: "product_image_url",
            label: "Product Image URL",
            placeholder: "Product image url.",
            changeProp: 1,
            attributes: { style: "display: none" },
          },
          {
            type: "button",
            text: "Select Image",
            label: "Product Image",
            full: true,
            command: (editor) =>
              editor.runCommand("open-assets", {
                target: this,
                types: ["image"],
                accept: "image/*",
                onSelect: (asset) => {
                  editor.Modal.close();
                  const url = isString(asset) ? asset : asset.get("src");
                  const component = editor.getSelected();
                  component.updateTrait("product_image_url", {
                    value: url,
                  });
                },
              }),
          },
          {
            name: "product_price",
            label: "Product Price",
            placeholder: "Product price",
            changeProp: 1,
          },
          {
            type: "select",
            name: "price_currency",
            label: "Currency",
            changeProp: 1,
            options: currencies,
          },
          {
            type: "select",
            name: "currency_position",
            label: "Currency Position",
            changeProp: 1,
            options: [
              { value: "left", name: "left" },
              { value: "right", name: "right" },
            ],
          },
          {
            name: "paypal_client_id",
            label: "Paypal Client id",
            placeholder: "Paypal client Id",
            changeProp: 1,
          },
        ],
      },
      init() {
        this.setComponent();
        this.on(
          "change:product_price change:product_id change:product_name change:product_description" +
            " change:price_currency change:paypal_client_id change:currency_position change:product_image_url",
          () => valuesOnchange()
        );
        const valuesOnchange = () => {
          this.setComponent();
        };
        tm.set("active", 1);
      },
      setComponent() {
        const product_name = this.get("product_name");
        const product_image_url = this.get("product_image_url")
          ? this.get("product_image_url")
          : "https://source.unsplash.com/random/?food";
        let product_id = this.get("product_id");
        if (product_id === "" || product_id === undefined) {
          product_id = product_name;
        }
        const btn_id = product_name.split(" ").join("");
        const paypal_client_id = this.get("paypal_client_id");
        const uniq_id = this.getId();
        const payPalLink = `https://www.paypal.com/sdk/js?client-id=${paypal_client_id}`;
        const paypal_append_script = `if (!document.getElementById("paypal_script")) { const script = document.createElement("script"); script.setAttribute("id", "paypal_script"); script.setAttribute("src", "${payPalLink}"); document.head.appendChild(script); }`;
        const paypal_script = `<script type="text/javascript">${paypal_append_script}</script>`;

        const currency_position = this.get("currency_position");
        let right = "";
        let left = "";
        if (currency_position === "right") {
          right = this.get("price_currency");
        } else {
          left = this.get("price_currency");
        }
        const btn_div = `<div id='${btn_id}'  style='padding: 15px;'></div>`;
        const btn_script = `function onclickBuyButton${uniq_id}(el){el.style.display='none';document.getElementById('${uniq_id}').innerHTML+="${btn_div}";paypal.Buttons({style:{size:'small',color:'gold',shape:'pill',label:'pay',layout:'horizontal',tagline:'false'},createOrder:function(data,actions){return actions.order.create({purchase_units:[{reference_id:"${product_id}",amount:{currency_code:"${this.get(
          "price_currency"
        )}",value:"${this.get(
          "product_price"
        )}",breakdown:{item_total:{currency_code:"${this.get(
          "price_currency"
        )}",value:"${this.get(
          "product_price"
        )}",}}},items:[{name:"${product_name}",description:"${this.get(
          "product_description"
        )}",unit_amount:{currency_code:"${this.get(
          "price_currency"
        )}",value:"${this.get(
          "product_price"
        )}"},quantity:"1",}],}]});},onApprove:function(data,actions){return actions.order.capture().then(function(details){const message="Transaction completed successfully";alert(message);});},}).render("#${btn_id}");}`;
        const paypal_order_script = `<script type="text/javascript">${btn_script}</script>`;
        const new_component = `${paypal_script}<img style="${product_img_css}" src="${product_image_url}" alt=""><h3 style="${product_h3_css}">${this.get(
          "product_name"
        )}</h3><p style="${product_p_css}">${this.get(
          "product_description"
        )}</p><h6 style="${product_h6_css}">${left} ${this.get(
          "product_price"
        )} ${right}</h6><div style="${btn_css}" onclick="onclickBuyButton${uniq_id}(this)">Checkout</div>
                ${paypal_order_script}`;
        this.set("content", new_component);
        this.setStyle(product_div_css);
        return new_component;
      },
      ...PayPalProductBlockComponent,
    },
    view: {},
  });
};
