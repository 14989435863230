import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";

function PropertyFormFields({
  properties,
  selectedProperty,
  setSelectedProperty,
}) {
  const handleSelectionChange = useCallback(
    (propertyId, streamId) => {
      setSelectedProperty({ property_id: propertyId, stream_id: streamId });
    },
    [setSelectedProperty],
  );

  const handleKeyPress = useCallback(
    (e, propertyId, streamId) => {
      if (e.key === "Enter" || e.key === " ") {
        handleSelectionChange(propertyId, streamId);
      }
    },
    [handleSelectionChange],
  );

  const renderedPropertyList = useMemo(() => {
    return properties?.map((property) =>
      property?.streams?.map((stream) => {
        const propertyId = property.name.split("/")[1];
        const isSelected =
          selectedProperty?.property_id === propertyId &&
          selectedProperty?.stream_id === stream.id;
        return (
          <div
            key={`${propertyId}-${stream.id}`}
            className={`form-check p-3 mb-2 border rounded position-relative d-flex align-items-center ${
              isSelected ? "bg-dark text-white" : "bg-light"
            }`}
            role="radio"
            tabIndex="0"
            aria-checked={isSelected}
            onClick={() => handleSelectionChange(propertyId, stream.id)}
            onKeyPress={(e) => handleKeyPress(e, propertyId, stream.id)}
          >
            <input
              className="form-check-input position-absolute"
              type="radio"
              name="selectedProperty"
              value={`${propertyId}-${stream.id}`}
              id={`${propertyId}-${stream.id}`}
              checked={isSelected}
              onChange={() => handleSelectionChange(propertyId, stream.id)}
              style={{ top: "50%" }}
              hidden
              aria-label={`Select property ${property.display_name} with stream ${stream.default_uri}`}
            />
            <label
              className="form-check-label ms-5 d-flex flex-column w-100 justify-center text-center"
              htmlFor={`${propertyId}-${stream.id}`}
            >
              <span className="fw-bold">{property.display_name}</span>
              <span className={`${isSelected ? "text-light" : "text-muted"}`}>
                {stream.default_uri}
              </span>
            </label>
          </div>
        );
      }),
    );
  }, [properties, selectedProperty, handleSelectionChange, handleKeyPress]);

  return <div className="mb-3">{renderedPropertyList}</div>;
}

PropertyFormFields.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      display_name: PropTypes.string.isRequired,
      streams: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          default_uri: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  selectedProperty: PropTypes.shape({
    property_id: PropTypes.string,
    stream_id: PropTypes.string,
  }),
  setSelectedProperty: PropTypes.func.isRequired,
};

PropertyFormFields.defaultProps = {
  selectedProperty: null,
};

export default PropertyFormFields;
