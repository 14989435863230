import PropTypes from "prop-types";
import EditStageForm from "./EditStageForm";
import Modal from "modules/base/components/Modal";
import { Stage } from "modules/marketing/MarketingModels";

function EditStageModal({ row }) {
  const modalId = `edit-stage-modal-${row.id}`;
  return (
    <Modal modal_title="Edit Stage" modal_id={modalId} size="md">
      <EditStageForm stage={row} currentModalId={modalId} />
    </Modal>
  );
}

EditStageModal.propTypes = {
  row: PropTypes.instanceOf(Stage).isRequired,
};

export default EditStageModal;
